/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { GetPaymentDetail } from 'src/Redux/Slices/authSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import FailedPopup from 'src/Components/PymentPopups/FailedPopup'

const Fail: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('emailId')
  const emailId = useAppSelector((state) => state?.auth?.SignUpData?.emailId)
  const sessionId = useAppSelector((state: RootState) => state?.auth?.sessionId)
  const [openDialog, setOpenDialog] = useState(true)

  const handleOpen = () => {
    setOpenDialog(true)
  }
  useEffect(() => {
    dispatch(
      GetPaymentDetail({
        emailId: email ?? emailId,
        sessionId,
        status: 'fail',
      }),
    )
  }, [])

  return (
    <>
      <FailedPopup
        status={'Failed'}
        message={'Payment is failed please try again'}
        paymentId={sessionId}
        date={new Intl.DateTimeFormat('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
          hourCycle: 'h12',
        }).format(new Date())}
        supportLink={'https://benefitsboost.vercel.app/contact'}
        open={openDialog}
        setOpen={setOpenDialog}
        handleOpen={handleOpen}
      />
    </>
  )
}

export default Fail
