export const medicationArray = [
  {
    name: '0.25% Acetic Acid (acetic acid)',
    value: '0.25% Acetic Acid (acetic acid)',
  },
  {
    name: '10% Calcium Chloride (calcium chloride)',
    value: '10% Calcium Chloride (calcium chloride)',
  },
  {
    name: '10% Dextrose Injection (dextrose monohydrate)',
    value: '10% Dextrose Injection (dextrose monohydrate)',
  },
  {
    name: '2% Acetic Acid (acetic acid)',
    value: '2% Acetic Acid (acetic acid)',
  },
  {
    name: '4% Xylocaine-MPF (lidocaine hydrochloride)',
    value: '4% Xylocaine-MPF (lidocaine hydrochloride)',
  },
  {
    name: '4Life Transfer Factor Tri-Factor Formula (nanofactor/transfer factor)',
    value:
      '4Life Transfer Factor Tri-Factor Formula (nanofactor/transfer factor)',
  },
  {
    name: '5% Dextrose (dextrose monohydrate)',
    value: '5% Dextrose (dextrose monohydrate)',
  },
  { name: '8-Mop (methoxsalen)', value: '8-Mop (methoxsalen)' },
  {
    name: 'Abelcet (amphotericin B lipid complex)',
    value: 'Abelcet (amphotericin B lipid complex)',
  },
  { name: 'Abilify (aripiprazole)', value: 'Abilify (aripiprazole)' },
  {
    name: 'Abilify Maintena (aripiprazole)',
    value: 'Abilify Maintena (aripiprazole)',
  },
  {
    name: 'Abraxane (paclitaxel protein-bound)',
    value: 'Abraxane (paclitaxel protein-bound)',
  },
  { name: 'Abreva (docosanol)', value: 'Abreva (docosanol)' },
  { name: 'Absorica (isotretinoin)', value: 'Absorica (isotretinoin)' },
  { name: 'Abstral (fentanyl)', value: 'Abstral (fentanyl)' },
  {
    name: 'Acanya (benzoyl peroxide/clindamycin phosphate)',
    value: 'Acanya (benzoyl peroxide/clindamycin phosphate)',
  },
  { name: 'Accolate (zafirlukast)', value: 'Accolate (zafirlukast)' },
  { name: 'AccuNeb (albuterol sulfate)', value: 'AccuNeb (albuterol sulfate)' },
  {
    name: 'Accupril (quinapril hydrochloride)',
    value: 'Accupril (quinapril hydrochloride)',
  },
  {
    name: 'Accuretic (hydrochlorothiazide/quinapril hydrochloride)',
    value: 'Accuretic (hydrochlorothiazide/quinapril hydrochloride)',
  },
  { name: 'Acetadote (acetylcysteine)', value: 'Acetadote (acetylcysteine)' },
  {
    name: 'Acetaminophen and Codeine Phosphate Oral Solution (acetaminophen/codeine phosphate)',
    value:
      'Acetaminophen and Codeine Phosphate Oral Solution (acetaminophen/codeine phosphate)',
  },
  {
    name: 'Acetaminophen and Codeine Phosphate Tablets (acetaminophen/codeine phosphate)',
    value:
      'Acetaminophen and Codeine Phosphate Tablets (acetaminophen/codeine phosphate)',
  },
  {
    name: 'Acetasol HC (acetic acid/hydrocortisone)',
    value: 'Acetasol HC (acetic acid/hydrocortisone)',
  },
  {
    name: 'Acetazolamide for Injection (acetazolamide)',
    value: 'Acetazolamide for Injection (acetazolamide)',
  },
  {
    name: 'Acetazolamide Tablets (acetazolamide)',
    value: 'Acetazolamide Tablets (acetazolamide)',
  },
  {
    name: 'Acetylcysteine (acetylcysteine)',
    value: 'Acetylcysteine (acetylcysteine)',
  },
  {
    name: 'Aciphex (rabeprazole sodium)',
    value: 'Aciphex (rabeprazole sodium)',
  },
  {
    name: 'Aciphex Sprinkle (rabeprazole sodium)',
    value: 'Aciphex Sprinkle (rabeprazole sodium)',
  },
  {
    name: 'Aclovate (alclometasone dipropionate)',
    value: 'Aclovate (alclometasone dipropionate)',
  },
  { name: 'Actemra (tocilizumab)', value: 'Actemra (tocilizumab)' },
  {
    name: 'ActHIB (haemophilus b conjugate vaccine (tetanus toxoid conjugate))',
    value:
      'ActHIB (haemophilus b conjugate vaccine (tetanus toxoid conjugate))',
  },
  {
    name: 'Acthrel (corticorelin ovine triflutate)',
    value: 'Acthrel (corticorelin ovine triflutate)',
  },
  {
    name: 'Acticlate/Acticlate CAP (doxycycline hyclate)',
    value: 'Acticlate/Acticlate CAP (doxycycline hyclate)',
  },
  { name: 'Actigall (ursodiol)', value: 'Actigall (ursodiol)' },
  {
    name: 'Actimmune (interferon gamma-1b)',
    value: 'Actimmune (interferon gamma-1b)',
  },
  { name: 'Actiq (fentanyl citrate)', value: 'Actiq (fentanyl citrate)' },
  { name: 'Activase (alteplase)', value: 'Activase (alteplase)' },
  {
    name: 'Activella (estradiol/norethindrone acetate)',
    value: 'Activella (estradiol/norethindrone acetate)',
  },
  {
    name: 'Actonel (risedronate sodium)',
    value: 'Actonel (risedronate sodium)',
  },
  {
    name: 'ActoPlus Met (metformin hydrochloride/pioglitazone)',
    value: 'ActoPlus Met (metformin hydrochloride/pioglitazone)',
  },
  {
    name: 'ActoPlus Met XR (metformin hydrochloride/pioglitazone)',
    value: 'ActoPlus Met XR (metformin hydrochloride/pioglitazone)',
  },
  { name: 'Actos (pioglitazone)', value: 'Actos (pioglitazone)' },
  {
    name: 'Acular (ketorolac tromethamine)',
    value: 'Acular (ketorolac tromethamine)',
  },
  {
    name: 'Acular LS (ketorolac tromethamine)',
    value: 'Acular LS (ketorolac tromethamine)',
  },
  {
    name: 'Acuvail (ketorolac tromethamine)',
    value: 'Acuvail (ketorolac tromethamine)',
  },
  {
    name: 'Acyclovir (acyclovir sodium)',
    value: 'Acyclovir (acyclovir sodium)',
  },
  { name: 'Aczone 5% (dapsone)', value: 'Aczone 5% (dapsone)' },
  { name: 'Aczone 7.5% (dapsone)', value: 'Aczone 7.5% (dapsone)' },
  {
    name: 'Adacel (tetanus toxoid, reduced diphtheria toxoid and acellular pertussis vac etc.',
    value:
      'Adacel (tetanus toxoid, reduced diphtheria toxoid and acellular pertussis vac etc.',
  },
  { name: 'Adagen (pegademase bovine)', value: 'Adagen (pegademase bovine)' },
  {
    name: 'ADAKVEO (crizanlizumab injection)',
    value: 'ADAKVEO (crizanlizumab injection)',
  },
  { name: 'Adalat CC (nifedipine)', value: 'Adalat CC (nifedipine)' },
  { name: 'Adapalene (adapalene)', value: 'Adapalene (adapalene)' },
  { name: 'Adasuve (loxapine)', value: 'Adasuve (loxapine)' },
  {
    name: 'Adcetris (brentuximab vedotin)',
    value: 'Adcetris (brentuximab vedotin)',
  },
  { name: 'Adcirca (tadalafil)', value: 'Adcirca (tadalafil)' },
  {
    name: 'Adderall (amphetamine aspartate monohydrate/amphetamine sulfate/dextroamphetami etc.',
    value:
      'Adderall (amphetamine aspartate monohydrate/amphetamine sulfate/dextroamphetami etc.',
  },
  {
    name: 'Adderall XR (amphetamine aspartate monohydrate/amphetamine sulfate/dextroamphetami etc.',
    value:
      'Adderall XR (amphetamine aspartate monohydrate/amphetamine sulfate/dextroamphetami etc.',
  },
  { name: 'Addyi (flibanserin)', value: 'Addyi (flibanserin)' },
  { name: 'Adempas (riociguat)', value: 'Adempas (riociguat)' },
  { name: 'Adenocard (adenosine)', value: 'Adenocard (adenosine)' },
  { name: 'Adenoscan (adenosine)', value: 'Adenoscan (adenosine)' },
  { name: 'Adenosine (adenosine)', value: 'Adenosine (adenosine)' },
  {
    name: 'Adhansia XR (methylphenidate hydrochloride)',
    value: 'Adhansia XR (methylphenidate hydrochloride)',
  },
  {
    name: 'Adipex-P (phentermine hydrochloride)',
    value: 'Adipex-P (phentermine hydrochloride)',
  },
  { name: 'Adlyxin (lixisenatide)', value: 'Adlyxin (lixisenatide)' },
  { name: 'Adoxa (doxycycline)', value: 'Adoxa (doxycycline)' },
  { name: 'Adrenaclick (epinephrine)', value: 'Adrenaclick (epinephrine)' },
  { name: 'Adrenalin (epinephrine)', value: 'Adrenalin (epinephrine)' },
  { name: 'AdreView (iobenguane I 123)', value: 'AdreView (iobenguane I 123)' },
  {
    name: 'Adriamycin (doxorubicin hydrochloride)',
    value: 'Adriamycin (doxorubicin hydrochloride)',
  },
  { name: 'Adrucil (fluorouracil)', value: 'Adrucil (fluorouracil)' },
  {
    name: "Adults' FeverAll (acetaminophen)",
    value: "Adults' FeverAll (acetaminophen)",
  },
  {
    name: 'Advair Diskus (fluticasone propionate/salmeterol)',
    value: 'Advair Diskus (fluticasone propionate/salmeterol)',
  },
  {
    name: 'Advair HFA (fluticasone propionate/salmeterol)',
    value: 'Advair HFA (fluticasone propionate/salmeterol)',
  },
  {
    name: 'Advanced Blood Sugar Control (dietary supplement)',
    value: 'Advanced Blood Sugar Control (dietary supplement)',
  },
  {
    name: 'Advanced Eye Relief Dry Eye Rejuvenation (glycerin/propylene glycol)',
    value:
      'Advanced Eye Relief Dry Eye Rejuvenation (glycerin/propylene glycol)',
  },
  {
    name: 'Advanced Lung Cleanse (dietary supplement)',
    value: 'Advanced Lung Cleanse (dietary supplement)',
  },
  {
    name: 'Advate (antihemophilic factor (recombinant))',
    value: 'Advate (antihemophilic factor (recombinant))',
  },
  { name: 'Advicor (lovastatin/niacin)', value: 'Advicor (lovastatin/niacin)' },
  { name: 'Advil (ibuprofen)', value: 'Advil (ibuprofen)' },
  {
    name: 'Advil Allergy Sinus (chlorpheniramine maleate/ibuprofen/pseudoephedrine hydrochloride)',
    value:
      'Advil Allergy Sinus (chlorpheniramine maleate/ibuprofen/pseudoephedrine hydrochloride)',
  },
  {
    name: 'Advil Cold and Sinus Caplets (ibuprofen/pseudoephedrine hydrochloride)',
    value:
      'Advil Cold and Sinus Caplets (ibuprofen/pseudoephedrine hydrochloride)',
  },
  {
    name: 'Advil Cold and Sinus Liqui-Gels (ibuprofen/pseudoephedrine hydrochloride)',
    value:
      'Advil Cold and Sinus Liqui-Gels (ibuprofen/pseudoephedrine hydrochloride)',
  },
  {
    name: 'Advil Film-Coated (ibuprofen)',
    value: 'Advil Film-Coated (ibuprofen)',
  },
  {
    name: 'Advil Liqui-Gels (ibuprofen)',
    value: 'Advil Liqui-Gels (ibuprofen)',
  },
  { name: 'Advil Migraine (ibuprofen)', value: 'Advil Migraine (ibuprofen)' },
  {
    name: 'Advil PM Caplets (diphenhydramine citrate/ibuprofen)',
    value: 'Advil PM Caplets (diphenhydramine citrate/ibuprofen)',
  },
  {
    name: 'Advil PM Liqui-Gels (diphenhydramine hydrochloride/ibuprofen)',
    value: 'Advil PM Liqui-Gels (diphenhydramine hydrochloride/ibuprofen)',
  },
  {
    name: 'Adynovate (antihemophilic factor (recombinant), pegylated)',
    value: 'Adynovate (antihemophilic factor (recombinant), pegylated)',
  },
  {
    name: 'Adzenys XR-ODT (amphetamine)',
    value: 'Adzenys XR-ODT (amphetamine)',
  },
  { name: 'Aerospan (flunisolide)', value: 'Aerospan (flunisolide)' },
  { name: 'Afeditab CR (nifedipine)', value: 'Afeditab CR (nifedipine)' },
  { name: 'Afinitor (everolimus)', value: 'Afinitor (everolimus)' },
  {
    name: 'Afirmelle (ethinyl estradiol/levonorgestrel)',
    value: 'Afirmelle (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'Afluria (influenza vaccine)', value: 'Afluria (influenza vaccine)' },
  { name: 'Afrezza (insulin human)', value: 'Afrezza (insulin human)' },
  {
    name: 'Afstyla (antihemophilic factor (recombinant), single chain)',
    value: 'Afstyla (antihemophilic factor (recombinant), single chain)',
  },
  {
    name: 'ageLOC R2 (dietary supplement)',
    value: 'ageLOC R2 (dietary supplement)',
  },
  {
    name: 'ageLOC TR90 (dietary supplement)',
    value: 'ageLOC TR90 (dietary supplement)',
  },
  {
    name: 'ageLOC Youth (dietary supplement)',
    value: 'ageLOC Youth (dietary supplement)',
  },
  {
    name: 'Aggrastat (tirofiban hydrochloride)',
    value: 'Aggrastat (tirofiban hydrochloride)',
  },
  {
    name: 'Aggrenox (aspirin/dipyridamole)',
    value: 'Aggrenox (aspirin/dipyridamole)',
  },
  {
    name: 'Agrylin (anagrelide hydrochloride)',
    value: 'Agrylin (anagrelide hydrochloride)',
  },
  { name: 'Aimovig (erenumab-aooe)', value: 'Aimovig (erenumab-aooe)' },
  {
    name: 'AirDuo RespiClick (fluticasone propionate/salmeterol)',
    value: 'AirDuo RespiClick (fluticasone propionate/salmeterol)',
  },
  { name: 'Ajovy (fremanezumab-vfrm)', value: 'Ajovy (fremanezumab-vfrm)' },
  {
    name: 'Ak-Fluor (fluorescein sodium)',
    value: 'Ak-Fluor (fluorescein sodium)',
  },
  { name: 'AKLIEF (trifarotene cream)', value: 'AKLIEF (trifarotene cream)' },
  { name: 'Akovaz (ephedrine sulfate)', value: 'Akovaz (ephedrine sulfate)' },
  {
    name: 'Akten (lidocaine hydrochloride)',
    value: 'Akten (lidocaine hydrochloride)',
  },
  {
    name: 'Akynzeo (netupitant/palonosetron)',
    value: 'Akynzeo (netupitant/palonosetron)',
  },
  { name: 'Ala-Cort (hydrocortisone)', value: 'Ala-Cort (hydrocortisone)' },
  { name: 'Alaway (ketotifen fumarate)', value: 'Alaway (ketotifen fumarate)' },
  { name: 'Albenza (albendazole)', value: 'Albenza (albendazole)' },
  {
    name: 'Albuked 25 (albumin (human))',
    value: 'Albuked 25 (albumin (human))',
  },
  { name: 'Albuked 5 (albumin (human))', value: 'Albuked 5 (albumin (human))' },
  {
    name: 'Albuminar-25 (albumin (human))',
    value: 'Albuminar-25 (albumin (human))',
  },
  {
    name: 'Albuminar-5 (albumin (human))',
    value: 'Albuminar-5 (albumin (human))',
  },
  { name: 'AlbuRx 25 (albumin (human))', value: 'AlbuRx 25 (albumin (human))' },
  { name: 'AlbuRx 5 (albumin (human))', value: 'AlbuRx 5 (albumin (human))' },
  {
    name: 'Albutein 25% (albumin (human))',
    value: 'Albutein 25% (albumin (human))',
  },
  {
    name: 'Albutein 5% (albumin (human))',
    value: 'Albutein 5% (albumin (human))',
  },
  {
    name: 'Albuterol Sulfate Inhalation Solution 0.083% (albuterol sulfate)',
    value: 'Albuterol Sulfate Inhalation Solution 0.083% (albuterol sulfate)',
  },
  {
    name: 'Albuterol Sulfate Inhalation Solution 0.5% (albuterol sulfate)',
    value: 'Albuterol Sulfate Inhalation Solution 0.5% (albuterol sulfate)',
  },
  {
    name: 'Albuterol Sulfate Syrup (albuterol sulfate)',
    value: 'Albuterol Sulfate Syrup (albuterol sulfate)',
  },
  {
    name: 'Albuterol Tablets (albuterol)',
    value: 'Albuterol Tablets (albuterol)',
  },
  {
    name: 'Alcaine (proparacaine hydrochloride)',
    value: 'Alcaine (proparacaine hydrochloride)',
  },
  {
    name: 'Aldactazide (hydrochlorothiazide/spironolactone)',
    value: 'Aldactazide (hydrochlorothiazide/spironolactone)',
  },
  { name: 'Aldactone (spironolactone)', value: 'Aldactone (spironolactone)' },
  { name: 'Aldara (imiquimod)', value: 'Aldara (imiquimod)' },
  { name: 'Aldurazyme (laronidase)', value: 'Aldurazyme (laronidase)' },
  { name: 'Alecensa (alectinib)', value: 'Alecensa (alectinib)' },
  {
    name: 'Alendronate Sodium Oral Solution (alendronate sodium)',
    value: 'Alendronate Sodium Oral Solution (alendronate sodium)',
  },
  {
    name: 'Alendronate Sodium Tablets (alendronate sodium)',
    value: 'Alendronate Sodium Tablets (alendronate sodium)',
  },
  {
    name: 'Alfentanil Hydrochloride (alfentanil hydrochloride)',
    value: 'Alfentanil Hydrochloride (alfentanil hydrochloride)',
  },
  {
    name: 'Alimta (pemetrexed disodium)',
    value: 'Alimta (pemetrexed disodium)',
  },
  { name: 'Alinia (nitazoxanide)', value: 'Alinia (nitazoxanide)' },
  { name: 'ALIQOPA (copanlisib)', value: 'ALIQOPA (copanlisib)' },
  { name: 'Aliskiren (aliskiren)', value: 'Aliskiren (aliskiren)' },
  {
    name: 'Alkeran for Injection (melphalan hydrochloride)',
    value: 'Alkeran for Injection (melphalan hydrochloride)',
  },
  { name: 'Alkeran Tablets (melphalan)', value: 'Alkeran Tablets (melphalan)' },
  {
    name: 'Allegra Allergy Tablets (fexofenadine hydrochloride)',
    value: 'Allegra Allergy Tablets (fexofenadine hydrochloride)',
  },
  {
    name: 'Allegra-D 12 Hour (fexofenadine hydrochloride/pseudoephedrine hydrochloride)',
    value:
      'Allegra-D 12 Hour (fexofenadine hydrochloride/pseudoephedrine hydrochloride)',
  },
  {
    name: 'Allegra-D 24 Hour (fexofenadine hydrochloride/pseudoephedrine hydrochloride)',
    value:
      'Allegra-D 24 Hour (fexofenadine hydrochloride/pseudoephedrine hydrochloride)',
  },
  { name: 'Alocril (nedocromil sodium)', value: 'Alocril (nedocromil sodium)' },
  {
    name: 'Alomide (lodoxamide tromethamine)',
    value: 'Alomide (lodoxamide tromethamine)',
  },
  {
    name: 'Aloprim (allopurinol sodium)',
    value: 'Aloprim (allopurinol sodium)',
  },
  { name: 'Alora (estradiol)', value: 'Alora (estradiol)' },
  {
    name: 'Aloxi (palonosetron hydrochloride)',
    value: 'Aloxi (palonosetron hydrochloride)',
  },
  {
    name: 'Alphagan P (brimonidine tartrate)',
    value: 'Alphagan P (brimonidine tartrate)',
  },
  {
    name: 'Alphanate (antihemophilic factor/von Willebrand factor complex (human))',
    value:
      'Alphanate (antihemophilic factor/von Willebrand factor complex (human))',
  },
  {
    name: 'AlphaNine SD (coagulation factor IX (human))',
    value: 'AlphaNine SD (coagulation factor IX (human))',
  },
  {
    name: 'Alprazolam Intensol Oral Concentrate (alprazolam)',
    value: 'Alprazolam Intensol Oral Concentrate (alprazolam)',
  },
  {
    name: 'Alprazolam Orally Disintegrating Tablets (alprazolam)',
    value: 'Alprazolam Orally Disintegrating Tablets (alprazolam)',
  },
  {
    name: 'Alprolix (coagulation factor IX (recombinant), Fc fusion protein)',
    value: 'Alprolix (coagulation factor IX (recombinant), Fc fusion protein)',
  },
  {
    name: 'Alrex (loteprednol etabonate)',
    value: 'Alrex (loteprednol etabonate)',
  },
  { name: 'Altabax (retapamulin)', value: 'Altabax (retapamulin)' },
  { name: 'Altace (ramipril)', value: 'Altace (ramipril)' },
  {
    name: 'Altavera (ethinyl estradiol/levonorgestrel)',
    value: 'Altavera (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'Altoprev (lovastatin)', value: 'Altoprev (lovastatin)' },
  { name: 'Altreno (tretinoin)', value: 'Altreno (tretinoin)' },
  {
    name: 'Aluminum Hydroxide (aluminum hydroxide)',
    value: 'Aluminum Hydroxide (aluminum hydroxide)',
  },
  { name: 'Alunbrig (brigatinib)', value: 'Alunbrig (brigatinib)' },
  { name: 'Alvesco (ciclesonide)', value: 'Alvesco (ciclesonide)' },
  {
    name: 'Amabelz (estradiol/norethindrone acetate)',
    value: 'Amabelz (estradiol/norethindrone acetate)',
  },
  {
    name: 'Amantadine Hydrochloride Capsules (amantadine hydrochloride)',
    value: 'Amantadine Hydrochloride Capsules (amantadine hydrochloride)',
  },
  {
    name: 'Amantadine Hydrochloride Tablets (amantadine hydrochloride)',
    value: 'Amantadine Hydrochloride Tablets (amantadine hydrochloride)',
  },
  { name: 'Amaryl (glimepiride)', value: 'Amaryl (glimepiride)' },
  { name: 'Ambien (zolpidem tartrate)', value: 'Ambien (zolpidem tartrate)' },
  {
    name: 'Ambien CR (zolpidem tartrate)',
    value: 'Ambien CR (zolpidem tartrate)',
  },
  {
    name: 'AmBisome (amphotericin B liposome)',
    value: 'AmBisome (amphotericin B liposome)',
  },
  {
    name: 'Amcinonide Cream (amcinonide)',
    value: 'Amcinonide Cream (amcinonide)',
  },
  {
    name: 'Amcinonide Lotion (amcinonide)',
    value: 'Amcinonide Lotion (amcinonide)',
  },
  {
    name: 'Amcinonide Ointment (amcinonide)',
    value: 'Amcinonide Ointment (amcinonide)',
  },
  {
    name: 'Ameluz (aminolevulinic acid hydrochloride)',
    value: 'Ameluz (aminolevulinic acid hydrochloride)',
  },
  {
    name: 'Amerge (naratriptan hydrochloride)',
    value: 'Amerge (naratriptan hydrochloride)',
  },
  {
    name: 'Amethia Lo (ethinyl estradiol/levonorgestrel)',
    value: 'Amethia Lo (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'Amethyst (ethinyl estradiol/levonorgestrel)',
    value: 'Amethyst (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'Amicar (aminocaproic acid)', value: 'Amicar (aminocaproic acid)' },
  { name: 'Amidate (etomidate)', value: 'Amidate (etomidate)' },
  { name: 'Amifostine (amifostine)', value: 'Amifostine (amifostine)' },
  {
    name: 'Amikacin Sulfate (amikacin sulfate)',
    value: 'Amikacin Sulfate (amikacin sulfate)',
  },
  {
    name: 'Amiloride Hydrochloride (amiloride hydrochloride)',
    value: 'Amiloride Hydrochloride (amiloride hydrochloride)',
  },
  {
    name: 'Amiloride Hydrochloride and Hydrochlorothiazide (amiloride hydrochloride/hydrochlorothiazide)',
    value:
      'Amiloride Hydrochloride and Hydrochlorothiazide (amiloride hydrochloride/hydrochlorothiazide)',
  },
  {
    name: 'Aminocaproic Acid (aminocaproic acid)',
    value: 'Aminocaproic Acid (aminocaproic acid)',
  },
  {
    name: 'Aminophylline Injection (aminophylline dihydrate)',
    value: 'Aminophylline Injection (aminophylline dihydrate)',
  },
  {
    name: 'Amiodarone Hydrochloride Injection (amiodarone hydrochloride)',
    value: 'Amiodarone Hydrochloride Injection (amiodarone hydrochloride)',
  },
  { name: 'Amitiza (lubiprostone)', value: 'Amitiza (lubiprostone)' },
  {
    name: 'Amitriptyline Hydrochloride (amitriptyline hydrochloride)',
    value: 'Amitriptyline Hydrochloride (amitriptyline hydrochloride)',
  },
  {
    name: 'Ammonul (sodium benzoate/sodium phenylacelate)',
    value: 'Ammonul (sodium benzoate/sodium phenylacelate)',
  },
  { name: 'Amnesteem (isotretinoin)', value: 'Amnesteem (isotretinoin)' },
  { name: 'Amoxapine (amoxapine)', value: 'Amoxapine (amoxapine)' },
  {
    name: 'Amoxicillin and Clavulanate Potassium Powder (amoxicillin/clavulanate potassium)',
    value:
      'Amoxicillin and Clavulanate Potassium Powder (amoxicillin/clavulanate potassium)',
  },
  {
    name: 'Amoxicillin Powder for Suspension (amoxicillin)',
    value: 'Amoxicillin Powder for Suspension (amoxicillin)',
  },
  {
    name: 'Amoxicillin Tablets (amoxicillin)',
    value: 'Amoxicillin Tablets (amoxicillin)',
  },
  { name: 'Amphadase (hyaluronidase)', value: 'Amphadase (hyaluronidase)' },
  {
    name: 'Amphotericin B (amphotericin B)',
    value: 'Amphotericin B (amphotericin B)',
  },
  {
    name: 'Ampicillin Capsules and Oral Suspension (ampicillin trihydrate)',
    value: 'Ampicillin Capsules and Oral Suspension (ampicillin trihydrate)',
  },
  {
    name: 'Ampicillin for Injection (ampicillin)',
    value: 'Ampicillin for Injection (ampicillin)',
  },
  { name: 'Ampyra (dalfampridine)', value: 'Ampyra (dalfampridine)' },
  {
    name: 'Amrix (cyclobenzaprine hydrochloride)',
    value: 'Amrix (cyclobenzaprine hydrochloride)',
  },
  {
    name: 'Amturnide (aliskiren/amlodipine/hydrochlorothiazide)',
    value: 'Amturnide (aliskiren/amlodipine/hydrochlorothiazide)',
  },
  {
    name: 'Amytal Sodium (amobarbital sodium)',
    value: 'Amytal Sodium (amobarbital sodium)',
  },
  { name: 'Anadrol-50 (oxymetholone)', value: 'Anadrol-50 (oxymetholone)' },
  {
    name: 'Anafranil (clomipramine hydrochloride)',
    value: 'Anafranil (clomipramine hydrochloride)',
  },
  { name: 'Anagrelide (anagrelide)', value: 'Anagrelide (anagrelide)' },
  {
    name: 'Analpram HC Cream 2.5% (hydrocortisone acetate/pramoxine hydrochloride)',
    value:
      'Analpram HC Cream 2.5% (hydrocortisone acetate/pramoxine hydrochloride)',
  },
  {
    name: 'Analpram HC Cream, 1% (hydrocortisone acetate/pramoxine hydrochloride)',
    value:
      'Analpram HC Cream, 1% (hydrocortisone acetate/pramoxine hydrochloride)',
  },
  {
    name: 'Analpram HC Lotion (hydrocortisone acetate/pramoxine hydrochloride)',
    value:
      'Analpram HC Lotion (hydrocortisone acetate/pramoxine hydrochloride)',
  },
  {
    name: 'Anaprox/EC-Naprosyn/Naprosyn (naproxen); (naproxen sodium)',
    value: 'Anaprox/EC-Naprosyn/Naprosyn (naproxen); (naproxen sodium)',
  },
  {
    name: "Anascorp (centruroides (scorpion) immune F (ab')2 (equine))",
    value: "Anascorp (centruroides (scorpion) immune F (ab')2 (equine))",
  },
  {
    name: "Anavip (crotalidae immune F (ab')2 (equine))",
    value: "Anavip (crotalidae immune F (ab')2 (equine))",
  },
  { name: 'Anbesol (benzocaine)', value: 'Anbesol (benzocaine)' },
  { name: 'Anbesol Baby (benzocaine)', value: 'Anbesol Baby (benzocaine)' },
  { name: 'Ancobon (flucytosine)', value: 'Ancobon (flucytosine)' },
  { name: 'Androderm (testosterone)', value: 'Androderm (testosterone)' },
  {
    name: 'AndroGel 1.62% (testosterone)',
    value: 'AndroGel 1.62% (testosterone)',
  },
  { name: 'AndroGel 1% (testosterone)', value: 'AndroGel 1% (testosterone)' },
  {
    name: 'Android (methyltestosterone)',
    value: 'Android (methyltestosterone)',
  },
  { name: 'Androxy (fluoxymesterone)', value: 'Androxy (fluoxymesterone)' },
  {
    name: 'Anectine (succinylcholine chloride)',
    value: 'Anectine (succinylcholine chloride)',
  },
  {
    name: 'Angeliq (drospirenone/estradiol)',
    value: 'Angeliq (drospirenone/estradiol)',
  },
  { name: 'Angiomax (bivalirudin)', value: 'Angiomax (bivalirudin)' },
  {
    name: 'Anoni Juice (dietary supplement)',
    value: 'Anoni Juice (dietary supplement)',
  },
  {
    name: 'Anoro Ellipta (umeclidinium/vilanterol)',
    value: 'Anoro Ellipta (umeclidinium/vilanterol)',
  },
  { name: 'Antabuse (disulfiram)', value: 'Antabuse (disulfiram)' },
  { name: 'Antara (fenofibrate)', value: 'Antara (fenofibrate)' },
  {
    name: 'Antivert (meclizine hydrochloride)',
    value: 'Antivert (meclizine hydrochloride)',
  },
  {
    name: 'Anusol-HC Cream (hydrocortisone)',
    value: 'Anusol-HC Cream (hydrocortisone)',
  },
  {
    name: 'Anusol-HC Suppositories (hydrocortisone acetate)',
    value: 'Anusol-HC Suppositories (hydrocortisone acetate)',
  },
  {
    name: 'Anzemet Injection (dolasetron mesylate)',
    value: 'Anzemet Injection (dolasetron mesylate)',
  },
  {
    name: 'Anzemet Tablets (dolasetron mesylate)',
    value: 'Anzemet Tablets (dolasetron mesylate)',
  },
  {
    name: 'ApexiCon (diflorasone diacetate)',
    value: 'ApexiCon (diflorasone diacetate)',
  },
  {
    name: 'ApexiCon E (diflorasone diacetate)',
    value: 'ApexiCon E (diflorasone diacetate)',
  },
  {
    name: 'Apidra (insulin glulisine (rDNA origin))',
    value: 'Apidra (insulin glulisine (rDNA origin))',
  },
  {
    name: 'Aplenzin (bupropion hydrobromide)',
    value: 'Aplenzin (bupropion hydrobromide)',
  },
  {
    name: 'Aplisol (tuberculin purified protein derivative, diluted)',
    value: 'Aplisol (tuberculin purified protein derivative, diluted)',
  },
  {
    name: 'Apokyn (apomorphine hydrochloride)',
    value: 'Apokyn (apomorphine hydrochloride)',
  },
  {
    name: 'Apri (desogestrel/ethinyl estradiol)',
    value: 'Apri (desogestrel/ethinyl estradiol)',
  },
  { name: 'Apriso (mesalamine)', value: 'Apriso (mesalamine)' },
  {
    name: 'Aptensio XR (methylphenidate hydrochloride)',
    value: 'Aptensio XR (methylphenidate hydrochloride)',
  },
  {
    name: 'Aptiom (eslicarbazepine acetate)',
    value: 'Aptiom (eslicarbazepine acetate)',
  },
  { name: 'Aptivus (tipranavir)', value: 'Aptivus (tipranavir)' },
  {
    name: 'Aquasol A (vitamin A palmitate)',
    value: 'Aquasol A (vitamin A palmitate)',
  },
  {
    name: 'Aralast NP (alpha1-proteinase inhibitor (human))',
    value: 'Aralast NP (alpha1-proteinase inhibitor (human))',
  },
  {
    name: 'Aranelle (ethinyl estradiol/norethindrone)',
    value: 'Aranelle (ethinyl estradiol/norethindrone)',
  },
  { name: 'Aranesp (darbepoetin alfa)', value: 'Aranesp (darbepoetin alfa)' },
  { name: 'Arava (leflunomide)', value: 'Arava (leflunomide)' },
  {
    name: 'Arbinoxa Tablets (carbinoxamine maleate)',
    value: 'Arbinoxa Tablets (carbinoxamine maleate)',
  },
  { name: 'Arcalyst (rilonacept)', value: 'Arcalyst (rilonacept)' },
  {
    name: 'Arcapta Neohaler (indacaterol)',
    value: 'Arcapta Neohaler (indacaterol)',
  },
  {
    name: 'Arestin (minocycline hydrochloride)',
    value: 'Arestin (minocycline hydrochloride)',
  },
  {
    name: 'Argatroban Injection 250 mg/2.5 mL (argatroban)',
    value: 'Argatroban Injection 250 mg/2.5 mL (argatroban)',
  },
  {
    name: 'Argatroban Injection 50 mg/50 mL (argatroban)',
    value: 'Argatroban Injection 50 mg/50 mL (argatroban)',
  },
  {
    name: 'Argatroban Injection in 0.9% Sodium Chloride (argatroban)',
    value: 'Argatroban Injection in 0.9% Sodium Chloride (argatroban)',
  },
  {
    name: 'Aricept (donepezil hydrochloride)',
    value: 'Aricept (donepezil hydrochloride)',
  },
  {
    name: 'Arikayce (amikacin liposome)',
    value: 'Arikayce (amikacin liposome)',
  },
  { name: 'Arimidex (anastrozole)', value: 'Arimidex (anastrozole)' },
  {
    name: 'Aristada (aripiprazole lauroxil)',
    value: 'Aristada (aripiprazole lauroxil)',
  },
  {
    name: 'Aristada Initio (aripiprazole lauroxil)',
    value: 'Aristada Initio (aripiprazole lauroxil)',
  },
  {
    name: 'Arixtra (fondaparinux sodium)',
    value: 'Arixtra (fondaparinux sodium)',
  },
  { name: 'Armour Thyroid (thyroid)', value: 'Armour Thyroid (thyroid)' },
  {
    name: 'Arnuity Ellipta (fluticasone furoate)',
    value: 'Arnuity Ellipta (fluticasone furoate)',
  },
  { name: 'Aromasin (exemestane)', value: 'Aromasin (exemestane)' },
  { name: 'Arranon (nelarabine)', value: 'Arranon (nelarabine)' },
  {
    name: 'Arthrotec (diclofenac sodium/misoprostol)',
    value: 'Arthrotec (diclofenac sodium/misoprostol)',
  },
  {
    name: 'Artiss (fibrin sealant (human))',
    value: 'Artiss (fibrin sealant (human))',
  },
  {
    name: 'Artiss Fibrin Sealant (fibrin sealant (human))',
    value: 'Artiss Fibrin Sealant (fibrin sealant (human))',
  },
  { name: 'Arymo ER (morphine sulfate)', value: 'Arymo ER (morphine sulfate)' },
  { name: 'Arzerra (ofatumumab)', value: 'Arzerra (ofatumumab)' },
  { name: 'Asacol HD (mesalamine)', value: 'Asacol HD (mesalamine)' },
  {
    name: 'Asmanex (mometasone furoate)',
    value: 'Asmanex (mometasone furoate)',
  },
  {
    name: 'Asmanex HFA (mometasone furoate)',
    value: 'Asmanex HFA (mometasone furoate)',
  },
  { name: 'Astagraf XL (tacrolimus)', value: 'Astagraf XL (tacrolimus)' },
  {
    name: 'Astepro (azelastine hydrochloride)',
    value: 'Astepro (azelastine hydrochloride)',
  },
  {
    name: 'Atacand (candesartan cilexetil)',
    value: 'Atacand (candesartan cilexetil)',
  },
  {
    name: 'Atacand HCT (candesartan cilexetil/hydrochlorothiazide)',
    value: 'Atacand HCT (candesartan cilexetil/hydrochlorothiazide)',
  },
  {
    name: 'Atelvia (risedronate sodium)',
    value: 'Atelvia (risedronate sodium)',
  },
  {
    name: 'Atgam (lymphocyte immune globulin, anti-thymocyte globulin (equine))',
    value:
      'Atgam (lymphocyte immune globulin, anti-thymocyte globulin (equine))',
  },
  {
    name: 'Ativan Injection (lorazepam)',
    value: 'Ativan Injection (lorazepam)',
  },
  { name: 'Ativan Tablets (lorazepam)', value: 'Ativan Tablets (lorazepam)' },
  { name: 'Atralin (tretinoin)', value: 'Atralin (tretinoin)' },
  {
    name: 'Atripla (efavirenz/emtricitabine/tenofovir disoproxil fumarate)',
    value: 'Atripla (efavirenz/emtricitabine/tenofovir disoproxil fumarate)',
  },
  {
    name: 'Atropine Sulfate Injection (atropine sulfate)',
    value: 'Atropine Sulfate Injection (atropine sulfate)',
  },
  {
    name: 'Atropine Sulfate Ophthalmic Solution (atropine sulfate)',
    value: 'Atropine Sulfate Ophthalmic Solution (atropine sulfate)',
  },
  {
    name: 'Atrovent HFA (ipratropium bromide)',
    value: 'Atrovent HFA (ipratropium bromide)',
  },
  {
    name: 'Atrovent Nasal Spray 0.03% (ipratropium bromide)',
    value: 'Atrovent Nasal Spray 0.03% (ipratropium bromide)',
  },
  {
    name: 'Atrovent Nasal Spray 0.06% (ipratropium bromide)',
    value: 'Atrovent Nasal Spray 0.06% (ipratropium bromide)',
  },
  {
    name: 'ATryn (antithrombin (recombinant))',
    value: 'ATryn (antithrombin (recombinant))',
  },
  { name: 'Aubagio (teriflunomide)', value: 'Aubagio (teriflunomide)' },
  {
    name: 'Aubra EQ (ethinyl estradiol/levonorgestrel)',
    value: 'Aubra EQ (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'Augmentin Chewable Tablets, Powder for Oral Suspension, and Tablets (amoxicillin/clavulanate potassium)',
    value:
      'Augmentin Chewable Tablets, Powder for Oral Suspension, and Tablets (amoxicillin/clavulanate potassium)',
  },
  {
    name: 'Augmentin XR (amoxicillin/clavulanate potassium)',
    value: 'Augmentin XR (amoxicillin/clavulanate potassium)',
  },
  { name: 'Auryxia (ferric citrate)', value: 'Auryxia (ferric citrate)' },
  { name: 'Austedo (deutetrabenazine)', value: 'Austedo (deutetrabenazine)' },
  { name: 'Auvi-Q (epinephrine)', value: 'Auvi-Q (epinephrine)' },
  { name: 'Avage (tazarotene)', value: 'Avage (tazarotene)' },
  {
    name: 'Avalide (hydrochlorothiazide/irbesartan)',
    value: 'Avalide (hydrochlorothiazide/irbesartan)',
  },
  {
    name: 'Avandamet (metformin hydrochloride/rosiglitazone maleate)',
    value: 'Avandamet (metformin hydrochloride/rosiglitazone maleate)',
  },
  {
    name: 'Avandaryl (glimepiride/rosiglitazone maleate)',
    value: 'Avandaryl (glimepiride/rosiglitazone maleate)',
  },
  {
    name: 'Avandia (rosiglitazone maleate)',
    value: 'Avandia (rosiglitazone maleate)',
  },
  { name: 'Avapro (irbesartan)', value: 'Avapro (irbesartan)' },
  {
    name: 'Avar Cleanser (sodium sulfacetamide/sulfur)',
    value: 'Avar Cleanser (sodium sulfacetamide/sulfur)',
  },
  {
    name: 'Avar Foam (sodium sulfacetamide/sulfur)',
    value: 'Avar Foam (sodium sulfacetamide/sulfur)',
  },
  {
    name: 'Avar LS Cleanser (sodium sulfacetamide/sulfur)',
    value: 'Avar LS Cleanser (sodium sulfacetamide/sulfur)',
  },
  {
    name: 'Avar LS Foam (sodium sulfacetamide/sulfur)',
    value: 'Avar LS Foam (sodium sulfacetamide/sulfur)',
  },
  {
    name: 'Avar-e Emollient (sodium sulfacetamide/sulfur)',
    value: 'Avar-e Emollient (sodium sulfacetamide/sulfur)',
  },
  {
    name: 'Avar-e LS Emollient (sodium sulfacetamide/sulfur)',
    value: 'Avar-e LS Emollient (sodium sulfacetamide/sulfur)',
  },
  { name: 'Avastin (bevacizumab)', value: 'Avastin (bevacizumab)' },
  { name: 'AVC (sulfanilamide)', value: 'AVC (sulfanilamide)' },
  {
    name: 'Aveed (testosterone undecanoate)',
    value: 'Aveed (testosterone undecanoate)',
  },
  {
    name: 'Avelox (moxifloxacin hydrochloride)',
    value: 'Avelox (moxifloxacin hydrochloride)',
  },
  {
    name: 'Aviane 28 (ethinyl estradiol/levonorgestrel)',
    value: 'Aviane 28 (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'Avinza (morphine sulfate)', value: 'Avinza (morphine sulfate)' },
  { name: 'Avita Cream (tretinoin)', value: 'Avita Cream (tretinoin)' },
  { name: 'Avita Gel (tretinoin)', value: 'Avita Gel (tretinoin)' },
  { name: 'Avodart (dutasteride)', value: 'Avodart (dutasteride)' },
  { name: 'Avonex (interferon beta-1a)', value: 'Avonex (interferon beta-1a)' },
  {
    name: 'Avycaz (avibactam/ceftazidime)',
    value: 'Avycaz (avibactam/ceftazidime)',
  },
  { name: 'Axert (almotriptan malate)', value: 'Axert (almotriptan malate)' },
  { name: 'Axiron (testosterone)', value: 'Axiron (testosterone)' },
  {
    name: 'Aygestin (norethindrone acetate)',
    value: 'Aygestin (norethindrone acetate)',
  },
  {
    name: 'Ayuna (ethinyl estradiol/levonorgestrel)',
    value: 'Ayuna (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'Azactam for Injection in Galaxy Plastic Container (aztreonam)',
    value: 'Azactam for Injection in Galaxy Plastic Container (aztreonam)',
  },
  {
    name: 'Azactam Injection (aztreonam)',
    value: 'Azactam Injection (aztreonam)',
  },
  { name: 'Azasan (azathioprine)', value: 'Azasan (azathioprine)' },
  { name: 'AzaSite (azithromycin)', value: 'AzaSite (azithromycin)' },
  { name: 'Azedra (iobenguane I 131)', value: 'Azedra (iobenguane I 131)' },
  { name: 'Azelex (azelaic acid)', value: 'Azelex (azelaic acid)' },
  {
    name: 'Azilect (rasagiline mesylate)',
    value: 'Azilect (rasagiline mesylate)',
  },
  { name: 'Azithromycin (azithromycin)', value: 'Azithromycin (azithromycin)' },
  {
    name: 'AZO Urinary Pain Relief Maximum Strength (phenazopyridine hydrochloride)',
    value:
      'AZO Urinary Pain Relief Maximum Strength (phenazopyridine hydrochloride)',
  },
  { name: 'Azopt (brinzolamide)', value: 'Azopt (brinzolamide)' },
  {
    name: 'Azor (amlodipine/olmesartan medoxomil)',
    value: 'Azor (amlodipine/olmesartan medoxomil)',
  },
  { name: 'Azulfidine (sulfasalazine)', value: 'Azulfidine (sulfasalazine)' },
  {
    name: 'Azulfidine EN-tabs (sulfasalazine)',
    value: 'Azulfidine EN-tabs (sulfasalazine)',
  },
  {
    name: 'Azurette (desogestrel/ethinyl estradiol)',
    value: 'Azurette (desogestrel/ethinyl estradiol)',
  },
  {
    name: 'Bacitracin Injection (bacitracin)',
    value: 'Bacitracin Injection (bacitracin)',
  },
  {
    name: 'Bacitracin Ointment (bacitracin)',
    value: 'Bacitracin Ointment (bacitracin)',
  },
  {
    name: 'Bacitracin Zinc and Polymyxin B Sulfate (bacitracin zinc/polymyxin B sulfate)',
    value:
      'Bacitracin Zinc and Polymyxin B Sulfate (bacitracin zinc/polymyxin B sulfate)',
  },
  { name: 'Baclofen (baclofen)', value: 'Baclofen (baclofen)' },
  {
    name: 'Bactrim/Bactrim DS (sulfamethoxazole/trimethoprim)',
    value: 'Bactrim/Bactrim DS (sulfamethoxazole/trimethoprim)',
  },
  {
    name: 'Bactroban Cream (mupirocin calcium)',
    value: 'Bactroban Cream (mupirocin calcium)',
  },
  {
    name: 'Bactroban Nasal (mupirocin calcium)',
    value: 'Bactroban Nasal (mupirocin calcium)',
  },
  {
    name: 'Bactroban Ointment (mupirocin)',
    value: 'Bactroban Ointment (mupirocin)',
  },
  { name: 'Balversa (erdafitinib)', value: 'Balversa (erdafitinib)' },
  {
    name: 'Balziva (ethinyl estradiol/norethindrone)',
    value: 'Balziva (ethinyl estradiol/norethindrone)',
  },
  { name: 'Banzel (rufinamide)', value: 'Banzel (rufinamide)' },
  { name: 'Baqsimi (glucagon)', value: 'Baqsimi (glucagon)' },
  { name: 'Baraclude (entecavir)', value: 'Baraclude (entecavir)' },
  { name: 'Basaglar (insulin glargine)', value: 'Basaglar (insulin glargine)' },
  { name: 'Bavencio (avelumab)', value: 'Bavencio (avelumab)' },
  { name: 'Baxdela (delafloxacin)', value: 'Baxdela (delafloxacin)' },
  {
    name: 'Bayer Chewable Aspirin Regimen (aspirin)',
    value: 'Bayer Chewable Aspirin Regimen (aspirin)',
  },
  {
    name: 'Bayer Low Dose Aspirin Regimen (aspirin)',
    value: 'Bayer Low Dose Aspirin Regimen (aspirin)',
  },
  { name: 'BCG Vaccine (BCG live)', value: 'BCG Vaccine (BCG live)' },
  { name: 'Bebulin (factor IX complex)', value: 'Bebulin (factor IX complex)' },
  {
    name: 'Beconase AQ (beclomethasone dipropionate monohydrate)',
    value: 'Beconase AQ (beclomethasone dipropionate monohydrate)',
  },
  { name: 'Belbuca (buprenorphine)', value: 'Belbuca (buprenorphine)' },
  { name: 'Beleodaq (belinostat)', value: 'Beleodaq (belinostat)' },
  { name: 'Belsomra (suvorexant)', value: 'Belsomra (suvorexant)' },
  {
    name: 'Belviq (lorcaserin hydrochloride)',
    value: 'Belviq (lorcaserin hydrochloride)',
  },
  {
    name: 'Belviq XR (lorcaserin hydrochloride)',
    value: 'Belviq XR (lorcaserin hydrochloride)',
  },
  {
    name: 'Benadryl Allergy Liqui-Gels (diphenhydramine hydrochloride)',
    value: 'Benadryl Allergy Liqui-Gels (diphenhydramine hydrochloride)',
  },
  {
    name: 'Benadryl Allergy Ultratabs (diphenhydramine hydrochloride)',
    value: 'Benadryl Allergy Ultratabs (diphenhydramine hydrochloride)',
  },
  {
    name: 'Benazepril Hydrochloride (benazepril hydrochloride)',
    value: 'Benazepril Hydrochloride (benazepril hydrochloride)',
  },
  {
    name: 'Bendeka (bendamustine hydrochloride)',
    value: 'Bendeka (bendamustine hydrochloride)',
  },
  {
    name: 'BeneFIX (coagulation factor IX (recombinant))',
    value: 'BeneFIX (coagulation factor IX (recombinant))',
  },
  {
    name: 'Benicar (olmesartan medoxomil)',
    value: 'Benicar (olmesartan medoxomil)',
  },
  {
    name: 'Benicar HCT (hydrochlorothiazide/olmesartan medoxomil)',
    value: 'Benicar HCT (hydrochlorothiazide/olmesartan medoxomil)',
  },
  { name: 'Benlysta (belimumab)', value: 'Benlysta (belimumab)' },
  {
    name: 'Bentyl (dicyclomine hydrochloride)',
    value: 'Bentyl (dicyclomine hydrochloride)',
  },
  {
    name: 'BenzaClin (benzoyl peroxide/clindamycin)',
    value: 'BenzaClin (benzoyl peroxide/clindamycin)',
  },
  {
    name: 'Benzamycin (benzoyl peroxide/erythromycin)',
    value: 'Benzamycin (benzoyl peroxide/erythromycin)',
  },
  {
    name: 'BenzEFoam (benzoyl peroxide)',
    value: 'BenzEFoam (benzoyl peroxide)',
  },
  {
    name: 'BenzEFoam Ultra (benzoyl peroxide)',
    value: 'BenzEFoam Ultra (benzoyl peroxide)',
  },
  { name: 'Benznidazole (benznidazole)', value: 'Benznidazole (benznidazole)' },
  { name: 'Benzonatate (benzonatate)', value: 'Benzonatate (benzonatate)' },
  {
    name: 'Benzoyl Peroxide Lotion (benzoyl peroxide)',
    value: 'Benzoyl Peroxide Lotion (benzoyl peroxide)',
  },
  {
    name: 'Benzphetamine Hydrochloride (benzphetamine hydrochloride)',
    value: 'Benzphetamine Hydrochloride (benzphetamine hydrochloride)',
  },
  {
    name: 'Benztropine Mesylate (benztropine mesylate)',
    value: 'Benztropine Mesylate (benztropine mesylate)',
  },
  { name: 'BEOVU (brolucizumab)', value: 'BEOVU (brolucizumab)' },
  {
    name: 'Bepreve (bepotastine besilate)',
    value: 'Bepreve (bepotastine besilate)',
  },
  {
    name: 'Berinert (C1 esterase inhibitor (human))',
    value: 'Berinert (C1 esterase inhibitor (human))',
  },
  { name: 'Besivance (besifloxacin)', value: 'Besivance (besifloxacin)' },
  {
    name: 'Besponsa (inotuzumab ozogamicin)',
    value: 'Besponsa (inotuzumab ozogamicin)',
  },
  {
    name: 'Betadine 5% (povidone iodine)',
    value: 'Betadine 5% (povidone iodine)',
  },
  {
    name: 'Betagan (levobunolol hydrochloride)',
    value: 'Betagan (levobunolol hydrochloride)',
  },
  {
    name: 'Betamethasone Dipropionate Cream, Lotion, Ointment (betamethasone dipropionate)',
    value:
      'Betamethasone Dipropionate Cream, Lotion, Ointment (betamethasone dipropionate)',
  },
  {
    name: 'Betamethasone Valerate (betamethasone valerate)',
    value: 'Betamethasone Valerate (betamethasone valerate)',
  },
  {
    name: 'Betapace/Betapace AF (sotalol hydrochloride)',
    value: 'Betapace/Betapace AF (sotalol hydrochloride)',
  },
  {
    name: 'Betaseron (interferon beta-1b)',
    value: 'Betaseron (interferon beta-1b)',
  },
  {
    name: 'Betaxolol Ophthalmic Solution (betaxolol hydrochloride)',
    value: 'Betaxolol Ophthalmic Solution (betaxolol hydrochloride)',
  },
  {
    name: 'Betaxolol Tablets (betaxolol hydrochloride)',
    value: 'Betaxolol Tablets (betaxolol hydrochloride)',
  },
  { name: 'Bethkis (tobramycin)', value: 'Bethkis (tobramycin)' },
  { name: 'Betimol (timolol)', value: 'Betimol (timolol)' },
  {
    name: 'Betoptic S (betaxolol hydrochloride)',
    value: 'Betoptic S (betaxolol hydrochloride)',
  },
  {
    name: 'Bevespi Aerosphere (formoterol fumarate/glycopyrrolate)',
    value: 'Bevespi Aerosphere (formoterol fumarate/glycopyrrolate)',
  },
  { name: 'Bevyxxa (betrixaban)', value: 'Bevyxxa (betrixaban)' },
  { name: 'Bexarotene (bexarotene)', value: 'Bexarotene (bexarotene)' },
  {
    name: 'Bexsero (meningococcal group B vaccine)',
    value: 'Bexsero (meningococcal group B vaccine)',
  },
  {
    name: 'Beyaz (drospirenone/ethinyl estradiol/levomefolate calcium)',
    value: 'Beyaz (drospirenone/ethinyl estradiol/levomefolate calcium)',
  },
  { name: 'Biaxin (clarithromycin)', value: 'Biaxin (clarithromycin)' },
  {
    name: 'Bicillin C-R (penicillin G benzathine/penicillin G procaine)',
    value: 'Bicillin C-R (penicillin G benzathine/penicillin G procaine)',
  },
  {
    name: 'Bicillin C-R 900/300 (penicillin G benzathine/penicillin G procaine)',
    value:
      'Bicillin C-R 900/300 (penicillin G benzathine/penicillin G procaine)',
  },
  {
    name: 'Bicillin L-A (penicillin G benzathine)',
    value: 'Bicillin L-A (penicillin G benzathine)',
  },
  { name: 'BiCNU (carmustine)', value: 'BiCNU (carmustine)' },
  {
    name: 'BiDil (hydralazine hydrochloride/isosorbide dinitrate)',
    value: 'BiDil (hydralazine hydrochloride/isosorbide dinitrate)',
  },
  {
    name: 'Biktarvy (bictegravir/emtricitabine/ tenofovir alafenamide)',
    value: 'Biktarvy (bictegravir/emtricitabine/ tenofovir alafenamide)',
  },
  { name: 'Biltricide (praziquantel)', value: 'Biltricide (praziquantel)' },
  {
    name: 'Binosto (alendronate sodium)',
    value: 'Binosto (alendronate sodium)',
  },
  {
    name: 'Bion Tears (dextran 70/hypromellose)',
    value: 'Bion Tears (dextran 70/hypromellose)',
  },
  {
    name: 'BioThrax (anthrax vaccine adsorbed)',
    value: 'BioThrax (anthrax vaccine adsorbed)',
  },
  {
    name: 'Bivigam (immune globulin intravenous (human))',
    value: 'Bivigam (immune globulin intravenous (human))',
  },
  {
    name: 'Blenrep (belantamab mafodotin-blmf)',
    value: 'Blenrep (belantamab mafodotin-blmf)',
  },
  {
    name: 'Bleomycin (bleomycin sulfate)',
    value: 'Bleomycin (bleomycin sulfate)',
  },
  {
    name: 'Bleph-10 (sulfacetamide sodium)',
    value: 'Bleph-10 (sulfacetamide sodium)',
  },
  {
    name: 'Blephamide Ophthalmic Ointment (prednisolone acetate/sulfacetamide sodium)',
    value:
      'Blephamide Ophthalmic Ointment (prednisolone acetate/sulfacetamide sodium)',
  },
  {
    name: 'Blephamide Ophthalmic Suspension (prednisolone acetate/sulfacetamide sodium)',
    value:
      'Blephamide Ophthalmic Suspension (prednisolone acetate/sulfacetamide sodium)',
  },
  { name: 'Blincyto (blinatumomab)', value: 'Blincyto (blinatumomab)' },
  {
    name: 'Blisovi 24 Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
    value:
      'Blisovi 24 Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
  },
  {
    name: 'Blossom Sakura NMN 15000 (dietary supplement)',
    value: 'Blossom Sakura NMN 15000 (dietary supplement)',
  },
  {
    name: 'Bloxiverz (neostigmine methylsulfate)',
    value: 'Bloxiverz (neostigmine methylsulfate)',
  },
  {
    name: 'Boniva Injection (ibandronate)',
    value: 'Boniva Injection (ibandronate)',
  },
  {
    name: 'Boniva Tablets (ibandronate sodium)',
    value: 'Boniva Tablets (ibandronate sodium)',
  },
  {
    name: 'Bontril PDM (phendimetrazine tartrate)',
    value: 'Bontril PDM (phendimetrazine tartrate)',
  },
  {
    name: 'Boostrix (tetanus toxoid, reduced diphtheria toxoid and acellular pertussis vac etc.',
    value:
      'Boostrix (tetanus toxoid, reduced diphtheria toxoid and acellular pertussis vac etc.',
  },
  { name: 'Bosulif (bosutinib)', value: 'Bosulif (bosutinib)' },
  { name: 'Botox (onabotulinumtoxinA)', value: 'Botox (onabotulinumtoxinA)' },
  {
    name: 'Botox Cosmetic (onabotulinumtoxinA)',
    value: 'Botox Cosmetic (onabotulinumtoxinA)',
  },
  { name: 'Braftovi (encorafenib)', value: 'Braftovi (encorafenib)' },
  { name: 'Bravelle (urofollitropin)', value: 'Bravelle (urofollitropin)' },
  {
    name: 'Breo Ellipta (fluticasone furoate/vilanterol)',
    value: 'Breo Ellipta (fluticasone furoate/vilanterol)',
  },
  {
    name: 'Brevibloc (esmolol hydrochloride)',
    value: 'Brevibloc (esmolol hydrochloride)',
  },
  {
    name: 'Brevicon/Norinyl (ethinyl estradiol/norethindrone); (mestranol/norethindrone)',
    value:
      'Brevicon/Norinyl (ethinyl estradiol/norethindrone); (mestranol/norethindrone)',
  },
  {
    name: 'Brevital Sodium (methohexital sodium)',
    value: 'Brevital Sodium (methohexital sodium)',
  },
  { name: 'Bridion (sugammadex)', value: 'Bridion (sugammadex)' },
  { name: 'Brilinta (ticagrelor)', value: 'Brilinta (ticagrelor)' },
  {
    name: 'Brimonidine Tartrate (brimonidine tartrate)',
    value: 'Brimonidine Tartrate (brimonidine tartrate)',
  },
  { name: 'Brineura (cerliponase alfa)', value: 'Brineura (cerliponase alfa)' },
  { name: 'Brisdelle (paroxetine)', value: 'Brisdelle (paroxetine)' },
  { name: 'Briviact (brivaracetam)', value: 'Briviact (brivaracetam)' },
  {
    name: 'Bromfed DM (brompheniramine maleate/dextromethorphan hydrobromide/pseudoephedrine etc.',
    value:
      'Bromfed DM (brompheniramine maleate/dextromethorphan hydrobromide/pseudoephedrine etc.',
  },
  { name: 'BromSite (bromfenac)', value: 'BromSite (bromfenac)' },
  {
    name: 'Brovana (arformoterol tartrate)',
    value: 'Brovana (arformoterol tartrate)',
  },
  {
    name: 'Bryhali (halobetasol propionate)',
    value: 'Bryhali (halobetasol propionate)',
  },
  {
    name: 'BSS PLUS (balanced salt solution/bicarbonate/dextrose/glutathione)',
    value: 'BSS PLUS (balanced salt solution/bicarbonate/dextrose/glutathione)',
  },
  {
    name: 'Bumetanide Injection (bumetanide)',
    value: 'Bumetanide Injection (bumetanide)',
  },
  {
    name: 'Bumetanide Tablets (bumetanide)',
    value: 'Bumetanide Tablets (bumetanide)',
  },
  {
    name: 'Buminate 25% (albumin (human))',
    value: 'Buminate 25% (albumin (human))',
  },
  {
    name: 'Buminate 5% (albumin (human))',
    value: 'Buminate 5% (albumin (human))',
  },
  {
    name: 'Bunavail (buprenorphine/naloxone)',
    value: 'Bunavail (buprenorphine/naloxone)',
  },
  {
    name: 'Bupap (acetaminophen/butalbital)',
    value: 'Bupap (acetaminophen/butalbital)',
  },
  {
    name: 'Buphenyl (sodium phenylbutyrate)',
    value: 'Buphenyl (sodium phenylbutyrate)',
  },
  {
    name: 'Buprenex (buprenorphine hydrochloride)',
    value: 'Buprenex (buprenorphine hydrochloride)',
  },
  {
    name: 'Buprenorphine Hydrochloride (buprenorphine hydrochloride)',
    value: 'Buprenorphine Hydrochloride (buprenorphine hydrochloride)',
  },
  {
    name: 'Buspirone Hydrochloride Tablets, USP, 7.5 mg (buspirone hydrochloride)',
    value:
      'Buspirone Hydrochloride Tablets, USP, 7.5 mg (buspirone hydrochloride)',
  },
  { name: 'Busulfan (busulfan)', value: 'Busulfan (busulfan)' },
  { name: 'Busulfex (busulfan)', value: 'Busulfex (busulfan)' },
  {
    name: 'Butisol Sodium (butabarbital sodium)',
    value: 'Butisol Sodium (butabarbital sodium)',
  },
  {
    name: 'Butorphanol Tartrate Nasal Solution (butorphanol tartrate)',
    value: 'Butorphanol Tartrate Nasal Solution (butorphanol tartrate)',
  },
  { name: 'Butrans (buprenorphine)', value: 'Butrans (buprenorphine)' },
  { name: 'Bydureon (exenatide)', value: 'Bydureon (exenatide)' },
  { name: 'Bydureon BCise (exenatide)', value: 'Bydureon BCise (exenatide)' },
  { name: 'Byetta (exenatide)', value: 'Byetta (exenatide)' },
  { name: 'Bystolic (nebivolol)', value: 'Bystolic (nebivolol)' },
  {
    name: 'Byvalson (nebivolol/valsartan)',
    value: 'Byvalson (nebivolol/valsartan)',
  },
  { name: 'Cabergoline (cabergoline)', value: 'Cabergoline (cabergoline)' },
  { name: 'Cablivi (caplacizumab-yhdp)', value: 'Cablivi (caplacizumab-yhdp)' },
  { name: 'Cabometyx (cabozantinib)', value: 'Cabometyx (cabozantinib)' },
  {
    name: 'Caduet (amlodipine besylate/atorvastatin calcium)',
    value: 'Caduet (amlodipine besylate/atorvastatin calcium)',
  },
  { name: 'Cafcit (caffeine citrate)', value: 'Cafcit (caffeine citrate)' },
  {
    name: 'Caffeine Citrate (caffeine citrate)',
    value: 'Caffeine Citrate (caffeine citrate)',
  },
  {
    name: 'Calan (verapamil hydrochloride)',
    value: 'Calan (verapamil hydrochloride)',
  },
  {
    name: 'Calan SR (verapamil hydrochloride)',
    value: 'Calan SR (verapamil hydrochloride)',
  },
  {
    name: 'Calcipotriene Ointment (calcipotriene)',
    value: 'Calcipotriene Ointment (calcipotriene)',
  },
  {
    name: 'Calcipotriene Topical Solution (calcipotriene)',
    value: 'Calcipotriene Topical Solution (calcipotriene)',
  },
  {
    name: 'Calcium Acetate (calcium acetate)',
    value: 'Calcium Acetate (calcium acetate)',
  },
  {
    name: 'Calcium Disodium Versenate (edetate calcium disodium)',
    value: 'Calcium Disodium Versenate (edetate calcium disodium)',
  },
  { name: 'Caldolor (ibuprofen)', value: 'Caldolor (ibuprofen)' },
  {
    name: 'CalerieHealth Alpha Hope (dietary supplement)',
    value: 'CalerieHealth Alpha Hope (dietary supplement)',
  },
  {
    name: 'CalerieHealth Aqua SOD Probiotics (dietary supplement)',
    value: 'CalerieHealth Aqua SOD Probiotics (dietary supplement)',
  },
  {
    name: 'CalerieHealth Glow (dietary supplement)',
    value: 'CalerieHealth Glow (dietary supplement)',
  },
  {
    name: 'CalerieHealth Kids Essential (dietary supplement)',
    value: 'CalerieHealth Kids Essential (dietary supplement)',
  },
  {
    name: 'CalerieHealth Life Span Capsules (dietary supplement)',
    value: 'CalerieHealth Life Span Capsules (dietary supplement)',
  },
  {
    name: 'CalerieHealth MaxMen (dietary supplement)',
    value: 'CalerieHealth MaxMen (dietary supplement)',
  },
  {
    name: 'CalerieHealth Rejuve Medley Drink (dietary supplement)',
    value: 'CalerieHealth Rejuve Medley Drink (dietary supplement)',
  },
  {
    name: 'CalerieHealth SOD + NMN Chewable Tablets (dietary supplement)',
    value: 'CalerieHealth SOD + NMN Chewable Tablets (dietary supplement)',
  },
  {
    name: 'CalerieHealth VitalBlue (dietary supplement)',
    value: 'CalerieHealth VitalBlue (dietary supplement)',
  },
  { name: 'Calquence (acalabrutinib)', value: 'Calquence (acalabrutinib)' },
  {
    name: 'Cambia (diclofenac potassium)',
    value: 'Cambia (diclofenac potassium)',
  },
  { name: 'Camila (norethindrone)', value: 'Camila (norethindrone)' },
  {
    name: 'Campral (acamprosate calcium)',
    value: 'Campral (acamprosate calcium)',
  },
  {
    name: 'Camptosar (irinotecan hydrochloride)',
    value: 'Camptosar (irinotecan hydrochloride)',
  },
  {
    name: 'Camrese (ethinyl estradiol/levonorgestrel)',
    value: 'Camrese (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'CamreseLo (ethinyl estradiol/levonorgestrel)',
    value: 'CamreseLo (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'Canasa (mesalamine)', value: 'Canasa (mesalamine)' },
  {
    name: 'Cancidas (caspofungin acetate)',
    value: 'Cancidas (caspofungin acetate)',
  },
  {
    name: 'Cantil (mepenzolate bromide)',
    value: 'Cantil (mepenzolate bromide)',
  },
  {
    name: 'Capastat Sulfate (capreomycin sulfate)',
    value: 'Capastat Sulfate (capreomycin sulfate)',
  },
  {
    name: 'Capex (fluocinolone acetonide)',
    value: 'Capex (fluocinolone acetonide)',
  },
  { name: 'Caprelsa (vandetanib)', value: 'Caprelsa (vandetanib)' },
  { name: 'Captopril (captopril)', value: 'Captopril (captopril)' },
  {
    name: 'Captopril and Hydrochlorothiazide (captopril/hydrochlorothiazide)',
    value: 'Captopril and Hydrochlorothiazide (captopril/hydrochlorothiazide)',
  },
  { name: 'Carac (fluorouracil)', value: 'Carac (fluorouracil)' },
  {
    name: 'Carafate Suspension (sucralfate)',
    value: 'Carafate Suspension (sucralfate)',
  },
  {
    name: 'Carafate Tablets (sucralfate)',
    value: 'Carafate Tablets (sucralfate)',
  },
  { name: 'Carbaglu (carglumic acid)', value: 'Carbaglu (carglumic acid)' },
  {
    name: 'Carbamazepine (carbamazepine)',
    value: 'Carbamazepine (carbamazepine)',
  },
  { name: 'Carbatrol (carbamazepine)', value: 'Carbatrol (carbamazepine)' },
  {
    name: 'Carbinoxamine Maleate (carbinoxamine maleate)',
    value: 'Carbinoxamine Maleate (carbinoxamine maleate)',
  },
  {
    name: 'Carbocaine (mepivacaine hydrochloride)',
    value: 'Carbocaine (mepivacaine hydrochloride)',
  },
  { name: 'Carboplatin (carboplatin)', value: 'Carboplatin (carboplatin)' },
  {
    name: 'Cardene I.V. Premixed Injection 20 mg (nicardipine hydrochloride)',
    value: 'Cardene I.V. Premixed Injection 20 mg (nicardipine hydrochloride)',
  },
  {
    name: 'Cardene I.V. Premixed Injection 40 mg (nicardipine hydrochloride)',
    value: 'Cardene I.V. Premixed Injection 40 mg (nicardipine hydrochloride)',
  },
  {
    name: 'Cardizem (diltiazem hydrochloride)',
    value: 'Cardizem (diltiazem hydrochloride)',
  },
  {
    name: 'Cardizem CD (diltiazem hydrochloride)',
    value: 'Cardizem CD (diltiazem hydrochloride)',
  },
  {
    name: 'Cardizem LA (diltiazem hydrochloride)',
    value: 'Cardizem LA (diltiazem hydrochloride)',
  },
  {
    name: 'Cardura (doxazosin mesylate)',
    value: 'Cardura (doxazosin mesylate)',
  },
  {
    name: 'Cardura XL (doxazosin mesylate)',
    value: 'Cardura XL (doxazosin mesylate)',
  },
  {
    name: 'Carimune NF (immune globulin intravenous (human))',
    value: 'Carimune NF (immune globulin intravenous (human))',
  },
  {
    name: 'Carisoprodol and Aspirin (aspirin/carisoprodol)',
    value: 'Carisoprodol and Aspirin (aspirin/carisoprodol)',
  },
  {
    name: 'Carisoprodol, Aspirin and Codeine Phosphate (aspirin/carisoprodol/codeine phosphate)',
    value:
      'Carisoprodol, Aspirin and Codeine Phosphate (aspirin/carisoprodol/codeine phosphate)',
  },
  {
    name: 'Carnitor Injection (levocarnitine)',
    value: 'Carnitor Injection (levocarnitine)',
  },
  {
    name: 'Carnitor Oral Solution, Tablets, and SF Sugar-Free Oral Solution (levocarnitine)',
    value:
      'Carnitor Oral Solution, Tablets, and SF Sugar-Free Oral Solution (levocarnitine)',
  },
  { name: 'CaroSpir (carospir)', value: 'CaroSpir (carospir)' },
  {
    name: 'Carteolol Hydrochloride (carteolol hydrochloride)',
    value: 'Carteolol Hydrochloride (carteolol hydrochloride)',
  },
  {
    name: 'Cartia XT (diltiazem hydrochloride)',
    value: 'Cartia XT (diltiazem hydrochloride)',
  },
  { name: 'Casodex (bicalutamide)', value: 'Casodex (bicalutamide)' },
  {
    name: 'Cataflam (diclofenac potassium)',
    value: 'Cataflam (diclofenac potassium)',
  },
  {
    name: 'Catapres (clonidine hydrochloride)',
    value: 'Catapres (clonidine hydrochloride)',
  },
  { name: 'Catapres-TTS (clonidine)', value: 'Catapres-TTS (clonidine)' },
  {
    name: 'Cathflo Activase (alteplase)',
    value: 'Cathflo Activase (alteplase)',
  },
  { name: 'Caverject (alprostadil)', value: 'Caverject (alprostadil)' },
  {
    name: 'Caverject Impulse (alprostadil)',
    value: 'Caverject Impulse (alprostadil)',
  },
  { name: 'Cayston (aztreonam)', value: 'Cayston (aztreonam)' },
  { name: 'Cedax (ceftibuten)', value: 'Cedax (ceftibuten)' },
  {
    name: 'Cefaclor Capsules (cefaclor)',
    value: 'Cefaclor Capsules (cefaclor)',
  },
  {
    name: 'Cefaclor Extended-Release Tablets (cefaclor)',
    value: 'Cefaclor Extended-Release Tablets (cefaclor)',
  },
  {
    name: 'Cefadroxil Capsules (cefadroxil)',
    value: 'Cefadroxil Capsules (cefadroxil)',
  },
  {
    name: 'Cefadroxil For Oral Suspension (cefadroxil)',
    value: 'Cefadroxil For Oral Suspension (cefadroxil)',
  },
  {
    name: 'Cefadroxil Tablets (cefadroxil)',
    value: 'Cefadroxil Tablets (cefadroxil)',
  },
  {
    name: 'Cefazolin Sodium (cefazolin sodium)',
    value: 'Cefazolin Sodium (cefazolin sodium)',
  },
  {
    name: 'Cefdinir Capsules (cefdinir)',
    value: 'Cefdinir Capsules (cefdinir)',
  },
  {
    name: 'Cefdinir for Oral Suspension (cefdinir)',
    value: 'Cefdinir for Oral Suspension (cefdinir)',
  },
  {
    name: 'Cefepime (cefepime hydrochloride)',
    value: 'Cefepime (cefepime hydrochloride)',
  },
  {
    name: 'Cefotetan (cefotetan disodium)',
    value: 'Cefotetan (cefotetan disodium)',
  },
  {
    name: 'Cefpodoxime Proxetil Oral Suspension (cefpodoxime proxetil)',
    value: 'Cefpodoxime Proxetil Oral Suspension (cefpodoxime proxetil)',
  },
  {
    name: 'Cefpodoxime Proxetil Tablets (cefpodoxime proxetil)',
    value: 'Cefpodoxime Proxetil Tablets (cefpodoxime proxetil)',
  },
  { name: 'Cefprozil (cefprozil)', value: 'Cefprozil (cefprozil)' },
  { name: 'Ceftin (cefuroxime axetil)', value: 'Ceftin (cefuroxime axetil)' },
  { name: 'Ceftriaxone (ceftriaxone)', value: 'Ceftriaxone (ceftriaxone)' },
  { name: 'Celebrex (celecoxib)', value: 'Celebrex (celecoxib)' },
  {
    name: 'Celestone Soluspan (betamethasone acetate/betamethasone sodium phosphate)',
    value:
      'Celestone Soluspan (betamethasone acetate/betamethasone sodium phosphate)',
  },
  {
    name: 'Celexa (citalopram hydrobromide)',
    value: 'Celexa (citalopram hydrobromide)',
  },
  {
    name: 'CellCept (mycophenolate mofetil)',
    value: 'CellCept (mycophenolate mofetil)',
  },
  {
    name: 'Cellsentials Core Minerals (boron citrate/calcium (calcium citrate, magnesium ascorbate)/calcium etc.',
    value:
      'Cellsentials Core Minerals (boron citrate/calcium (calcium citrate, magnesium ascorbate)/calcium etc.',
  },
  {
    name: 'Cellsentials Vita-Antioxidant (biotin/choline bitartrate/coenzyme Q10/incelligence complex (alpha li etc.',
    value:
      'Cellsentials Vita-Antioxidant (biotin/choline bitartrate/coenzyme Q10/incelligence complex (alpha li etc.',
  },
  { name: 'Celontin (methsuximide)', value: 'Celontin (methsuximide)' },
  { name: 'Cephalexin (cephalexin)', value: 'Cephalexin (cephalexin)' },
  { name: 'Cequa (cyclosporine)', value: 'Cequa (cyclosporine)' },
  { name: 'Cerdelga (eliglustat)', value: 'Cerdelga (eliglustat)' },
  {
    name: 'Cerebyx (fosphenytoin sodium)',
    value: 'Cerebyx (fosphenytoin sodium)',
  },
  { name: 'Cerezyme (imiglucerase)', value: 'Cerezyme (imiglucerase)' },
  {
    name: 'Cervarix (human papillomavirus bivalent (types 16, 18) vaccine, recombinant)',
    value:
      'Cervarix (human papillomavirus bivalent (types 16, 18) vaccine, recombinant)',
  },
  { name: 'Cervidil (dinoprostone)', value: 'Cervidil (dinoprostone)' },
  { name: 'Cesamet (nabilone)', value: 'Cesamet (nabilone)' },
  {
    name: 'Cetirizine Hydrochloride 10 mg Chewable Tablets (cetirizine hydrochloride)',
    value:
      'Cetirizine Hydrochloride 10 mg Chewable Tablets (cetirizine hydrochloride)',
  },
  {
    name: 'Cetirizine Hydrochloride 5 mg Chewable Tablets (cetirizine hydrochloride)',
    value:
      'Cetirizine Hydrochloride 5 mg Chewable Tablets (cetirizine hydrochloride)',
  },
  {
    name: 'Cetirizine Hydrochloride Tablets (cetirizine hydrochloride)',
    value: 'Cetirizine Hydrochloride Tablets (cetirizine hydrochloride)',
  },
  { name: 'Cetraxal (ciprofloxacin)', value: 'Cetraxal (ciprofloxacin)' },
  {
    name: 'Cetrotide (cetrorelix acetate)',
    value: 'Cetrotide (cetrorelix acetate)',
  },
  { name: 'Cetylev (acetylcysteine)', value: 'Cetylev (acetylcysteine)' },
  { name: 'Chantix (varenicline)', value: 'Chantix (varenicline)' },
  { name: 'Chemet (succimer)', value: 'Chemet (succimer)' },
  { name: 'Chenodal (chenodiol)', value: 'Chenodal (chenodiol)' },
  {
    name: 'Cheratussin AC (codeine phosphate/guaifenesin)',
    value: 'Cheratussin AC (codeine phosphate/guaifenesin)',
  },
  {
    name: "Children's Advil Suspension (ibuprofen)",
    value: "Children's Advil Suspension (ibuprofen)",
  },
  {
    name: "Children's Allegra Allergy Oral Suspension (fexofenadine hydrochloride)",
    value:
      "Children's Allegra Allergy Oral Suspension (fexofenadine hydrochloride)",
  },
  {
    name: "Children's Allegra Allergy Orally Disintegrating Tablets (fexofenadine hydrochloride)",
    value:
      "Children's Allegra Allergy Orally Disintegrating Tablets (fexofenadine hydrochloride)",
  },
  {
    name: "Children's Benadryl Allergy Liquid (diphenhydramine hydrochloride)",
    value: "Children's Benadryl Allergy Liquid (diphenhydramine hydrochloride)",
  },
  {
    name: "Children's Claritin Chewable Tablets (loratadine)",
    value: "Children's Claritin Chewable Tablets (loratadine)",
  },
  {
    name: "Children's Claritin Oral Solution (loratadine)",
    value: "Children's Claritin Oral Solution (loratadine)",
  },
  {
    name: "Children's Dimetapp Cold and Allergy (brompheniramine maleate/phenylephrine hydrochloride)",
    value:
      "Children's Dimetapp Cold and Allergy (brompheniramine maleate/phenylephrine hydrochloride)",
  },
  {
    name: "Children's Dimetapp Cold and Cough (brompheniramine maleate/dextromethorphan hydrobromide/phenylephrine h etc.",
    value:
      "Children's Dimetapp Cold and Cough (brompheniramine maleate/dextromethorphan hydrobromide/phenylephrine h etc.",
  },
  {
    name: "Children's FeverAll (acetaminophen)",
    value: "Children's FeverAll (acetaminophen)",
  },
  {
    name: "Children's Mucinex Chest Congestion Mini-Melts (guaifenesin)",
    value: "Children's Mucinex Chest Congestion Mini-Melts (guaifenesin)",
  },
  {
    name: "Children's Mucinex Mini-Melts Cough (dextromethorphan hydrobromide/guaifenesin)",
    value:
      "Children's Mucinex Mini-Melts Cough (dextromethorphan hydrobromide/guaifenesin)",
  },
  {
    name: "Children's Mucinex Stuffy Nose and Cold (guaifenesin/phenylephrine hydrochloride)",
    value:
      "Children's Mucinex Stuffy Nose and Cold (guaifenesin/phenylephrine hydrochloride)",
  },
  {
    name: "Children's Robitussin (dextromethorphan hydrobromide)",
    value: "Children's Robitussin (dextromethorphan hydrobromide)",
  },
  {
    name: "Children's Sudafed Non-Drowsy (pseudoephedrine hydrochloride)",
    value: "Children's Sudafed Non-Drowsy (pseudoephedrine hydrochloride)",
  },
  {
    name: "Children's Xyzal Allergy (levocetirizine dihydrochloride)",
    value: "Children's Xyzal Allergy (levocetirizine dihydrochloride)",
  },
  {
    name: 'Children’s Flonase Allergy Relief (fluticasone propionate)',
    value: 'Children’s Flonase Allergy Relief (fluticasone propionate)',
  },
  { name: 'ChiRhoStim (human secretin)', value: 'ChiRhoStim (human secretin)' },
  {
    name: 'Chlordiazepoxide and Amitriptyline Hydrochloride (amitriptyline hydrochloride/chlordiazepoxide)',
    value:
      'Chlordiazepoxide and Amitriptyline Hydrochloride (amitriptyline hydrochloride/chlordiazepoxide)',
  },
  {
    name: 'Chloroquine Phosphate (chloroquine phosphate)',
    value: 'Chloroquine Phosphate (chloroquine phosphate)',
  },
  {
    name: 'Chlorothiazide (chlorothiazide)',
    value: 'Chlorothiazide (chlorothiazide)',
  },
  {
    name: 'Chlorpromazine Hydrochloride Injection (chlorpromazine hydrochloride)',
    value:
      'Chlorpromazine Hydrochloride Injection (chlorpromazine hydrochloride)',
  },
  {
    name: 'Chlorpromazine Hydrochloride Tablets (chlorpromazine hydrochloride)',
    value:
      'Chlorpromazine Hydrochloride Tablets (chlorpromazine hydrochloride)',
  },
  {
    name: 'Chlorpropamide (chlorpropamide)',
    value: 'Chlorpropamide (chlorpropamide)',
  },
  {
    name: 'Chlorthalidone (chlorthalidone)',
    value: 'Chlorthalidone (chlorthalidone)',
  },
  { name: 'Cholbam (cholic acid)', value: 'Cholbam (cholic acid)' },
  { name: 'Cialis (tadalafil)', value: 'Cialis (tadalafil)' },
  { name: 'Ciclopirox Gel (ciclopirox)', value: 'Ciclopirox Gel (ciclopirox)' },
  {
    name: 'Ciclopirox Olamine Cream (ciclopirox olamine)',
    value: 'Ciclopirox Olamine Cream (ciclopirox olamine)',
  },
  {
    name: 'Ciclopirox Olamine Topical Suspension (ciclopirox olamine)',
    value: 'Ciclopirox Olamine Topical Suspension (ciclopirox olamine)',
  },
  {
    name: 'Ciloxan Ointment (ciprofloxacin hydrochloride)',
    value: 'Ciloxan Ointment (ciprofloxacin hydrochloride)',
  },
  {
    name: 'Ciloxan Solution (ciprofloxacin hydrochloride)',
    value: 'Ciloxan Solution (ciprofloxacin hydrochloride)',
  },
  {
    name: 'Cimduo (lamivudine/tenofovir disoproxil fumarate)',
    value: 'Cimduo (lamivudine/tenofovir disoproxil fumarate)',
  },
  {
    name: 'Cimetidine Hydrochloride Oral Solution (cimetidine hydrochloride)',
    value: 'Cimetidine Hydrochloride Oral Solution (cimetidine hydrochloride)',
  },
  {
    name: 'Cimetidine Tablets (cimetidine)',
    value: 'Cimetidine Tablets (cimetidine)',
  },
  { name: 'Cimzia (certolizumab pegol)', value: 'Cimzia (certolizumab pegol)' },
  {
    name: 'Cinacalcet Hydrochloride (cinacalcet hydrochloride)',
    value: 'Cinacalcet Hydrochloride (cinacalcet hydrochloride)',
  },
  { name: 'Cinqair (reslizumab)', value: 'Cinqair (reslizumab)' },
  {
    name: 'Cinryze (C1 esterase inhibitor (human))',
    value: 'Cinryze (C1 esterase inhibitor (human))',
  },
  {
    name: 'Cipro HC Otic (ciprofloxacin hydrochloride/hydrocortisone)',
    value: 'Cipro HC Otic (ciprofloxacin hydrochloride/hydrocortisone)',
  },
  { name: 'Cipro IV (ciprofloxacin)', value: 'Cipro IV (ciprofloxacin)' },
  {
    name: 'Cipro Oral Suspension and Tablets (ciprofloxacin hydrochloride)',
    value: 'Cipro Oral Suspension and Tablets (ciprofloxacin hydrochloride)',
  },
  { name: 'Cipro XR (ciprofloxacin)', value: 'Cipro XR (ciprofloxacin)' },
  {
    name: 'Ciprodex (ciprofloxacin/dexamethasone)',
    value: 'Ciprodex (ciprofloxacin/dexamethasone)',
  },
  {
    name: 'Ciprofloxacin Injection (ciprofloxacin)',
    value: 'Ciprofloxacin Injection (ciprofloxacin)',
  },
  {
    name: 'Ciprofloxacin Tablets (ciprofloxacin)',
    value: 'Ciprofloxacin Tablets (ciprofloxacin)',
  },
  {
    name: 'Circulation Plus (dietary supplement)',
    value: 'Circulation Plus (dietary supplement)',
  },
  { name: 'Cisplatin (cisplatin)', value: 'Cisplatin (cisplatin)' },
  {
    name: 'Citalopram Oral Solution (citalopram)',
    value: 'Citalopram Oral Solution (citalopram)',
  },
  {
    name: 'CitraNatal 90 DHA (calcium citrate/cupric oxide/docosahexaenoic acid (DHA)/docusate sodi etc.',
    value:
      'CitraNatal 90 DHA (calcium citrate/cupric oxide/docosahexaenoic acid (DHA)/docusate sodi etc.',
  },
  {
    name: 'CitraNatal B-Calm (calcium citrate/ferrous gluconate/iron carbonyl/vitamin B6 (pyridoxin etc.',
    value:
      'CitraNatal B-Calm (calcium citrate/ferrous gluconate/iron carbonyl/vitamin B6 (pyridoxin etc.',
  },
  {
    name: 'CitraNatal Bloom (iron/folic acid/cyanocobalamin/ascorbic acid/docusate sodium)',
    value:
      'CitraNatal Bloom (iron/folic acid/cyanocobalamin/ascorbic acid/docusate sodium)',
  },
  {
    name: 'CitraNatal Bloom DHA (iron/folic acid/cyanocobalamin/ascorbic acid/docusate sodium)',
    value:
      'CitraNatal Bloom DHA (iron/folic acid/cyanocobalamin/ascorbic acid/docusate sodium)',
  },
  {
    name: 'CitraNatal DHA (calcium citrate/cupric oxide/docosahexaenoic acid (DHA)/docusate sodi etc.',
    value:
      'CitraNatal DHA (calcium citrate/cupric oxide/docosahexaenoic acid (DHA)/docusate sodi etc.',
  },
  {
    name: 'CitraNatal Harmony (calcium citrate/docosahexaenoic acid (DHA)/docusate sodium/ferrous fu etc.',
    value:
      'CitraNatal Harmony (calcium citrate/docosahexaenoic acid (DHA)/docusate sodium/ferrous fu etc.',
  },
  {
    name: 'CitraNatal RX (calcium citrate/cupric oxide/docusate sodium/ferrous gluconate/iron c etc.',
    value:
      'CitraNatal RX (calcium citrate/cupric oxide/docusate sodium/ferrous gluconate/iron c etc.',
  },
  { name: 'Cladribine (cladribine)', value: 'Cladribine (cladribine)' },
  { name: 'Claforan (cefotaxime)', value: 'Claforan (cefotaxime)' },
  { name: 'Claravis (isotretinoin)', value: 'Claravis (isotretinoin)' },
  { name: 'Clarinex (desloratadine)', value: 'Clarinex (desloratadine)' },
  {
    name: 'Clarinex-D 12 Hour (desloratadine/pseudoephedrine sulfate)',
    value: 'Clarinex-D 12 Hour (desloratadine/pseudoephedrine sulfate)',
  },
  { name: 'Claritin (loratadine)', value: 'Claritin (loratadine)' },
  {
    name: 'Claritin Liqui-Gels (loratadine)',
    value: 'Claritin Liqui-Gels (loratadine)',
  },
  {
    name: 'Claritin Reditabs 12 Hour (loratadine)',
    value: 'Claritin Reditabs 12 Hour (loratadine)',
  },
  {
    name: 'Claritin Reditabs 24 Hour (loratadine)',
    value: 'Claritin Reditabs 24 Hour (loratadine)',
  },
  {
    name: 'Claritin-D 12 Hour (loratadine/pseudoephedrine sulfate)',
    value: 'Claritin-D 12 Hour (loratadine/pseudoephedrine sulfate)',
  },
  {
    name: 'Claritin-D 24 Hour (loratadine/pseudoephedrine sulfate)',
    value: 'Claritin-D 24 Hour (loratadine/pseudoephedrine sulfate)',
  },
  {
    name: 'Clemastine Fumarate Syrup (clemastine fumarate)',
    value: 'Clemastine Fumarate Syrup (clemastine fumarate)',
  },
  {
    name: 'Clemastine Fumarate Tablets (clemastine fumarate)',
    value: 'Clemastine Fumarate Tablets (clemastine fumarate)',
  },
  {
    name: 'CLENPIQ (anhydrous citric acid/magnesium oxide/sodium picosulfate)',
    value: 'CLENPIQ (anhydrous citric acid/magnesium oxide/sodium picosulfate)',
  },
  {
    name: 'Cleocin Hydrochloride Capsules (clindamycin hydrochloride)',
    value: 'Cleocin Hydrochloride Capsules (clindamycin hydrochloride)',
  },
  {
    name: 'Cleocin Pediatric (clindamycin palmitate hydrochloride)',
    value: 'Cleocin Pediatric (clindamycin palmitate hydrochloride)',
  },
  {
    name: 'Cleocin Phosphate Injection (clindamycin)',
    value: 'Cleocin Phosphate Injection (clindamycin)',
  },
  {
    name: 'Cleocin T (clindamycin phosphate)',
    value: 'Cleocin T (clindamycin phosphate)',
  },
  {
    name: 'Cleocin Vaginal Cream (clindamycin phosphate)',
    value: 'Cleocin Vaginal Cream (clindamycin phosphate)',
  },
  {
    name: 'Cleocin Vaginal Ovules (clindamycin phosphate)',
    value: 'Cleocin Vaginal Ovules (clindamycin phosphate)',
  },
  { name: 'Cleviprex (clevidipine)', value: 'Cleviprex (clevidipine)' },
  { name: 'Climara (estradiol)', value: 'Climara (estradiol)' },
  {
    name: 'Climara Pro (estradiol/levonorgestrel)',
    value: 'Climara Pro (estradiol/levonorgestrel)',
  },
  {
    name: 'Clindagel (clindamycin phosphate)',
    value: 'Clindagel (clindamycin phosphate)',
  },
  {
    name: 'Clindamycin in 5 Percent Dextrose (Clindamycin in 5 Percent Dextrose)',
    value:
      'Clindamycin in 5 Percent Dextrose (Clindamycin in 5 Percent Dextrose)',
  },
  {
    name: 'Clindamycin Phosphate and Benzoyl Peroxide (benzoyl peroxide/clindamycin phosphate)',
    value:
      'Clindamycin Phosphate and Benzoyl Peroxide (benzoyl peroxide/clindamycin phosphate)',
  },
  {
    name: 'Clindesse (clindamycin phosphate)',
    value: 'Clindesse (clindamycin phosphate)',
  },
  {
    name: 'Clobetasol Propionate Cream, Gel and Ointment (clobetasol propionate)',
    value:
      'Clobetasol Propionate Cream, Gel and Ointment (clobetasol propionate)',
  },
  {
    name: 'Clobetasol Propionate Topical Solution (clobetasol propionate)',
    value: 'Clobetasol Propionate Topical Solution (clobetasol propionate)',
  },
  {
    name: 'Clobex Lotion (clobetasol propionate)',
    value: 'Clobex Lotion (clobetasol propionate)',
  },
  {
    name: 'Clobex Shampoo (clobetasol propionate)',
    value: 'Clobex Shampoo (clobetasol propionate)',
  },
  {
    name: 'Clobex Spray (clobetasol propionate)',
    value: 'Clobex Spray (clobetasol propionate)',
  },
  {
    name: 'Cloderm (clocortolone pivalate)',
    value: 'Cloderm (clocortolone pivalate)',
  },
  { name: 'Clolar (clofarabine)', value: 'Clolar (clofarabine)' },
  {
    name: 'Clonazepam Orally Disintegrating Tablets (clonazepam)',
    value: 'Clonazepam Orally Disintegrating Tablets (clonazepam)',
  },
  {
    name: 'Clorpres (chlorthalidone/clonidine hydrochloride)',
    value: 'Clorpres (chlorthalidone/clonidine hydrochloride)',
  },
  {
    name: 'Clotrimazole Cream (clotrimazole)',
    value: 'Clotrimazole Cream (clotrimazole)',
  },
  {
    name: 'Clotrimazole Lozenge (clotrimazole)',
    value: 'Clotrimazole Lozenge (clotrimazole)',
  },
  {
    name: 'Clotrimazole Topical Solution (clotrimazole)',
    value: 'Clotrimazole Topical Solution (clotrimazole)',
  },
  { name: 'Clozapine (clozapine)', value: 'Clozapine (clozapine)' },
  { name: 'Clozaril (clozapine)', value: 'Clozaril (clozapine)' },
  {
    name: 'Coagadex (coagulation factor X (human))',
    value: 'Coagadex (coagulation factor X (human))',
  },
  {
    name: 'Coartem (artemether/lumefantrine)',
    value: 'Coartem (artemether/lumefantrine)',
  },
  {
    name: 'Codeine Sulfate Tablets (codeine sulfate)',
    value: 'Codeine Sulfate Tablets (codeine sulfate)',
  },
  {
    name: 'Cogentin (benztropine mesylate)',
    value: 'Cogentin (benztropine mesylate)',
  },
  {
    name: 'Colace Capsules (docusate sodium)',
    value: 'Colace Capsules (docusate sodium)',
  },
  {
    name: 'Colazal (balsalazide disodium)',
    value: 'Colazal (balsalazide disodium)',
  },
  { name: 'Colcrys (colchicine)', value: 'Colcrys (colchicine)' },
  {
    name: 'Colestid Granules (colestipol hydrochloride)',
    value: 'Colestid Granules (colestipol hydrochloride)',
  },
  {
    name: 'Colestid Tablets (colestipol hydrochloride)',
    value: 'Colestid Tablets (colestipol hydrochloride)',
  },
  {
    name: 'Collagenase Santyl (collagenase)',
    value: 'Collagenase Santyl (collagenase)',
  },
  { name: 'Colocort (hydrocortisone)', value: 'Colocort (hydrocortisone)' },
  {
    name: 'Coly-Mycin M (colistimethate sodium)',
    value: 'Coly-Mycin M (colistimethate sodium)',
  },
  {
    name: 'Coly-Mycin S (colistin sulfate/hydrocortisone acetate/neomycin sulfate/thonzonium b etc.',
    value:
      'Coly-Mycin S (colistin sulfate/hydrocortisone acetate/neomycin sulfate/thonzonium b etc.',
  },
  {
    name: 'Colyte 4 Liters (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
    value:
      'Colyte 4 Liters (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
  },
  {
    name: 'Colyte One Gallon (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
    value:
      'Colyte One Gallon (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
  },
  {
    name: 'Combigan (brimonidine tartrate/timolol maleate)',
    value: 'Combigan (brimonidine tartrate/timolol maleate)',
  },
  {
    name: 'CombiPatch (estradiol/norethindrone acetate)',
    value: 'CombiPatch (estradiol/norethindrone acetate)',
  },
  {
    name: 'Combivent Respimat (albuterol/ipratropium bromide)',
    value: 'Combivent Respimat (albuterol/ipratropium bromide)',
  },
  {
    name: 'Combivir (lamivudine/zidovudine)',
    value: 'Combivir (lamivudine/zidovudine)',
  },
  { name: 'Cometriq (cabozantinib)', value: 'Cometriq (cabozantinib)' },
  {
    name: 'Complera (emtricitabine/rilpivirine/tenofovir disoproxil fumarate)',
    value: 'Complera (emtricitabine/rilpivirine/tenofovir disoproxil fumarate)',
  },
  { name: 'Compro (prochlorperazine)', value: 'Compro (prochlorperazine)' },
  { name: 'Comtan (entacapone)', value: 'Comtan (entacapone)' },
  {
    name: 'Comvax (haemophilus b conjugate (meningococcal protein conjugate) and hepatit etc.',
    value:
      'Comvax (haemophilus b conjugate (meningococcal protein conjugate) and hepatit etc.',
  },
  {
    name: 'Concept DHA (copper/docosahexaenoic acid (DHA)/eicosapentaenoic acid (EPA)/ferrous etc.',
    value:
      'Concept DHA (copper/docosahexaenoic acid (DHA)/eicosapentaenoic acid (EPA)/ferrous etc.',
  },
  {
    name: 'Concept OB (copper sulfate/ferrous fumarate (anhydrous)/magnesium sulfate/mangane etc.',
    value:
      'Concept OB (copper sulfate/ferrous fumarate (anhydrous)/magnesium sulfate/mangane etc.',
  },
  {
    name: 'Concerta (methylphenidate hydrochloride)',
    value: 'Concerta (methylphenidate hydrochloride)',
  },
  { name: 'Condylox Gel (podofilox)', value: 'Condylox Gel (podofilox)' },
  {
    name: 'Condylox Topical Solution (podofilox)',
    value: 'Condylox Topical Solution (podofilox)',
  },
  { name: 'Constulose (lactulose)', value: 'Constulose (lactulose)' },
  {
    name: 'Contrave (bupropion hydrochloride/naltrexone hydrochloride)',
    value: 'Contrave (bupropion hydrochloride/naltrexone hydrochloride)',
  },
  {
    name: 'ConZip (tramadol hydrochloride)',
    value: 'ConZip (tramadol hydrochloride)',
  },
  {
    name: 'Copaxone (glatiramer acetate)',
    value: 'Copaxone (glatiramer acetate)',
  },
  { name: 'Copegus (ribavirin)', value: 'Copegus (ribavirin)' },
  { name: 'Copiktra (duvelisib)', value: 'Copiktra (duvelisib)' },
  {
    name: 'CoQuinone 30 (coenzyme Q-10/lipoic acid)',
    value: 'CoQuinone 30 (coenzyme Q-10/lipoic acid)',
  },
  {
    name: 'Cordarone (amiodarone hydrochloride)',
    value: 'Cordarone (amiodarone hydrochloride)',
  },
  {
    name: 'Cordran Cream and Ointment (flurandrenolide)',
    value: 'Cordran Cream and Ointment (flurandrenolide)',
  },
  {
    name: 'Cordran Lotion (flurandrenolide)',
    value: 'Cordran Lotion (flurandrenolide)',
  },
  {
    name: 'Cordran Tape (flurandrenolide)',
    value: 'Cordran Tape (flurandrenolide)',
  },
  { name: 'Coreg (carvedilol)', value: 'Coreg (carvedilol)' },
  {
    name: 'Coreg CR (carvedilol phosphate)',
    value: 'Coreg CR (carvedilol phosphate)',
  },
  { name: 'Corgard (nadolol)', value: 'Corgard (nadolol)' },
  {
    name: 'Coricidin HBP Chest Congestion and Cough (dextromethorphan hydrobromide/guaifenesin)',
    value:
      'Coricidin HBP Chest Congestion and Cough (dextromethorphan hydrobromide/guaifenesin)',
  },
  {
    name: 'Coricidin HBP Cold & Cough (chlorpheniramine maleate/dextromethorphan hydrobromide)',
    value:
      'Coricidin HBP Cold & Cough (chlorpheniramine maleate/dextromethorphan hydrobromide)',
  },
  {
    name: 'Coricidin HBP Cold & Flu (acetaminophen/chlorpheniramine maleate)',
    value: 'Coricidin HBP Cold & Flu (acetaminophen/chlorpheniramine maleate)',
  },
  {
    name: 'Coricidin HBP Maximum Strength Flu (acetaminophen/chlorpheniramine maleate/dextromethorphan hydrobromide) etc.',
    value:
      'Coricidin HBP Maximum Strength Flu (acetaminophen/chlorpheniramine maleate/dextromethorphan hydrobromide) etc.',
  },
  {
    name: 'Corifact (factor XIII concentrate (human))',
    value: 'Corifact (factor XIII concentrate (human))',
  },
  { name: 'Corlanor (ivabradine)', value: 'Corlanor (ivabradine)' },
  {
    name: 'Corlopam (fenoldopam mesylate)',
    value: 'Corlopam (fenoldopam mesylate)',
  },
  { name: 'Cortef (hydrocortisone)', value: 'Cortef (hydrocortisone)' },
  {
    name: 'Cortifoam (hydrocortisone acetate)',
    value: 'Cortifoam (hydrocortisone acetate)',
  },
  {
    name: 'Cortisone Acetate (cortisone acetate)',
    value: 'Cortisone Acetate (cortisone acetate)',
  },
  {
    name: 'Cortisporin Cream (hydrocortisone acetate/neomycin sulfate/polymyxin B sulfate)',
    value:
      'Cortisporin Cream (hydrocortisone acetate/neomycin sulfate/polymyxin B sulfate)',
  },
  {
    name: 'Cortisporin Ointment (bacitracin zinc/hydrocortisone/neomycin sulfate/polymyxin B sulfate)',
    value:
      'Cortisporin Ointment (bacitracin zinc/hydrocortisone/neomycin sulfate/polymyxin B sulfate)',
  },
  {
    name: 'Cortisporin Otic Solution (hydrocortisone/neomycin sulfate/polymyxin B sulfate)',
    value:
      'Cortisporin Otic Solution (hydrocortisone/neomycin sulfate/polymyxin B sulfate)',
  },
  {
    name: 'Cortisporin-TC (colistin sulfate/hydrocortisone acetate/neomycin sulfate/thonzonium b etc.',
    value:
      'Cortisporin-TC (colistin sulfate/hydrocortisone acetate/neomycin sulfate/thonzonium b etc.',
  },
  {
    name: 'Cortizone-10 (hydrocortisone)',
    value: 'Cortizone-10 (hydrocortisone)',
  },
  { name: 'Cortrosyn (cosyntropin)', value: 'Cortrosyn (cosyntropin)' },
  {
    name: 'Corvert (ibutilide fumarate)',
    value: 'Corvert (ibutilide fumarate)',
  },
  {
    name: 'Corzide (bendroflumethiazide/nadolol)',
    value: 'Corzide (bendroflumethiazide/nadolol)',
  },
  { name: 'Cosentyx (secukinumab)', value: 'Cosentyx (secukinumab)' },
  { name: 'Cosmegen (dactinomycin)', value: 'Cosmegen (dactinomycin)' },
  {
    name: 'Cosopt (dorzolamide hydrochloride/timolol maleate)',
    value: 'Cosopt (dorzolamide hydrochloride/timolol maleate)',
  },
  { name: 'Cotellic (cobimetinib)', value: 'Cotellic (cobimetinib)' },
  {
    name: 'Cotempla XR-ODT (methylphenidate)',
    value: 'Cotempla XR-ODT (methylphenidate)',
  },
  { name: 'Coumadin (warfarin sodium)', value: 'Coumadin (warfarin sodium)' },
  { name: 'Cozaar (losartan potassium)', value: 'Cozaar (losartan potassium)' },
  { name: 'Creon (pancrelipase)', value: 'Creon (pancrelipase)' },
  {
    name: 'Cresemba (isavuconazonium sulfate)',
    value: 'Cresemba (isavuconazonium sulfate)',
  },
  {
    name: 'Crestor (rosuvastatin calcium)',
    value: 'Crestor (rosuvastatin calcium)',
  },
  { name: 'Crinone (progesterone)', value: 'Crinone (progesterone)' },
  {
    name: 'Crixivan (indinavir sulfate)',
    value: 'Crixivan (indinavir sulfate)',
  },
  {
    name: 'CroFab (crotalidae polyvalent immune fab (ovine))',
    value: 'CroFab (crotalidae polyvalent immune fab (ovine))',
  },
  {
    name: 'Cromolyn Sodium Inhalation Solution (cromolyn sodium)',
    value: 'Cromolyn Sodium Inhalation Solution (cromolyn sodium)',
  },
  {
    name: 'Cromolyn Sodium Ophthalmic Solution (cromolyn sodium)',
    value: 'Cromolyn Sodium Ophthalmic Solution (cromolyn sodium)',
  },
  {
    name: 'Cryselle (ethinyl estradiol/norgestrel)',
    value: 'Cryselle (ethinyl estradiol/norgestrel)',
  },
  { name: 'Cubicin (daptomycin)', value: 'Cubicin (daptomycin)' },
  { name: 'Cubicin RF (daptomycin)', value: 'Cubicin RF (daptomycin)' },
  { name: 'Cuprimine (penicillamine)', value: 'Cuprimine (penicillamine)' },
  { name: 'Curosurf (poractant alfa)', value: 'Curosurf (poractant alfa)' },
  {
    name: 'Cutaquig (immune globulin subcutaneous (human))',
    value: 'Cutaquig (immune globulin subcutaneous (human))',
  },
  {
    name: 'Cutivate Lotion (fluticasone propionate)',
    value: 'Cutivate Lotion (fluticasone propionate)',
  },
  {
    name: 'Cuvitru (immune globulin subcutaneous (human))',
    value: 'Cuvitru (immune globulin subcutaneous (human))',
  },
  { name: 'Cuvposa (glycopyrrolate)', value: 'Cuvposa (glycopyrrolate)' },
  { name: 'Cyanokit (hydroxocobalamin)', value: 'Cyanokit (hydroxocobalamin)' },
  {
    name: 'Cyclafem 1/35 (ethinyl estradiol/norethindrone)',
    value: 'Cyclafem 1/35 (ethinyl estradiol/norethindrone)',
  },
  {
    name: 'Cyclafem 7/7/7 (ethinyl estradiol/norethindrone)',
    value: 'Cyclafem 7/7/7 (ethinyl estradiol/norethindrone)',
  },
  {
    name: 'Cyclessa (desogestrel/ethinyl estradiol)',
    value: 'Cyclessa (desogestrel/ethinyl estradiol)',
  },
  {
    name: 'Cyclobenzaprine Hydrochloride (cyclobenzaprine hydrochloride)',
    value: 'Cyclobenzaprine Hydrochloride (cyclobenzaprine hydrochloride)',
  },
  {
    name: 'Cyclogyl (cyclopentolate hydrochloride)',
    value: 'Cyclogyl (cyclopentolate hydrochloride)',
  },
  {
    name: 'Cyclomydril (cyclopentolate hydrochloride/phenylephrine hydrochloride)',
    value:
      'Cyclomydril (cyclopentolate hydrochloride/phenylephrine hydrochloride)',
  },
  {
    name: 'Cyclophosphamide Capsules (cyclophosphamide)',
    value: 'Cyclophosphamide Capsules (cyclophosphamide)',
  },
  {
    name: 'Cyclophosphamide Injection (cyclophosphamide)',
    value: 'Cyclophosphamide Injection (cyclophosphamide)',
  },
  {
    name: 'Cyclophosphamide Tablets (cyclophosphamide)',
    value: 'Cyclophosphamide Tablets (cyclophosphamide)',
  },
  {
    name: 'Cycloset (bromocriptine mesylate)',
    value: 'Cycloset (bromocriptine mesylate)',
  },
  {
    name: 'Cyklokapron (tranexamic acid)',
    value: 'Cyklokapron (tranexamic acid)',
  },
  { name: 'Cymbalta (duloxetine)', value: 'Cymbalta (duloxetine)' },
  {
    name: 'Cyproheptadine Hydrochloride Syrup (cyproheptadine hydrochloride)',
    value: 'Cyproheptadine Hydrochloride Syrup (cyproheptadine hydrochloride)',
  },
  { name: 'Cyramza (ramucirumab)', value: 'Cyramza (ramucirumab)' },
  {
    name: 'Cystadane (betaine anhydrous)',
    value: 'Cystadane (betaine anhydrous)',
  },
  {
    name: 'Cystagon (cysteamine bitartrate)',
    value: 'Cystagon (cysteamine bitartrate)',
  },
  { name: 'Cystaran (cysteamine)', value: 'Cystaran (cysteamine)' },
  {
    name: 'Cysview (hexaminolevulinate hydrochloride)',
    value: 'Cysview (hexaminolevulinate hydrochloride)',
  },
  {
    name: 'Cytarabine Injection 2 g/20 mL (cytarabine)',
    value: 'Cytarabine Injection 2 g/20 mL (cytarabine)',
  },
  {
    name: 'Cytarabine Injection 20 mg/mL (cytarabine)',
    value: 'Cytarabine Injection 20 mg/mL (cytarabine)',
  },
  {
    name: 'Cytogam (cytomegalovirus immune globulin intravenous (human))',
    value: 'Cytogam (cytomegalovirus immune globulin intravenous (human))',
  },
  {
    name: 'Cytomel (liothyronine sodium)',
    value: 'Cytomel (liothyronine sodium)',
  },
  { name: 'Cytotec (misoprostol)', value: 'Cytotec (misoprostol)' },
  {
    name: 'Cytovene (ganciclovir sodium)',
    value: 'Cytovene (ganciclovir sodium)',
  },
  { name: 'D-Cal (calcium carbonate)', value: 'D-Cal (calcium carbonate)' },
  {
    name: 'D-Cal Kids Chewable Tablets (calcium carbonate)',
    value: 'D-Cal Kids Chewable Tablets (calcium carbonate)',
  },
  {
    name: 'D-Cal Kids Granules (calcium carbonate)',
    value: 'D-Cal Kids Granules (calcium carbonate)',
  },
  {
    name: 'D.H.E 45 (dihydroergotamine mesylate)',
    value: 'D.H.E 45 (dihydroergotamine mesylate)',
  },
  { name: 'Dacarbazine (dacarbazine)', value: 'Dacarbazine (dacarbazine)' },
  { name: 'Dacogen (decitabine)', value: 'Dacogen (decitabine)' },
  {
    name: 'Daily Build (dietary supplement)',
    value: 'Daily Build (dietary supplement)',
  },
  { name: 'Daklinza (daclatasvir)', value: 'Daklinza (daclatasvir)' },
  {
    name: 'Dalfampridine (dalfampridine)',
    value: 'Dalfampridine (dalfampridine)',
  },
  { name: 'Daliresp (roflumilast)', value: 'Daliresp (roflumilast)' },
  {
    name: 'Dallergy Drops (chlorpheniramine maleate/phenylephrine hydrochloride)',
    value:
      'Dallergy Drops (chlorpheniramine maleate/phenylephrine hydrochloride)',
  },
  { name: 'Dalvance (dalbavancin)', value: 'Dalvance (dalbavancin)' },
  { name: 'Danazol (danazol)', value: 'Danazol (danazol)' },
  {
    name: 'Dantrium Capsules (dantrolene sodium)',
    value: 'Dantrium Capsules (dantrolene sodium)',
  },
  {
    name: 'Dantrium Intravenous (dantrolene sodium)',
    value: 'Dantrium Intravenous (dantrolene sodium)',
  },
  { name: 'Dapsone (dapsone)', value: 'Dapsone (dapsone)' },
  {
    name: 'Daptacel (diphtheria and tetanus toxoids and acellular pertussis vaccine adsorb etc.',
    value:
      'Daptacel (diphtheria and tetanus toxoids and acellular pertussis vaccine adsorb etc.',
  },
  { name: 'Daraprim (pyrimethamine)', value: 'Daraprim (pyrimethamine)' },
  { name: 'Darzalex (daratumumab)', value: 'Darzalex (daratumumab)' },
  {
    name: 'Daunorubicin Hydrochloride (daunorubicin hydrochloride)',
    value: 'Daunorubicin Hydrochloride (daunorubicin hydrochloride)',
  },
  { name: 'Daurismo (glasdegib)', value: 'Daurismo (glasdegib)' },
  { name: 'Daxbia (cephalexin)', value: 'Daxbia (cephalexin)' },
  { name: 'Daypro (oxaprozin)', value: 'Daypro (oxaprozin)' },
  { name: 'Daytrana (methylphenidate)', value: 'Daytrana (methylphenidate)' },
  {
    name: 'DDAVP Injection (desmopressin acetate)',
    value: 'DDAVP Injection (desmopressin acetate)',
  },
  {
    name: 'DDAVP Nasal Spray (desmopressin acetate)',
    value: 'DDAVP Nasal Spray (desmopressin acetate)',
  },
  {
    name: 'DDAVP Rhinal Tube (desmopressin acetate)',
    value: 'DDAVP Rhinal Tube (desmopressin acetate)',
  },
  {
    name: 'DDAVP Tablets (desmopressin acetate)',
    value: 'DDAVP Tablets (desmopressin acetate)',
  },
  { name: 'Deferiprone (deferiprone)', value: 'Deferiprone (deferiprone)' },
  {
    name: 'Definity (perflutren lipid microsphere)',
    value: 'Definity (perflutren lipid microsphere)',
  },
  {
    name: 'Defitelio (defibrotide sodium)',
    value: 'Defitelio (defibrotide sodium)',
  },
  {
    name: 'Delatestryl (testosterone enanthate)',
    value: 'Delatestryl (testosterone enanthate)',
  },
  {
    name: 'Delestrogen (estradiol valerate)',
    value: 'Delestrogen (estradiol valerate)',
  },
  {
    name: 'Delstrigo (doravirine/lamivudine/tenofovir disoproxil fumarate)',
    value: 'Delstrigo (doravirine/lamivudine/tenofovir disoproxil fumarate)',
  },
  {
    name: 'Delsym 12 Hour Cough Relief (dextromethorphan polistirex)',
    value: 'Delsym 12 Hour Cough Relief (dextromethorphan polistirex)',
  },
  { name: 'Delzicol (mesalamine)', value: 'Delzicol (mesalamine)' },
  { name: 'Demadex (torsemide)', value: 'Demadex (torsemide)' },
  {
    name: 'Demeclocycline Hydrochloride (demeclocycline hydrochloride)',
    value: 'Demeclocycline Hydrochloride (demeclocycline hydrochloride)',
  },
  {
    name: 'Demerol Injectable (meperidine hydrochloride)',
    value: 'Demerol Injectable (meperidine hydrochloride)',
  },
  {
    name: 'Demerol Tablets (meperidine hydrochloride)',
    value: 'Demerol Tablets (meperidine hydrochloride)',
  },
  { name: 'Demser (metyrosine)', value: 'Demser (metyrosine)' },
  { name: 'Denavir (penciclovir)', value: 'Denavir (penciclovir)' },
  {
    name: 'Dengvaxia (dengue tetravalent)',
    value: 'Dengvaxia (dengue tetravalent)',
  },
  {
    name: 'Denta 5000 Plus (sodium fluoride)',
    value: 'Denta 5000 Plus (sodium fluoride)',
  },
  { name: 'Depacon (valproate sodium)', value: 'Depacon (valproate sodium)' },
  { name: 'Depakene (valproic acid)', value: 'Depakene (valproic acid)' },
  {
    name: 'Depakote ER (divalproex sodium)',
    value: 'Depakote ER (divalproex sodium)',
  },
  {
    name: 'Depakote Sprinkle Capsules (divalproex sodium)',
    value: 'Depakote Sprinkle Capsules (divalproex sodium)',
  },
  {
    name: 'Depakote Tablets (divalproex sodium)',
    value: 'Depakote Tablets (divalproex sodium)',
  },
  { name: 'Depen (penicillamine)', value: 'Depen (penicillamine)' },
  {
    name: 'Depo-Estradiol (estradiol cypionate)',
    value: 'Depo-Estradiol (estradiol cypionate)',
  },
  {
    name: 'Depo-Medrol Multidose (methylprednisolone acetate)',
    value: 'Depo-Medrol Multidose (methylprednisolone acetate)',
  },
  {
    name: 'Depo-Medrol Single-Dose (methylprednisolone acetate)',
    value: 'Depo-Medrol Single-Dose (methylprednisolone acetate)',
  },
  {
    name: 'Depo-Provera Contraceptive Injection (medroxyprogesterone acetate)',
    value: 'Depo-Provera Contraceptive Injection (medroxyprogesterone acetate)',
  },
  {
    name: 'Depo-Provera Sterile Aqueous Suspension (medroxyprogesterone acetate)',
    value:
      'Depo-Provera Sterile Aqueous Suspension (medroxyprogesterone acetate)',
  },
  {
    name: 'depo-subQ provera 104 (medroxyprogesterone acetate)',
    value: 'depo-subQ provera 104 (medroxyprogesterone acetate)',
  },
  {
    name: 'Depo-Testosterone (testosterone cypionate)',
    value: 'Depo-Testosterone (testosterone cypionate)',
  },
  {
    name: 'DepoCyt (cytarabine liposome)',
    value: 'DepoCyt (cytarabine liposome)',
  },
  {
    name: 'Dermatop Emollient Cream (prednicarbate)',
    value: 'Dermatop Emollient Cream (prednicarbate)',
  },
  {
    name: 'Dermatop Ointment (prednicarbate)',
    value: 'Dermatop Ointment (prednicarbate)',
  },
  {
    name: 'Descovy (emtricitabine/tenofovir alafenamide)',
    value: 'Descovy (emtricitabine/tenofovir alafenamide)',
  },
  {
    name: 'Desferal (deferoxamine mesylate)',
    value: 'Desferal (deferoxamine mesylate)',
  },
  {
    name: 'Desloratadine Orally Disintegrating Tablets (desloratadine)',
    value: 'Desloratadine Orally Disintegrating Tablets (desloratadine)',
  },
  {
    name: 'Desogen (desogestrel/ethinyl estradiol)',
    value: 'Desogen (desogestrel/ethinyl estradiol)',
  },
  { name: 'Desonate (desonide)', value: 'Desonate (desonide)' },
  { name: 'Desonide (desonide)', value: 'Desonide (desonide)' },
  {
    name: 'Desonide Ointment, 0.05% (desonide)',
    value: 'Desonide Ointment, 0.05% (desonide)',
  },
  { name: 'DesOwen (desonide)', value: 'DesOwen (desonide)' },
  {
    name: 'Desoxyn (methamphetamine hydrochloride)',
    value: 'Desoxyn (methamphetamine hydrochloride)',
  },
  {
    name: 'Detrol (tolterodine tartrate)',
    value: 'Detrol (tolterodine tartrate)',
  },
  {
    name: 'Detrol LA (tolterodine tartrate)',
    value: 'Detrol LA (tolterodine tartrate)',
  },
  {
    name: 'Dexamethasone Sodium Phosphate Injection, USP 10 mg/mL (dexamethasone sodium phosphate)',
    value:
      'Dexamethasone Sodium Phosphate Injection, USP 10 mg/mL (dexamethasone sodium phosphate)',
  },
  {
    name: 'Dexamethasone Sodium Phosphate Injection, USP 4 mg/mL (dexamethasone sodium phosphate)',
    value:
      'Dexamethasone Sodium Phosphate Injection, USP 4 mg/mL (dexamethasone sodium phosphate)',
  },
  {
    name: 'Dexamethasone Sodium Phosphate Ophthalmic Solution (dexamethasone sodium phosphate)',
    value:
      'Dexamethasone Sodium Phosphate Ophthalmic Solution (dexamethasone sodium phosphate)',
  },
  {
    name: 'Dexamethasone/Dexamethasone Intensol (dexamethasone)',
    value: 'Dexamethasone/Dexamethasone Intensol (dexamethasone)',
  },
  {
    name: 'Dexedrine (dextroamphetamine sulfate)',
    value: 'Dexedrine (dextroamphetamine sulfate)',
  },
  { name: 'Dexferrum (iron dextran)', value: 'Dexferrum (iron dextran)' },
  { name: 'Dexilant (dexlansoprazole)', value: 'Dexilant (dexlansoprazole)' },
  {
    name: 'Dexmedetomidine Hydrochloride in 0.9% Sodium Chloride (dexmedetomidine hydrochloride in 0.9% sodium chloride)',
    value:
      'Dexmedetomidine Hydrochloride in 0.9% Sodium Chloride (dexmedetomidine hydrochloride in 0.9% sodium chloride)',
  },
  { name: 'Dexrazoxane (dexamethasone)', value: 'Dexrazoxane (dexamethasone)' },
  { name: 'DiaBeta (glyburide)', value: 'DiaBeta (glyburide)' },
  {
    name: 'Diamox Sequels (acetazolamide)',
    value: 'Diamox Sequels (acetazolamide)',
  },
  { name: 'Diastat (diazepam)', value: 'Diastat (diazepam)' },
  {
    name: 'Diazepam Injection (diazepam)',
    value: 'Diazepam Injection (diazepam)',
  },
  {
    name: 'Diazepam Oral Solution/Intensol Oral Solution (diazepam)',
    value: 'Diazepam Oral Solution/Intensol Oral Solution (diazepam)',
  },
  {
    name: 'Dibenzyline (phenoxybenzamine hydrochloride)',
    value: 'Dibenzyline (phenoxybenzamine hydrochloride)',
  },
  {
    name: 'Diclegis (doxylamine succinate/pyridoxine hydrochloride)',
    value: 'Diclegis (doxylamine succinate/pyridoxine hydrochloride)',
  },
  {
    name: 'Diclofenac Sodium Delayed-Release (diclofenac sodium)',
    value: 'Diclofenac Sodium Delayed-Release (diclofenac sodium)',
  },
  {
    name: 'Diclofenac Sodium Topical Solution 1.5% (diclofenac sodium)',
    value: 'Diclofenac Sodium Topical Solution 1.5% (diclofenac sodium)',
  },
  {
    name: 'Dicloxacillin Sodium (dicloxacillin sodium)',
    value: 'Dicloxacillin Sodium (dicloxacillin sodium)',
  },
  {
    name: 'Dicyclomine Hydrochloride Injection (dicyclomine hydrochloride)',
    value: 'Dicyclomine Hydrochloride Injection (dicyclomine hydrochloride)',
  },
  {
    name: 'Dicyclomine Oral Solution (dicyclomine hydrochloride)',
    value: 'Dicyclomine Oral Solution (dicyclomine hydrochloride)',
  },
  {
    name: 'Diethylpropion Hydrochloride (diethylpropion hydrochloride)',
    value: 'Diethylpropion Hydrochloride (diethylpropion hydrochloride)',
  },
  { name: 'Differin Cream (adapalene)', value: 'Differin Cream (adapalene)' },
  {
    name: 'Differin Gel 0.1% (adapalene)',
    value: 'Differin Gel 0.1% (adapalene)',
  },
  {
    name: 'Differin Gel 0.1% OTC (adapalene)',
    value: 'Differin Gel 0.1% OTC (adapalene)',
  },
  {
    name: 'Differin Gel 0.3% (adapalene)',
    value: 'Differin Gel 0.3% (adapalene)',
  },
  { name: 'Differin Lotion (adapalene)', value: 'Differin Lotion (adapalene)' },
  { name: 'Dificid (fidaxomicin)', value: 'Dificid (fidaxomicin)' },
  { name: 'Diflucan (fluconazole)', value: 'Diflucan (fluconazole)' },
  {
    name: 'Diflunisal Tablets (diflunisal)',
    value: 'Diflunisal Tablets (diflunisal)',
  },
  {
    name: 'DigiFab (digoxin immune fab (ovine))',
    value: 'DigiFab (digoxin immune fab (ovine))',
  },
  { name: 'Digitek (digoxin)', value: 'Digitek (digoxin)' },
  { name: 'Digoxin (digoxin)', value: 'Digoxin (digoxin)' },
  {
    name: 'Dilantin Capsules (phenytoin sodium)',
    value: 'Dilantin Capsules (phenytoin sodium)',
  },
  {
    name: 'Dilantin Infatabs (phenytoin)',
    value: 'Dilantin Infatabs (phenytoin)',
  },
  { name: 'Dilantin-125 (phenytoin)', value: 'Dilantin-125 (phenytoin)' },
  {
    name: 'Dilatrate-SR (isosorbide dinitrate)',
    value: 'Dilatrate-SR (isosorbide dinitrate)',
  },
  {
    name: 'Dilaudid Injection and HP Injection (hydromorphone hydrochloride)',
    value: 'Dilaudid Injection and HP Injection (hydromorphone hydrochloride)',
  },
  {
    name: 'Dilaudid Liquid and Tablets (hydromorphone hydrochloride)',
    value: 'Dilaudid Liquid and Tablets (hydromorphone hydrochloride)',
  },
  {
    name: 'Diltiazem Hydrochloride Injection (diltiazem hydrochloride)',
    value: 'Diltiazem Hydrochloride Injection (diltiazem hydrochloride)',
  },
  {
    name: 'Diltiazem Hydrochloride Tablets (diltiazem hydrochloride)',
    value: 'Diltiazem Hydrochloride Tablets (diltiazem hydrochloride)',
  },
  { name: 'Diovan (valsartan)', value: 'Diovan (valsartan)' },
  {
    name: 'Diovan HCT (hydrochlorothiazide/valsartan)',
    value: 'Diovan HCT (hydrochlorothiazide/valsartan)',
  },
  {
    name: 'Dipentum (olsalazine sodium)',
    value: 'Dipentum (olsalazine sodium)',
  },
  {
    name: 'Diphenhydramine Hydrochloride (diphenhydramine hydrochloride)',
    value: 'Diphenhydramine Hydrochloride (diphenhydramine hydrochloride)',
  },
  { name: 'Diprivan (propofol)', value: 'Diprivan (propofol)' },
  {
    name: 'Diprolene AF Cream (betamethasone dipropionate)',
    value: 'Diprolene AF Cream (betamethasone dipropionate)',
  },
  {
    name: 'Diprolene Lotion (betamethasone dipropionate)',
    value: 'Diprolene Lotion (betamethasone dipropionate)',
  },
  {
    name: 'Diprolene Ointment (betamethasone dipropionate)',
    value: 'Diprolene Ointment (betamethasone dipropionate)',
  },
  { name: 'Dipyridamole (dipyridamole)', value: 'Dipyridamole (dipyridamole)' },
  {
    name: 'Diskets (methadone hydrochloride)',
    value: 'Diskets (methadone hydrochloride)',
  },
  {
    name: 'Ditropan XL (oxybutynin chloride)',
    value: 'Ditropan XL (oxybutynin chloride)',
  },
  {
    name: 'Diuril Oral Suspension (chlorothiazide)',
    value: 'Diuril Oral Suspension (chlorothiazide)',
  },
  { name: 'Divigel (estradiol)', value: 'Divigel (estradiol)' },
  {
    name: 'Dobutamine (dobutamine hydrochloride)',
    value: 'Dobutamine (dobutamine hydrochloride)',
  },
  {
    name: 'Dobutamine Hydrochloride in 5% Dextrose (dobutamine hydrochloride)',
    value: 'Dobutamine Hydrochloride in 5% Dextrose (dobutamine hydrochloride)',
  },
  { name: 'Docefrez (docetaxel)', value: 'Docefrez (docetaxel)' },
  {
    name: 'Docetaxel Injection (docetaxel)',
    value: 'Docetaxel Injection (docetaxel)',
  },
  {
    name: 'Docetaxel Injection Concentrate (docetaxel)',
    value: 'Docetaxel Injection Concentrate (docetaxel)',
  },
  { name: 'Dofetilide (dofetilide)', value: 'Dofetilide (dofetilide)' },
  {
    name: 'Dolophine (methadone hydrochloride)',
    value: 'Dolophine (methadone hydrochloride)',
  },
  {
    name: 'Donnatal Elixir (atropine sulfate/hyoscyamine sulfate/phenobarbital/scopolamine hydrob etc.',
    value:
      'Donnatal Elixir (atropine sulfate/hyoscyamine sulfate/phenobarbital/scopolamine hydrob etc.',
  },
  {
    name: 'Donnatal Tablets (atropine sulfate/hyoscyamine sulfate/phenobarbital/scopolamine hydrob etc.',
    value:
      'Donnatal Tablets (atropine sulfate/hyoscyamine sulfate/phenobarbital/scopolamine hydrob etc.',
  },
  {
    name: 'Dopamine Hydrochloride (dopamine hydrochloride)',
    value: 'Dopamine Hydrochloride (dopamine hydrochloride)',
  },
  {
    name: 'Dopamine Hydrochloride and 5% Dextrose (dopamine hydrochloride/dextrose)',
    value:
      'Dopamine Hydrochloride and 5% Dextrose (dopamine hydrochloride/dextrose)',
  },
  { name: 'Doral (quazepam)', value: 'Doral (quazepam)' },
  { name: 'Doribax (doripenem)', value: 'Doribax (doripenem)' },
  { name: 'Doryx (doxycycline hyclate)', value: 'Doryx (doxycycline hyclate)' },
  {
    name: 'Doryx MPC (doxycycline hyclate)',
    value: 'Doryx MPC (doxycycline hyclate)',
  },
  {
    name: 'Dotarem Injection (gadoterate meglumine)',
    value: 'Dotarem Injection (gadoterate meglumine)',
  },
  {
    name: 'Dovato (dolutegravir/lamivudine)',
    value: 'Dovato (dolutegravir/lamivudine)',
  },
  {
    name: 'Dovonex Cream (calcipotriene)',
    value: 'Dovonex Cream (calcipotriene)',
  },
  {
    name: 'Doxepin Hydrochloride Oral Solution (doxepin hydrochloride)',
    value: 'Doxepin Hydrochloride Oral Solution (doxepin hydrochloride)',
  },
  {
    name: 'Doxil (doxorubicin hydrochloride liposome)',
    value: 'Doxil (doxorubicin hydrochloride liposome)',
  },
  {
    name: 'Doxorubicin Hydrochloride (doxorubicin hydrochloride)',
    value: 'Doxorubicin Hydrochloride (doxorubicin hydrochloride)',
  },
  {
    name: 'Doxorubicin Hydrochloride Liposome (doxorubicin hydrochloride liposome)',
    value:
      'Doxorubicin Hydrochloride Liposome (doxorubicin hydrochloride liposome)',
  },
  { name: 'Doxy 100 (doxycycline)', value: 'Doxy 100 (doxycycline)' },
  { name: 'Doxycycline (doxycycline)', value: 'Doxycycline (doxycycline)' },
  {
    name: 'Doxycycline Hyclate Capsules (doxycycline hyclate)',
    value: 'Doxycycline Hyclate Capsules (doxycycline hyclate)',
  },
  {
    name: 'Doxycycline Hyclate Delayed-Release Tablets (doxycycline hyclate)',
    value: 'Doxycycline Hyclate Delayed-Release Tablets (doxycycline hyclate)',
  },
  {
    name: 'Doxycycline Hyclate Tablets (doxycycline hyclate)',
    value: 'Doxycycline Hyclate Tablets (doxycycline hyclate)',
  },
  {
    name: 'Doxylamine Succinate and Pyridoxine Hydrochloride (doxylamine succinate /pyridoxine hydrochloride)',
    value:
      'Doxylamine Succinate and Pyridoxine Hydrochloride (doxylamine succinate /pyridoxine hydrochloride)',
  },
  { name: 'Drisdol (ergocalciferol)', value: 'Drisdol (ergocalciferol)' },
  { name: 'Droperidol (droperidol)', value: 'Droperidol (droperidol)' },
  { name: 'Droxia (hydroxyurea)', value: 'Droxia (hydroxyurea)' },
  {
    name: 'Duac (benzoyl peroxide/clindamycin phosphate)',
    value: 'Duac (benzoyl peroxide/clindamycin phosphate)',
  },
  {
    name: 'Duavee (bazedoxifene/conjugated estrogens)',
    value: 'Duavee (bazedoxifene/conjugated estrogens)',
  },
  {
    name: 'Duetact (glimepiride/pioglitazone)',
    value: 'Duetact (glimepiride/pioglitazone)',
  },
  {
    name: 'Duexis (famotidine/ibuprofen)',
    value: 'Duexis (famotidine/ibuprofen)',
  },
  {
    name: 'Dulera (formoterol fumarate dihydrate/mometasone furoate)',
    value: 'Dulera (formoterol fumarate dihydrate/mometasone furoate)',
  },
  {
    name: 'Duobrii (halobetasol propionate/tazarotene)',
    value: 'Duobrii (halobetasol propionate/tazarotene)',
  },
  {
    name: 'DuoNeb (albuterol sulfate/ipratropium bromide)',
    value: 'DuoNeb (albuterol sulfate/ipratropium bromide)',
  },
  { name: 'Duopa (carbidopa/levodopa)', value: 'Duopa (carbidopa/levodopa)' },
  { name: 'Dupixent (dupilumab)', value: 'Dupixent (dupilumab)' },
  {
    name: 'Duraclon (clonidine hydrochloride)',
    value: 'Duraclon (clonidine hydrochloride)',
  },
  { name: 'Duragesic (fentanyl)', value: 'Duragesic (fentanyl)' },
  {
    name: 'Duramorph (morphine sulfate)',
    value: 'Duramorph (morphine sulfate)',
  },
  { name: 'Durezol (difluprednate)', value: 'Durezol (difluprednate)' },
  { name: 'Durlaza (aspirin)', value: 'Durlaza (aspirin)' },
  { name: 'Durysta (bimatoprost)', value: 'Durysta (bimatoprost)' },
  {
    name: 'Dutoprol (hydrochlorothiazide/metoprolol succinate)',
    value: 'Dutoprol (hydrochlorothiazide/metoprolol succinate)',
  },
  {
    name: 'Duzallo (allopurinol/lesinurad)',
    value: 'Duzallo (allopurinol/lesinurad)',
  },
  {
    name: 'Dvorah (acetaminophen/caffeine/dihydrocodeine bitartrate)',
    value: 'Dvorah (acetaminophen/caffeine/dihydrocodeine bitartrate)',
  },
  { name: 'Dyanavel XR (amphetamine)', value: 'Dyanavel XR (amphetamine)' },
  {
    name: 'Dyazide (hydrochlorothiazide/triamterene)',
    value: 'Dyazide (hydrochlorothiazide/triamterene)',
  },
  {
    name: 'Dyloject (diclofenac sodium)',
    value: 'Dyloject (diclofenac sodium)',
  },
  {
    name: 'Dymista (azelastine hydrochloride/fluticasone propionate)',
    value: 'Dymista (azelastine hydrochloride/fluticasone propionate)',
  },
  { name: 'Dyrenium (triamterene)', value: 'Dyrenium (triamterene)' },
  {
    name: 'Dysport (abobotulinumtoxinA)',
    value: 'Dysport (abobotulinumtoxinA)',
  },
  {
    name: 'E.E.S. (erythromycin ethylsuccinate)',
    value: 'E.E.S. (erythromycin ethylsuccinate)',
  },
  {
    name: 'Easy Sleep (dietary supplement)',
    value: 'Easy Sleep (dietary supplement)',
  },
  {
    name: 'Econazole Nitrate (econazole nitrate)',
    value: 'Econazole Nitrate (econazole nitrate)',
  },
  { name: 'Ecoza (econazole nitrate)', value: 'Ecoza (econazole nitrate)' },
  {
    name: 'Edarbi (azilsartan medoxomil)',
    value: 'Edarbi (azilsartan medoxomil)',
  },
  {
    name: 'Edarbyclor (azilsartan medoxomil/chlorthalidone)',
    value: 'Edarbyclor (azilsartan medoxomil/chlorthalidone)',
  },
  {
    name: 'Edecrin/Sodium Edecrin (ethacrynate sodium); (ethacrynic acid)',
    value: 'Edecrin/Sodium Edecrin (ethacrynate sodium); (ethacrynic acid)',
  },
  { name: 'Edex (alprostadil)', value: 'Edex (alprostadil)' },
  { name: 'Edluar (zolpidem tartrate)', value: 'Edluar (zolpidem tartrate)' },
  { name: 'Edurant (rilpivirine)', value: 'Edurant (rilpivirine)' },
  { name: 'Effexor XR (venlafaxine)', value: 'Effexor XR (venlafaxine)' },
  { name: 'Effient (prasugrel)', value: 'Effient (prasugrel)' },
  { name: 'Efudex (fluorouracil)', value: 'Efudex (fluorouracil)' },
  { name: 'Egaten (triclabendazole)', value: 'Egaten (triclabendazole)' },
  { name: 'Egrifta (tesamorelin)', value: 'Egrifta (tesamorelin)' },
  { name: 'Elaprase (idursulfase)', value: 'Elaprase (idursulfase)' },
  {
    name: 'Eldepryl Capsules (selegiline hydrochloride)',
    value: 'Eldepryl Capsules (selegiline hydrochloride)',
  },
  {
    name: 'Elelyso (taliglucerase alfa)',
    value: 'Elelyso (taliglucerase alfa)',
  },
  {
    name: 'Elestat (epinastine hydrochloride)',
    value: 'Elestat (epinastine hydrochloride)',
  },
  { name: 'Elestrin (estradiol)', value: 'Elestrin (estradiol)' },
  { name: 'Elidel (pimecrolimus)', value: 'Elidel (pimecrolimus)' },
  {
    name: 'Eligard (leuprolide acetate)',
    value: 'Eligard (leuprolide acetate)',
  },
  { name: 'Elimite (permethrin)', value: 'Elimite (permethrin)' },
  { name: 'Eliphos (calcium acetate)', value: 'Eliphos (calcium acetate)' },
  { name: 'Eliquis (apixaban)', value: 'Eliquis (apixaban)' },
  { name: 'Elitek (rasburicase)', value: 'Elitek (rasburicase)' },
  {
    name: 'Elixophyllin (theophylline anhydrous)',
    value: 'Elixophyllin (theophylline anhydrous)',
  },
  { name: 'Ella (ulipristal acetate)', value: 'Ella (ulipristal acetate)' },
  {
    name: 'Ellence (epirubicin hydrochloride)',
    value: 'Ellence (epirubicin hydrochloride)',
  },
  {
    name: 'Elmiron (pentosan polysulfate sodium)',
    value: 'Elmiron (pentosan polysulfate sodium)',
  },
  {
    name: 'Elocon Cream (mometasone furoate)',
    value: 'Elocon Cream (mometasone furoate)',
  },
  {
    name: 'Elocon Lotion (mometasone furoate)',
    value: 'Elocon Lotion (mometasone furoate)',
  },
  {
    name: 'Elocon Ointment (mometasone furoate)',
    value: 'Elocon Ointment (mometasone furoate)',
  },
  {
    name: 'Eloctate (antihemophilic factor (recombinant), fc fusion protein)',
    value: 'Eloctate (antihemophilic factor (recombinant), fc fusion protein)',
  },
  { name: 'Eloxatin (oxaliplatin)', value: 'Eloxatin (oxaliplatin)' },
  {
    name: 'Emadine (emedastine difumarate)',
    value: 'Emadine (emedastine difumarate)',
  },
  {
    name: 'Embeda (morphine sulfate/naltrexone hydrochloride)',
    value: 'Embeda (morphine sulfate/naltrexone hydrochloride)',
  },
  {
    name: 'Emcyt (estramustine phosphate sodium)',
    value: 'Emcyt (estramustine phosphate sodium)',
  },
  {
    name: 'Emend Capsules and Oral Suspension (aprepitant)',
    value: 'Emend Capsules and Oral Suspension (aprepitant)',
  },
  {
    name: 'Emend for Injection (fosaprepitant dimeglumine)',
    value: 'Emend for Injection (fosaprepitant dimeglumine)',
  },
  { name: 'Emflaza (deflazacort)', value: 'Emflaza (deflazacort)' },
  {
    name: 'Emgality (galcanezumab-gnlm)',
    value: 'Emgality (galcanezumab-gnlm)',
  },
  {
    name: 'Emoquette (desogestrel/ethinyl estradiol)',
    value: 'Emoquette (desogestrel/ethinyl estradiol)',
  },
  { name: 'Empliciti (elotuzumab)', value: 'Empliciti (elotuzumab)' },
  { name: 'Emsam (selegiline)', value: 'Emsam (selegiline)' },
  { name: 'Emtriva (emtricitabine)', value: 'Emtriva (emtricitabine)' },
  { name: 'Emverm (mebendazole)', value: 'Emverm (mebendazole)' },
  { name: 'Enablex (darifenacin)', value: 'Enablex (darifenacin)' },
  {
    name: 'Enalapril Maleate and Hydrochlorothiazide (enalapril maleate/hydrochlorothiazide)',
    value:
      'Enalapril Maleate and Hydrochlorothiazide (enalapril maleate/hydrochlorothiazide)',
  },
  { name: 'Enbrel (etanercept)', value: 'Enbrel (etanercept)' },
  {
    name: 'Endocet (acetaminophen/oxycodone)',
    value: 'Endocet (acetaminophen/oxycodone)',
  },
  { name: 'Endodan (aspirin/oxycodone)', value: 'Endodan (aspirin/oxycodone)' },
  { name: 'Endometrin (progesterone)', value: 'Endometrin (progesterone)' },
  { name: 'Energy (dietary supplement)', value: 'Energy (dietary supplement)' },
  {
    name: 'Engerix-B (hepatitis B vaccine (recombinant))',
    value: 'Engerix-B (hepatitis B vaccine (recombinant))',
  },
  {
    name: 'Enjuvia (synthetic conjugated estrogens, B)',
    value: 'Enjuvia (synthetic conjugated estrogens, B)',
  },
  {
    name: 'Enlon (edrophonium chloride)',
    value: 'Enlon (edrophonium chloride)',
  },
  {
    name: 'Enlon-Plus (atropine sulfate/edrophonium chloride)',
    value: 'Enlon-Plus (atropine sulfate/edrophonium chloride)',
  },
  {
    name: 'Enpresse 28 (ethinyl estradiol/levonorgestrel)',
    value: 'Enpresse 28 (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'Enstilar (betamethasone dipropionate/calcipotriene)',
    value: 'Enstilar (betamethasone dipropionate/calcipotriene)',
  },
  { name: 'Entereg (alvimopan)', value: 'Entereg (alvimopan)' },
  {
    name: 'Entex T (guaifenesin/pseudoephedrine hydrochloride)',
    value: 'Entex T (guaifenesin/pseudoephedrine hydrochloride)',
  },
  { name: 'Entocort EC (budesonide)', value: 'Entocort EC (budesonide)' },
  {
    name: 'Entresto (sacubitril/valsartan)',
    value: 'Entresto (sacubitril/valsartan)',
  },
  { name: 'Entyvio (vedolizumab)', value: 'Entyvio (vedolizumab)' },
  { name: 'Enulose (lactulose)', value: 'Enulose (lactulose)' },
  { name: 'Envarsus XR (tacrolimus)', value: 'Envarsus XR (tacrolimus)' },
  {
    name: 'Eovist (gadoxetate disodium)',
    value: 'Eovist (gadoxetate disodium)',
  },
  { name: 'Epaned (enalapril)', value: 'Epaned (enalapril)' },
  {
    name: 'Epclusa (sofosbuvir/velpatasvir)',
    value: 'Epclusa (sofosbuvir/velpatasvir)',
  },
  {
    name: 'Epiduo (adapalene/benzoyl peroxide)',
    value: 'Epiduo (adapalene/benzoyl peroxide)',
  },
  {
    name: 'Epiduo Forte (adapalene/benzoyl peroxide)',
    value: 'Epiduo Forte (adapalene/benzoyl peroxide)',
  },
  { name: 'EpiPen (epinephrine)', value: 'EpiPen (epinephrine)' },
  { name: 'Epitol (carbamazepine)', value: 'Epitol (carbamazepine)' },
  { name: 'Epivir (lamivudine)', value: 'Epivir (lamivudine)' },
  { name: 'Epivir-HBV (lamivudine)', value: 'Epivir-HBV (lamivudine)' },
  { name: 'Epogen (epoetin alfa)', value: 'Epogen (epoetin alfa)' },
  { name: 'Eptifibatide (eptifibatide)', value: 'Eptifibatide (eptifibatide)' },
  {
    name: 'Epzicom (abacavir sulfate/lamivudine)',
    value: 'Epzicom (abacavir sulfate/lamivudine)',
  },
  { name: 'Equetro (carbamazepine)', value: 'Equetro (carbamazepine)' },
  { name: 'Eraxis (anidulafungin)', value: 'Eraxis (anidulafungin)' },
  { name: 'Erbitux (cetuximab)', value: 'Erbitux (cetuximab)' },
  {
    name: 'Ergocalciferol (ergocalciferol)',
    value: 'Ergocalciferol (ergocalciferol)',
  },
  {
    name: 'Ergoloid Mesylates (ergoloid mesylates)',
    value: 'Ergoloid Mesylates (ergoloid mesylates)',
  },
  {
    name: 'Ergomar (ergotamine tartrate)',
    value: 'Ergomar (ergotamine tartrate)',
  },
  { name: 'Erivedge (vismodegib)', value: 'Erivedge (vismodegib)' },
  { name: 'Erleada (apalutamide)', value: 'Erleada (apalutamide)' },
  { name: 'Erlotinib (erlotinib)', value: 'Erlotinib (erlotinib)' },
  {
    name: 'Erlotinib Hydrochloride (erlotinib hydrochloride)',
    value: 'Erlotinib Hydrochloride (erlotinib hydrochloride)',
  },
  { name: 'Errin (norethindrone)', value: 'Errin (norethindrone)' },
  {
    name: 'Ertaczo (sertaconazole nitrate)',
    value: 'Ertaczo (sertaconazole nitrate)',
  },
  {
    name: 'Erwinaze (asparaginase erwinia chrysanthemi)',
    value: 'Erwinaze (asparaginase erwinia chrysanthemi)',
  },
  {
    name: 'Ery-Ped (erythromycin ethylsuccinate)',
    value: 'Ery-Ped (erythromycin ethylsuccinate)',
  },
  { name: 'Ery-Tab (erythromycin)', value: 'Ery-Tab (erythromycin)' },
  {
    name: 'Erythrocin Lactobionate-I.V. (erythromycin lactobionate)',
    value: 'Erythrocin Lactobionate-I.V. (erythromycin lactobionate)',
  },
  {
    name: 'Erythrocin Stearate (erythromycin stearate)',
    value: 'Erythrocin Stearate (erythromycin stearate)',
  },
  {
    name: 'Erythromycin Ethylsuccinate (erythromycin ethylsuccinate)',
    value: 'Erythromycin Ethylsuccinate (erythromycin ethylsuccinate)',
  },
  {
    name: 'Erythromycin Ophthalmic Ointment (erythromycin)',
    value: 'Erythromycin Ophthalmic Ointment (erythromycin)',
  },
  {
    name: 'Erythromycin Pledgets (erythromycin)',
    value: 'Erythromycin Pledgets (erythromycin)',
  },
  {
    name: 'Erythromycin Topical Gel (erythromycin)',
    value: 'Erythromycin Topical Gel (erythromycin)',
  },
  {
    name: 'Erythromycin Topical Solution (erythromycin)',
    value: 'Erythromycin Topical Solution (erythromycin)',
  },
  { name: 'Esbriet (pirfenidone)', value: 'Esbriet (pirfenidone)' },
  {
    name: 'Esgic Capsules (acetaminophen/butalbital/caffeine)',
    value: 'Esgic Capsules (acetaminophen/butalbital/caffeine)',
  },
  {
    name: 'Esgic Tablets (acetaminophen/butalbital/caffeine)',
    value: 'Esgic Tablets (acetaminophen/butalbital/caffeine)',
  },
  {
    name: 'Esomeprazole Strontium (esomeprazole strontium)',
    value: 'Esomeprazole Strontium (esomeprazole strontium)',
  },
  {
    name: 'Essential Greens (dietary supplement)',
    value: 'Essential Greens (dietary supplement)',
  },
  { name: 'Estazolam (estazolam)', value: 'Estazolam (estazolam)' },
  {
    name: 'Esterified Estrogens and Methyltestosterone (esterified estrogens/methyltestosterone)',
    value:
      'Esterified Estrogens and Methyltestosterone (esterified estrogens/methyltestosterone)',
  },
  { name: 'Estrace Tablets (estradiol)', value: 'Estrace Tablets (estradiol)' },
  {
    name: 'Estrace Vaginal Cream (estradiol)',
    value: 'Estrace Vaginal Cream (estradiol)',
  },
  { name: 'Estradiol (estradiol)', value: 'Estradiol (estradiol)' },
  {
    name: 'Estradiol and Norethindrone Acetate (estradiol/norethindrone acetate)',
    value:
      'Estradiol and Norethindrone Acetate (estradiol/norethindrone acetate)',
  },
  { name: 'Estring (estradiol)', value: 'Estring (estradiol)' },
  { name: 'EstroGel (estradiol)', value: 'EstroGel (estradiol)' },
  { name: 'Estropipate (estropipate)', value: 'Estropipate (estropipate)' },
  {
    name: 'Estrostep Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
    value:
      'Estrostep Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
  },
  { name: 'Ethyol (amifostine)', value: 'Ethyol (amifostine)' },
  {
    name: 'Etidronate Disodium (etidronate disodium)',
    value: 'Etidronate Disodium (etidronate disodium)',
  },
  {
    name: 'Etodolac Capsules and Tablets (etodolac)',
    value: 'Etodolac Capsules and Tablets (etodolac)',
  },
  {
    name: 'Etodolac Extended-Release Tablets (etodolac)',
    value: 'Etodolac Extended-Release Tablets (etodolac)',
  },
  {
    name: 'Etopophos (etoposide phosphate)',
    value: 'Etopophos (etoposide phosphate)',
  },
  {
    name: 'Etoposide Capsules (etoposide)',
    value: 'Etoposide Capsules (etoposide)',
  },
  { name: 'Eucrisa (crisaborole)', value: 'Eucrisa (crisaborole)' },
  { name: 'Eurax (crotamiton)', value: 'Eurax (crotamiton)' },
  { name: 'Evamist (estradiol)', value: 'Evamist (estradiol)' },
  {
    name: 'Evekeo (amphetamine sulfate)',
    value: 'Evekeo (amphetamine sulfate)',
  },
  { name: 'Evenity (romosozumab-aqqg)', value: 'Evenity (romosozumab-aqqg)' },
  {
    name: 'Evista (raloxifene hydrochloride)',
    value: 'Evista (raloxifene hydrochloride)',
  },
  {
    name: 'Evoclin (clindamycin phosphate)',
    value: 'Evoclin (clindamycin phosphate)',
  },
  { name: 'Evomela (melphalan)', value: 'Evomela (melphalan)' },
  {
    name: 'Evotaz (atazanavir/cobicistat)',
    value: 'Evotaz (atazanavir/cobicistat)',
  },
  {
    name: 'Evoxac (cevimeline hydrochloride)',
    value: 'Evoxac (cevimeline hydrochloride)',
  },
  {
    name: 'Evzio 0.4 mg (naloxone hydrochloride)',
    value: 'Evzio 0.4 mg (naloxone hydrochloride)',
  },
  {
    name: 'Evzio 2 mg (naloxone hydrochloride)',
    value: 'Evzio 2 mg (naloxone hydrochloride)',
  },
  {
    name: 'Exalgo (hydromorphone hydrochloride)',
    value: 'Exalgo (hydromorphone hydrochloride)',
  },
  {
    name: 'Excedrin Extra Strength (acetaminophen/aspirin/caffeine)',
    value: 'Excedrin Extra Strength (acetaminophen/aspirin/caffeine)',
  },
  {
    name: 'Excedrin Migraine (acetaminophen/aspirin/caffeine)',
    value: 'Excedrin Migraine (acetaminophen/aspirin/caffeine)',
  },
  {
    name: 'Excedrin Migraine Geltabs (acetaminophen/aspirin/caffeine)',
    value: 'Excedrin Migraine Geltabs (acetaminophen/aspirin/caffeine)',
  },
  {
    name: 'Exelderm Cream (sulconazole nitrate)',
    value: 'Exelderm Cream (sulconazole nitrate)',
  },
  {
    name: 'Exelderm Solution (sulconazole nitrate)',
    value: 'Exelderm Solution (sulconazole nitrate)',
  },
  {
    name: 'Exelon Capsules and Oral Solution (rivastigmine tartrate)',
    value: 'Exelon Capsules and Oral Solution (rivastigmine tartrate)',
  },
  { name: 'Exelon Patch (rivastigmine)', value: 'Exelon Patch (rivastigmine)' },
  {
    name: 'Exforge (amlodipine/valsartan)',
    value: 'Exforge (amlodipine/valsartan)',
  },
  {
    name: 'Exforge HCT (amlodipine/hydrochlorothiazide/valsartan)',
    value: 'Exforge HCT (amlodipine/hydrochlorothiazide/valsartan)',
  },
  { name: 'Exjade (deferasirox)', value: 'Exjade (deferasirox)' },
  { name: 'Exondys 51 (eteplirsen)', value: 'Exondys 51 (eteplirsen)' },
  {
    name: 'Exparel (bupivacaine liposome)',
    value: 'Exparel (bupivacaine liposome)',
  },
  {
    name: 'Extavia (interferon beta-1b)',
    value: 'Extavia (interferon beta-1b)',
  },
  { name: 'Eylea (aflibercept)', value: 'Eylea (aflibercept)' },
  { name: 'Fabior (tazarotene)', value: 'Fabior (tazarotene)' },
  { name: 'Fabrazyme (agalsidase beta)', value: 'Fabrazyme (agalsidase beta)' },
  {
    name: 'Famotidine Injection (famotidine)',
    value: 'Famotidine Injection (famotidine)',
  },
  { name: 'Famvir (famciclovir)', value: 'Famvir (famciclovir)' },
  { name: 'Fanapt (iloperidone)', value: 'Fanapt (iloperidone)' },
  {
    name: 'Fareston (toremifene citrate)',
    value: 'Fareston (toremifene citrate)',
  },
  { name: 'Farxiga (dapagliflozin)', value: 'Farxiga (dapagliflozin)' },
  { name: 'Farydak (panobinostat)', value: 'Farydak (panobinostat)' },
  { name: 'Fasenra (benralizumab)', value: 'Fasenra (benralizumab)' },
  { name: 'Faslodex (fulvestrant)', value: 'Faslodex (fulvestrant)' },
  {
    name: 'Fayosim (ethinyl estradiol/levonorgestrel)',
    value: 'Fayosim (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'FazaClo (clozapine)', value: 'FazaClo (clozapine)' },
  { name: 'Febuxostat (febuxostat)', value: 'Febuxostat (febuxostat)' },
  {
    name: 'Feiba NF (anti-inhibitor coagulant complex)',
    value: 'Feiba NF (anti-inhibitor coagulant complex)',
  },
  { name: 'Felbatol (felbamate)', value: 'Felbatol (felbamate)' },
  { name: 'Feldene (piroxicam)', value: 'Feldene (piroxicam)' },
  { name: 'Felodipine (felodipine)', value: 'Felodipine (felodipine)' },
  { name: 'Femara (letrozole)', value: 'Femara (letrozole)' },
  {
    name: 'Femhrt (ethinyl estradiol/norethindrone acetate)',
    value: 'Femhrt (ethinyl estradiol/norethindrone acetate)',
  },
  { name: 'Femring (estradiol acetate)', value: 'Femring (estradiol acetate)' },
  { name: 'Fenoglide (fenofibrate)', value: 'Fenoglide (fenofibrate)' },
  {
    name: 'Fenoprofen Calcium (fenoprofen calcium)',
    value: 'Fenoprofen Calcium (fenoprofen calcium)',
  },
  {
    name: 'Fentanyl Citrate (fentanyl citrate)',
    value: 'Fentanyl Citrate (fentanyl citrate)',
  },
  { name: 'Fentora (fentanyl)', value: 'Fentora (fentanyl)' },
  { name: 'Feraheme (ferumoxytol)', value: 'Feraheme (ferumoxytol)' },
  {
    name: 'Ferriprox Oral Solution (deferiprone)',
    value: 'Ferriprox Oral Solution (deferiprone)',
  },
  {
    name: 'Ferriprox Tablets (deferiprone)',
    value: 'Ferriprox Tablets (deferiprone)',
  },
  {
    name: 'Ferrlecit (sodium ferric gluconate complex)',
    value: 'Ferrlecit (sodium ferric gluconate complex)',
  },
  { name: 'Fetzima (levomilnacipran)', value: 'Fetzima (levomilnacipran)' },
  { name: 'Fibricor (fenofibric acid)', value: 'Fibricor (fenofibric acid)' },
  { name: 'Finacea Foam (azelaic acid)', value: 'Finacea Foam (azelaic acid)' },
  { name: 'Finacea Gel (azelaic acid)', value: 'Finacea Gel (azelaic acid)' },
  {
    name: 'Fioricet Capsules (acetaminophen/butalbital/caffeine)',
    value: 'Fioricet Capsules (acetaminophen/butalbital/caffeine)',
  },
  {
    name: 'Fiorinal (aspirin/butalbital/caffeine)',
    value: 'Fiorinal (aspirin/butalbital/caffeine)',
  },
  {
    name: 'Fiorinal with Codeine (aspirin/butalbital/caffeine/codeine phosphate)',
    value:
      'Fiorinal with Codeine (aspirin/butalbital/caffeine/codeine phosphate)',
  },
  { name: 'Firazyr (icatibant)', value: 'Firazyr (icatibant)' },
  { name: 'Firmagon (degarelix)', value: 'Firmagon (degarelix)' },
  {
    name: 'Flagyl Capsules (metronidazole)',
    value: 'Flagyl Capsules (metronidazole)',
  },
  { name: 'Flagyl ER (metronidazole)', value: 'Flagyl ER (metronidazole)' },
  {
    name: 'Flagyl Tablets (metronidazole)',
    value: 'Flagyl Tablets (metronidazole)',
  },
  {
    name: 'Flarex (fluorometholone acetate)',
    value: 'Flarex (fluorometholone acetate)',
  },
  {
    name: 'Flavoxate Hydrochloride (flavoxate hydrochloride)',
    value: 'Flavoxate Hydrochloride (flavoxate hydrochloride)',
  },
  {
    name: 'Flebogamma 10% DIF (immune globulin intravenous (human))',
    value: 'Flebogamma 10% DIF (immune globulin intravenous (human))',
  },
  {
    name: 'Flebogamma 5% DIF (immune globulin intravenous (human))',
    value: 'Flebogamma 5% DIF (immune globulin intravenous (human))',
  },
  {
    name: 'Flecainide Acetate (flecainide acetate)',
    value: 'Flecainide Acetate (flecainide acetate)',
  },
  {
    name: 'Flector (diclofenac epolamine)',
    value: 'Flector (diclofenac epolamine)',
  },
  {
    name: 'Fleet Bisacodyl Enema (bisacodyl)',
    value: 'Fleet Bisacodyl Enema (bisacodyl)',
  },
  {
    name: 'Fleet Bisacodyl Tablets (bisacodyl)',
    value: 'Fleet Bisacodyl Tablets (bisacodyl)',
  },
  {
    name: 'Fleet Mineral Oil Enema (mineral oil)',
    value: 'Fleet Mineral Oil Enema (mineral oil)',
  },
  {
    name: 'Fleet Pedia-Lax Chewable Tablets (magnesium hydroxide)',
    value: 'Fleet Pedia-Lax Chewable Tablets (magnesium hydroxide)',
  },
  {
    name: 'Fleet Pedia-Lax Enema (dibasic sodium phosphate/monobasic sodium phosphate)',
    value:
      'Fleet Pedia-Lax Enema (dibasic sodium phosphate/monobasic sodium phosphate)',
  },
  {
    name: 'Fleet Pedia-Lax Liquid (docusate sodium)',
    value: 'Fleet Pedia-Lax Liquid (docusate sodium)',
  },
  {
    name: 'Fleet Pedia-Lax Liquid Glycerin Suppositories (glycerin)',
    value: 'Fleet Pedia-Lax Liquid Glycerin Suppositories (glycerin)',
  },
  {
    name: 'Fleet Saline Enema (dibasic sodium phosphate/monobasic sodium phosphate)',
    value:
      'Fleet Saline Enema (dibasic sodium phosphate/monobasic sodium phosphate)',
  },
  {
    name: 'Flexbumin 25% (albumin (human))',
    value: 'Flexbumin 25% (albumin (human))',
  },
  {
    name: 'Flexbumin 5% (albumin (human))',
    value: 'Flexbumin 5% (albumin (human))',
  },
  {
    name: 'Flolan (epoprostenol sodium)',
    value: 'Flolan (epoprostenol sodium)',
  },
  {
    name: 'Flomax (tamsulosin hydrochloride)',
    value: 'Flomax (tamsulosin hydrochloride)',
  },
  {
    name: 'Flonase (fluticasone propionate)',
    value: 'Flonase (fluticasone propionate)',
  },
  {
    name: 'Flonase Allergy Relief (fluticasone propionate)',
    value: 'Flonase Allergy Relief (fluticasone propionate)',
  },
  {
    name: 'Flonase Sensimist Allergy Relief (fluticasone furoate)',
    value: 'Flonase Sensimist Allergy Relief (fluticasone furoate)',
  },
  {
    name: 'Flovent Diskus (fluticasone propionate)',
    value: 'Flovent Diskus (fluticasone propionate)',
  },
  {
    name: 'Flovent HFA (fluticasone propionate)',
    value: 'Flovent HFA (fluticasone propionate)',
  },
  {
    name: 'Flowtuss (guaifenesin/hydrocodone bitartrate)',
    value: 'Flowtuss (guaifenesin/hydrocodone bitartrate)',
  },
  { name: 'Floxuridine (floxuridine)', value: 'Floxuridine (floxuridine)' },
  {
    name: 'Fluad (influenza vaccine, adjuvanted)',
    value: 'Fluad (influenza vaccine, adjuvanted)',
  },
  {
    name: 'Fluarix Quadrivalent (influenza vaccine)',
    value: 'Fluarix Quadrivalent (influenza vaccine)',
  },
  { name: 'Flublok (influenza vaccine)', value: 'Flublok (influenza vaccine)' },
  {
    name: 'Flublok Quadrivalent (influenza vaccine)',
    value: 'Flublok Quadrivalent (influenza vaccine)',
  },
  {
    name: 'Flucelvax Quadrivalent (influenza vaccine)',
    value: 'Flucelvax Quadrivalent (influenza vaccine)',
  },
  {
    name: 'Fluconazole Injection (fluconazole)',
    value: 'Fluconazole Injection (fluconazole)',
  },
  {
    name: 'Fludarabine Phosphate for Injection (fludarabine phosphate)',
    value: 'Fludarabine Phosphate for Injection (fludarabine phosphate)',
  },
  {
    name: 'Fludarabine Phosphate Injection (fludarabine phosphate)',
    value: 'Fludarabine Phosphate Injection (fludarabine phosphate)',
  },
  {
    name: 'Fludrocortisone Acetate (fludrocortisone acetate)',
    value: 'Fludrocortisone Acetate (fludrocortisone acetate)',
  },
  {
    name: 'Flulaval (influenza vaccine)',
    value: 'Flulaval (influenza vaccine)',
  },
  {
    name: 'Flulaval Quadrivalent (influenza vaccine)',
    value: 'Flulaval Quadrivalent (influenza vaccine)',
  },
  {
    name: 'Flumadine (rimantadine hydrochloride)',
    value: 'Flumadine (rimantadine hydrochloride)',
  },
  { name: 'Flumazenil (flumazenil)', value: 'Flumazenil (flumazenil)' },
  {
    name: 'FluMist Quadrivalent (influenza vaccine live, intranasal)',
    value: 'FluMist Quadrivalent (influenza vaccine live, intranasal)',
  },
  {
    name: 'Flunisolide Nasal Solution (flunisolide)',
    value: 'Flunisolide Nasal Solution (flunisolide)',
  },
  {
    name: 'Fluocinolone Acetonide (fluocinolone acetonide)',
    value: 'Fluocinolone Acetonide (fluocinolone acetonide)',
  },
  {
    name: 'Fluocinonide Cream, Gel and Ointment (fluocinonide)',
    value: 'Fluocinonide Cream, Gel and Ointment (fluocinonide)',
  },
  {
    name: 'Fluocinonide Topical Solution (fluocinonide)',
    value: 'Fluocinonide Topical Solution (fluocinonide)',
  },
  {
    name: 'Fluor-a-day (sodium fluoride/xylitol)',
    value: 'Fluor-a-day (sodium fluoride/xylitol)',
  },
  { name: 'Fluorescite (fluorescein)', value: 'Fluorescite (fluorescein)' },
  {
    name: 'Fluoressa NMN 12000 (dietary supplement)',
    value: 'Fluoressa NMN 12000 (dietary supplement)',
  },
  {
    name: 'Fluoritab 0.5 mg (sodium fluoride)',
    value: 'Fluoritab 0.5 mg (sodium fluoride)',
  },
  { name: 'Fluoroplex (fluorouracil)', value: 'Fluoroplex (fluorouracil)' },
  {
    name: 'Fluoxetine Capsules (fluoxetine hydrochloride)',
    value: 'Fluoxetine Capsules (fluoxetine hydrochloride)',
  },
  {
    name: 'Fluoxetine Hydrochloride (fluoxetine hydrochloride)',
    value: 'Fluoxetine Hydrochloride (fluoxetine hydrochloride)',
  },
  {
    name: 'Fluoxetine Oral Solution (fluoxetine hydrochloride)',
    value: 'Fluoxetine Oral Solution (fluoxetine hydrochloride)',
  },
  {
    name: 'Fluoxetine Tablets (fluoxetine hydrochloride)',
    value: 'Fluoxetine Tablets (fluoxetine hydrochloride)',
  },
  {
    name: 'Fluoxetine Tablets, 60 mg (fluoxetine hydrochloride)',
    value: 'Fluoxetine Tablets, 60 mg (fluoxetine hydrochloride)',
  },
  {
    name: 'Fluphenazine Decanoate (fluphenazine decanoate)',
    value: 'Fluphenazine Decanoate (fluphenazine decanoate)',
  },
  {
    name: 'Fluphenazine Hydrochloride Elixir (fluphenazine hydrochloride)',
    value: 'Fluphenazine Hydrochloride Elixir (fluphenazine hydrochloride)',
  },
  {
    name: 'Fluphenazine Hydrochloride Injection (fluphenazine hydrochloride)',
    value: 'Fluphenazine Hydrochloride Injection (fluphenazine hydrochloride)',
  },
  {
    name: 'Fluphenazine Hydrochloride Oral Solution (fluphenazine hydrochloride)',
    value:
      'Fluphenazine Hydrochloride Oral Solution (fluphenazine hydrochloride)',
  },
  {
    name: 'Fluphenazine Hydrochloride Tablets (fluphenazine hydrochloride)',
    value: 'Fluphenazine Hydrochloride Tablets (fluphenazine hydrochloride)',
  },
  {
    name: 'Flurazepam Hydrochloride (flurazepam hydrochloride)',
    value: 'Flurazepam Hydrochloride (flurazepam hydrochloride)',
  },
  {
    name: 'Flurbiprofen Tablets (flurbiprofen)',
    value: 'Flurbiprofen Tablets (flurbiprofen)',
  },
  { name: 'Flutamide (flutamide)', value: 'Flutamide (flutamide)' },
  {
    name: 'Fluticasone Propionate Cream (fluticasone propionate)',
    value: 'Fluticasone Propionate Cream (fluticasone propionate)',
  },
  {
    name: 'Fluticasone Propionate Ointment (fluticasone propionate)',
    value: 'Fluticasone Propionate Ointment (fluticasone propionate)',
  },
  {
    name: 'Fluvirin (influenza virus vaccine)',
    value: 'Fluvirin (influenza virus vaccine)',
  },
  { name: 'Fluzone (influenza vaccine)', value: 'Fluzone (influenza vaccine)' },
  {
    name: 'Fluzone High-Dose (influenza vaccine)',
    value: 'Fluzone High-Dose (influenza vaccine)',
  },
  {
    name: 'FLUZONE High-Dose Quadrivalent (influenza a virus)',
    value: 'FLUZONE High-Dose Quadrivalent (influenza a virus)',
  },
  {
    name: 'Fluzone Intradermal (influenza vaccine)',
    value: 'Fluzone Intradermal (influenza vaccine)',
  },
  {
    name: 'Fluzone Intradermal Quadrivalent (influenza vaccine)',
    value: 'Fluzone Intradermal Quadrivalent (influenza vaccine)',
  },
  {
    name: 'Fluzone Quadrivalent (influenza vaccine)',
    value: 'Fluzone Quadrivalent (influenza vaccine)',
  },
  { name: 'FML Forte (fluorometholone)', value: 'FML Forte (fluorometholone)' },
  {
    name: 'FML Ophthalmic Ointment (fluorometholone)',
    value: 'FML Ophthalmic Ointment (fluorometholone)',
  },
  {
    name: 'FML Ophthalmic Suspension (fluorometholone)',
    value: 'FML Ophthalmic Suspension (fluorometholone)',
  },
  {
    name: 'Focalin (dexmethylphenidate hydrochloride)',
    value: 'Focalin (dexmethylphenidate hydrochloride)',
  },
  {
    name: 'Focalin XR (dexmethylphenidate hydrochloride)',
    value: 'Focalin XR (dexmethylphenidate hydrochloride)',
  },
  {
    name: 'Folic Acid Injection (folic acid)',
    value: 'Folic Acid Injection (folic acid)',
  },
  {
    name: 'Folic Acid Tablets (folic acid)',
    value: 'Folic Acid Tablets (folic acid)',
  },
  {
    name: 'Follistim AQ Cartridge (follitropin beta)',
    value: 'Follistim AQ Cartridge (follitropin beta)',
  },
  {
    name: 'Follistim AQ Injection (follitropin beta)',
    value: 'Follistim AQ Injection (follitropin beta)',
  },
  { name: 'Folotyn (pralatrexate)', value: 'Folotyn (pralatrexate)' },
  {
    name: 'Foradil (formoterol fumarate)',
    value: 'Foradil (formoterol fumarate)',
  },
  { name: 'Forane (isoflurane)', value: 'Forane (isoflurane)' },
  {
    name: 'Forfivo XL (bupropion hydrochloride)',
    value: 'Forfivo XL (bupropion hydrochloride)',
  },
  {
    name: 'Fortamet (metformin hydrochloride)',
    value: 'Fortamet (metformin hydrochloride)',
  },
  { name: 'Fortaz (ceftazidime)', value: 'Fortaz (ceftazidime)' },
  {
    name: 'Forteo (teriparatide (rDNA origin))',
    value: 'Forteo (teriparatide (rDNA origin))',
  },
  { name: 'Fortesta (testosterone)', value: 'Fortesta (testosterone)' },
  {
    name: 'Fortical (calcitonin-salmon (rDNA origin))',
    value: 'Fortical (calcitonin-salmon (rDNA origin))',
  },
  {
    name: 'Fosamax (alendronate sodium)',
    value: 'Fosamax (alendronate sodium)',
  },
  {
    name: 'Fosamax Plus D (alendronate sodium/cholecalciferol)',
    value: 'Fosamax Plus D (alendronate sodium/cholecalciferol)',
  },
  {
    name: 'Fosaprepitant (fosaprepitant)',
    value: 'Fosaprepitant (fosaprepitant)',
  },
  { name: 'Foscavir (foscarnet sodium)', value: 'Foscavir (foscarnet sodium)' },
  {
    name: 'Fosinopril Sodium (fosinopril sodium)',
    value: 'Fosinopril Sodium (fosinopril sodium)',
  },
  {
    name: 'Fosinopril Sodium and Hydrochlorothiazide (fosinopril sodium/hydrochlorothiazide)',
    value:
      'Fosinopril Sodium and Hydrochlorothiazide (fosinopril sodium/hydrochlorothiazide)',
  },
  {
    name: 'Fosrenol (lanthanum carbonate)',
    value: 'Fosrenol (lanthanum carbonate)',
  },
  { name: 'Fragmin (dalteparin sodium)', value: 'Fragmin (dalteparin sodium)' },
  {
    name: 'Frova (frovatriptan succinate)',
    value: 'Frova (frovatriptan succinate)',
  },
  { name: 'Fulvestrant (fulvestrant)', value: 'Fulvestrant (fulvestrant)' },
  { name: 'Furadantin (nitrofurantoin)', value: 'Furadantin (nitrofurantoin)' },
  {
    name: 'Furosemide Injection (furosemide)',
    value: 'Furosemide Injection (furosemide)',
  },
  {
    name: 'Furosemide Oral Solution and Tablets (furosemide)',
    value: 'Furosemide Oral Solution and Tablets (furosemide)',
  },
  { name: 'Fusilev (levoleucovorin)', value: 'Fusilev (levoleucovorin)' },
  { name: 'Fuzeon (enfuvirtide)', value: 'Fuzeon (enfuvirtide)' },
  {
    name: 'Fyavolv (ethinyl estradiol/norethindrone acetate)',
    value: 'Fyavolv (ethinyl estradiol/norethindrone acetate)',
  },
  { name: 'Fycompa (perampanel)', value: 'Fycompa (perampanel)' },
  {
    name: 'Gabitril (tiagabine hydrochloride)',
    value: 'Gabitril (tiagabine hydrochloride)',
  },
  { name: 'Gablofen (baclofen)', value: 'Gablofen (baclofen)' },
  { name: 'Gadavist (gadobutrol)', value: 'Gadavist (gadobutrol)' },
  { name: 'Galafold (migalastat)', value: 'Galafold (migalastat)' },
  { name: 'Galzin (zinc acetate)', value: 'Galzin (zinc acetate)' },
  {
    name: 'GamaSTAN S/D (immune globulin (human))',
    value: 'GamaSTAN S/D (immune globulin (human))',
  },
  {
    name: 'Gammagard Liquid (immune globulin infusion (human))',
    value: 'Gammagard Liquid (immune globulin infusion (human))',
  },
  {
    name: 'Gammagard S/D IgA ≤ 1µg/mL in a 5% Solution (immune globulin intravenous (human))',
    value:
      'Gammagard S/D IgA ≤ 1µg/mL in a 5% Solution (immune globulin intravenous (human))',
  },
  {
    name: 'Gammaked (immune globulin injection (human))',
    value: 'Gammaked (immune globulin injection (human))',
  },
  {
    name: 'Gammaplex 10% (immune globulin intravenous (human))',
    value: 'Gammaplex 10% (immune globulin intravenous (human))',
  },
  {
    name: 'Gammaplex 5% (immune globulin intravenous (human))',
    value: 'Gammaplex 5% (immune globulin intravenous (human))',
  },
  {
    name: 'Gamunex-C (immune globulin injection (human))',
    value: 'Gamunex-C (immune globulin injection (human))',
  },
  {
    name: 'Ganirelix Acetate (ganirelix acetate)',
    value: 'Ganirelix Acetate (ganirelix acetate)',
  },
  {
    name: 'Garamycin Ophthalmic Solution (gentamicin sulfate)',
    value: 'Garamycin Ophthalmic Solution (gentamicin sulfate)',
  },
  {
    name: 'Gardasil (human papillomavirus quadrivalent (types 6, 11, 16, and 18) vaccine, etc.',
    value:
      'Gardasil (human papillomavirus quadrivalent (types 6, 11, 16, and 18) vaccine, etc.',
  },
  {
    name: 'Gardasil 9 (human papillomavirus 9-valent vaccine, recombinant)',
    value: 'Gardasil 9 (human papillomavirus 9-valent vaccine, recombinant)',
  },
  { name: 'Gas-X (simethicone)', value: 'Gas-X (simethicone)' },
  {
    name: 'Gastrocrom (cromolyn sodium)',
    value: 'Gastrocrom (cromolyn sodium)',
  },
  {
    name: 'Gattex (teduglutide (rDNA origin))',
    value: 'Gattex (teduglutide (rDNA origin))',
  },
  {
    name: 'GaviLyte-C (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
    value:
      'GaviLyte-C (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
  },
  {
    name: 'GaviLyte-H and Bisacodyl (bisacodyl/polyethylene glycol 3350/potassium chloride/sodium bicarbon etc.',
    value:
      'GaviLyte-H and Bisacodyl (bisacodyl/polyethylene glycol 3350/potassium chloride/sodium bicarbon etc.',
  },
  {
    name: 'GaviLyte-N (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
    value:
      'GaviLyte-N (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
  },
  { name: 'Gazyva (obinutuzumab)', value: 'Gazyva (obinutuzumab)' },
  {
    name: 'Gelnique 10% (oxybutynin chloride)',
    value: 'Gelnique 10% (oxybutynin chloride)',
  },
  { name: 'Gemcitabine (gemcitabine)', value: 'Gemcitabine (gemcitabine)' },
  { name: 'Gemzar (gemcitabine)', value: 'Gemzar (gemcitabine)' },
  {
    name: 'Generess Fe (ethinyl estradiol/ferrous fumarate/norethindrone)',
    value: 'Generess Fe (ethinyl estradiol/ferrous fumarate/norethindrone)',
  },
  { name: 'Generlac (lactulose)', value: 'Generlac (lactulose)' },
  {
    name: 'Gengraf Capsules (cyclosporine)',
    value: 'Gengraf Capsules (cyclosporine)',
  },
  {
    name: 'Gengraf Oral Solution (cyclosporine)',
    value: 'Gengraf Oral Solution (cyclosporine)',
  },
  {
    name: 'Genotropin (somatropin (rDNA origin))',
    value: 'Genotropin (somatropin (rDNA origin))',
  },
  {
    name: 'Gentak Ointment (gentamicin sulfate)',
    value: 'Gentak Ointment (gentamicin sulfate)',
  },
  {
    name: 'Gentamicin Injection 10 mg/mL (gentamicin sulfate)',
    value: 'Gentamicin Injection 10 mg/mL (gentamicin sulfate)',
  },
  {
    name: 'Gentamicin Injection 40 mg/mL (gentamicin sulfate)',
    value: 'Gentamicin Injection 40 mg/mL (gentamicin sulfate)',
  },
  {
    name: 'Gentamicin Sulfate in 0.9% Sodium Chloride (gentamicin sulfate)',
    value: 'Gentamicin Sulfate in 0.9% Sodium Chloride (gentamicin sulfate)',
  },
  {
    name: 'Gentamicin Sulfate Ointment (gentamicin sulfate)',
    value: 'Gentamicin Sulfate Ointment (gentamicin sulfate)',
  },
  { name: 'GenTeal Mild (hypromellose)', value: 'GenTeal Mild (hypromellose)' },
  {
    name: 'GenTeal Mild to Moderate (hypromellose)',
    value: 'GenTeal Mild to Moderate (hypromellose)',
  },
  {
    name: 'GenTeal Moderate to Severe (carboxymethylcellulose sodium/hypromellose)',
    value:
      'GenTeal Moderate to Severe (carboxymethylcellulose sodium/hypromellose)',
  },
  {
    name: 'GenTeal Severe (hypromellose)',
    value: 'GenTeal Severe (hypromellose)',
  },
  {
    name: 'Genvoya (cobicistat/elvitegravir/emtricitabine/tenofovir alafenamide)',
    value:
      'Genvoya (cobicistat/elvitegravir/emtricitabine/tenofovir alafenamide)',
  },
  {
    name: 'Geodon (ziprasidone hydrochloride); (ziprasidone mesylate)',
    value: 'Geodon (ziprasidone hydrochloride); (ziprasidone mesylate)',
  },
  { name: 'Giapreza (angiotensin II)', value: 'Giapreza (angiotensin II)' },
  {
    name: 'Giazo (balsalazide disodium)',
    value: 'Giazo (balsalazide disodium)',
  },
  {
    name: 'Gildess 1.5/30 (ethinyl estradiol/norethindrone acetate)',
    value: 'Gildess 1.5/30 (ethinyl estradiol/norethindrone acetate)',
  },
  {
    name: 'Gildess 1/20 (ethinyl estradiol/norethindrone acetate)',
    value: 'Gildess 1/20 (ethinyl estradiol/norethindrone acetate)',
  },
  {
    name: 'Gildess Fe 1.5/30 (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
    value:
      'Gildess Fe 1.5/30 (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
  },
  {
    name: 'Gildess Fe 1/20 (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
    value:
      'Gildess Fe 1/20 (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
  },
  { name: 'Gilenya (fingolimod)', value: 'Gilenya (fingolimod)' },
  { name: 'Gilotrif (afatinib)', value: 'Gilotrif (afatinib)' },
  {
    name: 'Glassia (alpha1-proteinase inhibitor (human))',
    value: 'Glassia (alpha1-proteinase inhibitor (human))',
  },
  {
    name: 'Glatopa (glatiramer acetate)',
    value: 'Glatopa (glatiramer acetate)',
  },
  { name: 'Gleevec (imatinib mesylate)', value: 'Gleevec (imatinib mesylate)' },
  { name: 'Gleostine (lomustine)', value: 'Gleostine (lomustine)' },
  { name: 'Gliadel (carmustine)', value: 'Gliadel (carmustine)' },
  { name: 'Glimepiride (glimepiride)', value: 'Glimepiride (glimepiride)' },
  {
    name: 'Glipizide and Metformin Hydrochloride (glipizide/metformin hydrochloride)',
    value:
      'Glipizide and Metformin Hydrochloride (glipizide/metformin hydrochloride)',
  },
  {
    name: 'GlucaGen (glucagon (rDNA origin))',
    value: 'GlucaGen (glucagon (rDNA origin))',
  },
  {
    name: 'Glucagon (glucagon (rDNA origin))',
    value: 'Glucagon (glucagon (rDNA origin))',
  },
  {
    name: 'Glucophage/Glucophage XR (metformin hydrochloride)',
    value: 'Glucophage/Glucophage XR (metformin hydrochloride)',
  },
  { name: 'Glucotrol (glipizide)', value: 'Glucotrol (glipizide)' },
  { name: 'Glucotrol XL (glipizide)', value: 'Glucotrol XL (glipizide)' },
  {
    name: 'Glucovance (glyburide/metformin hydrochloride)',
    value: 'Glucovance (glyburide/metformin hydrochloride)',
  },
  {
    name: 'Glumetza (metformin hydrochloride)',
    value: 'Glumetza (metformin hydrochloride)',
  },
  { name: 'Glyburide (glyburide)', value: 'Glyburide (glyburide)' },
  {
    name: 'Glyburide and Metformin Hydrochloride (glyburide/metformin hydrochloride)',
    value:
      'Glyburide and Metformin Hydrochloride (glyburide/metformin hydrochloride)',
  },
  { name: 'Glynase (glyburide)', value: 'Glynase (glyburide)' },
  { name: 'Glyset (miglitol)', value: 'Glyset (miglitol)' },
  {
    name: 'Glyxambi (empagliflozin/linagliptin)',
    value: 'Glyxambi (empagliflozin/linagliptin)',
  },
  {
    name: 'Gold Vitamins NMN 15000 (dietary supplement)',
    value: 'Gold Vitamins NMN 15000 (dietary supplement)',
  },
  {
    name: 'GoLytely (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
    value:
      'GoLytely (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
  },
  { name: 'Gonal-f (follitropin alfa)', value: 'Gonal-f (follitropin alfa)' },
  {
    name: 'Gonal-f RFF for Injection (follitropin alfa)',
    value: 'Gonal-f RFF for Injection (follitropin alfa)',
  },
  {
    name: 'Gonal-f RFF Redi-ject (follitropin alfa)',
    value: 'Gonal-f RFF Redi-ject (follitropin alfa)',
  },
  { name: 'Gonitro (nitroglycerin)', value: 'Gonitro (nitroglycerin)' },
  {
    name: 'Goprelto (cocaine hydrochloride)',
    value: 'Goprelto (cocaine hydrochloride)',
  },
  { name: 'GoYin (dietary supplement)', value: 'GoYin (dietary supplement)' },
  { name: 'Gralise (gabapentin)', value: 'Gralise (gabapentin)' },
  {
    name: 'Granisetron Hydrochloride Injection 1 mg/mL and 0.1 mg/mL (granisetron hydrochloride)',
    value:
      'Granisetron Hydrochloride Injection 1 mg/mL and 0.1 mg/mL (granisetron hydrochloride)',
  },
  {
    name: 'Granisetron Hydrochloride Injection 4 mg/4 mL (granisetron hydrochloride)',
    value:
      'Granisetron Hydrochloride Injection 4 mg/4 mL (granisetron hydrochloride)',
  },
  {
    name: 'Granisetron Hydrochloride Tablets (granisetron hydrochloride)',
    value: 'Granisetron Hydrochloride Tablets (granisetron hydrochloride)',
  },
  { name: 'Granix (tbo-filgrastim)', value: 'Granix (tbo-filgrastim)' },
  {
    name: 'Granulex (balsam peru/castor oil/trypsin)',
    value: 'Granulex (balsam peru/castor oil/trypsin)',
  },
  {
    name: 'Grastek (timothy grass pollen allergen extract)',
    value: 'Grastek (timothy grass pollen allergen extract)',
  },
  { name: 'Grifulvin V (griseofulvin)', value: 'Grifulvin V (griseofulvin)' },
  { name: 'Gris-PEG (griseofulvin)', value: 'Gris-PEG (griseofulvin)' },
  {
    name: 'Griseofulvin Oral Suspension (griseofulvin)',
    value: 'Griseofulvin Oral Suspension (griseofulvin)',
  },
  {
    name: 'Guanidine Hydrochloride (guanidine hydrochloride)',
    value: 'Guanidine Hydrochloride (guanidine hydrochloride)',
  },
  {
    name: 'Gynazole-1 (butoconazole nitrate)',
    value: 'Gynazole-1 (butoconazole nitrate)',
  },
  {
    name: 'Gyne-Lotrimin 3 (clotrimazole)',
    value: 'Gyne-Lotrimin 3 (clotrimazole)',
  },
  {
    name: 'Gyne-Lotrimin 7 (clotrimazole)',
    value: 'Gyne-Lotrimin 7 (clotrimazole)',
  },
  { name: 'H.P. Acthar (corticotropin)', value: 'H.P. Acthar (corticotropin)' },
  {
    name: 'Haegarda (C1 Esterase Inhibitor Subcutaneous (Human))',
    value: 'Haegarda (C1 Esterase Inhibitor Subcutaneous (Human))',
  },
  { name: 'Halaven (eribulin mesylate)', value: 'Halaven (eribulin mesylate)' },
  { name: 'Halcinonide (halcinonide)', value: 'Halcinonide (halcinonide)' },
  { name: 'Halcion (triazolam)', value: 'Halcion (triazolam)' },
  { name: 'Haldol (haloperidol)', value: 'Haldol (haloperidol)' },
  {
    name: 'Haldol Decanoate (haloperidol decanoate)',
    value: 'Haldol Decanoate (haloperidol decanoate)',
  },
  { name: 'Halog Cream (halcinonide)', value: 'Halog Cream (halcinonide)' },
  {
    name: 'Halog Ointment (halcinonide)',
    value: 'Halog Ointment (halcinonide)',
  },
  {
    name: 'Haloperidol Oral Solution (haloperidol)',
    value: 'Haloperidol Oral Solution (haloperidol)',
  },
  {
    name: 'Haloperidol Tablets (haloperidol)',
    value: 'Haloperidol Tablets (haloperidol)',
  },
  {
    name: 'Harvoni (ledipasvir/sofosbuvir)',
    value: 'Harvoni (ledipasvir/sofosbuvir)',
  },
  {
    name: 'Havrix (hepatitis A vaccine)',
    value: 'Havrix (hepatitis A vaccine)',
  },
  {
    name: 'HealthTrim Cleanse (dietary supplement)',
    value: 'HealthTrim Cleanse (dietary supplement)',
  },
  {
    name: 'Hectorol Capsules (doxercalciferol)',
    value: 'Hectorol Capsules (doxercalciferol)',
  },
  {
    name: 'Hectorol Injection (doxercalciferol)',
    value: 'Hectorol Injection (doxercalciferol)',
  },
  {
    name: 'Helixate FS (antihemophilic factor (recombinant))',
    value: 'Helixate FS (antihemophilic factor (recombinant))',
  },
  {
    name: 'Hemabate (carboprost tromethamine)',
    value: 'Hemabate (carboprost tromethamine)',
  },
  {
    name: 'Hemangeol (propranolol hydrochloride)',
    value: 'Hemangeol (propranolol hydrochloride)',
  },
  { name: 'Hemlibra (emicizumab-kxwh)', value: 'Hemlibra (emicizumab-kxwh)' },
  {
    name: 'Hemofil M (antihemophilic factor (human))',
    value: 'Hemofil M (antihemophilic factor (human))',
  },
  {
    name: 'HepaGam B (hepatitis B immune globulin intravenous (human))',
    value: 'HepaGam B (hepatitis B immune globulin intravenous (human))',
  },
  {
    name: 'Heparin Sodium and 0.9% Sodium Chloride (heparin sodium)',
    value: 'Heparin Sodium and 0.9% Sodium Chloride (heparin sodium)',
  },
  {
    name: 'Heparin Sodium in 0.45% Sodium Chloride Injection (heparin sodium)',
    value: 'Heparin Sodium in 0.45% Sodium Chloride Injection (heparin sodium)',
  },
  {
    name: 'Heparin Sodium in 5% Dextrose (heparin sodium)',
    value: 'Heparin Sodium in 5% Dextrose (heparin sodium)',
  },
  {
    name: 'Heparin Sodium Injection (heparin sodium)',
    value: 'Heparin Sodium Injection (heparin sodium)',
  },
  {
    name: 'Heplisav-B (hepatitis B vaccine (recombinant) adjuvanted)',
    value: 'Heplisav-B (hepatitis B vaccine (recombinant) adjuvanted)',
  },
  {
    name: 'Hepsera (adefovir dipivoxil)',
    value: 'Hepsera (adefovir dipivoxil)',
  },
  { name: 'Herceptin (trastuzumab)', value: 'Herceptin (trastuzumab)' },
  {
    name: 'Herceptin Hylecta (trastuzumab/hyaluronidase-oysk)',
    value: 'Herceptin Hylecta (trastuzumab/hyaluronidase-oysk)',
  },
  { name: 'Herzuma (trastuzumab)', value: 'Herzuma (trastuzumab)' },
  { name: 'Hetlioz (tasimelteon)', value: 'Hetlioz (tasimelteon)' },
  { name: 'Hexalen (altretamine)', value: 'Hexalen (altretamine)' },
  {
    name: 'Hiberix (haemophilus b conjugate vaccine (tetanus toxoid conjugate))',
    value:
      'Hiberix (haemophilus b conjugate vaccine (tetanus toxoid conjugate))',
  },
  {
    name: 'Hibiclens (chlorhexidine gluconate)',
    value: 'Hibiclens (chlorhexidine gluconate)',
  },
  {
    name: 'Hiprex (methenamine hippurate)',
    value: 'Hiprex (methenamine hippurate)',
  },
  {
    name: 'Hizentra (immune globulin subcutaneous (human))',
    value: 'Hizentra (immune globulin subcutaneous (human))',
  },
  {
    name: 'Horizant (gabapentin enacarbil)',
    value: 'Horizant (gabapentin enacarbil)',
  },
  { name: 'Humalog (insulin lispro)', value: 'Humalog (insulin lispro)' },
  {
    name: 'Humalog Mix50/50 (insulin lispro protamine (rDNA origin)/insulin lispro (rDNA origin))',
    value:
      'Humalog Mix50/50 (insulin lispro protamine (rDNA origin)/insulin lispro (rDNA origin))',
  },
  {
    name: 'Humalog Mix75/25 (insulin lispro protamine (rDNA origin)/insulin lispro (rDNA origin))',
    value:
      'Humalog Mix75/25 (insulin lispro protamine (rDNA origin)/insulin lispro (rDNA origin))',
  },
  {
    name: 'Human Albumin Grifols 25% (albumin (human))',
    value: 'Human Albumin Grifols 25% (albumin (human))',
  },
  {
    name: 'Humate-P (antihemophilic factor/von Willebrand factor complex (human))',
    value:
      'Humate-P (antihemophilic factor/von Willebrand factor complex (human))',
  },
  {
    name: 'Humatrope (somatropin (rDNA origin))',
    value: 'Humatrope (somatropin (rDNA origin))',
  },
  { name: 'Humira (adalimumab)', value: 'Humira (adalimumab)' },
  {
    name: 'Humulin 70/30 (NPH, human insulin isophane (rDNA origin)/regular, human insulin (rDN etc.',
    value:
      'Humulin 70/30 (NPH, human insulin isophane (rDNA origin)/regular, human insulin (rDN etc.',
  },
  {
    name: 'Humulin N (NPH, human insulin isophane (rDNA origin))',
    value: 'Humulin N (NPH, human insulin isophane (rDNA origin))',
  },
  {
    name: 'Humulin R (regular, human insulin (rDNA origin))',
    value: 'Humulin R (regular, human insulin (rDNA origin))',
  },
  {
    name: 'Hycamtin Capsules (topotecan)',
    value: 'Hycamtin Capsules (topotecan)',
  },
  {
    name: 'Hycamtin for Injection (topotecan)',
    value: 'Hycamtin for Injection (topotecan)',
  },
  {
    name: 'Hycet (acetaminophen/hydrocodone bitartrate)',
    value: 'Hycet (acetaminophen/hydrocodone bitartrate)',
  },
  {
    name: 'Hydralazine Hydrochloride Injection (hydralazine hydrochloride)',
    value: 'Hydralazine Hydrochloride Injection (hydralazine hydrochloride)',
  },
  {
    name: 'Hydralazine Hydrochloride Tablets (hydralazine hydrochloride)',
    value: 'Hydralazine Hydrochloride Tablets (hydralazine hydrochloride)',
  },
  { name: 'Hydrea (hydroxyurea)', value: 'Hydrea (hydroxyurea)' },
  { name: 'Hydro 35 (urea)', value: 'Hydro 35 (urea)' },
  {
    name: 'Hydrochlorothiazide Tablets (hydrochlorothiazide)',
    value: 'Hydrochlorothiazide Tablets (hydrochlorothiazide)',
  },
  {
    name: 'Hydrocortisone Cream and Ointment (hydrocortisone)',
    value: 'Hydrocortisone Cream and Ointment (hydrocortisone)',
  },
  {
    name: 'Hydrocortisone Lotion 2.5% (hydrocortisone)',
    value: 'Hydrocortisone Lotion 2.5% (hydrocortisone)',
  },
  {
    name: 'Hydrocortisone Valerate (hydrocortisone valerate)',
    value: 'Hydrocortisone Valerate (hydrocortisone valerate)',
  },
  {
    name: 'Hydromet (homatropine methylbromide/hydrocodone bitartrate)',
    value: 'Hydromet (homatropine methylbromide/hydrocodone bitartrate)',
  },
  {
    name: 'Hydroxyzine Hydrochloride Injection (hydroxyzine hydrochloride)',
    value: 'Hydroxyzine Hydrochloride Injection (hydroxyzine hydrochloride)',
  },
  {
    name: 'Hydroxyzine Hydrochloride Syrup (hydroxyzine hydrochloride)',
    value: 'Hydroxyzine Hydrochloride Syrup (hydroxyzine hydrochloride)',
  },
  {
    name: 'Hydroxyzine Hydrochloride Tablets (hydroxyzine hydrochloride)',
    value: 'Hydroxyzine Hydrochloride Tablets (hydroxyzine hydrochloride)',
  },
  {
    name: 'Hydroxyzine Pamoate (hydroxyzine pamoate)',
    value: 'Hydroxyzine Pamoate (hydroxyzine pamoate)',
  },
  {
    name: 'Hylenex (hyaluronidase human)',
    value: 'Hylenex (hyaluronidase human)',
  },
  {
    name: 'Hyosyne Elixir (hyoscyamine sulfate)',
    value: 'Hyosyne Elixir (hyoscyamine sulfate)',
  },
  {
    name: 'Hyosyne Oral Drops (hyoscyamine sulfate)',
    value: 'Hyosyne Oral Drops (hyoscyamine sulfate)',
  },
  {
    name: 'Hypercare (aluminum chloride/ethyl alcohol)',
    value: 'Hypercare (aluminum chloride/ethyl alcohol)',
  },
  {
    name: 'HyperHEP B S/D (hepatitis B immune globulin (human))',
    value: 'HyperHEP B S/D (hepatitis B immune globulin (human))',
  },
  {
    name: 'HyperIg PL100 (dietary supplement)',
    value: 'HyperIg PL100 (dietary supplement)',
  },
  {
    name: 'HyperRAB S/D (rabies immune globulin (human))',
    value: 'HyperRAB S/D (rabies immune globulin (human))',
  },
  {
    name: 'HyperRHO S/D Full Dose (rho (D) immune globulin (human))',
    value: 'HyperRHO S/D Full Dose (rho (D) immune globulin (human))',
  },
  {
    name: 'HyperRHO S/D Mini-Dose (rho (D) immune globulin (human))',
    value: 'HyperRHO S/D Mini-Dose (rho (D) immune globulin (human))',
  },
  {
    name: 'HyperTET S/D (tetanus immune globulin (human))',
    value: 'HyperTET S/D (tetanus immune globulin (human))',
  },
  {
    name: 'Hyqvia (immune globulin infusion (human) with recombinant human hyaluronidase etc.',
    value:
      'Hyqvia (immune globulin infusion (human) with recombinant human hyaluronidase etc.',
  },
  {
    name: 'Hysingla ER (hydrocodone bitartrate)',
    value: 'Hysingla ER (hydrocodone bitartrate)',
  },
  {
    name: 'Hyzaar (hydrochlorothiazide/losartan potassium)',
    value: 'Hyzaar (hydrochlorothiazide/losartan potassium)',
  },
  { name: 'Ibrance (palbociclib)', value: 'Ibrance (palbociclib)' },
  {
    name: 'Ibuprofen Suspension (ibuprofen)',
    value: 'Ibuprofen Suspension (ibuprofen)',
  },
  {
    name: 'Ibuprofen Tablets (ibuprofen)',
    value: 'Ibuprofen Tablets (ibuprofen)',
  },
  {
    name: 'IC-Green (indocyanine green)',
    value: 'IC-Green (indocyanine green)',
  },
  { name: 'Icatibant (icatibant)', value: 'Icatibant (icatibant)' },
  {
    name: 'Iclevia (ethinyl estradiol/levonorgestrel)',
    value: 'Iclevia (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'Iclusig (ponatinib)', value: 'Iclusig (ponatinib)' },
  {
    name: 'Idamycin PFS (idarubicin hydrochloride)',
    value: 'Idamycin PFS (idarubicin hydrochloride)',
  },
  {
    name: 'Idelvion (coagulation factor IX (recombinant), albumin fusion protein)',
    value:
      'Idelvion (coagulation factor IX (recombinant), albumin fusion protein)',
  },
  { name: 'Idhifa (enasidenib)', value: 'Idhifa (enasidenib)' },
  { name: 'Ifex (ifosfamide)', value: 'Ifex (ifosfamide)' },
  { name: 'Ilaris (canakinumab)', value: 'Ilaris (canakinumab)' },
  { name: 'Ilevro (nepafenac)', value: 'Ilevro (nepafenac)' },
  {
    name: 'Iluvien (fluocinolone acetonide)',
    value: 'Iluvien (fluocinolone acetonide)',
  },
  {
    name: 'Imatinib Mesylate (imatinib mesylate)',
    value: 'Imatinib Mesylate (imatinib mesylate)',
  },
  { name: 'Imbruvica (ibrutinib)', value: 'Imbruvica (ibrutinib)' },
  { name: 'Imfinzi (durvalumab)', value: 'Imfinzi (durvalumab)' },
  {
    name: 'Imitrex Injection (sumatriptan succinate)',
    value: 'Imitrex Injection (sumatriptan succinate)',
  },
  {
    name: 'Imitrex Nasal Spray (sumatriptan)',
    value: 'Imitrex Nasal Spray (sumatriptan)',
  },
  {
    name: 'Imitrex Tablets (sumatriptan succinate)',
    value: 'Imitrex Tablets (sumatriptan succinate)',
  },
  {
    name: 'Imlygic (talimogene laherparepvec)',
    value: 'Imlygic (talimogene laherparepvec)',
  },
  { name: 'Immunocal (cysteine)', value: 'Immunocal (cysteine)' },
  {
    name: 'Imodium Multi-Symptom (loperamide hydrochloride/simethicone)',
    value: 'Imodium Multi-Symptom (loperamide hydrochloride/simethicone)',
  },
  {
    name: 'Imogam (rabies immune globulin (human))',
    value: 'Imogam (rabies immune globulin (human))',
  },
  { name: 'Imovax (rabies vaccine)', value: 'Imovax (rabies vaccine)' },
  { name: 'Impavido (miltefosine)', value: 'Impavido (miltefosine)' },
  { name: 'Imuran (azathioprine)', value: 'Imuran (azathioprine)' },
  {
    name: 'Imvexxy (estradiol vaginal insert)',
    value: 'Imvexxy (estradiol vaginal insert)',
  },
  { name: 'Inbrija (levodopa)', value: 'Inbrija (levodopa)' },
  { name: 'Incivek (telaprevir)', value: 'Incivek (telaprevir)' },
  {
    name: 'Increlex (mecasermin (rDNA origin))',
    value: 'Increlex (mecasermin (rDNA origin))',
  },
  {
    name: 'Incruse Ellipta (umeclidinium)',
    value: 'Incruse Ellipta (umeclidinium)',
  },
  { name: 'Indapamide (indapamide)', value: 'Indapamide (indapamide)' },
  {
    name: 'Inderal LA (propranolol hydrochloride)',
    value: 'Inderal LA (propranolol hydrochloride)',
  },
  {
    name: 'Inderal XL (propranolol hydrochloride)',
    value: 'Inderal XL (propranolol hydrochloride)',
  },
  {
    name: 'Indocin Oral Suspension (indomethacin)',
    value: 'Indocin Oral Suspension (indomethacin)',
  },
  {
    name: 'Indocin Suppositories (indomethacin)',
    value: 'Indocin Suppositories (indomethacin)',
  },
  {
    name: 'Indomethacin Capsules (indomethacin)',
    value: 'Indomethacin Capsules (indomethacin)',
  },
  {
    name: 'Indomethacin Extended-Release Capsules (indomethacin)',
    value: 'Indomethacin Extended-Release Capsules (indomethacin)',
  },
  {
    name: 'Infanrix (diphtheria and tetanus toxoids and acellular pertussis vaccine adsorb etc.',
    value:
      'Infanrix (diphtheria and tetanus toxoids and acellular pertussis vaccine adsorb etc.',
  },
  {
    name: "Infants' Advil Concentrated Drops (ibuprofen)",
    value: "Infants' Advil Concentrated Drops (ibuprofen)",
  },
  {
    name: "Infants' FeverAll (acetaminophen)",
    value: "Infants' FeverAll (acetaminophen)",
  },
  { name: "Infants' Motrin (ibuprofen)", value: "Infants' Motrin (ibuprofen)" },
  { name: 'Infasurf (calfactant)', value: 'Infasurf (calfactant)' },
  { name: 'INFeD (iron dextran)', value: 'INFeD (iron dextran)' },
  { name: 'Inflectra (infliximab-dyyb)', value: 'Inflectra (infliximab-dyyb)' },
  {
    name: 'Infumorph (morphine sulfate)',
    value: 'Infumorph (morphine sulfate)',
  },
  { name: 'Ingrezza (valbenazine)', value: 'Ingrezza (valbenazine)' },
  {
    name: 'Injectafer (ferric carboxymaltose)',
    value: 'Injectafer (ferric carboxymaltose)',
  },
  { name: 'Inlyta (axitinib)', value: 'Inlyta (axitinib)' },
  {
    name: 'InnoPran XL (propranolol hydrochloride)',
    value: 'InnoPran XL (propranolol hydrochloride)',
  },
  { name: 'Inrebic (fedratinib)', value: 'Inrebic (fedratinib)' },
  { name: 'Inspra (eplerenone)', value: 'Inspra (eplerenone)' },
  { name: 'Integrilin (eptifibatide)', value: 'Integrilin (eptifibatide)' },
  { name: 'Intelence (etravirine)', value: 'Intelence (etravirine)' },
  {
    name: 'Intermezzo (zolpidem tartrate)',
    value: 'Intermezzo (zolpidem tartrate)',
  },
  {
    name: 'Intrarosa (prasterone insert)',
    value: 'Intrarosa (prasterone insert)',
  },
  {
    name: 'Intravenous Sodium Diuril (chlorothiazide sodium)',
    value: 'Intravenous Sodium Diuril (chlorothiazide sodium)',
  },
  { name: 'Intuniv (guanfacine)', value: 'Intuniv (guanfacine)' },
  { name: 'Invanz (ertapenem)', value: 'Invanz (ertapenem)' },
  { name: 'Invega (paliperidone)', value: 'Invega (paliperidone)' },
  {
    name: 'Invega Sustenna (paliperidone palmitate)',
    value: 'Invega Sustenna (paliperidone palmitate)',
  },
  {
    name: 'Invega Trinza (paliperidone palmitate)',
    value: 'Invega Trinza (paliperidone palmitate)',
  },
  {
    name: 'Inveltys (loteprednol etabonate)',
    value: 'Inveltys (loteprednol etabonate)',
  },
  {
    name: 'Invirase (saquinavir mesylate)',
    value: 'Invirase (saquinavir mesylate)',
  },
  {
    name: 'Invokamet (canagliflozin/metformin hydrochloride)',
    value: 'Invokamet (canagliflozin/metformin hydrochloride)',
  },
  {
    name: 'Invokamet XR (canagliflozin/metformin hydrochloride)',
    value: 'Invokamet XR (canagliflozin/metformin hydrochloride)',
  },
  { name: 'Invokana (canagliflozin)', value: 'Invokana (canagliflozin)' },
  { name: 'Ionsys (fentanyl)', value: 'Ionsys (fentanyl)' },
  {
    name: 'Iopidine 0.5% Ophthalmic Solution (apraclonidine hydrochloride)',
    value: 'Iopidine 0.5% Ophthalmic Solution (apraclonidine hydrochloride)',
  },
  {
    name: 'Iopidine 1% Ophthalmic Solution (apraclonidine hydrochloride)',
    value: 'Iopidine 1% Ophthalmic Solution (apraclonidine hydrochloride)',
  },
  {
    name: 'Ipol (poliovirus vaccine inactivated)',
    value: 'Ipol (poliovirus vaccine inactivated)',
  },
  {
    name: 'Ipratropium Bromide (ipratropium bromide)',
    value: 'Ipratropium Bromide (ipratropium bromide)',
  },
  { name: 'Iprivask (desirudin)', value: 'Iprivask (desirudin)' },
  { name: 'Iressa (gefitinib)', value: 'Iressa (gefitinib)' },
  {
    name: 'Irinotecan Hydrochloride (irinotecan hydrochloride)',
    value: 'Irinotecan Hydrochloride (irinotecan hydrochloride)',
  },
  { name: 'Isentress (raltegravir)', value: 'Isentress (raltegravir)' },
  {
    name: 'Isibloom (desogestrel/ethinyl estradiol)',
    value: 'Isibloom (desogestrel/ethinyl estradiol)',
  },
  {
    name: 'Isoniazid Injection (isoniazid)',
    value: 'Isoniazid Injection (isoniazid)',
  },
  {
    name: 'Isoniazid Solution (isoniazid)',
    value: 'Isoniazid Solution (isoniazid)',
  },
  {
    name: 'Isoniazid Tablets (isoniazid)',
    value: 'Isoniazid Tablets (isoniazid)',
  },
  {
    name: 'Isopto Carpine (pilocarpine hydrochloride)',
    value: 'Isopto Carpine (pilocarpine hydrochloride)',
  },
  {
    name: 'Isordil Oral Titradose (isosorbide dinitrate)',
    value: 'Isordil Oral Titradose (isosorbide dinitrate)',
  },
  {
    name: 'Isosorbide Dinitrate Tablets (isosorbide dinitrate)',
    value: 'Isosorbide Dinitrate Tablets (isosorbide dinitrate)',
  },
  { name: 'Isradipine (isradipine)', value: 'Isradipine (isradipine)' },
  { name: 'Istalol (timolol maleate)', value: 'Istalol (timolol maleate)' },
  { name: 'Istodax (romidepsin)', value: 'Istodax (romidepsin)' },
  { name: 'Ixempra (ixabepilone)', value: 'Ixempra (ixabepilone)' },
  {
    name: 'Ixiaro (japanese encephalitis vaccine, inactivated, adsorbed)',
    value: 'Ixiaro (japanese encephalitis vaccine, inactivated, adsorbed)',
  },
  {
    name: 'Ixinity (coagulation factor IX (recombinant))',
    value: 'Ixinity (coagulation factor IX (recombinant))',
  },
  { name: 'Jadenu (deferasirox)', value: 'Jadenu (deferasirox)' },
  { name: 'Jakafi (ruxolitinib)', value: 'Jakafi (ruxolitinib)' },
  {
    name: 'Jalyn (dutasteride/tamsulosin hydrochloride)',
    value: 'Jalyn (dutasteride/tamsulosin hydrochloride)',
  },
  { name: 'Jantoven (warfarin sodium)', value: 'Jantoven (warfarin sodium)' },
  {
    name: 'Janumet (metformin hydrochloride/sitagliptin)',
    value: 'Janumet (metformin hydrochloride/sitagliptin)',
  },
  {
    name: 'Janumet XR (metformin hydrochloride/sitagliptin)',
    value: 'Janumet XR (metformin hydrochloride/sitagliptin)',
  },
  { name: 'Januvia (sitagliptin)', value: 'Januvia (sitagliptin)' },
  { name: 'Jardiance (empagliflozin)', value: 'Jardiance (empagliflozin)' },
  {
    name: 'Jentadueto (linagliptin/metformin hydrochloride)',
    value: 'Jentadueto (linagliptin/metformin hydrochloride)',
  },
  {
    name: 'Jentadueto XR (linagliptin/metformin hydrochloride)',
    value: 'Jentadueto XR (linagliptin/metformin hydrochloride)',
  },
  { name: 'Jetrea (ocriplasmin)', value: 'Jetrea (ocriplasmin)' },
  { name: 'Jevtana (cabazitaxel)', value: 'Jevtana (cabazitaxel)' },
  {
    name: 'Jivi (antihemophilic factor (recombinant), PEGylated-aucl)',
    value: 'Jivi (antihemophilic factor (recombinant), PEGylated-aucl)',
  },
  {
    name: 'Jolessa (ethinyl estradiol/levonorgestrel)',
    value: 'Jolessa (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'Jolivette (norethindrone)', value: 'Jolivette (norethindrone)' },
  {
    name: 'Jornay PM (methylphenidate hydrochloride)',
    value: 'Jornay PM (methylphenidate hydrochloride)',
  },
  { name: 'Jublia (efinaconazole)', value: 'Jublia (efinaconazole)' },
  {
    name: 'Juluca (dolutegravir/rilpivirine)',
    value: 'Juluca (dolutegravir/rilpivirine)',
  },
  {
    name: 'Junel/Junel Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate); (ethinyl e etc.',
    value:
      'Junel/Junel Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate); (ethinyl e etc.',
  },
  {
    name: 'Junior Strength Advil Chewable Tablets (ibuprofen)',
    value: 'Junior Strength Advil Chewable Tablets (ibuprofen)',
  },
  {
    name: 'Junior Strength Advil Coated Tablets (ibuprofen)',
    value: 'Junior Strength Advil Coated Tablets (ibuprofen)',
  },
  {
    name: 'Junior Strength FeverAll (acetaminophen)',
    value: 'Junior Strength FeverAll (acetaminophen)',
  },
  { name: 'Juxtapid (lomitapide)', value: 'Juxtapid (lomitapide)' },
  { name: 'Jynarque (tolvaptan)', value: 'Jynarque (tolvaptan)' },
  {
    name: 'K-Phos Neutral (dibasic sodium phosphate anhydrous/monobasic potassium phosphate/mono etc.',
    value:
      'K-Phos Neutral (dibasic sodium phosphate anhydrous/monobasic potassium phosphate/mono etc.',
  },
  {
    name: 'K-Phos No. 2 (potassium acid phosphate/sodium acid phosphate anhydrous)',
    value:
      'K-Phos No. 2 (potassium acid phosphate/sodium acid phosphate anhydrous)',
  },
  {
    name: 'K-Phos Original (potassium acid phosphate)',
    value: 'K-Phos Original (potassium acid phosphate)',
  },
  { name: 'K-Tab (potassium chloride)', value: 'K-Tab (potassium chloride)' },
  {
    name: 'Kadcyla (ado-trastuzumab emtansine)',
    value: 'Kadcyla (ado-trastuzumab emtansine)',
  },
  { name: 'Kadian (morphine sulfate)', value: 'Kadian (morphine sulfate)' },
  {
    name: 'Kaitlib Fe (ethinyl estradiol/ferrous fumarate/norethindrone)',
    value: 'Kaitlib Fe (ethinyl estradiol/ferrous fumarate/norethindrone)',
  },
  { name: 'Kalbitor (ecallantide)', value: 'Kalbitor (ecallantide)' },
  {
    name: 'Kaletra (lopinavir/ritonavir)',
    value: 'Kaletra (lopinavir/ritonavir)',
  },
  {
    name: 'Kalliga (desogestrel/ethinyl estradiol)',
    value: 'Kalliga (desogestrel/ethinyl estradiol)',
  },
  { name: 'Kalydeco (ivacaftor)', value: 'Kalydeco (ivacaftor)' },
  { name: 'Kanjinti (trastuzumab-anns)', value: 'Kanjinti (trastuzumab-anns)' },
  { name: 'Kanuma (sebelipase alfa)', value: 'Kanuma (sebelipase alfa)' },
  {
    name: 'Kapvay (clonidine hydrochloride)',
    value: 'Kapvay (clonidine hydrochloride)',
  },
  {
    name: 'Karbinal ER (carbinoxamine maleate)',
    value: 'Karbinal ER (carbinoxamine maleate)',
  },
  {
    name: 'Kariva (desogestrel/ethinyl estradiol)',
    value: 'Kariva (desogestrel/ethinyl estradiol)',
  },
  {
    name: 'Kayexalate (sodium polystyrene sulfonate)',
    value: 'Kayexalate (sodium polystyrene sulfonate)',
  },
  {
    name: 'Kazano (alogliptin/metformin hydrochloride)',
    value: 'Kazano (alogliptin/metformin hydrochloride)',
  },
  {
    name: 'Kcentra (prothrombin complex concentrate (human))',
    value: 'Kcentra (prothrombin complex concentrate (human))',
  },
  { name: 'Kedbumin (albumin (human))', value: 'Kedbumin (albumin (human))' },
  { name: 'Keflex (cephalexin)', value: 'Keflex (cephalexin)' },
  {
    name: 'Kelnor (ethinyl estradiol/ethynodiol diacetate)',
    value: 'Kelnor (ethinyl estradiol/ethynodiol diacetate)',
  },
  {
    name: 'Kelnor 1/50 (ethinyl estradiol/ethynodiol diacetate)',
    value: 'Kelnor 1/50 (ethinyl estradiol/ethynodiol diacetate)',
  },
  {
    name: 'Kenalog Spray (triamcinolone acetonide)',
    value: 'Kenalog Spray (triamcinolone acetonide)',
  },
  {
    name: 'Kenalog-10 (triamcinolone acetonide)',
    value: 'Kenalog-10 (triamcinolone acetonide)',
  },
  {
    name: 'Kenalog-40 (triamcinolone acetonide)',
    value: 'Kenalog-40 (triamcinolone acetonide)',
  },
  { name: 'Kengreal (cangrelor)', value: 'Kengreal (cangrelor)' },
  { name: 'Kepivance (palifermin)', value: 'Kepivance (palifermin)' },
  {
    name: 'Keppra Injection (levetiracetam)',
    value: 'Keppra Injection (levetiracetam)',
  },
  {
    name: 'Keppra Oral Solution and Tablets (levetiracetam)',
    value: 'Keppra Oral Solution and Tablets (levetiracetam)',
  },
  { name: 'Keppra XR (levetiracetam)', value: 'Keppra XR (levetiracetam)' },
  { name: 'Kerydin (tavaborole)', value: 'Kerydin (tavaborole)' },
  {
    name: 'Ketalar (ketamine hydrochloride)',
    value: 'Ketalar (ketamine hydrochloride)',
  },
  { name: 'Ketek (telithromycin)', value: 'Ketek (telithromycin)' },
  {
    name: 'Ketoconazole Cream (ketoconazole)',
    value: 'Ketoconazole Cream (ketoconazole)',
  },
  {
    name: 'Ketoconazole Tablets (ketoconazole)',
    value: 'Ketoconazole Tablets (ketoconazole)',
  },
  { name: 'Ketoprofen (ketoprofen)', value: 'Ketoprofen (ketoprofen)' },
  {
    name: 'Ketorolac Tromethamine Tablets (ketorolac tromethamine)',
    value: 'Ketorolac Tromethamine Tablets (ketorolac tromethamine)',
  },
  { name: 'Keveyis (dichlorphenamide)', value: 'Keveyis (dichlorphenamide)' },
  { name: 'Kevzara (sarilumab)', value: 'Kevzara (sarilumab)' },
  { name: 'Keytruda (pembrolizumab)', value: 'Keytruda (pembrolizumab)' },
  { name: 'Khedezla (desvenlafaxine)', value: 'Khedezla (desvenlafaxine)' },
  { name: 'KIMMTRAK (tebentafusp-tebn)', value: 'KIMMTRAK (tebentafusp-tebn)' },
  { name: 'Kineret (anakinra)', value: 'Kineret (anakinra)' },
  { name: 'Kinevac (sincalide)', value: 'Kinevac (sincalide)' },
  {
    name: 'Kinrix (diphtheria and tetanus toxoids and acellular pertussis adsorbed and i etc.',
    value:
      'Kinrix (diphtheria and tetanus toxoids and acellular pertussis adsorbed and i etc.',
  },
  {
    name: 'Kionex Powder for Suspension (sodium polystyrene sulfonate)',
    value: 'Kionex Powder for Suspension (sodium polystyrene sulfonate)',
  },
  {
    name: 'Kionex Suspension (sodium polystyrene sulfonate)',
    value: 'Kionex Suspension (sodium polystyrene sulfonate)',
  },
  { name: 'Kisqali (ribociclib)', value: 'Kisqali (ribociclib)' },
  {
    name: 'Kisqali Femara Co-Pack (letrozole/ribociclib)',
    value: 'Kisqali Femara Co-Pack (letrozole/ribociclib)',
  },
  { name: 'Kitabis Pak (tobramycin)', value: 'Kitabis Pak (tobramycin)' },
  {
    name: 'Klaron (sodium sulfacetamide)',
    value: 'Klaron (sodium sulfacetamide)',
  },
  { name: 'Klonopin (clonazepam)', value: 'Klonopin (clonazepam)' },
  {
    name: 'Klor-Con and Klor-Con/25 Powder (potassium chloride)',
    value: 'Klor-Con and Klor-Con/25 Powder (potassium chloride)',
  },
  {
    name: 'Klor-Con/EF (potassium bicarbonate effervescent)',
    value: 'Klor-Con/EF (potassium bicarbonate effervescent)',
  },
  {
    name: 'Koate-DVI (antihemophilic factor (human))',
    value: 'Koate-DVI (antihemophilic factor (human))',
  },
  {
    name: 'Kogenate FS (antihemophilic factor (recombinant))',
    value: 'Kogenate FS (antihemophilic factor (recombinant))',
  },
  {
    name: 'Kogenate FS with Bio-Set (antihemophilic factor (recombinant))',
    value: 'Kogenate FS with Bio-Set (antihemophilic factor (recombinant))',
  },
  {
    name: 'Kogenate FS with Vial Adapter (antihemophilic factor (recombinant))',
    value:
      'Kogenate FS with Vial Adapter (antihemophilic factor (recombinant))',
  },
  {
    name: 'Kombiglyze XR (metformin hydrochloride/saxagliptin)',
    value: 'Kombiglyze XR (metformin hydrochloride/saxagliptin)',
  },
  { name: 'Korlym (mifepristone)', value: 'Korlym (mifepristone)' },
  {
    name: 'Kovaltry (antihemophilic factor (recombinant))',
    value: 'Kovaltry (antihemophilic factor (recombinant))',
  },
  { name: 'Krintafel (tafenoquine)', value: 'Krintafel (tafenoquine)' },
  { name: 'Kristalose (lactulose)', value: 'Kristalose (lactulose)' },
  { name: 'Krystexxa (pegloticase)', value: 'Krystexxa (pegloticase)' },
  {
    name: 'Kuvan (sapropterin dihydrochloride)',
    value: 'Kuvan (sapropterin dihydrochloride)',
  },
  { name: 'Kyleena (levonorgestrel)', value: 'Kyleena (levonorgestrel)' },
  { name: 'Kymriah (tisagenlecleucel)', value: 'Kymriah (tisagenlecleucel)' },
  { name: 'Kynamro (mipomersen sodium)', value: 'Kynamro (mipomersen sodium)' },
  { name: 'Kyprolis (carfilzomib)', value: 'Kyprolis (carfilzomib)' },
  {
    name: 'Labetalol Hydrochloride Injection (labetalol hydrochloride)',
    value: 'Labetalol Hydrochloride Injection (labetalol hydrochloride)',
  },
  {
    name: 'Labetalol Hydrochloride Tablets (labetalol hydrochloride)',
    value: 'Labetalol Hydrochloride Tablets (labetalol hydrochloride)',
  },
  {
    name: 'Lac-Hydrin Cream (ammonium lactate)',
    value: 'Lac-Hydrin Cream (ammonium lactate)',
  },
  {
    name: 'Lac-Hydrin Lotion (ammonium lactate)',
    value: 'Lac-Hydrin Lotion (ammonium lactate)',
  },
  {
    name: 'Lacrisert (hydroxypropyl cellulose)',
    value: 'Lacrisert (hydroxypropyl cellulose)',
  },
  { name: 'Lamictal (lamotrigine)', value: 'Lamictal (lamotrigine)' },
  { name: 'Lamictal XR (lamotrigine)', value: 'Lamictal XR (lamotrigine)' },
  {
    name: 'Laminine (dietary supplement)',
    value: 'Laminine (dietary supplement)',
  },
  {
    name: 'Lamisil AF Defense Spray Powder (tolnaftate)',
    value: 'Lamisil AF Defense Spray Powder (tolnaftate)',
  },
  {
    name: 'Lamisil AT Continuous Spray (terbinafine hydrochloride)',
    value: 'Lamisil AT Continuous Spray (terbinafine hydrochloride)',
  },
  {
    name: 'Lamisil AT Cream (terbinafine hydrochloride)',
    value: 'Lamisil AT Cream (terbinafine hydrochloride)',
  },
  {
    name: 'Lamisil AT Gel (terbinafine)',
    value: 'Lamisil AT Gel (terbinafine)',
  },
  {
    name: 'Lamisil Granules (terbinafine hydrochloride)',
    value: 'Lamisil Granules (terbinafine hydrochloride)',
  },
  {
    name: 'Lamisil Tablets (terbinafine hydrochloride)',
    value: 'Lamisil Tablets (terbinafine hydrochloride)',
  },
  { name: 'Lanoxin Injection (digoxin)', value: 'Lanoxin Injection (digoxin)' },
  { name: 'Lanoxin Tablets (digoxin)', value: 'Lanoxin Tablets (digoxin)' },
  { name: 'Lantus (insulin glargine)', value: 'Lantus (insulin glargine)' },
  {
    name: 'Larissia (ethinyl estradiol/levonorgestrel)',
    value: 'Larissia (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'Lartruvo (olaratumab)', value: 'Lartruvo (olaratumab)' },
  { name: 'Lasix (furosemide)', value: 'Lasix (furosemide)' },
  { name: 'Lastacaft (alcaftadine)', value: 'Lastacaft (alcaftadine)' },
  { name: 'Latisse (bimatoprost)', value: 'Latisse (bimatoprost)' },
  {
    name: 'Latuda (lurasidone hydrochloride)',
    value: 'Latuda (lurasidone hydrochloride)',
  },
  { name: 'Lazanda (fentanyl)', value: 'Lazanda (fentanyl)' },
  {
    name: 'Leena (ethinyl estradiol/norethindrone)',
    value: 'Leena (ethinyl estradiol/norethindrone)',
  },
  { name: 'Lemtrada (alemtuzumab)', value: 'Lemtrada (alemtuzumab)' },
  { name: 'Lenvima (lenvatinib)', value: 'Lenvima (lenvatinib)' },
  {
    name: 'Lescol/Lescol XL (fluvastatin sodium)',
    value: 'Lescol/Lescol XL (fluvastatin sodium)',
  },
  {
    name: 'Lessina (ethinyl estradiol/levonorgestrel)',
    value: 'Lessina (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'Letairis (ambrisentan)', value: 'Letairis (ambrisentan)' },
  {
    name: 'Leucovorin Calcium Tablets (leucovorin calcium)',
    value: 'Leucovorin Calcium Tablets (leucovorin calcium)',
  },
  {
    name: 'Leukeran Tablets (chlorambucil)',
    value: 'Leukeran Tablets (chlorambucil)',
  },
  { name: 'Leukine (sargramostim)', value: 'Leukine (sargramostim)' },
  {
    name: 'Leuprolide Acetate (leuprolide acetate)',
    value: 'Leuprolide Acetate (leuprolide acetate)',
  },
  { name: 'Levaquin (levofloxacin)', value: 'Levaquin (levofloxacin)' },
  {
    name: 'Levbid (hyoscyamine sulfate)',
    value: 'Levbid (hyoscyamine sulfate)',
  },
  {
    name: 'Levemir (insulin detemir (rDNA origin))',
    value: 'Levemir (insulin detemir (rDNA origin))',
  },
  {
    name: 'Levetiracetam Injection (levetiracetam)',
    value: 'Levetiracetam Injection (levetiracetam)',
  },
  {
    name: 'Levetiracetam Tablet (levetiracetam)',
    value: 'Levetiracetam Tablet (levetiracetam)',
  },
  {
    name: 'Levitra (vardenafil hydrochloride)',
    value: 'Levitra (vardenafil hydrochloride)',
  },
  {
    name: 'Levofloxacin Ophthalmic Solution (levofloxacin)',
    value: 'Levofloxacin Ophthalmic Solution (levofloxacin)',
  },
  {
    name: 'Levoleucovorin (levoleucovorin)',
    value: 'Levoleucovorin (levoleucovorin)',
  },
  {
    name: 'Levophed (norepinephrine bitartrate)',
    value: 'Levophed (norepinephrine bitartrate)',
  },
  {
    name: 'Levora (ethinyl estradiol/levonorgestrel)',
    value: 'Levora (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'Levorphanol Tartrate (levorphanol tartrate)',
    value: 'Levorphanol Tartrate (levorphanol tartrate)',
  },
  {
    name: 'Levothyroxine Sodium Injection (levothyroxine sodium)',
    value: 'Levothyroxine Sodium Injection (levothyroxine sodium)',
  },
  {
    name: 'Levoxyl (levothyroxine sodium)',
    value: 'Levoxyl (levothyroxine sodium)',
  },
  {
    name: 'Levsin Injection (hyoscyamine sulfate)',
    value: 'Levsin Injection (hyoscyamine sulfate)',
  },
  {
    name: 'Levsin Tablets (hyoscyamine sulfate)',
    value: 'Levsin Tablets (hyoscyamine sulfate)',
  },
  {
    name: 'Levsin/SL (hyoscyamine sulfate)',
    value: 'Levsin/SL (hyoscyamine sulfate)',
  },
  {
    name: 'Levulan (aminolevulinic acid hydrochloride)',
    value: 'Levulan (aminolevulinic acid hydrochloride)',
  },
  {
    name: 'Lexapro (escitalopram oxalate)',
    value: 'Lexapro (escitalopram oxalate)',
  },
  { name: 'Lexiscan (regadenoson)', value: 'Lexiscan (regadenoson)' },
  {
    name: 'Lexiva (fosamprenavir calcium)',
    value: 'Lexiva (fosamprenavir calcium)',
  },
  { name: 'Lialda (mesalamine)', value: 'Lialda (mesalamine)' },
  {
    name: 'Librax (chlordiazepoxide hydrochloride/clidinium bromide)',
    value: 'Librax (chlordiazepoxide hydrochloride/clidinium bromide)',
  },
  { name: 'Lidocaine Cream (lidocaine)', value: 'Lidocaine Cream (lidocaine)' },
  {
    name: 'Lidocaine Hydrochloride and 5% Dextrose (lidocaine hydrochloride/dextrose)',
    value:
      'Lidocaine Hydrochloride and 5% Dextrose (lidocaine hydrochloride/dextrose)',
  },
  {
    name: 'Lidocaine Hydrochloride Injection (lidocaine hydrochloride)',
    value: 'Lidocaine Hydrochloride Injection (lidocaine hydrochloride)',
  },
  {
    name: 'Lidocaine Ointment (lidocaine)',
    value: 'Lidocaine Ointment (lidocaine)',
  },
  {
    name: 'Lidocaine Viscous Solution (lidocaine hydrochloride)',
    value: 'Lidocaine Viscous Solution (lidocaine hydrochloride)',
  },
  { name: 'Lidoderm Patch (lidocaine)', value: 'Lidoderm Patch (lidocaine)' },
  {
    name: 'Liletta SHI (levonorgestrel)',
    value: 'Liletta SHI (levonorgestrel)',
  },
  {
    name: 'Lincocin (lincomycin hydrochloride)',
    value: 'Lincocin (lincomycin hydrochloride)',
  },
  { name: 'Lindane Lotion (lindane)', value: 'Lindane Lotion (lindane)' },
  { name: 'Lindane Shampoo (lindane)', value: 'Lindane Shampoo (lindane)' },
  { name: 'Linzess (linaclotide)', value: 'Linzess (linaclotide)' },
  { name: 'Lioresal (baclofen)', value: 'Lioresal (baclofen)' },
  {
    name: 'Lipitor (atorvastatin calcium)',
    value: 'Lipitor (atorvastatin calcium)',
  },
  { name: 'Lipofen (fenofibrate)', value: 'Lipofen (fenofibrate)' },
  {
    name: 'Liptruzet (atorvastatin/ezetimibe)',
    value: 'Liptruzet (atorvastatin/ezetimibe)',
  },
  {
    name: 'Lithium Carbonate Capsules and Tablets, Lithium Oral Solution (lithium); (lithium carbonate)',
    value:
      'Lithium Carbonate Capsules and Tablets, Lithium Oral Solution (lithium); (lithium carbonate)',
  },
  {
    name: 'Lithium Carbonate Extended Release Tablets (lithium carbonate)',
    value: 'Lithium Carbonate Extended Release Tablets (lithium carbonate)',
  },
  {
    name: 'Lithobid (lithium carbonate)',
    value: 'Lithobid (lithium carbonate)',
  },
  {
    name: 'Lithostat (acetohydroxamic acid)',
    value: 'Lithostat (acetohydroxamic acid)',
  },
  {
    name: 'Lo Loestrin Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
    value:
      'Lo Loestrin Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
  },
  {
    name: 'Locoid Cream (hydrocortisone butyrate)',
    value: 'Locoid Cream (hydrocortisone butyrate)',
  },
  {
    name: 'Locoid Lipocream (hydrocortisone butyrate)',
    value: 'Locoid Lipocream (hydrocortisone butyrate)',
  },
  {
    name: 'Locoid Lotion (hydrocortisone butyrate)',
    value: 'Locoid Lotion (hydrocortisone butyrate)',
  },
  {
    name: 'Locoid Ointment (hydrocortisone butyrate)',
    value: 'Locoid Ointment (hydrocortisone butyrate)',
  },
  {
    name: 'Locoid Solution (hydrocortisone butyrate)',
    value: 'Locoid Solution (hydrocortisone butyrate)',
  },
  { name: 'Lodosyn (carbidopa)', value: 'Lodosyn (carbidopa)' },
  {
    name: 'Loestrin/Loestrin Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate); (ethinyl e etc.',
    value:
      'Loestrin/Loestrin Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate); (ethinyl e etc.',
  },
  {
    name: 'Lofibra Capsules (fenofibrate)',
    value: 'Lofibra Capsules (fenofibrate)',
  },
  {
    name: 'Lofibra Tablets (fenofibrate)',
    value: 'Lofibra Tablets (fenofibrate)',
  },
  {
    name: 'Lokelma (sodium zirconium cyclosilicate)',
    value: 'Lokelma (sodium zirconium cyclosilicate)',
  },
  {
    name: 'Lomaira (phentermine hydrochloride)',
    value: 'Lomaira (phentermine hydrochloride)',
  },
  {
    name: 'Lomotil (atropine sulfate/diphenoxylate hydrochloride)',
    value: 'Lomotil (atropine sulfate/diphenoxylate hydrochloride)',
  },
  {
    name: 'Lonsurf (tipiracil/trifluridine)',
    value: 'Lonsurf (tipiracil/trifluridine)',
  },
  {
    name: 'Loperamide Hydrochloride Capsules (loperamide hydrochloride)',
    value: 'Loperamide Hydrochloride Capsules (loperamide hydrochloride)',
  },
  { name: 'Lopid (gemfibrozil)', value: 'Lopid (gemfibrozil)' },
  {
    name: 'Lopressor HCT (hydrochlorothiazide/metoprolol tartrate)',
    value: 'Lopressor HCT (hydrochlorothiazide/metoprolol tartrate)',
  },
  {
    name: 'Lopressor Injection (metoprolol tartrate)',
    value: 'Lopressor Injection (metoprolol tartrate)',
  },
  {
    name: 'Lopressor Tablets (metoprolol tartrate)',
    value: 'Lopressor Tablets (metoprolol tartrate)',
  },
  {
    name: 'Loprox Cream (ciclopirox olamine)',
    value: 'Loprox Cream (ciclopirox olamine)',
  },
  { name: 'Loprox Shampoo (ciclopirox)', value: 'Loprox Shampoo (ciclopirox)' },
  {
    name: 'Loprox Topical Suspension (ciclopirox olamine)',
    value: 'Loprox Topical Suspension (ciclopirox olamine)',
  },
  {
    name: 'Lorazepam Intensol Oral Concentrate (lorazepam)',
    value: 'Lorazepam Intensol Oral Concentrate (lorazepam)',
  },
  { name: 'Lorbrena (lorlatinib)', value: 'Lorbrena (lorlatinib)' },
  {
    name: 'Lorcet/Lorcet HD/Lorcet Plus (acetaminophen/hydrocodone bitartrate)',
    value:
      'Lorcet/Lorcet HD/Lorcet Plus (acetaminophen/hydrocodone bitartrate)',
  },
  {
    name: 'Lortab Elixir (acetaminophen/hydrocodone bitartrate)',
    value: 'Lortab Elixir (acetaminophen/hydrocodone bitartrate)',
  },
  {
    name: 'Lortab Tablets (acetaminophen/hydrocodone bitartrate)',
    value: 'Lortab Tablets (acetaminophen/hydrocodone bitartrate)',
  },
  {
    name: 'Loryna (drospirenone/ethinyl estradiol)',
    value: 'Loryna (drospirenone/ethinyl estradiol)',
  },
  { name: 'Lorzone (chlorzoxazone)', value: 'Lorzone (chlorzoxazone)' },
  {
    name: 'Losartan Potassium (losartan potassium)',
    value: 'Losartan Potassium (losartan potassium)',
  },
  {
    name: 'LoSeasonique (ethinyl estradiol/levonorgestrel)',
    value: 'LoSeasonique (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'Lotemax Gel (loteprednol etabonate)',
    value: 'Lotemax Gel (loteprednol etabonate)',
  },
  {
    name: 'Lotemax Ointment (loteprednol etabonate)',
    value: 'Lotemax Ointment (loteprednol etabonate)',
  },
  {
    name: 'Lotemax SM (loteprednol etabonate)',
    value: 'Lotemax SM (loteprednol etabonate)',
  },
  {
    name: 'Lotemax Suspension (loteprednol etabonate)',
    value: 'Lotemax Suspension (loteprednol etabonate)',
  },
  {
    name: 'Lotensin (benazepril hydrochloride)',
    value: 'Lotensin (benazepril hydrochloride)',
  },
  {
    name: 'Lotensin HCT (benazepril hydrochloride/hydrochlorothiazide)',
    value: 'Lotensin HCT (benazepril hydrochloride/hydrochlorothiazide)',
  },
  {
    name: 'Lotrel (amlodipine besylate/benazepril hydrochloride)',
    value: 'Lotrel (amlodipine besylate/benazepril hydrochloride)',
  },
  {
    name: 'Lotrimin AF Athlete’s Foot Cream (clotrimazole)',
    value: 'Lotrimin AF Athlete’s Foot Cream (clotrimazole)',
  },
  {
    name: 'Lotrimin Ultra Cream (butenafine hydrochloride)',
    value: 'Lotrimin Ultra Cream (butenafine hydrochloride)',
  },
  {
    name: 'Lotrisone (betamethasone dipropionate/clotrimazole)',
    value: 'Lotrisone (betamethasone dipropionate/clotrimazole)',
  },
  {
    name: 'Lotronex (alosetron hydrochloride)',
    value: 'Lotronex (alosetron hydrochloride)',
  },
  { name: 'Lovastatin (lovastatin)', value: 'Lovastatin (lovastatin)' },
  {
    name: 'Lovaza (omega-3-acid ethyl esters)',
    value: 'Lovaza (omega-3-acid ethyl esters)',
  },
  { name: 'Lovenox (enoxaparin sodium)', value: 'Lovenox (enoxaparin sodium)' },
  {
    name: 'Low-Ogestrel (ethinyl estradiol/norgestrel)',
    value: 'Low-Ogestrel (ethinyl estradiol/norgestrel)',
  },
  { name: 'Loxapine (loxapine)', value: 'Loxapine (loxapine)' },
  { name: 'Lucentis (ranibizumab)', value: 'Lucentis (ranibizumab)' },
  { name: 'Lumigan (bimatoprost)', value: 'Lumigan (bimatoprost)' },
  {
    name: 'Lumizyme (alglucosidase alfa)',
    value: 'Lumizyme (alglucosidase alfa)',
  },
  {
    name: 'Lumoxiti, IV Solution Stabilizer (moxetumomab pasudotox-tdfk)',
    value: 'Lumoxiti, IV Solution Stabilizer (moxetumomab pasudotox-tdfk)',
  },
  { name: 'Lunesta (eszopiclone)', value: 'Lunesta (eszopiclone)' },
  {
    name: 'Lupaneta Pack 11.25 mg (leuprolide acetate/norethindrone acetate)',
    value: 'Lupaneta Pack 11.25 mg (leuprolide acetate/norethindrone acetate)',
  },
  {
    name: 'Lupaneta Pack 3.75 mg (leuprolide acetate/norethindrone acetate)',
    value: 'Lupaneta Pack 3.75 mg (leuprolide acetate/norethindrone acetate)',
  },
  {
    name: 'Lupron Depot 11.25 mg- 3 Month (leuprolide acetate)',
    value: 'Lupron Depot 11.25 mg- 3 Month (leuprolide acetate)',
  },
  {
    name: 'Lupron Depot 3.75 mg (leuprolide acetate)',
    value: 'Lupron Depot 3.75 mg (leuprolide acetate)',
  },
  {
    name: 'Lupron Depot 7.5 mg 1-Month, 22.5 mg 3-Month, 30 mg 4-Month, 45 mg 6-Month (leuprolide acetate)',
    value:
      'Lupron Depot 7.5 mg 1-Month, 22.5 mg 3-Month, 30 mg 4-Month, 45 mg 6-Month (leuprolide acetate)',
  },
  {
    name: 'Lupron Depot-PED (leuprolide acetate)',
    value: 'Lupron Depot-PED (leuprolide acetate)',
  },
  {
    name: 'Lutathera (lutetium lu 177 dotatate)',
    value: 'Lutathera (lutetium lu 177 dotatate)',
  },
  {
    name: 'Lutera (ethinyl estradiol/levonorgestrel)',
    value: 'Lutera (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'Luxiq (betamethasone valerate)',
    value: 'Luxiq (betamethasone valerate)',
  },
  {
    name: 'Luxturna (voretigene neparvovec)',
    value: 'Luxturna (voretigene neparvovec)',
  },
  { name: 'Luzu (luliconazole)', value: 'Luzu (luliconazole)' },
  { name: 'Lynparza (olaparib)', value: 'Lynparza (olaparib)' },
  { name: 'Lyrica (pregabalin)', value: 'Lyrica (pregabalin)' },
  { name: 'Lyrica CR (pregabalin)', value: 'Lyrica CR (pregabalin)' },
  { name: 'Lysodren (mitotane)', value: 'Lysodren (mitotane)' },
  { name: 'Lysteda (tranexamic acid)', value: 'Lysteda (tranexamic acid)' },
  { name: 'Lyza (norethindrone)', value: 'Lyza (norethindrone)' },
  {
    name: 'M-M-R II (measles, mumps, and rubella virus vaccine live)',
    value: 'M-M-R II (measles, mumps, and rubella virus vaccine live)',
  },
  {
    name: 'Macrobid (nitrofurantoin macrocrystals/nitrofurantoin monohydrate)',
    value: 'Macrobid (nitrofurantoin macrocrystals/nitrofurantoin monohydrate)',
  },
  {
    name: 'Macrodantin (nitrofurantoin macrocrystals)',
    value: 'Macrodantin (nitrofurantoin macrocrystals)',
  },
  { name: 'Macugen (pegaptanib sodium)', value: 'Macugen (pegaptanib sodium)' },
  {
    name: 'MagneCal D (boron/calcium/magnesium/silicon/vitamin e)',
    value: 'MagneCal D (boron/calcium/magnesium/silicon/vitamin e)',
  },
  {
    name: 'Magnevist Injection (gadopentetate dimeglumine)',
    value: 'Magnevist Injection (gadopentetate dimeglumine)',
  },
  {
    name: 'Makena (hydroxyprogesterone caproate)',
    value: 'Makena (hydroxyprogesterone caproate)',
  },
  {
    name: 'Malarone (atovaquone/proguanil hydrochloride)',
    value: 'Malarone (atovaquone/proguanil hydrochloride)',
  },
  {
    name: 'Maprotiline Hydrochloride (maprotiline hydrochloride)',
    value: 'Maprotiline Hydrochloride (maprotiline hydrochloride)',
  },
  {
    name: 'Marcaine Spinal (bupivacaine hydrochloride)',
    value: 'Marcaine Spinal (bupivacaine hydrochloride)',
  },
  {
    name: 'Marcaine/Marcaine with Epinephrine (bupivacaine hydrochloride); (bupivacaine hydrochloride/epinephrine)',
    value:
      'Marcaine/Marcaine with Epinephrine (bupivacaine hydrochloride); (bupivacaine hydrochloride/epinephrine)',
  },
  { name: 'Marinol (dronabinol)', value: 'Marinol (dronabinol)' },
  { name: 'Marplan (isocarboxazid)', value: 'Marplan (isocarboxazid)' },
  {
    name: 'Marqibo (vincristine sulfate liposome)',
    value: 'Marqibo (vincristine sulfate liposome)',
  },
  {
    name: 'Matulane (procarbazine hydrochloride)',
    value: 'Matulane (procarbazine hydrochloride)',
  },
  { name: 'Mavik (trandolapril)', value: 'Mavik (trandolapril)' },
  {
    name: 'Mavyret (glecaprevir/pibrentasvir)',
    value: 'Mavyret (glecaprevir/pibrentasvir)',
  },
  {
    name: 'Maxalt (rizatriptan benzoate)',
    value: 'Maxalt (rizatriptan benzoate)',
  },
  { name: 'Maxidex (dexamethasone)', value: 'Maxidex (dexamethasone)' },
  {
    name: 'Maximum Strength Mucinex (guaifenesin)',
    value: 'Maximum Strength Mucinex (guaifenesin)',
  },
  {
    name: 'Maximum Strength Mucinex D (guaifenesin/pseudoephedrine hydrochloride)',
    value:
      'Maximum Strength Mucinex D (guaifenesin/pseudoephedrine hydrochloride)',
  },
  {
    name: 'Maximum Strength Mucinex DM (dextromethorphan hydrobromide/guaifenesin)',
    value:
      'Maximum Strength Mucinex DM (dextromethorphan hydrobromide/guaifenesin)',
  },
  {
    name: 'Maximum Strength Mucinex Fast-Max, Cold, Flu & Sore Throat (acetaminophen/dextromethorphan hydrobromide/guaifenesin/phenylephrine etc.',
    value:
      'Maximum Strength Mucinex Fast-Max, Cold, Flu & Sore Throat (acetaminophen/dextromethorphan hydrobromide/guaifenesin/phenylephrine etc.',
  },
  {
    name: 'Maxipime (cefepime hydrochloride)',
    value: 'Maxipime (cefepime hydrochloride)',
  },
  {
    name: 'Maxitrol Ophthalmic Ointment (dexamethasone/neomycin sulfate/polymyxin B sulfate)',
    value:
      'Maxitrol Ophthalmic Ointment (dexamethasone/neomycin sulfate/polymyxin B sulfate)',
  },
  {
    name: 'Maxitrol Suspension (dexamethasone/neomycin sulfate/polymyxin B sulfate)',
    value:
      'Maxitrol Suspension (dexamethasone/neomycin sulfate/polymyxin B sulfate)',
  },
  {
    name: 'Maxzide/Maxzide-25 (hydrochlorothiazide/triamterene)',
    value: 'Maxzide/Maxzide-25 (hydrochlorothiazide/triamterene)',
  },
  { name: 'Mayzent (siponimod)', value: 'Mayzent (siponimod)' },
  {
    name: 'Meclofenamate Sodium (meclofenamate sodium)',
    value: 'Meclofenamate Sodium (meclofenamate sodium)',
  },
  { name: 'Medrol (methylprednisolone)', value: 'Medrol (methylprednisolone)' },
  {
    name: 'Mefloquine Hydrochloride (mefloquine hydrochloride)',
    value: 'Mefloquine Hydrochloride (mefloquine hydrochloride)',
  },
  { name: 'Megace (megestrol acetate)', value: 'Megace (megestrol acetate)' },
  {
    name: 'Megace ES (megestrol acetate)',
    value: 'Megace ES (megestrol acetate)',
  },
  {
    name: 'Megestrol Acetate (megestrol acetate)',
    value: 'Megestrol Acetate (megestrol acetate)',
  },
  { name: 'Mekinist (trametinib)', value: 'Mekinist (trametinib)' },
  { name: 'Mektovi (binimetinib)', value: 'Mektovi (binimetinib)' },
  {
    name: 'Menactra (meningococcal (groups A, C, Y and W-135) polysaccharide diphtheria to etc.',
    value:
      'Menactra (meningococcal (groups A, C, Y and W-135) polysaccharide diphtheria to etc.',
  },
  {
    name: 'Menest (esterified estrogens)',
    value: 'Menest (esterified estrogens)',
  },
  {
    name: 'Menhibrix (meningococcal groups C and Y and haemophilus b tetanus toxoid conjuga etc.',
    value:
      'Menhibrix (meningococcal groups C and Y and haemophilus b tetanus toxoid conjuga etc.',
  },
  {
    name: 'Menomune (meningococcal polysaccharide vaccine, groups A, C, Y and W-135 combin etc.',
    value:
      'Menomune (meningococcal polysaccharide vaccine, groups A, C, Y and W-135 combin etc.',
  },
  { name: 'Menopur (menotropins)', value: 'Menopur (menotropins)' },
  { name: 'Menostar (estradiol)', value: 'Menostar (estradiol)' },
  {
    name: 'Mentax (butenafine hydrochloride)',
    value: 'Mentax (butenafine hydrochloride)',
  },
  {
    name: 'Menveo (meningococcal (groups A, C, Y and W-135) oligosaccharide diphtheria C etc.',
    value:
      'Menveo (meningococcal (groups A, C, Y and W-135) oligosaccharide diphtheria C etc.',
  },
  {
    name: 'Meperidine Hydrochloride Injection Solution (meperidine hydrochloride)',
    value:
      'Meperidine Hydrochloride Injection Solution (meperidine hydrochloride)',
  },
  {
    name: 'Meperidine Hydrochloride Oral Solution and Tablets (meperidine hydrochloride)',
    value:
      'Meperidine Hydrochloride Oral Solution and Tablets (meperidine hydrochloride)',
  },
  { name: 'Mephyton (phytonadione)', value: 'Mephyton (phytonadione)' },
  { name: 'Mepron (atovaquone)', value: 'Mepron (atovaquone)' },
  {
    name: 'MEPSEVII (vestronidase alfa-vjbk)',
    value: 'MEPSEVII (vestronidase alfa-vjbk)',
  },
  { name: 'Merrem (meropenem)', value: 'Merrem (meropenem)' },
  { name: 'Mesnex (mesna)', value: 'Mesnex (mesna)' },
  {
    name: 'Mestinon (pyridostigmine bromide)',
    value: 'Mestinon (pyridostigmine bromide)',
  },
  {
    name: 'Metabolic MAX (dietary supplement)',
    value: 'Metabolic MAX (dietary supplement)',
  },
  {
    name: 'Metabolic ONE (dietary supplement)',
    value: 'Metabolic ONE (dietary supplement)',
  },
  {
    name: 'Metabolic TEN (dietary supplement)',
    value: 'Metabolic TEN (dietary supplement)',
  },
  {
    name: 'Metadate CD (methylphenidate hydrochloride)',
    value: 'Metadate CD (methylphenidate hydrochloride)',
  },
  {
    name: 'Metadate ER (methylphenidate hydrochloride)',
    value: 'Metadate ER (methylphenidate hydrochloride)',
  },
  {
    name: 'Metaproterenol Sulfate Syrup (metaproterenol sulfate)',
    value: 'Metaproterenol Sulfate Syrup (metaproterenol sulfate)',
  },
  {
    name: 'Metaproterenol Sulfate Tablets (metaproterenol sulfate)',
    value: 'Metaproterenol Sulfate Tablets (metaproterenol sulfate)',
  },
  { name: 'Metaxalone (metaxalone)', value: 'Metaxalone (metaxalone)' },
  {
    name: 'Methadone Hydrochloride Intensol Oral Concentrate (methadone hydrochloride)',
    value:
      'Methadone Hydrochloride Intensol Oral Concentrate (methadone hydrochloride)',
  },
  {
    name: 'Methadone Hydrochloride Oral Solution (methadone hydrochloride)',
    value: 'Methadone Hydrochloride Oral Solution (methadone hydrochloride)',
  },
  {
    name: 'Methotrexate Tablets (methotrexate)',
    value: 'Methotrexate Tablets (methotrexate)',
  },
  {
    name: 'Methyclothiazide (methyclothiazide)',
    value: 'Methyclothiazide (methyclothiazide)',
  },
  { name: 'Methyldopa (methyldopa)', value: 'Methyldopa (methyldopa)' },
  {
    name: 'Methyldopa and Hydrochlorothiazide (hydrochlorothiazide/methyldopa)',
    value:
      'Methyldopa and Hydrochlorothiazide (hydrochlorothiazide/methyldopa)',
  },
  {
    name: 'Methyldopate Hydrochloride (methyldopate hydrochloride)',
    value: 'Methyldopate Hydrochloride (methyldopate hydrochloride)',
  },
  {
    name: 'Methylene Blue 1% (methylene blue)',
    value: 'Methylene Blue 1% (methylene blue)',
  },
  {
    name: 'Methylergonovine Maleate Injection (methylergonovine maleate)',
    value: 'Methylergonovine Maleate Injection (methylergonovine maleate)',
  },
  {
    name: 'Methylergonovine Maleate Tablets (methylergonovine maleate)',
    value: 'Methylergonovine Maleate Tablets (methylergonovine maleate)',
  },
  {
    name: 'Methylin Chewable Tablets (methylphenidate hydrochloride)',
    value: 'Methylin Chewable Tablets (methylphenidate hydrochloride)',
  },
  {
    name: 'Methylin Oral Solution (methylphenidate hydrochloride)',
    value: 'Methylin Oral Solution (methylphenidate hydrochloride)',
  },
  {
    name: 'Methylphenidate Hydrochloride ER (methylphenidate hydrochloride)',
    value: 'Methylphenidate Hydrochloride ER (methylphenidate hydrochloride)',
  },
  {
    name: 'Methylphenidate Hydrochloride/Methylphenidate Hydrochloride ER (methylphenidate hydrochloride)',
    value:
      'Methylphenidate Hydrochloride/Methylphenidate Hydrochloride ER (methylphenidate hydrochloride)',
  },
  {
    name: 'Methylprednisolone Sodium Succinate (methylprednisolone sodium succinate)',
    value:
      'Methylprednisolone Sodium Succinate (methylprednisolone sodium succinate)',
  },
  { name: 'Metipranolol (metipranolol)', value: 'Metipranolol (metipranolol)' },
  {
    name: 'Metoclopramide Injection (metoclopramide)',
    value: 'Metoclopramide Injection (metoclopramide)',
  },
  {
    name: 'Metoclopramide Oral Solution (metoclopramide)',
    value: 'Metoclopramide Oral Solution (metoclopramide)',
  },
  { name: 'Metolazone (metolazone)', value: 'Metolazone (metolazone)' },
  { name: 'Metopirone (metyrapone)', value: 'Metopirone (metyrapone)' },
  {
    name: 'Metoprolol Tartrate (metoprolol tartrate)',
    value: 'Metoprolol Tartrate (metoprolol tartrate)',
  },
  {
    name: 'Metoprolol Tartrate and Hydrochlorothiazide (hydrochlorothiazide/metoprolol tartrate)',
    value:
      'Metoprolol Tartrate and Hydrochlorothiazide (hydrochlorothiazide/metoprolol tartrate)',
  },
  {
    name: 'Metozolv ODT (metoclopramide hydrochloride)',
    value: 'Metozolv ODT (metoclopramide hydrochloride)',
  },
  { name: 'MetroCream (metronidazole)', value: 'MetroCream (metronidazole)' },
  {
    name: 'Metrogel Gel, 1% (metronidazole)',
    value: 'Metrogel Gel, 1% (metronidazole)',
  },
  {
    name: 'Metrogel-Vaginal (metronidazole)',
    value: 'Metrogel-Vaginal (metronidazole)',
  },
  { name: 'MetroLotion (metronidazole)', value: 'MetroLotion (metronidazole)' },
  {
    name: 'Metronidazole Gel 0.75% (metronidazole)',
    value: 'Metronidazole Gel 0.75% (metronidazole)',
  },
  {
    name: 'Metronidazole Injection (metronidazole)',
    value: 'Metronidazole Injection (metronidazole)',
  },
  {
    name: 'Mexiletine Hydrochloride (mexiletine hydrochloride)',
    value: 'Mexiletine Hydrochloride (mexiletine hydrochloride)',
  },
  {
    name: 'Miacalcin Injection (calcitonin-salmon)',
    value: 'Miacalcin Injection (calcitonin-salmon)',
  },
  {
    name: 'Miacalcin Nasal Spray (calcitonin-salmon)',
    value: 'Miacalcin Nasal Spray (calcitonin-salmon)',
  },
  {
    name: 'Mibelas 24 Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
    value:
      'Mibelas 24 Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
  },
  { name: 'Micardis (telmisartan)', value: 'Micardis (telmisartan)' },
  {
    name: 'Micardis HCT (hydrochlorothiazide/telmisartan)',
    value: 'Micardis HCT (hydrochlorothiazide/telmisartan)',
  },
  {
    name: 'MICRhoGAM/RhoGAM (rho (D) immune globulin (human))',
    value: 'MICRhoGAM/RhoGAM (rho (D) immune globulin (human))',
  },
  {
    name: 'Micro-K 10 (potassium chloride)',
    value: 'Micro-K 10 (potassium chloride)',
  },
  {
    name: 'Microgestin/Microgestin Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate); (ethinyl e etc.',
    value:
      'Microgestin/Microgestin Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate); (ethinyl e etc.',
  },
  {
    name: 'Microzide (hydrochlorothiazide)',
    value: 'Microzide (hydrochlorothiazide)',
  },
  {
    name: 'Midamor (amiloride hydrochloride)',
    value: 'Midamor (amiloride hydrochloride)',
  },
  {
    name: 'Midazolam Hydrochloride Injection (midazolam hydrochloride)',
    value: 'Midazolam Hydrochloride Injection (midazolam hydrochloride)',
  },
  {
    name: 'Midazolam Hydrochloride Syrup (midazolam hydrochloride)',
    value: 'Midazolam Hydrochloride Syrup (midazolam hydrochloride)',
  },
  {
    name: 'Mie Collagen + Probiotics (dietary supplement)',
    value: 'Mie Collagen + Probiotics (dietary supplement)',
  },
  {
    name: 'Migergot (caffeine/ergotamine tartrate)',
    value: 'Migergot (caffeine/ergotamine tartrate)',
  },
  {
    name: 'Migranal (dihydroergotamine mesylate)',
    value: 'Migranal (dihydroergotamine mesylate)',
  },
  {
    name: 'Millipred Oral Solution (prednisolone sodium phosphate)',
    value: 'Millipred Oral Solution (prednisolone sodium phosphate)',
  },
  {
    name: 'Millipred/Millipred DP Tablets (prednisolone)',
    value: 'Millipred/Millipred DP Tablets (prednisolone)',
  },
  {
    name: 'Milrinone Lactate (milrinone lactate)',
    value: 'Milrinone Lactate (milrinone lactate)',
  },
  {
    name: 'Minastrin 24 Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
    value:
      'Minastrin 24 Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
  },
  {
    name: 'Minipress (prazosin hydrochloride)',
    value: 'Minipress (prazosin hydrochloride)',
  },
  { name: 'Minitran (nitroglycerin)', value: 'Minitran (nitroglycerin)' },
  { name: 'Minivelle (estradiol)', value: 'Minivelle (estradiol)' },
  {
    name: 'Minocin Intravenous (minocycline)',
    value: 'Minocin Intravenous (minocycline)',
  },
  {
    name: 'Minocin Pellet-Filled Capsules (minocycline hydrochloride)',
    value: 'Minocin Pellet-Filled Capsules (minocycline hydrochloride)',
  },
  {
    name: 'Minocycline Hydrochloride Capsules (minocycline hydrochloride)',
    value: 'Minocycline Hydrochloride Capsules (minocycline hydrochloride)',
  },
  {
    name: 'Minocycline Hydrochloride Extended-Release Tablets (minocycline hydrochloride)',
    value:
      'Minocycline Hydrochloride Extended-Release Tablets (minocycline hydrochloride)',
  },
  { name: 'Minoxidil (minoxidil)', value: 'Minoxidil (minoxidil)' },
  {
    name: 'Miochol-E (acetylcholine chloride)',
    value: 'Miochol-E (acetylcholine chloride)',
  },
  { name: 'Miostat (carbachol)', value: 'Miostat (carbachol)' },
  {
    name: 'MiraLax (polyethylene glycol 3350)',
    value: 'MiraLax (polyethylene glycol 3350)',
  },
  {
    name: 'Mirapex (pramipexole dihydrochloride)',
    value: 'Mirapex (pramipexole dihydrochloride)',
  },
  {
    name: 'Mirapex ER (pramipexole dihydrochloride)',
    value: 'Mirapex ER (pramipexole dihydrochloride)',
  },
  {
    name: 'Mircette (desogestrel/ethinyl estradiol)',
    value: 'Mircette (desogestrel/ethinyl estradiol)',
  },
  { name: 'Mirena (levonorgestrel)', value: 'Mirena (levonorgestrel)' },
  { name: 'Mirtazapine (mirtazapine)', value: 'Mirtazapine (mirtazapine)' },
  { name: 'Mirvaso (brimonidine)', value: 'Mirvaso (brimonidine)' },
  { name: 'Mitigare (colchicine)', value: 'Mitigare (colchicine)' },
  { name: 'Mitomycin (mitomycin)', value: 'Mitomycin (mitomycin)' },
  { name: 'Mitosol (mitomycin)', value: 'Mitosol (mitomycin)' },
  { name: 'Mitoxantrone (mitoxantrone)', value: 'Mitoxantrone (mitoxantrone)' },
  { name: 'Mobic (meloxicam)', value: 'Mobic (meloxicam)' },
  { name: 'Moderiba (ribavirin)', value: 'Moderiba (ribavirin)' },
  {
    name: 'Modicon/Ortho-Novum (ethinyl estradiol/norethindrone)',
    value: 'Modicon/Ortho-Novum (ethinyl estradiol/norethindrone)',
  },
  {
    name: 'Molindone Hydrochloride (molindone hydrochloride)',
    value: 'Molindone Hydrochloride (molindone hydrochloride)',
  },
  {
    name: 'Mometasone Furoate Topical Solution (mometasone furoate)',
    value: 'Mometasone Furoate Topical Solution (mometasone furoate)',
  },
  {
    name: 'Monoclate-P (antihemophilic factor (human))',
    value: 'Monoclate-P (antihemophilic factor (human))',
  },
  {
    name: 'Monodox (doxycycline monohydrate)',
    value: 'Monodox (doxycycline monohydrate)',
  },
  {
    name: 'MonoNessa/TriNessa (ethinyl estradiol/norgestimate)',
    value: 'MonoNessa/TriNessa (ethinyl estradiol/norgestimate)',
  },
  {
    name: 'Mononine (coagulation factor IX (human))',
    value: 'Mononine (coagulation factor IX (human))',
  },
  {
    name: 'Monurol (fosfomycin tromethamine)',
    value: 'Monurol (fosfomycin tromethamine)',
  },
  {
    name: 'MorphaBond (morphine sulfate)',
    value: 'MorphaBond (morphine sulfate)',
  },
  {
    name: 'Morphine Sulfate Oral Solution (morphine sulfate)',
    value: 'Morphine Sulfate Oral Solution (morphine sulfate)',
  },
  {
    name: 'Morphine Sulfate Tablets (morphine sulfate)',
    value: 'Morphine Sulfate Tablets (morphine sulfate)',
  },
  { name: 'Motegrity (prucalopride)', value: 'Motegrity (prucalopride)' },
  {
    name: 'Motofen (atropine sulfate/difenoxin hydrochloride)',
    value: 'Motofen (atropine sulfate/difenoxin hydrochloride)',
  },
  { name: 'Movantik (naloxegol)', value: 'Movantik (naloxegol)' },
  {
    name: 'MoviPrep (ascorbic acid/polyethylene glycol 3350/potassium chloride/sodium asco etc.',
    value:
      'MoviPrep (ascorbic acid/polyethylene glycol 3350/potassium chloride/sodium asco etc.',
  },
  { name: 'Moxatag (amoxicillin)', value: 'Moxatag (amoxicillin)' },
  {
    name: 'Moxeza (moxifloxacin hydrochloride)',
    value: 'Moxeza (moxifloxacin hydrochloride)',
  },
  { name: 'Mozobil (plerixafor)', value: 'Mozobil (plerixafor)' },
  {
    name: 'MS Contin (morphine sulfate)',
    value: 'MS Contin (morphine sulfate)',
  },
  { name: 'Mucinex (guaifenesin)', value: 'Mucinex (guaifenesin)' },
  {
    name: 'Mucinex D (guaifenesin/pseudoephedrine hydrochloride)',
    value: 'Mucinex D (guaifenesin/pseudoephedrine hydrochloride)',
  },
  {
    name: 'Mucinex DM (dextromethorphan hydrobromide/guaifenesin)',
    value: 'Mucinex DM (dextromethorphan hydrobromide/guaifenesin)',
  },
  {
    name: 'Mucinex Fast-Max Severe Congestion & Cold (acetaminophen/dextromethorphan hydrobromide/guaifenesin/phenylephrine etc.',
    value:
      'Mucinex Fast-Max Severe Congestion & Cold (acetaminophen/dextromethorphan hydrobromide/guaifenesin/phenylephrine etc.',
  },
  { name: 'Multaq (dronedarone)', value: 'Multaq (dronedarone)' },
  {
    name: 'Multihance Injection Vials (gadobenate dimeglumine)',
    value: 'Multihance Injection Vials (gadobenate dimeglumine)',
  },
  {
    name: 'Muro 128 2% Solution (sodium chloride)',
    value: 'Muro 128 2% Solution (sodium chloride)',
  },
  {
    name: 'Muro 128 5% Solution (sodium chloride)',
    value: 'Muro 128 5% Solution (sodium chloride)',
  },
  {
    name: 'Muro 128 Ointment (sodium chloride)',
    value: 'Muro 128 Ointment (sodium chloride)',
  },
  { name: 'Muse (alprostadil)', value: 'Muse (alprostadil)' },
  {
    name: 'Mustargen (mechlorethamine hydrochloride)',
    value: 'Mustargen (mechlorethamine hydrochloride)',
  },
  { name: 'Mvasi (bevacizumab-awwb)', value: 'Mvasi (bevacizumab-awwb)' },
  { name: 'Myalept (metreleptin)', value: 'Myalept (metreleptin)' },
  {
    name: 'Myambutol (ethambutol hydrochloride)',
    value: 'Myambutol (ethambutol hydrochloride)',
  },
  {
    name: 'Mycamine (micafungin sodium)',
    value: 'Mycamine (micafungin sodium)',
  },
  { name: 'Mycobutin (rifabutin)', value: 'Mycobutin (rifabutin)' },
  { name: 'Mydriacyl (tropicamide)', value: 'Mydriacyl (tropicamide)' },
  {
    name: 'Myfortic (mycophenolic acid)',
    value: 'Myfortic (mycophenolic acid)',
  },
  { name: 'Myleran (busulfan)', value: 'Myleran (busulfan)' },
  {
    name: 'Mylotarg (gemtuzumab/ozogamicin)',
    value: 'Mylotarg (gemtuzumab/ozogamicin)',
  },
  {
    name: 'Myobloc (rimabotulinumtoxinB)',
    value: 'Myobloc (rimabotulinumtoxinB)',
  },
  {
    name: 'Myozyme (alglucosidase alfa)',
    value: 'Myozyme (alglucosidase alfa)',
  },
  { name: 'Myrbetriq (mirabegron)', value: 'Myrbetriq (mirabegron)' },
  { name: 'Mysoline (primidone)', value: 'Mysoline (primidone)' },
  { name: 'Mytesi (crofelemer)', value: 'Mytesi (crofelemer)' },
  {
    name: 'Myxredlin (insulin human in sodium chloride)',
    value: 'Myxredlin (insulin human in sodium chloride)',
  },
  {
    name: 'Nabi-HB (hepatitis B immune globulin (human))',
    value: 'Nabi-HB (hepatitis B immune globulin (human))',
  },
  { name: 'Nabumetone (nabumetone)', value: 'Nabumetone (nabumetone)' },
  { name: 'Nafcillin (nafcillin)', value: 'Nafcillin (nafcillin)' },
  {
    name: 'Naftin Cream 1% and Gel 1% (naftifine hydrochloride)',
    value: 'Naftin Cream 1% and Gel 1% (naftifine hydrochloride)',
  },
  {
    name: 'Naftin Cream 2% (naftifine hydrochloride)',
    value: 'Naftin Cream 2% (naftifine hydrochloride)',
  },
  {
    name: 'Naftin Gel 2% (naftifine hydrochloride)',
    value: 'Naftin Gel 2% (naftifine hydrochloride)',
  },
  { name: 'Naglazyme (galsulfase)', value: 'Naglazyme (galsulfase)' },
  {
    name: 'Nalbuphine Hydrochloride (nalbuphine hydrochloride)',
    value: 'Nalbuphine Hydrochloride (nalbuphine hydrochloride)',
  },
  { name: 'Nalfon (fenoprofen calcium)', value: 'Nalfon (fenoprofen calcium)' },
  {
    name: 'name2Buspirone Hydrochloride Tablets, USP (5 mg, 10 mg, 15 mg, 30 mg)',
    value:
      'name2Buspirone Hydrochloride Tablets, USP (5 mg, 10 mg, 15 mg, 30 mg)',
  },
  {
    name: 'Namenda (memantine hydrochloride)',
    value: 'Namenda (memantine hydrochloride)',
  },
  {
    name: 'Namenda XR (memantine hydrochloride)',
    value: 'Namenda XR (memantine hydrochloride)',
  },
  {
    name: 'Namzaric (donepezil hydrochloride/memantine hydrochloride)',
    value: 'Namzaric (donepezil hydrochloride/memantine hydrochloride)',
  },
  {
    name: 'Naphazoline (naphazoline hydrochloride)',
    value: 'Naphazoline (naphazoline hydrochloride)',
  },
  {
    name: 'Naphcon-A (naphazoline hydrochloride/pheniramine maleate)',
    value: 'Naphcon-A (naphazoline hydrochloride/pheniramine maleate)',
  },
  { name: 'Naprelan (naproxen sodium)', value: 'Naprelan (naproxen sodium)' },
  {
    name: 'Narcan (naloxone hydrochloride)',
    value: 'Narcan (naloxone hydrochloride)',
  },
  { name: 'Nardil (phenelzine sulfate)', value: 'Nardil (phenelzine sulfate)' },
  {
    name: 'Naropin (ropivacaine hydrochloride)',
    value: 'Naropin (ropivacaine hydrochloride)',
  },
  {
    name: 'Nasacort Allergy 24HR (triamcinolone acetonide)',
    value: 'Nasacort Allergy 24HR (triamcinolone acetonide)',
  },
  { name: 'Nascobal (cyanocobalamin)', value: 'Nascobal (cyanocobalamin)' },
  {
    name: 'Nasonex (mometasone furoate monohydrate)',
    value: 'Nasonex (mometasone furoate monohydrate)',
  },
  { name: 'Natacyn (natamycin)', value: 'Natacyn (natamycin)' },
  {
    name: 'Natazia (dienogest/estradiol valerate)',
    value: 'Natazia (dienogest/estradiol valerate)',
  },
  { name: 'Natesto (testosterone)', value: 'Natesto (testosterone)' },
  {
    name: 'Natpara (parathyroid hormone)',
    value: 'Natpara (parathyroid hormone)',
  },
  { name: 'Natrecor (nesiritide)', value: 'Natrecor (nesiritide)' },
  { name: 'Natroba (spinosad)', value: 'Natroba (spinosad)' },
  {
    name: 'Navelbine (vinorelbine tartrate)',
    value: 'Navelbine (vinorelbine tartrate)',
  },
  { name: 'Nayzilam (midazolam)', value: 'Nayzilam (midazolam)' },
  {
    name: 'NebuPent (pentamidine isethionate)',
    value: 'NebuPent (pentamidine isethionate)',
  },
  {
    name: 'Necon (ethinyl estradiol/norethindrone); (mestranol/norethindrone)',
    value: 'Necon (ethinyl estradiol/norethindrone); (mestranol/norethindrone)',
  },
  {
    name: 'Necon 7/7/7 (ethinyl estradiol/norethindrone)',
    value: 'Necon 7/7/7 (ethinyl estradiol/norethindrone)',
  },
  {
    name: 'Nefazodone (nefazodone hydrochloride)',
    value: 'Nefazodone (nefazodone hydrochloride)',
  },
  {
    name: 'Nembutal (pentobarbital sodium)',
    value: 'Nembutal (pentobarbital sodium)',
  },
  {
    name: 'Neo-Synalar (fluocinolone acetonide/neomycin sulfate)',
    value: 'Neo-Synalar (fluocinolone acetonide/neomycin sulfate)',
  },
  {
    name: 'Neomycin and Polymyxin B Sulfates and Hydrocortisone Ophthalmic Suspension (hydrocortisone/neomycin sulfate/polymyxin B sulfate)',
    value:
      'Neomycin and Polymyxin B Sulfates and Hydrocortisone Ophthalmic Suspension (hydrocortisone/neomycin sulfate/polymyxin B sulfate)',
  },
  {
    name: 'Neomycin and Polymyxin B Sulfates and Hydrocortisone Otic Suspension (hydrocortisone/neomycin sulfate/polymyxin B sulfate)',
    value:
      'Neomycin and Polymyxin B Sulfates and Hydrocortisone Otic Suspension (hydrocortisone/neomycin sulfate/polymyxin B sulfate)',
  },
  {
    name: 'Neomycin and Polymyxin B Sulfates, Bacitracin Zinc and Hydrocortisone (bacitracin zinc/hydrocortisone/neomycin sulfate/polymyxin B sulfate)',
    value:
      'Neomycin and Polymyxin B Sulfates, Bacitracin Zinc and Hydrocortisone (bacitracin zinc/hydrocortisone/neomycin sulfate/polymyxin B sulfate)',
  },
  {
    name: 'Neomycin Sulfate (neomycin sulfate)',
    value: 'Neomycin Sulfate (neomycin sulfate)',
  },
  {
    name: 'NeoProfen (ibuprofen lysine)',
    value: 'NeoProfen (ibuprofen lysine)',
  },
  { name: 'Neoral (cyclosporine)', value: 'Neoral (cyclosporine)' },
  {
    name: 'Neosporin G.U. Irrigant (neomycin sulfate/polymyxin B sulfate)',
    value: 'Neosporin G.U. Irrigant (neomycin sulfate/polymyxin B sulfate)',
  },
  {
    name: 'Neosporin Ophthalmic Solution (gramicidin/neomycin sulfate/polymyxin B sulfate)',
    value:
      'Neosporin Ophthalmic Solution (gramicidin/neomycin sulfate/polymyxin B sulfate)',
  },
  {
    name: 'Neosporin Original Ointment (bacitracin/neomycin/polymyxin B sulfate)',
    value:
      'Neosporin Original Ointment (bacitracin/neomycin/polymyxin B sulfate)',
  },
  { name: 'Nerlynx (neratinib)', value: 'Nerlynx (neratinib)' },
  {
    name: 'Nesacaine (chloroprocaine hydrochloride)',
    value: 'Nesacaine (chloroprocaine hydrochloride)',
  },
  { name: 'Nesina (alogliptin)', value: 'Nesina (alogliptin)' },
  {
    name: 'Netspot (gallium Ga 68 dotatate)',
    value: 'Netspot (gallium Ga 68 dotatate)',
  },
  { name: 'Neulasta (pegfilgrastim)', value: 'Neulasta (pegfilgrastim)' },
  { name: 'Neumega (oprelvekin)', value: 'Neumega (oprelvekin)' },
  { name: 'Neupogen (filgrastim)', value: 'Neupogen (filgrastim)' },
  { name: 'Neupro (rotigotine)', value: 'Neupro (rotigotine)' },
  { name: 'Neurontin (gabapentin)', value: 'Neurontin (gabapentin)' },
  { name: 'Nevanac (nepafenac)', value: 'Nevanac (nepafenac)' },
  { name: 'Nexavar (sorafenib)', value: 'Nexavar (sorafenib)' },
  {
    name: 'Nexium 24HR Delayed-Release Capsules (esomeprazole)',
    value: 'Nexium 24HR Delayed-Release Capsules (esomeprazole)',
  },
  {
    name: 'Nexium 24HR Delayed-Release Tablets (esomeprazole)',
    value: 'Nexium 24HR Delayed-Release Tablets (esomeprazole)',
  },
  {
    name: 'Nexium Delayed-Release Capsules and Oral Suspension (esomeprazole magnesium)',
    value:
      'Nexium Delayed-Release Capsules and Oral Suspension (esomeprazole magnesium)',
  },
  {
    name: 'Nexium I.V. (esomeprazole sodium)',
    value: 'Nexium I.V. (esomeprazole sodium)',
  },
  { name: 'Nexplanon (etonogestrel)', value: 'Nexplanon (etonogestrel)' },
  {
    name: 'Nexterone (amiodarone hydrochloride)',
    value: 'Nexterone (amiodarone hydrochloride)',
  },
  { name: 'Niaspan (niacin)', value: 'Niaspan (niacin)' },
  {
    name: 'Nicardipine Hydrochloride Capsules (nicardipine hydrochloride)',
    value: 'Nicardipine Hydrochloride Capsules (nicardipine hydrochloride)',
  },
  { name: 'Nicotrol Inhaler (nicotine)', value: 'Nicotrol Inhaler (nicotine)' },
  { name: 'Nicotrol NS (nicotine)', value: 'Nicotrol NS (nicotine)' },
  { name: 'Nifedical XL (nifedipine)', value: 'Nifedical XL (nifedipine)' },
  { name: 'Nifedipine (nifedipine)', value: 'Nifedipine (nifedipine)' },
  { name: 'Nilandron (nilutamide)', value: 'Nilandron (nilutamide)' },
  {
    name: 'Nimbex (cisatracurium besylate)',
    value: 'Nimbex (cisatracurium besylate)',
  },
  { name: 'Nimodipine (nimodipine)', value: 'Nimodipine (nimodipine)' },
  { name: 'Ninlaro (ixazomib)', value: 'Ninlaro (ixazomib)' },
  { name: 'Nipent (pentostatin)', value: 'Nipent (pentostatin)' },
  {
    name: 'Nipride RTU (sodium nitroprusside)',
    value: 'Nipride RTU (sodium nitroprusside)',
  },
  { name: 'Nitro-Bid (nitroglycerin)', value: 'Nitro-Bid (nitroglycerin)' },
  { name: 'Nitro-Dur (nitroglycerin)', value: 'Nitro-Dur (nitroglycerin)' },
  {
    name: 'Nitroglycerin in 5% Dextrose (nitroglycerin)',
    value: 'Nitroglycerin in 5% Dextrose (nitroglycerin)',
  },
  {
    name: 'Nitrolingual (nitroglycerin)',
    value: 'Nitrolingual (nitroglycerin)',
  },
  { name: 'NitroMist (nitroglycerin)', value: 'NitroMist (nitroglycerin)' },
  {
    name: 'Nitropress (sodium nitroprusside)',
    value: 'Nitropress (sodium nitroprusside)',
  },
  { name: 'Nitrostat (nitroglycerin)', value: 'Nitrostat (nitroglycerin)' },
  { name: 'Nivestym (filgrastim-aafi)', value: 'Nivestym (filgrastim-aafi)' },
  {
    name: 'Nizatidine Capsules (nizatidine)',
    value: 'Nizatidine Capsules (nizatidine)',
  },
  {
    name: 'Nizatidine Oral Solution (nizatidine)',
    value: 'Nizatidine Oral Solution (nizatidine)',
  },
  { name: 'Nizoral 2% (ketoconazole)', value: 'Nizoral 2% (ketoconazole)' },
  {
    name: 'NMN Gene Balance (dietary supplement)',
    value: 'NMN Gene Balance (dietary supplement)',
  },
  {
    name: 'NMN Plus (dietary supplement)',
    value: 'NMN Plus (dietary supplement)',
  },
  {
    name: 'Nocdurna (desmopressin acetate)',
    value: 'Nocdurna (desmopressin acetate)',
  },
  {
    name: 'Noctiva (desmopressin acetate)',
    value: 'Noctiva (desmopressin acetate)',
  },
  { name: 'Nor-QD (norethindrone)', value: 'Nor-QD (norethindrone)' },
  {
    name: 'Norco (acetaminophen/hydrocodone bitartrate)',
    value: 'Norco (acetaminophen/hydrocodone bitartrate)',
  },
  {
    name: 'Norditropin (somatropin (rDNA origin))',
    value: 'Norditropin (somatropin (rDNA origin))',
  },
  {
    name: 'Norgestimate and Ethinyl Estradiol (ethinyl estradiol/norgestimate)',
    value:
      'Norgestimate and Ethinyl Estradiol (ethinyl estradiol/norgestimate)',
  },
  { name: 'Noritate (metronidazole)', value: 'Noritate (metronidazole)' },
  {
    name: 'Norpace/Norpace CR (disopyramide phosphate)',
    value: 'Norpace/Norpace CR (disopyramide phosphate)',
  },
  {
    name: 'Norpramin (desipramine hydrochloride)',
    value: 'Norpramin (desipramine hydrochloride)',
  },
  { name: 'Northera (droxidopa)', value: 'Northera (droxidopa)' },
  {
    name: 'Nortrel 21 Day Regimen/28 Day Regimen (ethinyl estradiol/norethindrone)',
    value:
      'Nortrel 21 Day Regimen/28 Day Regimen (ethinyl estradiol/norethindrone)',
  },
  {
    name: 'Nortrel 7/7/7 (ethinyl estradiol/norethindrone)',
    value: 'Nortrel 7/7/7 (ethinyl estradiol/norethindrone)',
  },
  {
    name: 'Nortriptyline Hydrochloride Capsules (nortriptyline hydrochloride)',
    value: 'Nortriptyline Hydrochloride Capsules (nortriptyline hydrochloride)',
  },
  {
    name: 'Nortriptyline Hydrochloride Oral Solution (nortriptyline hydrochloride)',
    value:
      'Nortriptyline Hydrochloride Oral Solution (nortriptyline hydrochloride)',
  },
  {
    name: 'Norvasc (amlodipine besylate)',
    value: 'Norvasc (amlodipine besylate)',
  },
  { name: 'Norvir Capsules (ritonavir)', value: 'Norvir Capsules (ritonavir)' },
  {
    name: 'Norvir Oral Solution and Tablets (ritonavir)',
    value: 'Norvir Oral Solution and Tablets (ritonavir)',
  },
  {
    name: 'Novacort (hydrocortisone acetate/pramoxine hydrochloride)',
    value: 'Novacort (hydrocortisone acetate/pramoxine hydrochloride)',
  },
  {
    name: 'Novarel (chorionic gonadotropin)',
    value: 'Novarel (chorionic gonadotropin)',
  },
  {
    name: 'Novoeight (antihemophilic factor (recombinant))',
    value: 'Novoeight (antihemophilic factor (recombinant))',
  },
  {
    name: 'Novolin 70/30 (NPH, human insulin isophane (rDNA origin)/regular, human insulin (rDN etc.',
    value:
      'Novolin 70/30 (NPH, human insulin isophane (rDNA origin)/regular, human insulin (rDN etc.',
  },
  {
    name: 'Novolin N (NPH, human insulin isophane (rDNA origin))',
    value: 'Novolin N (NPH, human insulin isophane (rDNA origin))',
  },
  {
    name: 'Novolin R (regular, human insulin (rDNA origin))',
    value: 'Novolin R (regular, human insulin (rDNA origin))',
  },
  {
    name: 'NovoLog (insulin aspart (rDNA origin))',
    value: 'NovoLog (insulin aspart (rDNA origin))',
  },
  {
    name: 'NovoLog Mix 70/30 (insulin aspart protamine (rDNA origin)/insulin aspart (rDNA origin))',
    value:
      'NovoLog Mix 70/30 (insulin aspart protamine (rDNA origin)/insulin aspart (rDNA origin))',
  },
  {
    name: 'NovoSeven RT (coagulation factor VIIa (recombinant))',
    value: 'NovoSeven RT (coagulation factor VIIa (recombinant))',
  },
  { name: 'Noxafil (posaconazole)', value: 'Noxafil (posaconazole)' },
  { name: 'Nplate (romiplostim)', value: 'Nplate (romiplostim)' },
  { name: 'Nubeqa (darolutamide)', value: 'Nubeqa (darolutamide)' },
  { name: 'Nucala (mepolizumab)', value: 'Nucala (mepolizumab)' },
  { name: 'Nucynta (tapentadol)', value: 'Nucynta (tapentadol)' },
  { name: 'Nucynta ER (tapentadol)', value: 'Nucynta ER (tapentadol)' },
  {
    name: 'Nuedexta (dextromethorphan hydrobromide/quinidine sulfate)',
    value: 'Nuedexta (dextromethorphan hydrobromide/quinidine sulfate)',
  },
  { name: 'Nulojix (belatacept)', value: 'Nulojix (belatacept)' },
  {
    name: 'NuLYTELY (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
    value:
      'NuLYTELY (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
  },
  { name: 'Nuplazid (pimavanserin)', value: 'Nuplazid (pimavanserin)' },
  {
    name: 'Nurtec ODT (rimegepant sulfate)',
    value: 'Nurtec ODT (rimegepant sulfate)',
  },
  { name: 'NutreStore (L-glutamine)', value: 'NutreStore (L-glutamine)' },
  {
    name: 'Nutropin AQ (somatropin (rDNA origin))',
    value: 'Nutropin AQ (somatropin (rDNA origin))',
  },
  {
    name: 'NuvaRing (ethinyl estradiol/etonogestrel)',
    value: 'NuvaRing (ethinyl estradiol/etonogestrel)',
  },
  { name: 'Nuvessa (metronidazole)', value: 'Nuvessa (metronidazole)' },
  { name: 'Nuvigil (armodafinil)', value: 'Nuvigil (armodafinil)' },
  {
    name: 'Nuwiq (antihemophilic factor (recombinant))',
    value: 'Nuwiq (antihemophilic factor (recombinant))',
  },
  { name: 'Nyamyc (nystatin)', value: 'Nyamyc (nystatin)' },
  { name: 'Nymalize (nimodipine)', value: 'Nymalize (nimodipine)' },
  {
    name: 'Nystatin and Triamcinolone Acetonide (nystatin/triamcinolone acetonide)',
    value:
      'Nystatin and Triamcinolone Acetonide (nystatin/triamcinolone acetonide)',
  },
  { name: 'Nystatin Cream (nystatin)', value: 'Nystatin Cream (nystatin)' },
  {
    name: 'Nystatin Ointment (nystatin)',
    value: 'Nystatin Ointment (nystatin)',
  },
  {
    name: 'Nystatin Oral Suspension (nystatin)',
    value: 'Nystatin Oral Suspension (nystatin)',
  },
  { name: 'Nystatin Tablets (nystatin)', value: 'Nystatin Tablets (nystatin)' },
  { name: 'Nystop (nystatin)', value: 'Nystop (nystatin)' },
  {
    name: 'Obizur (antihemophilic factor (recombinant), porcine sequence)',
    value: 'Obizur (antihemophilic factor (recombinant), porcine sequence)',
  },
  {
    name: 'Obredon (guaifenesin/hydrocodone bitartrate)',
    value: 'Obredon (guaifenesin/hydrocodone bitartrate)',
  },
  { name: 'Ocaliva (obeticholic acid)', value: 'Ocaliva (obeticholic acid)' },
  {
    name: 'Ocella (drospirenone/ethinyl estradiol)',
    value: 'Ocella (drospirenone/ethinyl estradiol)',
  },
  { name: 'Ocrevus (ocrelizumab)', value: 'Ocrevus (ocrelizumab)' },
  {
    name: 'Octagam 10% (immune globulin intravenous (human))',
    value: 'Octagam 10% (immune globulin intravenous (human))',
  },
  {
    name: 'Octagam 5% (immune globulin intravenous (human))',
    value: 'Octagam 5% (immune globulin intravenous (human))',
  },
  {
    name: 'Ocufen (flurbiprofen sodium)',
    value: 'Ocufen (flurbiprofen sodium)',
  },
  { name: 'Ocuflox (ofloxacin)', value: 'Ocuflox (ofloxacin)' },
  {
    name: 'Odactra (dermatophagoides farinae and dermatophagoides pteronyssinus)',
    value:
      'Odactra (dermatophagoides farinae and dermatophagoides pteronyssinus)',
  },
  {
    name: 'Odefsey (emtricitabine/rilpivirine/tenofovir alafenamide)',
    value: 'Odefsey (emtricitabine/rilpivirine/tenofovir alafenamide)',
  },
  { name: 'Odomzo (sonidegib)', value: 'Odomzo (sonidegib)' },
  { name: 'Ofev (nintedanib)', value: 'Ofev (nintedanib)' },
  { name: 'Ofirmev (acetaminophen)', value: 'Ofirmev (acetaminophen)' },
  {
    name: 'Ofloxacin Otic Ophthalmic Solution (ofloxacin)',
    value: 'Ofloxacin Otic Ophthalmic Solution (ofloxacin)',
  },
  {
    name: 'Ogestrel (ethinyl estradiol/norgestrel)',
    value: 'Ogestrel (ethinyl estradiol/norgestrel)',
  },
  { name: 'Ogivri (trastuzumab-dkst)', value: 'Ogivri (trastuzumab-dkst)' },
  {
    name: 'Oleptro (trazodone hydrochloride)',
    value: 'Oleptro (trazodone hydrochloride)',
  },
  { name: 'Olumiant (baricitinib)', value: 'Olumiant (baricitinib)' },
  {
    name: 'Olux (clobetasol propionate)',
    value: 'Olux (clobetasol propionate)',
  },
  {
    name: 'Olux-E (clobetasol propionate)',
    value: 'Olux-E (clobetasol propionate)',
  },
  { name: 'Olysio (simeprevir)', value: 'Olysio (simeprevir)' },
  {
    name: 'Omeclamox-Pak (amoxicillin/clarithromycin/omeprazole)',
    value: 'Omeclamox-Pak (amoxicillin/clarithromycin/omeprazole)',
  },
  { name: 'Omegaven (fish oil)', value: 'Omegaven (fish oil)' },
  { name: 'Omeprazole (omeprazole)', value: 'Omeprazole (omeprazole)' },
  {
    name: 'Omidria (ketorolac/phenylephrine)',
    value: 'Omidria (ketorolac/phenylephrine)',
  },
  { name: 'Omnaris (ciclesonide)', value: 'Omnaris (ciclesonide)' },
  { name: 'Omnipaque (iohexol)', value: 'Omnipaque (iohexol)' },
  {
    name: 'Omnipred (prednisolone acetate)',
    value: 'Omnipred (prednisolone acetate)',
  },
  {
    name: 'Omnitrope (somatropin (rDNA origin))',
    value: 'Omnitrope (somatropin (rDNA origin))',
  },
  { name: 'Oncaspar (pegaspargase)', value: 'Oncaspar (pegaspargase)' },
  {
    name: 'Ondansetron (ondansetron hydrochloride)',
    value: 'Ondansetron (ondansetron hydrochloride)',
  },
  {
    name: 'Onexton (benzoyl peroxide/clindamycin phosphate)',
    value: 'Onexton (benzoyl peroxide/clindamycin phosphate)',
  },
  { name: 'Onfi (clobazam)', value: 'Onfi (clobazam)' },
  { name: 'Onglyza (saxagliptin)', value: 'Onglyza (saxagliptin)' },
  {
    name: 'Onivyde (irinotecan liposome)',
    value: 'Onivyde (irinotecan liposome)',
  },
  { name: 'Onzetra Xsail (sumatriptan)', value: 'Onzetra Xsail (sumatriptan)' },
  {
    name: 'Opana ER (oxymorphone hydrochloride)',
    value: 'Opana ER (oxymorphone hydrochloride)',
  },
  {
    name: 'Opana Injection (oxymorphone hydrochloride)',
    value: 'Opana Injection (oxymorphone hydrochloride)',
  },
  {
    name: 'Opana Tablets (oxymorphone hydrochloride)',
    value: 'Opana Tablets (oxymorphone hydrochloride)',
  },
  {
    name: 'OPC Supreme (dietary supplement)',
    value: 'OPC Supreme (dietary supplement)',
  },
  {
    name: 'Opcon-A (naphazoline hydrochloride/pheniramine maleate)',
    value: 'Opcon-A (naphazoline hydrochloride/pheniramine maleate)',
  },
  { name: 'Opdivo (nivolumab)', value: 'Opdivo (nivolumab)' },
  { name: 'Opsumit (macitentan)', value: 'Opsumit (macitentan)' },
  {
    name: 'Optimals (dietary supplement)',
    value: 'Optimals (dietary supplement)',
  },
  { name: 'Optimark (gadoversetamide)', value: 'Optimark (gadoversetamide)' },
  { name: 'Optiray (ioversol)', value: 'Optiray (ioversol)' },
  {
    name: 'Optison (perflutren protein-type A microspheres)',
    value: 'Optison (perflutren protein-type A microspheres)',
  },
  {
    name: 'Optivar (azelastine hydrochloride)',
    value: 'Optivar (azelastine hydrochloride)',
  },
  { name: 'Oracea (doxycycline)', value: 'Oracea (doxycycline)' },
  {
    name: 'Oralair (sweet vernal/orchard/perennial rye/timothy/kentucky bluegrass mixed p etc.',
    value:
      'Oralair (sweet vernal/orchard/perennial rye/timothy/kentucky bluegrass mixed p etc.',
  },
  { name: 'Orap (pimozide)', value: 'Orap (pimozide)' },
  {
    name: 'Orapred ODT (prednisolone sodium phosphate)',
    value: 'Orapred ODT (prednisolone sodium phosphate)',
  },
  {
    name: 'Oraqix (lidocaine/prilocaine)',
    value: 'Oraqix (lidocaine/prilocaine)',
  },
  { name: 'Oravig (miconazole)', value: 'Oravig (miconazole)' },
  { name: 'Orbactiv (oritavancin)', value: 'Orbactiv (oritavancin)' },
  { name: 'Orencia (abatacept)', value: 'Orencia (abatacept)' },
  { name: 'Orenitram (treprostinil)', value: 'Orenitram (treprostinil)' },
  { name: 'Orfadin (nitisinone)', value: 'Orfadin (nitisinone)' },
  {
    name: 'Oriahnn (elagolix/estradiol/norethisterone)',
    value: 'Oriahnn (elagolix/estradiol/norethisterone)',
  },
  { name: 'Orilissa (elagolix)', value: 'Orilissa (elagolix)' },
  {
    name: 'Orkambi (ivacaftor/lumacaftor)',
    value: 'Orkambi (ivacaftor/lumacaftor)',
  },
  {
    name: 'Orphenadrine Citrate Extended-Release Tablets (orphenadrine citrate)',
    value:
      'Orphenadrine Citrate Extended-Release Tablets (orphenadrine citrate)',
  },
  {
    name: 'Orphenadrine Citrate Injection (orphenadrine citrate)',
    value: 'Orphenadrine Citrate Injection (orphenadrine citrate)',
  },
  {
    name: 'Orsythia (ethinyl estradiol/levonorgestrel)',
    value: 'Orsythia (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'Ortho Micronor (norethindrone)',
    value: 'Ortho Micronor (norethindrone)',
  },
  {
    name: 'Ortho Tri-Cyclen Lo (ethinyl estradiol/norgestimate)',
    value: 'Ortho Tri-Cyclen Lo (ethinyl estradiol/norgestimate)',
  },
  {
    name: 'Ortho Tri-Cyclen/Ortho-Cyclen (ethinyl estradiol/norgestimate)',
    value: 'Ortho Tri-Cyclen/Ortho-Cyclen (ethinyl estradiol/norgestimate)',
  },
  {
    name: 'Ortho-Cept (desogestrel/ethinyl estradiol)',
    value: 'Ortho-Cept (desogestrel/ethinyl estradiol)',
  },
  {
    name: 'Oseni (alogliptin/pioglitazone)',
    value: 'Oseni (alogliptin/pioglitazone)',
  },
  { name: 'Osmitrol (mannitol)', value: 'Osmitrol (mannitol)' },
  { name: 'Osmolex ER (amantadine)', value: 'Osmolex ER (amantadine)' },
  {
    name: 'OsmoPrep (sodium phosphate dibasic anhydrous/sodium phosphate monobasic monohyd etc.',
    value:
      'OsmoPrep (sodium phosphate dibasic anhydrous/sodium phosphate monobasic monohyd etc.',
  },
  { name: 'Osphena (ospemifene)', value: 'Osphena (ospemifene)' },
  { name: 'Otezla (apremilast)', value: 'Otezla (apremilast)' },
  { name: 'Otiprio (ciprofloxacin)', value: 'Otiprio (ciprofloxacin)' },
  {
    name: 'Otovel (ciprofloxacin/fluocinolone acetonide)',
    value: 'Otovel (ciprofloxacin/fluocinolone acetonide)',
  },
  { name: 'Otrexup (methotrexate)', value: 'Otrexup (methotrexate)' },
  {
    name: 'Ovace Plus Cream (sodium sulfacetamide)',
    value: 'Ovace Plus Cream (sodium sulfacetamide)',
  },
  {
    name: 'Ovace Plus Foam (sodium sulfacetamide)',
    value: 'Ovace Plus Foam (sodium sulfacetamide)',
  },
  {
    name: 'Ovace Plus Lotion (sodium sulfacetamide)',
    value: 'Ovace Plus Lotion (sodium sulfacetamide)',
  },
  {
    name: 'Ovace Plus Shampoo (sodium sulfacetamide)',
    value: 'Ovace Plus Shampoo (sodium sulfacetamide)',
  },
  {
    name: 'Ovace Plus Wash (sodium sulfacetamide)',
    value: 'Ovace Plus Wash (sodium sulfacetamide)',
  },
  {
    name: 'Ovace Plus Wash Cleansing Gel (sodium sulfacetamide)',
    value: 'Ovace Plus Wash Cleansing Gel (sodium sulfacetamide)',
  },
  {
    name: 'Ovace Wash (sodium sulfacetamide)',
    value: 'Ovace Wash (sodium sulfacetamide)',
  },
  {
    name: 'Ovcon 35 (ethinyl estradiol/norethindrone)',
    value: 'Ovcon 35 (ethinyl estradiol/norethindrone)',
  },
  {
    name: 'Ovidrel (choriogonadotropin alfa)',
    value: 'Ovidrel (choriogonadotropin alfa)',
  },
  { name: 'Oxacillin (oxacillin)', value: 'Oxacillin (oxacillin)' },
  { name: 'Oxaliplatin (oxaliplatin)', value: 'Oxaliplatin (oxaliplatin)' },
  { name: 'Oxandrin (oxandrolone)', value: 'Oxandrin (oxandrolone)' },
  {
    name: 'Oxaydo (oxycodone hydrochloride)',
    value: 'Oxaydo (oxycodone hydrochloride)',
  },
  { name: 'Oxazepam (oxazepam)', value: 'Oxazepam (oxazepam)' },
  {
    name: 'Oxistat (oxiconazole nitrate)',
    value: 'Oxistat (oxiconazole nitrate)',
  },
  { name: 'Oxsoralen (methoxsalen)', value: 'Oxsoralen (methoxsalen)' },
  {
    name: 'Oxsoralen-Ultra (methoxsalen)',
    value: 'Oxsoralen-Ultra (methoxsalen)',
  },
  { name: 'Oxtellar XR (oxcarbazepine)', value: 'Oxtellar XR (oxcarbazepine)' },
  {
    name: 'Oxybutynin Chloride Syrup (oxybutynin chloride)',
    value: 'Oxybutynin Chloride Syrup (oxybutynin chloride)',
  },
  {
    name: 'Oxybutynin Chloride Tablets (oxybutynin chloride)',
    value: 'Oxybutynin Chloride Tablets (oxybutynin chloride)',
  },
  {
    name: 'Oxycodone HCl (oxycodone hydrochloride)',
    value: 'Oxycodone HCl (oxycodone hydrochloride)',
  },
  {
    name: 'Oxycodone Hydrochloride Oral Solution (oxycodone hydrochloride)',
    value: 'Oxycodone Hydrochloride Oral Solution (oxycodone hydrochloride)',
  },
  {
    name: 'Oxycodone Hydrochloride Tablets (oxycodone hydrochloride)',
    value: 'Oxycodone Hydrochloride Tablets (oxycodone hydrochloride)',
  },
  {
    name: 'OxyContin (oxycodone hydrochloride)',
    value: 'OxyContin (oxycodone hydrochloride)',
  },
  { name: 'Oxytrol (oxybutynin)', value: 'Oxytrol (oxybutynin)' },
  {
    name: 'Oxytrol for Women (oxybutynin)',
    value: 'Oxytrol for Women (oxybutynin)',
  },
  { name: 'Ozempic (semaglutide)', value: 'Ozempic (semaglutide)' },
  { name: 'Ozurdex (dexamethasone)', value: 'Ozurdex (dexamethasone)' },
  { name: 'Paclitaxel (paclitaxel)', value: 'Paclitaxel (paclitaxel)' },
  {
    name: 'Palonosetron hydrochloride (palonosetron hydrochloride)',
    value: 'Palonosetron hydrochloride (palonosetron hydrochloride)',
  },
  {
    name: 'Pamelor (nortriptyline hydrochloride)',
    value: 'Pamelor (nortriptyline hydrochloride)',
  },
  {
    name: 'Pamidronate Disodium (pamidronate disodium)',
    value: 'Pamidronate Disodium (pamidronate disodium)',
  },
  { name: 'Pancreaze (pancrelipase)', value: 'Pancreaze (pancrelipase)' },
  {
    name: 'Pancuronium Bromide (pancuronium bromide)',
    value: 'Pancuronium Bromide (pancuronium bromide)',
  },
  { name: 'Panhematin (hemin)', value: 'Panhematin (hemin)' },
  { name: 'Panretin (alitretinoin)', value: 'Panretin (alitretinoin)' },
  {
    name: 'Panzyga (immune globulin intravenous (human))',
    value: 'Panzyga (immune globulin intravenous (human))',
  },
  {
    name: 'Papaverine Hydrochloride (papaverine hydrochloride)',
    value: 'Papaverine Hydrochloride (papaverine hydrochloride)',
  },
  {
    name: 'Parafon Forte DSC (chlorzoxazone)',
    value: 'Parafon Forte DSC (chlorzoxazone)',
  },
  {
    name: 'Paremyd (hydroxyamphetamine hydrobromide/tropicamide)',
    value: 'Paremyd (hydroxyamphetamine hydrobromide/tropicamide)',
  },
  {
    name: 'Parlodel (bromocriptine mesylate)',
    value: 'Parlodel (bromocriptine mesylate)',
  },
  {
    name: 'Parnate (tranylcypromine sulfate)',
    value: 'Parnate (tranylcypromine sulfate)',
  },
  {
    name: 'Paromomycin Sulfate (paromomycin sulfate)',
    value: 'Paromomycin Sulfate (paromomycin sulfate)',
  },
  { name: 'Parsabiv (etelcalcetide)', value: 'Parsabiv (etelcalcetide)' },
  { name: 'Paser (aminosalicylic acid)', value: 'Paser (aminosalicylic acid)' },
  {
    name: 'Pataday (olopatadine hydrochloride)',
    value: 'Pataday (olopatadine hydrochloride)',
  },
  {
    name: 'Patanase (olopatadine hydrochloride)',
    value: 'Patanase (olopatadine hydrochloride)',
  },
  {
    name: 'Patanol (olopatadine hydrochloride)',
    value: 'Patanol (olopatadine hydrochloride)',
  },
  {
    name: 'Paxil (paroxetine hydrochloride)',
    value: 'Paxil (paroxetine hydrochloride)',
  },
  {
    name: 'Paxil CR (paroxetine hydrochloride)',
    value: 'Paxil CR (paroxetine hydrochloride)',
  },
  {
    name: 'Pazeo (olopatadine hydrochloride)',
    value: 'Pazeo (olopatadine hydrochloride)',
  },
  { name: 'PCE Tablets (erythromycin)', value: 'PCE Tablets (erythromycin)' },
  {
    name: 'Pediaderm HC (hydrocortisone)',
    value: 'Pediaderm HC (hydrocortisone)',
  },
  {
    name: 'Pediapred (prednisolone sodium phosphate)',
    value: 'Pediapred (prednisolone sodium phosphate)',
  },
  {
    name: 'Pediarix (diphtheria and tetanus toxoids and acellular pertussis adsorbed, hepa etc.',
    value:
      'Pediarix (diphtheria and tetanus toxoids and acellular pertussis adsorbed, hepa etc.',
  },
  {
    name: 'PedvaxHIB (haemophilus b conjugate vaccine (meningococcal protein conjugate))',
    value:
      'PedvaxHIB (haemophilus b conjugate vaccine (meningococcal protein conjugate))',
  },
  { name: 'Peganone (ethotoin)', value: 'Peganone (ethotoin)' },
  {
    name: 'Pegasys (peginterferon alfa-2a)',
    value: 'Pegasys (peginterferon alfa-2a)',
  },
  {
    name: 'Penicillin G Potassium (penicillin G potassium)',
    value: 'Penicillin G Potassium (penicillin G potassium)',
  },
  {
    name: 'Penicillin G Procaine (penicillin G procaine)',
    value: 'Penicillin G Procaine (penicillin G procaine)',
  },
  {
    name: 'Penicillin G Sodium (penicillin G sodium)',
    value: 'Penicillin G Sodium (penicillin G sodium)',
  },
  {
    name: 'Penicillin V Potassium (penicillin V potassium)',
    value: 'Penicillin V Potassium (penicillin V potassium)',
  },
  { name: 'Penlac (ciclopirox)', value: 'Penlac (ciclopirox)' },
  {
    name: 'Pennsaid 2% (diclofenac sodium)',
    value: 'Pennsaid 2% (diclofenac sodium)',
  },
  {
    name: 'Pentacel (diphtheria and tetanus toxoids and acellular pertussis adsorbed, inac etc.',
    value:
      'Pentacel (diphtheria and tetanus toxoids and acellular pertussis adsorbed, inac etc.',
  },
  { name: 'Pentasa (mesalamine)', value: 'Pentasa (mesalamine)' },
  {
    name: 'Pentazocine and Naloxone (naloxone/pentazocine)',
    value: 'Pentazocine and Naloxone (naloxone/pentazocine)',
  },
  {
    name: 'Pentoxifylline (pentoxifylline)',
    value: 'Pentoxifylline (pentoxifylline)',
  },
  {
    name: 'Pepcid AC Maximum Strength (famotidine)',
    value: 'Pepcid AC Maximum Strength (famotidine)',
  },
  {
    name: 'Pepcid AC Original Strength (famotidine)',
    value: 'Pepcid AC Original Strength (famotidine)',
  },
  {
    name: 'Pepcid for Oral Suspension (famotidine)',
    value: 'Pepcid for Oral Suspension (famotidine)',
  },
  { name: 'Pepcid Tablets (famotidine)', value: 'Pepcid Tablets (famotidine)' },
  {
    name: 'Percocet (acetaminophen/oxycodone)',
    value: 'Percocet (acetaminophen/oxycodone)',
  },
  {
    name: 'Percodan (aspirin/oxycodone)',
    value: 'Percodan (aspirin/oxycodone)',
  },
  {
    name: 'Perforomist (formoterol fumarate)',
    value: 'Perforomist (formoterol fumarate)',
  },
  {
    name: 'Peri-Colace (docusate sodium/sennosides)',
    value: 'Peri-Colace (docusate sodium/sennosides)',
  },
  {
    name: 'PerioGard (chlorhexidine gluconate)',
    value: 'PerioGard (chlorhexidine gluconate)',
  },
  { name: 'Perjeta (pertuzumab)', value: 'Perjeta (pertuzumab)' },
  { name: 'Perphenazine (perphenazine)', value: 'Perphenazine (perphenazine)' },
  {
    name: 'Perphenazine and Amitriptyline Hydrochloride (amitriptyline hydrochloride/perphenazine)',
    value:
      'Perphenazine and Amitriptyline Hydrochloride (amitriptyline hydrochloride/perphenazine)',
  },
  { name: 'Persantine (dipyridamole)', value: 'Persantine (dipyridamole)' },
  { name: 'Pertzye (pancrelipase)', value: 'Pertzye (pancrelipase)' },
  {
    name: 'Pexeva (paroxetine mesylate)',
    value: 'Pexeva (paroxetine mesylate)',
  },
  {
    name: 'Pfizerpen (penicillin G potassium)',
    value: 'Pfizerpen (penicillin G potassium)',
  },
  {
    name: 'Pharmanex g3 (dietary supplement)',
    value: 'Pharmanex g3 (dietary supplement)',
  },
  {
    name: 'Pharmanex LifePak (dietary supplement)',
    value: 'Pharmanex LifePak (dietary supplement)',
  },
  {
    name: 'Pharmanex ReishiMax GLp (dietary supplement)',
    value: 'Pharmanex ReishiMax GLp (dietary supplement)',
  },
  {
    name: 'Pharmanex Tegreen 97 (dietary supplement)',
    value: 'Pharmanex Tegreen 97 (dietary supplement)',
  },
  {
    name: 'Phenadoz (promethazine hydrochloride)',
    value: 'Phenadoz (promethazine hydrochloride)',
  },
  {
    name: 'Phendimetrazine Tartrate Extended-Release Capsules (phendimetrazine tartrate)',
    value:
      'Phendimetrazine Tartrate Extended-Release Capsules (phendimetrazine tartrate)',
  },
  {
    name: 'Phenobarbital Elixir (phenobarbital)',
    value: 'Phenobarbital Elixir (phenobarbital)',
  },
  {
    name: 'Phentermine Hydrochloride Capsules (phentermine hydrochloride)',
    value: 'Phentermine Hydrochloride Capsules (phentermine hydrochloride)',
  },
  { name: 'Phenytek (phenytoin sodium)', value: 'Phenytek (phenytoin sodium)' },
  {
    name: 'Phenytoin Sodium Injection (phenytoin sodium)',
    value: 'Phenytoin Sodium Injection (phenytoin sodium)',
  },
  { name: 'PhosLo (calcium acetate)', value: 'PhosLo (calcium acetate)' },
  { name: 'Phoslyra (calcium acetate)', value: 'Phoslyra (calcium acetate)' },
  {
    name: 'Phosphasal (hyoscyamine sulfate/methenamine/methylene blue/phenyl salicylate/sodi etc.',
    value:
      'Phosphasal (hyoscyamine sulfate/methenamine/methylene blue/phenyl salicylate/sodi etc.',
  },
  {
    name: 'Phospholine Iodide (echothiophate iodide)',
    value: 'Phospholine Iodide (echothiophate iodide)',
  },
  { name: 'Photofrin (porfimer sodium)', value: 'Photofrin (porfimer sodium)' },
  { name: 'Phytonadione (phytonadione)', value: 'Phytonadione (phytonadione)' },
  { name: 'Picato (ingenol mebutate)', value: 'Picato (ingenol mebutate)' },
  { name: 'Pifeltro (doravirine)', value: 'Pifeltro (doravirine)' },
  {
    name: 'Pin-X Chewable Tablets (pyrantel pamoate)',
    value: 'Pin-X Chewable Tablets (pyrantel pamoate)',
  },
  {
    name: 'Pin-X Liquid (pyrantel pamoate)',
    value: 'Pin-X Liquid (pyrantel pamoate)',
  },
  { name: 'Pindolol (pindolol)', value: 'Pindolol (pindolol)' },
  { name: 'Pinnacaine (benzocaine)', value: 'Pinnacaine (benzocaine)' },
  { name: 'Piqray (alpelisib)', value: 'Piqray (alpelisib)' },
  { name: 'Pitocin (oxytocin)', value: 'Pitocin (oxytocin)' },
  {
    name: 'Plan B One-Step (levonorgestrel)',
    value: 'Plan B One-Step (levonorgestrel)',
  },
  {
    name: 'Plaquenil (hydroxychloroquine sulfate)',
    value: 'Plaquenil (hydroxychloroquine sulfate)',
  },
  {
    name: 'Plasbumin-25 (albumin (human))',
    value: 'Plasbumin-25 (albumin (human))',
  },
  {
    name: 'Plasbumin-5 (albumin (human))',
    value: 'Plasbumin-5 (albumin (human))',
  },
  {
    name: 'Plasmanate (plasma protein fraction (human))',
    value: 'Plasmanate (plasma protein fraction (human))',
  },
  {
    name: 'Plavix (clopidogrel bisulfate)',
    value: 'Plavix (clopidogrel bisulfate)',
  },
  {
    name: 'Plegridy (peginterferon beta-1a)',
    value: 'Plegridy (peginterferon beta-1a)',
  },
  {
    name: 'Plenvu (ascorbic acid/polyethylene glycol 3350/potassium chloride/sodium asco etc.',
    value:
      'Plenvu (ascorbic acid/polyethylene glycol 3350/potassium chloride/sodium asco etc.',
  },
  { name: 'Pletal (cilostazol)', value: 'Pletal (cilostazol)' },
  {
    name: 'Pliaglis (lidocaine/tetracaine)',
    value: 'Pliaglis (lidocaine/tetracaine)',
  },
  {
    name: 'Pneumovax 23 (pneumococcal vaccine polyvalent)',
    value: 'Pneumovax 23 (pneumococcal vaccine polyvalent)',
  },
  { name: 'Podocon-25 (podophyllin)', value: 'Podocon-25 (podophyllin)' },
  {
    name: 'Polivy (polatuzumab/vedotin-piiq)',
    value: 'Polivy (polatuzumab/vedotin-piiq)',
  },
  {
    name: 'Polocaine (mepivacaine hydrochloride)',
    value: 'Polocaine (mepivacaine hydrochloride)',
  },
  {
    name: 'Polymyxin B (polymyxin B sulfate)',
    value: 'Polymyxin B (polymyxin B sulfate)',
  },
  {
    name: 'Polytrim (polymyxin B sulfate/trimethoprim)',
    value: 'Polytrim (polymyxin B sulfate/trimethoprim)',
  },
  { name: 'Pomalyst (pomalidomide)', value: 'Pomalyst (pomalidomide)' },
  { name: 'Ponstel (mefenamic acid)', value: 'Ponstel (mefenamic acid)' },
  { name: 'Ponvory (ponesimod)', value: 'Ponvory (ponesimod)' },
  {
    name: 'Portia (ethinyl estradiol/levonorgestrel)',
    value: 'Portia (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'Portrazza (necitumumab)', value: 'Portrazza (necitumumab)' },
  {
    name: 'Potaba (aminobenzoate potassium)',
    value: 'Potaba (aminobenzoate potassium)',
  },
  {
    name: 'Potassium Chloride Injection (potassium chloride)',
    value: 'Potassium Chloride Injection (potassium chloride)',
  },
  { name: 'Potiga (ezogabine)', value: 'Potiga (ezogabine)' },
  {
    name: 'Pradaxa (dabigatran etexilate mesylate)',
    value: 'Pradaxa (dabigatran etexilate mesylate)',
  },
  { name: 'Praluent (alirocumab)', value: 'Praluent (alirocumab)' },
  {
    name: 'Pramosone Cream 1% (hydrocortisone acetate/pramoxine hydrochloride)',
    value:
      'Pramosone Cream 1% (hydrocortisone acetate/pramoxine hydrochloride)',
  },
  {
    name: 'Pramosone Cream 2.5% (hydrocortisone acetate/pramoxine hydrochloride)',
    value:
      'Pramosone Cream 2.5% (hydrocortisone acetate/pramoxine hydrochloride)',
  },
  {
    name: 'Pramosone E (hydrocortisone acetate/pramoxine hydrochloride)',
    value: 'Pramosone E (hydrocortisone acetate/pramoxine hydrochloride)',
  },
  {
    name: 'Pramosone Lotion (hydrocortisone acetate/pramoxine hydrochloride)',
    value: 'Pramosone Lotion (hydrocortisone acetate/pramoxine hydrochloride)',
  },
  {
    name: 'Pramosone Ointment (hydrocortisone acetate/pramoxine hydrochloride)',
    value:
      'Pramosone Ointment (hydrocortisone acetate/pramoxine hydrochloride)',
  },
  {
    name: 'PrandiMet (metformin hydrochloride/repaglinide)',
    value: 'PrandiMet (metformin hydrochloride/repaglinide)',
  },
  { name: 'Prandin (repaglinide)', value: 'Prandin (repaglinide)' },
  {
    name: 'Pravachol (pravastatin sodium)',
    value: 'Pravachol (pravastatin sodium)',
  },
  {
    name: 'Pravastatin Sodium (pravastatin sodium)',
    value: 'Pravastatin Sodium (pravastatin sodium)',
  },
  { name: 'Praxbind (idarucizumab)', value: 'Praxbind (idarucizumab)' },
  {
    name: 'Precedex (dexmedetomidine hydrochloride)',
    value: 'Precedex (dexmedetomidine hydrochloride)',
  },
  { name: 'Precose (acarbose)', value: 'Precose (acarbose)' },
  {
    name: 'Pred Forte (prednisolone acetate)',
    value: 'Pred Forte (prednisolone acetate)',
  },
  {
    name: 'Pred Mild (prednisolone acetate)',
    value: 'Pred Mild (prednisolone acetate)',
  },
  {
    name: 'Pred-G Ophthalmic Ointment (gentamicin/prednisolone acetate)',
    value: 'Pred-G Ophthalmic Ointment (gentamicin/prednisolone acetate)',
  },
  {
    name: 'Pred-G Ophthalmic Suspension (gentamicin/prednisolone acetate)',
    value: 'Pred-G Ophthalmic Suspension (gentamicin/prednisolone acetate)',
  },
  {
    name: 'Prednisolone Sodium Phosphate (prednisolone sodium phosphate)',
    value: 'Prednisolone Sodium Phosphate (prednisolone sodium phosphate)',
  },
  {
    name: 'Prednisolone Syrup (prednisolone)',
    value: 'Prednisolone Syrup (prednisolone)',
  },
  {
    name: 'Prednisone Tablets (prednisone)',
    value: 'Prednisone Tablets (prednisone)',
  },
  {
    name: 'Prednisone/Prednisone Intensol (prednisone)',
    value: 'Prednisone/Prednisone Intensol (prednisone)',
  },
  {
    name: 'Prefest (estradiol/norgestimate)',
    value: 'Prefest (estradiol/norgestimate)',
  },
  {
    name: 'Pregnyl (chorionic gonadotropin)',
    value: 'Pregnyl (chorionic gonadotropin)',
  },
  {
    name: 'Premarin IV (conjugated estrogens)',
    value: 'Premarin IV (conjugated estrogens)',
  },
  {
    name: 'Premarin Tablets (conjugated estrogens)',
    value: 'Premarin Tablets (conjugated estrogens)',
  },
  {
    name: 'Premarin Vaginal Cream (conjugated estrogens)',
    value: 'Premarin Vaginal Cream (conjugated estrogens)',
  },
  {
    name: 'Prempro/Premphase (conjugated estrogens/medroxyprogesterone acetate)',
    value:
      'Prempro/Premphase (conjugated estrogens/medroxyprogesterone acetate)',
  },
  {
    name: 'Prenatal Core Minerals (calcium (calcium ascorbate, calcium citrate)/calcium silicate/chromiu etc.',
    value:
      'Prenatal Core Minerals (calcium (calcium ascorbate, calcium citrate)/calcium silicate/chromiu etc.',
  },
  {
    name: 'Prenatal Vita-Antioxidant (alpha lipoic acid/biotin/cholecalciferol/choline bitartrate/coenzyme etc.',
    value:
      'Prenatal Vita-Antioxidant (alpha lipoic acid/biotin/cholecalciferol/choline bitartrate/coenzyme etc.',
  },
  {
    name: 'Prenate AM (calcium carbonate/folic acid/ginger extract/lingonberry/vitamin B6 (p etc.',
    value:
      'Prenate AM (calcium carbonate/folic acid/ginger extract/lingonberry/vitamin B6 (p etc.',
  },
  {
    name: 'Prenate Chewable (biotin/blueberry extract/boron amino acid chelate/calcium carbonate/f etc.',
    value:
      'Prenate Chewable (biotin/blueberry extract/boron amino acid chelate/calcium carbonate/f etc.',
  },
  {
    name: 'Prenate DHA (calcium formate/docosahexaenoic acid (DHA)/ferrous asparto glycinate/ etc.',
    value:
      'Prenate DHA (calcium formate/docosahexaenoic acid (DHA)/ferrous asparto glycinate/ etc.',
  },
  {
    name: 'Prenate Elite (calcium formate/copper oxide/ferrous asparto glycinate/magnesium oxid etc.',
    value:
      'Prenate Elite (calcium formate/copper oxide/ferrous asparto glycinate/magnesium oxid etc.',
  },
  {
    name: 'Prenate Essential (calcium formate/docosahexaenoic acid (DHA)/eicosapentaenoic acid (EPA etc.',
    value:
      'Prenate Essential (calcium formate/docosahexaenoic acid (DHA)/eicosapentaenoic acid (EPA etc.',
  },
  {
    name: 'Prenate Mini (blueberry extract (vaccinium spp.)/calcium carbonate/docosahexaenoic etc.',
    value:
      'Prenate Mini (blueberry extract (vaccinium spp.)/calcium carbonate/docosahexaenoic etc.',
  },
  { name: 'Prepidil (dinoprostone)', value: 'Prepidil (dinoprostone)' },
  {
    name: 'Prepopik (anhydrous citric acid/magnesium oxide/sodium picosulfate)',
    value:
      'Prepopik (anhydrous citric acid/magnesium oxide/sodium picosulfate)',
  },
  {
    name: 'Prestalia (amlodipine/perindopril arginine)',
    value: 'Prestalia (amlodipine/perindopril arginine)',
  },
  { name: 'Pretomanid (pretomanid)', value: 'Pretomanid (pretomanid)' },
  { name: 'Prevacid (lansoprazole)', value: 'Prevacid (lansoprazole)' },
  {
    name: 'Prevacid 24 Hr (lansoprazole)',
    value: 'Prevacid 24 Hr (lansoprazole)',
  },
  { name: 'Prevalite (cholestyramine)', value: 'Prevalite (cholestyramine)' },
  {
    name: 'Previfem (ethinyl estradiol/norgestimate)',
    value: 'Previfem (ethinyl estradiol/norgestimate)',
  },
  {
    name: 'Prevnar 13 (pneumococcal 13-valent conjugate vaccine (diphtheria CRM197 protein)) etc.',
    value:
      'Prevnar 13 (pneumococcal 13-valent conjugate vaccine (diphtheria CRM197 protein)) etc.',
  },
  {
    name: 'PrevPAC (amoxicillin/clarithromycin/lansoprazole)',
    value: 'PrevPAC (amoxicillin/clarithromycin/lansoprazole)',
  },
  { name: 'Prevymis (letermovir)', value: 'Prevymis (letermovir)' },
  {
    name: 'Prezcobix (cobicistat/darunavir)',
    value: 'Prezcobix (cobicistat/darunavir)',
  },
  { name: 'Prezista (darunavir)', value: 'Prezista (darunavir)' },
  { name: 'Prialt (ziconotide)', value: 'Prialt (ziconotide)' },
  { name: 'Priftin (rifapentine)', value: 'Priftin (rifapentine)' },
  {
    name: 'Prilosec Delayed Release Capsules and Oral Suspension (omeprazole); (omeprazole magnesium)',
    value:
      'Prilosec Delayed Release Capsules and Oral Suspension (omeprazole); (omeprazole magnesium)',
  },
  {
    name: 'Primaquine Phosphate (primaquine phosphate)',
    value: 'Primaquine Phosphate (primaquine phosphate)',
  },
  {
    name: 'Primatene Mist (epinephrine)',
    value: 'Primatene Mist (epinephrine)',
  },
  {
    name: 'Primaxin I.M. (cilastatin/imipenem)',
    value: 'Primaxin I.M. (cilastatin/imipenem)',
  },
  {
    name: 'Primaxin I.V. (cilastatin/imipenem)',
    value: 'Primaxin I.V. (cilastatin/imipenem)',
  },
  {
    name: 'Primlev (acetaminophen/oxycodone hydrochloride)',
    value: 'Primlev (acetaminophen/oxycodone hydrochloride)',
  },
  {
    name: 'Primsol (trimethoprim hydrochloride)',
    value: 'Primsol (trimethoprim hydrochloride)',
  },
  { name: 'Prinivil (lisinopril)', value: 'Prinivil (lisinopril)' },
  { name: 'Pristiq (desvenlafaxine)', value: 'Pristiq (desvenlafaxine)' },
  {
    name: 'Privigen (immune globulin intravenous (human))',
    value: 'Privigen (immune globulin intravenous (human))',
  },
  {
    name: 'ProAir Digihaler (albuterol sulfate)',
    value: 'ProAir Digihaler (albuterol sulfate)',
  },
  {
    name: 'ProAir HFA (albuterol sulfate)',
    value: 'ProAir HFA (albuterol sulfate)',
  },
  {
    name: 'ProAir RespiClick (albuterol sulfate)',
    value: 'ProAir RespiClick (albuterol sulfate)',
  },
  {
    name: 'ProArgi-9+ (d-ribose/grape skin extract (vitis vinifera)/L-arginine/L-citrulline/ etc.',
    value:
      'ProArgi-9+ (d-ribose/grape skin extract (vitis vinifera)/L-arginine/L-citrulline/ etc.',
  },
  { name: 'Probenecid (probenecid)', value: 'Probenecid (probenecid)' },
  {
    name: 'Probenecid and Colchicine (colchicine/probenecid)',
    value: 'Probenecid and Colchicine (colchicine/probenecid)',
  },
  { name: 'Probuphine (buprenorphine)', value: 'Probuphine (buprenorphine)' },
  {
    name: 'Procainamide Hydrochloride (procainamide hydrochloride)',
    value: 'Procainamide Hydrochloride (procainamide hydrochloride)',
  },
  { name: 'Procardia (nifedipine)', value: 'Procardia (nifedipine)' },
  { name: 'Procardia XL (nifedipine)', value: 'Procardia XL (nifedipine)' },
  {
    name: 'ProCentra (dextroamphetamine sulfate)',
    value: 'ProCentra (dextroamphetamine sulfate)',
  },
  {
    name: 'Prochlorperazine Edisylate (prochlorperazine edisylate)',
    value: 'Prochlorperazine Edisylate (prochlorperazine edisylate)',
  },
  {
    name: 'Prochlorperazine Maleate (prochlorperazine maleate)',
    value: 'Prochlorperazine Maleate (prochlorperazine maleate)',
  },
  {
    name: 'Procosa (glucosamine hydrochloride/magnesium sulphate/manganese gluconate/meri etc.',
    value:
      'Procosa (glucosamine hydrochloride/magnesium sulphate/manganese gluconate/meri etc.',
  },
  { name: 'Procrit (epoetin alfa)', value: 'Procrit (epoetin alfa)' },
  {
    name: 'Proctocort Cream (hydrocortisone)',
    value: 'Proctocort Cream (hydrocortisone)',
  },
  {
    name: 'Proctocort Suppositories (hydrocortisone acetate)',
    value: 'Proctocort Suppositories (hydrocortisone acetate)',
  },
  {
    name: 'Proctofoam-HC (hydrocortisone acetate/pramoxine hydrochloride)',
    value: 'Proctofoam-HC (hydrocortisone acetate/pramoxine hydrochloride)',
  },
  {
    name: 'Proctozone-HC (hydrocortisone)',
    value: 'Proctozone-HC (hydrocortisone)',
  },
  {
    name: 'Procysbi (cysteamine bitartrate)',
    value: 'Procysbi (cysteamine bitartrate)',
  },
  {
    name: 'Profilnine (factor IX complex)',
    value: 'Profilnine (factor IX complex)',
  },
  {
    name: 'Proflavanol C 100 (grape seed extract (vitis vinifera L.)/poly C vitamin blend (calcium, etc.',
    value:
      'Proflavanol C 100 (grape seed extract (vitis vinifera L.)/poly C vitamin blend (calcium, etc.',
  },
  { name: 'Progesterone (progesterone)', value: 'Progesterone (progesterone)' },
  { name: 'Proglycem (diazoxide)', value: 'Proglycem (diazoxide)' },
  { name: 'Prograf (tacrolimus)', value: 'Prograf (tacrolimus)' },
  {
    name: 'Prohance Injection (gadoteridol)',
    value: 'Prohance Injection (gadoteridol)',
  },
  {
    name: 'Prolastin-C (alpha1-proteinase inhibitor (human))',
    value: 'Prolastin-C (alpha1-proteinase inhibitor (human))',
  },
  { name: 'Prolensa (bromfenac sodium)', value: 'Prolensa (bromfenac sodium)' },
  { name: 'Proleukin (aldesleukin)', value: 'Proleukin (aldesleukin)' },
  { name: 'Prolia (denosumab)', value: 'Prolia (denosumab)' },
  { name: 'Promacta (eltrombopag)', value: 'Promacta (eltrombopag)' },
  {
    name: 'Promethazine DM (dextromethorphan hydrobromide/promethazine hydrochloride)',
    value:
      'Promethazine DM (dextromethorphan hydrobromide/promethazine hydrochloride)',
  },
  {
    name: 'Promethazine Hydrochloride Injection (promethazine hydrochloride)',
    value: 'Promethazine Hydrochloride Injection (promethazine hydrochloride)',
  },
  {
    name: 'Promethazine Hydrochloride Syrup (promethazine hydrochloride)',
    value: 'Promethazine Hydrochloride Syrup (promethazine hydrochloride)',
  },
  {
    name: 'Promethazine Hydrochloride Tablets (promethazine hydrochloride)',
    value: 'Promethazine Hydrochloride Tablets (promethazine hydrochloride)',
  },
  {
    name: 'Promethazine VC (phenylephrine hydrochloride/promethazine hydrochloride)',
    value:
      'Promethazine VC (phenylephrine hydrochloride/promethazine hydrochloride)',
  },
  {
    name: 'Promethazine VC with Codeine (codeine phosphate/phenylephrine hydrochloride/promethazine hydrochlor etc.',
    value:
      'Promethazine VC with Codeine (codeine phosphate/phenylephrine hydrochloride/promethazine hydrochlor etc.',
  },
  {
    name: 'Promethazine with Codeine (codeine phosphate/promethazine hydrochloride)',
    value:
      'Promethazine with Codeine (codeine phosphate/promethazine hydrochloride)',
  },
  {
    name: 'Promethegan (promethazine hydrochloride)',
    value: 'Promethegan (promethazine hydrochloride)',
  },
  { name: 'Prometrium (progesterone)', value: 'Prometrium (progesterone)' },
  {
    name: 'Propafenone Hydrochloride (propafenone hydrochloride)',
    value: 'Propafenone Hydrochloride (propafenone hydrochloride)',
  },
  { name: 'Propecia (finasteride)', value: 'Propecia (finasteride)' },
  {
    name: 'Propranolol Hydrochloride and Hydrochlorothiazide (hydrochlorothiazide/propranolol hydrochloride)',
    value:
      'Propranolol Hydrochloride and Hydrochlorothiazide (hydrochlorothiazide/propranolol hydrochloride)',
  },
  {
    name: 'Propranolol Hydrochloride Injection (propranolol hydrochloride)',
    value: 'Propranolol Hydrochloride Injection (propranolol hydrochloride)',
  },
  {
    name: 'Propranolol Hydrochloride Oral Solution (propranolol hydrochloride)',
    value:
      'Propranolol Hydrochloride Oral Solution (propranolol hydrochloride)',
  },
  {
    name: 'Propranolol Hydrochloride Tablets (propranolol hydrochloride)',
    value: 'Propranolol Hydrochloride Tablets (propranolol hydrochloride)',
  },
  {
    name: 'Propylthiouracil (propylthiouracil)',
    value: 'Propylthiouracil (propylthiouracil)',
  },
  {
    name: 'ProQuad (measles, mumps, rubella and varicella virus vaccine live)',
    value: 'ProQuad (measles, mumps, rubella and varicella virus vaccine live)',
  },
  { name: 'Proscar (finasteride)', value: 'Proscar (finasteride)' },
  { name: 'Prostin E2 (dinoprostone)', value: 'Prostin E2 (dinoprostone)' },
  {
    name: 'Prostin VR Pediatric (alprostadil)',
    value: 'Prostin VR Pediatric (alprostadil)',
  },
  {
    name: 'Protamine Sulfate (protamine sulfate)',
    value: 'Protamine Sulfate (protamine sulfate)',
  },
  {
    name: 'Protonix Delayed Release Oral Suspension and Tablets (pantoprazole sodium)',
    value:
      'Protonix Delayed Release Oral Suspension and Tablets (pantoprazole sodium)',
  },
  {
    name: 'Protonix I.V. (pantoprazole sodium)',
    value: 'Protonix I.V. (pantoprazole sodium)',
  },
  {
    name: 'Protopam Chloride (pralidoxime chloride)',
    value: 'Protopam Chloride (pralidoxime chloride)',
  },
  { name: 'Protopic (tacrolimus)', value: 'Protopic (tacrolimus)' },
  { name: 'Provayblue (methylene blue)', value: 'Provayblue (methylene blue)' },
  { name: 'Provenge (sipuleucel-T)', value: 'Provenge (sipuleucel-T)' },
  {
    name: 'Proventil HFA (albuterol sulfate)',
    value: 'Proventil HFA (albuterol sulfate)',
  },
  {
    name: 'Provera (medroxyprogesterone acetate)',
    value: 'Provera (medroxyprogesterone acetate)',
  },
  { name: 'Provigil (modafinil)', value: 'Provigil (modafinil)' },
  {
    name: 'Provocholine (methacholine chloride)',
    value: 'Provocholine (methacholine chloride)',
  },
  {
    name: 'Prozac (fluoxetine hydrochloride)',
    value: 'Prozac (fluoxetine hydrochloride)',
  },
  {
    name: 'Prozac Weekly (fluoxetine hydrochloride)',
    value: 'Prozac Weekly (fluoxetine hydrochloride)',
  },
  {
    name: 'Pulmicort Flexhaler (budesonide)',
    value: 'Pulmicort Flexhaler (budesonide)',
  },
  {
    name: 'Pulmicort Respules (budesonide)',
    value: 'Pulmicort Respules (budesonide)',
  },
  { name: 'Pulmozyme (dornase alfa)', value: 'Pulmozyme (dornase alfa)' },
  {
    name: 'Pure Gold (dietary supplement)',
    value: 'Pure Gold (dietary supplement)',
  },
  {
    name: 'Pure NMN (dietary supplement)',
    value: 'Pure NMN (dietary supplement)',
  },
  { name: 'Purinethol (mercaptopurine)', value: 'Purinethol (mercaptopurine)' },
  { name: 'Purixan (mercaptopurine)', value: 'Purixan (mercaptopurine)' },
  {
    name: 'PurXcel (dietary supplement)',
    value: 'PurXcel (dietary supplement)',
  },
  {
    name: 'Pylera (bismuth subcitrate potassium/metronidazole/tetracycline hydrochloride etc.',
    value:
      'Pylera (bismuth subcitrate potassium/metronidazole/tetracycline hydrochloride etc.',
  },
  { name: 'Pyrazinamide (pyrazinamide)', value: 'Pyrazinamide (pyrazinamide)' },
  {
    name: 'Pyridium (phenazopyridine hydrochloride)',
    value: 'Pyridium (phenazopyridine hydrochloride)',
  },
  {
    name: 'Pyridoxine Hydrochloride (pyridoxine hydrochloride)',
    value: 'Pyridoxine Hydrochloride (pyridoxine hydrochloride)',
  },
  { name: 'Qbrelis (lisinopril)', value: 'Qbrelis (lisinopril)' },
  {
    name: 'Qelbree Capsules (viloxazine hydrochloride)',
    value: 'Qelbree Capsules (viloxazine hydrochloride)',
  },
  { name: 'Qmiiz ODT (meloxicam)', value: 'Qmiiz ODT (meloxicam)' },
  {
    name: 'Qnasl (beclomethasone dipropionate)',
    value: 'Qnasl (beclomethasone dipropionate)',
  },
  {
    name: 'Qsymia (phentermine/topiramate)',
    value: 'Qsymia (phentermine/topiramate)',
  },
  {
    name: 'Qtern (dapagliflozin/saxagliptin)',
    value: 'Qtern (dapagliflozin/saxagliptin)',
  },
  {
    name: 'Quadracel (diphtheria and tetanus toxoids and acellular pertussis adsorbed and i etc.',
    value:
      'Quadracel (diphtheria and tetanus toxoids and acellular pertussis adsorbed and i etc.',
  },
  {
    name: 'Quadramet (samarium Sm 153 lexidronam)',
    value: 'Quadramet (samarium Sm 153 lexidronam)',
  },
  { name: 'Qualaquin (quinine sulfate)', value: 'Qualaquin (quinine sulfate)' },
  {
    name: 'Quartette (ethinyl estradiol/levonorgestrel)',
    value: 'Quartette (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'Quasense (ethinyl estradiol/levonorgestrel)',
    value: 'Quasense (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'Qudexy XR (topiramate)', value: 'Qudexy XR (topiramate)' },
  {
    name: 'Quelicin (succinylcholine chloride)',
    value: 'Quelicin (succinylcholine chloride)',
  },
  {
    name: 'QuilliChew ER (methylphenidate hydrochloride)',
    value: 'QuilliChew ER (methylphenidate hydrochloride)',
  },
  {
    name: 'Quillivant XR (methylphenidate hydrochloride)',
    value: 'Quillivant XR (methylphenidate hydrochloride)',
  },
  {
    name: 'Quinidine Gluconate Extended-Release Tablets (quinidine gluconate)',
    value: 'Quinidine Gluconate Extended-Release Tablets (quinidine gluconate)',
  },
  {
    name: 'Quinidine Gluconate Injection (quinidine gluconate)',
    value: 'Quinidine Gluconate Injection (quinidine gluconate)',
  },
  {
    name: 'Quinidine Sulfate Extended-Release Tablets (quinidine sulfate)',
    value: 'Quinidine Sulfate Extended-Release Tablets (quinidine sulfate)',
  },
  {
    name: 'Quinidine Sulfate Tablets (quinidine sulfate)',
    value: 'Quinidine Sulfate Tablets (quinidine sulfate)',
  },
  {
    name: 'Quinja (iodoquinol/aloe polysaccharide)',
    value: 'Quinja (iodoquinol/aloe polysaccharide)',
  },
  { name: 'Qulipta (atogepant)', value: 'Qulipta (atogepant)' },
  { name: 'Qutenza (capsaicin)', value: 'Qutenza (capsaicin)' },
  {
    name: 'Qvar (beclomethasone dipropionate)',
    value: 'Qvar (beclomethasone dipropionate)',
  },
  { name: 'RabAvert (rabies vaccine)', value: 'RabAvert (rabies vaccine)' },
  { name: 'Radicava (edaravone)', value: 'Radicava (edaravone)' },
  {
    name: 'Ragwitek (short ragweed pollen allergen extract)',
    value: 'Ragwitek (short ragweed pollen allergen extract)',
  },
  { name: 'Ranexa (ranolazine)', value: 'Ranexa (ranolazine)' },
  {
    name: 'Ranitidine Hydrochloride Capsules and Tablets (ranitidine hydrochloride)',
    value:
      'Ranitidine Hydrochloride Capsules and Tablets (ranitidine hydrochloride)',
  },
  {
    name: 'Ranitidine Hydrochloride Syrup (ranitidine hydrochloride)',
    value: 'Ranitidine Hydrochloride Syrup (ranitidine hydrochloride)',
  },
  {
    name: 'Ranitidine Tablets (ranitidine hydrochloride)',
    value: 'Ranitidine Tablets (ranitidine hydrochloride)',
  },
  { name: 'Rapaflo (silodosin)', value: 'Rapaflo (silodosin)' },
  { name: 'Rapamune (sirolimus)', value: 'Rapamune (sirolimus)' },
  { name: 'Rapivab (peramivir)', value: 'Rapivab (peramivir)' },
  {
    name: 'Raplixa (fibrin sealant (human))',
    value: 'Raplixa (fibrin sealant (human))',
  },
  { name: 'Rasuvo (methotrexate)', value: 'Rasuvo (methotrexate)' },
  {
    name: 'Ravicti (glycerol phenylbutyrate)',
    value: 'Ravicti (glycerol phenylbutyrate)',
  },
  { name: 'Rayaldee (calcifediol)', value: 'Rayaldee (calcifediol)' },
  { name: 'Rayos (prednisone)', value: 'Rayos (prednisone)' },
  {
    name: 'Razadyne (galantamine hydrobromide)',
    value: 'Razadyne (galantamine hydrobromide)',
  },
  { name: 'Rebetol (ribavirin)', value: 'Rebetol (ribavirin)' },
  { name: 'Rebif (interferon beta-1a)', value: 'Rebif (interferon beta-1a)' },
  {
    name: 'Rebinyn (Coagulation factor IX (recombinant) glycoPEGylated)',
    value: 'Rebinyn (Coagulation factor IX (recombinant) glycoPEGylated)',
  },
  { name: 'Reclast (zoledronic acid)', value: 'Reclast (zoledronic acid)' },
  {
    name: 'Reclipsen (desogestrel/ethinyl estradiol)',
    value: 'Reclipsen (desogestrel/ethinyl estradiol)',
  },
  {
    name: 'Recombinate (antihemophilic factor (recombinant))',
    value: 'Recombinate (antihemophilic factor (recombinant))',
  },
  {
    name: 'Recombivax HB (hepatitis B vaccine (recombinant))',
    value: 'Recombivax HB (hepatitis B vaccine (recombinant))',
  },
  {
    name: 'Recothrom (thrombin (recombinant))',
    value: 'Recothrom (thrombin (recombinant))',
  },
  { name: 'Rectiv (nitroglycerin)', value: 'Rectiv (nitroglycerin)' },
  {
    name: "Reese's Pinworm Medicine (pyrantel pamoate)",
    value: "Reese's Pinworm Medicine (pyrantel pamoate)",
  },
  {
    name: 'Refresh Celluvisc (carboxymethylcellulose sodium)',
    value: 'Refresh Celluvisc (carboxymethylcellulose sodium)',
  },
  {
    name: 'Refresh Classic (polyvinyl alcohol/povidone)',
    value: 'Refresh Classic (polyvinyl alcohol/povidone)',
  },
  {
    name: 'Refresh Liquigel (carboxymethylcellulose sodium)',
    value: 'Refresh Liquigel (carboxymethylcellulose sodium)',
  },
  {
    name: 'Regimex (benzphetamine hydrochloride)',
    value: 'Regimex (benzphetamine hydrochloride)',
  },
  {
    name: 'Reglan Tablets (metoclopramide)',
    value: 'Reglan Tablets (metoclopramide)',
  },
  {
    name: 'Regonol (pyridostigmine bromide)',
    value: 'Regonol (pyridostigmine bromide)',
  },
  { name: 'Regranex (becaplermin)', value: 'Regranex (becaplermin)' },
  { name: 'Relenza (zanamivir)', value: 'Relenza (zanamivir)' },
  {
    name: 'Relistor (methylnaltrexone bromide)',
    value: 'Relistor (methylnaltrexone bromide)',
  },
  {
    name: 'Relpax (eletriptan hydrobromide)',
    value: 'Relpax (eletriptan hydrobromide)',
  },
  { name: 'Remeron (mirtazapine)', value: 'Remeron (mirtazapine)' },
  { name: 'RemeronSolTab (mirtazapine)', value: 'RemeronSolTab (mirtazapine)' },
  { name: 'Remicade (infliximab)', value: 'Remicade (infliximab)' },
  { name: 'Remodulin (treprostinil)', value: 'Remodulin (treprostinil)' },
  {
    name: 'Renagel (sevelamer hydrochloride)',
    value: 'Renagel (sevelamer hydrochloride)',
  },
  { name: 'Renflexis (infliximab-abda)', value: 'Renflexis (infliximab-abda)' },
  { name: 'Renova 0.02% (tretinoin)', value: 'Renova 0.02% (tretinoin)' },
  {
    name: 'Renvela (sevelamer carbonate)',
    value: 'Renvela (sevelamer carbonate)',
  },
  { name: 'ReoPro (abciximab)', value: 'ReoPro (abciximab)' },
  { name: 'Repatha (evolocumab)', value: 'Repatha (evolocumab)' },
  {
    name: 'Reprexain (hydrocodone bitartrate/ibuprofen)',
    value: 'Reprexain (hydrocodone bitartrate/ibuprofen)',
  },
  { name: 'Requip (ropinirole)', value: 'Requip (ropinirole)' },
  { name: 'Requip XL (ropinirole)', value: 'Requip XL (ropinirole)' },
  {
    name: 'Rescriptor (delavirdine mesylate)',
    value: 'Rescriptor (delavirdine mesylate)',
  },
  { name: 'Reserpine (reserpine)', value: 'Reserpine (reserpine)' },
  { name: 'Restasis (cyclosporine)', value: 'Restasis (cyclosporine)' },
  {
    name: 'Restasis MultiDose (cyclosporine)',
    value: 'Restasis MultiDose (cyclosporine)',
  },
  { name: 'Restoril (temazepam)', value: 'Restoril (temazepam)' },
  {
    name: 'Retacrit (epoetin alfa-epbx)',
    value: 'Retacrit (epoetin alfa-epbx)',
  },
  { name: 'Retavase (reteplase)', value: 'Retavase (reteplase)' },
  {
    name: 'Retin-A Cream and Gel (tretinoin)',
    value: 'Retin-A Cream and Gel (tretinoin)',
  },
  {
    name: 'Retin-A Micro Gel (tretinoin)',
    value: 'Retin-A Micro Gel (tretinoin)',
  },
  {
    name: 'Retisert (fluocinolone acetonide)',
    value: 'Retisert (fluocinolone acetonide)',
  },
  { name: 'Retrovir (zidovudine)', value: 'Retrovir (zidovudine)' },
  { name: 'Revatio (sildenafil)', value: 'Revatio (sildenafil)' },
  {
    name: 'Revia (naltrexone hydrochloride)',
    value: 'Revia (naltrexone hydrochloride)',
  },
  { name: 'Revlimid (lenalidomide)', value: 'Revlimid (lenalidomide)' },
  { name: 'Rexulti (brexpiprazole)', value: 'Rexulti (brexpiprazole)' },
  { name: 'Reyataz (atazanavir)', value: 'Reyataz (atazanavir)' },
  { name: 'Reyvow (lasmiditan tablet)', value: 'Reyvow (lasmiditan tablet)' },
  {
    name: 'Rezira (hydrocodone bitartrate/pseudoephedrine hydrochloride)',
    value: 'Rezira (hydrocodone bitartrate/pseudoephedrine hydrochloride)',
  },
  { name: 'Rheumatrex (methotrexate)', value: 'Rheumatrex (methotrexate)' },
  {
    name: 'Rhofade (oxymetazoline hydrochloride)',
    value: 'Rhofade (oxymetazoline hydrochloride)',
  },
  {
    name: 'Rhophylac (rho (D) immune globulin intravenous (human))',
    value: 'Rhophylac (rho (D) immune globulin intravenous (human))',
  },
  {
    name: 'RiaSTAP (fibrinogen concentrate (human))',
    value: 'RiaSTAP (fibrinogen concentrate (human))',
  },
  {
    name: 'Ribasphere Capsules (ribavirin)',
    value: 'Ribasphere Capsules (ribavirin)',
  },
  {
    name: 'Ribasphere Tablets (ribavirin)',
    value: 'Ribasphere Tablets (ribavirin)',
  },
  { name: 'Ridaura (auranofin)', value: 'Ridaura (auranofin)' },
  { name: 'Rifadin (rifampin)', value: 'Rifadin (rifampin)' },
  {
    name: 'Rifamate (isoniazid/rifampin)',
    value: 'Rifamate (isoniazid/rifampin)',
  },
  {
    name: 'Rifater (isoniazid/pyrazinamide/rifampin)',
    value: 'Rifater (isoniazid/pyrazinamide/rifampin)',
  },
  { name: 'Rilutek (riluzole)', value: 'Rilutek (riluzole)' },
  {
    name: 'Rimso-50 (dimethyl sulfoxide)',
    value: 'Rimso-50 (dimethyl sulfoxide)',
  },
  { name: 'Rinvoq (upadacitinib)', value: 'Rinvoq (upadacitinib)' },
  {
    name: 'Riomet (metformin hydrochloride)',
    value: 'Riomet (metformin hydrochloride)',
  },
  { name: 'Risperdal (risperidone)', value: 'Risperdal (risperidone)' },
  {
    name: 'Risperdal Consta (risperidone)',
    value: 'Risperdal Consta (risperidone)',
  },
  { name: 'Risperidone (risperidone)', value: 'Risperidone (risperidone)' },
  {
    name: 'Ritalin LA (methylphenidate hydrochloride)',
    value: 'Ritalin LA (methylphenidate hydrochloride)',
  },
  {
    name: 'Ritalin/Ritalin SR (methylphenidate hydrochloride)',
    value: 'Ritalin/Ritalin SR (methylphenidate hydrochloride)',
  },
  { name: 'Rituxan (rituximab)', value: 'Rituxan (rituximab)' },
  {
    name: 'Rituxan Hycela (hyaluronidase human/rituximab)',
    value: 'Rituxan Hycela (hyaluronidase human/rituximab)',
  },
  {
    name: 'Rivelsa (ethinyl estradiol/levonorgestrel)',
    value: 'Rivelsa (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'Rixubis (coagulation factor IX (recombinant))',
    value: 'Rixubis (coagulation factor IX (recombinant))',
  },
  {
    name: 'Robaxin/Robaxin-750 Tablets (methocarbamol)',
    value: 'Robaxin/Robaxin-750 Tablets (methocarbamol)',
  },
  {
    name: 'Robinul Injection (glycopyrrolate)',
    value: 'Robinul Injection (glycopyrrolate)',
  },
  {
    name: 'Robinul Tablets and Forte Tablets (glycopyrrolate)',
    value: 'Robinul Tablets and Forte Tablets (glycopyrrolate)',
  },
  {
    name: 'Robitussin Maximum Strength Cough+Chest Congestion DM (dextromethorphan hydrobromide/guaifenesin)',
    value:
      'Robitussin Maximum Strength Cough+Chest Congestion DM (dextromethorphan hydrobromide/guaifenesin)',
  },
  {
    name: 'Robitussin Peak Cold Cough + Chest Congestion DM (dextromethorphan hydrobromide/guaifenesin)',
    value:
      'Robitussin Peak Cold Cough + Chest Congestion DM (dextromethorphan hydrobromide/guaifenesin)',
  },
  {
    name: 'Robitussin Peak Cold Multi-Symptom Cold (dextromethorphan hydrobromide/guaifenesin/phenylephrine hydrochloride etc.',
    value:
      'Robitussin Peak Cold Multi-Symptom Cold (dextromethorphan hydrobromide/guaifenesin/phenylephrine hydrochloride etc.',
  },
  { name: 'Rocaltrol (calcitriol)', value: 'Rocaltrol (calcitriol)' },
  {
    name: 'Rocuronium Bromide (rocuronium bromide)',
    value: 'Rocuronium Bromide (rocuronium bromide)',
  },
  {
    name: 'Ropivacaine Hydrochloride (ropivacaine hydrochloride)',
    value: 'Ropivacaine Hydrochloride (ropivacaine hydrochloride)',
  },
  {
    name: 'Rosadan Cream (metronidazole)',
    value: 'Rosadan Cream (metronidazole)',
  },
  { name: 'Rosadan Gel (metronidazole)', value: 'Rosadan Gel (metronidazole)' },
  {
    name: 'Rotarix (rotavirus vaccine, live, oral)',
    value: 'Rotarix (rotavirus vaccine, live, oral)',
  },
  {
    name: 'RotaTeq (rotavirus vaccine, live, oral, pentavalent)',
    value: 'RotaTeq (rotavirus vaccine, live, oral, pentavalent)',
  },
  { name: 'Roweepra (levetiracetam)', value: 'Roweepra (levetiracetam)' },
  { name: 'Roweepra XR (levetiracetam)', value: 'Roweepra XR (levetiracetam)' },
  {
    name: 'Roxicet (acetaminophen/oxycodone)',
    value: 'Roxicet (acetaminophen/oxycodone)',
  },
  {
    name: 'Roxicodone (oxycodone hydrochloride)',
    value: 'Roxicodone (oxycodone hydrochloride)',
  },
  { name: 'Rozerem (ramelteon)', value: 'Rozerem (ramelteon)' },
  { name: 'Rozlytrek (entrectinib)', value: 'Rozlytrek (entrectinib)' },
  {
    name: 'RSHO Gold (dietary supplement)',
    value: 'RSHO Gold (dietary supplement)',
  },
  { name: 'Rubraca (rucaparib)', value: 'Rubraca (rucaparib)' },
  {
    name: 'Ruconest (C1 esterase inhibitor (recombinant))',
    value: 'Ruconest (C1 esterase inhibitor (recombinant))',
  },
  { name: 'Ruzurgi (amifampridine)', value: 'Ruzurgi (amifampridine)' },
  {
    name: 'Ryanodex (dantrolene sodium)',
    value: 'Ryanodex (dantrolene sodium)',
  },
  { name: 'Rybelsus (semaglutide)', value: 'Rybelsus (semaglutide)' },
  { name: 'Rydapt (midostaurin)', value: 'Rydapt (midostaurin)' },
  { name: 'Rytary (carbidopa/levodopa)', value: 'Rytary (carbidopa/levodopa)' },
  {
    name: 'Rythmol (propafenone hydrochloride)',
    value: 'Rythmol (propafenone hydrochloride)',
  },
  {
    name: 'Rythmol SR (propafenone hydrochloride)',
    value: 'Rythmol SR (propafenone hydrochloride)',
  },
  {
    name: 'Sabril for Oral Solution (vigabatrin)',
    value: 'Sabril for Oral Solution (vigabatrin)',
  },
  { name: 'Sabril Tablets (vigabatrin)', value: 'Sabril Tablets (vigabatrin)' },
  {
    name: 'Safyral (drospirenone/ethinyl estradiol/levomefolate calcium)',
    value: 'Safyral (drospirenone/ethinyl estradiol/levomefolate calcium)',
  },
  {
    name: 'Saizen (somatropin (rDNA origin))',
    value: 'Saizen (somatropin (rDNA origin))',
  },
  {
    name: 'Salagen (pilocarpine hydrochloride)',
    value: 'Salagen (pilocarpine hydrochloride)',
  },
  { name: 'Salex (salicylic acid)', value: 'Salex (salicylic acid)' },
  {
    name: 'Salonpas Deep Relieving Gel (camphor/menthol/methyl salicylate)',
    value: 'Salonpas Deep Relieving Gel (camphor/menthol/methyl salicylate)',
  },
  { name: 'Salsalate (salsalate)', value: 'Salsalate (salsalate)' },
  { name: 'Salvax (salicylic acid)', value: 'Salvax (salicylic acid)' },
  { name: 'Samsca (tolvaptan)', value: 'Samsca (tolvaptan)' },
  { name: 'Sancuso (granisetron)', value: 'Sancuso (granisetron)' },
  { name: 'Sandimmune (cyclosporine)', value: 'Sandimmune (cyclosporine)' },
  {
    name: 'Sandostatin (octreotide acetate)',
    value: 'Sandostatin (octreotide acetate)',
  },
  {
    name: 'Sandostatin LAR (octreotide acetate)',
    value: 'Sandostatin LAR (octreotide acetate)',
  },
  { name: 'Saphris (asenapine)', value: 'Saphris (asenapine)' },
  {
    name: 'Sarafem Tablets (fluoxetine hydrochloride)',
    value: 'Sarafem Tablets (fluoxetine hydrochloride)',
  },
  { name: 'Savaysa (edoxaban)', value: 'Savaysa (edoxaban)' },
  {
    name: 'Savella (milnacipran hydrochloride)',
    value: 'Savella (milnacipran hydrochloride)',
  },
  { name: 'Saxenda (liraglutide)', value: 'Saxenda (liraglutide)' },
  { name: 'Sclerosol (sterile talc)', value: 'Sclerosol (sterile talc)' },
  { name: 'Scopolamine (scopolamine)', value: 'Scopolamine (scopolamine)' },
  {
    name: 'Scopolamine Hydrobromide (scopolamine hydrobromide)',
    value: 'Scopolamine Hydrobromide (scopolamine hydrobromide)',
  },
  {
    name: 'Seasonique (ethinyl estradiol/levonorgestrel)',
    value: 'Seasonique (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'Seconal Sodium (secobarbital sodium)',
    value: 'Seconal Sodium (secobarbital sodium)',
  },
  {
    name: 'Seebri Neohaler (glycopyrrolate)',
    value: 'Seebri Neohaler (glycopyrrolate)',
  },
  {
    name: 'SEGLUROMET (ertugliflozin/metformin hydrochloride)',
    value: 'SEGLUROMET (ertugliflozin/metformin hydrochloride)',
  },
  {
    name: 'Select-OB (folate/iron/magnesium/niacin/riboflavin/thiamin/vitamin a/vitaminb6/v etc.',
    value:
      'Select-OB (folate/iron/magnesium/niacin/riboflavin/thiamin/vitamin a/vitaminb6/v etc.',
  },
  {
    name: 'Select-OB+DHA (algal oil blend/magnesium oxide/polysaccharide iron complex/vitamin A etc.',
    value:
      'Select-OB+DHA (algal oil blend/magnesium oxide/polysaccharide iron complex/vitamin A etc.',
  },
  {
    name: 'Selegiline Hydrochloride (selegiline hydrochloride)',
    value: 'Selegiline Hydrochloride (selegiline hydrochloride)',
  },
  {
    name: 'Selenium Sulfide Lotion (selenium sulfide)',
    value: 'Selenium Sulfide Lotion (selenium sulfide)',
  },
  {
    name: 'Selenium Sulfide Shampoo (selenium sulfide)',
    value: 'Selenium Sulfide Shampoo (selenium sulfide)',
  },
  { name: 'Selzentry (maraviroc)', value: 'Selzentry (maraviroc)' },
  {
    name: 'Semprex-D (acrivastine/pseudoephedrine hydrochloride)',
    value: 'Semprex-D (acrivastine/pseudoephedrine hydrochloride)',
  },
  { name: 'Senokot (sennosides)', value: 'Senokot (sennosides)' },
  { name: 'SenokotXTRA (sennosides)', value: 'SenokotXTRA (sennosides)' },
  { name: 'Sensipar (cinacalcet)', value: 'Sensipar (cinacalcet)' },
  {
    name: 'Sensorcaine-MPF Spinal (bupivacaine hydrochloride)',
    value: 'Sensorcaine-MPF Spinal (bupivacaine hydrochloride)',
  },
  {
    name: 'Sensorcaine/Sensorcaine with Epinephrine/Sensorcaine-MPF/Sensorcaine-MPF with Epinephrine (bupivacaine hydrochloride); (bupivacaine hydrochloride/epinephrine bi etc.',
    value:
      'Sensorcaine/Sensorcaine with Epinephrine/Sensorcaine-MPF/Sensorcaine-MPF with Epinephrine (bupivacaine hydrochloride); (bupivacaine hydrochloride/epinephrine bi etc.',
  },
  {
    name: 'Serevent Diskus (salmeterol xinafoate)',
    value: 'Serevent Diskus (salmeterol xinafoate)',
  },
  {
    name: 'Sernivo (betamethasone dipropionate)',
    value: 'Sernivo (betamethasone dipropionate)',
  },
  {
    name: 'Seroquel (quetiapine fumarate)',
    value: 'Seroquel (quetiapine fumarate)',
  },
  {
    name: 'Seroquel XR (quetiapine fumarate)',
    value: 'Seroquel XR (quetiapine fumarate)',
  },
  {
    name: 'Serostim (somatropin (rDNA origin))',
    value: 'Serostim (somatropin (rDNA origin))',
  },
  {
    name: 'Sevelamer Hydrochloride (sevelamer hydrochloride)',
    value: 'Sevelamer Hydrochloride (sevelamer hydrochloride)',
  },
  { name: 'SF Rowasa (mesalamine)', value: 'SF Rowasa (mesalamine)' },
  {
    name: 'Shingrix (zoster vaccine recombinant adjuvanted)',
    value: 'Shingrix (zoster vaccine recombinant adjuvanted)',
  },
  { name: 'Signifor (pasireotide)', value: 'Signifor (pasireotide)' },
  { name: 'Signifor LAR (pasireotide)', value: 'Signifor LAR (pasireotide)' },
  { name: 'Silenor (doxepin)', value: 'Silenor (doxepin)' },
  { name: 'Siliq (brodalumab)', value: 'Siliq (brodalumab)' },
  {
    name: 'Silvadene (silver sulfadiazine)',
    value: 'Silvadene (silver sulfadiazine)',
  },
  {
    name: 'Simbrinza (brimonidine tartrate/brinzolamide)',
    value: 'Simbrinza (brimonidine tartrate/brinzolamide)',
  },
  { name: 'Simcor (niacin/simvastatin)', value: 'Simcor (niacin/simvastatin)' },
  {
    name: 'Simpesse (ethinyl estradiol/levonorgestrel)',
    value: 'Simpesse (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'Simponi (golimumab)', value: 'Simponi (golimumab)' },
  { name: 'Simponi Aria (golimumab)', value: 'Simponi Aria (golimumab)' },
  { name: 'Simulect (basiliximab)', value: 'Simulect (basiliximab)' },
  {
    name: 'Sinemet (carbidopa/levodopa)',
    value: 'Sinemet (carbidopa/levodopa)',
  },
  {
    name: 'Sinemet CR (carbidopa/levodopa)',
    value: 'Sinemet CR (carbidopa/levodopa)',
  },
  {
    name: 'Singulair (montelukast sodium)',
    value: 'Singulair (montelukast sodium)',
  },
  { name: 'Sirturo (bedaquiline)', value: 'Sirturo (bedaquiline)' },
  { name: 'Sitavig (acyclovir)', value: 'Sitavig (acyclovir)' },
  {
    name: 'Sivextro (tedizolid phosphate)',
    value: 'Sivextro (tedizolid phosphate)',
  },
  { name: 'Skelaxin (metaxalone)', value: 'Skelaxin (metaxalone)' },
  { name: 'Sklice (ivermectin)', value: 'Sklice (ivermectin)' },
  { name: 'Skyla (levonorgestrel)', value: 'Skyla (levonorgestrel)' },
  { name: 'Skyrizi (risankizumab-rzaa)', value: 'Skyrizi (risankizumab-rzaa)' },
  { name: 'Slynd (drospirenone)', value: 'Slynd (drospirenone)' },
  {
    name: 'Sodium Chloride (sodium chloride)',
    value: 'Sodium Chloride (sodium chloride)',
  },
  {
    name: 'Sodium Sulfacetamide, Sulfur (sodium sulfacetamide/sulfur)',
    value: 'Sodium Sulfacetamide, Sulfur (sodium sulfacetamide/sulfur)',
  },
  {
    name: 'Soliqua 100/33 (insulin glargine/lixisenatide)',
    value: 'Soliqua 100/33 (insulin glargine/lixisenatide)',
  },
  { name: 'Soliris (eculizumab)', value: 'Soliris (eculizumab)' },
  {
    name: 'Solodyn (minocycline hydrochloride)',
    value: 'Solodyn (minocycline hydrochloride)',
  },
  {
    name: 'Soltamox (tamoxifen citrate)',
    value: 'Soltamox (tamoxifen citrate)',
  },
  {
    name: 'Solu-Cortef (hydrocortisone sodium succinate)',
    value: 'Solu-Cortef (hydrocortisone sodium succinate)',
  },
  {
    name: 'Solu-Medrol (methylprednisolone sodium succinate)',
    value: 'Solu-Medrol (methylprednisolone sodium succinate)',
  },
  { name: 'Soma (carisoprodol)', value: 'Soma (carisoprodol)' },
  {
    name: 'Somatuline Depot (lanreotide)',
    value: 'Somatuline Depot (lanreotide)',
  },
  { name: 'Somavert (pegvisomant)', value: 'Somavert (pegvisomant)' },
  { name: 'Sonata (zaleplon)', value: 'Sonata (zaleplon)' },
  { name: 'Soolantra (ivermectin)', value: 'Soolantra (ivermectin)' },
  { name: 'Soriatane (acitretin)', value: 'Soriatane (acitretin)' },
  { name: 'Sorilux (calcipotriene)', value: 'Sorilux (calcipotriene)' },
  {
    name: 'Sorine (sotalol hydrochloride)',
    value: 'Sorine (sotalol hydrochloride)',
  },
  {
    name: 'Sotalol Hydrochloride Injection (sotalol hydrochloride)',
    value: 'Sotalol Hydrochloride Injection (sotalol hydrochloride)',
  },
  {
    name: 'Sotradecol (sodium tetradecyl sulfate)',
    value: 'Sotradecol (sodium tetradecyl sulfate)',
  },
  {
    name: 'Sotylize (sotalol hydrochloride)',
    value: 'Sotylize (sotalol hydrochloride)',
  },
  { name: 'Sovaldi (sofosbuvir)', value: 'Sovaldi (sofosbuvir)' },
  {
    name: 'Spectracef (cefditoren pivoxil)',
    value: 'Spectracef (cefditoren pivoxil)',
  },
  { name: 'SPIKEVAX (covid-19 vaccine)', value: 'SPIKEVAX (covid-19 vaccine)' },
  { name: 'Spinraza (nusinersen)', value: 'Spinraza (nusinersen)' },
  {
    name: 'Spiriva (tiotropium bromide)',
    value: 'Spiriva (tiotropium bromide)',
  },
  {
    name: 'Spiriva Respimat (tiotropium bromide)',
    value: 'Spiriva Respimat (tiotropium bromide)',
  },
  {
    name: 'Sporanox Capsules (itraconazole)',
    value: 'Sporanox Capsules (itraconazole)',
  },
  {
    name: 'Sporanox Oral Solution (itraconazole)',
    value: 'Sporanox Oral Solution (itraconazole)',
  },
  {
    name: 'Spravato (esketamine hydrochloride)',
    value: 'Spravato (esketamine hydrochloride)',
  },
  {
    name: 'Sprintec (ethinyl estradiol/norgestimate)',
    value: 'Sprintec (ethinyl estradiol/norgestimate)',
  },
  { name: 'Spritam (levetiracetam)', value: 'Spritam (levetiracetam)' },
  { name: 'Sprycel (dasatinib)', value: 'Sprycel (dasatinib)' },
  {
    name: 'Sronyx (ethinyl estradiol/levonorgestrel)',
    value: 'Sronyx (ethinyl estradiol/levonorgestrel)',
  },
  { name: 'SSD (silver sulfadiazine)', value: 'SSD (silver sulfadiazine)' },
  { name: 'SSKI (potassium iodide)', value: 'SSKI (potassium iodide)' },
  {
    name: 'Stalevo (carbidopa/entacapone/levodopa)',
    value: 'Stalevo (carbidopa/entacapone/levodopa)',
  },
  {
    name: 'Stamaril (yellow fever vaccine)',
    value: 'Stamaril (yellow fever vaccine)',
  },
  { name: 'Starlix (nateglinide)', value: 'Starlix (nateglinide)' },
  {
    name: 'Staxyn (vardenafil hydrochloride)',
    value: 'Staxyn (vardenafil hydrochloride)',
  },
  { name: 'Steglatro (ertugliflozin)', value: 'Steglatro (ertugliflozin)' },
  {
    name: 'Steglujan (ertugliflozin/sitagliptin)',
    value: 'Steglujan (ertugliflozin/sitagliptin)',
  },
  { name: 'Stelara (ustekinumab)', value: 'Stelara (ustekinumab)' },
  {
    name: 'StemRenu (dietary supplement)',
    value: 'StemRenu (dietary supplement)',
  },
  { name: 'Stendra (avanafil)', value: 'Stendra (avanafil)' },
  {
    name: 'Stimate (desmopressin acetate)',
    value: 'Stimate (desmopressin acetate)',
  },
  {
    name: 'Stiolto Respimat (olodaterol/tiotropium bromide)',
    value: 'Stiolto Respimat (olodaterol/tiotropium bromide)',
  },
  { name: 'Stivarga (regorafenib)', value: 'Stivarga (regorafenib)' },
  { name: 'Strattera (atomoxetine)', value: 'Strattera (atomoxetine)' },
  { name: 'Strensiq (asfotase alfa)', value: 'Strensiq (asfotase alfa)' },
  { name: 'Streptomycin (streptomycin)', value: 'Streptomycin (streptomycin)' },
  { name: 'Striant (testosterone)', value: 'Striant (testosterone)' },
  {
    name: 'Stribild (cobicistat/elvitegravir/emtricitabine/tenofovir disoproxil fumarate)',
    value:
      'Stribild (cobicistat/elvitegravir/emtricitabine/tenofovir disoproxil fumarate)',
  },
  {
    name: 'Striverdi Respimat (olodaterol)',
    value: 'Striverdi Respimat (olodaterol)',
  },
  { name: 'Stromectol (ivermectin)', value: 'Stromectol (ivermectin)' },
  {
    name: 'Suboxone (buprenorphine/naloxone)',
    value: 'Suboxone (buprenorphine/naloxone)',
  },
  { name: 'Subsys (fentanyl)', value: 'Subsys (fentanyl)' },
  {
    name: 'Suclear (magnesium sulfate/potassium sulfate/sodium sulfate, polyethylene glyc etc.',
    value:
      'Suclear (magnesium sulfate/potassium sulfate/sodium sulfate, polyethylene glyc etc.',
  },
  { name: 'Sucraid (sacrosidase)', value: 'Sucraid (sacrosidase)' },
  {
    name: 'Sudafed Sinus Congestion 24 Hour (hydrochloride/pseudoephedrine)',
    value: 'Sudafed Sinus Congestion 24 Hour (hydrochloride/pseudoephedrine)',
  },
  { name: 'Sular (nisoldipine)', value: 'Sular (nisoldipine)' },
  {
    name: 'Sulfamethoxazole and Trimethoprim (sulfamethoxazole/trimethoprim)',
    value: 'Sulfamethoxazole and Trimethoprim (sulfamethoxazole/trimethoprim)',
  },
  {
    name: 'Sulfamylon Cream (mafenide acetate)',
    value: 'Sulfamylon Cream (mafenide acetate)',
  },
  {
    name: 'Sulfamylon Topical Solution (mafenide acetate)',
    value: 'Sulfamylon Topical Solution (mafenide acetate)',
  },
  {
    name: 'Sulfatrim (sulfamethoxazole/trimethoprim)',
    value: 'Sulfatrim (sulfamethoxazole/trimethoprim)',
  },
  { name: 'Sulindac (sulindac)', value: 'Sulindac (sulindac)' },
  {
    name: 'Sumadan (sodium sulfacetamide/sulfur)',
    value: 'Sumadan (sodium sulfacetamide/sulfur)',
  },
  {
    name: 'Sumavel DosePro (sumatriptan)',
    value: 'Sumavel DosePro (sumatriptan)',
  },
  { name: 'Sunosi (solriamfetol)', value: 'Sunosi (solriamfetol)' },
  {
    name: 'Supprelin LA (histrelin acetate)',
    value: 'Supprelin LA (histrelin acetate)',
  },
  { name: 'Suprane (desflurane)', value: 'Suprane (desflurane)' },
  { name: 'Suprax (cefixime)', value: 'Suprax (cefixime)' },
  {
    name: 'Suprenza (phentermine hydrochloride)',
    value: 'Suprenza (phentermine hydrochloride)',
  },
  {
    name: 'Suprep Bowel Prep (magnesium sulfate/potassium sulfate/sodium sulfate)',
    value:
      'Suprep Bowel Prep (magnesium sulfate/potassium sulfate/sodium sulfate)',
  },
  {
    name: 'Surmontil (trimipramine maleate)',
    value: 'Surmontil (trimipramine maleate)',
  },
  { name: 'Survanta (beractant)', value: 'Survanta (beractant)' },
  { name: 'Sustiva (efavirenz)', value: 'Sustiva (efavirenz)' },
  { name: 'Sustol (granisetron)', value: 'Sustol (granisetron)' },
  { name: 'Susvimo (ranibizumab)', value: 'Susvimo (ranibizumab)' },
  { name: 'Sutent (sunitinib malate)', value: 'Sutent (sunitinib malate)' },
  {
    name: 'Syeda (drospirenone/ethinyl estradiol)',
    value: 'Syeda (drospirenone/ethinyl estradiol)',
  },
  { name: 'Sylvant (siltuximab)', value: 'Sylvant (siltuximab)' },
  {
    name: 'Symbicort (budesonide/formoterol fumarate dihydrate)',
    value: 'Symbicort (budesonide/formoterol fumarate dihydrate)',
  },
  {
    name: 'Symbyax (fluoxetine/olanzapine)',
    value: 'Symbyax (fluoxetine/olanzapine)',
  },
  {
    name: 'Symdeko (ivacaftor/tezacaftor)',
    value: 'Symdeko (ivacaftor/tezacaftor)',
  },
  {
    name: 'Symlin (pramlintide acetate)',
    value: 'Symlin (pramlintide acetate)',
  },
  { name: 'Sympazan (clobazam)', value: 'Sympazan (clobazam)' },
  { name: 'Symproic (naldemedine)', value: 'Symproic (naldemedine)' },
  {
    name: 'Symtuza (cobicistat/darunavir/emtricitabine/tenofovir alafenamide)',
    value: 'Symtuza (cobicistat/darunavir/emtricitabine/tenofovir alafenamide)',
  },
  { name: 'Synagis (palivizumab)', value: 'Synagis (palivizumab)' },
  {
    name: 'Synalar Cream (fluocinolone acetonide)',
    value: 'Synalar Cream (fluocinolone acetonide)',
  },
  {
    name: 'Synalar Ointment (fluocinolone acetonide)',
    value: 'Synalar Ointment (fluocinolone acetonide)',
  },
  {
    name: 'Synalar Topical Solution (fluocinolone acetonide)',
    value: 'Synalar Topical Solution (fluocinolone acetonide)',
  },
  {
    name: 'Synalgos-DC (aspirin/caffeine/dihydrocodeine bitartrate)',
    value: 'Synalgos-DC (aspirin/caffeine/dihydrocodeine bitartrate)',
  },
  { name: 'Synarel (nafarelin acetate)', value: 'Synarel (nafarelin acetate)' },
  {
    name: 'Synera (lidocaine/tetracaine)',
    value: 'Synera (lidocaine/tetracaine)',
  },
  {
    name: 'SynerBeet (dietary supplement)',
    value: 'SynerBeet (dietary supplement)',
  },
  {
    name: 'Synercid (dalfopristin/quinupristin)',
    value: 'Synercid (dalfopristin/quinupristin)',
  },
  {
    name: 'Synjardy (empagliflozin/metformin hydrochloride)',
    value: 'Synjardy (empagliflozin/metformin hydrochloride)',
  },
  {
    name: 'Synjardy XR (empagliflozin/metformin hydrochloride)',
    value: 'Synjardy XR (empagliflozin/metformin hydrochloride)',
  },
  {
    name: 'Synribo (omacetaxine mepesuccinate)',
    value: 'Synribo (omacetaxine mepesuccinate)',
  },
  {
    name: 'Synthroid (levothyroxine sodium)',
    value: 'Synthroid (levothyroxine sodium)',
  },
  {
    name: 'Syprine (trientine hydrochloride)',
    value: 'Syprine (trientine hydrochloride)',
  },
  {
    name: 'Systane Balance (propylene glycol)',
    value: 'Systane Balance (propylene glycol)',
  },
  { name: 'Tabrecta (capmatinib)', value: 'Tabrecta (capmatinib)' },
  { name: 'TachoSil (fibrin sealant)', value: 'TachoSil (fibrin sealant)' },
  {
    name: 'Taclonex Ointment (betamethasone dipropionate/calcipotriene)',
    value: 'Taclonex Ointment (betamethasone dipropionate/calcipotriene)',
  },
  {
    name: 'Taclonex Topical Suspension (betamethasone dipropionate/calcipotriene)',
    value:
      'Taclonex Topical Suspension (betamethasone dipropionate/calcipotriene)',
  },
  { name: 'Tafinlar (dabrafenib)', value: 'Tafinlar (dabrafenib)' },
  { name: 'Tagrisso (osimertinib)', value: 'Tagrisso (osimertinib)' },
  {
    name: 'Tahitian Noni Juice (dietary supplement)',
    value: 'Tahitian Noni Juice (dietary supplement)',
  },
  { name: 'Taltz (ixekizumab)', value: 'Taltz (ixekizumab)' },
  { name: 'Talwin (pentazocine)', value: 'Talwin (pentazocine)' },
  { name: 'Talzenna (talazoparib)', value: 'Talzenna (talazoparib)' },
  {
    name: 'Tamiflu (oseltamivir phosphate)',
    value: 'Tamiflu (oseltamivir phosphate)',
  },
  {
    name: 'Tamoxifen Citrate (tamoxifen citrate)',
    value: 'Tamoxifen Citrate (tamoxifen citrate)',
  },
  {
    name: 'Tandem DHA (docosahexaenoic acid (DHA)/eicosapentaenoic acid (EPA)/ferrous fumara etc.',
    value:
      'Tandem DHA (docosahexaenoic acid (DHA)/eicosapentaenoic acid (EPA)/ferrous fumara etc.',
  },
  {
    name: 'Tandem Plus (copper sulfate/ferrous fumarate/manganese sulfate/polysaccharide iron etc.',
    value:
      'Tandem Plus (copper sulfate/ferrous fumarate/manganese sulfate/polysaccharide iron etc.',
  },
  { name: 'Tanzeum (albiglutide)', value: 'Tanzeum (albiglutide)' },
  { name: 'Tapazole (methimazole)', value: 'Tapazole (methimazole)' },
  { name: 'Tarceva (erlotinib)', value: 'Tarceva (erlotinib)' },
  {
    name: 'Targretin Capsules (bexarotene)',
    value: 'Targretin Capsules (bexarotene)',
  },
  { name: 'Targretin Gel (bexarotene)', value: 'Targretin Gel (bexarotene)' },
  {
    name: 'Tarka (trandolapril/verapamil hydrochloride)',
    value: 'Tarka (trandolapril/verapamil hydrochloride)',
  },
  { name: 'Tasigna (nilotinib)', value: 'Tasigna (nilotinib)' },
  { name: 'Tasmar (tolcapone)', value: 'Tasmar (tolcapone)' },
  {
    name: 'Tavist Allergy (clemastine fumarate)',
    value: 'Tavist Allergy (clemastine fumarate)',
  },
  { name: 'Taxotere (docetaxel)', value: 'Taxotere (docetaxel)' },
  {
    name: 'Taytulla (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
    value:
      'Taytulla (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
  },
  { name: 'Tazicef (ceftazidime)', value: 'Tazicef (ceftazidime)' },
  { name: 'Tazorac Cream (tazarotene)', value: 'Tazorac Cream (tazarotene)' },
  { name: 'Tazorac Gel (tazarotene)', value: 'Tazorac Gel (tazarotene)' },
  {
    name: 'Taztia XT (diltiazem hydrochloride)',
    value: 'Taztia XT (diltiazem hydrochloride)',
  },
  {
    name: 'Tears Naturale Forte (dextran 70/glycerin/hypromellose)',
    value: 'Tears Naturale Forte (dextran 70/glycerin/hypromellose)',
  },
  {
    name: 'Tears Naturale Free (dextran 70/hypromellose)',
    value: 'Tears Naturale Free (dextran 70/hypromellose)',
  },
  { name: 'Tecentriq (atezolizumab)', value: 'Tecentriq (atezolizumab)' },
  {
    name: 'Tecfidera (dimethyl fumarate)',
    value: 'Tecfidera (dimethyl fumarate)',
  },
  {
    name: 'Technivie (ombitasvir/paritaprevir/ritonavir)',
    value: 'Technivie (ombitasvir/paritaprevir/ritonavir)',
  },
  { name: 'Tecvayli (teclistamab-cqyv)', value: 'Tecvayli (teclistamab-cqyv)' },
  {
    name: 'Teflaro (ceftaroline fosamil)',
    value: 'Teflaro (ceftaroline fosamil)',
  },
  {
    name: 'Tegretol/Tegretol XR (carbamazepine)',
    value: 'Tegretol/Tegretol XR (carbamazepine)',
  },
  {
    name: 'Tekamlo (aliskiren/amlodipine)',
    value: 'Tekamlo (aliskiren/amlodipine)',
  },
  { name: 'Tekturna (aliskiren)', value: 'Tekturna (aliskiren)' },
  {
    name: 'Tekturna HCT (aliskiren/hydrochlorothiazide)',
    value: 'Tekturna HCT (aliskiren/hydrochlorothiazide)',
  },
  { name: 'Temodar (temozolomide)', value: 'Temodar (temozolomide)' },
  { name: 'Ten (dietary supplement)', value: 'Ten (dietary supplement)' },
  {
    name: 'Tenex (guanfacine hydrochloride)',
    value: 'Tenex (guanfacine hydrochloride)',
  },
  { name: 'Teniposide (teniposide)', value: 'Teniposide (teniposide)' },
  {
    name: 'Tenivac (tetanus and diphtheria toxoids adsorbed)',
    value: 'Tenivac (tetanus and diphtheria toxoids adsorbed)',
  },
  {
    name: 'Tenoretic (atenolol/chlorthalidone)',
    value: 'Tenoretic (atenolol/chlorthalidone)',
  },
  { name: 'Tenormin (atenolol)', value: 'Tenormin (atenolol)' },
  { name: 'Tepadina (thiotepa)', value: 'Tepadina (thiotepa)' },
  {
    name: 'Terazol 3/Terazol 7 (terconazole)',
    value: 'Terazol 3/Terazol 7 (terconazole)',
  },
  {
    name: 'Terazosin Hydrochloride (terazosin hydrochloride)',
    value: 'Terazosin Hydrochloride (terazosin hydrochloride)',
  },
  {
    name: 'Terbutaline Sulfate Tablets (terbutaline sulfate)',
    value: 'Terbutaline Sulfate Tablets (terbutaline sulfate)',
  },
  { name: 'Tersi (selenium sulfide)', value: 'Tersi (selenium sulfide)' },
  { name: 'Tessalon (benzonatate)', value: 'Tessalon (benzonatate)' },
  { name: 'Testim (testosterone)', value: 'Testim (testosterone)' },
  { name: 'Testopel (testosterone)', value: 'Testopel (testosterone)' },
  {
    name: 'Testred (methyltestosterone)',
    value: 'Testred (methyltestosterone)',
  },
  {
    name: 'Tetracycline Hydrochloride Capsules (tetracycline hydrochloride)',
    value: 'Tetracycline Hydrochloride Capsules (tetracycline hydrochloride)',
  },
  {
    name: 'Teveten (eprosartan mesylate)',
    value: 'Teveten (eprosartan mesylate)',
  },
  {
    name: 'Teveten HCT (eprosartan mesylate/hydrochlorothiazide)',
    value: 'Teveten HCT (eprosartan mesylate/hydrochlorothiazide)',
  },
  { name: 'Thalitone (chlorthalidone)', value: 'Thalitone (chlorthalidone)' },
  { name: 'Thalomid (thalidomide)', value: 'Thalomid (thalidomide)' },
  {
    name: 'Theo-24 (theophylline anhydrous)',
    value: 'Theo-24 (theophylline anhydrous)',
  },
  {
    name: 'Theophylline in 5% Dextrose (theophylline)',
    value: 'Theophylline in 5% Dextrose (theophylline)',
  },
  {
    name: 'Theraflu Daytime Severe Cold and Cough (acetaminophen/dextromethorphan hydrobromide/phenylephrine hydrochlori etc.',
    value:
      'Theraflu Daytime Severe Cold and Cough (acetaminophen/dextromethorphan hydrobromide/phenylephrine hydrochlori etc.',
  },
  {
    name: 'Theraflu Multi-Symptom Severe Cold (acetaminophen/dextromethorphan hydrobromide/phenylephrine hydrochlori etc.',
    value:
      'Theraflu Multi-Symptom Severe Cold (acetaminophen/dextromethorphan hydrobromide/phenylephrine hydrochlori etc.',
  },
  {
    name: 'Thiamine (thiamine hydrochloride)',
    value: 'Thiamine (thiamine hydrochloride)',
  },
  { name: 'Thiola (tiopronin)', value: 'Thiola (tiopronin)' },
  { name: 'Thiola EC (tiopronin)', value: 'Thiola EC (tiopronin)' },
  {
    name: 'Thioridazine Hydrochloride (thioridazine hydrochloride)',
    value: 'Thioridazine Hydrochloride (thioridazine hydrochloride)',
  },
  { name: 'Thiothixene (thiothixene)', value: 'Thiothixene (thiothixene)' },
  {
    name: 'Thrombate III (antithrombin III (human))',
    value: 'Thrombate III (antithrombin III (human))',
  },
  {
    name: 'Thrombin-JMI (thrombin (bovine origin))',
    value: 'Thrombin-JMI (thrombin (bovine origin))',
  },
  {
    name: 'Thymoglobulin (anti-thymocyte globulin (rabbit))',
    value: 'Thymoglobulin (anti-thymocyte globulin (rabbit))',
  },
  { name: 'Thyrogen (thyrotropin alfa)', value: 'Thyrogen (thyrotropin alfa)' },
  { name: 'Thyrolar (liotrix (T3;T4))', value: 'Thyrolar (liotrix (T3;T4))' },
  {
    name: 'Tiazac (diltiazem hydrochloride)',
    value: 'Tiazac (diltiazem hydrochloride)',
  },
  { name: 'Tice BCG (BCG live)', value: 'Tice BCG (BCG live)' },
  {
    name: 'Ticlopidine Hydrochloride (ticlopidine hydrochloride)',
    value: 'Ticlopidine Hydrochloride (ticlopidine hydrochloride)',
  },
  {
    name: 'Tigan Capsules (trimethobenzamide hydrochloride)',
    value: 'Tigan Capsules (trimethobenzamide hydrochloride)',
  },
  {
    name: 'Tigan Injectable (trimethobenzamide hydrochloride)',
    value: 'Tigan Injectable (trimethobenzamide hydrochloride)',
  },
  { name: 'Tikosyn (dofetilide)', value: 'Tikosyn (dofetilide)' },
  {
    name: 'Tilia Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
    value:
      'Tilia Fe (ethinyl estradiol/ferrous fumarate/norethindrone acetate)',
  },
  {
    name: 'Timentin (clavulanate potassium/ticarcillin disodium)',
    value: 'Timentin (clavulanate potassium/ticarcillin disodium)',
  },
  {
    name: 'Timolol Maleate (timolol maleate)',
    value: 'Timolol Maleate (timolol maleate)',
  },
  { name: 'Timoptic (timolol maleate)', value: 'Timoptic (timolol maleate)' },
  {
    name: 'Timoptic in Ocudose (timolol maleate)',
    value: 'Timoptic in Ocudose (timolol maleate)',
  },
  {
    name: 'Timoptic-XE (timolol maleate)',
    value: 'Timoptic-XE (timolol maleate)',
  },
  {
    name: "Tinactin Athlete's Foot Cream (tolnaftate)",
    value: "Tinactin Athlete's Foot Cream (tolnaftate)",
  },
  {
    name: 'Tinactin Jock Itch Cream (tolnaftate)',
    value: 'Tinactin Jock Itch Cream (tolnaftate)',
  },
  { name: 'Tindamax (tinidazole)', value: 'Tindamax (tinidazole)' },
  {
    name: 'Tirosint (levothyroxine sodium)',
    value: 'Tirosint (levothyroxine sodium)',
  },
  { name: 'Tisseel (fibrin sealant)', value: 'Tisseel (fibrin sealant)' },
  { name: 'Tivicay (dolutegravir)', value: 'Tivicay (dolutegravir)' },
  { name: 'Tivorbex (indomethacin)', value: 'Tivorbex (indomethacin)' },
  { name: 'Tizanidine (tizanidine)', value: 'Tizanidine (tizanidine)' },
  { name: 'TNKase (tenecteplase)', value: 'TNKase (tenecteplase)' },
  { name: 'Tobi (tobramycin)', value: 'Tobi (tobramycin)' },
  { name: 'Tobi Podhaler (tobramycin)', value: 'Tobi Podhaler (tobramycin)' },
  {
    name: 'TobraDex Ophthalmic Ointment (dexamethasone/tobramycin)',
    value: 'TobraDex Ophthalmic Ointment (dexamethasone/tobramycin)',
  },
  {
    name: 'Tobradex Ophthalmic Suspension (dexamethasone/tobramycin)',
    value: 'Tobradex Ophthalmic Suspension (dexamethasone/tobramycin)',
  },
  {
    name: 'Tobradex ST (dexamethasone/tobramycin)',
    value: 'Tobradex ST (dexamethasone/tobramycin)',
  },
  { name: 'Tobramycin (tobramycin)', value: 'Tobramycin (tobramycin)' },
  {
    name: 'Tobrex Ophthalmic Ointment (tobramycin)',
    value: 'Tobrex Ophthalmic Ointment (tobramycin)',
  },
  {
    name: 'Tobrex Ophthalmic Solution (tobramycin)',
    value: 'Tobrex Ophthalmic Solution (tobramycin)',
  },
  {
    name: 'Tofranil Tablets (imipramine hydrochloride)',
    value: 'Tofranil Tablets (imipramine hydrochloride)',
  },
  {
    name: 'Tofranil-PM (imipramine pamoate)',
    value: 'Tofranil-PM (imipramine pamoate)',
  },
  { name: 'Tolak (fluorouracil)', value: 'Tolak (fluorouracil)' },
  { name: 'Tolazamide (tolazamide)', value: 'Tolazamide (tolazamide)' },
  { name: 'Tolbutamide (tolbutamide)', value: 'Tolbutamide (tolbutamide)' },
  {
    name: 'Tolmetin 400mg Capsules (tolmetin sodium)',
    value: 'Tolmetin 400mg Capsules (tolmetin sodium)',
  },
  {
    name: 'Tolmetin 600mg Tablets (tolmetin sodium)',
    value: 'Tolmetin 600mg Tablets (tolmetin sodium)',
  },
  { name: 'Topamax (topiramate)', value: 'Topamax (topiramate)' },
  {
    name: 'Topicort Cream and Gel (desoximetasone)',
    value: 'Topicort Cream and Gel (desoximetasone)',
  },
  {
    name: 'Topicort Ointment 0.05% (desoximetasone)',
    value: 'Topicort Ointment 0.05% (desoximetasone)',
  },
  {
    name: 'Topicort Ointment 0.25% (desoximetasone)',
    value: 'Topicort Ointment 0.25% (desoximetasone)',
  },
  {
    name: 'Topicort Topical Spray (desoximetasone)',
    value: 'Topicort Topical Spray (desoximetasone)',
  },
  { name: 'Toposar (etoposide)', value: 'Toposar (etoposide)' },
  {
    name: 'Toprol-XL (metoprolol succinate)',
    value: 'Toprol-XL (metoprolol succinate)',
  },
  { name: 'Torisel (temsirolimus)', value: 'Torisel (temsirolimus)' },
  { name: 'Torsemide (torsemide)', value: 'Torsemide (torsemide)' },
  { name: 'Toujeo (insulin glargine)', value: 'Toujeo (insulin glargine)' },
  {
    name: 'Toviaz (fesoterodine fumarate)',
    value: 'Toviaz (fesoterodine fumarate)',
  },
  { name: 'Tracleer (bosentan)', value: 'Tracleer (bosentan)' },
  { name: 'Tradjenta (linagliptin)', value: 'Tradjenta (linagliptin)' },
  {
    name: 'Transderm Scop (scopolamine)',
    value: 'Transderm Scop (scopolamine)',
  },
  {
    name: 'Tranxene (clorazepate dipotassium)',
    value: 'Tranxene (clorazepate dipotassium)',
  },
  { name: 'Travatan Z (travoprost)', value: 'Travatan Z (travoprost)' },
  {
    name: 'Trazodone Hydrochloride (trazodone hydrochloride)',
    value: 'Trazodone Hydrochloride (trazodone hydrochloride)',
  },
  {
    name: 'Treanda (bendamustine hydrochloride)',
    value: 'Treanda (bendamustine hydrochloride)',
  },
  { name: 'Trecator (ethionamide)', value: 'Trecator (ethionamide)' },
  {
    name: 'Trelegy Ellipta (fluticasone furoate/umeclidinium/vilanterol)',
    value: 'Trelegy Ellipta (fluticasone furoate/umeclidinium/vilanterol)',
  },
  {
    name: 'Trelstar (triptorelin pamoate)',
    value: 'Trelstar (triptorelin pamoate)',
  },
  { name: 'Tremfya (guselkumab)', value: 'Tremfya (guselkumab)' },
  { name: 'Treprostinil (treprostinil)', value: 'Treprostinil (treprostinil)' },
  { name: 'Tresiba (insulin degludec)', value: 'Tresiba (insulin degludec)' },
  { name: 'Tretin•X (tretinoin)', value: 'Tretin•X (tretinoin)' },
  { name: 'Tretinoin (tretinoin)', value: 'Tretinoin (tretinoin)' },
  {
    name: 'Tretten (coagulation factor XIII A-subunit (recombinant))',
    value: 'Tretten (coagulation factor XIII A-subunit (recombinant))',
  },
  { name: 'Trexall (methotrexate)', value: 'Trexall (methotrexate)' },
  {
    name: 'Treximet (naproxen sodium/sumatriptan)',
    value: 'Treximet (naproxen sodium/sumatriptan)',
  },
  {
    name: 'Trezix (acetaminophen/caffeine/dihydrocodeine bitartrate)',
    value: 'Trezix (acetaminophen/caffeine/dihydrocodeine bitartrate)',
  },
  {
    name: 'Tri Femynor (ethinyl estradiol/norgestimate)',
    value: 'Tri Femynor (ethinyl estradiol/norgestimate)',
  },
  {
    name: 'Tri-Lo-Mili (ethinyl estradiol/norgestimate)',
    value: 'Tri-Lo-Mili (ethinyl estradiol/norgestimate)',
  },
  {
    name: 'Tri-Luma (fluocinolone acetonide/hydroquinone/tretinoin)',
    value: 'Tri-Luma (fluocinolone acetonide/hydroquinone/tretinoin)',
  },
  {
    name: 'Tri-Norinyl (ethinyl estradiol/norethindrone)',
    value: 'Tri-Norinyl (ethinyl estradiol/norethindrone)',
  },
  {
    name: 'Tri-Previfem (ethinyl estradiol/norgestimate)',
    value: 'Tri-Previfem (ethinyl estradiol/norgestimate)',
  },
  {
    name: 'Tri-Sprintec (ethinyl estradiol/norgestimate)',
    value: 'Tri-Sprintec (ethinyl estradiol/norgestimate)',
  },
  {
    name: 'Triamcinolone Acetonide Cream (triamcinolone acetonide)',
    value: 'Triamcinolone Acetonide Cream (triamcinolone acetonide)',
  },
  {
    name: 'Triamcinolone Acetonide Dental Paste (triamcinolone acetonide)',
    value: 'Triamcinolone Acetonide Dental Paste (triamcinolone acetonide)',
  },
  {
    name: 'Triamcinolone Acetonide Lotion, USP 0.025% (triamcinolone acetonide)',
    value:
      'Triamcinolone Acetonide Lotion, USP 0.025% (triamcinolone acetonide)',
  },
  {
    name: 'Triamcinolone Acetonide Lotion, USP 0.1% (triamcinolone acetonide)',
    value: 'Triamcinolone Acetonide Lotion, USP 0.1% (triamcinolone acetonide)',
  },
  {
    name: 'Triamcinolone Acetonide Ointment (triamcinolone acetonide)',
    value: 'Triamcinolone Acetonide Ointment (triamcinolone acetonide)',
  },
  {
    name: 'Triaminic Cough and Congestion (dextromethorphan hydrobromide/guaifenesin)',
    value:
      'Triaminic Cough and Congestion (dextromethorphan hydrobromide/guaifenesin)',
  },
  {
    name: 'Triaminic Multi-Symptom Fever and Cold (acetaminophen/chlorpheniramine maleate/dextromethorphan hydrobromide/ etc.',
    value:
      'Triaminic Multi-Symptom Fever and Cold (acetaminophen/chlorpheniramine maleate/dextromethorphan hydrobromide/ etc.',
  },
  {
    name: 'Triamterene and Hydrochlorothiazide (hydrochlorothiazide/triamterene)',
    value:
      'Triamterene and Hydrochlorothiazide (hydrochlorothiazide/triamterene)',
  },
  {
    name: 'Tribenzor (amlodipine/hydrochlorothiazide/olmesartan medoxomil)',
    value: 'Tribenzor (amlodipine/hydrochlorothiazide/olmesartan medoxomil)',
  },
  { name: 'Tricor (fenofibrate)', value: 'Tricor (fenofibrate)' },
  {
    name: 'Triesence (triamcinolone acetonide)',
    value: 'Triesence (triamcinolone acetonide)',
  },
  {
    name: 'Triferic (ferric pyrophosphate citrate)',
    value: 'Triferic (ferric pyrophosphate citrate)',
  },
  {
    name: 'Trifluoperazine Hydrochloride (trifluoperazine hydrochloride)',
    value: 'Trifluoperazine Hydrochloride (trifluoperazine hydrochloride)',
  },
  { name: 'Triglide (fenofibrate)', value: 'Triglide (fenofibrate)' },
  {
    name: 'Trihexyphenidyl Hydrochloride Oral Solution (trihexyphenidyl hydrochloride)',
    value:
      'Trihexyphenidyl Hydrochloride Oral Solution (trihexyphenidyl hydrochloride)',
  },
  {
    name: 'Trihexyphenidyl Hydrochloride Tablets (trihexyphenidyl hydrochloride)',
    value:
      'Trihexyphenidyl Hydrochloride Tablets (trihexyphenidyl hydrochloride)',
  },
  { name: 'Trileptal (oxcarbazepine)', value: 'Trileptal (oxcarbazepine)' },
  { name: 'Trilipix (fenofibric acid)', value: 'Trilipix (fenofibric acid)' },
  {
    name: 'TriLyte (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
    value:
      'TriLyte (polyethylene glycol 3350/potassium chloride/sodium bicarbonate/sodium etc.',
  },
  { name: 'Trimethoprim (trimethoprim)', value: 'Trimethoprim (trimethoprim)' },
  { name: 'Trintellix (vortioxetine)', value: 'Trintellix (vortioxetine)' },
  {
    name: 'Triostat (liothyronine sodium)',
    value: 'Triostat (liothyronine sodium)',
  },
  { name: 'Triptodur (triptorelin)', value: 'Triptodur (triptorelin)' },
  { name: 'Trisenox (arsenic trioxide)', value: 'Trisenox (arsenic trioxide)' },
  {
    name: 'Triumeq (abacavir/dolutegravir/lamivudine)',
    value: 'Triumeq (abacavir/dolutegravir/lamivudine)',
  },
  {
    name: 'Trivora (ethinyl estradiol/levonorgestrel)',
    value: 'Trivora (ethinyl estradiol/levonorgestrel)',
  },
  {
    name: 'Trizivir (abacavir/lamivudine/zidovudine)',
    value: 'Trizivir (abacavir/lamivudine/zidovudine)',
  },
  { name: 'Trokendi XR (topiramate)', value: 'Trokendi XR (topiramate)' },
  {
    name: 'Trospium Chloride Extended-Release Capsules (trospium chloride)',
    value: 'Trospium Chloride Extended-Release Capsules (trospium chloride)',
  },
  {
    name: 'Trospium Chloride Tablets (trospium chloride)',
    value: 'Trospium Chloride Tablets (trospium chloride)',
  },
  {
    name: 'TruGreen (dietary supplement)',
    value: 'TruGreen (dietary supplement)',
  },
  { name: 'Trulance (plecanatide)', value: 'Trulance (plecanatide)' },
  { name: 'Trulicity (dulaglutide)', value: 'Trulicity (dulaglutide)' },
  {
    name: 'Trumenba (meningococcal group B vaccine)',
    value: 'Trumenba (meningococcal group B vaccine)',
  },
  {
    name: 'Trusopt (dorzolamide hydrochloride)',
    value: 'Trusopt (dorzolamide hydrochloride)',
  },
  {
    name: 'Truvada (emtricitabine/tenofovir disoproxil fumarate)',
    value: 'Truvada (emtricitabine/tenofovir disoproxil fumarate)',
  },
  { name: 'Truxima (rituximab-abbs)', value: 'Truxima (rituximab-abbs)' },
  {
    name: 'Tubersol (tuberculin purified protein derivative)',
    value: 'Tubersol (tuberculin purified protein derivative)',
  },
  {
    name: 'Tudorza Pressair (aclidinium bromide)',
    value: 'Tudorza Pressair (aclidinium bromide)',
  },
  { name: 'Turalio (pexidartinib)', value: 'Turalio (pexidartinib)' },
  {
    name: 'TussiCaps (chlorpheniramine polistirex/hydrocodone polistirex)',
    value: 'TussiCaps (chlorpheniramine polistirex/hydrocodone polistirex)',
  },
  {
    name: 'Tussigon (homatropine methylbromide/hydrocodone bitartrate)',
    value: 'Tussigon (homatropine methylbromide/hydrocodone bitartrate)',
  },
  {
    name: 'Tussionex (chlorpheniramine polistirex/hydrocodone polistirex)',
    value: 'Tussionex (chlorpheniramine polistirex/hydrocodone polistirex)',
  },
  {
    name: 'Tussionex Pennkinetic (chlorpheniramine/hydrocodone)',
    value: 'Tussionex Pennkinetic (chlorpheniramine/hydrocodone)',
  },
  {
    name: 'Tuzistra XR (chlorpheniramine polistirex/codeine polistirex)',
    value: 'Tuzistra XR (chlorpheniramine polistirex/codeine polistirex)',
  },
  {
    name: 'Twinrix (hepatitis A & hepatitis B (recombinant) vaccine)',
    value: 'Twinrix (hepatitis A & hepatitis B (recombinant) vaccine)',
  },
  {
    name: 'Twynsta (amlodipine/telmisartan)',
    value: 'Twynsta (amlodipine/telmisartan)',
  },
  { name: 'Tybost (cobicistat)', value: 'Tybost (cobicistat)' },
  { name: 'Tygacil (tigecycline)', value: 'Tygacil (tigecycline)' },
  { name: 'Tykerb (lapatinib)', value: 'Tykerb (lapatinib)' },
  { name: 'Tylenol 8HR (acetaminophen)', value: 'Tylenol 8HR (acetaminophen)' },
  {
    name: 'Tylenol 8HR Arthritis Pain (acetaminophen)',
    value: 'Tylenol 8HR Arthritis Pain (acetaminophen)',
  },
  {
    name: 'Tylenol Extra Strength (acetaminophen)',
    value: 'Tylenol Extra Strength (acetaminophen)',
  },
  {
    name: 'Tylenol with Codeine (acetaminophen/codeine phosphate)',
    value: 'Tylenol with Codeine (acetaminophen/codeine phosphate)',
  },
  { name: 'Tymlos (abaloparatide)', value: 'Tymlos (abaloparatide)' },
  {
    name: 'Typhim Vi (typhoid Vi polysaccharide vaccine)',
    value: 'Typhim Vi (typhoid Vi polysaccharide vaccine)',
  },
  { name: 'Tysabri (natalizumab)', value: 'Tysabri (natalizumab)' },
  { name: 'Tyvaso (treprostinil)', value: 'Tyvaso (treprostinil)' },
  { name: 'Tyzeka (telbivudine)', value: 'Tyzeka (telbivudine)' },
  {
    name: 'Ubiquinol, Active Form of CoQl0 (CoQ10)',
    value: 'Ubiquinol, Active Form of CoQl0 (CoQ10)',
  },
  { name: 'Ubrelvy (ubrogepant)', value: 'Ubrelvy (ubrogepant)' },
  {
    name: 'Uceris Extended-Release Tablets (budesonide)',
    value: 'Uceris Extended-Release Tablets (budesonide)',
  },
  {
    name: 'Uceris Rectal Foam (budesonide)',
    value: 'Uceris Rectal Foam (budesonide)',
  },
  {
    name: 'Udenyca (pegfilgrastim-cbqv)',
    value: 'Udenyca (pegfilgrastim-cbqv)',
  },
  { name: 'Ulesfia (benzyl alcohol)', value: 'Ulesfia (benzyl alcohol)' },
  { name: 'Uloric (febuxostat)', value: 'Uloric (febuxostat)' },
  { name: 'Ultane (sevoflurane)', value: 'Ultane (sevoflurane)' },
  {
    name: 'Ultiva (remifentanil hydrochloride)',
    value: 'Ultiva (remifentanil hydrochloride)',
  },
  {
    name: 'Ultracet (acetaminophen/tramadol hydrochloride)',
    value: 'Ultracet (acetaminophen/tramadol hydrochloride)',
  },
  {
    name: 'Ultram (tramadol hydrochloride)',
    value: 'Ultram (tramadol hydrochloride)',
  },
  {
    name: 'Ultram ER (tramadol hydrochloride)',
    value: 'Ultram ER (tramadol hydrochloride)',
  },
  {
    name: 'Ultravate (halobetasol propionate)',
    value: 'Ultravate (halobetasol propionate)',
  },
  {
    name: 'Ultravist Injection (iopromide)',
    value: 'Ultravist Injection (iopromide)',
  },
  { name: 'Ultresa (pancrelipase)', value: 'Ultresa (pancrelipase)' },
  {
    name: 'Unasyn (ampicillin sodium/sulbactam sodium)',
    value: 'Unasyn (ampicillin sodium/sulbactam sodium)',
  },
  {
    name: 'UNICITY Balance (dietary supplement)',
    value: 'UNICITY Balance (dietary supplement)',
  },
  {
    name: 'UNICITY Bio-C (dietary supplement)',
    value: 'UNICITY Bio-C (dietary supplement)',
  },
  {
    name: 'UNICITY Bios 7 (dietary supplement)',
    value: 'UNICITY Bios 7 (dietary supplement)',
  },
  {
    name: 'UNICITY Bios Life C Plus (dietary supplement)',
    value: 'UNICITY Bios Life C Plus (dietary supplement)',
  },
  {
    name: 'UNICITY Bios Life ProBionic (dietary supplement)',
    value: 'UNICITY Bios Life ProBionic (dietary supplement)',
  },
  {
    name: 'UNICITY Bios Life Vision Essentials (dietary supplement)',
    value: 'UNICITY Bios Life Vision Essentials (dietary supplement)',
  },
  {
    name: 'UNICITY Cardio-Essentials (dietary supplement)',
    value: 'UNICITY Cardio-Essentials (dietary supplement)',
  },
  {
    name: 'UNICITY Core Health (dietary supplement)',
    value: 'UNICITY Core Health (dietary supplement)',
  },
  {
    name: 'UNICITY Enzygen Plus (dietary supplement)',
    value: 'UNICITY Enzygen Plus (dietary supplement)',
  },
  {
    name: 'UNICITY Immunizen (dietary supplement)',
    value: 'UNICITY Immunizen (dietary supplement)',
  },
  {
    name: 'UNICITY Joint Mobility (dietary supplement)',
    value: 'UNICITY Joint Mobility (dietary supplement)',
  },
  {
    name: 'UNICITY LC Base (dietary supplement)',
    value: 'UNICITY LC Base (dietary supplement)',
  },
  {
    name: 'UNICITY Meno Multi Support (dietary supplement)',
    value: 'UNICITY Meno Multi Support (dietary supplement)',
  },
  {
    name: 'UNICITY OmegaLife-3 (dietary supplement)',
    value: 'UNICITY OmegaLife-3 (dietary supplement)',
  },
  {
    name: 'UNICITY Ubiquinol CoQ10 (dietary supplement)',
    value: 'UNICITY Ubiquinol CoQ10 (dietary supplement)',
  },
  {
    name: 'UNICITY Unimate (dietary supplement)',
    value: 'UNICITY Unimate (dietary supplement)',
  },
  {
    name: 'UNICITY Unimate Fuel (dietary supplement)',
    value: 'UNICITY Unimate Fuel (dietary supplement)',
  },
  {
    name: 'Unisom (doxylamine succinate)',
    value: 'Unisom (doxylamine succinate)',
  },
  {
    name: 'Unithroid (levothyroxine sodium)',
    value: 'Unithroid (levothyroxine sodium)',
  },
  { name: 'Unituxin (dinutuximab)', value: 'Unituxin (dinutuximab)' },
  { name: 'Uptravi (selexipag)', value: 'Uptravi (selexipag)' },
  {
    name: 'Urecholine (bethanechol chloride)',
    value: 'Urecholine (bethanechol chloride)',
  },
  {
    name: 'Uribel (hyoscyamine sulfate/methenamine/methylene blue/phenyl salicylate/sodi etc.',
    value:
      'Uribel (hyoscyamine sulfate/methenamine/methylene blue/phenyl salicylate/sodi etc.',
  },
  {
    name: 'Urocit-K (potassium citrate)',
    value: 'Urocit-K (potassium citrate)',
  },
  {
    name: 'Urogesic-Blue (hyoscyamine sulfate/methenamine/methylene blue/monobasic sodium phosp etc.',
    value:
      'Urogesic-Blue (hyoscyamine sulfate/methenamine/methylene blue/monobasic sodium phosp etc.',
  },
  {
    name: 'Uroxatral (alfuzosin hydrochloride)',
    value: 'Uroxatral (alfuzosin hydrochloride)',
  },
  {
    name: 'Urso 250/Urso Forte (ursodiol)',
    value: 'Urso 250/Urso Forte (ursodiol)',
  },
  {
    name: 'Usana HealthPak (alpha lipoic acid/biotin/boron citrate/calcium/calcium silicate/chole etc.',
    value:
      'Usana HealthPak (alpha lipoic acid/biotin/boron citrate/calcium/calcium silicate/chole etc.',
  },
  {
    name: 'UTA (hyoscyamine sulfate/methenamine/methylene blue/sodium phosphate monob etc.',
    value:
      'UTA (hyoscyamine sulfate/methenamine/methylene blue/sodium phosphate monob etc.',
  },
  {
    name: 'Utibron Neohaler (glycopyrrolate/indacaterol)',
    value: 'Utibron Neohaler (glycopyrrolate/indacaterol)',
  },
  {
    name: 'Utira-C (hyoscyamine sulfate/methenamine/methylene blue/phenyl salicylate/sodi etc.',
    value:
      'Utira-C (hyoscyamine sulfate/methenamine/methylene blue/phenyl salicylate/sodi etc.',
  },
  { name: 'Uvadex (methoxsalen)', value: 'Uvadex (methoxsalen)' },
  {
    name: 'Vabomere (meropenem/vaborbactam)',
    value: 'Vabomere (meropenem/vaborbactam)',
  },
  { name: 'Vagifem (estradiol)', value: 'Vagifem (estradiol)' },
  { name: 'Vagistat-1 (tioconazole)', value: 'Vagistat-1 (tioconazole)' },
  { name: 'Valchlor (mechlorethamine)', value: 'Valchlor (mechlorethamine)' },
  {
    name: 'Valcyte (valganciclovir hydrochloride)',
    value: 'Valcyte (valganciclovir hydrochloride)',
  },
  { name: 'Valium (diazepam)', value: 'Valium (diazepam)' },
  { name: 'Valsartan (valsartan)', value: 'Valsartan (valsartan)' },
  { name: 'Valstar (valrubicin)', value: 'Valstar (valrubicin)' },
  {
    name: 'Valtrex (valacyclovir hydrochloride)',
    value: 'Valtrex (valacyclovir hydrochloride)',
  },
  {
    name: 'Vancocin (vancomycin hydrochloride)',
    value: 'Vancocin (vancomycin hydrochloride)',
  },
  {
    name: 'Vancomycin Hydrochloride for Injection (vancomycin hydrochloride)',
    value: 'Vancomycin Hydrochloride for Injection (vancomycin hydrochloride)',
  },
  { name: 'Vandazole (metronidazole)', value: 'Vandazole (metronidazole)' },
  {
    name: 'Vaniqa (eflornithine hydrochloride)',
    value: 'Vaniqa (eflornithine hydrochloride)',
  },
  { name: 'Vanos (fluocinonide)', value: 'Vanos (fluocinonide)' },
  { name: 'Vantas (histrelin acetate)', value: 'Vantas (histrelin acetate)' },
  {
    name: 'Vaprisol (conivaptan hydrochloride)',
    value: 'Vaprisol (conivaptan hydrochloride)',
  },
  {
    name: 'Vaqta (hepatitis A vaccine, inactivated)',
    value: 'Vaqta (hepatitis A vaccine, inactivated)',
  },
  { name: 'Varithena (polidocanol)', value: 'Varithena (polidocanol)' },
  {
    name: 'Varivax (varicella virus vaccine live)',
    value: 'Varivax (varicella virus vaccine live)',
  },
  {
    name: 'Varizig (varicella zoster immune globulin (human))',
    value: 'Varizig (varicella zoster immune globulin (human))',
  },
  { name: 'Varubi (rolapitant)', value: 'Varubi (rolapitant)' },
  {
    name: 'Vasayo Core Complete (dietary supplement)',
    value: 'Vasayo Core Complete (dietary supplement)',
  },
  {
    name: 'Vasayo Neuro (dietary supplement)',
    value: 'Vasayo Neuro (dietary supplement)',
  },
  {
    name: 'Vasayo Premere (dietary supplement)',
    value: 'Vasayo Premere (dietary supplement)',
  },
  {
    name: 'Vasayo Renew (dietary supplement)',
    value: 'Vasayo Renew (dietary supplement)',
  },
  { name: 'Vascepa (icosapent ethyl)', value: 'Vascepa (icosapent ethyl)' },
  {
    name: 'Vaseretic (enalapril maleate/hydrochlorothiazide)',
    value: 'Vaseretic (enalapril maleate/hydrochlorothiazide)',
  },
  { name: 'Vasostrict (vasopressin)', value: 'Vasostrict (vasopressin)' },
  { name: 'Vasotec (enalapril maleate)', value: 'Vasotec (enalapril maleate)' },
  {
    name: 'Vaxchora (cholera vaccine, live, oral)',
    value: 'Vaxchora (cholera vaccine, live, oral)',
  },
  {
    name: 'Vazculep (phenylephrine hydrochloride)',
    value: 'Vazculep (phenylephrine hydrochloride)',
  },
  { name: 'Vectibix (panitumumab)', value: 'Vectibix (panitumumab)' },
  { name: 'Vectical (calcitriol)', value: 'Vectical (calcitriol)' },
  {
    name: 'Vecuronium Bromide (vecuronium bromide)',
    value: 'Vecuronium Bromide (vecuronium bromide)',
  },
  { name: 'Velcade (bortezomib)', value: 'Velcade (bortezomib)' },
  { name: 'Veletri (epoprostenol)', value: 'Veletri (epoprostenol)' },
  {
    name: 'Velivet (desogestrel/ethinyl estradiol)',
    value: 'Velivet (desogestrel/ethinyl estradiol)',
  },
  {
    name: 'Velphoro (sucroferric oxyhydroxide)',
    value: 'Velphoro (sucroferric oxyhydroxide)',
  },
  { name: 'Veltassa (patiromer)', value: 'Veltassa (patiromer)' },
  {
    name: 'Veltin (clindamycin phosphate/tretinoin)',
    value: 'Veltin (clindamycin phosphate/tretinoin)',
  },
  {
    name: 'Vemlidy (tenofovir alafenamide)',
    value: 'Vemlidy (tenofovir alafenamide)',
  },
  { name: 'Venclexta (venetoclax)', value: 'Venclexta (venetoclax)' },
  {
    name: 'Venlafaxine Hydrochloride Extended Release Tablets (venlafaxine hydrochloride)',
    value:
      'Venlafaxine Hydrochloride Extended Release Tablets (venlafaxine hydrochloride)',
  },
  {
    name: 'Venlafaxine Tablets (venlafaxine hydrochloride)',
    value: 'Venlafaxine Tablets (venlafaxine hydrochloride)',
  },
  { name: 'Venofer (iron sucrose)', value: 'Venofer (iron sucrose)' },
  { name: 'Ventavis (iloprost)', value: 'Ventavis (iloprost)' },
  {
    name: 'Ventolin HFA (albuterol sulfate)',
    value: 'Ventolin HFA (albuterol sulfate)',
  },
  {
    name: 'Veramyst (fluticasone furoate)',
    value: 'Veramyst (fluticasone furoate)',
  },
  {
    name: 'Verapamil Hydrochloride Injection (verapamil hydrochloride)',
    value: 'Verapamil Hydrochloride Injection (verapamil hydrochloride)',
  },
  { name: 'Verdeso (desonide)', value: 'Verdeso (desonide)' },
  { name: 'Veregen (sinecatechins)', value: 'Veregen (sinecatechins)' },
  {
    name: 'Verelan (verapamil hydrochloride)',
    value: 'Verelan (verapamil hydrochloride)',
  },
  {
    name: 'Verelan PM (verapamil hydrochloride)',
    value: 'Verelan PM (verapamil hydrochloride)',
  },
  {
    name: 'Veripred 20 (prednisolone sodium phosphate)',
    value: 'Veripred 20 (prednisolone sodium phosphate)',
  },
  { name: 'Verquvo (vericiguat)', value: 'Verquvo (vericiguat)' },
  { name: 'Versacloz (clozapine)', value: 'Versacloz (clozapine)' },
  { name: 'Verzenio (abemaciclib)', value: 'Verzenio (abemaciclib)' },
  {
    name: 'VESIcare (solifenacin succinate)',
    value: 'VESIcare (solifenacin succinate)',
  },
  { name: 'Vexol (rimexolone)', value: 'Vexol (rimexolone)' },
  { name: 'VFEND (voriconazole)', value: 'VFEND (voriconazole)' },
  { name: 'Viagra (sildenafil citrate)', value: 'Viagra (sildenafil citrate)' },
  { name: 'Vibativ (telavancin)', value: 'Vibativ (telavancin)' },
  { name: 'Viberzi (eluxadoline)', value: 'Viberzi (eluxadoline)' },
  {
    name: 'Vibramycin/Vibra-Tabs (doxycycline calcium); (doxycycline hyclate); (doxycycline monohydrate etc.',
    value:
      'Vibramycin/Vibra-Tabs (doxycycline calcium); (doxycycline hyclate); (doxycycline monohydrate etc.',
  },
  {
    name: 'Vicodin/Vicodin ES/Vicodin HP (acetaminophen/hydrocodone bitartrate)',
    value:
      'Vicodin/Vicodin ES/Vicodin HP (acetaminophen/hydrocodone bitartrate)',
  },
  {
    name: 'Vicoprofen (hydrocodone bitartrate/ibuprofen)',
    value: 'Vicoprofen (hydrocodone bitartrate/ibuprofen)',
  },
  { name: 'Victoza (liraglutide)', value: 'Victoza (liraglutide)' },
  { name: 'Victrelis (boceprevir)', value: 'Victrelis (boceprevir)' },
  { name: 'Vidaza (azacitidine)', value: 'Vidaza (azacitidine)' },
  { name: 'Videx (didanosine)', value: 'Videx (didanosine)' },
  { name: 'Videx EC (didanosine)', value: 'Videx EC (didanosine)' },
  {
    name: 'Viekira Pak (dasabuvir); (ombitasvir/paritaprevir/ritonavir)',
    value: 'Viekira Pak (dasabuvir); (ombitasvir/paritaprevir/ritonavir)',
  },
  {
    name: 'Viekira XR (dasabuvir/ombitasvir/paritaprevir/ritonavir)',
    value: 'Viekira XR (dasabuvir/ombitasvir/paritaprevir/ritonavir)',
  },
  {
    name: 'Vigamox (moxifloxacin hydrochloride)',
    value: 'Vigamox (moxifloxacin hydrochloride)',
  },
  {
    name: 'Viibryd (vilazodone hydrochloride)',
    value: 'Viibryd (vilazodone hydrochloride)',
  },
  { name: 'Vimizim (elosulfase alfa)', value: 'Vimizim (elosulfase alfa)' },
  {
    name: 'Vimovo (esomeprazole magnesium/naproxen)',
    value: 'Vimovo (esomeprazole magnesium/naproxen)',
  },
  { name: 'Vimpat (lacosamide)', value: 'Vimpat (lacosamide)' },
  { name: 'Vinali (dietary supplement)', value: 'Vinali (dietary supplement)' },
  {
    name: 'Vinblastine Sulfate (vinblastine sulfate)',
    value: 'Vinblastine Sulfate (vinblastine sulfate)',
  },
  {
    name: 'Vincristine Sulfate (vincristine sulfate)',
    value: 'Vincristine Sulfate (vincristine sulfate)',
  },
  { name: 'Viokace (pancrelipase)', value: 'Viokace (pancrelipase)' },
  {
    name: 'Viracept (nelfinavir mesylate)',
    value: 'Viracept (nelfinavir mesylate)',
  },
  { name: 'Viramune (nevirapine)', value: 'Viramune (nevirapine)' },
  { name: 'Viramune XR (nevirapine)', value: 'Viramune XR (nevirapine)' },
  { name: 'Virasal (salicylic acid)', value: 'Virasal (salicylic acid)' },
  { name: 'Virazole (ribavirin)', value: 'Virazole (ribavirin)' },
  {
    name: 'Viread (tenofovir disoproxil fumarate)',
    value: 'Viread (tenofovir disoproxil fumarate)',
  },
  { name: 'Viroptic (trifluridine)', value: 'Viroptic (trifluridine)' },
  {
    name: 'Visine Tears Long Lasting Dry Eye Relief (glycerin/hypromellose/polyethylene glycol 400)',
    value:
      'Visine Tears Long Lasting Dry Eye Relief (glycerin/hypromellose/polyethylene glycol 400)',
  },
  {
    name: 'Visionex (bilberry extract/lutein/vitamin C/zeaxanthin/zinc citrate)',
    value:
      'Visionex (bilberry extract/lutein/vitamin C/zeaxanthin/zinc citrate)',
  },
  { name: 'Visipaque (iodixanol)', value: 'Visipaque (iodixanol)' },
  {
    name: 'Vistaril (hydroxyzine pamoate)',
    value: 'Vistaril (hydroxyzine pamoate)',
  },
  { name: 'Vistide (cidofovir)', value: 'Vistide (cidofovir)' },
  {
    name: 'Vistogard (uridine triacetate)',
    value: 'Vistogard (uridine triacetate)',
  },
  { name: 'Visudyne (verteporfin)', value: 'Visudyne (verteporfin)' },
  {
    name: 'Vitafol-OB (calcium carbonate/copper oxide/ferrous fumarate/folic acid/magnesium etc.',
    value:
      'Vitafol-OB (calcium carbonate/copper oxide/ferrous fumarate/folic acid/magnesium etc.',
  },
  {
    name: 'Vitafol-OB + DHA (calcium carbonate/copper oxide/docosahexaenoic acid (DHA)/ferrous fum etc.',
    value:
      'Vitafol-OB + DHA (calcium carbonate/copper oxide/docosahexaenoic acid (DHA)/ferrous fum etc.',
  },
  { name: 'Vitekta (elvitegravir)', value: 'Vitekta (elvitegravir)' },
  { name: 'Vitrakvi (larotrectinib)', value: 'Vitrakvi (larotrectinib)' },
  { name: 'Vitrase (hyaluronidase)', value: 'Vitrase (hyaluronidase)' },
  {
    name: 'Vituz (chlorpheniramine maleate/hydrocodone bitartrate)',
    value: 'Vituz (chlorpheniramine maleate/hydrocodone bitartrate)',
  },
  { name: 'Vivelle-Dot (estradiol)', value: 'Vivelle-Dot (estradiol)' },
  { name: 'Vivitrol (naltrexone)', value: 'Vivitrol (naltrexone)' },
  { name: 'Vivlodex (meloxicam)', value: 'Vivlodex (meloxicam)' },
  {
    name: 'Vivotif (typhoid vaccine live oral Ty21a)',
    value: 'Vivotif (typhoid vaccine live oral Ty21a)',
  },
  { name: 'Vizimpro (dacomitinib)', value: 'Vizimpro (dacomitinib)' },
  { name: 'Vogelxo (testosterone)', value: 'Vogelxo (testosterone)' },
  {
    name: 'Voltaren Gel (diclofenac sodium)',
    value: 'Voltaren Gel (diclofenac sodium)',
  },
  {
    name: 'Voltaren XR (diclofenac sodium)',
    value: 'Voltaren XR (diclofenac sodium)',
  },
  {
    name: 'Voluven (hydroxyethyl starch 130/0.4/sodium chloride)',
    value: 'Voluven (hydroxyethyl starch 130/0.4/sodium chloride)',
  },
  {
    name: 'Vonvendi (von willebrand factor (recombinant))',
    value: 'Vonvendi (von willebrand factor (recombinant))',
  },
  { name: 'Voraxaze (glucarpidase)', value: 'Voraxaze (glucarpidase)' },
  {
    name: 'Vosevi (sofosbuvir/velpatasvir/voxilaprevir)',
    value: 'Vosevi (sofosbuvir/velpatasvir/voxilaprevir)',
  },
  {
    name: 'VoSpire ER (albuterol sulfate)',
    value: 'VoSpire ER (albuterol sulfate)',
  },
  { name: 'Votrient (pazopanib)', value: 'Votrient (pazopanib)' },
  { name: 'Vpriv (velaglucerase alfa)', value: 'Vpriv (velaglucerase alfa)' },
  { name: 'Vraylar (cariprazine)', value: 'Vraylar (cariprazine)' },
  { name: 'VTAMA (tapinarof)', value: 'VTAMA (tapinarof)' },
  {
    name: 'Vuity (pilocarpine hydrochloride)',
    value: 'Vuity (pilocarpine hydrochloride)',
  },
  {
    name: 'Vumerity (diroximel fumarate)',
    value: 'Vumerity (diroximel fumarate)',
  },
  {
    name: 'Vusion (miconazole nitrate/white petrolatum/zinc oxide)',
    value: 'Vusion (miconazole nitrate/white petrolatum/zinc oxide)',
  },
  {
    name: 'Vyndaqel, Vyndamax (tafamidis); (tafamidis meglumine)',
    value: 'Vyndaqel, Vyndamax (tafamidis); (tafamidis meglumine)',
  },
  {
    name: 'Vytone (hydrocortisone acetate/iodoquinol)',
    value: 'Vytone (hydrocortisone acetate/iodoquinol)',
  },
  {
    name: 'Vytorin (ezetimibe/simvastatin)',
    value: 'Vytorin (ezetimibe/simvastatin)',
  },
  {
    name: 'Vyvanse (lisdexamfetamine dimesylate)',
    value: 'Vyvanse (lisdexamfetamine dimesylate)',
  },
  {
    name: 'Vyxeos (daunorubicin/cytarabine)',
    value: 'Vyxeos (daunorubicin/cytarabine)',
  },
  {
    name: 'Vyzulta (latanoprostene bunod)',
    value: 'Vyzulta (latanoprostene bunod)',
  },
  { name: 'Warfarin (warfarin sodium)', value: 'Warfarin (warfarin sodium)' },
  {
    name: 'Wayal NMN5G (dietary supplement)',
    value: 'Wayal NMN5G (dietary supplement)',
  },
  {
    name: 'Wayal Poten5X (dietary supplement)',
    value: 'Wayal Poten5X (dietary supplement)',
  },
  {
    name: 'Welchol (colesevelam hydrochloride)',
    value: 'Welchol (colesevelam hydrochloride)',
  },
  {
    name: 'Wellbutrin (bupropion hydrochloride)',
    value: 'Wellbutrin (bupropion hydrochloride)',
  },
  {
    name: 'Wellbutrin SR (bupropion hydrochloride)',
    value: 'Wellbutrin SR (bupropion hydrochloride)',
  },
  {
    name: 'Wellbutrin XL (bupropion hydrochloride)',
    value: 'Wellbutrin XL (bupropion hydrochloride)',
  },
  {
    name: 'Wilate (coagulation factor VIII complex (human)/von Willebrand factor (human) etc.',
    value:
      'Wilate (coagulation factor VIII complex (human)/von Willebrand factor (human) etc.',
  },
  {
    name: 'WinRho SDF (rho (D) immune globulin intravenous (human))',
    value: 'WinRho SDF (rho (D) immune globulin intravenous (human))',
  },
  {
    name: 'Wymzya Fe (ethinyl estradiol/ferrous fumarate/norethindrone)',
    value: 'Wymzya Fe (ethinyl estradiol/ferrous fumarate/norethindrone)',
  },
  { name: 'Xadago (safinamide)', value: 'Xadago (safinamide)' },
  { name: 'Xalatan (latanoprost)', value: 'Xalatan (latanoprost)' },
  { name: 'Xalkori (crizotinib)', value: 'Xalkori (crizotinib)' },
  { name: 'Xanax (alprazolam)', value: 'Xanax (alprazolam)' },
  { name: 'Xanax XR (alprazolam)', value: 'Xanax XR (alprazolam)' },
  { name: 'Xarelto (rivaroxaban)', value: 'Xarelto (rivaroxaban)' },
  {
    name: 'Xartemis XR (acetaminophen/oxycodone hydrochloride)',
    value: 'Xartemis XR (acetaminophen/oxycodone hydrochloride)',
  },
  { name: 'Xatmep (methotrexate)', value: 'Xatmep (methotrexate)' },
  {
    name: 'Xeljanz/Xeljanz XR (tofacitinib)',
    value: 'Xeljanz/Xeljanz XR (tofacitinib)',
  },
  { name: 'Xeloda (capecitabine)', value: 'Xeloda (capecitabine)' },
  { name: 'Xelpros (latanoprost)', value: 'Xelpros (latanoprost)' },
  { name: 'Xenazine (tetrabenazine)', value: 'Xenazine (tetrabenazine)' },
  { name: 'Xenical (orlistat)', value: 'Xenical (orlistat)' },
  {
    name: 'Xeomin (incobotulinumtoxinA)',
    value: 'Xeomin (incobotulinumtoxinA)',
  },
  {
    name: 'Xerese (acyclovir/hydrocortisone)',
    value: 'Xerese (acyclovir/hydrocortisone)',
  },
  { name: 'Xermelo (telotristat ethyl)', value: 'Xermelo (telotristat ethyl)' },
  { name: 'Xgeva (denosumab)', value: 'Xgeva (denosumab)' },
  {
    name: 'Xiaflex (collagenase clostridium histolyticum)',
    value: 'Xiaflex (collagenase clostridium histolyticum)',
  },
  { name: 'Xifaxan (rifaximin)', value: 'Xifaxan (rifaximin)' },
  {
    name: 'Xigduo XR (dapagliflozin/metformin hydrochloride)',
    value: 'Xigduo XR (dapagliflozin/metformin hydrochloride)',
  },
  { name: 'Xiidra (lifitegrast)', value: 'Xiidra (lifitegrast)' },
  {
    name: 'Ximino (minocycline hydrochloride)',
    value: 'Ximino (minocycline hydrochloride)',
  },
  {
    name: 'Xofigo (radium Ra 223 dichloride)',
    value: 'Xofigo (radium Ra 223 dichloride)',
  },
  {
    name: 'Xofluza (baloxavir marboxil)',
    value: 'Xofluza (baloxavir marboxil)',
  },
  { name: 'Xolair (omalizumab)', value: 'Xolair (omalizumab)' },
  { name: 'Xolegel (ketoconazole)', value: 'Xolegel (ketoconazole)' },
  {
    name: 'Xopenex HFA (levalbuterol tartrate)',
    value: 'Xopenex HFA (levalbuterol tartrate)',
  },
  {
    name: 'Xopenex Inhalation Solution (levalbuterol hydrochloride)',
    value: 'Xopenex Inhalation Solution (levalbuterol hydrochloride)',
  },
  {
    name: 'Xopenex Inhalation Solution Concentrate (levalbuterol hydrochloride)',
    value:
      'Xopenex Inhalation Solution Concentrate (levalbuterol hydrochloride)',
  },
  { name: 'Xospata (gilteritinib)', value: 'Xospata (gilteritinib)' },
  { name: 'Xtampza ER (oxycodone)', value: 'Xtampza ER (oxycodone)' },
  { name: 'Xtandi (enzalutamide)', value: 'Xtandi (enzalutamide)' },
  {
    name: 'Xulane (ethinyl estradiol/norelgestromin)',
    value: 'Xulane (ethinyl estradiol/norelgestromin)',
  },
  {
    name: 'Xultophy 100/3.6 (insulin degludec/liraglutide)',
    value: 'Xultophy 100/3.6 (insulin degludec/liraglutide)',
  },
  {
    name: 'Xuriden (uridine triacetate)',
    value: 'Xuriden (uridine triacetate)',
  },
  {
    name: 'Xylocaine Dental with Epinephrine (lidocaine hydrochloride/epinephrine)',
    value:
      'Xylocaine Dental with Epinephrine (lidocaine hydrochloride/epinephrine)',
  },
  {
    name: 'Xylocaine Injection (lidocaine hydrochloride)',
    value: 'Xylocaine Injection (lidocaine hydrochloride)',
  },
  {
    name: 'Xylocaine Topical Solution (lidocaine hydrochloride)',
    value: 'Xylocaine Topical Solution (lidocaine hydrochloride)',
  },
  {
    name: 'Xylocaine/Xylocaine with Epinephrine/Xylocaine-MPF/Xylocaine-MFP with Epinephrine (lidocaine hydrochloride); (lidocaine hydrochloride/epinephrine)',
    value:
      'Xylocaine/Xylocaine with Epinephrine/Xylocaine-MPF/Xylocaine-MFP with Epinephrine (lidocaine hydrochloride); (lidocaine hydrochloride/epinephrine)',
  },
  {
    name: 'Xyntha Solofuse (antihemophilic factor (recombinant))',
    value: 'Xyntha Solofuse (antihemophilic factor (recombinant))',
  },
  {
    name: 'Xyntha Vials (antihemophilic factor (recombinant))',
    value: 'Xyntha Vials (antihemophilic factor (recombinant))',
  },
  { name: 'Xyrem (sodium oxybate)', value: 'Xyrem (sodium oxybate)' },
  {
    name: 'Xyzal (levocetirizine dihydrochloride)',
    value: 'Xyzal (levocetirizine dihydrochloride)',
  },
  {
    name: 'Xyzal Allergy 24HR (levocetirizine dihydrochloride)',
    value: 'Xyzal Allergy 24HR (levocetirizine dihydrochloride)',
  },
  {
    name: 'Yasmin (drospirenone/ethinyl estradiol)',
    value: 'Yasmin (drospirenone/ethinyl estradiol)',
  },
  {
    name: 'Yaz (drospirenone/ethinyl estradiol)',
    value: 'Yaz (drospirenone/ethinyl estradiol)',
  },
  { name: 'Yervoy (ipilimumab)', value: 'Yervoy (ipilimumab)' },
  {
    name: 'Yescarta (axicabtagene ciloleucel)',
    value: 'Yescarta (axicabtagene ciloleucel)',
  },
  {
    name: 'YF-Vax (yellow fever vaccine)',
    value: 'YF-Vax (yellow fever vaccine)',
  },
  { name: 'Yondelis (trabectedin)', value: 'Yondelis (trabectedin)' },
  {
    name: 'Yosprala (aspirin/omeprazole)',
    value: 'Yosprala (aspirin/omeprazole)',
  },
  { name: 'Yupelri (revefenacin)', value: 'Yupelri (revefenacin)' },
  {
    name: 'Yutiq (fluocinolone acetonide intravitreal implant)',
    value: 'Yutiq (fluocinolone acetonide intravitreal implant)',
  },
  { name: 'Yuvafem (estradiol)', value: 'Yuvafem (estradiol)' },
  {
    name: 'Zaditor (ketotifen fumarate)',
    value: 'Zaditor (ketotifen fumarate)',
  },
  { name: 'Zaltrap (ziv-aflibercept)', value: 'Zaltrap (ziv-aflibercept)' },
  {
    name: 'Zanaflex (tizanidine hydrochloride)',
    value: 'Zanaflex (tizanidine hydrochloride)',
  },
  { name: 'Zanosar (streptozocin)', value: 'Zanosar (streptozocin)' },
  {
    name: 'Zantac 150 and 300 Tablets (ranitidine hydrochloride)',
    value: 'Zantac 150 and 300 Tablets (ranitidine hydrochloride)',
  },
  {
    name: 'Zantac Injection (ranitidine hydrochloride)',
    value: 'Zantac Injection (ranitidine hydrochloride)',
  },
  {
    name: 'Zarah (drospirenone/ethinyl estradiol)',
    value: 'Zarah (drospirenone/ethinyl estradiol)',
  },
  {
    name: 'Zarontin Capsules (ethosuximide)',
    value: 'Zarontin Capsules (ethosuximide)',
  },
  {
    name: 'Zarontin Oral Solution (ethosuximide)',
    value: 'Zarontin Oral Solution (ethosuximide)',
  },
  { name: 'Zarxio (filgrastim-sndz)', value: 'Zarxio (filgrastim-sndz)' },
  { name: 'Zavesca (miglustat)', value: 'Zavesca (miglustat)' },
  {
    name: 'Zeasorb (miconazole nitrate)',
    value: 'Zeasorb (miconazole nitrate)',
  },
  {
    name: 'Zebeta (bisoprolol fumarate)',
    value: 'Zebeta (bisoprolol fumarate)',
  },
  {
    name: 'Zebutal (acetaminophen/butalbital/caffeine)',
    value: 'Zebutal (acetaminophen/butalbital/caffeine)',
  },
  { name: 'Zecuity (sumatriptan)', value: 'Zecuity (sumatriptan)' },
  {
    name: 'Zegerid (omeprazole/sodium bicarbonate)',
    value: 'Zegerid (omeprazole/sodium bicarbonate)',
  },
  {
    name: 'Zegerid OTC (omeprazole/sodium bicarbonate)',
    value: 'Zegerid OTC (omeprazole/sodium bicarbonate)',
  },
  { name: 'Zejula (niraparib)', value: 'Zejula (niraparib)' },
  {
    name: 'Zelapar (selegiline hydrochloride)',
    value: 'Zelapar (selegiline hydrochloride)',
  },
  { name: 'Zelboraf (vemurafenib)', value: 'Zelboraf (vemurafenib)' },
  {
    name: 'Zemaira (alpha1-proteinase inhibitor (human))',
    value: 'Zemaira (alpha1-proteinase inhibitor (human))',
  },
  {
    name: 'Zembrace SymTouch (sumatriptan succinate)',
    value: 'Zembrace SymTouch (sumatriptan succinate)',
  },
  { name: 'Zemdri (plazomicin)', value: 'Zemdri (plazomicin)' },
  {
    name: 'Zemplar Capsules (paricalcitol)',
    value: 'Zemplar Capsules (paricalcitol)',
  },
  {
    name: 'Zemplar Injection (paricalcitol)',
    value: 'Zemplar Injection (paricalcitol)',
  },
  {
    name: 'Zenchent (ethinyl estradiol/norethindrone)',
    value: 'Zenchent (ethinyl estradiol/norethindrone)',
  },
  {
    name: 'Zenchent Fe (ethinyl estradiol/ferrous fumarate/norethindrone)',
    value: 'Zenchent Fe (ethinyl estradiol/ferrous fumarate/norethindrone)',
  },
  { name: 'Zenpep (pancrelipase)', value: 'Zenpep (pancrelipase)' },
  {
    name: 'Zenzedi (dextroamphetamine sulfate)',
    value: 'Zenzedi (dextroamphetamine sulfate)',
  },
  {
    name: 'Zepatier (elbasvir/grazoprevir)',
    value: 'Zepatier (elbasvir/grazoprevir)',
  },
  {
    name: 'Zerbaxa (ceftolozane/tazobactam)',
    value: 'Zerbaxa (ceftolozane/tazobactam)',
  },
  { name: 'Zerit (stavudine)', value: 'Zerit (stavudine)' },
  {
    name: 'Zestoretic (hydrochlorothiazide/lisinopril)',
    value: 'Zestoretic (hydrochlorothiazide/lisinopril)',
  },
  { name: 'Zestril (lisinopril)', value: 'Zestril (lisinopril)' },
  { name: 'Zetia (ezetimibe)', value: 'Zetia (ezetimibe)' },
  { name: 'Zetonna (ciclesonide)', value: 'Zetonna (ciclesonide)' },
  {
    name: 'Zevalin (ibritumomab tiuxetan)',
    value: 'Zevalin (ibritumomab tiuxetan)',
  },
  {
    name: 'Ziac (bisoprolol fumarate/hydrochlorothiazide)',
    value: 'Ziac (bisoprolol fumarate/hydrochlorothiazide)',
  },
  { name: 'Ziagen (abacavir)', value: 'Ziagen (abacavir)' },
  {
    name: 'Ziana (clindamycin phosphate/tretinoin)',
    value: 'Ziana (clindamycin phosphate/tretinoin)',
  },
  { name: 'Zidovudine (zidovudine)', value: 'Zidovudine (zidovudine)' },
  { name: 'Zinacef (cefuroxime)', value: 'Zinacef (cefuroxime)' },
  { name: 'Zinbryta (daclizumab)', value: 'Zinbryta (daclizumab)' },
  { name: 'Zinecard (dexrazoxane)', value: 'Zinecard (dexrazoxane)' },
  { name: 'Zinplava (bezlotoxumab)', value: 'Zinplava (bezlotoxumab)' },
  { name: 'Zioptan (tafluprost)', value: 'Zioptan (tafluprost)' },
  {
    name: 'Zipsor (diclofenac potassium)',
    value: 'Zipsor (diclofenac potassium)',
  },
  { name: 'Zirgan (ganciclovir)', value: 'Zirgan (ganciclovir)' },
  { name: 'Zithranol-RR (anthralin)', value: 'Zithranol-RR (anthralin)' },
  {
    name: 'Zithromax 250 mg and 500 mg Tablets and Oral Suspension (azithromycin)',
    value:
      'Zithromax 250 mg and 500 mg Tablets and Oral Suspension (azithromycin)',
  },
  {
    name: 'Zithromax for Injection (azithromycin)',
    value: 'Zithromax for Injection (azithromycin)',
  },
  {
    name: 'ZJ1 NMN 12000 (dietary supplement)',
    value: 'ZJ1 NMN 12000 (dietary supplement)',
  },
  { name: 'Zmax (azithromycin)', value: 'Zmax (azithromycin)' },
  { name: 'Zocor (simvastatin)', value: 'Zocor (simvastatin)' },
  {
    name: 'Zofran Injection (ondansetron hydrochloride)',
    value: 'Zofran Injection (ondansetron hydrochloride)',
  },
  {
    name: 'Zofran ODT Orally Disintegrating Tablets, Oral Solution, and Tablets (ondansetron); (ondansetron hydrochloride)',
    value:
      'Zofran ODT Orally Disintegrating Tablets, Oral Solution, and Tablets (ondansetron); (ondansetron hydrochloride)',
  },
  {
    name: 'Zohydro ER (hydrocodone bitartrate)',
    value: 'Zohydro ER (hydrocodone bitartrate)',
  },
  {
    name: 'Zoladex Implant 10.8 mg (goserelin acetate)',
    value: 'Zoladex Implant 10.8 mg (goserelin acetate)',
  },
  {
    name: 'Zoladex Implant 3.6 mg (goserelin acetate)',
    value: 'Zoladex Implant 3.6 mg (goserelin acetate)',
  },
  { name: 'Zolinza (vorinostat)', value: 'Zolinza (vorinostat)' },
  {
    name: 'Zoloft (sertraline hydrochloride)',
    value: 'Zoloft (sertraline hydrochloride)',
  },
  {
    name: 'Zolpimist (zolpidem tartrate)',
    value: 'Zolpimist (zolpidem tartrate)',
  },
  {
    name: 'Zomacton (somatropin (rDNA origin))',
    value: 'Zomacton (somatropin (rDNA origin))',
  },
  { name: 'Zometa (zoledronic acid)', value: 'Zometa (zoledronic acid)' },
  {
    name: 'Zomig Nasal Spray (zolmitriptan)',
    value: 'Zomig Nasal Spray (zolmitriptan)',
  },
  {
    name: 'Zomig/Zomig-ZMT (zolmitriptan)',
    value: 'Zomig/Zomig-ZMT (zolmitriptan)',
  },
  {
    name: 'Zonalon (doxepin hydrochloride)',
    value: 'Zonalon (doxepin hydrochloride)',
  },
  { name: 'Zonegran (zonisamide)', value: 'Zonegran (zonisamide)' },
  { name: 'Zonisamide (zonisamide)', value: 'Zonisamide (zonisamide)' },
  { name: 'Zontivity (vorapaxar)', value: 'Zontivity (vorapaxar)' },
  {
    name: 'Zorbtive (somatropin (rDNA origin))',
    value: 'Zorbtive (somatropin (rDNA origin))',
  },
  { name: 'Zortress (everolimus)', value: 'Zortress (everolimus)' },
  { name: 'Zorvolex (diclofenac)', value: 'Zorvolex (diclofenac)' },
  {
    name: 'Zostavax (zoster vaccine live)',
    value: 'Zostavax (zoster vaccine live)',
  },
  {
    name: 'Zosyn (piperacillin/tazobactam)',
    value: 'Zosyn (piperacillin/tazobactam)',
  },
  {
    name: 'Zovia 1/35 (ethinyl estradiol/ethynodiol diacetate)',
    value: 'Zovia 1/35 (ethinyl estradiol/ethynodiol diacetate)',
  },
  {
    name: 'Zovia 1/50E-28 (ethinyl estradiol/ethynodiol diacetate)',
    value: 'Zovia 1/50E-28 (ethinyl estradiol/ethynodiol diacetate)',
  },
  {
    name: 'Zovirax Capsules, Suspension, and Tablets (acyclovir)',
    value: 'Zovirax Capsules, Suspension, and Tablets (acyclovir)',
  },
  { name: 'Zovirax Cream (acyclovir)', value: 'Zovirax Cream (acyclovir)' },
  {
    name: 'Zovirax Ointment (acyclovir)',
    value: 'Zovirax Ointment (acyclovir)',
  },
  {
    name: 'Zubsolv (buprenorphine/naloxone)',
    value: 'Zubsolv (buprenorphine/naloxone)',
  },
  { name: 'Zuplenz (ondansetron)', value: 'Zuplenz (ondansetron)' },
  { name: 'Zurampic (lesinurad)', value: 'Zurampic (lesinurad)' },
  {
    name: 'Zutripro (chlorpheniramine maleate/hydrocodone bitartrate/pseudoephedrine hydro etc.',
    value:
      'Zutripro (chlorpheniramine maleate/hydrocodone bitartrate/pseudoephedrine hydro etc.',
  },
  {
    name: 'Zyban (bupropion hydrochloride)',
    value: 'Zyban (bupropion hydrochloride)',
  },
  { name: 'Zyclara (imiquimod)', value: 'Zyclara (imiquimod)' },
  { name: 'Zydelig (idelalisib)', value: 'Zydelig (idelalisib)' },
  { name: 'Zyflo (zileuton)', value: 'Zyflo (zileuton)' },
  { name: 'Zyflo CR (zileuton)', value: 'Zyflo CR (zileuton)' },
  { name: 'Zykadia (ceritinib)', value: 'Zykadia (ceritinib)' },
  {
    name: 'Zylet (loteprednol etabonate/tobramycin)',
    value: 'Zylet (loteprednol etabonate/tobramycin)',
  },
  { name: 'Zyloprim (allopurinol)', value: 'Zyloprim (allopurinol)' },
  { name: 'Zymaxid (gatifloxacin)', value: 'Zymaxid (gatifloxacin)' },
  { name: 'Zyprexa (olanzapine)', value: 'Zyprexa (olanzapine)' },
  {
    name: 'Zyprexa Relprevv (olanzapine)',
    value: 'Zyprexa Relprevv (olanzapine)',
  },
  {
    name: 'Zyrtec Liquid Gels (cetirizine hydrochloride)',
    value: 'Zyrtec Liquid Gels (cetirizine hydrochloride)',
  },
  {
    name: 'Zyrtec Tablets (cetirizine hydrochloride)',
    value: 'Zyrtec Tablets (cetirizine hydrochloride)',
  },
  {
    name: 'Zyrtec-D (cetirizine hydrochloride/pseudoephedrine hydrochloride)',
    value: 'Zyrtec-D (cetirizine hydrochloride/pseudoephedrine hydrochloride)',
  },
  {
    name: 'Zytiga (abiraterone acetate)',
    value: 'Zytiga (abiraterone acetate)',
  },
  { name: 'Zyvox (linezolid)', value: 'Zyvox (linezolid)' },
  { name: 'Other', value: 'Other' },
]
