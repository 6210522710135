import { Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import CustomStepper from 'src/Components/Application/CustomStepper'
import DisabilityBackground from './DisabilityBackground'
import DisabilityEmployment from './DisabilityEmployment'
import PayoutsSettlements from './PayoutsSettlements'
import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { setDisabilityDetailsStep } from 'src/Redux/Slices/ApplicationStore/ApplicationStepStore'

const DisabilityDetails = ({
  chatForm,
  setChatForm,
}: {
  chatForm?: any
  setChatForm?: any
}) => {
  const formComponents = [
    {
      title: 'Disability & Background',
      content: DisabilityBackground,
    },
    {
      title: 'Disability & Employment',
      content: DisabilityEmployment,
    },
    {
      title: 'Payouts Settlements',
      content: PayoutsSettlements,
    },
  ]

  const dispatch = useAppDispatch()
  const location = useLocation()
  const currentPath = location.pathname
  const myRef = useRef<HTMLDivElement>(null)
  const totalSteps = formComponents.length

  const DisabilityDetailsStep = useAppSelector(
    (state) => state?.applicationStore?.ApplicationStep?.DisabilityDetailsStep,
  )

  const [activeStep, setActiveStep] = useState(
    DisabilityDetailsStep ? parseInt(DisabilityDetailsStep) : 0,
  )

  useEffect(() => {
    dispatch(setDisabilityDetailsStep(activeStep.toString()))
  }, [activeStep, currentPath])

  useEffect(() => {
    setChatForm(false)
  }, [])

  const handleNext = () => {
    setChatForm(false)
    setActiveStep((prevActiveStep) =>
      prevActiveStep < totalSteps - 1 ? prevActiveStep + 1 : prevActiveStep,
    )
    myRef?.current?.scrollIntoView()
  }

  const handleBack = () => {
    setChatForm(false)
    setActiveStep((prevActiveStep) =>
      prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep,
    )
    myRef?.current?.scrollIntoView()
  }

  const FormToShow = formComponents[activeStep].content

  return (
    <>
      <CustomStepper
        myRef={myRef}
        formComponents={formComponents}
        activeStep={activeStep}
        handleNext={handleNext}
        handleBack={handleBack}
      />
      <Box>
        {process.env.REACT_APP_MILESTONE_4 && FormToShow && (
          <FormToShow
            chatForm={chatForm}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        )}
      </Box>
    </>
  )
}

export default DisabilityDetails
