import React, { useState } from 'react'
import TextInput from 'src/Components/Application/CustomInputs/TextInput'
import {
  Box,
  Grid,
  Typography,
  DialogActions,
  IconButton,
  InputAdornment,
  TextField,
  Button,
} from '@mui/material'
import styles from '../../index.module.scss'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { FW_SEMIMEDIUM } from 'src/Utils/Constants'
import Style from '../stepper.module.scss'
import { useAppSelector, useAppDispatch } from 'src/Redux/hooks'
import {
  AddOrganizationContactInfo,
  SetAddOrganization,
  SetAddOrganizationValue,
} from 'src/Redux/Slices/orgSlice'
interface PopupFormProps {
  handleNext: () => void
  handleBack: () => void
}
const PopupForm: React.FC<PopupFormProps> = ({ handleNext, handleBack }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)

  const itemStyle = {
    xs: 12,
    sm: 12,
    md: 12,
  }
  const dispatch = useAppDispatch()

  const { addOrganization } = useAppSelector((state) => state.organization)
  const [errors, setErrors] = useState({
    contactFirstName: '',
    contactLastName: '',
    contactEmailId: '',
    contactPassword: '',
    contactRepeatPassword: '',
  })

  const marginStyle = {
    mr: { md: 2, xs: 2 },
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword)
  }
  const calculateStrength = (password: string) => {
    let strength = 0
    if (password.length > 5) strength++
    if (password.match(/[a-z]+/) && password.match(/[A-Z]+/)) strength++
    if (password.match(/[0-9]+/)) strength++
    if (password.match(/[$@#&!]+/)) strength++
    return strength
  }

  const strength = calculateStrength(addOrganization?.contactPassword)

  const handleValidation = () => {
    const errorsObj = { ...errors }
    // Validating business name, description, street address, state, type, specialization, and tracking name
    errorsObj.contactFirstName = addOrganization.contactFirstName
      ? ''
      : 'Contact first name is required'
    errorsObj.contactLastName = addOrganization.contactLastName
      ? ''
      : 'Contact last name is required'
    errorsObj.contactPassword = addOrganization.contactPassword
      ? /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/.test(
          addOrganization.contactPassword,
        )
        ? ''
        : 'Password must be 8 or more characters long and include at least one letter, one number, and one special character.'
      : 'Password is required'

    errorsObj.contactRepeatPassword = addOrganization.contactRepeatPassword
      ? addOrganization.contactRepeatPassword ===
        addOrganization.contactPassword
        ? ''
        : 'Repeat password must match the password.'
      : 'Contact repeat password is required'

    if (!addOrganization.contactEmailId) {
      errorsObj.contactEmailId = 'Email Id is required'
    } else if (
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(addOrganization.contactEmailId)
    ) {
      errorsObj.contactEmailId = 'Invalid Email ID'
    } else {
      errorsObj.contactEmailId = ''
    }
    setErrors(errorsObj)

    return errorsObj
  }

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { id, value } = event.target
    const key = id
    dispatch(
      SetAddOrganizationValue({
        key,
        value,
      }),
    )
  }

  const areAllErrorsEmpty = (formErrors) => {
    for (const key in formErrors) {
      if (
        Object.prototype.hasOwnProperty.call(formErrors, key) &&
        formErrors[key] !== ''
      ) {
        return false // If any key has a non-empty value, return false
      }
    }
    return true // If all keys have empty values, return true
  }
  const handleSubmit = () => {
    const formErrors = handleValidation()

    const allErrorsEmpty = areAllErrorsEmpty(formErrors)
    if (allErrorsEmpty) {
      dispatch(SetAddOrganization(addOrganization))

      dispatch(
        AddOrganizationContactInfo({
          ...addOrganization,
          uuid: addOrganization.uuid,
        }),
      )
      handleNext()
    }
  }
  return (
    <>
      <Box>
        <form
        // onSubmit={handleSubmit}
        >
          <Grid>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography className='stepNumber'>STEP 2 OF 2</Typography>
            </Grid>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Contact Details
              </Typography>
            </Grid>
          </Grid>

          <Grid container className='form-row-container'>
            <Grid item md={6} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  label={'First Name'}
                  required={true}
                  placeholder={'First Name'}
                  id='contactFirstName'
                  onChange={handleTextChange}
                  value={addOrganization.contactFirstName}
                  error={!!errors.contactFirstName}
                  helperText={
                    errors.contactFirstName ? errors.contactFirstName : ''
                  }
                />
              </Grid>
            </Grid>
            <Grid item md={6} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  label={'Last name'}
                  placeholder={'Last name'}
                  id='contactLastName'
                  onChange={handleTextChange}
                  value={addOrganization.contactLastName}
                  error={!!errors.contactLastName}
                  helperText={
                    errors.contactLastName ? errors.contactLastName : ''
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className='form-row-container'>
            <Grid item md={12} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <TextInput
                  label={'Email'}
                  required={true}
                  placeholder={'Email'}
                  id='contactEmailId'
                  onChange={handleTextChange}
                  value={addOrganization.contactEmailId}
                  error={!!errors.contactEmailId}
                  helperText={
                    errors.contactEmailId ? errors.contactEmailId : ''
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className='form-row-container'>
            <Grid item md={12} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <Box className={styles.flexStartBox}>
                  <Typography variant='h3' fontWeight={FW_SEMIMEDIUM}>
                    Password
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant='outlined'
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge='end'
                          >
                            {!showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={styles.field}
                    // onChange={(e) => {
                    //   setPassword(e.target.value)
                    // }}
                    placeholder='Enter your password'
                    // value={password}
                    id='contactPassword'
                    onChange={handleTextChange}
                    value={addOrganization.contactPassword}
                    error={!!errors.contactPassword}
                  />
                  <Box className={styles.validationBar}>
                    <Box
                      className={
                        strength >= 1 ? styles.validatorred : styles.validator
                      }
                    />
                    <Box
                      className={
                        strength >= 2
                          ? styles.validatororange
                          : styles.validator
                      }
                    />
                    <Box
                      className={
                        strength >= 3
                          ? styles.validatoryellow
                          : styles.validator
                      }
                    />
                    <Box
                      className={
                        strength >= 4 ? styles.validatorgreen : styles.validator
                      }
                    />
                  </Box>
                  <Box className={styles.flexSpaceBox}>
                    {errors.contactPassword && (
                      <Typography className={styles.useOrMore}>
                        {errors.contactPassword}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className='form-row-container'>
            <Grid item md={12} xs={12} className='form-row-item'>
              <Grid item {...itemStyle} {...marginStyle}>
                <Box className={styles.flexStartBox}>
                  <Typography variant='h3' fontWeight={FW_SEMIMEDIUM}>
                    Repeat Password
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    variant='outlined'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={toggleRepeatPasswordVisibility}
                            edge='end'
                          >
                            {!showRepeatPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    type={showRepeatPassword ? 'text' : 'password'}
                    id='contactRepeatPassword'
                    onChange={handleTextChange}
                    value={addOrganization.contactRepeatPassword}
                    error={!!errors.contactRepeatPassword}
                    className={styles.field}
                    placeholder='Repeat your password'
                  />
                  <Box className={styles.flexSpaceBox}>
                    {errors.contactRepeatPassword && (
                      <Typography className={styles.useOrMore}>
                        {errors.contactRepeatPassword}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <DialogActions></DialogActions>
        </form>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          color='inherit'
          onClick={handleBack}
          className={Style.PrevButton}
          sx={{ mr: 1 }}
        >
          Previous
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />

        <>
          <Button
            onClick={handleSubmit}
            sx={{ mr: 1 }}
            className={Style.Button}
          >
            Submit
          </Button>
        </>
      </Box>
    </>
  )
}

export default PopupForm
