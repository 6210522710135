import React from 'react'
import Alert from '@mui/material/Alert'

interface CustomAlertProps {
  severity?: 'error' | 'warning' | 'info' | 'success'
  message: string
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  severity = 'info',
  message,
}) => {
  return (
    <Alert
      severity={severity}
      sx={{
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: '30px',
        display: 'flex',
        width: '100%',
      }}
    >
      {message}
    </Alert>
  )
}

export default CustomAlert
