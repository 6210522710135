import { Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CustomStepper from 'src/Components/Application/CustomStepper'
import ChildCare from './ChildCare'
import Education from './Education'
import EmployerDetails from './EmployerDetails'
import ForeignWork from './ForeignWork'
import JobDetails from './JobDetails'
import SelfEmployement from './SelfEmployement'
import SpecialEducation from './SpecialEducation'
import TradeAndTraining from './TradeAndTraining'
import WorkTaxDetails from './WorkTaxDetails'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { setEducationDetailsStep } from 'src/Redux/Slices/ApplicationStore/ApplicationStepStore'

export const GradeList = [
  { name: 'First Grade', value: 'First Grade' },
  { name: 'Second Grade', value: 'Second Grade' },
  { name: 'Third Grade', value: 'Third Grade' },
  { name: 'Fourth Grade', value: 'Fourth Grade' },
  { name: 'Fifth Grade', value: 'Fifth Grade' },
  { name: 'Sixth Grade', value: 'Sixth Grade' },
  { name: 'Seventh Grade', value: 'Seventh Grade' },
  { name: 'Eighth Grade', value: 'Eighth Grade' },
  { name: 'Ninth Grade', value: 'Ninth Grade' },
  { name: 'Tenth Grade', value: 'Tenth Grade' },
  { name: 'Eleventh Grade', value: 'Eleventh Grade' },
  { name: 'Twelfth Grade', value: 'Twelfth Grade' },
  { name: 'One Year of College', value: 'One Year of College' },
  { name: 'Two Years of College', value: 'Two Years of College' },
  {
    name: 'Three Years of College',
    value: 'Three Years of College',
  },
  {
    name: 'Four Years of College',
    value: 'Four Years of College',
  },
  {
    name: 'Five or More Years of College',
    value: 'Five or More Years of College',
  },
]

export const JobList = [
  { name: 'Plumber', value: 'Plumber' },
  { name: 'HVAC Technician', value: 'HVAC Technician' },
  { name: 'Mechanic', value: 'Mechanic' },
  { name: 'Cosmetologist', value: 'Cosmetologist' },
  { name: 'Massage Therapist', value: 'Massage Therapist' },
  { name: 'IT/Tech', value: 'IT/Tech' },
  { name: 'Criminal Justice', value: 'Criminal Justice' },
  { name: 'Medical', value: 'Medical' },
  { name: 'Dental', value: 'Dental' },
  { name: 'Culinary', value: 'Culinary' },
  { name: 'Other', value: 'Other' },
]

const EducationEmployment = ({
  chatForm,
  setChatForm,
}: {
  chatForm?: any
  setChatForm?: any
}) => {
  const formComponents = [
    {
      title: 'Education',
      content: Education,
    },
    {
      title: 'Special Education',
      content: SpecialEducation,
    },
    {
      title: 'Specialized Trade Education & Training',
      content: TradeAndTraining,
    },
    {
      title: 'Employer Details',
      content: EmployerDetails,
    },
    {
      title: 'Job Details',
      content: JobDetails,
    },
    {
      title: 'Child-Care',
      content: ChildCare,
    },
    {
      title: 'Self-Employment',
      content: SelfEmployement,
    },
    {
      title: 'Foreign Work',
      content: ForeignWork,
    },
    {
      title: 'Work Tax Details',
      content: WorkTaxDetails,
    },
  ]

  const dispatch = useAppDispatch()
  const location = useLocation()
  const currentPath = location.pathname
  const myRef = useRef<HTMLDivElement>(null)
  const totalSteps = formComponents.length

  const EducationDetailsStep = useAppSelector(
    (state) => state?.applicationStore?.ApplicationStep?.EducationDetailsStep,
  )

  const [activeStep, setActiveStep] = useState<number>(
    EducationDetailsStep ? parseInt(EducationDetailsStep) : 0,
  )

  useEffect(() => {
    setChatForm(false)
  }, [])

  const handleNext = () => {
    setChatForm(false)
    setActiveStep((prevActiveStep) =>
      prevActiveStep < totalSteps - 1 ? prevActiveStep + 1 : prevActiveStep,
    )
    myRef?.current?.scrollIntoView()
  }

  const handleBack = () => {
    setChatForm(false)
    setActiveStep((prevActiveStep) =>
      prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep,
    )
    myRef?.current?.scrollIntoView()
  }

  useEffect(() => {
    // localStorage.setItem('EducationAndEmployement', activeStep.toString())
    dispatch(setEducationDetailsStep(activeStep.toString()))
  }, [activeStep, currentPath])

  const FormToShow = formComponents.map(
    (formComponent) => formComponent.content,
  )[activeStep] // Get the form component based on the active step

  return (
    <>
      <CustomStepper
        myRef={myRef}
        formComponents={formComponents}
        activeStep={activeStep}
        handleNext={handleNext}
        handleBack={handleBack}
      />
      <Box width={'100%'}>
        {process.env.REACT_APP_MILESTONE_4 && FormToShow && (
          <FormToShow
            chatForm={chatForm}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        )}
        {/* Render the form component based on the active step */}
      </Box>
    </>
  )
}

export default EducationEmployment
