import { baseAPI } from '../Common/httpCommon'
import { type RoleType } from './Types/roleType'

const viewRole = async (token: string) => {
  try {
    return await baseAPI.get<RoleType>('role/get', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const viewRolePermission = async (token: string) => {
  try {
    return await baseAPI.get<RoleType>('role-permission/get', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const viewRolePermissionDetails = async (data: string, token: string) => {
  try {
    return await baseAPI.get<RoleType>(`role-permission/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const viewRoleDetail = async (data: string, token: string) => {
  try {
    return await baseAPI.get(`role/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const addRole = async (data: FormData, token: string) => {
  try {
    return await baseAPI.post('role-permission/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const editRole = async (uuid: string, data: FormData, token: string) => {
  try {
    return await baseAPI.put(`role-permission/${uuid}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const deletRole = async (data: string, token: string) => {
  try {
    return await baseAPI.delete(`role/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const ProfileService = {
  viewRole,
  viewRolePermission,
  viewRolePermissionDetails,
  viewRoleDetail,
  addRole,
  editRole,
  deletRole,
}
export default ProfileService
