import { Grid, Link, Typography } from '@mui/material'
import React from 'react'
import styles from './index.module.scss'
import { FW_MEDIUM } from 'src/Utils/Constants'

const FooterContent: React.FunctionComponent = () => {
  const { center, footerElm, alignItemEnd, contentStart } = styles

  return (
    <>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        height='10vh'
        className={`${alignItemEnd} ${center}`}
      >
        <Grid
          item
          sx={{ width: '460px' }}
          py={3}
          // px={14}
          mr={4}
          container
        >
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Typography
              variant='h3'
              className={`${footerElm} fw-bold pointer`}
              fontWeight={FW_MEDIUM}
            >
              <Link
                href='https://benefitsboost.vercel.app/privacy-policy'
                target='_blank'
                sx={{
                  textDecoration: 'none', // Remove underline
                  color: 'inherit', // Inherit text color
                }}
              >
                Privacy Policy
              </Link>
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Typography
              variant='h3'
              className={`${center} fw-bold pointer`}
              fontWeight={FW_MEDIUM}
            >
              <Link
                href='https://benefitsboost.vercel.app/terms-and-conditions'
                target='_blank'
                sx={{
                  textDecoration: 'none', // Remove underline
                  color: 'inherit', // Inherit text color
                }}
              >
                Terms & Conditions
              </Link>
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Typography
              variant='h3'
              className={`${contentStart} fw-bold pointer`}
              fontWeight={FW_MEDIUM}
            >
              <Link
                href='https://benefitsboost.vercel.app/contact'
                target='_blank'
                sx={{
                  textDecoration: 'none', // Remove underline
                  color: 'inherit', // Inherit text color
                }}
              >
                Contact Us
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default FooterContent
