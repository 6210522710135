import React from 'react'
import GeneralHelth from './GeneralHelth'
import VisionAndHearing from './VisionAndHearing'
import IllnessAndInjuries from './IllnessAndInjuries'
import MedicalProfessional from './MedicalProfessional'
import MedicalFacilityCare from './MedicalFacilityCare'
import MedicalTest from './MedicalTest'
import Medicine from './Medicine'
import AdditionalInfo from './AdditionalInfo'

const MedicalDetails: React.FunctionComponent = () => {
  return (
    <>
      <GeneralHelth />
      <VisionAndHearing />
      <IllnessAndInjuries />
      <MedicalProfessional />
      <MedicalFacilityCare />
      <MedicalTest />
      <Medicine />
      <AdditionalInfo />
    </>
  )
}

export default MedicalDetails
