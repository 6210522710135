import { Divider, Grid, InputLabel, TextField } from '@mui/material'
import { useRef, useState } from 'react'
import {
  fetchPlaceDetails,
  updateMultiObjectAddressInFormValues,
} from 'src/Components/Application/CommonAddress'
import { ZipcodeField } from 'src/Components/Fields'
import { HospitalAndMedicalFacilityCareType } from 'src/Redux/Services/Types/applicationType'
import Style from '../index.module.scss'

interface Place {
  id: string
  description: string
}
const itemStyle = {
  xs: 12,
  sm: 12,
  md: 12,
}

const marginStyle = {
  mr: { md: 2, xs: 2 },
}

const Address = ({
  errors,
  formValues,
  realValues,
  index,
  setFormValues,
  handleFieldChange,
  fieldName,
}: {
  errors: Record<string, string>
  realValues: HospitalAndMedicalFacilityCareType
  formValues:
    | (typeof realValues.otherOrganizationDetail)[0]
    | (typeof realValues.haveSurgeriesMedicalFacility)[0]
  fieldName:
    | 'haveEmergencyRoomVisitMedicalFacility'
    | 'haveMedicalFacilityMedicalFacility'
    | 'haveMentalHeathTreatmentMedicalFacility'
    | 'haveInpatientOrOverNightStayMedicalFacility'
    | 'haveOutpatientOrSamedayVisitMedicalFacility'
    | 'haveSurgeriesMedicalFacility'
    | 'havePhysicalTherapyMedicalFacility'
    | 'jailDoctorDetail'
    | 'welfareDetail'
    | 'attorneyDetail'
    | 'insuranceDetail'
    | 'rehabilitationOrganizationDetail'
    | 'workersCompensationDetail'
    | 'otherOrganizationDetail'
  index: number
  setFormValues: React.Dispatch<
    React.SetStateAction<HospitalAndMedicalFacilityCareType>
  >
  handleFieldChange: (data: { name: string; value: string }) => void
}) => {
  const addressinputRef: React.MutableRefObject<undefined> = useRef()

  const [predictions, setPredictions] = useState<Place[]>([])

  const autocomplete = (query: string) => {
    if (query.length === 0) {
      setPredictions([])
      return
    }

    const autocompleteService = new google.maps.places.AutocompleteService()

    autocompleteService.getPlacePredictions(
      { input: query },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const placePredictions: Place[] = predictions.map((prediction) => {
            return {
              description: prediction.description,
              id: prediction.place_id,
            }
          })
          setPredictions(placePredictions)
        }
      },
    )
  }

  const handlePredictionClick = (prediction: Place) => {
    fetchPlaceDetails(prediction.id, formValues?.address, (addressData) => {
      updateMultiObjectAddressInFormValues(
        realValues,
        index,
        addressData,
        setFormValues,
        fieldName,
      )
    })
    setPredictions([])
  }

  const handleAddressChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target
    if (value.length) {
      autocomplete(value) // Ensure this function is not causing issues
    } else {
      // setFormValues(initialObj);
      setPredictions([])
    }

    let newMedicalProfessionals = [...realValues[fieldName]]
    newMedicalProfessionals = newMedicalProfessionals.map(
      (item: any, i: number) => {
        if (i === index) {
          return {
            ...item,
            address: {
              ...item.address,
              [name]: value,
            },
          }
        }
        return item
      },
    )
    const updatedFormValues = {
      ...realValues,
      [fieldName]: newMedicalProfessionals,
    }

    setFormValues(updatedFormValues)
  }

  const handleMultiAddress = ({
    name,
    value,
  }: {
    name: string
    value: string
  }) => {
    let newMedicalProfessionals = [...realValues[fieldName]]
    newMedicalProfessionals = newMedicalProfessionals.map(
      (item: any, i: number) => {
        if (i === index) {
          return {
            ...item,
            address: {
              ...item.address,
              [name]: value,
            },
          }
        }
        return item
      },
    )
    const updatedFormValues = {
      ...realValues,
      [fieldName]: newMedicalProfessionals,
    }

    setFormValues(updatedFormValues)
  }

  return (
    <>
      <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
        <Grid width={'100%'}>
          <InputLabel className={Style.label}>
            Street Address <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <TextField
            inputRef={addressinputRef}
            fullWidth
            type='text'
            id='streetAddress'
            name='streetAddress'
            className={Style.AnsTxt}
            placeholder='Street Address'
            value={formValues?.address?.streetAddress}
            // onChange={(e) => {handleFieldChange({name:e.target.name, value:e.target.value})}}
            onChange={handleAddressChange}
            error={!!errors?.streetAddress}
            helperText={errors?.streetAddress}
          />
          {predictions.length ? (
            <Grid item md={12} xs={12} className='form-row-item'>
              <Grid
                item
                {...itemStyle}
                {...marginStyle}
                className={Style.listBox}
              >
                {predictions.map((prediction, index) => (
                  <>
                    <div
                      key={index}
                      className={Style.listItem}
                      onClick={() => {
                        handlePredictionClick(prediction)
                      }}
                    >
                      {prediction.description}
                    </div>
                    <Divider />
                  </>
                ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <Grid width={'100%'}>
          <InputLabel className={Style.label}>
            City/Town <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <TextField
            fullWidth
            type='text'
            id='city'
            name='city'
            className={Style.AnsTxt}
            placeholder='City/Town'
            value={formValues?.address?.city}
            onChange={(e) => {
              handleMultiAddress({ name: e.target.name, value: e.target.value })
            }}
            error={!!errors?.city}
            helperText={errors?.city}
          />
        </Grid>
      </Grid>
      <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
        <Grid width={'100%'}>
          <InputLabel className={Style.label}>
            State <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <TextField
            fullWidth
            type='text'
            id='state'
            name='state'
            className={Style.AnsTxt}
            placeholder='State'
            value={formValues?.address?.state}
            onChange={(e) => {
              handleMultiAddress({ name: e.target.name, value: e.target.value })
            }}
            error={!!errors?.state}
            helperText={errors?.state}
          />
        </Grid>
        <ZipcodeField
          postalCode={formValues.address?.postalCode}
          handleChange={(e) => {
            handleMultiAddress({ name: e.name, value: e.value })
          }}
          error={errors?.postalCode}
        />
        <Grid width={'100%'}>
          <InputLabel className={Style.label}>
            Country <span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <TextField
            fullWidth
            type='text'
            id='country'
            name='country'
            className={Style.AnsTxt}
            placeholder='Country'
            value={formValues?.address?.country}
            onChange={(e) => {
              handleMultiAddress({ name: e.target.name, value: e.target.value })
            }}
            error={!!errors?.country}
            helperText={errors?.country}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Address
