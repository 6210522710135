/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DialogTitle,
  Dialog,
  Box,
  Typography,
  DialogContent,
  styled,
  Grid,
} from '@mui/material'
import { Icons, scrollToSection } from 'src/Utils/Constants'
import Style from './index.module.scss'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { setIsDisqualified } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import CustomSeparator from './breadCrumbForFlags'
import ArrowRight from 'src/Assets/Icons/arrow-right.svg'
import AlertIcon from 'src/Assets/Icons/organgeAlert.svg'

import { RootState } from 'src/Redux/store'
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
  Key,
} from 'react'
import { getTabData } from 'src/Pages/Applications/allTabsData'
import { setActiveStepTab, setStepCompleted } from 'src/Redux/Slices/stepSlice'
const Disqualification = ({
  open,
  setOpen,
  handleOpen,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
}) => {
  const dispatch = useAppDispatch()
  const handleClose = () => {
    setOpen(false)
    dispatch(setIsDisqualified(false))
  }

  const StyledContainer = styled(Box)({
    padding: '15px 0',
    borderBottom: '1px dashed rgba(200, 201, 204, 1)',
  })

  const StyledLabel = styled(Typography)({
    color: '#9d9fa4',
    font: '500 16px/150% SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif',
  })

  const StyledIconContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    padding: '5px',
    borderRadius: '6px',
    backgroundColor: '#f1f1f4',
  })

  const StyledImg = styled('img')({
    width: '20px',
    aspectRatio: '1',
    objectFit: 'contain',
    objectPosition: 'center',
  })

  const StyledErrorContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    padding: '5px 15px',
    marginTop: '10px',
    borderRadius: '6px',
    backgroundColor: '#ffeef3',
    fontSize: '16px',
    color: '#6f737a',
    fontWeight: 500,
    lineHeight: '150%',
  })

  const StyledSmallImg = styled('img')({
    width: '16px',
    aspectRatio: '1',
    objectFit: 'contain',
    objectPosition: 'center',
  })

  const StyledErrorText = styled(Typography)({
    fontFamily: 'SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif',
  })

  const flagdata = useAppSelector(
    (state: RootState) => state?.applicationStore?.BasicDetails?.flagdata,
  )

  function removeSpecialCharacters(str: string): string {
    // Regular expression to match any characters that are not underscores or letters
    const regex = /[^a-zA-Z_]/g
    // Replace all matched characters with an empty string
    return str.replace(regex, '')
  }

  const allSteps = useAppSelector((state) => state.steps)

  // const key = 'EducationAndEmploymentDetail.employerDetail[0].address.city'
  function findSectionId(key: string) {
    const keyToRemove = 'activeTab'
    const { [keyToRemove]: _, ...stepsWithoutActiveTab } = allSteps
    const keyParts = key.split('.')
    if (keyParts.length >= 2) {
      const sectionName = keyParts[0]
      const subSectionName = removeSpecialCharacters(keyParts[1])
      const tabName = getTabData(sectionName)

      for (const key of Object.keys(stepsWithoutActiveTab)) {
        const matchingSections: any[] = []
        for (const key of Object.keys(stepsWithoutActiveTab)) {
          const sections = stepsWithoutActiveTab[key].filter(
            (item: any) => item.tabName.toLowerCase() === tabName.toLowerCase(),
          )
          matchingSections.push(...sections)
        }
        dispatch(setActiveStepTab(tabName))
        handleClose()

        setTimeout(() => {
          for (const section of matchingSections) {
            if (
              section.flagSectionName &&
              section.flagSectionName.toLowerCase() ===
                subSectionName.toLowerCase()
            ) {
              dispatch(
                setStepCompleted({
                  section,
                  label: section.label,
                }),
              )
              scrollToSection(section.sectionId)

              // Return sectionId or do something else with the section
              return section.sectionId
            }
          }
        }, 6)

        // if (section) {
        //   return section.sectionId
        // }
      }
    }
    return null
  }

  interface MenuItem {
    label: string
  }

  const generateMenuItems = (key: string): MenuItem[] => {
    const labels = key.split('.').map((part, index) => {
      // Remove square brackets from the part
      const cleanPart = part.replace(/\[.*?\]/g, '')
      return { label: cleanPart, index }
    })

    return labels.map(({ label }) => ({ label }))
  }
  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
          style: { margin: 'auto' },
        }}
        open={open}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Red Flags ({flagdata?.remark?.length ?? 0})
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='Cancel'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              justifyContent='center'
              className={Style.scrollableContainer}
            >
              {flagdata?.remark?.map(
                (
                  remark: {
                    column_name:
                      | string
                      | number
                      | boolean
                      | ReactElement<any, string | JSXElementConstructor<any>>
                      | Iterable<ReactNode>
                      | ReactPortal
                      | null
                      | undefined
                    key: string
                    remark:
                      | string
                      | number
                      | boolean
                      | ReactElement<any, string | JSXElementConstructor<any>>
                      | Iterable<ReactNode>
                      | ReactPortal
                      | null
                      | undefined
                  },
                  index: Key | null | undefined,
                ) => (
                  <>
                    <Grid item xs={12} key={index}>
                      <StyledContainer>
                        <Grid container alignItems='center' spacing={2}>
                          <Grid item xs={12} sm={6}>
                            {/* <StyledLabel>{remark.column_name}</StyledLabel> */}
                            <StyledLabel>
                              <CustomSeparator
                                menuItems={generateMenuItems(remark.key)}
                              />
                            </StyledLabel>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            container
                            alignItems='center'
                            spacing={2}
                          >
                            <Grid item xs>
                              <StyledLabel>{remark?.value ?? ''}</StyledLabel>
                            </Grid>
                            <Grid item sx={{ cursor: 'pointer' }}>
                              <StyledIconContainer
                                onClick={() => {
                                  findSectionId(remark.key)
                                }}
                              >
                                <StyledImg loading='lazy' src={ArrowRight} />
                              </StyledIconContainer>
                            </Grid>
                          </Grid>
                        </Grid>
                      </StyledContainer>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledErrorContainer>
                        <Grid item>
                          <StyledSmallImg loading='lazy' src={AlertIcon} />
                        </Grid>
                        <Grid item xs>
                          <StyledErrorText>{remark.remark}</StyledErrorText>
                        </Grid>
                      </StyledErrorContainer>
                    </Grid>
                  </>
                ),
              )}
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default Disqualification
