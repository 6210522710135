/* eslint-disable @typescript-eslint/no-unused-vars */
// This slice manages the state for the sign in feature
import {
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
// import { extractDetailedApplicationData } from 'src/Pages/Applications/getApiData'
// import { RoleType } from '../Services/Types/roleType'
import AllApplicationservice from 'src/Redux/Services/allApplicationservice'
import { RootState } from 'src/Redux/store'
import { DynamicObj, handleResponse } from 'src/Utils/Constants'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: any
  activeTab: string
  activeStep: number
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {},
  activeTab: '',
  activeStep: 0,
}

// Define the reducers that can modify the state
const allApplicationSlice = createSlice({
  name: 'AllApplications',
  initialState,
  reducers: {
    SetApplicationList: (state, action: PayloadAction<any>) => {
      state.data = action.payload
    },
    ResetSetApplicationList: (state, action: PayloadAction<any>) => {
      state.data = action.payload
    },

    setActive: (state, action: PayloadAction<any>) => {
      const { activeTab, activeStep } = action.payload
      state.activeTab = activeTab
      state.activeStep = activeStep
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(ViewRoleDetailRequest.pending, (state) => {
    //   state.success = false
    //   state.loading = true
    // })
    // builder.addCase(
    //   ViewRoleDetailRequest.fulfilled,
    //   (state, action: PayloadAction<stateType>) => {
    //     state.success = action.payload?.success
    //     state.loading = false
    //     state.data = action.payload?.data
    //   },
    // )
    // builder.addCase(ViewRoleDetailRequest.rejected, (state) => {
    //   state.success = false
    //   state.loading = false
    // })
  },
})

// Define the async thunk action creator for sign in
export const getAllApplicationList = createAsyncThunk(
  'getAllApplicationList', // name of the action
  async (filter: DynamicObj, thunkAPI) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const response = await AllApplicationservice.getAllApplicationList(filter) // make the API call
      if (response.data.success) {
        dispatch(SetApplicationList(response.data.data))
      }
      handleResponse({ response, dispatch, onlyFail })
      //   return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const getAllApplicationEmployeeList = createAsyncThunk(
  'getAllApplicationEmployeeList', // name of the action
  async (_, thunkAPI) => {
    // const { dispatch } = thunkAPI
    try {
      const response =
        await AllApplicationservice.getAllApplicationEmployeeList() // make the API call

      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const getApplicationDetail = createAsyncThunk(
  'getAllApplicationList', // name of the action
  async (
    applicationId: string,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    // const { dispatch } = thunkAPI
    try {
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const response = await AllApplicationservice.getApplicationDetail(
        applicationId,
        isPublic,
      ) // make the API call

      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AssignEmployeeToApplication = createAsyncThunk(
  'AssignEmployeeToApplication', // name of the action
  async (object: DynamicObj, thunkAPI) => {
    // const { dispatch } = thunkAPI
    try {
      const response =
        await AllApplicationservice.AssignEmployeeToApplication(object) // make the API call

      // return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default allApplicationSlice.reducer
export const { SetApplicationList, ResetSetApplicationList } =
  allApplicationSlice.actions
