import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  styled,
  type TypographyProps,
} from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AlertIcon from 'src/Assets/Icons/annotationAlert.svg'
import signBg from 'src/Assets/Icons/signBg.svg'
import FooterContent from 'src/Components/FooterContent'
import { ForgetPasswordRequest } from 'src/Redux/Slices/authSlice'
import { useAppDispatch } from 'src/Redux/hooks'
import { FW_BOLD } from 'src/Utils/Constants'
import { ROUTES } from 'src/Utils/Routes'
import styles from './index.module.scss'

const ForgotPassword: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  // const [alert, setAlert] = useState('')
  const [isSubmit, setIsSubmit] = useState(false)
  const dispatch = useAppDispatch()
  const SubTypography = styled(Typography)<TypographyProps>({
    fontWeight: FW_BOLD,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  })
  const {
    theBox,
    br20,
    containerHeight,
    bgCmn1,
    wBox,
    p10,
    secondContainer,
    center,
  } = styles

  const isValidEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return re.test(String(email).toLowerCase())
  }

  const handleSubmit = () => {
    setIsSubmit(true)

    if (!isValidEmail(email)) {
      return
      // setAlert('Invalid Email')
    }

    dispatch(ForgetPasswordRequest({ email }))
      .unwrap()
      .then(({ success }) => {
        if (success) {
          // setAlert('Reset password link is sent to your mail')
          navigate(ROUTES.SIGN_IN)
        } else {
          // setAlert('Something went wrong!')
        }
      })
      .catch((error) => {
        console.log(error.response?.data.message)
      })
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ backgroundImage: `url(${signBg})` }}
        className={`${p10} ${containerHeight}`}
      >
        <Grid
          container
          lg={6}
          md={6}
          sm={12}
          xs={12}
          direction={'row'}
          className={`${bgCmn1} ${br20} ${secondContainer}`}
        >
          <Grid item lg={12} md={12} sm={12} xs={12} height='10vh'></Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            height='80vh'
            className={`${center}`}
          >
            {/* <Snackbar
              open={Boolean(alert)}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              autoHideDuration={6000}
              onClose={() => {
                setAlert('')
              }}
            >
              <Alert severity='error' sx={{ width: '100%' }}>
                <Box display='flex' alignItems='center'>
                  <Box mr={2}>
                    <img src={AlertIcon} className='w-20' alt='alert' />
                  </Box>
                  {alert}
                </Box>
              </Alert>
            </Snackbar> */}

            <Grid
              className={`${wBox}`}
              sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  mb: 3,
                }}
              >
                <Typography variant='h5' sx={{ fontWeight: FW_BOLD }}>
                  Forgot Password?
                </Typography>

                <Typography variant='body1' className={styles.subTitle}>
                  Enter the email address you used when you joined, and we'll
                  send you instructions to reset your password.
                </Typography>
              </Box>
              <Box className={theBox} p={1} height={104}>
                <Typography variant='body2' color='warning'>
                  <SubTypography variant='body1'>
                    <img src={AlertIcon} style={{ padding: '0 6px' }} /> We need
                    your attention!
                  </SubTypography>
                  For security reasons, we do NOT store your password. So rest
                  assured that we will never send your password via email.
                </Typography>
              </Box>
              <Box className={styles.flexStartBox}>
                <Typography className={styles.textFieldWrapper}>
                  Email Address
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  className={styles.field}
                  placeholder='Enter your email'
                  value={email}
                  onChange={(e) => {
                    setIsSubmit(false)
                    setEmail(e.target.value)
                  }}
                  error={!isValidEmail(email) && isSubmit}
                  helperText={
                    !isValidEmail(email) && isSubmit
                      ? 'Please enter valid email'
                      : ''
                  }
                />
              </Box>
              <Button onClick={handleSubmit} className={styles.button}>
                Submit
              </Button>
              <Button
                onClick={() => {
                  navigate(ROUTES.SIGN_IN)
                }}
                className={styles.prvButton}
              >
                Back
              </Button>
            </Grid>
          </Grid>
          <FooterContent />
        </Grid>
      </Grid>
    </>
  )
}

export default ForgotPassword
