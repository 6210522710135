import { baseAPI } from '../Common/httpCommon'
import { type AssistantFormType } from './Types/assistantFormType'

const AddAssistant = async (
  data: AssistantFormType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.post('application/add-assistance', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}
const UpdateAssistant = async (
  data: AssistantFormType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/edit-assistance', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AssistantService = {
  AddAssistant,
  UpdateAssistant,
}
export default AssistantService
