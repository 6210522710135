import { baseAPI } from '../Common/httpCommon'
import { employeeType } from './Types/employeeType'

const viewEmployeeList = async (
  filter: {
    scope?: string
    roleId?: string
    organizationId?: string
  },
  token: string,
) => {
  try {
    let setFilter = ''
    if (filter.scope ?? filter.roleId ?? filter.organizationId) {
      setFilter = '?'
      if (filter.scope) {
        setFilter += `scope=${filter.scope}&`
      }
      if (filter.roleId) {
        setFilter += `roleId=${filter.roleId}&`
      }
      if (filter.organizationId) {
        setFilter += `organizationId=${filter.organizationId}&`
      }
    }
    return await baseAPI.get(`employee/list${setFilter}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const viewOrganizationList = async (token: string) => {
  try {
    return await baseAPI.get('employee/organizationList', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const employeeDetails = async (token: string, id: string) => {
  try {
    return await baseAPI.get('employee/' + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const addEmployee = async (token: string, data: employeeType) => {
  try {
    return await baseAPI.post('employee/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e: any) {
    return e
  }
}

const editEmployee = async (token: string, data: FormData, uuid: string) => {
  try {
    return await baseAPI.put('employee/' + uuid, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e: any) {
    return e
  }
}

const deleteEmployee = async (token: string, uuid: string, data: string) => {
  try {
    return await baseAPI.put('employee/delete/' + uuid, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const EmployeeService = {
  viewEmployeeList,
  viewOrganizationList,
  addEmployee,
  employeeDetails,
  deleteEmployee,
  editEmployee,
}
export default EmployeeService
