import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import React, { useState } from 'react'
import { ResetPasswordIsTemp } from 'src/Redux/Slices/profileSlice'
import { useAppDispatch } from 'src/Redux/hooks'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'

const PopupForm = ({
  openDialog,
  setOpenDialog,
  handleOpen,
}: {
  openDialog: boolean
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
}) => {
  const dispatch = useAppDispatch()

  const handleClose = () => {
    setOpenDialog(false)
  }

  const [formValues, setFormValues] = useState({
    password: '',
    confirmPassword: '',
  })
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    // You may perform validation here and update errors state accordingly
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword)
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}
    const passwordRegex =
      /^(?=.[a-zA-Z])*(?=.[0-9])*(?=.[!@#$%^&*])*[a-zA-Z0-9!@#$%^&*]{8,20}$/

    if (!formValues.password) {
      newErrors.password = 'Password is required'
      valid = false
    } else if (!passwordRegex.test(formValues.password)) {
      newErrors.password =
        'Password must contain 8 to 20 characters, and must contain at least one capital letter, and one number, and one special character'
      valid = false
    }

    if (formValues.confirmPassword === '') {
      newErrors.confirmPassword = 'Confirm Password is required'
      valid = false
    }

    if (formValues.confirmPassword) {
      if (formValues.password !== formValues.confirmPassword) {
        newErrors.confirmPassword = 'Passwords do not match'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      dispatch(ResetPasswordIsTemp({ password: formValues.password })).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success === true) {
            handleClose()
          } else {
            handleOpen()
          }
        },
      )
      setOpenDialog(false)
    } else setOpenDialog(true)
  }

  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={openDialog}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Reset Password
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='threeDot'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={Style.DialogContentBox}>
              <form onSubmit={handleSubmit}>
                <Box width={'100%'}>
                  <InputLabel className={Style.label}>
                    Password<span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    tabIndex={-1}
                    fullWidth
                    variant='outlined'
                    type={showPassword ? 'text' : 'password'}
                    className={Style.AnsTxt}
                    onChange={handleFieldChange}
                    value={formValues?.password}
                    placeholder='Enter your password'
                    name='password'
                    id='password'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge='end'
                          >
                            {!showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.password}
                    helperText={errors.password}
                  />
                </Box>

                <Box width={'100%'} pt={1}>
                  <InputLabel className={Style.label} htmlFor='Description'>
                    Confirm Password<span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    tabIndex={-1}
                    fullWidth
                    variant='outlined'
                    type={showRepeatPassword ? 'text' : 'password'}
                    className={Style.AnsTxt}
                    placeholder='Repeat your password'
                    onChange={handleFieldChange}
                    value={formValues?.confirmPassword}
                    name='confirmPassword'
                    id='confirmPassword'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={toggleRepeatPasswordVisibility}
                            edge='end'
                          >
                            {!showRepeatPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                  />
                </Box>
                <DialogActions>
                  <Box className={Style.AddButton}>
                    <Button
                      variant='contained'
                      type='submit'
                      className={Style.Button}
                    >
                      Add
                    </Button>
                  </Box>
                </DialogActions>
              </form>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default PopupForm
