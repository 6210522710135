/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumb from 'src/Components/BreadCrumb'
import { ORG_TAB_DATA } from 'src/Utils/Constants'
import { ROUTES } from 'src/Utils/Routes'
import ContactInfo from './Tabs/contactInfo'
import ManageEmployees from './Tabs/employees'
import Overview from './Tabs/overview'
import PaymentSettings from './Tabs/paymentSettings'
import BasicHeader from './header'
import styles from './index.module.scss'
const scrollTop = 325
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

const OrganizationDetail = () => {
  type TabContentProps = Record<
    string,
    {
      title: string
      stepper?: React.ReactElement<
        unknown,
        string | React.JSXElementConstructor<unknown>
      >
      content: React.ReactElement<
        unknown,
        string | React.JSXElementConstructor<unknown>
      >
    }
  >

  const TAB_CONTENT: TabContentProps = {
    [ORG_TAB_DATA.OVERVIEW]: {
      title: ORG_TAB_DATA.OVERVIEW,
      content: <Overview />,
    },
    [ORG_TAB_DATA.CONTACT_INFORMATION]: {
      title: ORG_TAB_DATA.CONTACT_INFORMATION,
      content: <ContactInfo />,
    },

    [ORG_TAB_DATA.PAYMENT_SETTINGS]: {
      title: ORG_TAB_DATA.PAYMENT_SETTINGS,
      content: <PaymentSettings />,
    },
    [ORG_TAB_DATA.EMPLOYEES]: {
      title: ORG_TAB_DATA.EMPLOYEES,
      content: <ManageEmployees />,
    },
  }
  const [tabId, setTabId] = React.useState(0)
  const [selectedTabName, setSelectedTabName] = React.useState('Overview')
  const gridRef = React.useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (gridRef.current) {
      if (gridRef?.current?.scrollTop > scrollTop) {
        gridRef?.current?.scrollTo(0, scrollTop)
      }
    }
  }
  return (
    <>
      {
        <div
          ref={gridRef}
          onScroll={handleScroll}
          className={styles.GridScroll}
        >
          <Grid m={3}>
            <Breadcrumb
              title='Manage Organization'
              Menus={[{ name: 'Organization', route: ROUTES.ORGANIZATIONS }]}
            />
            <Grid container direction='column' justifyContent='space-between'>
              <Grid>
                <Grid item lg={12} className={styles.GridStyle} my={2}>
                  <BasicHeader
                    setTabId={setTabId}
                    setSelectedTabName={setSelectedTabName}
                  />
                </Grid>
                <Grid item lg={12} className={styles.GridStyle} my={2}>
                  <Box>
                    <Box className={styles.AppPageBox}>
                      <Grid container>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          mb={3}
                          className={styles.ContentGridStyle}
                        >
                          <CustomTabPanel
                            key={tabId}
                            value={tabId}
                            index={tabId}
                          >
                            {Object.keys(TAB_CONTENT).map((tabKey) => {
                              const tab =
                                TAB_CONTENT[tabKey as keyof typeof ORG_TAB_DATA]
                              if (tabKey === selectedTabName) {
                                return <>{tab.content}</>
                              } else {
                                return null
                              }
                            })}
                          </CustomTabPanel>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      }
    </>
  )
}

export default OrganizationDetail
