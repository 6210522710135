import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { CheckBox } from '@mui/icons-material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { createRef, useEffect, useRef, useState } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import BlankCheckbox from 'src/Assets/Icons/blankCheckbox.svg'
import quote from 'src/Assets/Icons/quots.svg'
import smallArrowCircle from 'src/Assets/Icons/smallArrowCircle.svg'
import star from 'src/Assets/Icons/star.svg'
import { useAppSelector } from 'src/Redux/hooks'
import Style from './index.module.scss'
import { COLORS } from 'src/Utils/Constants'
const StyledSelect = styled(Select)({
  borderRadius: '10px', // Set the border radius to your desired value
})

export const Password = () => {
  return <div>password</div>
}

export const ZipcodeField = ({
  postalCode,
  handleChange,
  error,
}: {
  postalCode: string
  handleChange: (data: { name: string; value: string }) => void
  error?: string
}) => {
  // const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/
  return (
    <Grid width={'100%'}>
      <InputLabel className={Style.label}>
        Zip Code <span style={{ color: 'red' }}>*</span>
      </InputLabel>
      <TextField
        fullWidth
        type='text'
        id='postalCode'
        name='postalCode'
        className={Style.AnsTxt}
        placeholder='Zip Code'
        value={postalCode}
        onChange={(e) => {
          if (e.target.value.match(/^\d*$/) && e.target.value.length <= 5)
            handleChange({ name: e.target.name, value: e.target.value })
        }}
        error={!!error}
        helperText={error}
      />
    </Grid>
  )
}

export const Name = ({
  title,
  value,
  name,
  error,
  handleChange,
  multiline,
  maxLength,
  preInput,
  number,
  email,
}: {
  title: string
  value?: string
  name: string
  error?: string
  handleChange: (data: { name?: string; value: string | number }) => void
  multiline?: boolean
  maxLength?: number
  preInput?: string
  number?: boolean
  email?: boolean
}) => {
  const isMultiLine = multiline
    ? {
        multiline,
        rows: 4,
      }
    : {}
  const preInputContent =
    preInput !== undefined
      ? {
          InputProps: {
            startAdornment: (
              <InputAdornment position='start'>{preInput}</InputAdornment>
            ),
          },
        }
      : {}
  const [mailError, setMailError] = useState('')
  useEffect(() => {
    if (
      email &&
      value?.length !== 0 &&
      !value?.match(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/)
    ) {
      setMailError('Please enter a valid email address')
    } else {
      setMailError('')
    }
  }, [value])
  const typeCheck = (newName: string, newValue: string) => {
    let valid = true
    if (newName === 'streetAddress') {
      if (newValue?.match(/^[a-zA-Z0-9 ]*$/)) {
        if (!maxLength && newValue?.length > 50) valid = false
        if (maxLength && newValue?.length > maxLength) valid = false
      } else valid = false
      return valid
    }
    if (email ?? number) {
      if (number) {
        if (newValue?.match(/^\d*$/)) {
          if (!maxLength && newValue?.length > 10) valid = false
          if (maxLength && newValue?.length > maxLength) valid = false
        } else valid = false
      }
      if (email) {
        if (!maxLength && newValue?.length > 200) valid = false
        if (maxLength && newValue?.length > maxLength) valid = false
      }
    } else if (newValue?.match(/^[a-zA-Z ]*$/)) {
      if (!maxLength && newValue?.length > 50) valid = false
      if (maxLength && newValue?.length > maxLength) valid = false
    } else valid = false
    return valid
  }
  return (
    <Grid width={'100%'}>
      <InputLabel className={Style.label}>
        {title} <span style={{ color: 'red' }}>*</span>
      </InputLabel>
      <TextField
        {...isMultiLine}
        {...preInputContent}
        fullWidth
        type='text'
        id={name}
        name={name}
        className={Style.AnsTxt}
        placeholder={title}
        value={value}
        onChange={(e) => {
          if (typeCheck(e.target.name, e.target.value))
            handleChange({ name: e.target.name, value: e.target.value })
        }}
        error={!!(error ?? mailError)}
        helperText={error ?? mailError}
      />
    </Grid>
  )
}

export const Calander = ({
  title,
  value,
  name,
  error,
  handleChange,
  disabled,
  minDate,
}: {
  title: string
  value?: string
  name: string
  error?: string
  handleChange: (data: { name: string; value: string }) => void
  disabled?: boolean
  minDate?: string
}) => {
  useEffect(() => {
    if (minDate && value && dayjs(minDate) > dayjs(value))
      handleChange({ name, value: minDate })
  }, [value, minDate])
  useEffect(() => {
    if (disabled) handleChange({ name, value: '' })
  }, [disabled])
  return (
    <Grid width={'100%'}>
      <InputLabel className={Style.label}>
        {title} <span style={{ color: 'red' }}>*</span>
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{
            width: '100%',
            fieldset: { borderColor: error ? '#d32f2f' : undefined },
          }}
          value={value ? dayjs(value) : null} // Convert to Dayjs object
          onChange={(date) => {
            handleChange({
              name,
              value: dayjs(date).format('YYYY-MM-DD') ?? '',
            })
          }}
          disabled={disabled}
          maxDate={dayjs()}
          minDate={minDate ? dayjs(minDate) : undefined}
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              style={{ width: '100%' }}
              id={name}
              name={name}
              placeholder='mm-DD-YYYY'
              disabled={disabled}
              className={Style.AnsTxt}
              InputProps={{
                inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
              }}
            />
          )}
        />
      </LocalizationProvider>
      <FormHelperText>{error}</FormHelperText>
    </Grid>
  )
}

// commonly used application components
export const CommonDropdown = ({
  name,
  value,
  error,
  label,
  menulist,
  handleChange,
}: {
  name: string
  value: string | number
  error?: string
  label: string
  menulist: Array<{
    name: string
    value: string | number
  }>
  handleChange: (data: { name: string; value: string | number }) => void
}) => {
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // const open = Boolean(anchorEl)
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget)
  // }
  // const handleClose = () => {
  //   setAnchorEl(null)
  // }
  return (
    <Grid width={'100%'}>
      <InputLabel className={Style.label}>
        {label} <span style={{ color: 'red' }}>*</span>
      </InputLabel>
      <Autocomplete
        disablePortal
        fullWidth
        id='combo-box-demo'
        value={value ?? null}
        onChange={(_, newValue) => {
          handleChange({ name, value: newValue ?? '' })
        }}
        options={menulist.map((option) => option.name)}
        renderInput={(params) => (
          <TextField {...params} error={!!error} helperText={error} />
        )}
      />
    </Grid>
  )
}

export const MultiSelectDropDown = ({
  name,
  value,
  error,
  label,
  menulist,
  handleSelectChange,
}: {
  name: string
  value: string[]
  error?: string
  label: string
  menulist: Array<{
    title?: string
    name: string
    value: string
  }>
  handleSelectChange: (data: string[]) => void
}) => {
  return (
    <Grid width={'100%'}>
      <InputLabel className={Style.label}>{label}</InputLabel>
      <Autocomplete
        multiple
        fullWidth
        options={menulist}
        value={menulist.filter((i) => value.includes(i.name))}
        onChange={(_, newValue) => {
          handleSelectChange(newValue.map((i) => i.name))
        }}
        filterOptions={(x, y) => {
          return x.filter((item) =>
            item.name.toLowerCase().includes(y.inputValue.toLowerCase()),
          )
        }}
        renderOption={(parems, option) => {
          return (
            <Box
              {...parems}
              alignItems={'flex-start !important'}
              display={'flex'}
              flexDirection={'column'}
            >
              {option.title && (
                <InputLabel className={Style.label}>{option.title}</InputLabel>
              )}
              {option.name}
            </Box>
          )
        }}
        getOptionLabel={(option) => (option.title ? option.title : option.name)}
        renderInput={(params) => (
          <TextField {...params} error={!!error} helperText={error} />
        )}
      />
    </Grid>
  )
}

export const CountryList = ({
  name,
  value,
  error,
  label,
  handleChange,
}: {
  name: string
  value: string
  error?: string
  label: string
  handleChange: (data: { name: string; value: string }) => void
}) => {
  const countrySelect = useAppSelector(
    (state: { country: { data: Array<{ name: string }> } }) =>
      state.country?.data,
  )

  return (
    <Grid width={'100%'}>
      <InputLabel className={Style.label}>{label}</InputLabel>
      <StyledSelect
        onChange={(e: any) => {
          handleChange({ name, value: e.target.value })
        }}
        className={Style.AnsTxt}
        IconComponent={(props) => (
          <IconButton {...props}>
            <img src={IconChevronDown} alt='IconChevronDown' />
          </IconButton>
        )}
        displayEmpty
        renderValue={value !== '' ? () => value : () => 'Select'}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, // Adjust the value as needed
            },
          },
        }}
        value={value}
        fullWidth
        id={name}
        name={name}
        error={!!error}
      >
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
        {countrySelect?.map(
          (
            item: {
              name: string
            },
            index: number,
          ) => {
            return (
              <MenuItem key={index} value={item.name}>
                {item.name}
              </MenuItem>
            )
          },
        )}
      </StyledSelect>
      <FormHelperText sx={{ mx: 1.5 }}>{error}</FormHelperText>
    </Grid>
  )
}

export const ApplicationIntroBox = ({ heading }: { heading: string }) => {
  return (
    <Box
      width={'100%'}
      display={'flex'}
      alignItems={'flex-start'}
      sx={{
        py: 2,
        my: 3,
        borderRadius: '10px',
      }}
    >
      <img src={quote} className={Style.star} alt='' />
      <Typography className={Style.Heading}>{heading}</Typography>
    </Box>
  )
}

export const StarText = ({ text }: { text: string | string[] }) => {
  return (
    <Box
      width={'100%'}
      display={'flex'}
      alignItems={'flex-start'}
      sx={{
        p: 2,
        backgroundColor: '#ECF6FF',
        borderRadius: '10px',
      }}
    >
      <img src={star} className={Style.star} alt='' />
      <Typography>
        <i>
          {!Array.isArray(text) ? (
            text
          ) : (
            <>
              {text[0]} <br /> {text[1]}
            </>
          )}
        </i>
      </Typography>
    </Box>
  )
}

export const PresentBox = ({
  handleChange,
  Checked,
}: {
  handleChange: () => void
  Checked: boolean
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={Checked}
            color='primary'
            className={Style.Check}
            onChange={() => {
              handleChange()
            }}
            icon={<img src={BlankCheckbox} style={{ right: '7px' }} />} // Custom icon for unchecked state
            checkedIcon={<CheckBox style={{ color: COLORS.MAINCOLOR }} />}
          />
        }
        className={Style.checkBoxLabel}
        classes={{ label: Style.label }}
        label='Presently working'
      />
    </FormGroup>
  )
}

export const AddButton = ({
  onClick,
  title,
  subTitle,
  button,
}: {
  onClick: () => void
  title: string
  subTitle: string
  button: string
}) => {
  return (
    <>
      <Grid xs={12} className={Style.cardAddInstance}>
        <Box>
          <Typography className={Style.addNamesHeading} variant='body1'>
            {title}
          </Typography>
          <Typography className={Style.addNamesText} variant='body1'>
            {subTitle}
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={onClick}
            className={Style.addNamesButton}
            variant='contained'
          >
            {button}
          </Button>
        </Box>
      </Grid>
    </>
  )
}

export const ArraySelection = ({
  round,
  title,
  values,
  formValue,
  handleItemClick,
}: {
  round?: boolean
  title: string
  values: string[] | number[]
  formValue: string | number
  handleItemClick: (value: string | number | undefined) => void
}) => {
  const ref = createRef<null | HTMLDivElement>()

  const startRef = useRef<null | HTMLDivElement>(null)
  const endRef = useRef<null | HTMLDivElement>(null)
  const [showScroll, setShowScroll] = useState(false)

  useEffect(() => {
    if (
      ref.current?.clientWidth &&
      endRef.current?.offsetLeft &&
      ref.current?.clientWidth < endRef.current?.offsetLeft
    ) {
      setShowScroll(true)
    }
  }, [ref])
  const refference = (i: number) => {
    if (i === 0) {
      return startRef
    } else if (i === values.length - 1) {
      return endRef
    }
    return null
  }
  return (
    <>
      <Grid width={'100%'}>
        <InputLabel className={Style.label}>{title}</InputLabel>
        <Box ref={ref} className={Style.radioBoxWrapper}>
          {showScroll && (
            <img
              onClick={() => {
                startRef.current?.scrollIntoView({
                  block: 'nearest',
                  inline: 'start',
                })
              }}
              src={smallArrowCircle}
              alt='<'
            />
          )}
          <Box className={Style.radioBoxWrapper}>
            {values.map((value, index) => (
              <Box
                key={index}
                ref={refference(index)}
                className={
                  formValue === value ? Style.radioBox : Style.radioBoxActive
                }
                onClick={() => {
                  handleItemClick(value)
                }}
                borderRadius={round ? '50% !important' : undefined}
              >
                <InputLabel className={Style.label}>{value}</InputLabel>
              </Box>
            ))}
          </Box>
          {showScroll && (
            <img
              onClick={() => {
                endRef.current?.scrollIntoView({
                  block: 'nearest',
                  inline: 'start',
                })
              }}
              style={{ rotate: '180deg' }}
              src={smallArrowCircle}
              alt='<'
            />
          )}
        </Box>
      </Grid>
    </>
  )
}
