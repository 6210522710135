import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleResponse } from 'src/Utils/Constants'
import { PermissionType } from '../Services/Types/permissionType'
import PermissionService from '../Services/permissionService'
import { thunkAPItype } from '../store'
import { LogoutRequest } from './authSlice'

const initialState: {
  success: boolean
  loading: boolean
  data: PermissionType
} = {
  success: false,
  loading: false,
  data: {
    name: '',
    scope: '',
    description: '',
  },
}

// Define the reducers that can modify the state
const permissionManageSlice = createSlice({
  name: 'permissionsManage',
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setPermissionLoading: (state) => {
      state.loading = true
    },
    setPermissionSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setPermissionFail: (state) => {
      state.success = false
      state.loading = false
      state.data = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewPermissionRequest.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(ViewPermissionRequest.fulfilled, (state, action) => {
      state.success = action.payload?.success
      state.loading = false
      state.data = action.payload?.data
    })
    builder.addCase(ViewPermissionRequest.rejected, (state) => {
      state.success = false
      state.loading = false
    })
    builder.addCase(AddPermissionRequest.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(AddPermissionRequest.fulfilled, (state, action) => {
      state.success = action.payload?.success
      state.loading = false
      state.data = action.payload?.data
    })
    builder.addCase(AddPermissionRequest.rejected, (state) => {
      state.success = false
      state.loading = false
    })
    builder.addCase(EditPermissionRequest.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(EditPermissionRequest.fulfilled, (state, action) => {
      state.success = action.payload?.success
      state.loading = false
      state.data = action.payload?.data
    })
    builder.addCase(EditPermissionRequest.rejected, (state) => {
      state.success = false
      state.loading = false
    })
    builder.addCase(DeletPermissionRequest.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(DeletPermissionRequest.fulfilled, (state, action) => {
      state.success = action.payload?.success
      state.loading = false
      state.data = action.payload?.data
    })
    builder.addCase(DeletPermissionRequest.rejected, (state) => {
      state.success = false
      state.loading = false
    })
    builder.addCase(LogoutRequest.fulfilled, (state) => {
      state = initialState
    })
  },
})

export const {
  setPermissions,
  setPermissionLoading,
  setPermissionSuccess,
  setPermissionFail,
} = permissionManageSlice.actions

// Define the async thunk action creator for sign in
export const ViewPermissionRequest = createAsyncThunk(
  'viewPermission', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const token = thunkAPI.getState().auth.data?.token
      const response = await PermissionService.viewPermission(token) // make the API call
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddPermissionRequest = createAsyncThunk(
  'addPermission', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const token = thunkAPI.getState().auth.data?.token
      const response = await PermissionService.addPermission(data, token) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditPermissionRequest = createAsyncThunk(
  'editPermission', // name of the action
  async (
    { uuid, data }: { uuid: string; data: PermissionType },
    thunkAPI: thunkAPItype,
  ) => {
    try {
      const { dispatch } = thunkAPI
      const token = thunkAPI.getState().auth.data?.token
      const response = await PermissionService.editPermission(uuid, data, token) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const DeletPermissionRequest = createAsyncThunk(
  'deletPermission', // name of the action
  async (data: string, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const token = thunkAPI.getState().auth.data?.token
      const response = await PermissionService.deletPermission(data, token) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default permissionManageSlice.reducer
