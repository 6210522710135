import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import Styles from '../index.module.scss'
import DisabilityBackground from '../EditForms/DisabilityBackground'
import { convertISOToDate, hasEmpty } from 'src/Utils/Constants'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'

const DisabilityDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)
  const disabilityBackgroundRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    disabilityBackgroundRef?.current?.scrollIntoView()
  }
  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.DisabilityDetails?.DisabilityBackground,
  )

  const disabilityBackgroundSectionId = useAppSelector(
    (state) =>
      state?.steps?.DisablilityApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Disability Background',
      )?.sectionId,
  )

  return (
    <div id={disabilityBackgroundSectionId} ref={disabilityBackgroundRef}>
      <>
        {!editMode ? (
          <>
            <Box className={Styles.MainBox}>
              <Box className={Styles.title}>
                <Box className={Styles.main}>
                  <Typography variant='h6' className={Styles.textWrapper}>
                    Disability Background
                  </Typography>
                  <Button
                    className={Styles.button}
                    disabled={hasEmpty(data)}
                    onClick={handleEditToggle}
                  >
                    <Typography className={Styles.div}>Edit</Typography>
                  </Button>
                </Box>
                <div className={Styles.dottedLine} />
              </Box>

              {!hasEmpty(data) ? (
                <>
                  <Box className={Styles.div2}>
                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.p}>
                          Able to Work With Disability
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data?.isAbleToWork ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>

                    {data?.isAbleToWork ? (
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Able to Perform Usual Job Responsibilities
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.isAbleToPerformUsualJob ? 'Yes' : 'No'}
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}

                    {data?.isAbleToPerformUsualJob === true ? (
                      <>
                        <Box className={Styles.element2}>
                          <Box className={Styles.question}>
                            <Typography className={Styles.textWrapper3}>
                              Able to Work In the Last 14 Months
                            </Typography>
                          </Box>
                          <Box className={Styles.ans}>
                            <Typography className={Styles.textWrapper2}>
                              {' '}
                              {data?.is14MonthsUnableToDoJob ? 'Yes' : 'No'}
                            </Typography>
                          </Box>
                        </Box>

                        <Box className={Styles.element}>
                          <Box className={Styles.question}>
                            <Typography className={Styles.textWrapper3}>
                              Injury, illness or condition expeted from last 1
                              year
                            </Typography>
                          </Box>
                          <Box className={Styles.ans}>
                            <Typography className={Styles.textWrapper2}>
                              {data?.isDisabilityExpected1YearOrLonger
                                ? 'Yes'
                                : 'No'}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}

                    {data?.isAbleToPerformUsualJob === false && (
                      <>
                        <Box className={Styles.element}>
                          <Box className={Styles.question}>
                            <Typography className={Styles.textWrapper3}>
                              Injury, illness or condition expeted from last 1
                              year
                            </Typography>
                          </Box>
                          <Box className={Styles.ans}>
                            <Typography className={Styles.textWrapper2}>
                              {data?.isDisabilityExpected1YearOrLonger
                                ? 'Yes'
                                : 'No'}
                            </Typography>
                          </Box>
                        </Box>

                        <Box className={Styles.element}>
                          <Box className={Styles.question}>
                            <Typography className={Styles.textWrapper3}>
                              Injury, illness or condition start date
                            </Typography>
                          </Box>
                          <Box className={Styles.ans}>
                            <Typography className={Styles.textWrapper2}>
                              {convertISOToDate(data?.disabilityStartDate)}
                            </Typography>
                          </Box>
                        </Box>

                        <Box className={Styles.element2}>
                          <Box className={Styles.question}>
                            <Typography className={Styles.textWrapper3}>
                              Medical professional described any of your
                              specific conditions as one that&#39;s expected to
                              end in death
                            </Typography>
                          </Box>
                          <Box className={Styles.ans}>
                            <Typography className={Styles.textWrapper2}>
                              {' '}
                              {data?.disabilityEndInDeath ? 'Yes' : 'No'}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}

                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.textWrapper3}>
                          Are your illness(es), injuries, or condition(s)
                          related to work in any way
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data?.disabilityRelatedToWork ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <CustomAlert message='no data available.' />
              )}
            </Box>
          </>
        ) : (
          <>
            <Grid m={2}>
              <Grid container m={2}>
                <Grid lg={10} className='alignItemCenter' display='flex'>
                  <Typography variant='h1' className='FontBold'>
                    Disability Background
                  </Typography>
                </Grid>
                <Divider
                  sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
                />
                <Grid container m={2} ml={0}>
                  <>
                    <DisabilityBackground handleEditToggle={handleEditToggle} />
                  </>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </div>
  )
}

export default DisabilityDetails
