import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import Styles from '../index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import MedicalTests from '../EditForms/MedicalTests'
import { convertISOToDate, hasEmpty } from 'src/Utils/Constants'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
const MedicalDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)
  const medicalTestRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    medicalTestRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) => state?.applicationStore?.MedicalDetails?.MedicalTest,
  )
  interface MedicalTest {
    suffix: string
    doctorFirstName: string
    doctorLastName: string
    medicalTestName: string
    testedBodyPart: string
    mostRecentDateTestPerformed: string
    futureDateTestPerform: string
  }

  const medicalTestsSectionId = useAppSelector(
    (state) =>
      state?.steps?.MedicalApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Medical Tests',
      )?.sectionId,
  )

  return (
    <div id={medicalTestsSectionId} ref={medicalTestRef}>
      {!editMode ? (
        <>
          {' '}
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  Medical Tests
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>
            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Any medical tests completed related to your illnesses,
                        injuries, or conditions
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isMedicalTestCompleted ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {data?.isMedicalTestCompleted ? (
                  <>
                    {' '}
                    <Box className={Styles.div2}>
                      <Typography variant='h6' className={Styles.SubTitle}>
                        Added Medical Tests
                      </Typography>
                    </Box>
                    {/* card-1 */}
                    {data?.medicalTest?.map(
                      (item: MedicalTest, index: number) => (
                        <>
                          <Box className={Styles.card} key={index}>
                            <Box className={Styles.cardDiv}>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Doctor's Name
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {` ${item?.suffix}  ${item?.doctorFirstName} ${item?.doctorLastName} `}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Medical Test
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.medicalTestName}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    ></Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    ></Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Test was performed on
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {item?.testedBodyPart}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Recent date on which the test was
                                      performed
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(
                                        item?.mostRecentDateTestPerformed,
                                      )}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Future estimate the future date
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper3}
                                    >
                                      {convertISOToDate(
                                        item?.futureDateTestPerform,
                                      )}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </>
                      ),
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Medical Tests
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <MedicalTests handleEditToggle={handleEditToggle} />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default MedicalDetails
