import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import { ViewPermissionDetailRequest } from 'src/Redux/Slices/permissionDetail'
import {
  EditPermissionRequest,
  ViewPermissionRequest,
} from 'src/Redux/Slices/permissionManageSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'
interface datatype {
  name: string
  scope: string
  description: string
}
const PopupForm = ({
  permissionId,
  Editopen,
  setEditOpen,
  handleEditOpen,
}: {
  permissionId: string
  Editopen: boolean
  setEditOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleEditOpen: () => void
}) => {
  const dispatch = useAppDispatch()
  const PermissionList = useAppSelector(
    (state) => state.permissionManage?.permissionDetails?.data,
  )

  const handleClose = () => {
    setEditOpen(false)
  }

  useEffect(() => {
    dispatch(ViewPermissionDetailRequest(permissionId))
  }, [permissionId])

  const [formValues, setFormValues] = useState<datatype>({
    name: PermissionList?.name ?? '',
    scope: PermissionList?.scope ?? '',
    description: PermissionList?.description ?? '',
  })
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    // You may perform validation here and update errors state accordingly
  }
  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (!formValues.name.trim()) {
      newErrors.name = 'Permission Name is required'
      valid = false
    }
    if (!formValues.scope.trim()) {
      newErrors.scope = 'Scope is required'
      valid = false
    }
    if (!formValues.description.trim()) {
      newErrors.description = 'Description is required'
      valid = false
    }

    setErrors(newErrors)
    return valid
  }

  useEffect(() => {
    if (permissionId && PermissionList) {
      setFormValues({
        name: PermissionList?.name ?? '',
        scope: PermissionList?.scope ?? '',
        description: PermissionList?.description ?? '',
      })
    }
  }, [permissionId, PermissionList])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      dispatch(
        EditPermissionRequest({
          uuid: permissionId,
          data: {
            name: formValues.name,
            scope: formValues.scope,
            description: formValues.description,
          },
        }),
      ).then(() => {
        dispatch(ViewPermissionRequest())
      })
      setEditOpen(false)
    } else setEditOpen(true)
  }

  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={Editopen}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Edit Permission
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='threeDot'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={Style.DialogContentBox}>
              <form onSubmit={handleSubmit}>
                <Box width={'100%'}>
                  <InputLabel className={Style.label}>
                    Permission Name <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='text'
                    id='name'
                    name='name'
                    className={Style.AnsTxt}
                    placeholder='Permission Name'
                    value={formValues?.name}
                    onChange={handleFieldChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Box>

                <Box width={'100%'}>
                  <InputLabel className={Style.label} htmlFor='scope'>
                    Scope <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Select
                    onChange={(e) => {
                      handleFieldChange(e)
                    }}
                    className={Style.AnsTxt}
                    IconComponent={(props) => (
                      <IconButton {...props}>
                        <img src={IconChevronDown} alt='IconChevronDown' />
                      </IconButton>
                    )}
                    displayEmpty
                    renderValue={
                      formValues.scope !== ''
                        ? () => formValues.scope
                        : () => 'Select'
                    }
                    value={formValues.scope}
                    fullWidth
                    id='scope'
                    name='scope'
                    placeholder='Scope'
                    error={!!errors.scope}
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value='Admin'>Admin</MenuItem>
                    <MenuItem value='Legal'>Legal</MenuItem>
                    <MenuItem value='Both'>Both</MenuItem>
                  </Select>
                </Box>

                <Box width={'100%'} pt={1}>
                  <InputLabel className={Style.label}>
                    Description <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='text'
                    id='description'
                    name='description'
                    placeholder='Description'
                    multiline
                    rows={4}
                    className={Style.AnsTxt}
                    value={formValues.description}
                    onChange={handleFieldChange}
                    error={!!errors.description}
                    helperText={errors.description}
                  />
                </Box>
                <DialogActions>
                  <Box className={Style.AddButton}>
                    <Button
                      variant='contained'
                      type='submit'
                      className={Style.Button}
                    >
                      Save
                    </Button>
                  </Box>
                </DialogActions>
              </form>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default PopupForm
