import { useEffect, useRef, useState } from 'react'
import mic from 'src/Assets/Icons/mic.svg'
import Style from './index.module.scss'
import { Box } from '@mui/material'
import { chatbot } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'

const AudioRecorder = ({
  setRecordedUrl,
  setChatStack,
  recordedUrl,
  setChatForm,
}: {
  setRecordedUrl: React.Dispatch<React.SetStateAction<string | Blob>>
  setChatStack: React.Dispatch<
    React.SetStateAction<
      Array<{
        message: string | Blob
        type: string
      }>
    >
  >
  recordedUrl: string | Blob
  setChatForm: React.Dispatch<React.SetStateAction<any>>
}) => {
  const [isActive, setIsActive] = useState(false)
  const mediaStream = useRef(null)
  const mediaRecorder = useRef(null)
  const chunks = useRef([])

  // ----------- recorder ------------------
  const startRecording = async () => {
    setIsActive(true)
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      mediaStream.current = stream
      mediaRecorder.current = new MediaRecorder(stream)
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data)
        }
      }
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: 'audio/webm' })
        // const url = URL.createObjectURL(recordedBlob);
        chunks.current = []
        setRecordedUrl(recordedBlob)
      }
      mediaRecorder.current.start()
    } catch (error) {
      console.error('Error accessing microphone:', error)
    }
  }
  const stopRecording = () => {
    setIsActive(false)
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop()
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop()
      })
    }
  }
  const dispatch = useAppDispatch()
  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  useEffect(() => {
    if (recordedUrl && !isActive) {
      setChatStack((chatStack) => {
        return [...chatStack, { message: recordedUrl, type: 'audio' }]
      })
      dispatch(chatbot({ file: recordedUrl, uuid: detailId })).then(
        (response) => {
          if (response.payload) {
            const chatResponce = response.payload.response
              .map((item: { text: string }) => item.text)
              .join('\n')
            const buttons = response.payload.response
              .map(
                (item: {
                  buttons?: Array<{ title: string; payload: string }>
                }) => item.buttons,
              )
              .flat(Infinity)
            setChatStack((chatStack) => {
              return [
                ...chatStack,
                {
                  message: chatResponce,
                  buttons: buttons[0] ? buttons : undefined,
                  type: 'bot',
                },
              ]
            })
            if (response.payload?.form) {
              setChatForm(response.payload.form)
            }
          }
        },
      )
      setRecordedUrl('')
    }
  }, [recordedUrl, isActive])
  return (
    <Box className={Style.recorder}>
      <button
        className={isActive ? Style.micbuttonactive : Style.micbutton}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (isActive) stopRecording()
          else startRecording()
        }}
      >
        <img src={mic} alt='mic' />
      </button>
    </Box>
  )
}
export default AudioRecorder
