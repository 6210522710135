import React from 'react'

const Setting: React.FunctionComponent = () => {
  return (
    <>
      <h1>Setting</h1>
    </>
  )
}

export default Setting
