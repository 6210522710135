import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  Typography,
  type TypographyProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FooterContent from 'src/Components/FooterContent'
import { SignInRequest } from 'src/Redux/Slices/authSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import {
  COLORS,
  FONT_ONE,
  FW_BOLD,
  FW_REGULAR,
  Icons,
} from 'src/Utils/Constants'
import { ROUTES } from 'src/Utils/Routes'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import styles from './index.module.scss'
import signBg from 'src/Assets/Icons/signBg.svg'
import { PayloadAction } from '@reduxjs/toolkit'
import { ViewProfileRequest } from 'src/Redux/Slices/profileSlice'
import secureLocalStorage from 'react-secure-storage'
import { setPublic } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'

export type DynamicObj = Record<string, boolean | any>

const {
  textAlignEnd,
  wBox,
  bgCmn1,
  br20,
  containerHeight,
  secondContainerHeight,
  p10,
  center,
} = styles

const SignIn: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const SubTypography = styled(Typography)<TypographyProps>({
    fontWeight: FW_REGULAR,
    fontSize: FONT_ONE,
    color: COLORS.COLOR_BLUE,
    marginBottom: '50px',
  })
  interface stateType {}

  interface SerializedError {}
  interface PayloadType {
    data?: any
    warn?: boolean | object
    success?: boolean | object
  }
  const dispatch = useAppDispatch()
  const isPublic = useAppSelector(
    (state) => state.applicationStore.ApplicationId.isPublic,
  )
  useEffect(() => {
    if (secureLocalStorage.getItem('token') && !isPublic) {
      navigate('/dashboard')
    } else secureLocalStorage.removeItem('token')
  }, [])
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [isWrongPass, setIsWrongPass] = React.useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const emailValidation = !/^\S+@\S+\.\S+$/.test(email)
  const passwordValidation = password.length < 8

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setEmail(value)
    setIsWrongPass(false)
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setPassword(value)
    setIsWrongPass(false)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const handleSignUp = () => {
    navigate('/signup')
  }

  const handleSignIn = (e) => {
    e.preventDefault()
    if (emailValidation || passwordValidation) {
      setIsWrongPass(true)
      return
    }

    const loginData = {
      emailId: email,
      password,
    }
    dispatch(SignInRequest(loginData)).then(
      (
        res:
          | PayloadAction<
              stateType,
              string,
              {
                arg
                requestId: string
                requestStatus: 'fulfilled'
              },
              never
            >
          | PayloadAction<
              unknown,
              string,
              {
                arg: null | unknown
                requestId: string
                requestStatus: 'rejected'
                aborted: boolean
                condition: boolean
              } & ({} | ({} & {})),
              SerializedError
            >,
      ) => {
        const payload = res.payload as PayloadType

        if (payload?.success) {
          dispatch(setPublic(false))
          navigate(ROUTES.DASHBOARD)
          dispatch(ViewProfileRequest())
        } else {
          setIsWrongPass(true)
        }
        if (payload?.data?.isSubscriptionCancelled) {
          navigate(ROUTES.SIGN_UP, {
            state: { step: 'subscription', emailId: email },
          })
        }
      },
    )
  }
  return (
    <>
      <Box>
        <Grid
          container
          style={{ backgroundImage: `url(${signBg})` }}
          className={`${p10} ${containerHeight}`}
        >
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              margin: '20px 30px',
            }}
          >
            <img
              src={Icons.headerLogo}
              alt='header'
              style={{
                filter: 'invert(1) sepia(1) saturate(0) hue-rotate(180deg)',
              }}
            />
          </Box>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={`${bgCmn1} ${br20} ${secondContainerHeight}`}
            display={'grid'}
          >
            <Grid container direction='row'>
              <Grid item lg={12} md={12} sm={12} xs={12} height='10vh'></Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                p={{ lg: 1, sm: 0 }}
                xs={12}
                height='80vh'
                className={`${center}`}
              >
                <form onSubmit={handleSignIn}>
                  <Grid className={`${wBox}`}>
                    <Typography variant='h5' sx={{ fontWeight: FW_BOLD }}>
                      Sign In
                    </Typography>

                    <SubTypography variant='body1' className={styles.subTitle}>
                      Sign in by filling out the below details.
                    </SubTypography>

                    <InputLabel htmlFor='Email' style={{ marginTop: '20px' }}>
                      Email Address
                    </InputLabel>

                    <TextField
                      fullWidth
                      margin='normal'
                      type='email'
                      id='Email'
                      sx={{ marginBottom: '20px' }}
                      onChange={handleEmailChange}
                      error={emailValidation && isWrongPass}
                      helperText={
                        emailValidation && isWrongPass
                          ? 'Please enter a valid email address'
                          : undefined
                      }
                      value={email}
                    />

                    <Grid container>
                      <Grid item md={6} lg={6} xs={6} sm={6}>
                        <InputLabel htmlFor='Password'>Password</InputLabel>
                      </Grid>
                      <Grid item lg={6} md={6} xs={6} sm={6}>
                        <InputLabel
                          component={Link}
                          onClick={() => {
                            navigate(ROUTES.FORGOT_PASSWORD)
                          }}
                          sx={{
                            textDecoration: 'none',
                            color: '#7752FE',
                            cursor: 'pointer',
                          }}
                          className={`${textAlignEnd}`}
                        >
                          Forgot Password?
                        </InputLabel>
                      </Grid>
                    </Grid>

                    <TextField
                      tabIndex={-1}
                      fullWidth
                      margin='normal'
                      type={showPassword ? 'text' : 'password'}
                      id='Password'
                      sx={{ marginBottom: '40px' }}
                      onChange={handlePasswordChange}
                      error={passwordValidation && isWrongPass}
                      helperText={
                        passwordValidation && isWrongPass
                          ? 'Password must be at least 8 characters'
                          : undefined
                      }
                      value={password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge='end'
                            >
                              {!showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button type='submit' className={styles.button}>
                      <Typography className={styles.mediumText}>
                        Sign In
                      </Typography>
                    </Button>

                    <Box onClick={handleSignUp} className={styles.signin}>
                      <Typography className={styles.textWrapper8}>
                        Not a member yet?&nbsp;
                      </Typography>
                      <Typography className={styles.textFieldWrapper}>
                        Sign Up
                      </Typography>
                    </Box>
                  </Grid>
                </form>
              </Grid>
            </Grid>
            <FooterContent />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default SignIn
