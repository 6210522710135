import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import Styles from '../index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import VisionHearing from '../EditForms/VisionHearing'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { hasEmpty } from 'src/Utils/Constants'
const MedicalDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)
  const visionRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    visionRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.MedicalDetails?.VisionAndHearing,
  )

  const visionHearingSectionId = useAppSelector(
    (state) =>
      state?.steps?.MedicalApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Vision & Hearing',
      )?.sectionId,
  )
  return (
    <div id={visionHearingSectionId} ref={visionRef}>
      {!editMode ? (
        <>
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  Vision & Hearing
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>

            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Have 20/200 or less vision
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.zeroOrLessVision2020 ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {data?.zeroOrLessVision2020 ? null : (
                  <>
                    <Box className={Styles.cardDiv}>
                      {/* row-1 */}
                      <Grid container className={Styles.cardFrame}>
                        <Grid item xs={6} className={Styles.cardElement}>
                          <Box className={Styles.cardQuestion}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper}
                            >
                              Use any vision correctors
                            </Typography>
                          </Box>
                          <Box className={Styles.cardAns}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper3}
                            >
                              {data?.visionCorrectorDetail}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} className={Styles.cardElement}>
                          <Box className={Styles.cardQuestion}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper}
                            >
                              Experience impaired vision even with glasses or
                              contacts
                            </Typography>
                          </Box>
                          <Box className={Styles.cardAns}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper3}
                            >
                              {data?.isImpairedVision ? 'Yes' : 'No'}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      {/* row-2 */}
                      <Grid container className={Styles.cardFrame}>
                        <Grid item xs={12} className={Styles.cardElement}>
                          <Box className={Styles.cardQuestion}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper}
                            >
                              Vision-related symptoms
                            </Typography>
                          </Box>
                          <Box className={Styles.cardAns}>
                            {data?.visionRelatedSymptoms?.map(
                              (item: string) => (
                                <>
                                  <Typography
                                    variant='body1'
                                    className={Styles.textWrapper4}
                                  >
                                    {item}
                                  </Typography>
                                </>
                              ),
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                      {/* row-3 */}
                      <Grid container className={Styles.cardFrame}>
                        <Grid item xs={12} className={Styles.cardElement}>
                          <Box className={Styles.cardQuestion}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper}
                            >
                              Additional vision-related symptoms
                            </Typography>
                          </Box>
                          <Box className={Styles.cardAns}>
                            <Typography
                              variant='body1'
                              className={Styles.textWrapper2}
                            >
                              {data?.additionalVisionRelatedSymptoms}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}

                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Partially or totally deaf
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isPartialOrTotalDeaf ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Use any hearing aids
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isUsingHearingAids ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {data?.isUsingHearingAids && (
                  <>
                    <Box className={Styles.cardDiv}>
                      {/* row-1 */}
                      <Grid container className={Styles.cardFrame}>
                        {/* <Grid item xs={6} className={Styles.cardElement}>
                        <Box className={Styles.cardQuestion}>
                          <Typography
                            variant='body1'
                            className={Styles.cardTextWrapper}
                          >
                            Use any hearing aids
                          </Typography>
                        </Box>
                        <Box className={Styles.cardAns}>
                          <Typography
                            variant='body1'
                            className={Styles.cardTextWrapper3}
                          >
                            {data?.isUsingHearingAids ? 'Yes' : 'No'}
                          </Typography>
                        </Box>
                      </Grid> */}
                        {data?.isUsingHearingAids && (
                          <Grid item xs={6} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                experience impaired hearing, even with hearing
                                aids
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.isImpairedHearing ? 'Yes' : 'No'}
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                      </Grid>
                      {/* row-2 */}
                      {data?.isImpairedHearing && (
                        <>
                          <Grid container className={Styles.cardFrame}>
                            <Grid item xs={12} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Hearing-related symptoms
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                {data?.hearingSymptoms?.map((item: string) => (
                                  <>
                                    <Typography
                                      variant='body1'
                                      className={Styles.textWrapper4}
                                    >
                                      {item}
                                    </Typography>
                                  </>
                                ))}
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container className={Styles.cardFrame}>
                            <Grid item xs={12} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Additional hearing-related symptoms
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={Styles.textWrapper2}
                                >
                                  {data?.additionalHearingRelatedSymptoms}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>{' '}
                        </>
                      )}
                    </Box>
                  </>
                )}
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Vision & Hearing
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <VisionHearing handleEditToggle={handleEditToggle} />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default MedicalDetails
