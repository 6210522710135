import { Button, InputLabel, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import quote from 'src/Assets/Icons/quots.svg'
import star from 'src/Assets/Icons/star.svg'
import { addAdditionalInformation } from 'src/Redux/Slices/ApplicationSlices/MedicalDetailsSlice'
import { setAdditionalInformation } from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../index.module.scss'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/Utils/Routes'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'

const AdditionalInformation = ({
  handleNext,
  handleBack,
  chatForm,
}: {
  chatForm: any
  handleBack: () => void
  handleNext: () => void
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const AdditionalInformationState = useAppSelector(
    (state) => state?.applicationStore?.MedicalDetails?.AdditionalInformation,
  )

  const MedicalDetailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.MedicalDetailId,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const [formValues, setFormValues] = useState<any>({
    medicalDetailId: MedicalDetailId,
    additionalInformation: '',
  })

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({
      ...AdditionalInformationState,
      medicalDetailId: MedicalDetailId,
    })
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: { ...formValues },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    if (!formValues.additionalInformation?.trim()) {
      newErrors.additionalInformation = 'Please enter additional information'
      valid = false
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (validateForm()) {
      dispatch(setAdditionalInformation(formValues))
      dispatch(addAdditionalInformation(formValues)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            handleNext()
            navigate(ROUTES.UPLOAD_DOCUMENT)
          }
        },
      )
    }
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              py: 2,
              my: 3,
              borderRadius: '10px',
            }}
          >
            <img src={quote} className={Style.star} alt='' />
            <Typography className={Style.Heading}>
              Please provide any additional information or remarks that you
              would like to send with this application.
            </Typography>
          </Box>

          <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
            <Grid width={'100%'}>
              <InputLabel className={Style.label}>
                Additional Information
              </InputLabel>
              <TextField
                fullWidth
                type='text'
                id='additionalInformation'
                name='additionalInformation'
                multiline
                rows={12}
                className={Style.AnsTxt}
                placeholder='Additional Information'
                value={formValues?.additionalInformation}
                onChange={handleFieldChange}
                error={!!errors.additionalInformation}
                helperText={errors.additionalInformation}
              />
            </Grid>
          </Grid>

          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              p: 2,
              my: 4,
              backgroundColor: '#ECF6FF',
              borderRadius: '10px',
            }}
          >
            <img src={star} className={Style.star} alt='' />
            <Typography>
              <i>
                Way to go! We recognize that this process can be challenging.
                However, you just have a few steps left! You'll need to upload
                your W2 form(s), sign the consent forms, and review your
                application one last time to make sure that everything is
                accurate.
              </i>
            </Typography>
          </Box>

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleBack}
            >
              Previous
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Continue
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default AdditionalInformation
