import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import {
  AddSubscriptionRequest,
  EditSubscriptionRequest,
  SubscriptionDetailsRequest,
  SubscriptionListRequest,
} from 'src/Redux/Slices/subscriptionSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { IOSSwitch } from 'src/Theme'
import { Icons } from 'src/Utils/Constants'
import Style from '../index.module.scss'

const AddEdit: React.FunctionComponent<{
  type: string
  openEdit: boolean
  handleCloseEdit: () => void
  subscriptionId?: string
}> = ({ type, openEdit, handleCloseEdit, subscriptionId }) => {
  const dispatch = useAppDispatch()
  const subscriptionData: {
    id: string
    name: string
    plan: string
    amount: number
    pricePerApplication: number
    subscriptionPeriod: number
    numberOfApplication: number
    numberOfseats: number
    status: string
    createdAt: string
    description: string
  } = useAppSelector((state) => state.subscription?.details?.data)
  const [formData, setFormData] = React.useState({
    uuid: '',
    name: '',
    plan: '',
    amount: 0,
    pricePerApplication: 0,
    numberOfApplication: 0,
    numberOfseats: 0,
    subscriptionPeriod: 0,
    description: '',
    isActive: true,
  })
  const [formError, setFormError] = React.useState<{
    name?: string
    plan?: string
    amount?: string
    pricePerApplication?: string
    numberOfApplication?: string
    numberOfseats?: string
    subscriptionPeriod?: string
    description?: string
  }>({
    name: '',
    plan: '',
    amount: '',
    pricePerApplication: '',
    numberOfApplication: '',
    numberOfseats: '',
    subscriptionPeriod: '',
    description: '',
  })

  useEffect(() => {
    if (type === 'edit') {
      dispatch(SubscriptionDetailsRequest(subscriptionId))
    }
  }, [])
  useEffect(() => {
    if (type === 'edit') {
      setFormData({
        ...formData,
        uuid: subscriptionData?.id,
        name: subscriptionData?.name,
        plan: subscriptionData?.plan,
        amount: subscriptionData?.amount,
        pricePerApplication: subscriptionData?.pricePerApplication,
        numberOfApplication: subscriptionData?.numberOfApplication,
        numberOfseats: subscriptionData?.numberOfseats,
        subscriptionPeriod: subscriptionData?.subscriptionPeriod,
        description: subscriptionData?.description,
        isActive: subscriptionData?.status === 'active',
      })
    }
  }, [subscriptionData])
  const handleFormChange = (event: {
    target: { name: string; value: string | number }
  }) => {
    setFormError({})
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }
  const handleSubmit = () => {
    if (!validateForm()) {
      return
    }
    if (type === 'edit') {
      dispatch(EditSubscriptionRequest(formData)).then((res) => {
        if (res.payload?.success) {
          dispatch(SubscriptionListRequest())
          handleCloseEdit()
        }
      })
    }
    if (type === 'add') {
      dispatch(AddSubscriptionRequest(formData)).then((res) => {
        if (res.payload?.success) {
          dispatch(SubscriptionListRequest())
          handleCloseEdit()
        }
      })
    }
  }
  const validateForm = () => {
    let valid = true
    const newErrors: typeof formError = {}
    if (!formData.name.trim()) {
      newErrors.name = 'Plan Name is required'
      valid = false
    }
    if (!formData.plan.trim()) {
      newErrors.plan = 'Plan is required'
      valid = false
    }
    if (!formData.amount) {
      newErrors.amount = 'Amount is required'
      valid = false
    }
    if (!formData.subscriptionPeriod) {
      newErrors.subscriptionPeriod = 'Subscription Period is required'
      valid = false
    }
    if (!formData.numberOfApplication) {
      newErrors.numberOfApplication = 'Number of Applicationss is required'
      valid = false
    }
    if (!formData.numberOfseats) {
      newErrors.numberOfseats = 'Max Number of Seats is required'
      valid = false
    }
    if (!formData.description.trim()) {
      newErrors.description = 'Description is required'
      valid = false
    } else if (formData.description.trim().length > 199) {
      newErrors.description = 'Description should not exceed 200 characters'
      valid = false
    }
    setFormError(newErrors)
    return valid
  }
  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={openEdit}
        onClose={handleCloseEdit}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                {type.charAt(0).toUpperCase() + type.slice(1)} Subscription Plan
                <img
                  onClick={handleCloseEdit}
                  src={Icons.Cancel}
                  alt='Cancel'
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent className={Style.DialogContentBox}>
            <Box className={Style.flexStartBox}>
              <Typography className={Style.textFieldWrapper}>
                Plan Name
                <Typography className={Style.FF002E}>*</Typography>
              </Typography>
              <TextField
                fullWidth
                variant='outlined'
                className={Style.field}
                placeholder=''
                value={formData?.name}
                onChange={(e) => {
                  handleFormChange(e)
                }}
                name='name'
                error={!!formError?.name}
                helperText={formError?.name}
              />
            </Box>
            <Box className={Style.flexStartBox}>
              <Typography className={Style.textFieldWrapper}>
                Plan Description
                <Typography className={Style.FF002E}>*</Typography>
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                variant='outlined'
                className={Style.field}
                placeholder='Enter your plan description'
                value={formData?.description}
                onChange={(e) => {
                  handleFormChange(e)
                }}
                inputProps={{ maxLength: 200 }}
                name='description'
                error={!!formError?.description}
                helperText={formError?.description}
              />
            </Box>
            <Box className={Style.flexSpaceBox}>
              <Box className={Style.fullWidth}>
                <Typography className={Style.textFieldWrapper}>
                  Subscription Price
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  className={Style.field}
                  placeholder=''
                  value={`${formData?.amount}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>$</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    handleFormChange(e)
                  }}
                  name='amount'
                  error={!!formError?.amount}
                  helperText={formError?.amount}
                />
              </Box>
              <Box className={Style.fullWidth}>
                <Typography className={Style.textFieldWrapper}>
                  Repeat Payment Every
                  <Typography className={Style.FF002E}>*</Typography>
                </Typography>
                <Box className={Style.noGap}>
                  <TextField
                    variant='outlined'
                    type='number'
                    className={Style.halfField}
                    placeholder=''
                    value={formData?.subscriptionPeriod}
                    onChange={(e) => {
                      handleFormChange(e)
                    }}
                    name='subscriptionPeriod'
                    error={!!formError?.subscriptionPeriod}
                  />
                  <Select
                    className={Style.halfField}
                    IconComponent={(props) => (
                      <IconButton {...props} style={{ padding: '4px 10px' }}>
                        <img
                          src={Icons.IconChevronDown}
                          alt='IconChevronDown'
                        />
                      </IconButton>
                    )}
                    defaultValue={formData?.plan}
                    size='small'
                    name='plan'
                    value={formData?.plan}
                    displayEmpty
                    error={!!formError?.plan}
                    onChange={(e) => {
                      handleFormChange(e)
                    }}
                  >
                    <MenuItem value={'month'}>Month(s)</MenuItem>
                    <MenuItem value={'year'}>Year(s)</MenuItem>
                  </Select>
                </Box>
                {!!formError?.subscriptionPeriod && (
                  <FormHelperText error>
                    {formError?.subscriptionPeriod}
                  </FormHelperText>
                )}
              </Box>
            </Box>

            <Box className={Style.flexSpaceBox}>
              <Box className={Style.fullWidth}>
                <Typography className={Style.textFieldWrapper}>
                  Price Per Application
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  className={Style.field}
                  placeholder=''
                  value={`${formData?.pricePerApplication}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>$</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    handleFormChange(e)
                  }}
                  name='pricePerApplication'
                  error={!!formError?.pricePerApplication}
                  helperText={formError?.pricePerApplication}
                />
              </Box>
              <Box className={Style.fullWidth}>
                <Typography className={Style.textFieldWrapper}>
                  Number of Applications
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  className={Style.field}
                  placeholder=''
                  value={formData?.numberOfApplication}
                  onChange={(e) => {
                    handleFormChange(e)
                  }}
                  name='numberOfApplication'
                  error={!!formError?.numberOfApplication}
                  helperText={formError?.numberOfApplication}
                />
              </Box>
              <Box className={Style.fullWidth}>
                <Typography className={Style.textFieldWrapper}>
                  Max Number of Seats
                </Typography>
                <Select
                  className={Style.inField}
                  IconComponent={(props) => (
                    <IconButton {...props} style={{ padding: '4px 10px' }}>
                      <img src={Icons.IconChevronDown} alt='IconChevronDown' />
                    </IconButton>
                  )}
                  defaultValue={formData?.numberOfseats}
                  size='small'
                  name='numberOfseats'
                  value={formData?.numberOfseats}
                  error={!!formError?.numberOfseats}
                  onChange={(e) => {
                    handleFormChange(e)
                  }}
                >
                  <MenuItem value=''>None</MenuItem>
                  <MenuItem value={'50'}>50</MenuItem>
                  <MenuItem value={'100'}>100</MenuItem>
                  <MenuItem value={'200'}>200</MenuItem>
                  <MenuItem value={'500'}>500</MenuItem>
                </Select>
                {!!formError?.numberOfseats && (
                  <FormHelperText error>
                    {formError?.numberOfseats}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box className={Style.toggleWrapper}>
              <Box className={Style.toggleText}>
                <Typography className={Style.textFieldWrapper}>
                  Active
                </Typography>
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={formData?.isActive}
                      onChange={() => {
                        setFormData({
                          ...formData,
                          isActive: !formData?.isActive,
                        })
                      }}
                    />
                  }
                  label=''
                />
              </Box>
            </Box>
            <DialogActions className={Style.DialogActions}>
              <Button className={Style.ConfirmButton} onClick={handleSubmit}>
                Save Subscription
              </Button>
            </DialogActions>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default AddEdit
