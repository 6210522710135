import React from 'react'
import { Grid, TextField, TextFieldProps, Typography } from '@mui/material'
import { FW_REGULAR, FW_SEMIMEDIUM } from 'src/Utils/Constants'

interface CustomInputProps extends Omit<TextFieldProps, 'label'> {
  label?: string
  required?: boolean
  gridCol?: number
}

const TextInput: React.FC<CustomInputProps> = ({
  label,
  required = false,
  gridCol = 12,
  ...otherProps
}) => {
  return (
    <>
      <Grid lg={gridCol} my={1}>
        <Typography variant='h3' fontWeight={FW_SEMIMEDIUM}>
          {label}
          {required && <span style={{ color: 'red' }}>*</span>}
        </Typography>
      </Grid>
      <Grid lg={gridCol} my={1}>
        <TextField
          id={`id-${label}`}
          variant='outlined'
          fullWidth
          sx={{ fontWeight: FW_REGULAR }}
          {...otherProps}
          required
        />
      </Grid>
    </>
  )
}

export default TextInput
