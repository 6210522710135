// This slice manages the state for the sign in feature
import {
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import secureLocalStorage from 'react-secure-storage'
import FinalSubmitService from 'src/Redux/Services/FinalSubmitFormsService'
import { RootState } from 'src/Redux/store'
import { handleResponse } from 'src/Utils/Constants'

interface FinalSubmitType {
  applicationId: string
}

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: {}
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {},
}

// Define the reducers that can modify the state
const FinalSubmitForm = createSlice({
  name: 'FinalSubmitForm',
  initialState,
  reducers: {
    setFinalSubmitLoading: (state) => {
      state.loading = true
    },
    setFinalSubmitSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setFinalSubmitFail: (state) => {
      state.success = false
      state.loading = false
      state.data = initialState.data
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AddFinalSubmit.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddFinalSubmit.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddFinalSubmit.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

export const {
  setFinalSubmitLoading,
  setFinalSubmitSuccess,
  setFinalSubmitFail,
} = FinalSubmitForm.actions

export const AddFinalSubmit = createAsyncThunk(
  'FinalSubmit', // name of the action
  async (
    data: FinalSubmitType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await FinalSubmitService.FinalSubmit(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default FinalSubmitForm.reducer
