export enum ROUTES {
  DEFAULT_PATH = '/',
  SIGN_IN = '/sign-in',
  STYLE_GUIDE = '/app',
  FORGOT_PASSWORD = '/forgotpassword',
  RESET_PASSWORD = '/resetpassword/*',
  SIGN_UP = '/signup',
  DASHBOARD = '/dashboard',
  ORGANIZATIONS = '/organizations',
  ORGANIZATION_DETAIL = '/organization_detail',
  EMPLOYEES = '/employees', // nothing in content
  WELCOME_PAGE = '/welcome/*',
  ADMIN_MANAGE_EMPLOYEES = '/manage-employees',
  LEGAL_MANAGE_EMPLOYEES = '/manage-employee',
  ROLE = '/role',
  ROLE_DETAILS = '/role/details',
  APPLICATIONS = '/applications',
  APPLICATION_DETAIL = '/applicationdetail',
  SUBSCRIPTION_PLAN = '/subscriptionplan', // nothing in content,
  SETTING = '/setting', // nothing in content,
  ENHANCED_PLAN = '/enhancedplan',
  BILLING_INFO = '/billinginfo',
  PROFILE = '/profile',
  ADMIN_EMPLOYEE_DETAIL = '/employees/details',
  LEGAL_EMPLOYEE_DETAIL = '/employees/detail',
  NOTIFICATION = '/setting/notification',
  ROLE_PERMISSION = '/role-permission',
  PAYMENT_SUCCESS = '/success',
  PAYMENT_FAIL = '/fail',
  APPLICATION_OVERVIEW = '/overview',
  APPLICATION_ASSISTANT = '/assistant',
  APPLICATION = '/application/*',
  BASIC_DETAILS = '/application/basic-details',
  FAMILY_DETAILS = '/application/family-details',
  LEGAL_DETAILS = '/application/legal-details',
  DISABILITY_DETAILS = '/application/disability-details',
  EDUCATION_DETAILS = '/application/education-details',
  MEDICAL_DETAILS = '/application/medical-details',
  UPLOAD_DOCUMENT = '/application/upload-document',
  SIGN_CONSENT_FORM = '/application/sign-consent-forms',
  SUBMIT = '/application/final-submit',
}
