import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import * as React from 'react'
import Cancel from 'src/Assets/Icons/Cancel.svg'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import {
  createPayment,
  FetchSubscriptions,
  setselectedPlanId,
  setSessionId,
} from 'src/Redux/Slices/authSlice'
import { DynamicObj } from 'src/Utils/Constants'
import Style from './index.module.scss'
import { makePayment } from 'src/Components/SignUp/Billing/paymentFun'

const SwitchPlanDialogue = ({
  switchPlan,
  setSwitchPlan,
  paymentSummary,
}: {
  switchPlan: boolean
  setSwitchPlan: React.Dispatch<React.SetStateAction<boolean>>
  paymentSummary: DynamicObj
}) => {
  const dispatch = useAppDispatch()
  const AuthData = useAppSelector((state) => state.auth.data)
  const { emailId } = AuthData

  const [selected, setSelected] = React.useState('month')
  const [selectedPlan, setSelectedPlan] = React.useState<string | undefined>('')
  const [plans, setPlans] = React.useState<
    Array<{
      uuid?: string
      name?: string
      description?: string
      numberOfApplication?: number
      numberOfseats?: number
      subscriptionPeriod?: number
      amount?: number
      plan?: string
    }>
  >([])

  React.useEffect(() => {
    dispatch(FetchSubscriptions()).then((res) => {
      setSelected('month')
      setPlans(res.payload?.data)
    })
  }, [])
  const handlePlanChange = (newSelection: string | undefined, id: string) => {
    // const key = id
    // const value = newSelection
    setSelectedPlan(newSelection)

    dispatch(setselectedPlanId(newSelection))
  }

  const ConfirmPlan = (subscriptionId) => {
    dispatch(
      createPayment({
        subscriptionId,
        emailId,
        autoPayment: true,
      }),
    ).then(async (res) => {
      dispatch(setSessionId(res?.payload?.data?.data?.sessionId))
      makePayment(res?.payload?.data?.data?.sessionId)
    })
  }
  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={switchPlan}
        onClose={() => {
          setSwitchPlan(false)
        }}
      >
        <DialogTitle className={Style.DialogTitle}>
          Switch Plano
          <Box sx={{ cursor: 'pointer' }} alignSelf={'flex-end'}>
            <img
              src={Cancel}
              onClick={() => {
                setSwitchPlan(false)
              }}
            />
          </Box>
        </DialogTitle>
        <DialogContent className={Style.DialogContentBox}>
          <Box className={Style.planSwitch} width={'100%'}>
            <Button
              onClick={() => {
                setSelected('month')
              }}
              className={
                selected === 'month'
                  ? Style.planSelectedOption
                  : Style.planOption
              }
            >
              Monthly
            </Button>
            <Button
              onClick={() => {
                setSelected('year')
              }}
              className={
                selected === 'year'
                  ? Style.planSelectedOption
                  : Style.planOption
              }
            >
              Annual
            </Button>
          </Box>
          {plans
            ?.filter((plan) => plan.plan === selected)
            .map((plan) => (
              <Box
                key={plan.uuid}
                className={
                  selectedPlan === plan.uuid
                    ? Style.selectedCard
                    : Style.planCard
                }
                role='radio'
                aria-checked={selectedPlan === plan.uuid}
                onClick={() => {
                  if (paymentSummary.id !== plan.uuid)
                    handlePlanChange(plan.uuid, 'subUuid')
                }}
              >
                <Box className={Style.planHeader}>
                  <Box className={Style.planInfo}>
                    <Typography className={Style.planName}>
                      {plan.name}
                    </Typography>
                    <Typography
                      className={Style.planPrice}
                      style={{
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                      }}
                    >
                      {plan.description}
                    </Typography>
                  </Box>
                </Box>
                <Box className={Style.planFooter}>
                  <Typography className={Style.planCurrency}>$</Typography>
                  <Typography className={Style.planAmount}>
                    {plan.amount}
                  </Typography>
                  <Typography className={Style.planPeriod}>
                    /{plan.plan}
                  </Typography>
                  {paymentSummary.id !== plan.uuid ? (
                    <Button
                      variant='contained'
                      sx={{
                        textTransform: 'none',
                        textWrap: 'nowrap',
                        color: selectedPlan === plan.uuid ? '#fff' : '#252F4A',
                        background:
                          selectedPlan === plan.uuid ? '#7752FE' : '#F1F1F4',
                        fontSize: '16',
                        borderRadius: '10px',
                        '&:hover': {
                          background:
                            selectedPlan === plan.uuid ? '#7752FE' : '#F1F1F4',
                        },
                        margin: 0,
                        alignItem: 'center',
                        px: '24px',
                      }}
                      onClick={() => {
                        if (plan.uuid) {
                          ConfirmPlan(plan.uuid)
                        }
                      }}
                    >
                      Switch Plan
                    </Button>
                  ) : (
                    <Typography className={Style.currentPlan}>
                      Current Plan
                    </Typography>
                  )}
                </Box>
              </Box>
            ))}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SwitchPlanDialogue
