// This slice manages the state for the sign in feature
import {
  PayloadAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import secureLocalStorage from 'react-secure-storage'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import FamilyDetailsService from 'src/Redux/Services/FamillyDetailsService'
import { thunkAPItype } from 'src/Redux/store'
import { handleResponse } from 'src/Utils/Constants'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: any
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {},
}

// Define the reducers that can modify the state
const FamilyDetails = createSlice({
  name: 'FamilyDetails',
  initialState,
  reducers: {
    setFamilyDetailsLoading: (state) => {
      state.loading = true
    },
    setFamilyDetailsSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setFamilyDetailsFail: (state) => {
      state.success = false
      state.loading = false
      state.data = initialState.data
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AddCurrentSignificant.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddCurrentSignificant.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddCurrentSignificant.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(EditCurrentSignificant.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      EditCurrentSignificant.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(EditCurrentSignificant.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddPastSignificant.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddPastSignificant.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddPastSignificant.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddParents.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddParents.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddParents.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddDependents.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddDependents.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddDependents.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

export const {
  setFamilyDetailsLoading,
  setFamilyDetailsSuccess,
  setFamilyDetailsFail,
} = FamilyDetails.actions

export const AddCurrentSignificant = createAsyncThunk(
  'CurrentSignificant', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await FamilyDetailsService.AddCurrentSignificant(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditCurrentSignificant = createAsyncThunk(
  'CurrentSignificant', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await FamilyDetailsService.EditCurrentSignificant(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddPastSignificant = createAsyncThunk(
  'PastSignificant', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await FamilyDetailsService.AddPastSignificant(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddParents = createAsyncThunk(
  'Parents', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await FamilyDetailsService.AddParents(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddDependents = createAsyncThunk(
  'Dependents', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await FamilyDetailsService.AddDependents(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default FamilyDetails.reducer
