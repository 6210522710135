export const GradeList = [
  { name: 'First Grade', value: 'First Grade' },
  { name: 'Second Grade', value: 'Second Grade' },
  { name: 'Third Grade', value: 'Third Grade' },
  { name: 'Fourth Grade', value: 'Fourth Grade' },
  { name: 'Fifth Grade', value: 'Fifth Grade' },
  { name: 'Sixth Grade', value: 'Sixth Grade' },
  { name: 'Seventh Grade', value: 'Seventh Grade' },
  { name: 'Eighth Grade', value: 'Eighth Grade' },
  { name: 'Ninth Grade', value: 'Ninth Grade' },
  { name: 'Tenth Grade', value: 'Tenth Grade' },
  { name: 'Eleventh Grade', value: 'Eleventh Grade' },
  { name: 'Twelfth Grade', value: 'Twelfth Grade' },
  { name: 'One Year of College', value: 'One Year of College' },
  { name: 'Two Years of College', value: 'Two Years of College' },
  {
    name: 'Three Years of College',
    value: 'Three Years of College',
  },
  {
    name: 'Four Years of College',
    value: 'Four Years of College',
  },
  {
    name: 'Five or More Years of College',
    value: 'Five or More Years of College',
  },
]

export const JobList = [
  { name: 'Plumber', value: 'Plumber' },
  { name: 'HVAC Technician', value: 'HVAC Technician' },
  { name: 'Mechanic', value: 'Mechanic' },
  { name: 'Cosmetologist', value: 'Cosmetologist' },
  { name: 'Massage Therapist', value: 'Massage Therapist' },
  { name: 'IT/Tech', value: 'IT/Tech' },
  { name: 'Criminal Justice', value: 'Criminal Justice' },
  { name: 'Medical', value: 'Medical' },
  { name: 'Dental', value: 'Dental' },
  { name: 'Culinary', value: 'Culinary' },
  { name: 'Other', value: 'Other' },
]
