import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleResponse } from 'src/Utils/Constants'
import SubscriptionService from '../Services/subscriptionService'
import { thunkAPItype } from '../store'
import { LogoutRequest } from './authSlice'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: {
    id?: string
    name?: string
    plan?: string
    amount?: number
    subscriptionPeriod?: number
    status?: string
    createdAt?: string
    description?: string
  }
  details: {
    success: boolean
    loading: boolean
    data?: {
      id?: string
      name?: string
      plan?: string
      amount?: number
      subscriptionPeriod?: number
      numberOfApplications?: number
      numberOfSeats?: number
      status?: string
      createdAt?: string
      description?: string
    }
  }
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {}, // the data returned from the sign in request
  details: {
    success: false,
    loading: false,
    data: {},
  },
}

// Define the reducers that can modify the state
const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionLoading: (state) => {
      state.loading = true
    },
    setSubscriptionSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setSubscriptionFail: (state) => {
      state.success = false
      state.loading = false
      state.data = {}
    },
    setSubscriptionDetailsLoading: (state) => {
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
        },
      }
    },
    setSubscriptionDetailsSuccess: (state, action) => {
      return {
        ...state,
        details: {
          success: true,
          loading: false,
          data: action.payload,
        },
      }
    },
    setSubscriptionDetailsFail: (state) => {
      return {
        ...state,
        details: {
          success: false,
          loading: false,
          data: {},
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(SubscriptionListRequest.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      SubscriptionListRequest.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(SubscriptionListRequest.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(SubscriptionDetailsRequest.pending, (state) => {
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
        },
      }
    })
    builder.addCase(
      SubscriptionDetailsRequest.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        return {
          ...state,
          details: {
            success: action.payload?.success,
            loading: false,
            data: action.payload?.data,
          },
        }
      },
    )
    builder.addCase(SubscriptionDetailsRequest.rejected, (state) => {
      return {
        ...state,
        details: {
          success: false,
          loading: false,
          data: {},
        },
      }
    })
    builder.addCase(LogoutRequest.fulfilled, (state) => {
      state = initialState
    })
  },
})

export const {
  setSubscriptionLoading,
  setSubscriptionSuccess,
  setSubscriptionFail,
} = subscriptionSlice.actions

// Define the async thunk action creator
export const SubscriptionListRequest = createAsyncThunk(
  'subscriptionList', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const token = thunkAPI.getState().auth.data?.token
      const response = await SubscriptionService.viewSubscriptionList(token) // make the API call
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while subscriptionentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

// Define the async thunk action creator
export const SubscriptionDetailsRequest = createAsyncThunk(
  'subscriptionDetails', // name of the action
  async (id: string, thunkAPI: thunkAPItype) => {
    try {
      const token = thunkAPI.getState().auth.data?.token
      const response = await SubscriptionService.subscriptionDetails(token, id) // make the API call
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while subscriptionentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

// Define the async thunk action creator
export const AddSubscriptionRequest = createAsyncThunk(
  'addSubscription', // name of the action
  async (
    data: {
      uuid: string
      name: string
      plan: string
      amount: number
      subscriptionPeriod: number
      numberOfApplication?: number
      numberOfseats?: number
      description: string
      isActive: boolean
    },
    thunkAPI: thunkAPItype,
  ) => {
    try {
      data = {
        ...data,
        numberOfApplication: 300,
        numberOfseats: 100,
      }
      const token = thunkAPI.getState().auth.data?.token
      const { dispatch } = thunkAPI
      const response = await SubscriptionService.addSubscription(token, data) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while subscriptionentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

// Define the async thunk action creator
export const DeleteSubscriptionRequest = createAsyncThunk(
  'deleteSubscription', // name of the action
  async (data: { uuid: string; isActive: boolean }, thunkAPI: thunkAPItype) => {
    try {
      const token = thunkAPI.getState().auth.data?.token
      const { dispatch } = thunkAPI
      const response = await SubscriptionService.deleteSubscription(token, data) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while subscriptionentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

// Define the async thunk action creator
export const EditSubscriptionRequest = createAsyncThunk(
  'editSubscription', // name of the action
  async (
    data: {
      uuid: string
      name: string
      plan: string
      amount: number
      subscriptionPeriod: number
      numberOfApplication: number
      numberOfseats: number
      description: string
      isActive: boolean
    },
    thunkAPI: thunkAPItype,
  ) => {
    try {
      const token = thunkAPI.getState().auth.data?.token
      const { dispatch } = thunkAPI
      const response = await SubscriptionService.editSubscription(token, data) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while subscriptionentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default subscriptionSlice.reducer
