import { useRef, useState } from 'react'
interface Prediction {
  description: string
}
interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}
const usePlacesAutocomplete = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [predictions, setPredictions] = useState<Prediction[]>([])
  const [addressComponents, setAddressComponents] = useState<
    AddressComponent[]
  >([])

  const autocompleteService = new google.maps.places.AutocompleteService()

  const handleInputChange = () => {
    const input = inputRef.current
    if (!input) return

    autocompleteService.getPlacePredictions(
      { input: input.value },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setPredictions(predictions || [])
        } else {
          setPredictions([])
        }
      },
    )
  }

  const handlePredictionClick = (description: string) => {
    autocompleteService.getPlacePredictions(
      { input: description },
      (predictions, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          predictions &&
          predictions.length > 0
        ) {
          const placeId = predictions[0].place_id
          const placesService = new google.maps.places.PlacesService(
            document.createElement('div'),
          )
          placesService.getDetails({ placeId }, (placeResult, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              placeResult
            ) {
              setAddressComponents(placeResult.address_components ?? [])
            } else {
              setAddressComponents([])
            }
          })
        }
      },
    )
  }

  return {
    inputRef,
    predictions,
    addressComponents,
    handleInputChange,
    handlePredictionClick,
  }
}

export default usePlacesAutocomplete
