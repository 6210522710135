import { Box, Popover, PopoverProps } from '@mui/material'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import React from 'react'
import styles from './index.module.scss'

interface CalenderPopupProps {
  toggle: () => void
  anchor: React.MutableRefObject<undefined>
  open: boolean
  onClose: () => void
  dateRange: string | undefined
  setDateRange: (range: string) => void
}

const CalenderPopup: React.FunctionComponent<CalenderPopupProps> = ({
  toggle,
  anchor,
  open,
  onClose,
  dateRange,
  setDateRange,
}) => {
  const popoverProps: PopoverProps = {
    open,
    onClose,
    anchorEl: anchor.current,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    slotProps: {
      paper: {
        className: styles.paper,
      },
    },
  }

  return (
    <Popover {...popoverProps}>
      <Box display={'flex'}>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              maxDate={dayjs()}
              value={dateRange ? dayjs(dateRange) : null}
              onChange={(range) => {
                setDateRange(range)
                onClose()
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </Popover>
  )
}

export default CalenderPopup
