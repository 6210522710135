import { baseSecureAPI, baseSecureWithFormAPI } from '../Common/httpCommon'
import { DynamicObj, getAccessToken } from 'src/Utils/Constants/index'

const getOrganizationList = async (token: string) => {
  try {
    return await baseSecureAPI.get<any>('organization/list', {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  } catch (e: any) {
    return e
  }
}
const getOrganizationOverview = async (orgId: string, token: string) => {
  try {
    return await baseSecureAPI.get(`organization/detail/overview/${orgId}`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const ActiveOrDeactivateOrg = async (
  orgId: string,
  token: string,
  data: DynamicObj,
) => {
  try {
    return await baseSecureAPI.put(`organization/${orgId}`, data, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditOrganizationOverview = async (
  orgId: string,
  data: any,
  token: string,
) => {
  try {
    return await baseSecureWithFormAPI.put(
      `organization/detail/overview/${orgId}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
  } catch (e: any) {
    return e
  }
}
const EditOrganizationContactInfo = async (
  orgId: string,
  data: any,
  token: string,
) => {
  try {
    return await baseSecureAPI.put(
      `organization/detail/contact/${orgId}`,
      data,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
  } catch (e: any) {
    return e
  }
}
const AddOrganizationOverview = async (data: any, token: string) => {
  try {
    return await baseSecureWithFormAPI.post(
      `organization/detail/overview`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
  } catch (e: any) {
    return e
  }
}
const AddOrganizationContactInfo = async (
  orgId: string,
  data: any,
  token: string,
) => {
  try {
    return await baseSecureAPI.post(
      `organization/detail/contact/${orgId}`,
      data,
    )
  } catch (e: any) {
    return e
  }
}
const getOrganizationContactInfo = async (orgId: string, token: string) => {
  try {
    return await baseSecureAPI.get(`organization/detail/contact/${orgId}`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  } catch (e: any) {
    return e
  }
}
const getOrganizationPayment = async (orgId: string, token: string) => {
  try {
    return await baseSecureAPI.get(`organization/detail/payment/${orgId}`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  } catch (e: any) {
    return e
  }
}
const getOrganizationPaymentSummary = async (orgId: string, token: string) => {
  try {
    return await baseSecureAPI.get(
      `organization/detail/subscription/${orgId}`,
      {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    )
  } catch (e: any) {
    return e
  }
}

const getOrganizationEmployees = async (filter: {
  scope?: string
  roleId?: string
  organizationId?: string
  sort?: string
}) => {
  try {
    let setFilter = ''

    if (filter.scope ?? filter.roleId ?? filter.organizationId) {
      setFilter += `?uuid=${filter.organizationId}&`

      if (filter.scope) {
        setFilter += `scope=${filter.scope}&`
      }
      if (filter.roleId) {
        setFilter += `roleId=${filter.roleId}&`
      }
      if (filter.sort) {
        setFilter += `sort=${filter.sort}&`
      }
    }

    return await baseSecureAPI.get(`organization/detail/employee${setFilter}`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const CancelSubscription = async (orgId: string, token: string) => {
  try {
    return await baseSecureAPI.put(`organization/cancel-subscription/${orgId}`)
  } catch (e: any) {
    return e
  }
}

// const viewEmployeeList = async (
//   filter: {
//     scope?: string
//     roleId?: string
//     organizationId?: string
//   },
//   token: string,
// ) => {
//   try {
//     let setFilter = ''
//     if (filter.scope ?? filter.roleId ?? filter.organizationId) {
//       setFilter = '?'
//       if (filter.scope) {
//         setFilter += `scope=${filter.scope}&`
//       }
//       if (filter.roleId) {
//         setFilter += `roleId=${filter.roleId}&`
//       }
//       if (filter.organizationId) {
//         setFilter += `organizationId=${filter.organizationId}&`
//       }
//     }
//     return await baseAPI.get(`employee/list${setFilter}`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//   } catch (e: any) {
//     return e
//   }
// }

const OrganizationService = {
  getOrganizationList,
  getOrganizationOverview,
  getOrganizationContactInfo,
  getOrganizationPayment,
  getOrganizationEmployees,
  AddOrganizationOverview,
  EditOrganizationOverview,
  EditOrganizationContactInfo,
  AddOrganizationContactInfo,
  getOrganizationPaymentSummary,
  ActiveOrDeactivateOrg,
  CancelSubscription,
}
export default OrganizationService
