// This slice manages the state for the sign in feature
import {
  // PayloadAction,
  // AsyncThunk,
  // PayloadAction,
  // createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { BasicDetailsType } from '../../Services/Types/applicationType'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
// import BasicDetailsService from '../../Services/BasicDetailsService'
// import { handleResponse } from 'src/Utils/Constants'

// Define the initial state of the slice
const initialState: BasicDetailsType = {
  assistancedata: {
    uuid: null,
    address: null,
    emailId: null,
    lastName: null,
    firstName: null,
    phoneNumber: null,
    organizationName: null,
    relationToApplicant: null,
  },
  assigneeData: {
    uuid: null,
    lastName: null,
    firstName: null,
    profileURL: null,
  },
  flagdata: {
    flag: '',
    remark: [],
  },
  basicFlagData: {
    ContactInformation: {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      isUSAPhoneNumber: false,
      phone: '',
      physicalAddress: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      isLiveAtPhysicalAddress: false,
      currentAddress: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      wantToReceiveMailAtPhysicalAddress: false,
      mailingAddress: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
  },
  ContactInformation: {
    applicant: '',
    assistanceId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    isUSAPhoneNumber: undefined,
    physicalAddress: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    isLiveAtPhysicalAddress: undefined,
    currentAddress: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    wantToReceiveMailAtPhysicalAddress: undefined,
    mailingAddress: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
  },
  CommunicationPreference: {
    basicDetailId: '',
    isPreferredLanguageEnglish: undefined,
    preferredReadingLanguage: '',
    preferredSpeakingLanguage: '',
    preferredWritingLanguage: '',
    canReadEnglish: undefined,
    canWriteEnglish: undefined,
    canSpeakEnglish: undefined,
    isAbleToReadWriteMoreThenName: undefined,
  },
  Citizenship: {
    basicDetailId: '',
    wereBornInUSA: undefined,
    confirmUSACitizen: '',
    citizenshipCountryName: [],
    isGoingThroughProcessOfUSACitizenship: undefined,
    isLegalResidentOfUSA: undefined,
    residentCardNumber: '',
    bornUSACityName: '',
    bornUSAStateName: '',
    bornCountry: '',
    qualifiedUSACitizenship: '',
    dateOfCitizenship: '',
  },
  MilitaryService: {
    basicDetailId: '',
    isServedInMilitary: undefined,
    isReceivingFederalAgencyBenefit: undefined,
    typeOfBenefit: '',
    serviceInformation: [
      {
        typeOfDuty: '',
        branchOfService: '',
        startDate: '',
        endDate: '',
      },
    ],
  },
  PassApplication: {
    basicDetailId: '',
    isReceivingMedicareBenefits: undefined,
    haveAppliedForMedicareBenefits: undefined,
    statusOfPreviousMedicareBenefit: '',
    // SSDI
    isReceivingSSDI: undefined,
    haveAppliedForSSDI: undefined,
    statusOfPreviousSSDI: '',
    SSDIApprovedEndDate: '',
    isSSDIDenial60Days: undefined,
    denialDateSSDI: '',
    // SSI
    isReceivingSSI: undefined,
    haveAppliedForSSI: undefined,
    statusOfSSI: '',
    SSIApprovedEndDate: '',
    isSSIDenial60Days: undefined,
    denialDateSSI: '',
    // other
    haveAppliedWithSSN: undefined,
    appliedForAdditionalBenefits: '',
    currentlyparticipatingPrograms: '',
    benefitAppliedSSNDetail: [
      {
        suffix: '',
        firstName: '',
        middleName: '',
        lastName: '',
        monthlyBenefitAmount: '',
        socialSecurityNumber: '',
      },
    ],
    participateProgramDetails: [
      {
        organizationName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        contactName: '',
        phone: '',
        estimatedDate: '',
        description: '',
        stillParticipatedInProgram: undefined,
      },
    ],
  },
}

// Define the reducers that can modify the state
const BasicDetailsStore = createSlice({
  name: 'BasicDetails',
  initialState,
  reducers: {
    resetBasicDetails: (state) => {
      state.ContactInformation = {
        applicant: '',
        assistanceId: '',
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phone: '',
        isUSAPhoneNumber: undefined,
        physicalAddress: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        isLiveAtPhysicalAddress: undefined,
        currentAddress: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        wantToReceiveMailAtPhysicalAddress: undefined,
        mailingAddress: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
      }
      state.CommunicationPreference = {
        basicDetailId: '',
        isPreferredLanguageEnglish: undefined,
        preferredReadingLanguage: '',
        preferredSpeakingLanguage: '',
        preferredWritingLanguage: '',
        canReadEnglish: undefined,
        canWriteEnglish: undefined,
        canSpeakEnglish: undefined,
        isAbleToReadWriteMoreThenName: undefined,
      }
      state.Citizenship = {
        basicDetailId: '',
        wereBornInUSA: undefined,
        confirmUSACitizen: '',
        citizenshipCountryName: [],
        isGoingThroughProcessOfUSACitizenship: undefined,
        isLegalResidentOfUSA: undefined,
        residentCardNumber: '',

        bornUSACityName: '',
        bornUSAStateName: '',
        bornCountry: '',
        qualifiedUSACitizenship: '',
        dateOfCitizenship: '',
      }
      state.MilitaryService = {
        basicDetailId: '',
        isServedInMilitary: undefined,
        isReceivingFederalAgencyBenefit: undefined,
        typeOfBenefit: '',
        serviceInformation: [
          {
            typeOfDuty: '',
            branchOfService: '',
            startDate: '',
            endDate: '',
          },
        ],
      }
      state.PassApplication = {
        basicDetailId: '',
        isReceivingMedicareBenefits: undefined,
        haveAppliedForMedicareBenefits: undefined,
        statusOfPreviousMedicareBenefit: '',
        // SSDI
        isReceivingSSDI: undefined,
        haveAppliedForSSDI: undefined,
        statusOfPreviousSSDI: '',
        SSDIApprovedEndDate: '',
        isSSDIDenial60Days: undefined,
        denialDateSSDI: '',
        // SSI
        isReceivingSSI: undefined,
        haveAppliedForSSI: undefined,
        statusOfSSI: '',
        SSIApprovedEndDate: '',
        isSSIDenial60Days: undefined,
        denialDateSSI: '',
        // other
        haveAppliedWithSSN: undefined,
        appliedForAdditionalBenefits: '',
        currentlyparticipatingPrograms: '',
        benefitAppliedSSNDetail: [
          {
            suffix: '',
            firstName: '',
            middleName: '',
            lastName: '',
            monthlyBenefitAmount: '',
            socialSecurityNumber: '',
          },
        ],
        participateProgramDetails: [
          {
            organizationName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            contactName: '',
            phone: '',
            estimatedDate: '',
            description: '',
            stillParticipatedInProgram: undefined,
          },
        ],
      }
    },
    setBasicDetails: (state, action: { payload: typeof initialState }) => {
      state.ContactInformation = action.payload.ContactInformation
      state.CommunicationPreference = action.payload.CommunicationPreference
      state.Citizenship = action.payload.Citizenship
      state.MilitaryService = action.payload.MilitaryService
      state.PassApplication = action.payload.PassApplication
      state.assistancedata = action.payload.assistancedata
      state.assigneeData = action.payload.assigneeData
      state.flagdata = action.payload.flagdata
    },
    setContactInformation: (
      state,
      action: { payload: typeof initialState.ContactInformation },
    ) => {
      state.ContactInformation = action.payload
    },

    setBasicFlagData: (
      state,
      action: { payload: typeof initialState.basicFlagData },
    ) => {
      state.basicFlagData = action.payload
    },
    setCommunicationPreference: (
      state,
      action: { payload: typeof initialState.CommunicationPreference },
    ) => {
      state.CommunicationPreference = action.payload
    },
    setCitizenship: (
      state,
      action: { payload: typeof initialState.Citizenship },
    ) => {
      state.Citizenship = action.payload
    },
    setMilitaryService: (
      state,
      action: { payload: typeof initialState.MilitaryService },
    ) => {
      state.MilitaryService = action.payload
    },
    setPassApplication: (
      state,
      action: { payload: typeof initialState.PassApplication },
    ) => {
      state.PassApplication = action.payload
    },
  },
})

export default BasicDetailsStore.reducer

export const {
  resetBasicDetails,
  setContactInformation,
  setCommunicationPreference,
  setCitizenship,
  setMilitaryService,
  setBasicDetails,
  setPassApplication,
  setBasicFlagData,
} = BasicDetailsStore.actions
