import { Roles, AdminPermissions, LegalPermissions } from 'src/Utils/Constants'
import AdminProfile from 'src/Pages/Profile/AdminProfile'
import LegalProfile from 'src/Pages/Profile/LegalProfile'
import { useAppSelector } from 'src/Redux/hooks'
export default function MainProfile() {
  const UserPermission = useAppSelector((state) => state.permissions?.data)
  const data = useAppSelector((state) => state.auth?.data)

  return (
    <>
      {data?.roleScope === Roles.ADMIN &&
        UserPermission?.includes(AdminPermissions.PROFILE) && <AdminProfile />}

      {data?.roleScope === Roles.LEGAL &&
        UserPermission?.includes(LegalPermissions.PROFILE) && <LegalProfile />}
    </>
  )
}
