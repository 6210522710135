import { baseAPI } from '../Common/httpCommon'

const GenApplication = async (data: Record<string, string>, token: string) => {
  try {
    return await baseAPI.post('application/generate-application', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const VerifyApplink = async (link: string) => {
  try {
    return await baseAPI.get('application/verify-link?link=' + link)
  } catch (e: any) {
    return e
  }
}

const ApplicationService = {
  GenApplication,
  VerifyApplink,
}
export default ApplicationService
