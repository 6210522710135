import { useEffect, type FC } from 'react'
import { useLocation } from 'react-router-dom'
import { DynamicObj } from 'src/Pages/SignIn'
import { FetchSubscriptions } from 'src/Redux/Slices/authSlice'
import {
  getOrganizationPayment,
  getOrganizationPaymentSummary,
} from 'src/Redux/Slices/orgSlice'
import { ViewProfileRequest } from 'src/Redux/Slices/profileSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import {
  AdminPermissions,
  DashboardCard,
  LegalPermissions,
  Roles,
} from 'src/Utils/Constants'
import { ROUTES } from 'src/Utils/Routes'

const Dashboard: FC = () => {
  const data = useAppSelector((state) => state.auth?.data)
  const UserPermission = useAppSelector((state) => state.permissions?.data)
  const dispatch = useAppDispatch()

  // const [openDialog, setOpenDialog] = useState(false)
  // const handleOpen = () => {
  //   setOpenDialog(true)
  // }
  // useEffect(() => {
  //   if (data?.tempPassword === false) {
  //     handleOpen()
  //   }
  // }, [openDialog])
  const organizationUUID = useAppSelector(
    (state) => state.profile?.data?.organizationUUID,
  )

  const location = useLocation()
  useEffect(() => {
    if (location.pathname === ROUTES.ENHANCED_PLAN) {
      dispatch(ViewProfileRequest())
      if (organizationUUID) {
        dispatch(FetchSubscriptions())
        dispatch(getOrganizationPayment(organizationUUID))
        dispatch(getOrganizationPaymentSummary(organizationUUID))
      }
    }
  }, [location.pathname === ROUTES.ENHANCED_PLAN])

  return (
    <>
      {data?.roleScope === Roles.ADMIN &&
        UserPermission?.includes(AdminPermissions.ADMIN_DASHBOARD) && (
          <DashboardCard.AdminDashboard />
        )}
      {data?.roleScope === Roles.LEGAL &&
        UserPermission?.includes(LegalPermissions.LEGAL_DASHBOARD) && (
          <DashboardCard.LegalDashboard />
        )}
      {/* {data?.tempPassword === false &&
        openDialog &&
        <>
          <ResetPasswordPopUp
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            handleOpen={handleOpen}
          />
        </>
      } */}
    </>
  )
}

export default Dashboard
