import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import Styles from '../index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import IllnessesInjuriesConditions from '../EditForms/IllnessesInjuriesConditions'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { hasEmpty } from 'src/Utils/Constants'
const MedicalDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)
  const illnessRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    illnessRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.MedicalDetails?.IllnessesInjuriesAndConditions,
  )

  const illnessesInjuriesConditionsSectionId = useAppSelector(
    (state) =>
      state?.steps?.MedicalApplicationSteps?.find(
        (step: { label: string }) =>
          step.label === 'Illnesses, Injuries, & Conditions',
      )?.sectionId,
  )
  return (
    <div id={illnessesInjuriesConditionsSectionId} ref={illnessRef}>
      {!editMode ? (
        <>
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  Illnesses, Injuries, & Conditions
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>
            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Muscle or mobility-related symptoms
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      {data?.muscleOrMemoryRelatedSymptoms.map(
                        (item: string, index: number) => (
                          <Typography
                            className={Styles.textWrapper4}
                            key={index}
                          >
                            {item}
                          </Typography>
                        ),
                      )}
                    </Box>
                  </Box>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Energy or sleep-related symptoms
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      {data?.energyOrSleepRelatedSymptoms.map(
                        (item: string, index: number) => (
                          <Typography
                            className={Styles.textWrapper4}
                            key={index}
                          >
                            {item}
                          </Typography>
                        ),
                      )}
                    </Box>
                  </Box>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Fever or skin-related symptoms
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      {data?.feverOrSkinRelatedSymptoms.map(
                        (item: string, index: number) => (
                          <Typography
                            className={Styles.textWrapper4}
                            key={index}
                          >
                            {item}
                          </Typography>
                        ),
                      )}
                    </Box>
                  </Box>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Breathing or heart-related symptoms
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      {data?.breathingOrHeartRelatedSymptoms.map(
                        (item: string, index: number) => (
                          <Typography
                            className={Styles.textWrapper4}
                            key={index}
                          >
                            {item}
                          </Typography>
                        ),
                      )}
                    </Box>
                  </Box>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Speech-related symptoms
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      {data?.speechRelatedSymptoms.map(
                        (item: string, index: number) => (
                          <Typography
                            className={Styles.textWrapper4}
                            key={index}
                          >
                            {item}
                          </Typography>
                        ),
                      )}
                    </Box>
                  </Box>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Memory-related symptoms
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      {data?.memoryRelatedSymptoms.map(
                        (item: string, index: number) => (
                          <Typography
                            className={Styles.textWrapper4}
                            key={index}
                          >
                            {item}
                          </Typography>
                        ),
                      )}
                    </Box>
                  </Box>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Mental health-related symptoms
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      {data?.mentalHealthRelatedSymptoms.map(
                        (item: string, index: number) => (
                          <Typography
                            className={Styles.textWrapper4}
                            key={index}
                          >
                            {item}
                          </Typography>
                        ),
                      )}
                    </Box>
                  </Box>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Urinary symptoms
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      {data?.urinarySymptoms.map(
                        (item: string, index: number) => (
                          <Typography
                            className={Styles.textWrapper4}
                            key={index}
                          >
                            {item}
                          </Typography>
                        ),
                      )}
                    </Box>
                  </Box>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Dizziness or headache-related symptoms
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      {data?.dizzinessOrHeadacheRelatedSymptoms.map(
                        (item: string, index: number) => (
                          <Typography
                            className={Styles.textWrapper4}
                            key={index}
                          >
                            {item}
                          </Typography>
                        ),
                      )}
                    </Box>
                  </Box>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Seizure-related symptoms
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.isSeizureRelatedSymptoms ? 'Yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {data?.isSeizureRelatedSymptoms ? (
                  <>
                    <Box className={Styles.cardDiv}>
                      {/* row-1 */}
                      <Grid container className={Styles.cardFrame}>
                        <Grid item xs={6} className={Styles.cardElement}>
                          <Box className={Styles.cardQuestion}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper}
                            >
                              Symptoms experience during a seizure
                            </Typography>
                          </Box>

                          <Box className={Styles.cardAns}>
                            {data?.seizureRelatedSymptoms.map(
                              (item: string, index: number) => (
                                <Typography
                                  className={Styles.textWrapper4}
                                  key={index}
                                >
                                  {item}
                                </Typography>
                              ),
                            )}
                          </Box>
                          {data?.seizureRelatedSymptoms.includes('Other') ? (
                            <>
                              {/* Additional symptom */}
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={12}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      Additional symptom
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper2}
                                    >
                                      {data?.anotherSymptoms}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* Symptoms last */}
                          <Grid container className={Styles.cardFrame}>
                            <Grid item xs={6} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Symptoms last
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={Styles.textWrapper2}
                                >
                                  {data?.howLongSymptomLast}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Have after-effects following a seizure
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={Styles.textWrapper2}
                                >
                                  {data?.isAfterEffectSeizureExperience
                                    ? 'Yes'
                                    : 'No'}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          {data?.isAfterEffectSeizureExperience ? (
                            <>
                              {/* seizure after-effects you experience */}
                              <Grid container className={Styles.cardFrame}>
                                <Grid
                                  item
                                  xs={12}
                                  className={Styles.cardElement}
                                >
                                  <Box className={Styles.cardQuestion}>
                                    <Typography
                                      variant='body1'
                                      className={Styles.cardTextWrapper}
                                    >
                                      seizure after-effects you experience
                                    </Typography>
                                  </Box>
                                  <Box className={Styles.cardAns}>
                                    {data?.seizureAfterEffectExperience.map(
                                      (item: string, index: number) => (
                                        <Typography
                                          className={Styles.textWrapper4}
                                          key={index}
                                        >
                                          {item}
                                        </Typography>
                                      ),
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid item xs={6} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Your longest seizure-related symptoms last
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.textWrapper2}
                                  >
                                    {data?.seizureaftereffectsymptomsduration}
                                  </Typography>
                                </Box>
                              </Grid>
                            </>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : null}
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Physical and mental illnesses, injuries, and conditions
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      {data?.emotionalOrLearningProblems.map(
                        (item: string, index: number) => (
                          <Typography
                            className={Styles.textWrapper4}
                            key={index}
                          >
                            {item}
                          </Typography>
                        ),
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Illnesses, Injuries, & Conditions
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <IllnessesInjuriesConditions
                    handleEditToggle={handleEditToggle}
                  />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default MedicalDetails
