import { initializeApp } from 'firebase/app'
import { MessagePayload, getMessaging } from 'firebase/messaging'
import { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { onMessageListener, requestForToken } from './firebase'

const apiKey = process.env.REACT_APP_FIREBASE_API_KEY
const authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
const appId = process.env.REACT_APP_FIREBASE_APP_ID
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

const Notification = () => {
  const [notification, setNotification] = useState<{
    title?: string
    body?: string
  }>({ title: '', body: '' })
  const notify = () => toast(<ToastDisplay />)
  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    )
  }

  const firebaseConfig = {
    // apiKey: `REPLACE_WITH_YOUR_FIREBASE_MESSAGING_API_KEY`,
    // authDomain: `REPLACE_WITH_YOUR_FIREBASE_MESSAGING_AUTH_DOMAIN`,
    // projectId: `REPLACE_WITH_YOUR_FIREBASE_MESSAGING_PROJECT_ID`,
    // storageBucket: `REPLACE_WITH_YOUR_FIREBASE_MESSAGING_STORAGE_BUCKET`,
    // messagingSenderId: `REPLACE_WITH_YOUR_FIREBASE_MESSAGING_SENDER_ID`,
    // appId: `REPLACE_WITH_YOUR_FIREBASE_MESSAGING_APP_ID`,
    // measurementId: `REPLACE_WITH_YOUR_FIREBASE_MESSAGING_MEASUREMENT_ID`,
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId,
  }
  const firebaseApp = initializeApp(firebaseConfig)
  const messaging = getMessaging(firebaseApp)
  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  }, [notification])

  requestForToken(messaging)

  onMessageListener(messaging)
    .then((payload: MessagePayload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      })
    })
    .catch((err) => {
      console.log('failed: ', err)
    })

  return (
    <>
      <Toaster />
    </>
  )
}

export default Notification
