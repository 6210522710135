import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import varified from 'src/Assets/Icons/verified.svg'
import Breadcrumb from 'src/Components/BreadCrumb'
import {
  SendNotification,
  ViewEmployeeList,
  ViewNotificationList,
} from 'src/Redux/Slices/notificationListSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
// import { notificationsData } from 'src/Utils/Constants'
import { ROUTES } from 'src/Utils/Routes'
import styles from './index.module.scss'

// interface IFormValues {
//   employeeId: string
//   title: string
//   description: string
// }

// interface empList {
//   uuid: string
//   firstName: string
//   lastName: string
//   profileUrl: string | undefined
//   emailId: string
// }

const Notification: React.FunctionComponent = () => {
  // const length = 20
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(ViewNotificationList())
    dispatch(ViewEmployeeList())
  }, [])

  const notificationList = useAppSelector(
    (state) => state?.notificationList?.data?.notificationList,
  )
  const employeeList = useAppSelector(
    (state) => state?.notificationList?.data?.employeeList,
  )

  const [formValues, setFormValues] = useState({
    employeeId: '',
    title: '',
    description: '',
  })
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    // You may perform validation here and update errors state accordingly
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (formValues.employeeId === '') {
      newErrors.employeeId = 'Please select Employee'
      valid = false
    }
    if (!formValues.title.trim()) {
      newErrors.title = 'Title is required'
      valid = false
    }
    if (!formValues.description.trim()) {
      newErrors.description = 'Description is required'
      valid = false
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      dispatch(SendNotification(formValues)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          dispatch(ViewNotificationList())
          setFormValues({
            employeeId: '',
            title: '',
            description: '',
          })
        },
      )
    }
  }

  // const TempDate = [
  //   {
  //     id: 1,
  //     name: 'John Doe',
  //     email: 'john.doe@example.com',
  //     profileURL: 'https://picsum.photos/id/200/200/300',
  //   },
  //   {
  //     id: 2,
  //     name: 'Jane Smith',
  //     email: 'jane.smith@example.com',
  //     profileURL: 'https://picsum.photos/id/235/200/300',
  //   },
  //   {
  //     id: 3,
  //     name: 'Michael Johnson',
  //     email: 'michael.johnson@example.com',
  //     profileURL: 'https://picsum.photos/id/238/200/300',
  //   },
  //   {
  //     id: 4,
  //     name: 'Emily Brown',
  //     email: 'emily.brown@example.com',
  //     profileURL: 'https://picsum.photos/id/139/200/300',
  //   },
  //   {
  //     id: 5,
  //     name: 'David Lee',
  //     email: 'david.lee@example.com',
  //     profileURL: 'https://picsum.photos/id/240/200/300',
  //   },
  //   {
  //     id: 6,
  //     name: 'Sarah Wilson',
  //     email: 'sarah.wilson@example.com',
  //     profileURL: 'https://picsum.photos/id/241/200/300',
  //   },
  //   {
  //     id: 7,
  //     name: 'Christopher Davis',
  //     email: 'christopher.davis@example.com',
  //     profileURL: 'https://picsum.photos/id/200/200/300',
  //   },
  //   {
  //     id: 8,
  //     name: 'Olivia Taylor',
  //     email: 'olivia.taylor@example.com',
  //     profileURL: 'https://picsum.photos/id/235/200/300',
  //   },
  //   {
  //     id: 9,
  //     name: 'William Anderson',
  //     email: 'william.anderson@example.com',
  //     profileURL: 'https://picsum.photos/id/238/200/300',
  //   },
  //   {
  //     id: 10,
  //     name: 'Emma Stone',
  //     email: 'emma.stone@gmail.com',
  //     profileURL: 'https://picsum.photos/id/139/200/300',
  //   },
  //   {
  //     id: 11,
  //     name: 'Chris Hemsworth',
  //     email: 'chris.hemsworth@gmail.com',
  //     profileURL: 'https://picsum.photos/id/240/200/300',
  //   },
  //   {
  //     id: 12,
  //     name: 'Taylor Swift',
  //     email: 'taylor.swift@gmail.com',
  //     profileURL: 'https://picsum.photos/id/241/200/300',
  //   },
  // ]

  const convertDate = (dateString: string) => {
    const dateObj = new Date(dateString)
    const options = { month: 'long', day: 'numeric', year: 'numeric' }
    return dateObj.toLocaleDateString('en-US', options)
  }

  return (
    <>
      <Box className={styles.main}>
        <Breadcrumb
          title='Notifications'
          Menus={[
            { name: 'Setting', route: ROUTES.SETTING },
            { name: 'Notifications', route: ROUTES.NOTIFICATION },
          ]}
        />
        <Grid container xl={12} md={12} className={styles.GridList}>
          {/* <Grid xl={8} md={8} className={styles.gridContainer}>
            <Box className={styles.container}>
              <Typography className={styles.title}>
                All Notification ({length})
              </Typography>
              {notificationsData(length).length ? (
                notificationsData(length).map((notification, index) => (
                  <>
                    <Box className={styles.notification} key={index}>
                      <Box className={styles.icon}>
                        <img src={varified} alt='message' />
                      </Box>
                      <Box className={styles.notiContent}>
                        <Box className={styles.head}>
                          <Typography className={styles.p}>
                            {notification.title}
                          </Typography>
                          <Typography className={styles.date}>
                            {notification.date}
                          </Typography>
                        </Box>
                        <Typography className={styles.subTitle}>
                          {notification.subTitle}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ))
              ) : (
                <Typography>No notifications available.</Typography>
              )}
            </Box>
          </Grid> */}
          <Grid xl={8} md={8} className={styles.gridContainer}>
            <Box className={styles.container}>
              <Typography className={styles.title}>
                All Notification ({notificationList?.length})
              </Typography>
              {notificationList?.length ? (
                notificationList?.map((notification: any, index: any) => {
                  return (
                    <>
                      <Box className={styles.notification} key={index}>
                        <Box className={styles.icon}>
                          <img src={varified} alt='message' />
                        </Box>
                        <Box className={styles.notiContent}>
                          <Box className={styles.head}>
                            <Typography className={styles.p}>
                              {notification?.title}
                            </Typography>
                            <Typography className={styles.date}>
                              {convertDate(notification?.createdAt)}
                            </Typography>
                          </Box>
                          <Typography className={styles.subTitle}>
                            {notification?.description}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )
                })
              ) : (
                <Typography>No notifications available.</Typography>
              )}
            </Box>
          </Grid>

          <Grid xl={4} md={4} className={styles.AddBox}>
            <Box className={styles.container}>
              <Typography className={styles.title}>
                Create Notification
              </Typography>
              <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                <Box className={styles.createNotification}>
                  <Box className={styles.flexStartBox}>
                    <Typography className={styles.textFieldWrapper}>
                      Select Employee
                      <Typography className={styles.FF002E}>*</Typography>
                    </Typography>
                    {/* <Select
                      fullWidth
                      id='employeeId'
                      name='employeeId'
                      renderValue={
                        formValues.employeeId !== ''
                          ? () => formValues.employeeId
                          : () => 'Select'
                      }
                      value={formValues.employeeId}
                      onChange={(e) => {
                        handleFieldChange(
                          e as React.ChangeEvent<HTMLInputElement>,
                        )
                      }}
                      error={!!errors.employeeId}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200, // Adjust the value as needed
                          },
                        },
                      }}
                      className={styles.field}
                      IconComponent={(props) => (
                        <IconButton {...props}>
                          <img src={IconChevronDown} alt='IconChevronDown' />
                        </IconButton>
                      )}
                      displayEmpty
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {TempDate.map((item, index) => (
                        <MenuItem key={index} value={item.name} sx={{ py: 1 }}>
                          <Box display={'flex'} gap={2}>
                            <Box display={'flex'} alignItems={'center'}>
                              <img
                                src={item.profileURL}
                                height={'34px'}
                                width={'34px'}
                                style={{ borderRadius: '49%', margin: '0px 5px' }}
                                alt=''
                              />
                            </Box>
                            <Box display={'flex'} flexDirection={'column'}>
                              <InputLabel>
                                {item.name}
                              </InputLabel>
                              <InputLabel>
                                {item.email}
                              </InputLabel>
                            </Box>
                          </Box>
                        </MenuItem>
                      ))}
                    </Select> */}
                    <Autocomplete
                      fullWidth
                      // value={TempDate.find((item: any) => item.id === formValues?.employeeId)?.name ?? ''}
                      value={
                        employeeList?.length
                          ? employeeList?.find(
                              (item: any) =>
                                item?.uuid === formValues?.employeeId,
                            )?.firstName
                          : ''
                      }
                      id='combo-box-demo'
                      options={
                        employeeList?.length
                          ? employeeList?.map(
                              (option) =>
                                option?.firstName + ' ' + option?.lastName,
                            )
                          : []
                      }
                      // onChange={(e, value) => {
                      //   handleFieldChange({ target: { name: 'employeeId', value: value } })
                      // }}
                      onChange={(event, newValue) => {
                        const selectedItem = employeeList?.find(
                          (item) =>
                            item?.firstName + ' ' + item?.lastName === newValue,
                        )
                        handleFieldChange({
                          target: {
                            name: 'employeeId',
                            value: selectedItem?.uuid ?? '',
                          },
                        })
                      }}
                      renderOption={(
                        props: React.HTMLAttributes<HTMLLIElement>,
                        option: string,
                      ) => (
                        <Box {...props} display={'flex'} gap={2}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Avatar
                              src={
                                employeeList?.find(
                                  (item) =>
                                    item.firstName + ' ' + item.lastName ===
                                    option,
                                )?.profileUrl
                              }
                              height={'34px'}
                              width={'34px'}
                              style={{ borderRadius: '49%', margin: '0px 5px' }}
                              alt=''
                            />
                          </Box>
                          <Box display={'flex'} flexDirection={'column'}>
                            <InputLabel>{option}</InputLabel>
                            <InputLabel>
                              {
                                employeeList?.find(
                                  (item) =>
                                    item.firstName + ' ' + item.lastName ===
                                    option,
                                )?.emailId
                              }
                            </InputLabel>
                          </Box>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id='employeeId'
                          name='employeeId'
                          value={formValues?.employeeId}
                          // onChange={handleFieldChange}
                          error={!!errors?.employeeId}
                          helperText={errors?.employeeId}
                        />
                      )}
                    />
                    {/* <FormHelperText sx={{ mx: 1.5 }}>
                      {errors.employeeId}
                    </FormHelperText> */}
                  </Box>
                  <Box className={styles.flexStartBox}>
                    <Typography className={styles.textFieldWrapper}>
                      Notification Title
                      <Typography className={styles.FF002E}>*</Typography>
                    </Typography>
                    <TextField
                      fullWidth
                      variant='outlined'
                      className={styles.field}
                      type='text'
                      id='title'
                      name='title'
                      placeholder='Enter your Notification Title'
                      value={formValues.title}
                      onChange={handleFieldChange}
                      error={!!errors.title}
                      helperText={errors.title}
                    />
                  </Box>
                  <Box className={styles.flexStartBox}>
                    <Typography className={styles.textFieldWrapper}>
                      Notification Description
                      <Typography className={styles.FF002E}>*</Typography>
                    </Typography>
                    <TextField
                      multiline
                      rows={4}
                      fullWidth
                      variant='outlined'
                      type='text'
                      id='description'
                      name='description'
                      className={styles.field}
                      value={formValues.description}
                      onChange={handleFieldChange}
                      error={!!errors.description}
                      helperText={errors.description}
                      placeholder='Enter your Notification description'
                    />
                  </Box>
                  <Button type='submit' className={styles.button}>
                    Send Notification
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Notification
