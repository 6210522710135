import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  Box,
  Typography,
  DialogContent,
} from '@mui/material'
import { Icons } from 'src/Utils/Constants'
import download from 'src/Assets/Icons/download.svg'
import Style from './index.module.scss'

const FailedPopup = ({
  open,
  setOpen,
  handleOpen,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
}) => {
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={open}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Application Download
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='Cancel'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={Style.DialogContentBox}>
              <Typography className={Style.textWrapper}>
                Opt for the seamless and automated experience with
                BenefitsBoost's submission feature, or take control with the
                option to download and print a PDF. This printable document
                includes all questions and answers, aligning with the SSA's
                inquiries, allowing you to manually complete the application on
                the SSA's platform at your own pace.
              </Typography>
            </Box>
            <DialogActions>
              <Box className={Style.AddButton}>
                <Button className={Style.Button} onClick={handleClose}>
                  <Typography variant='button'>Download PDF</Typography>
                  <img src={download} alt='download' />
                </Button>
              </Box>
            </DialogActions>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default FailedPopup
