import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { MainComponent, ApplicationComponent } from 'src/Utils/Constants'
import { ROUTES } from 'src/Utils/Routes'
import Style from './index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import ResetPasswordPopUp from 'src/Components/ResetPasswordPopUp'

const Main: React.FunctionComponent = () => {
  const location = useLocation()
  const data = useAppSelector((state) => state.auth?.data)
  const viewProfile = useAppSelector((state) => state.profile?.data)

  const [openDialog, setOpenDialog] = useState(false)
  const handleOpen = () => {
    setOpenDialog(true)
  }
  useEffect(() => {
    if (data?.tempPassword === true) {
      handleOpen()
    }
  }, [])

  return (
    <>
      <Box className={Style.MainBox} component='main'>
        {location.pathname === ROUTES.DASHBOARD && <MainComponent.Dashboard />}
        {location.pathname === ROUTES.PROFILE && <MainComponent.Profile />}

        {location.pathname === ROUTES.ORGANIZATIONS && (
          <MainComponent.Organizations />
        )}
        {location.pathname === ROUTES.ORGANIZATION_DETAIL && (
          <MainComponent.OrganizationDetail />
        )}

        {location.pathname === ROUTES.ADMIN_MANAGE_EMPLOYEES && (
          <MainComponent.AdminManageEmployees />
        )}

        {location.pathname === ROUTES.ADMIN_EMPLOYEE_DETAIL && (
          <MainComponent.AdminEmployeeDetails />
        )}
        {location.pathname === ROUTES.LEGAL_MANAGE_EMPLOYEES && (
          <MainComponent.LegalManageEmployees />
        )}

        {location.pathname === ROUTES.LEGAL_EMPLOYEE_DETAIL && (
          <MainComponent.LegalEmployeeDetails />
        )}
        {location.pathname === ROUTES.ROLE && <MainComponent.Role />}
        {location.pathname === ROUTES.ROLE_DETAILS && (
          <MainComponent.RoleDetails />
        )}

        {location.pathname === ROUTES.APPLICATIONS && (
          <MainComponent.Applications />
        )}
        {location.pathname === ROUTES.ROLE_PERMISSION && (
          <MainComponent.RolePermission />
        )}

        {location.pathname === ROUTES.APPLICATION_DETAIL && (
          <>
            <ApplicationComponent.ApplicationDetail />
          </>
        )}
        {location.pathname === ROUTES.SUBSCRIPTION_PLAN && (
          <MainComponent.SubscriptionPlan />
        )}
        {location.pathname === ROUTES.ENHANCED_PLAN && (
          <MainComponent.EnhancedPlan />
        )}
        {location.pathname === ROUTES.SETTING && <MainComponent.Setting />}

        {location.pathname === ROUTES.NOTIFICATION && (
          <MainComponent.Notification />
        )}
      </Box>
      {data?.tempPassword === true && viewProfile?.tempPassword === true && (
        <>
          <ResetPasswordPopUp
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            handleOpen={handleOpen}
          />
        </>
      )}
    </>
  )
}

export default Main
