import { Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import CustomStepper from 'src/Components/Application/CustomStepper'
import AdditionalInformation from './AdditionalInformation'
import GeneralHealth from './GeneralHealth'
import HospitalMedicalFacilityCare from './HospitalMedicalFacilityCare'
import IllnessesInjuriesConditions from './IllnessesInjuriesConditions'
import MedicalProfessionals from './MedicalProfessionals'
import MedicalTests from './MedicalTests'
import Medicine from './Medicine'
import VisionHearing from './VisionHearing'
import { useLocation } from 'react-router-dom'
import { setMedicalDetailsStep } from 'src/Redux/Slices/ApplicationStore/ApplicationStepStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'

const MedicalDetails = ({
  chatForm,
  setChatForm,
}: {
  chatForm?: any
  setChatForm?: any
}) => {
  const formComponents = [
    {
      title: 'General Health',
      content: GeneralHealth,
    },
    {
      title: 'Vision & Hearing',
      content: VisionHearing,
    },
    {
      title: 'Illnesses, Injuries, & Conditions',
      content: IllnessesInjuriesConditions,
    },
    {
      title: 'Medical Professionals',
      content: MedicalProfessionals,
    },
    {
      title: 'Hospital or Medical Facility Care',
      content: HospitalMedicalFacilityCare,
    },
    {
      title: 'Medical Tests',
      content: MedicalTests,
    },
    {
      title: 'Medicine',
      content: Medicine,
    },
    {
      title: 'Additional Information',
      content: AdditionalInformation,
    },
  ]

  const dispatch = useAppDispatch()
  const location = useLocation()
  const currentPath = location.pathname
  const myRef = useRef<HTMLDivElement>(null)
  const totalSteps = formComponents.length

  const MedicalDetailsStep = useAppSelector(
    (state) => state?.applicationStore?.ApplicationStep?.MedicalDetailsStep,
  )

  const [activeStep, setActiveStep] = useState(
    MedicalDetailsStep ? parseInt(MedicalDetailsStep) : 0,
  )

  useEffect(() => {
    // localStorage.setItem('MedicalDetails', activeStep.toString())
    dispatch(setMedicalDetailsStep(activeStep.toString()))
  }, [activeStep, currentPath])
  useEffect(() => {
    setChatForm(false)
  }, [])

  const handleNext = () => {
    setChatForm(false)
    setActiveStep((prevActiveStep) =>
      prevActiveStep < totalSteps - 1 ? prevActiveStep + 1 : prevActiveStep,
    )
    myRef?.current?.scrollIntoView()
  }

  const handleBack = () => {
    setChatForm(false)
    setActiveStep((prevActiveStep) =>
      prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep,
    )
    myRef?.current?.scrollIntoView()
  }

  // const FormToShow = formComponents.map(
  //   (formComponent) => formComponent.content,
  // )[activeStep]
  const FormToShow = formComponents[activeStep].content // Get the form component based on the active step

  return (
    <>
      <CustomStepper
        myRef={myRef}
        formComponents={formComponents}
        activeStep={activeStep}
        handleNext={handleNext}
        handleBack={handleBack}
      />
      <Box>
        {process.env.REACT_APP_MILESTONE_4 && FormToShow && (
          <FormToShow
            chatForm={chatForm}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        )}
      </Box>
    </>
  )
}

export default MedicalDetails
