/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useEffect, useState } from 'react'
import { Grid, Box, Typography, Input, Button, Chip } from '@mui/material'
import { CutString, Icons, convertISOToDate } from 'src/Utils/Constants'
import './index.scss'
import PopupForm from './Add/popup'
import {
  getOrganizationList,
  SetSelectedOrganization,
} from 'src/Redux/Slices/orgSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { useNavigate } from 'react-router-dom'
import { OrganizationListObj } from 'src/Redux/Services/Types/orgType'
import { DynamicObj } from '../SignIn'
import { ROUTES } from 'src/Utils/Routes'
import ProfilePhoto from 'src/Assets/Icons/ProfilePhoto.svg'

import Breadcrumb from 'src/Components/BreadCrumb'

const Organizations: React.FunctionComponent = () => {
  const { organizationList } = useAppSelector((state) => state?.organization)
  const [orgnizationList, setOrganizationList] = useState(organizationList)
  const dispatch = useAppDispatch()
  const [openDialog, setOpenDialog] = useState(false)
  const searchParam = [
    'name',
    'organization_address',
    'address',
    'description',
    'status',
    'phoneNumber',
    'createdAt',
    'emailId',
  ]
  const [q, setQ] = React.useState('')
  const handleOpen = () => {
    setOpenDialog(true)
  }
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getOrganizationList()).then((res) => {
      const objArray = res.payload?.data
      setOrganizationList(objArray)
    })
  }, [])
  useEffect(() => {
    setOrganizationList(organizationList)
  }, [organizationList])

  function find(items: object[]) {
    return items?.filter((item: DynamicObj) => {
      return searchParam?.some((newItem: string) => {
        if (newItem === 'organization_address') {
          return (
            item?.organization_address.streetAddress
              ?.toString()
              .toLowerCase()
              .includes(q.toLowerCase()) ||
            item?.organization_address.city
              ?.toString()
              .toLowerCase()
              .includes(q.toLowerCase()) ||
            item?.organization_address.state
              ?.toString()
              .toLowerCase()
              .includes(q.toLowerCase()) ||
            item?.organization_address.postalCode
              ?.toString()
              .toLowerCase()
              .includes(q.toLowerCase())
          )
        }
        return item[newItem]?.toString().toLowerCase().includes(q.toLowerCase())
      })
    })
  }
  // it's just a temp data. It will be replaced in future once API integration is done.

  return (
    <>
      {openDialog && (
        <PopupForm
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          handleOpen={handleOpen}
        />
      )}
      <Box className='organizations'>
        <Grid xs={12} className='main'>
          <Box className='div'>
            <Breadcrumb
              title='Manage Organizations'
              Menus={[{ name: 'Organizations', route: ROUTES.ORGANIZATIONS }]}
            />
            <Box className='div-4'>
              <Box className='left'>
                <Typography className='text-wrapper-3'>
                  Organizations ({organizationList.length})
                </Typography>
                <Box className='SearchBox'>
                  <img src={Icons.Search} alt='Search' />
                  <Input
                    sx={{ p: 0 }}
                    disableUnderline
                    className='SearchEm'
                    placeholder='Search organization'
                    size='small'
                    type='text'
                    value={q}
                    onChange={(e: { target: { value: string } }) => {
                      setQ(e.target.value)
                    }}
                  />
                </Box>
              </Box>
              <Box className='right'>
                <Button
                  className='Add'
                  variant='contained'
                  onClick={handleOpen}
                >
                  <img
                    src={Icons.Plus}
                    alt='Plus'
                    style={{ marginRight: '8px' }}
                  />
                  Add Organization
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className='div-7'>
            {find(orgnizationList)?.map(
              (data: OrganizationListObj, index: number) => (
                <>
                  <Grid
                    xs={4}
                    key={index}
                    display='block'
                    alignItems='flex-start'
                    justifyContent='space-between'
                    p={4}
                    height='100%'
                    width='100%'
                    sx={{
                      borderRadius: 4,
                      backgroundColor: 'white',
                      py: '30px',
                      px: '30px',
                      flexGrow: 1,
                      flexBasis: '480px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      dispatch(SetSelectedOrganization(data))
                      navigate('/organization_detail')
                    }}
                  >
                    <Box
                      gap={2}
                      display='block'
                      alignItems='flex-start'
                      justifyContent='space-between'
                      p={4}
                      height='100%'
                      width='100%'
                      sx={{
                        borderRadius: 4,
                        backgroundColor: 'white',
                        py: '30px',
                        px: '30px',
                        flexGrow: 1,
                        flexBasis: '480px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        dispatch(SetSelectedOrganization(data))
                        navigate('/organization_detail')
                      }}
                    >
                      <Grid xs={12}>
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            fontSize: '20px',
                            fontFamily: 'SFUiDisplaySemiBold',
                            mb: '20px',
                          }}
                        >
                          {data?.name}
                        </Typography>
                      </Grid>
                      <Box
                        gap={2}
                        display='block'
                        alignItems='flex-start'
                        height='200px'
                        width='100%'
                        sx={{
                          borderRadius: 4,
                          backgroundColor: 'white',
                        }}
                      >
                        <Grid
                          xs={12}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Grid
                            className='OrgDec'
                            sx={{ display: 'flex', marginRight: '20px' }}
                          >
                            <Box
                              border={1}
                              borderColor='grey.400'
                              borderRadius={2}
                              height={'52px'}
                              width={'52px'}
                              p={1}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                className='LtsOrgBlack'
                                width={'52px'}
                                height={'52px'}
                                src={
                                  data?.profileUrl
                                    ? data.profileUrl
                                    : ProfilePhoto
                                }
                                alt='OrganizationData'
                              />
                            </Box>
                          </Grid>
                          <Grid xs={10}>
                            <Typography
                              sx={{
                                p: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontSize: '16px',
                                fontFamily: 'SFProDisplaySemiBold',
                              }}
                            >
                              {data?.name}
                            </Typography>
                            <Typography
                              sx={{
                                p: 0,
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '16px',
                                fontFamily: 'SFProDisplayRegular',
                              }}
                            >
                              <img
                                src={Icons.location}
                                alt='location'
                                style={{
                                  marginRight: '4px',
                                  width: '20px',
                                  height: '20px',
                                }}
                              />
                              {data?.organization_address && (
                                <>
                                  {data.organization_address.streetAddress && (
                                    <>
                                      {data.organization_address.streetAddress},{' '}
                                    </>
                                  )}
                                  {data.organization_address.city && (
                                    <>{data.organization_address.city}, </>
                                  )}
                                  {data.organization_address.state && (
                                    <>{data.organization_address.state} </>
                                  )}
                                  {data.organization_address.postalCode && (
                                    <>{data.organization_address.postalCode}</>
                                  )}
                                </>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid xs={12} py={'10px'}>
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '14px',
                              fontFamily: 'SFProDisplayRegular',
                              color: 'text.secondary',
                            }}
                          >
                            {CutString(data?.description ?? '', 200)}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={12}
                          sx={{
                            borderColor: 'grey.400',
                            borderBottom: '1px dotted black',
                          }}
                        ></Grid>
                        <Grid item xs={12} display='flex' py={'10px'}>
                          <Grid item xs={6} md={6} display='flex'>
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontSize: '16px',
                                fontFamily: 'SFProDisplaymedium',
                              }}
                            >
                              {data?.name}
                            </Typography>
                            <div
                              style={{
                                height: '6px',
                                width: '6px',
                                backgroundColor: 'grey.300',
                                borderRadius: '50%',
                                padding: '5px',
                                margin: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            />
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontSize: '16px',
                                fontFamily: 'SFProDisplayregular',
                              }}
                            >
                              {data?.address}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            md={6}
                            display='flex'
                            justifyContent={'flex-end'}
                            ml={'auto'}
                          >
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between',
                                fontSize: '16px',
                                fontFamily: 'SFProDisplayregular',
                              }}
                            >
                              <Chip
                                label={convertISOToDate(data?.createdAt ?? '')}
                              ></Chip>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid xs={12} display='flex' gap={5}>
                          {data?.phoneNumber && (
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontSize: '14px',
                                fontFamily: 'SFProDisplayregular',
                              }}
                            >
                              <img
                                style={{ paddingRight: '5px' }}
                                src={Icons.phone}
                                alt='phone'
                              />
                              {data?.phoneNumber}
                            </Typography>
                          )}
                          {data?.emailId && (
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontSize: '14px',
                                fontFamily: 'SFProDisplayregular',
                              }}
                            >
                              <img
                                style={{ paddingRight: '5px' }}
                                src={Icons.email}
                                alt='email'
                              />
                              {data?.emailId}
                            </Typography>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </>
              ),
            )}
          </Box>
        </Grid>
      </Box>
    </>
  )
}

export default Organizations
