// This slice manages the state for the sign in feature
import { createSlice } from '@reduxjs/toolkit'
import { DynamicObj } from 'src/Utils/Constants'

// Define the initial state of the slice
const initialState = {
  isPublic: false,
  applicationId: '',
  basicId: '',
  familyId: '',
  legalId: '',
  disabilityId: '',
  EducationAndEmployementId: '',
  MedicalDetailId: '',
  uploadDocumentId: '',
  signConsentId: '',
  disqualification: {},
  isDisqualified: false,
  finalSubmit: false,
}

// Define the reducers that can modify the state
const applicationIdStore = createSlice({
  name: 'applicationId',
  initialState,
  reducers: {
    resetApplicationIds: (state) => {
      state.applicationId = ''
      state.basicId = ''
      state.familyId = ''
      state.legalId = ''
      state.disabilityId = ''
      state.EducationAndEmployementId = ''
      state.MedicalDetailId = ''
      state.uploadDocumentId = ''
      state.signConsentId = ''
    },
    setPublic: (state, action: { payload: boolean }) => {
      state.isPublic = action.payload
    },
    setApplicationId: (state, action: { payload: string }) => {
      state.applicationId = action.payload
    },
    setBasicId: (state, action: { payload: string }) => {
      state.basicId = action.payload
    },
    setFamilyId: (state, action: { payload: string }) => {
      state.familyId = action.payload
    },
    setLegalId: (state, action: { payload: string }) => {
      state.legalId = action.payload
    },
    setDisabilityId: (state, action: { payload: string }) => {
      state.disabilityId = action.payload
    },
    setEducationAndEmployementId: (state, action: { payload: string }) => {
      state.EducationAndEmployementId = action.payload
    },
    setMedicalDetailId: (state, action: { payload: string }) => {
      state.MedicalDetailId = action.payload
    },
    setUploadDocumentId: (state, action: { payload: string }) => {
      state.uploadDocumentId = action.payload
    },
    setSignConsentId: (state, action: { payload: string }) => {
      state.signConsentId = action.payload
    },
    setDisqualification: (state, action: { payload: DynamicObj }) => {
      const obj = action.payload
      state.disqualification = { ...state.disqualification, ...obj }
    },
    setIsDisqualified: (state, action: { payload: boolean }) => {
      state.isDisqualified = action.payload
    },
    setFinalSubmit: (state, action: { payload: boolean }) => {
      state.finalSubmit = action.payload as unknown as boolean
    },
  },
})

export default applicationIdStore.reducer

export const {
  setPublic,
  resetApplicationIds,
  setApplicationId,
  setBasicId,
  setFamilyId,
  setLegalId,
  setDisabilityId,
  setEducationAndEmployementId,
  setMedicalDetailId,
  setUploadDocumentId,
  setSignConsentId,
  setDisqualification,
  setIsDisqualified,
  setFinalSubmit,
} = applicationIdStore.actions
