import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import Styles from '../index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import JobDetails from '../EditForms/JobDetails'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { hasEmpty } from 'src/Utils/Constants'

const EducationDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)
  const jobDetailsRef = useRef<HTMLDivElement>(null)
  const handleEditToggle = () => {
    setEditMode(!editMode)
    jobDetailsRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.EducationAndEmployement?.JobDetails,
  )

  const jobDetailsSectionId = useAppSelector(
    (state) =>
      state?.steps?.EducationEmploymentApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Job Details',
      )?.sectionId,
  )
  return (
    <div id={jobDetailsSectionId} ref={jobDetailsRef}>
      {!editMode ? (
        <>
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  Job Details
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>

            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Statement that applies to you
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.oneOrMoreJobsIn15Years}
                      </Typography>
                    </Box>
                  </Box>
                  {data.oneOrMoreJobsIn15Years ===
                    'I had more than one job during the last 15 years before I was unable to work.' ??
                  data.didSuperviseOther === false ? (
                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.p}>
                          Got it. Did you lead or mentor other workers?
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data.didLeadOrMentorOthers ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}

                  {data?.oneOrMoreJobsIn15Years !==
                  'I had more than one job during the last 15 years before I was unable to work.' ? (
                    <>
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Job description
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.jobDescriptionOf15Years}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Type of Job apply to you
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          {data?.applicableJobs?.map((item: string) => (
                            <>
                              <Typography className={Styles.textWrapper4}>
                                {item}
                              </Typography>
                            </>
                          ))}
                        </Box>
                      </Box>

                      <Box className={Styles.div2}>
                        <Typography variant='h6' className={Styles.SubTitle}>
                          Work hours you put in each day for the following
                        </Typography>
                      </Box>
                      <Box className={Styles.cardDiv}>
                        {/* row-1 */}
                        <Grid container className={Styles.cardFrame}>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Walk
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.hoursToPerform?.walk}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Stand
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.hoursToPerform?.stand}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Sit
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.hoursToPerform?.sit}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Climb
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.hoursToPerform?.climb}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Bend
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.hoursToPerform?.bend}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        {/* row-2 */}
                        <Grid container className={Styles.cardFrame}>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                kneel
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.hoursToPerform?.kneel}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Crouch
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.hoursToPerform?.crounch}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Crawl
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.hoursToPerform?.crawl}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Write or Type
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.hoursToPerform?.writeOrType}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Handel Large Object
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.hoursToPerform?.handleLargeObject}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        {/* row-3 */}
                        <Grid container className={Styles.cardFrame}>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Handle Small Objects
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.hoursToPerform?.handleSmallObjects}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Reach for Things
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.hoursToPerform?.reachToThings}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <div className={Styles.dottedLine} />
                        {/* row-4 */}
                        <Grid container className={Styles.cardFrame}>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                About Lifting
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.isLifitingInJob ? 'Yes' : 'No'}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        {/* row-5 */}
                        {data?.isLifitingInJob ? (
                          <>
                            <Grid container className={Styles.cardFrame}>
                              <Grid item xs={8} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    What was lifted, and how often, in this job?
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns} mt={'1px'}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper2}
                                  >
                                    {data?.whatLiftedAndHowOften}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                            {/* row-6 */}
                            <Grid container className={Styles.cardFrame}>
                              <Grid item xs={4} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Carry these items(in feet)
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper3}
                                  >
                                    {data?.howFarCarryItems}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Heaviest weight lifted
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper3}
                                  >
                                    {data?.heaviestWeightLifted}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Weightlifted 30% of the day or more
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper3}
                                  >
                                    {data?.howmuchWeightLiftedPerDay}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </>
                        ) : null}

                        <div className={Styles.dottedLine} />
                        {/* row-7 */}
                        <Grid container className={Styles.cardFrame}>
                          <Grid item xs={2} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Supervise other people
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper3}
                              >
                                {data?.didSuperviseOther ? 'Yes' : 'No'}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        {/* row-8 */}
                        {data?.didSuperviseOther ? (
                          <>
                            {' '}
                            <Grid container className={Styles.cardFrame}>
                              <Grid item xs={4} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Your time spent supervising others
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper3}
                                  >
                                    {`${data?.percentageOfTimeSpentSupervising}`}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    People you supervise
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper3}
                                  >
                                    {data?.howManyPeopleSupervise}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={4} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Hire and fire employees
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper3}
                                  >
                                    {data?.didHireOrFirePeople ? 'Yes' : 'No'}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </>
                        ) : null}
                      </Box>
                    </>
                  ) : null}
                </Box>
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Job Details
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <JobDetails handleEditToggle={handleEditToggle} />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default EducationDetails
