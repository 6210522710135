// This slice manages the state for the sign in feature
import {
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import {
  BasicDetailsType,
  CitizenshipType,
  CommunicationPreferenceType,
  ContactInformationType,
  MilitaryServiceType,
  PassApplicationType,
} from '../../Services/Types/applicationType'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { RootState } from 'src/Redux/store'
import { handleResponse } from 'src/Utils/Constants'
import BasicDetailsService from '../../Services/BasicDetailsService'
import secureLocalStorage from 'react-secure-storage'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: BasicDetailsType
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {
    assistancedata: {
      uuid: null,
      address: null,
      emailId: null,
      lastName: null,
      firstName: null,
      phoneNumber: null,
      organizationName: null,
      relationToApplicant: null,
    },
    assigneeData: {
      uuid: null,
      lastName: null,
      firstName: null,
      profileURL: null,
    },
    flagdata: [],
    ContactInformation: {
      applicant: '',
      assistanceId: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      isUSAPhoneNumber: undefined,
      physicalAddress: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      isLiveAtPhysicalAddress: undefined,
      currentAddress: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      wantToReceiveMailAtPhysicalAddress: undefined,
      mailingAddress: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
    CommunicationPreference: {
      basicDetailId: '',
      isPreferredLanguageEnglish: undefined,
      preferredReadingLanguage: '',
      preferredSpeakingLanguage: '',
      preferredWritingLanguage: '',
      canReadEnglish: undefined,
      canWriteEnglish: undefined,
      canSpeakEnglish: undefined,
      isAbleToReadWriteMoreThenName: undefined,
    },
    Citizenship: {
      basicDetailId: '',
      wereBornInUSA: undefined,
      confirmUSACitizen: '',
      citizenshipCountryName: [],
      isGoingThroughProcessOfUSACitizenship: undefined,
      isLegalResidentOfUSA: undefined,
      residentCardNumber: '',
    },
    MilitaryService: {
      basicDetailId: '',
      isServedInMilitary: undefined,
      isReceivingFederalAgencyBenefit: undefined,
      typeOfBenefit: '',
      serviceInformation: [
        {
          typeOfDuty: '',
          branchOfService: '',
          startDate: '',
          endDate: '',
        },
      ],
    },
    PassApplication: {
      basicDetailId: '',
      isReceivingMedicareBenefits: undefined,
      haveAppliedForMedicareBenefits: undefined,
      statusOfPreviousMedicareBenefit: '',
      // SSDI
      isReceivingSSDI: undefined,
      haveAppliedForSSDI: undefined,
      statusOfPreviousSSDI: '',
      SSDIApprovedEndDate: '',
      isSSDIDenial60Days: undefined,
      denialDateSSDI: '',
      // SSI
      isReceivingSSI: undefined,
      haveAppliedForSSI: undefined,
      statusOfSSI: '',
      SSIApprovedEndDate: '',
      isSSIDenial60Days: undefined,
      denialDateSSI: '',
      // other
      haveAppliedWithSSN: undefined,
      appliedForAdditionalBenefits: '',
      currentlyparticipatingPrograms: '',
      benefitAppliedSSNDetail: [
        {
          suffix: '',
          firstName: '',
          middleName: '',
          lastName: '',
          monthlyBenefitAmount: '',
          socialSecurityNumber: '',
        },
      ],
      participateProgramDetails: [
        {
          organizationName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          contactName: '',
          phone: '',
          estimatedDate: '',
          description: '',
          stillParticipatedInProgram: undefined,
        },
      ],
    },
  },
}

// Define the reducers that can modify the state
const BasicDetails = createSlice({
  name: 'BasicDetails',
  initialState,
  reducers: {
    setBasicDetailsLoading: (state) => {
      state.loading = true
    },
    setBasicDetailsSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setBasicDetailsFail: (state) => {
      state.success = false
      state.loading = false
      state.data = initialState.data
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AddContactInformation.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddContactInformation.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddContactInformation.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddCommunicationPreference.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddCommunicationPreference.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddCommunicationPreference.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddCitizenship.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddCitizenship.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddCitizenship.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddMilitaryService.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddMilitaryService.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddMilitaryService.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddPassApplication.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddPassApplication.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddPassApplication.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

export const {
  setBasicDetailsLoading,
  setBasicDetailsSuccess,
  setBasicDetailsFail,
} = BasicDetails.actions

export const AddContactInformation = createAsyncThunk(
  'ContactInformation', // name of the action
  async (
    data: ContactInformationType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await BasicDetailsService.AddContactInformation(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditContactInformation = createAsyncThunk(
  'ContactInformation', // name of the action
  async (
    data: any,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await BasicDetailsService.EditContactInformation(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddCommunicationPreference = createAsyncThunk(
  'CommunicationPreference', // name of the action
  async (
    data: CommunicationPreferenceType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await BasicDetailsService.AddCommunicationPreference(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddCitizenship = createAsyncThunk(
  'Citizenship', // name of the action
  async (
    data: CitizenshipType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await BasicDetailsService.AddCitizenship(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddMilitaryService = createAsyncThunk(
  'MilitaryService', // name of the action
  async (
    data: MilitaryServiceType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await BasicDetailsService.AddMilitaryService(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddPassApplication = createAsyncThunk(
  'PassApplication', // name of the action
  async (
    data: PassApplicationType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await BasicDetailsService.AddPassApplication(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default BasicDetails.reducer
