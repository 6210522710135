import * as React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledBox = styled(Box)<{
  severity: 'error' | 'info' | 'success' | 'warning'
}>(({ theme, severity }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  backgroundColor: getBackgroundColor(severity),
  color: getTextColor(severity),
  borderRadius: '4px',
  padding: '3px 12px',
  fontSize: '12px',
  fontWeight: 500,
  marginLeft: '8px',
  maxHeight: '35px',
  cursor: 'pointer',
}))

const StyledImg = styled('img')(({ theme }) => ({
  width: '16px',
  height: '16px',
  objectFit: 'contain',
}))

const getBackgroundColor = (
  severity: 'error' | 'info' | 'success' | 'warning',
): string => {
  switch (severity) {
    case 'error':
      return '#f8d7da' // Red Tint
    case 'warning':
      return '#fff3cd' // Yellow Tint
    case 'info':
      return '#cce5ff' // Blue Tint
    case 'success':
      return '#d4edda' // Green Tint
    default:
      return 'transparent'
  }
}

const getTextColor = (
  severity: 'error' | 'info' | 'success' | 'warning',
): string => {
  switch (severity) {
    case 'error':
      return '#721c24' // Red Shade
    case 'warning':
      return '#856404' // Yellow Shade
    case 'info':
      return '#004085' // Blue Shade
    case 'success':
      return '#155724' // Green Shade
    default:
      return 'inherit'
  }
}
const getIcon = (
  severity: 'error' | 'info' | 'success' | 'warning',
): string => {
  switch (severity) {
    case 'error':
      return '/Icons/alert.svg'
    case 'warning':
      return 'path/to/warning-icon.svg'
    case 'info':
      return '/Icons/time.svg'
    case 'success':
      return 'path/to/success-icon.svg'
    default:
      return ''
  }
}

interface StatusBadgeProps {
  status: string
  severity: 'error' | 'info' | 'success' | 'warning'
  setOpenDialog?: (val: boolean) => void
}

const StatusBadge: React.FC<StatusBadgeProps> = ({
  status,
  severity,
  setOpenDialog,
}) => {
  return (
    <StyledBox
      severity={severity}
      onClick={() => {
        if (setOpenDialog) setOpenDialog(true)
      }}
    >
      <StyledImg src={getIcon(severity)} alt='Icon' />
      <Typography sx={{ flex: 1 }}>{status}</Typography>
    </StyledBox>
  )
}

export default StatusBadge
