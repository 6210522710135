import { Box, Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import React from 'react'
import Style from './index.module.scss'
import { PermissionsTable } from 'src/Pages/RolePermission/Permission/PermissionTableList'
import { useAppSelector } from 'src/Redux/hooks'
interface Permission {
  id: string
  name: string
  description: string
  scope: string
  roleName: []
}

const PermissionList = (props: {
  find: Function
  setSearchParam: Function
}) => {
  const { find, setSearchParam } = props

  const permissionList: Permission[] = useAppSelector(
    (state) => state.permissionManage?.permissionList?.data,
  )

  React.useEffect(() => {
    setSearchParam(['name', 'roleName', 'scope'])
  }, [])
  return (
    <>
      <Box className={Style.Main}>
        <Grid className={Style.ListWrapper}>
          <Grid className={Style.ListGrid}>
            <Box className={Style.Table}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={Style.TblHeading}>
                      PERMISSION
                    </TableCell>
                    <TableCell className={Style.TblHeading}>
                      ASSIGNED TO
                    </TableCell>
                    <TableCell className={Style.TblHeading}>SCOPE</TableCell>
                    <TableCell className={Style.TblHeading}>ACTION</TableCell>
                  </TableRow>
                  {permissionList?.length &&
                    find(permissionList)?.map(
                      (item: Permission, index: number) => {
                        return (
                          <TableRow key={index}>
                            <PermissionsTable {...item} />
                          </TableRow>
                        )
                      },
                    )}
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default PermissionList
