/* eslint-disable @typescript-eslint/no-unused-vars */
import { Middleware } from '@reduxjs/toolkit'
import {
  showNotification,
  NotificationType,
} from 'src/Redux/Slices/notificationSlice'
import { baseAPI } from './Common/httpCommon'
import { setLoading } from './Slices/loaderSlice'

// Define an array of action types that should trigger notifications
const allowedActionTypesMain: string[] = ['codeVerify', 'mailVerify']

const allowedActionTypes: string[] = allowedActionTypesMain.reduce(
  (acc: string[], action: string) => [
    ...acc,
    `${action}/fulfilled`,
    `${action}/rejected`,
    `${action}/pending`,
  ],
  [],
)
const axiosMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  async (action: any) => {
    // Check if the action type is in the allowedActionTypes array
    // if (allowedActionTypes.includes(action.type)) {
    if (action.type.endsWith('fulfilled')) {
      dispatch(setLoading(false))
    } else if (action.type.endsWith('rejected')) {
      dispatch(setLoading(false))
    } else if (action.type.endsWith('pending')) {
      dispatch(setLoading(true))
    }
    // }

    return next(action)
  }

export default axiosMiddleware
