import {
  Grid,
  Box,
  Typography,
  Tab,
  Tabs,
  styled,
  Stack,
  Menu,
  MenuItem,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { ReactNode, useEffect, useState } from 'react'
import MoreIcon from 'src/Assets/Icons/More.svg'
import MailIcon from 'src/Assets/Icons/mail.svg'
import PhoneIcon from 'src/Assets/Icons/phoneIcon.svg'
import LocationIcon from 'src/Assets/Icons/LocationIcon.svg'
import { CutString, DynamicObj, ORG_TAB_DATA } from 'src/Utils/Constants'
import styles from './index.module.scss'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import ProfilePhoto from 'src/Assets/Icons/ProfilePhoto.svg'
import { ActiveOrDeactivateOrg } from 'src/Redux/Slices/orgSlice'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
export const StyledTab = styled(Tab)({
  '&.MuiTab-root.Mui-selected': {
    fontWeight: 'bold',
    color: '#7752FE',
  },
})

const StyledLabel = (label: string) => {
  return <span className={styles.StyledLabel}>{label}</span>
}

interface ContactInfoProps {
  icon: string
  text: string | ReactNode
}

const ContactInfo: React.FC<ContactInfoProps> = ({ icon, text }) => (
  <Stack direction='row' spacing={1} alignItems='center'>
    <img src={icon} alt='' style={{ width: 20, height: 20 }} />
    <Typography
      variant='body2'
      sx={{
        whiteSpace: { xs: 'initial', md: 'nowrap' },
      }}
    >
      {text}
    </Typography>
  </Stack>
)

interface BasicHeaderProps {
  setTabId: (id: number) => void
  setSelectedTabName: (lable: string) => void
}
function TabPanel(props: {
  children: ReactNode
  value: string | number | unknown
  index: number
}) {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}
const BasicHeader: React.FC<BasicHeaderProps> = (props) => {
  const { selectedOrganization } = useAppSelector((state) => state.organization)
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(
    selectedOrganization?.bussinessProfileUrl || null,
  )

  const tabData = Object.values(ORG_TAB_DATA).map((label) => ({ label }))

  const dispatch = useAppDispatch()
  const [value, setValue] = React.useState(0)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const ActionOpen = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: { stopPropagation: () => void }) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    props.setTabId(newValue)
    props.setSelectedTabName(tabData[newValue].label)
  }
  useEffect(() => {
    if (
      !selectedOrganization?.bussinessProfileUrl ||
      selectedOrganization?.bussinessProfileUrl?.length === 0
    ) {
      setPreviewUrl(null)
    } else {
      setPreviewUrl(selectedOrganization.bussinessProfileUrl)
    }
  }, [selectedOrganization.bussinessProfileUrl])

  function renderContactInfoPart(part: string | null) {
    return part ? <>{part}, </> : null
  }

  function renderContactInfo(selectedOrganization: DynamicObj) {
    const {
      bussinessStreetAddress,
      bussinessCity,
      bussinessState,
      bussinessPostalCode,
    } = selectedOrganization

    return (
      <>
        {renderContactInfoPart(bussinessStreetAddress)}
        {renderContactInfoPart(bussinessCity)}
        {renderContactInfoPart(bussinessState)}
        {renderContactInfoPart(bussinessPostalCode)}
      </>
    )
  }

  return (
    <>
      {selectedOrganization && (
        <Grid minHeight={'100%'} pb={0} sx={{ position: 'sticky', top: 0 }}>
          <Box
            sx={{
              display: 'flex',
              gap: 0,
              flexWrap: { xs: 'wrap', md: 'nowrap' },
              padding: 3,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 2.5,
                flex: 1,
                flexWrap: { xs: 'wrap', md: 'nowrap' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 160,
                  height: 160,
                  padding: '0 35px',
                  backgroundColor: '#f9f9f9',
                  borderRadius: 2,
                  '@media (max-width: 991px)': { padding: '0 20px' },
                }}
              >
                <img
                  loading='lazy'
                  src={previewUrl ?? ProfilePhoto}
                  alt='Profile'
                  style={{
                    width: 160,
                    aspectRatio: 1,
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  margin: 'auto 0',
                  padding: '0 20px',
                  fontSize: 14,
                  '@media (max-width: 991px)': { maxWidth: '100%' },
                }}
              >
                <Typography
                  variant='h5'
                  sx={{
                    color: '#190482',
                    fontWeight: 600,
                    lineHeight: '133%',

                    '@media (max-width: 991px)': { maxWidth: '100%' },
                  }}
                >
                  {selectedOrganization?.bussinessName}
                </Typography>
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  spacing={2.5}
                  alignItems={{ xs: 'flex-start', md: 'center' }}
                  sx={{
                    marginTop: 1.25,
                    color: '#4B5675',
                    fontWeight: 500,
                    lineHeight: '143%',
                    '@media (max-width: 991px)': { flexWrap: 'wrap' },
                  }}
                >
                  <ContactInfo
                    key={'index'}
                    icon={LocationIcon}
                    text={renderContactInfo(selectedOrganization)}
                  />
                  <ContactInfo
                    key={'index'}
                    icon={PhoneIcon}
                    text={selectedOrganization?.bussinessPhoneNumber}
                  />
                  <ContactInfo
                    key={'index'}
                    icon={MailIcon}
                    text={selectedOrganization?.bussinessEmailId}
                  />
                </Stack>
                <Typography
                  variant='body1'
                  sx={{
                    color: '#4B5675',

                    fontWeight: 400,
                    lineHeight: '20px',
                    marginTop: 1.25,
                    '@media (max-width: 991px)': { maxWidth: '100%' },
                  }}
                >
                  {CutString(
                    selectedOrganization?.bussinessDescription ?? '',
                    500,
                  )}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'flex-start',
                padding: 0.5,
              }}
            >
              <img
                src={MoreIcon}
                alt='threeDot'
                style={{ marginRight: '4px', cursor: 'pointer' }}
                aria-controls={ActionOpen ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={ActionOpen ? 'true' : undefined}
                onClick={(e) => {
                  handleClick(
                    e as unknown as React.MouseEvent<
                      HTMLButtonElement,
                      MouseEvent
                    >,
                  )
                }}
              />
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={ActionOpen}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation()
                    dispatch(
                      ActiveOrDeactivateOrg({
                        uuid: selectedOrganization.uuid,
                        isActive: !selectedOrganization.isActive,
                      }),
                    )
                  }}
                >
                  {selectedOrganization.isActive ? 'Deactive' : 'Active'}
                </MenuItem>
              </Menu>
            </Box>
          </Box>

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='data-driven tabs'
            variant='scrollable'
            scrollButtons='auto'
            className='sticky'
          >
            {tabData.map((tab, index) => (
              <StyledTab
                key={index}
                label={StyledLabel(tab.label)}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Grid>
      )}
    </>
  )
}

export default BasicHeader
