import React from 'react'
import { Box, Typography, Button, Grid } from '@mui/material'
import Styles from './index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import { DynamicObj, hasEmpty } from 'src/Utils/Constants'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'

interface ViewScreenProps {
  onEditClick: () => void
  ref: React.RefObject<HTMLDivElement>
}

const LegalName: React.FC<ViewScreenProps> = ({
  onEditClick,
  ref: legalRef,
}) => {
  const data = useAppSelector(
    (state: RootState) => state?.applicationStore?.LegalDetails?.LegalName,
  )
  function renderContactInfoPart(
    part: string | null | undefined,
    separator: string = ' ',
  ): string | null {
    return part ? `${part}${separator}` : ' ' // Concatenate the part with the separator
  }

  function renderContactInfo(data: DynamicObj) {
    const { firstName, middleName, lastName } = data

    const fullName = `${renderContactInfoPart(firstName)}${renderContactInfoPart(middleName)}${renderContactInfoPart(lastName)}`

    return fullName
  }
  const legalNamesSectionId = useAppSelector(
    (state) =>
      state?.steps?.LegalDocApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Legal Names',
      )?.sectionId,
  )

  return (
    <div id={legalNamesSectionId} ref={legalRef}>
      {/* Legal Names */}
      <Box className={Styles.MainBox}>
        <Box className={Styles.title}>
          <Box className={Styles.main}>
            <Typography variant='h6' className={Styles.textWrapper}>
              Legal Names
            </Typography>
            <Button
              onClick={onEditClick}
              disabled={hasEmpty(data)}
              className={Styles.button}
            >
              <Typography className={Styles.div}>Edit</Typography>
            </Button>
          </Box>
          <div className={Styles.dottedLine} />
        </Box>
        {!hasEmpty(data) ? (
          <>
            <Box className={Styles.div2}>
              <Box className={Styles.element}>
                <Box className={Styles.question}>
                  <Typography className={Styles.p}>
                    Used any other names
                  </Typography>
                </Box>
                <Box className={Styles.ans}>
                  <Typography className={Styles.textWrapperAns}>
                    {data?.haveUsedOtherName ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {data?.legalNames?.length && data?.haveUsedOtherName ? (
              <>
                <Box className={Styles.div2}>
                  <Typography variant='h6' className={Styles.SubTitle}>
                    Provided Names
                  </Typography>
                </Box>
                <Grid item gap={2} display={'flex'}>
                  {data?.legalNames?.map(
                    (item: {
                      firstName: string
                      middleName: string
                      lastName: string
                    }) => (
                      <>
                        <Box className={Styles.cardDivBox}>
                          <Grid container className={Styles.cardFrame}>
                            <Grid item xs={6} className={Styles.cardElement}>
                              <Box className={Styles.cardQuestion}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapper}
                                >
                                  Name
                                </Typography>
                              </Box>
                              <Box className={Styles.cardAns}>
                                <Typography
                                  variant='body1'
                                  className={Styles.cardTextWrapperAns}
                                >
                                  {renderContactInfo(item)}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    ),
                  )}
                </Grid>
              </>
            ) : null}
          </>
        ) : (
          <CustomAlert message='no data available.' />
        )}
      </Box>
    </div>
  )
}

export default LegalName
