import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import AppCancel from 'src/Assets/Icons/AppCancel.svg'
import * as Comps from 'src/Components/Application/CustomApplication'
import Disqualification from 'src/Components/Disqualification'
import { resetApplicationIds } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { resetBasicDetails } from 'src/Redux/Slices/ApplicationStore/BasicDetailsStore'
import { resetDisabilityDetails } from 'src/Redux/Slices/ApplicationStore/DisabilityDetailsStore'
import { resetEducationAndEmployement } from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import { resetFamilyDetails } from 'src/Redux/Slices/ApplicationStore/FamilyDetailsStore'
import { resetLegalDetails } from 'src/Redux/Slices/ApplicationStore/LegalDetailsStore'
import { resetMedicalDetails } from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ROUTES } from 'src/Utils/Routes'
import ClearApplication from './ClearApplication'
import styles from './index.module.scss'
import ChatPopup from 'src/Components/ChatBot'
import { resetApplicationStep } from 'src/Redux/Slices/ApplicationStore/ApplicationStepStore'

const ApplicationCommon: React.FunctionComponent = () => {
  const {
    SideSteps,
    BasicDetails,
    FamilyDetails,
    LegalDetails,
    DisabilityDetails,
    EducationDetails,
    MedicalDetails,
    UploadDocument,
    SignConsentForm,
    Submit,
  } = Comps
  const location = useLocation()
  const [active, setActive] = useState<string>(location.pathname)
  const [clear, setClear] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const isPublic = useAppSelector(
    (state) => state.applicationStore.ApplicationId.isPublic,
  )
  // const currentPath = location.pathname;
  const disqualification = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.disqualification,
  )

  useEffect(() => {
    setActive(location.pathname)
  }, [location])
  const isDisqualified = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.isDisqualified,
  )
  const [openDialog, setOpenDialog] = useState(false)
  const [chatForm, setChatForm] = useState(false)

  const handleOpen = () => {
    setOpenDialog(true)
  }
  useEffect(() => {
    setOpenDialog(isDisqualified)
  }, [disqualification])

  enum APPLICATIONROUTES {
    BASIC_DETAILS = '/basic-details',
    FAMILY_DETAILS = '/family-details',
    LEGAL_DETAILS = '/legal-details',
    DISABILITY_DETAILS = '/disability-details',
    EDUCATION_DETAILS = '/education-details',
    MEDICAL_DETAILS = '/medical-details',
    UPLOAD_DOCUMENT = '/upload-document',
    SIGN_CONSENT_FORM = '/sign-consent-forms',
    SUBMIT = '/final-submit',
    APPLICATIONS = '/applications',
  }

  const handleSubmit = () => {
    dispatch(resetBasicDetails())
    dispatch(resetFamilyDetails())
    dispatch(resetApplicationIds())
    dispatch(resetLegalDetails())
    dispatch(resetDisabilityDetails())
    dispatch(resetMedicalDetails())
    dispatch(resetEducationAndEmployement())
    dispatch(resetApplicationStep())
    if (isPublic) {
      secureLocalStorage.removeItem('token')
      navigate(ROUTES.DEFAULT_PATH)
    } else navigate(ROUTES.DASHBOARD)
    // localStorage.removeItem('BasicDetails')
    // localStorage.removeItem('FamilyDetails')
    // localStorage.removeItem('LegalDetails')
    // localStorage.removeItem('EducationAndEmployement')
    // localStorage.removeItem('DisabilityDetails')
    // localStorage.removeItem('MedicalDetails')
    // localStorage.removeItem('SignConsentForms')
  }
  return (
    <Grid container className={styles.Application}>
      <ChatPopup showOptions setChatForm={setChatForm} />
      <Box className={styles.Sidebar}>
        <SideSteps active={active} />
      </Box>
      <Box className={styles.container2}>
        <Box className={styles.background}>
          <Box className={styles.main2}>
            <Box
              className={styles.cancelButton}
              onClick={() => {
                setClear(true)
              }}
            >
              <img src={AppCancel} alt='' />
            </Box>
            {clear && (
              <ClearApplication
                handleSubmit={handleSubmit}
                openDialog={clear}
                setOpenDialog={setClear}
              />
            )}
            <Routes>
              <Route
                path={APPLICATIONROUTES.BASIC_DETAILS}
                element={
                  <BasicDetails chatForm={chatForm} setChatForm={setChatForm} />
                }
              />
              <Route
                path={APPLICATIONROUTES.FAMILY_DETAILS}
                element={
                  <FamilyDetails
                    chatForm={chatForm}
                    setChatForm={setChatForm}
                  />
                }
              />
              <Route
                path={APPLICATIONROUTES.LEGAL_DETAILS}
                element={
                  <LegalDetails chatForm={chatForm} setChatForm={setChatForm} />
                }
              />
              <Route
                path={APPLICATIONROUTES.DISABILITY_DETAILS}
                element={
                  <DisabilityDetails
                    chatForm={chatForm}
                    setChatForm={setChatForm}
                  />
                }
              />
              <Route
                path={APPLICATIONROUTES.EDUCATION_DETAILS}
                element={
                  <EducationDetails
                    chatForm={chatForm}
                    setChatForm={setChatForm}
                  />
                }
              />
              <Route
                path={APPLICATIONROUTES.MEDICAL_DETAILS}
                element={
                  <MedicalDetails
                    chatForm={chatForm}
                    setChatForm={setChatForm}
                  />
                }
              />
              <Route
                path={APPLICATIONROUTES.UPLOAD_DOCUMENT}
                element={<UploadDocument />}
              />
              <Route
                path={APPLICATIONROUTES.SIGN_CONSENT_FORM}
                element={<SignConsentForm />}
              />
              <Route path={APPLICATIONROUTES.SUBMIT} element={<Submit />} />
            </Routes>
          </Box>
        </Box>
      </Box>
      {openDialog && (
        <Disqualification
          open={openDialog}
          setOpen={setOpenDialog}
          handleOpen={handleOpen}
        />
      )}
    </Grid>
  )
}

export default ApplicationCommon
