import CurrenySignificant from './CurrentSignificant'
import PastSignificant from './PastSignificant'
import Dependents from './Dependent'
import Parents from './Parents'

const FamilyContent = () => {
  return (
    <>
      {/* =================================Current Significant Other ======================================== */}
      <CurrenySignificant />
      {/* ====================== Past Significant Other(s) start ================= */}
      <PastSignificant />
      {/* =================== Dependents starts ==================================== */}
      <Dependents />
      {/* =========================== Parents starts ============================== */}
      <Parents />
      {/* ==========================Parents over ============================= */}
    </>
  )
}
export default FamilyContent
