import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider, styled } from '@mui/material'
import Styles from '../index.module.scss'

import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import SelfEmployement from '../EditForms/SelfEmployement'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import {
  currentYear,
  hasEmpty,
  nextYear,
  previousYear,
} from 'src/Utils/Constants'
import Tooltip from '@mui/material/Tooltip'
import error from 'src/Assets/Icons/error.svg'

const EducationDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)

  const ErrorImage = styled('img')({
    marginRight: '8px', // Adjust margin as needed
    marginBottom: '-8px', // Adjust margin as needed
    paddingRight: '10px',
    paddingLeft: '10px',
    // position: 'absolute',
  })
  const flagData = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.EducationAndEmployement?.educationFlagData
        ?.SelfEmployement,
  )
  const selfEmploymentRef = useRef<HTMLDivElement>(null)
  const handleEditToggle = () => {
    setEditMode(!editMode)
    selfEmploymentRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.EducationAndEmployement?.SelfEmployement,
  )

  const selfEmploymentSectionId = useAppSelector(
    (state) =>
      state?.steps?.EducationEmploymentApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Self-Employment',
      )?.sectionId,
  )

  return (
    <div id={selfEmploymentSectionId} ref={selfEmploymentRef}>
      {!editMode ? (
        <>
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  Self-Employment
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>
            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Self-employed in the last 15 years
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography
                        className={
                          flagData?.isSelfEmployed?.length > 0
                            ? Styles.flagTextWrapper2
                            : Styles.textWrapper2
                        }
                      >
                        {data?.isSelfEmployed ? 'Yes' : 'No'}
                        {flagData?.isSelfEmployed?.length > 0 ? (
                          <>
                            <Tooltip title={flagData?.isSelfEmployed}>
                              <ErrorImage src={error} alt='Error' />
                            </Tooltip>
                          </>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>
                  {data?.isSelfEmployed ? (
                    <>
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Self-employed during {previousYear}
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography
                            className={
                              flagData?.isSelfEmployedDuringLastYear?.length > 0
                                ? Styles.flagTextWrapper2
                                : Styles.textWrapper2
                            }
                          >
                            {data?.isSelfEmployedDuringLastYear ? 'Yes' : 'No'}
                            {flagData?.isSelfEmployedDuringLastYear?.length >
                            0 ? (
                              <>
                                <Tooltip
                                  title={flagData?.isSelfEmployedDuringLastYear}
                                >
                                  <ErrorImage src={error} alt='Error' />
                                </Tooltip>
                              </>
                            ) : null}
                          </Typography>
                        </Box>
                      </Box>

                      {data?.isSelfEmployedDuringLastYear ? (
                        <>
                          <Box className={Styles.cardDiv}>
                            {/* row-1 */}
                            <Grid container className={Styles.cardFrame}>
                              <Grid item xs={6} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Type of business
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={
                                      flagData?.typeOfLastYearBusiness?.length >
                                      0
                                        ? Styles.flagCardTextWrapper3
                                        : Styles.cardTextWrapper3
                                    }
                                  >
                                    {data?.typeOfLastYearBusiness}
                                    {flagData?.typeOfLastYearBusiness?.length >
                                    0 ? (
                                      <>
                                        <Tooltip
                                          title={
                                            flagData?.typeOfLastYearBusiness
                                          }
                                        >
                                          <ErrorImage src={error} alt='Error' />
                                        </Tooltip>
                                      </>
                                    ) : null}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                            {/* row-2 */}
                            <Grid container className={Styles.cardFrame}>
                              <Grid item xs={6} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Profit more than $400 from the self-employed
                                    job in {previousYear}
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={
                                      flagData?.profitMoreAmountLastYear
                                        ?.length > 0
                                        ? Styles.flagCardTextWrapper3
                                        : Styles.cardTextWrapper3
                                    }
                                  >
                                    {data?.profitMoreAmountLastYear
                                      ? 'Yes'
                                      : 'No'}
                                    {flagData?.profitMoreAmountLastYear
                                      ?.length > 0 ? (
                                      <>
                                        <Tooltip
                                          title={
                                            flagData?.profitMoreAmountLastYear
                                          }
                                        >
                                          <ErrorImage src={error} alt='Error' />
                                        </Tooltip>
                                      </>
                                    ) : null}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={6} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Total of all wages and tips earned during
                                    {previousYear}
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={
                                      flagData?.totalEarnedAmountLastYear
                                        ?.length > 0
                                        ? Styles.flagCardTextWrapper3
                                        : Styles.cardTextWrapper3
                                    }
                                  >
                                    {`$${data?.totalEarnedAmountLastYear}`}
                                    {flagData?.totalEarnedAmountLastYear
                                      ?.length > 0 ? (
                                      <>
                                        <Tooltip
                                          title={
                                            flagData?.totalEarnedAmountLastYear
                                          }
                                        >
                                          <ErrorImage src={error} alt='Error' />
                                        </Tooltip>
                                      </>
                                    ) : null}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      ) : null}

                      <Box className={Styles.div2}>
                        <Box className={Styles.element}>
                          <Box className={Styles.question}>
                            <Typography className={Styles.p}>
                              Self-employed during {currentYear}
                            </Typography>
                          </Box>
                          <Box className={Styles.ans}>
                            <Typography
                              className={
                                flagData?.isSelfEmployedDuringCurrentYear
                                  ?.length > 0
                                  ? Styles.flagTextWrapper2
                                  : Styles.textWrapper2
                              }
                            >
                              {data?.isSelfEmployedDuringCurrentYear
                                ? 'Yes'
                                : 'No'}
                              {flagData?.isSelfEmployedDuringCurrentYear
                                ?.length > 0 ? (
                                <>
                                  <Tooltip
                                    title={
                                      flagData?.isSelfEmployedDuringCurrentYear
                                    }
                                  >
                                    <ErrorImage src={error} alt='Error' />
                                  </Tooltip>
                                </>
                              ) : null}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      {data?.isSelfEmployedDuringCurrentYear ? (
                        <>
                          <Box className={Styles.cardDiv}>
                            {/* row-1 */}
                            <Grid container className={Styles.cardFrame}>
                              <Grid item xs={6} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Type of business
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={
                                      flagData?.typeOfCurrentYearBusiness
                                        ?.length > 0
                                        ? Styles.flagCardTextWrapper3
                                        : Styles.cardTextWrapper3
                                    }
                                  >
                                    {data?.typeOfCurrentYearBusiness}
                                    {flagData?.typeOfCurrentYearBusiness
                                      ?.length > 0 ? (
                                      <>
                                        <Tooltip
                                          title={
                                            flagData?.typeOfCurrentYearBusiness
                                          }
                                        >
                                          <ErrorImage src={error} alt='Error' />
                                        </Tooltip>
                                      </>
                                    ) : null}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                            {/* row-2 */}
                            <Grid container className={Styles.cardFrame}>
                              <Grid item xs={6} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Profit more than $400 from the self-employed
                                    job in {currentYear}
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={
                                      flagData?.profitMoreAmountCurrentYear
                                        ?.length > 0
                                        ? Styles.flagCardTextWrapper3
                                        : Styles.cardTextWrapper3
                                    }
                                  >
                                    {data?.profitMoreAmountCurrentYear
                                      ? 'Yes'
                                      : 'No'}
                                    {flagData?.profitMoreAmountCurrentYear
                                      ?.length > 0 ? (
                                      <>
                                        <Tooltip
                                          title={
                                            flagData?.profitMoreAmountCurrentYear
                                          }
                                        >
                                          <ErrorImage src={error} alt='Error' />
                                        </Tooltip>
                                      </>
                                    ) : null}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={6} className={Styles.cardElement}>
                                <Box className={Styles.cardQuestion}>
                                  <Typography
                                    variant='body1'
                                    className={Styles.cardTextWrapper}
                                  >
                                    Total of all wages and tips earned during
                                    {currentYear}
                                  </Typography>
                                </Box>
                                <Box className={Styles.cardAns}>
                                  <Typography
                                    variant='body1'
                                    className={
                                      flagData?.totalEarnedAmountCurrentYear
                                        ?.length > 0
                                        ? Styles.flagCardTextWrapper3
                                        : Styles.cardTextWrapper3
                                    }
                                  >
                                    {`$${data?.totalEarnedAmountCurrentYear}`}
                                    {flagData?.totalEarnedAmountCurrentYear
                                      ?.length > 0 ? (
                                      <>
                                        <Tooltip
                                          title={
                                            flagData?.totalEarnedAmountCurrentYear
                                          }
                                        >
                                          <ErrorImage src={error} alt='Error' />
                                        </Tooltip>
                                      </>
                                    ) : null}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </Box>

                <>
                  <Box className={Styles.div2}>
                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.p}>
                          Plan to be Self-employed in {nextYear}
                        </Typography>
                      </Box>
                      <Box className={Styles.ans}>
                        <Typography
                          className={
                            flagData?.isSelfEmployedNextYear?.length > 0
                              ? Styles.flagTextWrapper2
                              : Styles.textWrapper2
                          }
                        >
                          {data?.isSelfEmployedNextYear ? 'Yes' : 'No'}
                          {flagData?.isSelfEmployedNextYear?.length > 0 ? (
                            <>
                              <Tooltip title={flagData?.isSelfEmployedNextYear}>
                                <ErrorImage src={error} alt='Error' />
                              </Tooltip>
                            </>
                          ) : null}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>

                {data?.isSelfEmployedNextYear ? (
                  <>
                    <Box className={Styles.cardDiv}>
                      {/* row-1 */}
                      <Grid container className={Styles.cardFrame}>
                        <Grid item xs={6} className={Styles.cardElement}>
                          <Box className={Styles.cardQuestion}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper}
                            >
                              Type of business
                            </Typography>
                          </Box>
                          <Box className={Styles.cardAns}>
                            <Typography
                              variant='body1'
                              className={
                                flagData?.typeOfNextYearBusiness?.length > 0
                                  ? Styles.flagCardTextWrapper3
                                  : Styles.cardTextWrapper3
                              }
                            >
                              {data?.typeOfNextYearBusiness}
                              {flagData?.typeOfNextYearBusiness?.length > 0 ? (
                                <>
                                  <Tooltip
                                    title={flagData?.typeOfNextYearBusiness}
                                  >
                                    <ErrorImage src={error} alt='Error' />
                                  </Tooltip>
                                </>
                              ) : null}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      {/* row-2 */}
                      <Grid container className={Styles.cardFrame}>
                        <Grid item xs={6} className={Styles.cardElement}>
                          <Box className={Styles.cardQuestion}>
                            <Typography
                              variant='body1'
                              className={Styles.cardTextWrapper}
                            >
                              Profit more than $400 from the self-employed job
                              in {nextYear}
                            </Typography>
                          </Box>
                          <Box className={Styles.cardAns}>
                            <Typography
                              variant='body1'
                              className={
                                flagData?.profitMoreAmountNextYear?.length > 0
                                  ? Styles.flagCardTextWrapper3
                                  : Styles.cardTextWrapper3
                              }
                            >
                              {data?.profitMoreAmountNextYear ? 'Yes' : 'No'}
                              {flagData?.profitMoreAmountNextYear?.length >
                              0 ? (
                                <>
                                  <Tooltip
                                    title={flagData?.profitMoreAmountNextYear}
                                  >
                                    <ErrorImage src={error} alt='Error' />
                                  </Tooltip>
                                </>
                              ) : null}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : null}
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Self Employement
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <SelfEmployement handleEditToggle={handleEditToggle} />{' '}
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default EducationDetails
