import { Box, Typography } from '@mui/material'
import React from 'react'
import { To, useNavigate } from 'react-router-dom'
import IconChevronRight from 'src/Assets/Icons/IconChevronRight.svg'
import styles from './index.module.scss'
import { ROUTES } from 'src/Utils/Routes'

const Breadcrumb: React.FunctionComponent<{
  home?: {
    route: To
    name: string
  }
  Menus: Array<{
    route: To
    name: string
  }>
  title: string
}> = ({ home, Menus, title }) => {
  const navigate = useNavigate()
  return (
    <>
      <Box className={styles.head}>
        <Box>
          <Typography className={styles.HeadText}>{title}</Typography>
          <Box display='flex'>
            <Typography
              onClick={() => {
                navigate(home?.route ?? ROUTES.DASHBOARD)
              }}
              className={styles.SubText}
            >
              {home?.name ?? 'Home'}
            </Typography>
            {Menus?.map((item: { route: To; name: string }) => {
              return (
                <>
                  <img src={IconChevronRight} alt='IconChevronRight' />
                  <Typography
                    onClick={() => {
                      navigate(item.route)
                    }}
                    className={styles.SubText}
                  >
                    {item.name}
                  </Typography>
                </>
              )
            })}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Breadcrumb
