// This slice manages the state for the sign in feature
import {
  PayloadAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import NotificationService from '../Services/NotificationService'
import { thunkAPItype } from '../store'
import { handleResponse } from 'src/Utils/Constants'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: {
    notificationList: {}
    employeeList: {}
  }
}
const initialState: stateType = {
  success: false,
  loading: false,
  data: {
    notificationList: {},
    employeeList: {},
  },
}

// Define the reducers that can modify the state
const notificationListSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ViewNotificationList.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewNotificationList.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data.notificationList = action.payload?.data
      },
    )
    builder.addCase(ViewNotificationList.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(ViewEmployeeList.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewEmployeeList.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data.employeeList = action.payload?.data
      },
    )
    builder.addCase(ViewEmployeeList.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

export const ViewNotificationList = createAsyncThunk(
  'notificationList', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await NotificationService.notificationList(token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const ViewEmployeeList = createAsyncThunk(
  'EmployeeList', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const token = thunkAPI.getState().auth.data?.token
      const response = await NotificationService.employeeList(token) // make the API call
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const SendNotification = createAsyncThunk(
  'SendNotification', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const token = thunkAPI.getState().auth.data?.token
      const response = await NotificationService.SendNotification(data, token) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default notificationListSlice.reducer
