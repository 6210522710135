import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider, CssBaseline, StyledEngineProvider } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import theme from './Theme'
import Router from './Components/Router/index'
import { Provider } from 'react-redux'
import { store, persistor } from 'src/Redux/store'
import { PersistGate } from 'redux-persist/integration/react'
const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <Suspense>
        <Provider store={store}>
          <BrowserRouter>
            <PersistGate persistor={persistor} loading={null}>
              <Router />
            </PersistGate>
          </BrowserRouter>
        </Provider>
      </Suspense>
    </StyledEngineProvider>
    {}
  </ThemeProvider>,
  // </React.StrictMode>,
)
