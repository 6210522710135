import { configureStore } from '@reduxjs/toolkit'
import { UnknownAction, combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { ThunkDispatch, thunk } from 'redux-thunk'
import ApplicationIdStore from './Slices/ApplicationStore/ApplicationIdStore'
import BasicDetailsStore from './Slices/ApplicationStore/BasicDetailsStore'
import DisabilityDetailsStore from './Slices/ApplicationStore/DisabilityDetailsStore'
import EducationAndEmployementStore from './Slices/ApplicationStore/EducationAndEmployement'
import FamilyDetailsStore from './Slices/ApplicationStore/FamilyDetailsStore'
import LegalDetailsStore from './Slices/ApplicationStore/LegalDetailsStore'
import authReducer from './Slices/authSlice'
import employeeSlice from './Slices/employeeSlice'
import loadingReducer from './Slices/loaderSlice'
import mapReducer from './Slices/mapSlice'
import notificationReducer from './Slices/notificationSlice'
import OrganizationReducer from './Slices/orgSlice'
import permissionDetailsSlice from './Slices/permissionDetail'
import permissionManageSlice from './Slices/permissionManageSlice'
import permissionReducer from './Slices/permissionSlice'
import profileSlice from './Slices/profileSlice'
import roleDetailsSlice from './Slices/roleDetails'
import RolePermissionSlice from './Slices/rolePermission'
import rolePermissionDetails from './Slices/rolePermissionDetails'
import roleSlice from './Slices/roleSlice'
import subscriptionSlice from './Slices/subscriptionSlice'
import axiosMiddleware from './middleware'
import AllApplicationSlice from './Slices/ApplicationSlices/allApplicationSlice'
import MedicalDetailsStore from './Slices/ApplicationStore/MedicalDetailsStore'
import DashboardSlice from './Slices/DashboardSlice'
import stepsReducer from './Slices/stepSlice'
import notificationList from './Slices/notificationListSlice'
import ApplicationStepStore from './Slices/ApplicationStore/ApplicationStepStore'

const roleReducer = combineReducers({
  roleList: roleSlice,
  roleDetails: roleDetailsSlice,
  RolePermissionList: RolePermissionSlice,
  RolePermissionDetails: rolePermissionDetails,
})

const permission = combineReducers({
  permissionList: permissionManageSlice,
  permissionDetails: permissionDetailsSlice,
})

const applicationStore = combineReducers({
  AllApplications: AllApplicationSlice,
  BasicDetails: BasicDetailsStore,
  FamilyDetails: FamilyDetailsStore,
  LegalDetails: LegalDetailsStore,
  DisabilityDetails: DisabilityDetailsStore,
  EducationAndEmployement: EducationAndEmployementStore,
  MedicalDetails: MedicalDetailsStore,
  ApplicationId: ApplicationIdStore,
  ApplicationStep: ApplicationStepStore,
})

const mainReducer = combineReducers({
  dashboard: DashboardSlice,
  applicationStore,
  country: mapReducer,
  auth: authReducer,
  permissions: permissionReducer,
  permissionManage: permission,
  profile: profileSlice,
  roles: roleReducer,
  subscription: subscriptionSlice,
  notification: notificationReducer,
  notificationList,
  loading: loadingReducer,
  organization: OrganizationReducer,
  employee: employeeSlice,
  steps: stepsReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['someReducer'], // Add the reducers you want to persist here
}

const Persistor: any = persistReducer(persistConfig, mainReducer)
const middleWare = [thunk, axiosMiddleware]

export const store = configureStore({
  reducer: Persistor,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(...middleWare),
})

// is that enables seamless persistence of your Redux store state to local storage, ensuring that the state is preserved even after a page refresh or when the application is reopened. It works by serializing the Redux store state and storing it in the chosen storage engine, such as localStorage or AsyncStorage.
export const persistor = persistStore(store)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export interface thunkAPItype {
  getState: () => RootState
  dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
}
