import { baseAPI } from '../Common/httpCommon'
import {
  AdditionalInformationType,
  GeneralHealthType,
  HospitalAndMedicalFacilityCareType,
  IllnessesInjuriesAndConditionsType,
  MedicalProfessionalDetailType,
  MedicalTestType,
  MedicineType,
  VisionAndHearingType,
} from './Types/applicationType'

const AddGeneralHealth = async (
  data: GeneralHealthType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.post('application/medical/general-health', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditGeneralHealth = async (
  data: GeneralHealthType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/medical/general-health', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddVisionAndHearing = async (
  data: VisionAndHearingType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/medical/vision-and-hearing', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddIllnessesInjuriesAndConditions = async (
  data: IllnessesInjuriesAndConditionsType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put(
      'application/medical/illnesses-injuries-and-conditions',
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'is-public': isPublic,
          'Content-Type': 'application/json',
        },
      },
    )
  } catch (e: any) {
    return e
  }
}

const AddMedicalProfessionalDetail = async (
  data: MedicalProfessionalDetailType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put(
      'application/medical/medical-professionals',
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'is-public': isPublic,
          'Content-Type': 'application/json',
        },
      },
    )
  } catch (e: any) {
    return e
  }
}

const AddHospitalAndMedicalFacilityCare = async (
  data: HospitalAndMedicalFacilityCareType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put(
      'application/medical/hospital-medical-facility-care',
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'is-public': isPublic,
          'Content-Type': 'application/json',
        },
      },
    )
  } catch (e: any) {
    return e
  }
}

const addMedicalTest = async (
  data: MedicalTestType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/medical/medical-test', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddMedicine = async (
  data: MedicineType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/medical/medicines', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AdditionalInformation = async (
  data: AdditionalInformationType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put(
      'application/medical/additional-information',
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'is-public': isPublic,
          'Content-Type': 'application/json',
        },
      },
    )
  } catch (e: any) {
    return e
  }
}

const MedicalDetailsService = {
  AddGeneralHealth,
  EditGeneralHealth,
  AddVisionAndHearing,
  AddIllnessesInjuriesAndConditions,
  AddMedicalProfessionalDetail,
  AddHospitalAndMedicalFacilityCare,
  addMedicalTest,
  AddMedicine,
  AdditionalInformation,
}
export default MedicalDetailsService
