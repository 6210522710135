import { baseAPI } from '../Common/httpCommon'
import { type ProfileData } from './Types/ProfileType'

const viewProfile = async (token: string) => {
  try {
    return await baseAPI.get<ProfileData>('profile/view', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const editProfile = async (data: FormData, token: string) => {
  try {
    return await baseAPI.put<ProfileData>('profile/edit', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e: any) {
    return e
  }
}

const editProfileOrganization = async (data: FormData, token: string) => {
  try {
    return await baseAPI.put<ProfileData>(
      'profile/edit-profile-organization',
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )
  } catch (e: any) {
    return e
  }
}

const ResetPasswordIsTemp = async (data: FormData, token: string) => {
  try {
    return await baseAPI.put<ProfileData>('profile/reset-password', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const ProfileService = {
  viewProfile,
  editProfile,
  editProfileOrganization,
  ResetPasswordIsTemp,
}
export default ProfileService
