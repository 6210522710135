import { baseAPI } from '../Common/httpCommon'
import { type SigninData } from './Types/SignInType'

const forgetPassword = async (data: any) => {
  try {
    return await baseAPI.post('auth/forgotPassword', data)
  } catch (e: any) {
    return e
  }
}

const verifyForgetLink = async (data: any) => {
  try {
    return await baseAPI.get('auth/verify?link=' + data.link)
  } catch (e: any) {
    return e
  }
}

const resetPassword = async (data: { link: string; password: string }) => {
  try {
    return await baseAPI.post('auth/resetPassword?link=' + data.link, {
      password: data.password,
    })
  } catch (e: any) {
    return e
  }
}

const signInRequest = async (data: any) => {
  try {
    return await baseAPI.post<SigninData>('auth/sign-in', data)
  } catch (e: any) {
    return e
  }
}

const signUpRequest = async (data: any) => {
  try {
    return await baseAPI.post<SigninData>('auth/sign-up', data)
  } catch (e: any) {
    return e
  }
}
const PaymentDetail = async (data: any) => {
  try {
    return await baseAPI.post<SigninData>('auth/billing', data)
  } catch (e: any) {
    return e
  }
}

const emailVerifyRequest = async (data: any) => {
  try {
    return await baseAPI.get<{ success: boolean; message: string }>(
      'auth/email-verification?emailId=' + data,
    )
  } catch (e: any) {
    return e
  }
}

const codeVerifyRequest = async (data: any) => {
  try {
    return await baseAPI.post<{ success: boolean; message: string }>(
      'auth/otp-verification',
      data,
    )
  } catch (e: any) {
    return e
  }
}

const organizationRequest = async (data: any) => {
  try {
    return await baseAPI.post<{ success: boolean; message: string }>(
      'auth/organization',
      data,
    )
  } catch (e: any) {
    return e
  }
}

const personalRequest = async (data: any) => {
  try {
    return await baseAPI.post<{ success: boolean; message: string }>(
      'auth/personal',
      data,
    )
  } catch (e: any) {
    return e
  }
}

const fetchSubscriptions = async () => {
  try {
    return await baseAPI.get<{ success: boolean; message: string }>(
      'auth/subscription',
    )
  } catch (e: any) {
    return e
  }
}

const createPayment = async (data: any) => {
  try {
    return await baseAPI.post<{
      success: boolean
      message: string
    }>('auth/billing-order', data)
  } catch (e: any) {
    return e
  }
}
const subscriptionRequest = async (data: any) => {
  try {
    return await baseAPI.post<{ success: boolean; message: string }>(
      'auth/subscription',
      data,
    )
  } catch (e: any) {
    return e
  }
}

const AuthService = {
  forgetPassword,
  PaymentDetail,
  verifyForgetLink,
  resetPassword,
  signInRequest,
  signUpRequest,
  emailVerifyRequest,
  codeVerifyRequest,
  organizationRequest,
  personalRequest,
  createPayment,
  fetchSubscriptions,
  subscriptionRequest,
}
export default AuthService
