import React from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import './index.scss'
import LegalNames from '../../EditForms/LegalDetails/LegalNames/index'
interface EditScreenProps {
  onSaveClick: () => void
}
const EditLegalName: React.FC<EditScreenProps> = ({ onSaveClick }) => {
  return (
    <>
      {/* Edit Legal Names */}
      <Grid m={2}>
        <Grid container m={2}>
          <Grid lg={10} className='alignItemCenter' display='flex'>
            <Typography variant='h1' className='FontBold'>
              Legal Names
            </Typography>
          </Grid>
          <Divider
            sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
          />
          <Grid container m={2} ml={0}>
            <>
              <LegalNames handleEditToggle={onSaveClick} />
            </>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default EditLegalName
