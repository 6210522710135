import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import quote from 'src/Assets/Icons/quots.svg'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { AddButton, ArraySelection, StarText } from 'src/Components/Fields'
import { DependentsType } from 'src/Redux/Services/Types/applicationType'
import { AddDependents } from 'src/Redux/Slices/ApplicationSlices/FamilyDetailsSlice'
import { setDependents } from 'src/Redux/Slices/ApplicationStore/FamilyDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../index.module.scss'
import ProfileBox from './ProfileBox'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'

const Dependents = ({
  handleNext,
  handleBack,
  chatForm,
}: {
  chatForm: any
  handleBack: () => void
  handleNext: () => void
}) => {
  const dispatch = useAppDispatch()

  const storeValues = useAppSelector(
    (state) => state?.applicationStore?.FamilyDetails?.Dependents,
  )
  const familyId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.familyId,
  )

  const [formValues, setFormValues] = useState<DependentsType>({
    familyId,
    haveAnyChildern: undefined,
    numberOfChildern: '',
    dependents: [
      {
        firstName: '',
        lastName: '',
        isYoungerThan17: undefined,
        isChildMarried: undefined,
        isChildDisabled: undefined,
        isChild1819SchoolUnmarried: undefined,
        isChildDisabledBeforeAge22: undefined,
      },
    ],
  })

  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )

  useEffect(() => {
    setFormValues({
      ...storeValues,
      familyId,
      dependents: storeValues?.dependents?.map((dependent) => ({
        firstName: dependent?.firstName ?? '',
        lastName: dependent?.lastName ?? '',
        isYoungerThan17: dependent.isYoungerThan17 ?? undefined,
        isChildMarried: !dependent.isChild1819SchoolUnmarried ?? undefined,
        isChildDisabled: dependent.isChildDisabledBeforeAge22 ?? undefined,
        isChild1819SchoolUnmarried:
          dependent.isChild1819SchoolUnmarried ?? undefined,
        isChildDisabledBeforeAge22:
          dependent.isChildDisabledBeforeAge22 ?? undefined,
      })),
    })
  }, [])

  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: detailId,
          data: {
            // ...formValues,
            haveAnyChildern:
              formValues?.haveAnyChildern === undefined
                ? 'Boolean'
                : formValues?.haveAnyChildern,
            numberOfChildern: formValues?.numberOfChildern?.toString(),
            dependents:
              formValues?.dependents?.map((dependent) => ({
                // ...dependent,
                firstName: dependent?.firstName ?? '',
                lastName: dependent?.lastName ?? '',

                isChild1819SchoolUnmarried:
                  dependent?.isChild1819SchoolUnmarried === undefined
                    ? 'Boolean'
                    : dependent?.isChild1819SchoolUnmarried,

                isChildDisabledBeforeAge22:
                  dependent?.isChildDisabledBeforeAge22 === undefined
                    ? 'Boolean'
                    : dependent?.isChildDisabledBeforeAge22,

                isChildDisabled:
                  dependent?.isChildDisabledBeforeAge22 === undefined
                    ? 'Boolean'
                    : dependent?.isChildDisabledBeforeAge22,

                isChildMarried:
                  !dependent?.isChild1819SchoolUnmarried === undefined
                    ? 'Boolean'
                    : dependent?.isChild1819SchoolUnmarried,

                isYoungerThan17:
                  dependent?.isYoungerThan17 === undefined
                    ? 'Boolean'
                    : dependent?.isYoungerThan17,
              })) ?? [],
          },
        }),
      )
  }, [isBotOpen, detailId])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
        numberOfChildern: Number.parseInt(chatForm?.numberOfChildern || ''),
      }))
  }, [chatForm])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const validateForm = () => {
    let isValid = true
    const newErrors: Record<string, string> = {}

    if (formValues?.haveAnyChildern === undefined) {
      newErrors.haveAnyChildern = 'Please select an option'
      isValid = false
    }
    if (formValues?.haveAnyChildern) {
      formValues.dependents.forEach((legalName, index) => {
        const { firstName, lastName } = legalName

        const validationRules = [
          {
            field: `firstName[${index}]`,
            label: 'First Name',
            value: firstName.trim(),
          },
          {
            field: `lastName[${index}]`,
            label: 'Last Name',
            value: lastName.trim(),
          },
        ]

        if (formValues?.dependents) {
          validationRules.forEach((rule) => {
            if (!rule.value) {
              newErrors[rule.field] = `${rule.label} is required`
              isValid = false
            } else if (!/^[A-Za-z ]+$/.test(rule.value)) {
              newErrors[rule.field] = `${rule.label} must contain only letters`
              isValid = false
            } else {
              // Update error message to an empty string if value is valid
              newErrors[rule.field] = ''
            }
          })
        }
      })
    }

    setErrors(newErrors)
    return isValid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      const formValuesToSubmit: typeof formValues = {}
      formValuesToSubmit.familyId = familyId
      formValuesToSubmit.haveAnyChildern = formValues.haveAnyChildern
      if (formValues.haveAnyChildern) {
        formValuesToSubmit.numberOfChildern =
          formValues.numberOfChildern?.toString()
        formValuesToSubmit.dependents = formValues.dependents
      }

      dispatch(setDependents(formValues))
      dispatch(AddDependents(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            handleNext()
          }
        },
      )
    }
  }

  const handleValuesChange = (
    index: number,
    data: { name: string; value: string },
  ) => {
    const newProfileForms: Array<{
      firstName: string
      lastName: string
      isYoungerThan17: boolean | undefined
      isChildMarried: boolean | undefined
      isChildDisabled: boolean | undefined
      isChild1819SchoolUnmarried: boolean | undefined
      isChildDisabledBeforeAge22: boolean | undefined
    }> = [...formValues.dependents]
    newProfileForms[`${index}`][`${data.name}`] = data.value
    setFormValues({ ...formValues, dependents: newProfileForms })
  }

  const handleRemove = (index: number) => {
    const newProfileForms = [...formValues.dependents]
    newProfileForms.splice(index, 1)
    setFormValues({ ...formValues, dependents: newProfileForms })
  }

  const addProfileBox = () => {
    const newForm: Array<{
      firstName: string
      lastName: string
      isYoungerThan17: boolean | undefined
      isChildMarried: boolean | undefined
      isChildDisabled: boolean | undefined
      isChild1819SchoolUnmarried: boolean | undefined
      isChildDisabledBeforeAge22: boolean | undefined
    }> = formValues.dependents || []
    newForm.push({
      firstName: '',
      lastName: '',
      isYoungerThan17: undefined,
      isChildMarried: undefined,
      isChildDisabled: undefined,
      isChild1819SchoolUnmarried: undefined,
      isChildDisabledBeforeAge22: undefined,
    })
    setFormValues({
      ...formValues,
      dependents: newForm,
    })
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              py: 2,
              my: 3,
              borderRadius: '10px',
            }}
          >
            <img src={quote} className={Style.star} alt='' />
            <Typography className={Style.Heading}>
              Page title goes here
            </Typography>
          </Box>

          <CommonRadioBox
            error={errors?.haveAnyChildern}
            title='Do you have any children?'
            commonState={formValues?.haveAnyChildern}
            setcommonState={(value: boolean) => {
              setFormValues((prevState: any) => ({
                ...prevState,
                haveAnyChildern: value,
              }))
            }}
            YesTitle='children'
            NoTitle='I do not have any children'
          />

          {formValues?.haveAnyChildern && (
            <>
              <StarText
                text={`Note that this includes biological and adopted children.`}
              />

              <ArraySelection
                formValue={formValues.numberOfChildern ?? 0}
                values={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                title='How many biological and/or adopted children do you have?'
                handleItemClick={(value) => {
                  setFormValues({
                    ...formValues,
                    numberOfChildern: value,
                  })
                }}
              />
              <StarText
                text={`All families are different, and we love them all equally!`}
              />

              <Typography className={Style.label}>
                Dependent Information: Please provide information for each of
                your children from youngest to oldest.
              </Typography>

              {formValues?.dependents?.map((form, index) => (
                <ProfileBox
                  errors={errors}
                  key={index}
                  handleRemove={() => {
                    handleRemove(index)
                  }}
                  index={index}
                  formValues={form}
                  changeHandler={(data: { name: string; value: string }) => {
                    handleValuesChange(index, data)
                  }}
                />
              ))}
              <AddButton
                button='Add Child'
                title='More information for each of your children'
                subTitle='For an effective records search, It is important that all children be included'
                onClick={addProfileBox}
              />
            </>
          )}
          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleBack}
            >
              Previous
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Next
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default Dependents
