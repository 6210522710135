/* eslint-disable no-prototype-builtins */
import {
  Grid,
  Box,
  Typography,
  Tab,
  Tabs,
  Chip,
  styled,
  Avatar,
  LinearProgress,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import MailIcon from 'src/Assets/Icons/email.svg'
import PhoneIcon from 'src/Assets/Icons/phone.svg'
import LocationIcon from 'src/Assets/Icons/LocationIcon.svg'
import { TAB_DATA } from 'src/Pages/Applications/allTabsData'
import styles from './index.module.scss'
import CustomChip from './snackbar' // Assuming CustomChip component is in a separate file
import { DynamicObj, convertISOToDate } from 'src/Utils/Constants'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import FlagPopup from 'src/Components/flagPopup'
import { setActiveStepTab } from 'src/Redux/Slices/stepSlice'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
export const StyledTab = styled(Tab)({
  '&.MuiTab-root.Mui-selected': {
    fontWeight: 'bold',
  },
})

const StyledLabel = (label: string) => {
  return <span className={styles.StyledLabel}>{label}</span>
}
const Img = styled('img')({
  width: '20px',
  height: '20px',
  marginTop: '7px',
  objectFit: 'cover',
  marginRight: '8px',
})

const ProgressContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '8px',
  border: '1px dashed #c8c9cc',
  bgcolor: '#fff',
  flexGrow: 1,
  whiteSpace: 'normal',
  padding: '10px 12px',
  height: '100%',
}))

const Header = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  color: '#6f737a',
}))

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  height: '4px',
  borderRadius: '30px',
  backgroundColor: '#f2f4f8',
  '& .MuiLinearProgress-bar': {
    borderRadius: '30px',
    backgroundColor: '#7752FE',
  },
  margin: theme.spacing(1, 0),
}))

interface BasicHeaderProps {
  setTabId: (id: number) => void
  setSelectedTabName: (lable: string) => void
  headerData: DynamicObj
  tabId: number
}
function TabPanel(props: {
  [x: string]: any
  children: any
  value: any
  index: any
}) {
  const { children, value, index, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}
const BasicHeader: React.FC<BasicHeaderProps> = (props) => {
  const [value, setValue] = React.useState(0)
  // const completion = 90 // This would likely be dynamic
  const dispatch = useAppDispatch()
  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.BasicDetails?.ContactInformation,
  )

  const assigneeData = useAppSelector(
    (state: RootState) => state?.applicationStore?.BasicDetails?.assigneeData,
  )

  function hasValue(obj: Record<string, any>): boolean {
    for (const key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        obj[key] !== null &&
        obj[key] !== undefined &&
        obj[key] !== ''
      ) {
        return true
      }
    }
    return false
  }

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    props.setTabId(newValue)
    props.setSelectedTabName(tabData[newValue].label)
    dispatch(setActiveStepTab(tabData[newValue].label))
  }

  useEffect(() => {
    setValue(props.tabId)
  }, [props.tabId])

  function renderContactInfoPart(
    part: string | null,
    separator: string = ' ',
  ): string | null {
    return part ? `${part}${separator}` : ' ' // Concatenate the part with the separator
  }

  function renderContactInfo(data: DynamicObj) {
    const { firstName, middleName, lastName } = data

    const fullName = `${renderContactInfoPart(firstName)}${renderContactInfoPart(middleName)}${renderContactInfoPart(lastName)}`

    return fullName
  }

  function renderAddress(address: DynamicObj) {
    const { streetAddress, city, state, country, postalCode } = address

    const formattedAddress = `${renderContactInfoPart(streetAddress, ',')}${renderContactInfoPart(city, ',')}${renderContactInfoPart(state, ',')}${renderContactInfoPart(country, ',')}${renderContactInfoPart(postalCode)}`

    return formattedAddress
  }

  const [openDialog, setOpenDialog] = useState(false)
  const flagdata = useAppSelector(
    (state: RootState) => state?.applicationStore?.BasicDetails?.flagdata,
  )
  const handleOpen = () => {
    setOpenDialog(true)
  }
  const tabData = Object.values(TAB_DATA).map((label) => ({ label }))
  return (
    <>
      {openDialog && (
        <FlagPopup
          open={openDialog}
          setOpen={setOpenDialog}
          handleOpen={handleOpen}
        />
      )}

      <Grid p={4} minHeight={'100%'} pb={0} sx={{ position: 'sticky', top: 0 }}>
        <Grid container pb={1}>
          <Grid
            item
            lg={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Typography
              variant='h2'
              className={`${styles.fontColorBlack} ${styles.fontBold} ${styles.nameTitle}`}
            >
              {renderContactInfo(data)}
            </Typography>
            <CustomChip
              status={
                props?.headerData?.status === 'InProgress'
                  ? 'In Progress'
                  : props?.headerData?.status
              }
              severity='info'
            />
          </Grid>
          {flagdata?.remark?.length ? (
            <Grid item sx={{ marginLeft: 'auto' }}>
              <CustomChip
                setOpenDialog={setOpenDialog}
                status={`${flagdata?.remark?.length} Flags`}
                severity='error'
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid pb={1.5}>
          {props.headerData?.socialSecurityNumber && (
            <Typography variant='h3' className={styles.ssntext}>
              SSN: {props.headerData?.socialSecurityNumber}
            </Typography>
          )}
        </Grid>

        <Grid container pb={2}>
          {props.headerData?.address && (
            <Grid item xs={'auto'} container alignItems='center' pr={3}>
              <Grid item>
                <Img src={LocationIcon} alt='Location Icon' />
              </Grid>
              <Grid item>
                <Typography variant='body1' className={styles.detailText}>
                  {renderAddress(props.headerData?.address)}
                </Typography>
              </Grid>
            </Grid>
          )}
          {props.headerData?.phoneNumber && (
            <Grid item xs={'auto'} container alignItems='center' pr={3}>
              <Grid item>
                <Img src={PhoneIcon} alt='Phone Icon' />
              </Grid>
              <Grid item>
                <Typography variant='body1' className={styles.detailText}>
                  {data?.phone}
                </Typography>
              </Grid>
            </Grid>
          )}

          {props.headerData?.emailId && (
            <Grid item xs={'auto'} container alignItems='center' pr={3}>
              <Grid item>
                <Img src={MailIcon} alt='Email Icon' />
              </Grid>
              <Grid item>
                <Typography variant='body1' className={styles.detailText}>
                  {' '}
                  {data?.email}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          <Grid item>
            <ProgressContainer>
              <Box className={styles.Box1}>
                <Box className={styles.Box2}>
                  <Typography variant='body1' className={styles.semiBoldText}>
                    {convertISOToDate(props.headerData?.createdAt)}
                  </Typography>
                </Box>
                <Typography variant='body2' className={styles.OtherText}>
                  Creation Date
                </Typography>
              </Box>
            </ProgressContainer>
          </Grid>

          {props.headerData?.applicationComplete && (
            <Grid item lg={4}>
              <ProgressContainer>
                <Box className={styles.AvatarBox}>
                  <Box className={styles.Box1}>
                    <Header paddingTop={0.5}>
                      <Typography variant='h3'>
                        Application Completion
                      </Typography>
                      <Chip
                        label={`${parseInt(props.headerData?.applicationComplete)}%`}
                        size='small'
                      ></Chip>
                    </Header>
                    <CustomLinearProgress
                      variant='determinate'
                      value={parseInt(props.headerData?.applicationComplete)}
                    />
                  </Box>
                </Box>
              </ProgressContainer>
            </Grid>
          )}
          <Grid item>
            {hasValue(assigneeData) ? (
              <ProgressContainer className={styles.profileBox}>
                <>
                  <Box className={styles.AvatarBox}>
                    <Avatar
                      src={assigneeData?.profileURL}
                      alt='Profile Image'
                      sx={{ width: 50, height: 50 }}
                    />
                    <Box className={styles.Box1}>
                      <Box className={styles.Box2}>
                        <Typography
                          variant='body1'
                          className={styles.semiBoldText}
                        >
                          {renderContactInfo(assigneeData)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              </ProgressContainer>
            ) : null}
          </Grid>
        </Grid>

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='data-driven tabs'
          variant='scrollable'
          scrollButtons='auto'
          className='sticky'
        >
          {tabData.map((tab, index) => (
            <StyledTab
              key={index}
              label={StyledLabel(tab.label)}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Grid>
    </>
  )
}

export default BasicHeader
