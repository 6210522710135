import { Avatar, Box, Grid, Menu, MenuItem, Typography } from '@mui/material'
import MoreIcon from 'src/Assets/Icons/More.svg'
import email from 'src/Assets/Icons/email.svg'
import Phone from 'src/Assets/Icons/phone.svg'
// import employee from 'src/Assets/Images/employee.svg'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { employeeType } from 'src/Redux/Services/Types/employeeType'
import {
  DeleteEmployeeRequest,
  EmployeeListRequest,
} from 'src/Redux/Slices/employeeSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { COLORS, Roles } from 'src/Utils/Constants'
import { ROUTES } from 'src/Utils/Routes'
import Style from './index.module.scss'

export const EmployeesList = ({
  employeeData,
  showActions = true,
}: {
  employeeData: employeeType
  showActions?: boolean
}) => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const data = useAppSelector((state) => state.auth?.data)

  const handleDetails = (uuid: string | undefined, editMode: boolean) => {
    if (data?.roleScope === Roles.ADMIN)
      navigate(ROUTES.ADMIN_EMPLOYEE_DETAIL, { state: { uuid, editMode } })
    else navigate(ROUTES.LEGAL_EMPLOYEE_DETAIL, { state: { uuid, editMode } })
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const ActionOpen = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: { stopPropagation: () => void }) => {
    event.stopPropagation()
    setAnchorEl(null)
  }
  return (
    <>
      <Grid
        className={Style.MainGrid}
        onClick={() => {
          handleDetails(employeeData.uuid, false)
        }}
        style={{
          backgroundColor: employeeData.isActive
            ? COLORS.COLOR_WHITE
            : COLORS.COLOR_WHITE_VARIANT_1,
        }}
      >
        <Grid className={Style.SubGrid}>
          <Box className={Style.AvatarBox}>
            <Avatar
              sx={{ height: '60px', width: '60px' }}
              src={employeeData.profileUrl}
              alt='photoURL'
            />
            <Box className={Style.ContentTypeBox}>
              <Box className={Style.ContentText}>
                {employeeData.jobType ?? '-'}
              </Box>
            </Box>
          </Box>
          <Box className={Style.divider} />
          <Box className={Style.ContentBox}>
            <Box className={Style.ContentHeadBox}>
              <Box>
                <Typography className={Style.ContentHeadNameText}>
                  {employeeData.firstName}
                </Typography>
                <Typography className={Style.ContentHead}>
                  {employeeData.jobTitle}
                </Typography>
              </Box>
              {showActions ? (
                <img
                  src={MoreIcon}
                  alt='threeDot'
                  style={{ marginRight: '4px' }}
                  aria-controls={ActionOpen ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={ActionOpen ? 'true' : undefined}
                  onClick={(e) => {
                    handleClick(
                      e as unknown as React.MouseEvent<
                        HTMLButtonElement,
                        MouseEvent
                      >,
                    )
                  }}
                />
              ) : null}

              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={ActionOpen}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation()
                    handleDetails(employeeData.uuid, false)
                  }}
                >
                  View
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation()
                    handleDetails(employeeData.uuid, true)
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation()
                    handleClose(event)
                    dispatch(
                      DeleteEmployeeRequest({
                        uuid: employeeData?.uuid,
                        data: { isActive: !employeeData?.isActive },
                      }),
                    ).then(() => {
                      dispatch(EmployeeListRequest({}))
                    })
                  }}
                >
                  {employeeData?.isActive ? 'Deactivate' : 'Activate'}
                </MenuItem>
              </Menu>
            </Box>
            <Box className={Style.ContentContactBox}>
              {employeeData?.phoneNumber ? (
                <Box className={Style.ContentContactSubBox}>
                  <img src={Phone} alt='Phone' />
                  <Box className={Style.ContentText}>
                    {`(${employeeData?.phoneNumber.slice(0, 3)}) ${employeeData?.phoneNumber.slice(3, 6)}-${employeeData?.phoneNumber.slice(6)}`}
                  </Box>
                </Box>
              ) : (
                <></>
              )}
              {employeeData.emailId ? (
                <Box className={Style.ContentContactSubBox}>
                  <img src={email} alt='email' />
                  <Box className={Style.ContentText}>
                    {employeeData.emailId}
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
