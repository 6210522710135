import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { ApplicationIntroBox, Name, StarText } from 'src/Components/Fields'
import { ContactInformationType } from 'src/Redux/Services/Types/applicationType'
import {
  AddContactInformation,
  EditContactInformation,
} from 'src/Redux/Slices/ApplicationSlices/BasicDetailsSlice'
import {
  setApplicationId,
  setBasicId,
} from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { setContactInformation } from 'src/Redux/Slices/ApplicationStore/BasicDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from '../index.module.scss'
import CurrentAddress from './Current'
import MailingAddress from './Mailing'
import PhysicalAddress from './Physical'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'

interface SuccessPayload {
  success: boolean
  message: string
  data: {
    applicationId: string
    basicDetailId: string
  }
}

const ContactInformation = ({
  handleNext,
  chatForm,
}: {
  chatForm: any
  handleNext: () => void
}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const assistanceId = location?.state?.data ?? ''

  const ContactInformationState = useAppSelector(
    (state) => state?.applicationStore?.BasicDetails?.ContactInformation,
  )
  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.basicId,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const [formValues, setFormValues] = useState<ContactInformationType>({
    assistanceId: assistanceId ?? '',
    applicant: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    isUSAPhoneNumber: undefined,
    phone: '',
    physicalAddress: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    isLiveAtPhysicalAddress: undefined,
    currentAddress: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    wantToReceiveMailAtPhysicalAddress: undefined,
    mailingAddress: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
  })

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    if (assistanceId) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        assistanceId,
        applicant: 'Assistance',
      }))
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        applicant: 'Self',
      }))
    }
  }, [])
  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            isUSAPhoneNumber:
              formValues?.isUSAPhoneNumber === undefined
                ? 'Boolean'
                : formValues?.isUSAPhoneNumber,
            isLiveAtPhysicalAddress:
              formValues?.isLiveAtPhysicalAddress === undefined
                ? 'Boolean'
                : formValues?.isLiveAtPhysicalAddress,
            wantToReceiveMailAtPhysicalAddress:
              formValues?.wantToReceiveMailAtPhysicalAddress === undefined
                ? 'Boolean'
                : formValues?.wantToReceiveMailAtPhysicalAddress,
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])

  useEffect(() => {
    setFormValues({
      ...ContactInformationState,
      assistanceId: assistanceId ?? '',
      applicant: assistanceId ? 'Assistance' : 'Self',
    })
  }, [assistanceId && ContactInformationState])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleAddressChange = (
    address: 'physicalAddress' | 'currentAddress' | 'mailingAddress',
    event: {
      name: string
      value: string
    },
  ) => {
    const { name, value } = event
    let updatedFormValues: ContactInformationType = { ...formValues }
    updatedFormValues = {
      ...updatedFormValues,
      [address]: {
        ...updatedFormValues[address],
        [name]: value,
      },
    }
    setFormValues(updatedFormValues)
  }

  const handleFieldChange = (data: {
    name: string
    value: string | number
  }) => {
    const { name, value } = data
    let updatedFormValues: any = { ...formValues }

    updatedFormValues = {
      ...updatedFormValues,
      [name]: value,
    }
    setFormValues(updatedFormValues)
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (!formValues.firstName?.trim()) {
      newErrors.firstName = 'First Name is required'
      valid = false
    } else if (!/^[A-Za-z ]+$/.test(formValues.firstName?.trim())) {
      newErrors.firstName = 'Only characters are allowed'
      valid = false
    }

    if (!formValues.middleName?.trim()) {
      newErrors.middleName = 'Middle Name is required'
      valid = false
    } else if (!/^[A-Za-z ]+$/.test(formValues.middleName?.trim())) {
      newErrors.middleName = 'Only characters are allowed'
      valid = false
    }

    if (!formValues.lastName?.trim()) {
      newErrors.lastName = 'Last Name is required'
      valid = false
    } else if (!/^[A-Za-z ]+$/.test(formValues.lastName?.trim())) {
      newErrors.lastName = 'Only characters are allowed'
      valid = false
    }

    // Validation logic for Phone Number
    if (!formValues.phone?.trim()) {
      newErrors.phone = 'Phone Number is required'
      valid = false
    } else if (!/^\d{10}$/.test(formValues.phone?.trim())) {
      newErrors.phone = 'Phone Number must be 10 digits'
      valid = false
    }

    if (!formValues.email?.trim()) {
      newErrors.email = 'Email Name is required'
      valid = false
    } else if (
      !/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(formValues.email?.trim())
    ) {
      newErrors.email = 'Please enter a valid email address'
      valid = false
    }

    if (!formValues.physicalAddress?.streetAddress?.trim()) {
      newErrors.address = 'Street Address is required'
      valid = false
    }
    if (!formValues.physicalAddress?.city?.trim()) {
      newErrors.city = 'City/Town is required'
      valid = false
    }

    if (!formValues.physicalAddress?.state?.trim()) {
      newErrors.state = 'State is required'
      valid = false
    }
    if (!formValues.physicalAddress?.postalCode?.trim()) {
      newErrors.postalCode = 'zip Code is required'
      valid = false
    }
    if (!formValues.physicalAddress?.country?.trim()) {
      newErrors.country = 'Country is required'
      valid = false
    }

    if (formValues.isUSAPhoneNumber === undefined) {
      newErrors.isUSAPhoneNumber = 'Please select an option'
      valid = false
    }
    if (formValues.isLiveAtPhysicalAddress === undefined) {
      newErrors.isLiveAtPhysicalAddress = 'Please select an option'
      valid = false
    }
    if (formValues.wantToReceiveMailAtPhysicalAddress === undefined) {
      newErrors.wantToReceiveMailAtPhysicalAddress = 'Please select an option'
      valid = false
    }

    if (!formValues.isLiveAtPhysicalAddress) {
      if (!formValues.currentAddress?.streetAddress?.trim()) {
        newErrors.address1 = 'Street Address is required'
        valid = false
      }
      if (!formValues.currentAddress?.city?.trim()) {
        newErrors.city1 = 'City/Town is required'
        valid = false
      }
      if (!formValues.currentAddress?.state?.trim()) {
        newErrors.state1 = 'State is required'
        valid = false
      }
      if (!formValues.currentAddress?.postalCode?.trim()) {
        newErrors.postalCode1 = 'zip Code is required'
        valid = false
      }
      if (!formValues.currentAddress?.country?.trim()) {
        newErrors.country1 = 'Country is required'
        valid = false
      }
    }

    if (!formValues.wantToReceiveMailAtPhysicalAddress) {
      if (!formValues.mailingAddress?.streetAddress?.trim()) {
        newErrors.address2 = 'Street Address is required'
        valid = false
      }
      if (!formValues.mailingAddress?.city?.trim()) {
        newErrors.city2 = 'City/Town is required'
        valid = false
      }
      if (!formValues.mailingAddress?.state?.trim()) {
        newErrors.state2 = 'State is required'
        valid = false
      }
      if (!formValues.mailingAddress?.postalCode?.trim()) {
        newErrors.postalCode2 = 'zip Code is required'
        valid = false
      }
      if (!formValues.mailingAddress?.country?.trim()) {
        newErrors.country2 = 'Country is required'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      dispatch(setContactInformation(formValues))
      if (!detailId) {
        dispatch(AddContactInformation(formValues)).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            if (res?.payload?.success) {
              const data = (res.payload as SuccessPayload).data
              dispatch(setBasicId(data.basicDetailId))
              dispatch(setApplicationId(data.applicationId))
              handleNext()
            }
          },
        )
      } else if (detailId) {
        dispatch(
          EditContactInformation({ ...formValues, basicDetailId: detailId }),
        ).then(
          (
            res:
              | PayloadAction<
                  any,
                  string,
                  { arg: any; requestId: string; requestStatus: 'fulfilled' },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & (
                    | { rejectedWithValue: true }
                    | ({ rejectedWithValue: false } & {})
                  ),
                  SerializedError
                >,
          ) => {
            if (res?.payload?.success) {
              handleNext()
            }
          },
        )
      }
    }
  }
  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox
            heading="First, we're going to collect some basic contact information.
              Let's go!"
          />
          <Typography className={Style.label}>
            Let's start by providing your basic contact details.
          </Typography>
          <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
            <Name
              name='firstName'
              title='First Name'
              value={formValues?.firstName}
              error={errors.firstName}
              handleChange={handleFieldChange}
            />
            <Name
              name='middleName'
              title='Middle Name'
              value={formValues?.middleName}
              error={errors.middleName}
              handleChange={handleFieldChange}
            />
            <Name
              name='lastName'
              title='Last Name'
              value={formValues?.lastName}
              error={errors.lastName}
              handleChange={handleFieldChange}
            />
          </Grid>
          <Name
            email
            name='email'
            title='Email'
            value={formValues?.email}
            error={errors.email}
            handleChange={handleFieldChange}
          />
          <CommonRadioBox
            error={errors.isUSAPhoneNumber}
            title='Do you have the U.S. Phone Number?'
            commonState={formValues.isUSAPhoneNumber}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isUSAPhoneNumber: value,
              })
            }}
            YesTitle='I have the U.S. Phone Number'
            NoTitle='I don’t have the U.S. Phone Number'
          />
          <Name
            number
            name='phone'
            title='Phone Number'
            value={formValues?.phone}
            error={errors.phone}
            handleChange={handleFieldChange}
          />
          <StarText text='Make sure to provide the best phone number to reach you between 9:00 AM and 5 PM in the event that BenefitsBoost or the SSA representative reviewing your case has questions' />
          <PhysicalAddress
            errors={errors}
            handleFieldChange={(data) => {
              handleAddressChange('physicalAddress', data)
            }}
            formValues={formValues}
            setFormValues={setFormValues}
          />
          <Box height={'10px'} width={'100%'} />
          <CommonRadioBox
            error={errors?.isLiveAtPhysicalAddress}
            title='Just to confirm: do you live at above given address?'
            // commonState={physicalAdd}
            // setcommonState={setphysicalAdd}
            commonState={formValues.isLiveAtPhysicalAddress}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isLiveAtPhysicalAddress: value,
              })
            }}
            YesTitle='I live at above address.'
            NoTitle='I do not live at above address.'
          />
          {formValues?.isLiveAtPhysicalAddress === false ? (
            <>
              <CurrentAddress
                errors={errors}
                handleFieldChange={(data: { name: string; value: string }) => {
                  handleAddressChange('currentAddress', data)
                }}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </>
          ) : (
            <></>
          )}
          <Box height={'10px'} width={'100%'} />
          <CommonRadioBox
            error={errors?.wantToReceiveMailAtPhysicalAddress}
            title='Would you like to receive mail related to this application at this address?'
            // commonState={mailAdd}
            // setcommonState={setMailAdd}
            commonState={formValues.wantToReceiveMailAtPhysicalAddress}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                wantToReceiveMailAtPhysicalAddress: value,
              })
            }}
            YesTitle=' I would like to receive mail related to this application at this address'
            NoTitle=' I don’t like to receive mail related to this application at this address'
          />
          {formValues?.wantToReceiveMailAtPhysicalAddress === false ? (
            <>
              <MailingAddress
                errors={errors}
                handleFieldChange={(data: { name: string; value: string }) => {
                  handleAddressChange('mailingAddress', data)
                }}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </>
          ) : (
            <></>
          )}
          <Box className={Style.AddButton}>
            <div></div>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
              // onClick={handleNext}
            >
              Next
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default ContactInformation
