import { baseAPI } from '../Common/httpCommon'
import {
  DisabilityBackgroundType,
  DisabilityEmploymentType,
  DisabilitySettlementsType,
  type DisabilityDetailsType,
} from './Types/applicationType'

const AddDisabilityBackground = async (
  data: DisabilityBackgroundType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.post('application/disability/background', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditDisabilityBackground = async (
  data: DisabilityDetailsType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/disability/background', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddDisabilityEmployment = async (
  data: DisabilityEmploymentType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/disability/employement', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddPayoutsSettlements = async (
  data: DisabilitySettlementsType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/disability/payout', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const DisabilityDetailsService = {
  AddDisabilityBackground,
  EditDisabilityBackground,
  AddDisabilityEmployment,
  AddPayoutsSettlements,
}
export default DisabilityDetailsService
