import React, { useState } from 'react'
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  Box,
  Typography,
  DialogContent,
  Checkbox,
  Grid,
  InputLabel,
  // IconButton,
} from '@mui/material'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'

interface DataType {
  roleName: string
  description: string
  permissions: string[]
}

const PopupForm = ({
  open,
  setOpen,
  handleOpen,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
}) => {
  // const dispatch = useAppDispatch()
  // const data = useAppSelector((state) => state.auth.data)

  const handleClose = () => {
    setOpen(false)
  }
  const [checked1, setChecked1] = useState(false)
  const [checked2, setChecked2] = useState(false)
  const [checked3, setChecked3] = useState(false)
  const [formValues, setFormValues] = useState<DataType>({
    roleName: '',
    description: '',
    permissions: [],
  })

  const handleCheckboxChange1 = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked
    setChecked1(isChecked)
    setFormValues({
      ...formValues,
      permissions: isChecked
        ? [...formValues.permissions, 'Permission 1']
        : formValues.permissions.filter(
            (permission) => permission !== 'Permission 1',
          ),
    })
  }

  const handleCheckboxChange2 = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked
    setChecked2(isChecked)
    setFormValues({
      ...formValues,
      permissions: isChecked
        ? [...formValues.permissions, 'Permission 2']
        : formValues.permissions.filter(
            (permission) => permission !== 'Permission 2',
          ),
    })
  }

  const handleCheckboxChange3 = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked
    setChecked3(isChecked)
    setFormValues({
      ...formValues,
      permissions: isChecked
        ? [...formValues.permissions, 'Permission 3']
        : formValues.permissions.filter(
            (permission) => permission !== 'Permission 3',
          ),
    })
  }
  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (!formValues.roleName.trim()) {
      newErrors.roleName = 'First Name is required'
      valid = false
    }
    if (!formValues.description.trim()) {
      newErrors.description = 'Description is required'
      valid = false
    }

    // setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      handleOpen()
    } else setOpen(true)
  }

  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={open}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Add Member as Administrator-Dummy-1
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='threeDot'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={Style.DialogContentBox}>
              <form onSubmit={handleSubmit}>
                <Box pt={1}>
                  <InputLabel className={Style.label}>
                    Select Members & Permissions
                  </InputLabel>
                  <Grid xs={12} className={Style.Checkbox}>
                    <Grid xs={5} className={Style.CheckGrid}>
                      <Checkbox
                        checked={checked1}
                        onChange={handleCheckboxChange1}
                      />
                      <Typography className={Style.CheckText}>
                        Permission 1
                      </Typography>
                    </Grid>
                    <Grid xs={4} className={Style.CheckGrid}>
                      <Checkbox
                        checked={checked2}
                        onChange={handleCheckboxChange2}
                      />
                      <Typography className={Style.CheckText}>
                        Permission 2
                      </Typography>
                    </Grid>
                    <Grid xs={4} className={Style.CheckGrid}>
                      <Checkbox
                        checked={checked3}
                        onChange={handleCheckboxChange3}
                      />
                      <Typography className={Style.CheckText}>
                        Permission 3
                      </Typography>
                    </Grid>
                    <Grid xs={4} className={Style.CheckGrid}>
                      <Checkbox
                        checked={checked3}
                        onChange={handleCheckboxChange3}
                      />
                      <Typography className={Style.CheckText}>
                        Permission 3
                      </Typography>
                    </Grid>
                    <Grid xs={4} className={Style.CheckGrid}>
                      <Checkbox
                        checked={checked3}
                        onChange={handleCheckboxChange3}
                      />
                      <Typography className={Style.CheckText}>
                        Permission 3
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <DialogActions>
                  <Box className={Style.AddButton}>
                    <Button type='submit' className={Style.Button}>
                      Add
                    </Button>
                  </Box>
                </DialogActions>
              </form>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default PopupForm
