import { baseAPI } from '../Common/httpCommon'

const totalOrganization = async (token: string) => {
  try {
    return await baseAPI.get('dashboard/total-organization', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const latestOrganization = async (token: string) => {
  try {
    return await baseAPI.get('dashboard/latest-organization', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const newOrganization = async (token: string) => {
  try {
    return await baseAPI.get('dashboard/new-organization', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const totalRevenue = async (token: string) => {
  try {
    return await baseAPI.get('dashboard/total-revenue', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const monthlyRevenue = async (token: string) => {
  try {
    return await baseAPI.get('dashboard/monthly-revenue', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const applicationReviewFilter = async (data: string, token: string) => {
  try {
    return await baseAPI.get(`dashboard/application-review?interval=${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const applicationReview = async (token: string) => {
  try {
    return await baseAPI.get('dashboard/application-review', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

// legal side total employee count
const totalEmployee = async (token: string) => {
  try {
    return await baseAPI.get('dashboard/total-employee', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const recurringRevenue = async (data: any, token: string) => {
  try {
    return await baseAPI.get(
      `dashboard/recurring-revenue?startDate=${data.startDate}&endDate=${data.endDate}&subscriptionId=${data.subscriptionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  } catch (e: any) {
    return e
  }
}

const futureForecast = async (data: any, token: string) => {
  try {
    return await baseAPI.get(
      `dashboard/future-forecast?startDate=${data.startDate}&endDate=${data.endDate}&subscriptionId=${data.subscriptionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  } catch (e: any) {
    return e
  }
}

const DashboardService = {
  totalOrganization,
  latestOrganization,
  newOrganization,
  totalRevenue,
  monthlyRevenue,
  applicationReviewFilter,
  applicationReview,
  totalEmployee,
  recurringRevenue,
  futureForecast,
}
export default DashboardService
