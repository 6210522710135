import { createSlice } from '@reduxjs/toolkit'
import { SignInRequest, GetPaymentDetail, LogoutRequest } from './authSlice'

const initialState: {
  success: boolean
  loading: boolean
  data: string[] | undefined
} = {
  success: false,
  loading: false,
  data: [],
}

// Define the reducers that can modify the state
const permissionSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(SignInRequest.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(SignInRequest.fulfilled, (state, action) => {
      state.success = action.payload?.success
      state.loading = false
      state.data = action.payload?.data?.permissions
    })
    builder.addCase(SignInRequest.rejected, (state) => {
      state.success = false
      state.loading = false
    })
    builder.addCase(GetPaymentDetail.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(GetPaymentDetail.fulfilled, (state, action) => {
      state.success = action.payload?.success
      state.loading = false
      state.data = action.payload?.data?.permissions
    })
    builder.addCase(GetPaymentDetail.rejected, (state) => {
      state.success = false
      state.loading = false
    })
    builder.addCase(LogoutRequest.fulfilled, (state) => {
      state = initialState
    })
  },
})

export default permissionSlice.reducer
