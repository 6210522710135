import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import BBLogo from 'src/Assets/Icons/BBLogo.svg'
import HandsImg from 'src/Assets/Icons/Hands.svg'
import { ROUTES } from 'src/Utils/Routes'
import Style from './index.module.scss'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { VerifyApplink } from 'src/Redux/Slices/ApplicationSlices/applicationSlice'
import { useEffect, useState } from 'react'
import {
  setApplicationId,
  setBasicId,
  setDisabilityId,
  setEducationAndEmployementId,
  setFamilyId,
  setLegalId,
  setMedicalDetailId,
  setPublic,
} from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import {
  BasicDetailsType,
  FamilyDetailsType,
  DisabilityDetailsType,
  EducationAndEmployementType,
  MedicalDetailsType,
} from 'src/Redux/Services/Types/applicationType'
import { getApplicationDetail } from 'src/Redux/Slices/ApplicationSlices/allApplicationSlice'
import { setBasicDetails } from 'src/Redux/Slices/ApplicationStore/BasicDetailsStore'
import { setDisabilityDetails } from 'src/Redux/Slices/ApplicationStore/DisabilityDetailsStore'
import { setEducationDetails } from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import { setFamilyDetails } from 'src/Redux/Slices/ApplicationStore/FamilyDetailsStore'
import { setLegalDetails } from 'src/Redux/Slices/ApplicationStore/LegalDetailsStore'
import { setMedicalDetails } from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import {
  extractDetailedApplicationMedicalData,
  extractDetailedApplicationLegalData,
  extractDetailedApplicationBasicData,
  extractDetailedApplicationFamilyData,
  extractDetailedApplicationDisabledData,
  extractDetailedApplicationEducationData,
} from 'src/Utils/Constants'
import {
  resetApplicationStep,
  setBasicDetailsStep,
  setDisabilityDetailsStep,
  setEducationDetailsStep,
  setFamilyDetailsStep,
  setLegalDetailsStep,
  setMedicalDetailsStep,
  setSignConsentStep,
  setSubmitStep,
  setUploadDocumentStep,
} from 'src/Redux/Slices/ApplicationStore/ApplicationStepStore'

const Image = styled('img')({
  maxWidth: '100%',
  height: 'auto',
  marginBottom: '40px',
})

const ActionButton = styled(Button)({
  backgroundColor: '#7752FE',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#161b22',
  },
  padding: '13px 23px',
  marginTop: '30px',
  textTransform: 'unset',
})

const Welcome = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [success, setSuccess] = useState(false)
  const link = useLocation().pathname.split('/')[2]

  const applicationId = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.applicationId,
  )

  useEffect(() => {
    if (process.env.REACT_APP_MILESTONE_4) {
      dispatch(VerifyApplink(link)).then((res) => {
        if (res.payload?.success) {
          setSuccess(true)
          dispatch(setApplicationId(res.payload.data.applicationId))
          dispatch(setBasicId(res.payload.data.basicDetailId))
          dispatch(setPublic(true))
        } else {
          setSuccess(false)
          dispatch(setPublic(false))
        }
      })
    }
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_MILESTONE_4) {
      dispatch(getApplicationDetail(applicationId)).then((res) => {
        setApplicationId(applicationId)

        // Check if response contains data
        if (res?.payload?.data) {
          const jsonData = res?.payload?.data
          const basicdata = jsonData?.basicdata[0]
          const familydata = jsonData?.familydata[0]
          const legalData = jsonData?.legalData[0]
          const disablitydata = jsonData?.disabilitydetails[0]
          const educationdata = jsonData?.educationdetails[0]
          const assistancedata = jsonData?.assistancedata[0]
          const medicaldetails = jsonData?.medicaldetails[0]

          const MedicalDetails = extractDetailedApplicationMedicalData(
            medicaldetails,
            applicationId,
          )
          const LegalDetails = extractDetailedApplicationLegalData(
            legalData,
            applicationId,
          )

          const basicDetails = extractDetailedApplicationBasicData(
            basicdata,
            assistancedata,
          )

          const familyDetails = extractDetailedApplicationFamilyData(
            familydata,
            applicationId,
          )
          const disabilityDetails = extractDetailedApplicationDisabledData(
            disablitydata,
            applicationId,
          )

          const educationDetail =
            extractDetailedApplicationEducationData(educationdata)

          if (basicDetails) {
            dispatch(setBasicId(jsonData?.basicdata[0]?.basicId as string))
            dispatch(setBasicDetails(basicDetails as BasicDetailsType))
          }
          if (familyDetails) {
            dispatch(setFamilyId(jsonData?.familydata[0]?.familyId as string))

            dispatch(setFamilyDetails(familyDetails as FamilyDetailsType))
          }

          if (LegalDetails) {
            dispatch(setLegalId(jsonData?.legalData[0]?.legalId as string))

            dispatch(setLegalDetails(LegalDetails))
          }
          if (disabilityDetails) {
            dispatch(
              setDisabilityId(jsonData?.disabilitydetails[0]?.disabilityId),
            )
            dispatch(
              setDisabilityDetails(disabilityDetails as DisabilityDetailsType),
            )
          }
          if (educationDetail) {
            dispatch(
              setEducationAndEmployementId(
                jsonData?.educationdetails[0]?.educationId,
              ),
            )

            dispatch(
              setEducationDetails(
                educationDetail as EducationAndEmployementType,
              ),
            )
          }
          if (MedicalDetails) {
            dispatch(setMedicalDetailId(jsonData?.medicaldetails[0]?.medicalId))
            dispatch(setMedicalDetails(MedicalDetails as MedicalDetailsType))
          }
        }
      })
    }
  }, [applicationId])

  const resetStep = () => {
    dispatch(resetApplicationStep())
    dispatch(setBasicDetailsStep(''))
    dispatch(setFamilyDetailsStep(''))
    dispatch(setLegalDetailsStep(''))
    dispatch(setDisabilityDetailsStep(''))
    dispatch(setEducationDetailsStep(''))
    dispatch(setMedicalDetailsStep(''))
    dispatch(setUploadDocumentStep(''))
    dispatch(setSignConsentStep(''))
    dispatch(setSubmitStep(''))
  }

  return (
    <>
      <Box className={Style.WelcomeBox}>
        {!success ? (
          <Box color='white'>
            The link is invalid or expired. Please try again.
          </Box>
        ) : (
          <Box className={Style.GridBox}>
            <Image src={BBLogo} alt='Welcome' loading='lazy' />
            <Typography className={Style.Title}>
              Welcome to BenefitsBoost: Your Digital Disability Benefits
              Assistant
            </Typography>
            {/* <Typography
                variant='body1'
                gutterBottom
                my={2}
                className={Style.SubTitle}
              >
                Please clarify whether you are assisting someone or seeking
                benefits for yourself.
              </Typography> */}
            <Typography variant='body1' gutterBottom className={Style.HeadTxt}>
              Welcome to BenefitsBoost, your trusted partner in navigating the
              disability benefits application process easily and efficiently. As
              your dedicated digital assistant to you and your representative,
              we're here to guide you through the disability benefits
              application questions, ensuring a smooth and stress-free
              experience.
            </Typography>
            <Typography variant='body1' gutterBottom className={Style.HeadTxt}>
              Our service includes an innovative voice assistant feature that
              allows you to speak your answers, making the process effortless
              and quick.The voice assistant may ask clarifying questions or
              provide suggestions to understand your situation better. This
              ensures we create the most comprehensive and accurate
              representation of your circumstances within your application.
              Furthermore, you can save and return at any time during your
              experience.
            </Typography>
            <Typography variant='body1' gutterBottom className={Style.HeadTxt}>
              Rest assured that your responses will be securely transmitted to
              your representative, allowing them to swiftly proceed with
              submitting your disability benefits application. Our goal is to
              make this journey as seamless as possible for you, so you can
              focus on what matters most: your well-being.
            </Typography>
            <Image
              sx={{ my: 2 }}
              src={HandsImg}
              alt='Get started'
              loading='lazy'
            />
            <Typography
              variant='body1'
              gutterBottom
              className={Style.HeadSubTxt}
            >
              Before we begin, tell us if you're completing the application for
              yourself or if you'll be assisting someone who is seeking benefits
              like a loved one or friend.
            </Typography>
            <ActionButton
              onClick={() => {
                resetStep()
                navigate(ROUTES.BASIC_DETAILS)
              }}
            >
              I'm completing the application for myself
            </ActionButton>
            <Typography
              my={2}
              variant='body1'
              gutterBottom
              className={Style.LineTxt}
              onClick={() => {
                resetStep()
                navigate(ROUTES.APPLICATION_ASSISTANT)
              }}
            >
              I'll be assisting a loved one or friend with their application
            </Typography>
          </Box>
        )}
      </Box>
    </>
  )
}
export default Welcome
