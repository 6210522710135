// placeUtils.js

import { Dispatch, SetStateAction } from 'react'
import {
  ContactInformationType,
  HospitalAndMedicalFacilityCareType,
  MedicalProfessionalDetailType,
  ParentType,
  PassApplicationType,
} from 'src/Redux/Services/Types/applicationType'

export const fetchPlaceDetails = (
  placeId: string,
  formValues: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  },
  setFormValues: ({
    streetAddress,
    city,
    state,
    postalCode,
    country,
  }: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  }) => void,
) => {
  const placesService = new google.maps.places.PlacesService(
    document.createElement('div'),
  )
  placesService.getDetails({ placeId }, (place, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      const addressComponents = place.address_components

      if (addressComponents && addressComponents.length > 0) {
        const updatedFormValues = { ...formValues }

        addressComponents.forEach((component) => {
          const types = component.types
          if (types.includes('street_number')) {
            updatedFormValues.streetAddress = component.long_name
          } else if (types.includes('route')) {
            updatedFormValues.streetAddress += ` ${component.long_name}`
          } else if (types.includes('locality')) {
            updatedFormValues.city = component.long_name
          } else if (types.includes('administrative_area_level_1')) {
            updatedFormValues.state = component.long_name
          } else if (types.includes('postal_code')) {
            updatedFormValues.postalCode = component.long_name
          } else if (types.includes('country')) {
            updatedFormValues.country = component.long_name
          }
        })

        setFormValues(updatedFormValues)
      } else {
        console.error('Invalid place or missing address components')
      }
    }
  })
}

export const fetchFromCity = (
  placeId: string,
  formValues:
    | {
        city?: string
        state?: string
        country?: string
      }
    | undefined,
  setFormValues: Dispatch<
    SetStateAction<{
      city?: string
      state?: string
      country?: string
    }>
  >,
) => {
  const placesService = new google.maps.places.PlacesService(
    document.createElement('div'),
  )
  placesService.getDetails({ placeId }, (place, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      const addressComponents = place.address_components

      if (addressComponents && addressComponents.length > 0) {
        const updatedFormValues = { ...formValues }

        addressComponents.forEach((component) => {
          const types = component.types
          if (types.includes('locality')) {
            updatedFormValues.city = component.long_name
          } else if (types.includes('administrative_area_level_1')) {
            updatedFormValues.state = component.long_name
          } else if (types.includes('country')) {
            updatedFormValues.country = component.long_name
          }
        })

        setFormValues(updatedFormValues)
      } else {
        console.error('Invalid place or missing address components')
      }
    }
  })
}

export const updateCityStateCountry = (
  formValues: {
    city?: string
    state?: string
    country?: string
  },
  addressData: {
    city?: string
    state?: string
    country?: string
  },
  setFormValues: Dispatch<
    SetStateAction<{
      city?: string
      state?: string
      country?: string
    }>
  >,
) => {
  const updatedFormValues = {
    ...formValues,
    ...addressData,
  }
  setFormValues(updatedFormValues)
}

export const updatePhysicalAddressInFormValues = (
  formValues: ContactInformationType,
  physicalAddressData: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  },
  setFormValues: Dispatch<SetStateAction<ContactInformationType>>,
) => {
  const updatedFormValues = {
    ...formValues,
    physicalAddress: {
      ...formValues.physicalAddress,
      ...physicalAddressData,
    },
  }
  setFormValues(updatedFormValues)
}

export const updateMultiObjectAddressInFormValues = (
  formValues: HospitalAndMedicalFacilityCareType,
  index: number,
  addressData: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  },
  setFormValues: Dispatch<SetStateAction<HospitalAndMedicalFacilityCareType>>,
  name:
    | 'haveEmergencyRoomVisitMedicalFacility'
    | 'haveMedicalFacilityMedicalFacility'
    | 'haveMentalHeathTreatmentMedicalFacility'
    | 'haveInpatientOrOverNightStayMedicalFacility'
    | 'haveOutpatientOrSamedayVisitMedicalFacility'
    | 'haveSurgeriesMedicalFacility'
    | 'havePhysicalTherapyMedicalFacility'
    | 'jailDoctorDetail'
    | 'welfareDetail'
    | 'attorneyDetail'
    | 'insuranceDetail'
    | 'rehabilitationOrganizationDetail'
    | 'workersCompensationDetail'
    | 'otherOrganizationDetail',
) => {
  const updatedFormValues = {
    ...formValues,
    [name]: [
      ...formValues[name]?.slice(0, index),
      {
        ...formValues[name][index],
        address: { ...addressData },
      },
      ...formValues[name].slice(index + 1),
    ],
  }
  setFormValues(updatedFormValues)
}

export const updateCurrentAddressInFormValues = (
  formValues: ContactInformationType,
  currentAddressData: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  },
  setFormValues: Dispatch<SetStateAction<ContactInformationType>>,
) => {
  const updatedFormValues = {
    ...formValues,
    currentAddress: {
      ...formValues.currentAddress,
      ...currentAddressData,
    },
  }
  setFormValues(updatedFormValues)
}

export const updateMailingAddressInFormValues = (
  formValues: ContactInformationType,
  mailingAddressData: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  },
  setFormValues: Dispatch<SetStateAction<ContactInformationType>>,
) => {
  const updatedFormValues = {
    ...formValues,
    mailingAddress: {
      ...formValues.mailingAddress,
      ...mailingAddressData,
    },
  }
  setFormValues(updatedFormValues)
}

export const updateAddressInFormValues = (
  formValues: PassApplicationType,
  index: number,
  addressData: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  },
  setFormValues: Dispatch<SetStateAction<PassApplicationType>>,
) => {
  let newParticipateProgramDetails = [...formValues.participateProgramDetails]
  newParticipateProgramDetails = newParticipateProgramDetails.map(
    (item: (typeof formValues.participateProgramDetails)[0], i: number) => {
      if (i === index) {
        return {
          ...item,
          address: {
            ...item.address,
            ...addressData,
          },
        }
      }
      return item
    },
  )
  const updatedFormValues = {
    ...formValues,
    participateProgramDetails: newParticipateProgramDetails,
  }
  setFormValues(updatedFormValues)
}

export const updateAddressInMedicalProfessionals = (
  formValues: MedicalProfessionalDetailType,
  index: number,
  addressData: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  },
  setFormValues: Dispatch<SetStateAction<MedicalProfessionalDetailType>>,
) => {
  let newMedicalProfessionals = [...formValues.medicalProfessionals]
  newMedicalProfessionals = newMedicalProfessionals.map(
    (item: (typeof formValues.medicalProfessionals)[0], i: number) => {
      if (i === index) {
        return {
          ...item,
          address: {
            ...item.address,
            ...addressData,
          },
        }
      }
      return item
    },
  )
  const updatedFormValues = {
    ...formValues,
    medicalProfessionals: newMedicalProfessionals,
  }
  setFormValues(updatedFormValues)
}

export const updateParentAddressInFormValues = (
  formValues: ParentType,
  whichParent: 'firstParent' | 'secondParent',
  addressData: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  },
  setFormValues: Dispatch<SetStateAction<ParentType>>,
) => {
  const updatedFormValues = {
    ...formValues,
    [`${whichParent}`]: {
      ...formValues?.[`${whichParent}`],
      address: addressData,
    },
  }

  setFormValues(updatedFormValues)
}

interface mapType {
  address: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  }
}

export const updateAddressValues = (
  formValues: Record<string, mapType[]>,
  index: number,
  arrayName: string,
  addressData: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  },
  setFormValues: Dispatch<
    SetStateAction<Record<string, mapType[] | undefined>>
  >,
) => {
  let newAddressData =
    formValues[arrayName] !== undefined ? [...formValues[arrayName]] : []
  newAddressData = newAddressData.map((item: mapType, i: number) => {
    if (i === index) {
      return {
        ...item,
        address: {
          ...item.address,
          ...addressData,
        },
      }
    }
    return item
  })
  const updatedFormValues = {
    ...formValues,
    [`${arrayName}`]: newAddressData,
  }
  setFormValues(updatedFormValues)
}
