// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import {
  MessagePayload,
  Messaging,
  getToken,
  onMessage,
} from 'firebase/messaging'
import secureLocalStorage from 'react-secure-storage'

let tried = 0

export const requestForToken = async (messaging: Messaging) => {
  // return getToken(messaging, { vapidKey: `REPLACE_WITH_YOUR_VAPID_KEY` })
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        secureLocalStorage.setItem('deviceToken', currentToken)
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.',
        )
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      if (tried < 5) {
        tried = tried + 1
        setTimeout(() => {
          requestForToken(messaging)
        }, 2000)
      }
    })
}

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = async (messaging: Messaging) =>
  new Promise((resolve: (payload: MessagePayload) => void) => {
    onMessage(messaging, (payload: MessagePayload) => {
      resolve(payload)
    })
  })
