// This slice manages the state for the sign in feature
import {
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { LegalDetailsType } from 'src/Redux/Services/Types/applicationType'
import { RootState, thunkAPItype } from 'src/Redux/store'
import { handleResponse } from 'src/Utils/Constants'
import LegalDetailsService from '../../Services/LegalDetailsService'
import secureLocalStorage from 'react-secure-storage'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: LegalDetailsType
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {
    LegalName: {
      legalId: '',
      applicationId: '',
      haveUsedOtherName: undefined,
      legalNames: [
        {
          firstName: '',
          middleName: '',
          lastName: '',
        },
      ],
    },
    IdentificationNumbers: {
      legalId: '',
      socialSecurityNumber: '',
      otherSocialSecurityNumbers: [''],
      isUsingOtherSSN: undefined,
    },
    ReceivingDisability: {
      legalId: '',
      isHavingBankAccount: undefined,
      accountType: '',
      routingNumber: '',
      accountNumber: '',
    },
  },
}

// Define the reducers that can modify the state
const LegalDetails = createSlice({
  name: 'LegalDetails',
  initialState,
  reducers: {
    setLegalDetailsLoading: (state) => {
      state.loading = true
    },
    setLegalDetailsSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setLegalDetailsFail: (state) => {
      state.success = false
      state.loading = false
      state.data = initialState.data
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AddLegalNames.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddLegalNames.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddLegalNames.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(EditLegalNames.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      EditLegalNames.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(EditLegalNames.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddIdentificationNumbers.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddIdentificationNumbers.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddIdentificationNumbers.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddReceivingDisability.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddReceivingDisability.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddReceivingDisability.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

export const {
  setLegalDetailsLoading,
  setLegalDetailsSuccess,
  setLegalDetailsFail,
} = LegalDetails.actions

export const AddLegalNames = createAsyncThunk(
  'LegalNames', // name of the action
  async (data: LegalDetailsType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await LegalDetailsService.AddLegalNames(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditLegalNames = createAsyncThunk(
  'EditLegalNames', // name of the action
  async (
    data: LegalDetailsType | any,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await LegalDetailsService.EditLegalNames(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddIdentificationNumbers = createAsyncThunk(
  'IdentificationNumbers', // name of the action
  async (data: LegalDetailsType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await LegalDetailsService.AddIdentificationNumbers(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddReceivingDisability = createAsyncThunk(
  'ReceivingDisability', // name of the action
  async (data: LegalDetailsType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await LegalDetailsService.AddReceivingDisability(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default LegalDetails.reducer
