/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import usePlacesAutocomplete from 'src/Components/CommonAddress/newLocation' // Assuming you have your hook in a separate file
import { parseAddressComponents } from 'src/Utils/Constants'

const PlacesAutocompleteTextField: React.FC = () => {
  const [addressObj, setAddressObj] = useState({
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  })
  const {
    inputRef,
    predictions,
    addressComponents,
    handleInputChange,
    handlePredictionClick,
  } = usePlacesAutocomplete()

  useEffect(() => {
    // Usage:
    const addressObj = parseAddressComponents(addressComponents)
    setAddressObj(addressObj)
  }, [addressComponents])
  return (
    <div>
      <TextField
        label='Search Places'
        inputRef={inputRef}
        onChange={handleInputChange}
        fullWidth
      />
      <ul>
        {predictions.map(
          (
            prediction: {
              description:
                | string
                | number
                | boolean
                | React.ReactElement<
                    any,
                    string | React.JSXElementConstructor<any>
                  >
                | Iterable<React.ReactNode>
                | React.ReactPortal
                | null
                | undefined
            },
            index: React.Key | null | undefined,
          ) => (
            <li
              key={index}
              onClick={() => {
                handlePredictionClick(prediction?.description as string)
              }}
            >
              {prediction.description}
            </li>
          ),
        )}
      </ul>
      {addressComponents.length > 0 && (
        <div>
          <h3>Address Components</h3>
          <ul>
            {addressComponents.map(
              (
                component: { types: any[]; long_name: any },
                index: React.Key | null | undefined,
              ) => (
                <li
                  key={index}
                >{`${component.types.join(', ')}: ${component.long_name}`}</li>
              ),
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

export default PlacesAutocompleteTextField
