import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import Styles from '../index.module.scss'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { hasEmpty, convertISOToDate } from 'src/Utils/Constants'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import TradeAndTraining from '../EditForms/TradeAndTraining'

const EducationDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)
  const educationRef = useRef<HTMLDivElement>(null)
  const handleEditToggle = () => {
    setEditMode(!editMode)
    educationRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.EducationAndEmployement?.TradeAndTraining,
  )

  const specializedTradeEducationTrainingSectionId = useAppSelector(
    (state) =>
      state?.steps?.EducationEmploymentApplicationSteps?.find(
        (step: { label: string }) =>
          step.label === 'Specialized Trade Education & Training',
      )?.sectionId,
  )
  return (
    <div id={specializedTradeEducationTrainingSectionId} ref={educationRef}>
      {!editMode ? (
        <>
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  Specialized Trade Education & Training
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>
            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Completed any type of specialized job training,
                        including trade or vocational school
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography className={Styles.textWrapper2}>
                        {data?.haveSpecializedTraining ? 'yes' : 'No'}
                      </Typography>
                    </Box>
                  </Box>
                  {data?.haveSpecializedTraining && (
                    <>
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Type of Specialized Training
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.typeOfSpecializedTraining}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Start Date
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {convertISOToDate(data?.startDate)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={Styles.element}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            End Date
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.endDate?.toString().length !== 0
                              ? convertISOToDate(data?.endDate)
                              : 'Presently Working'}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            School Name
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.schoolName}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={Styles.element}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            City/Town
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.city}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            State
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.state}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={Styles.element}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Country
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.country}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Specialized Trade Education & Training
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <TradeAndTraining handleEditToggle={handleEditToggle} />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default EducationDetails
