import { Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CustomStepper from 'src/Components/Application/CustomStepper'
import Citizenship from './Citizenship'
import CommunicationPreference from './CommunicationPreference'
import ContactInformation from './ContactInformation'
import MilitaryService from './MilitaryService'
import PassApplication from './PassApplication'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { setBasicDetailsStep } from 'src/Redux/Slices/ApplicationStore/ApplicationStepStore'
const BasicDetails = ({
  chatForm,
  setChatForm,
}: {
  chatForm?: any
  setChatForm?: any
}) => {
  const formComponents = [
    {
      title: 'Contact Information',
      content: ContactInformation,
    },
    {
      title: 'Communication Preference',
      content: CommunicationPreference,
    },
    {
      title: 'Citizenship',
      content: Citizenship,
    },
    {
      title: 'Military Service',
      content: MilitaryService,
    },
    {
      title: 'Pass Application',
      content: PassApplication,
    },
  ]

  const dispatch = useAppDispatch()
  const location = useLocation()
  const currentPath = location.pathname
  const myRef = useRef<HTMLDivElement>(null)
  const totalSteps = formComponents.length
  // const [activeStep, setActiveStep] = useState<number>(0)

  const BasicDetailsStep = useAppSelector(
    (state) => state?.applicationStore?.ApplicationStep?.BasicDetailsStep,
  )

  const [activeStep, setActiveStep] = useState(
    BasicDetailsStep ? parseInt(BasicDetailsStep) : 0,
  )

  useEffect(() => {
    // localStorage.setItem('BasicDetails', activeStep.toString())
    dispatch(setBasicDetailsStep(activeStep.toString()))
  }, [activeStep, currentPath])

  useEffect(() => {
    setChatForm(false)
  }, [])

  const handleNext = () => {
    setChatForm(false)
    setActiveStep((prevActiveStep) =>
      prevActiveStep < totalSteps - 1 ? prevActiveStep + 1 : prevActiveStep,
    )
    myRef?.current?.scrollIntoView()
  }

  const handleBack = () => {
    setChatForm(false)
    setActiveStep((prevActiveStep) =>
      prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep,
    )
    myRef?.current?.scrollIntoView()
  }

  const FormToShow = formComponents[activeStep].content // Get the form component based on the active step
  return (
    <>
      <CustomStepper
        myRef={myRef}
        formComponents={formComponents}
        activeStep={activeStep}
        handleNext={handleNext}
        handleBack={handleBack}
      />
      <Box width='100%'>
        <FormToShow
          chatForm={chatForm}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      </Box>
    </>
  )
}

export default BasicDetails
