import React from 'react'
import { Box, Typography, Button, Grid } from '@mui/material'
// import error from 'src/Assets/Icons/error.svg'
import Styles from './index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { hasEmpty } from 'src/Utils/Constants'

interface ViewScreenProps {
  onEditClick: () => void
  ref: React.RefObject<HTMLDivElement>
}

const Identification: React.FC<ViewScreenProps> = ({
  onEditClick,
  ref: identificationRef,
}) => {
  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.LegalDetails?.IdentificationNumbers,
  )

  const identificationNumbersSectionId = useAppSelector(
    (state) =>
      state?.steps?.LegalDocApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Identification Numbers',
      )?.sectionId,
  )
  return (
    <div id={identificationNumbersSectionId} ref={identificationRef}>
      {/* Identification Numbers */}
      <Box className={Styles.MainBox}>
        <Box className={Styles.title}>
          <Box className={Styles.main}>
            <Typography variant='h6' className={Styles.textWrapper}>
              Identification Numbers
            </Typography>
            <Button
              onClick={onEditClick}
              disabled={hasEmpty(data)}
              className={Styles.button}
            >
              <Typography className={Styles.div}>Edit</Typography>
            </Button>
          </Box>
          <div className={Styles.dottedLine} />
        </Box>
        {!hasEmpty(data) ? (
          <>
            <Box className={Styles.div2}>
              <Box className={Styles.element}>
                <Box className={Styles.question}>
                  <Typography className={Styles.p}>
                    Social Security Number (SSN)
                  </Typography>
                </Box>
                <Box className={Styles.ans}>
                  <Typography className={Styles.textWrapperAns}>
                    {data?.socialSecurityNumber}
                  </Typography>
                  {/* <img
                    src={error}
                    alt='threeDot'
                    style={{ marginRight: '10px' }}
                  /> */}
                </Box>
              </Box>
              <Box className={Styles.element2}>
                <Box className={Styles.question}>
                  <Typography className={Styles.textWrapper3}>
                    Used any other social security number (SSN)
                  </Typography>
                </Box>
                <Box className={Styles.ans}>
                  <Typography className={Styles.textWrapperAns}>
                    {' '}
                    {data?.isUsingOtherSSN ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {data?.isUsingOtherSSN ? (
              <>
                {data?.otherSocialSecurityNumbers.length ? (
                  <>
                    <Box className={Styles.div2}>
                      <Typography variant='h6' className={Styles.SubTitle}>
                        Additional Social Security Numbers (SSN)
                      </Typography>
                    </Box>
                  </>
                ) : null}

                <Grid item xs={12} gap={2} display={'flex'}>
                  {data?.otherSocialSecurityNumbers?.map((item: string) => (
                    <>
                      <Box className={Styles.cardDivBox}>
                        <Grid container className={Styles.cardFrame}>
                          <Grid item xs={12} className={Styles.cardElement}>
                            <Box className={Styles.cardQuestion}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapper}
                              >
                                Social Security Number (SSN)
                              </Typography>
                            </Box>
                            <Box className={Styles.cardAns}>
                              <Typography
                                variant='body1'
                                className={Styles.cardTextWrapperAns}
                              >
                                {item}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ))}
                </Grid>
              </>
            ) : null}
          </>
        ) : (
          <CustomAlert message='no data available.' />
        )}
      </Box>
    </div>
  )
}

export default Identification
