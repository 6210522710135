import { baseAPI } from '../Common/httpCommon'
import { PermissionType } from './Types/permissionType'
import { type RoleType } from './Types/roleType'

const viewPermission = async (token: string) => {
  try {
    return await baseAPI.get<RoleType>('permission/get', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const viewPermissionDetail = async (data: string, token: string) => {
  try {
    return await baseAPI.get(`permission/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const addPermission = async (data: FormData, token: string) => {
  try {
    return await baseAPI.post('permission/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const editPermission = async (
  uuid: string,
  data: PermissionType,
  token: string,
) => {
  try {
    return await baseAPI.put(`permission/${uuid}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const deletPermission = async (data: string, token: string) => {
  try {
    return await baseAPI.delete(`permission/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const PermissionService = {
  viewPermission,
  viewPermissionDetail,
  addPermission,
  editPermission,
  deletPermission,
}
export default PermissionService
