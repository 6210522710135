import { Grid, Button, Typography, Box } from '@mui/material'
import Logo from 'src/Assets/Images/logo_white.svg'
import Done from 'src/Assets/Icons/done.svg'
import styles from './index.module.scss'
import { STEPS } from 'src/Utils/Constants'

interface stepType {
  description: string
  label: string
  title: string
}

const SideSteps = ({ active }: { active: string }) => {
  const actNo = Object.keys(STEPS).indexOf(active)
  return (
    <Grid item xs={12} className={styles.container}>
      <img className={styles.logo} alt='Logo' src={Logo} />
      <Grid item xs={12} className={styles.main}>
        {Object.values(STEPS).map((step: stepType, index: number) => (
          <>
            <Button data-testid={step.title} className={styles.element}>
              <Grid
                item
                xs={12}
                className={
                  active === step.title ? styles.counter : styles.divWrapper
                }
              >
                <Typography className={styles.textWrapper}>
                  {actNo <= index ? index + 1 : <img src={Done} alt='v' />}
                </Typography>
              </Grid>
              <Grid item xs={12} className={styles.title}>
                <Typography className={styles.div}>{step.label}</Typography>
                <Typography className={styles.textWrapper2}>
                  {step.description}
                </Typography>
              </Grid>
            </Button>
            {index !== Object.keys(STEPS).length - 1 && (
              <Box className={styles.lineWrapper}>
                <span className={styles.linex}></span>
              </Box>
            )}
          </>
        ))}
      </Grid>
    </Grid>
  )
}

export default SideSteps
