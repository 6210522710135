import { Box } from '@mui/material'
import React from 'react'
import styles from '../index.module.scss'
import Cards from './Cards'

export const Applications = ({
  ApplicationsData,
}: {
  ApplicationsData: Array<{
    name: string
    basicInfo: {
      emailId: string
      phoneNumber: string
      firstName: string
      address: {
        streetAddress: string
      }
    }
    status: string
  }>
}): JSX.Element => {
  const [active, setActive] = React.useState(0)

  const tabs = [
    'All Applications',
    'Pending',
    'In Progress',
    'Completed',
    'Rejected',
    'Flagged',
  ]
  const filteredData = (filter: number) =>
    ApplicationsData?.filter((i) => {
      switch (filter) {
        case 0:
          return i
        case 1:
          return i.status === 'Pending'
        case 2:
          return i.status === 'InProgress' ? 'In Progress' : i
        case 3:
          return i.status === 'Completed'
        case 4:
          return i.status === 'Rejected'
        case 5:
          return i.status === 'Flagged'
        default:
          return i
      }
    })

  return (
    <>
      <Box className={styles.tabs}>
        {tabs.map((i, index) => (
          <Box
            key={index}
            onClick={() => {
              setActive(index)
            }}
            className={active === index ? styles.active : styles.inActive}
          >
            <Box className={styles.tabText}>
              {`${i} (${filteredData(index)?.length})`}
            </Box>
          </Box>
        ))}
      </Box>
      <Box className={styles.box}>
        {filteredData(active)?.map((row, idx) => (
          <Cards key={idx} row={row} />
          // <TableRow key={idx}>
          //   <TableCell component='th' scope='row'>
          //     {row.ASSIGNED_BY}
          //   </TableCell>
          //   <TableCell>
          //     <Box className={styles.timeWrapper}>{row.TIME}</Box>
          //   </TableCell>
          //   <TableCell>{row.APPLICATION_TYPE}</TableCell>
          //   <TableCell>
          //     <Box
          //       className={
          //         row.STATUS === 'In Progress'
          //           ? styles.progressText
          //           : styles[row.STATUS]
          //       }
          //     >
          //       {row.STATUS}
          //     </Box>
          //   </TableCell>
          // </TableRow>
        ))}
      </Box>
    </>
  )
}
