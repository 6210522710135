import { baseAPI } from '../Common/httpCommon'
import { EducationType } from './Types/applicationType'

const AddEducation = async (
  data: EducationType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.post('application/education/education', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditEducation = async (
  data: EducationType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/education/education', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditSpecialEducation = async (
  data: EducationType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/education/special-education', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditTradeAndTraining = async (
  data: any,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/education/trade-and-training', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditEmployerDetails = async (
  data: any,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/education/employer-details', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditJobDetails = async (data: any, token: string, isPublic: string) => {
  try {
    return await baseAPI.put('application/education/job-details', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditChildCare = async (data: any, token: string, isPublic: string) => {
  try {
    return await baseAPI.put('application/education/child-care', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditSelfEmployement = async (
  data: any,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/education/self-employment', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditForeignWork = async (data: any, token: string, isPublic: string) => {
  try {
    return await baseAPI.put('application/education/foreign-work', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditWorkTaxDetails = async (
  data: any,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/education/work-tax-details', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EducationAndEmploymentService = {
  AddEducation,
  EditEducation,
  EditSpecialEducation,
  EditTradeAndTraining,
  EditEmployerDetails,
  EditJobDetails,
  EditChildCare,
  EditSelfEmployement,
  EditForeignWork,
  EditWorkTaxDetails,
}
export default EducationAndEmploymentService
