import {
  Box,
  Button,
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import Plus from 'src/Assets/Icons/Plus.svg'
import Search from 'src/Assets/Icons/Search.svg'
import Breadcrumb from 'src/Components/BreadCrumb'
import AddEdit from 'src/Components/SubscriptionPlan/AddEditBox'
import { SubscriptionTable } from 'src/Components/SubscriptionPlan/TableList'
import { SubscriptionListRequest } from 'src/Redux/Slices/subscriptionSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ROUTES } from 'src/Utils/Routes'
import Style from './index.module.scss'
import { DynamicObj } from 'src/Utils/Constants'

const SubscriptionPlan: React.FunctionComponent = () => {
  const [openAdd, setOpenAdd] = React.useState(false)
  const [q, setQ] = React.useState('')

  const dispatch = useAppDispatch()
  const subscriptionList = useAppSelector((state) => state.subscription.data)
  const handleAdd = () => {
    setOpenAdd(!openAdd)
  }

  function find(items: []) {
    return items?.filter((item: DynamicObj) => {
      return [
        'name',
        'description',
        'amount',
        'status',
        'interval',
        'createdAt',
        'plan',
      ].some((newItem: string) => {
        return item[newItem]?.toString().toLowerCase().includes(q.toLowerCase())
      })
    })
  }

  useEffect(() => {
    dispatch(SubscriptionListRequest())
  }, [])
  return (
    <>
      <Box className={Style.Main}>
        <Box className={Style.Head}>
          <Breadcrumb
            title='Manage Subscription Plans'
            Menus={[
              { name: 'Subscription Plan', route: ROUTES.SUBSCRIPTION_PLAN },
            ]}
          />
          <Box className={Style.Action}>
            <Box className={Style.Left}>
              <Typography className={Style.HeadText}>
                Subscriptions ({subscriptionList.length})
              </Typography>
              <Box className={Style.SearchBox}>
                <img src={Search} alt='Search' />
                <Input
                  disableUnderline
                  className={Style.SearchEm}
                  placeholder='Search subscriptions'
                  type='text'
                  value={q}
                  onChange={(e: { target: { value: string } }) => {
                    setQ(e.target.value)
                  }}
                />
              </Box>
            </Box>
            <Box className={Style.Right}>
              <Button
                className={Style.Add}
                variant='contained'
                onClick={handleAdd}
              >
                <img src={Plus} alt='Plus' style={{ marginRight: '8px' }} />
                Add Subscription Plan
              </Button>
            </Box>
          </Box>
        </Box>
        <Grid className={Style.ListWrapper}>
          <Grid className={Style.ListGrid}>
            <Box className={Style.Table}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell width={'15%'} className={Style.TblText}>
                      PLAN NAME
                    </TableCell>
                    <TableCell width={'30%'} className={Style.TblText}>
                      DESCRIPTION
                    </TableCell>
                    <TableCell className={Style.TblText}>AMOUNT</TableCell>
                    <TableCell className={Style.TblText}>INTERVAL</TableCell>
                    <TableCell className={Style.TblText}>
                      CREATED DATE
                    </TableCell>
                    <TableCell className={Style.TblText}>STATUS</TableCell>
                    <TableCell
                      width={'1%'}
                      className={Style.TblText}
                      align='right'
                    >
                      ACTION
                    </TableCell>
                  </TableRow>
                  {subscriptionList?.length &&
                    find(subscriptionList)?.map(
                      (
                        item: {
                          id: string
                          name: string
                          plan: string
                          amount: number
                          subscriptionPeriod: number
                          status: string
                          createdAt: string
                          description: string
                          isDefault: boolean
                        },
                        index: React.Key,
                      ) => {
                        return (
                          <TableRow
                            key={index}
                            className={
                              item.status === 'active'
                                ? Style.active
                                : Style.suspended
                            }
                          >
                            <SubscriptionTable {...item} />
                          </TableRow>
                        )
                      },
                    )}
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {openAdd && (
        <AddEdit type='add' openEdit={openAdd} handleCloseEdit={handleAdd} />
      )}
    </>
  )
}

export default SubscriptionPlan
