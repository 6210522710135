import React, { useEffect, useState } from 'react'
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  Box,
  Typography,
  DialogContent,
  TextField,
  Checkbox,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  SelectChangeEvent,
  // IconButton,
} from '@mui/material'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { EditRoleRequest, ViewRoleRequest } from 'src/Redux/Slices/roleSlice'
import { ViewPermissionRequest } from 'src/Redux/Slices/permissionManageSlice'
import styled from 'styled-components'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'

interface DataType {
  name: string
  scope: string
  description: string
  addedPermissions: string[]
  removedPermissions: string[]
}
interface permissionType {
  id: string
  name: string
  scope: string
  description: string
  permissions: string[]
}

const StyledSelect = styled(Select)({
  borderRadius: '10px', // Set the border radius to your desired value
  // Add any other custom styles here
})
const PopupForm = ({
  roleId,
  Editopen,
  setEditOpen,
  handleEditOpen,
}: {
  roleId: string
  Editopen: boolean
  setEditOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleEditOpen: () => void
}) => {
  const dispatch = useAppDispatch()
  const permissionList = useAppSelector(
    (state) => state.permissionManage?.permissionList?.data || [],
  )

  const Roles = useAppSelector((state) => state.roles?.roleDetails?.data)

  const handleClose = () => {
    setEditOpen(false)
  }
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    // You may perform validation here and update errors state accordingly
  }

  const initialCheckedState = Array.isArray(permissionList)
    ? permissionList.map((item: { id: string }) =>
        Roles?.permission?.includes(item.id),
      )
    : []
  const [checked1, setChecked1] = useState<boolean[]>(initialCheckedState)

  const [formValues, setFormValues] = useState<DataType>({
    name: '',
    description: '',
    scope: Roles?.scope ?? '',
    addedPermissions: [],
    removedPermissions: [],
  })

  const [filteredPermissionList, setFilteredPermissionList] = useState<
    permissionType[]
  >(
    permissionList?.length
      ? permissionList?.filter((item: { scope: string }) => {
          return item.scope === formValues.scope || item.scope === 'Both'
        })
      : [],
  )

  useEffect(() => {
    permissionList?.length > 0 &&
      setFilteredPermissionList(
        permissionList?.filter((item: { scope: string }) => {
          return item.scope === formValues.scope || item.scope === 'Both'
        }),
      )
  }, [formValues.scope, roleId, Roles])

  useEffect(() => {
    permissionList?.length > 0 &&
      setFilteredPermissionList(
        permissionList?.filter((item: { scope: string }) => {
          return item.scope === formValues.scope || item.scope === 'Both'
        }),
      )
  }, [])

  const handleCheckboxChange1 = (index: number) => {
    setChecked1((prev) => {
      const newChecked1 = [...prev]
      newChecked1[index] = !newChecked1[index]
      return newChecked1
    })
    const permissionId = filteredPermissionList?.[index].id

    if (checked1[index]) {
      if (formValues.addedPermissions.includes(permissionId)) {
        setFormValues({
          ...formValues,
          addedPermissions: formValues.addedPermissions.filter(
            (id) => id !== permissionId,
          ),
        })
      } else if (
        !formValues.removedPermissions.includes(permissionId) &&
        filteredPermissionList?.map((i: { id: string }) => {
          return !!Roles?.permission?.includes(i.id)
        })[index]
      ) {
        setFormValues({
          ...formValues,
          removedPermissions: [...formValues.removedPermissions, permissionId],
        })
      }
    } else {
      if (formValues.removedPermissions.includes(permissionId)) {
        setFormValues({
          ...formValues,
          removedPermissions: formValues.removedPermissions.filter(
            (id) => id !== permissionId,
          ),
        })
      } else if (
        !formValues.addedPermissions.includes(permissionId) &&
        !filteredPermissionList?.map((i: { id: string }) => {
          return !!Roles?.permission?.includes(i.id)
        })[index]
      ) {
        setFormValues({
          ...formValues,
          addedPermissions: [...formValues.addedPermissions, permissionId],
        })
      }
    }
  }

  useEffect(() => {
    if (Roles && roleId) {
      setFormValues({
        name: Roles?.name ?? '',
        scope: Roles?.scope ?? '',
        description: Roles?.description ?? '',
        addedPermissions: [],
        removedPermissions: [],
      })

      if (Array.isArray(filteredPermissionList)) {
        setChecked1(
          filteredPermissionList?.map((i: { id: string }) => {
            return Roles?.permission?.includes(i.id)
          }),
        )
      } else {
        setChecked1([])
      }
    }
  }, [roleId, Roles, permissionList])

  useEffect(() => {
    if (Array.isArray(filteredPermissionList)) {
      setChecked1(
        filteredPermissionList?.map((i: { id: string }) => {
          return Roles?.permission?.includes(i.id)
        }),
      )
    } else {
      setChecked1([])
    }
  }, [filteredPermissionList, Roles])

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (!formValues.name.trim()) {
      newErrors.name = 'Role Name is required'
      valid = false
    }
    if (!formValues.scope.trim()) {
      newErrors.scope = 'Scope is required'
      valid = false
    }
    if (!formValues.description.trim()) {
      newErrors.description = 'Description is required'
      valid = false
    }
    // if (!formValues.permissions.length) {
    //   newErrors.permissions = 'Permissions is required'
    //   valid = false
    // }

    setErrors(newErrors)
    return valid
  }

  const handleScopeChange = (e: SelectChangeEvent<string>) => {
    if (e.target.value !== Roles?.scope) {
      const removing = Roles?.permission
        .map((item: string) => {
          if (
            permissionList.find(
              (i: { id: string; scope: string }) =>
                i.id === item && i.scope !== 'Both',
            )
          ) {
            return item
          }
          return null
        })
        .filter((item: null) => item !== null)
      setFormValues({
        ...formValues,
        removedPermissions: removing,
        scope: e.target.value,
        addedPermissions: [],
      })
    } else {
      setFormValues({
        ...formValues,
        removedPermissions: [],
        scope: e.target.value,
        addedPermissions: [],
      })
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      dispatch(
        EditRoleRequest({
          uuid: roleId,
          data: formValues,
        }),
      ).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          dispatch(ViewRoleRequest())
          dispatch(ViewPermissionRequest())
        },
      )
      setEditOpen(false)
      // dispatch(ViewRoleRequest())
    } else setEditOpen(true)
  }
  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={Editopen}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Edit Role
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='threeDot'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={Style.DialogContentBox}>
              <form onSubmit={handleSubmit}>
                <Box width={'100%'}>
                  <InputLabel className={Style.label}>
                    Role Name <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='text'
                    id='name'
                    name='name'
                    className={Style.AnsTxt}
                    placeholder='Role Name'
                    value={formValues.name}
                    onChange={handleFieldChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Box>

                <Box width={'100%'}>
                  <InputLabel className={Style.label} htmlFor='scope'>
                    Scope <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <StyledSelect
                    onChange={(e) => {
                      handleScopeChange(e)
                      setChecked1([])
                      setFilteredPermissionList([])
                    }}
                    className={Style.AnsTxt}
                    IconComponent={(props) => (
                      <IconButton {...props}>
                        <img src={IconChevronDown} alt='IconChevronDown' />
                      </IconButton>
                    )}
                    displayEmpty
                    renderValue={
                      formValues.scope !== ''
                        ? () => formValues.scope
                        : () => 'Select'
                    }
                    value={formValues.scope}
                    fullWidth
                    id='scope'
                    name='scope'
                    placeholder='Scope'
                    error={!!errors.scope}
                  >
                    <MenuItem value='Admin'>Admin</MenuItem>
                    <MenuItem value='Legal'>Legal</MenuItem>
                  </StyledSelect>
                </Box>

                <Box width={'100%'} pt={1}>
                  <InputLabel className={Style.label} htmlFor='Description'>
                    Description <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='text'
                    id='description'
                    name='description'
                    placeholder='Description'
                    multiline
                    rows={4}
                    className={Style.AnsTxt}
                    value={formValues.description}
                    onChange={handleFieldChange}
                    error={!!errors.description}
                    helperText={errors.description}
                  />
                </Box>
                <Box pt={1}>
                  <InputLabel className={Style.label}>
                    Select Modules to Assign the Permissions{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <Grid xs={12} className={Style.Checkbox}>
                    {permissionList?.length &&
                      filteredPermissionList?.map(
                        (item: permissionType, index: number) => {
                          return (
                            <Box key={index} className={Style.CheckGrid}>
                              <label
                                htmlFor={`checkbox-${index}`}
                                className={Style.CheckLabel}
                              >
                                <Checkbox
                                  checked={checked1[index] || false}
                                  onChange={() => {
                                    handleCheckboxChange1(index)
                                  }}
                                  className={Style.Check}
                                  id={`checkbox-${index}`}
                                />
                                <Typography className={Style.CheckText}>
                                  {item?.name}
                                </Typography>
                              </label>
                            </Box>
                          )
                        },
                      )}
                  </Grid>
                </Box>
                <DialogActions>
                  <Box className={Style.AddButton}>
                    <Button
                      variant='contained'
                      type='submit'
                      className={Style.Button}
                    >
                      Save
                    </Button>
                  </Box>
                </DialogActions>
              </form>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default PopupForm
