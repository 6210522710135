import { baseSecureAPI } from '../Common/httpCommon'
import { DynamicObj, getAccessToken } from 'src/Utils/Constants/index'

const getAllApplicationList = async (filter: DynamicObj) => {
  try {
    let setFilter = ''
    if (filter.sort ?? filter.status) {
      setFilter = '?'
      if (filter.status) {
        setFilter += `status=${filter.status.replace(/ +/g, '')}&`
      }
      if (filter.sort) {
        setFilter += `sort=${filter.sort}&`
      }
      if (filter.assignee) {
        setFilter += `assignee=${filter.assignee}&`
      }
    }
    return await baseSecureAPI.get<any>(`application/list${setFilter}`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  } catch (e: any) {
    return e
  }
}
const getApplicationDetail = async (
  applicationId: string,
  isPublic: string,
) => {
  try {
    return await baseSecureAPI.get(`application/${applicationId}`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        'is-public': isPublic,
        'Content-type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const getAllApplicationEmployeeList = async () => {
  try {
    return await baseSecureAPI.get(`application/employee-list`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const AssignEmployeeToApplication = async (object: DynamicObj) => {
  try {
    return await baseSecureAPI.post(`application/assign-employee`, object, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const AllApplicationservice = {
  getAllApplicationList,
  getApplicationDetail,
  getAllApplicationEmployeeList,
  AssignEmployeeToApplication,
}
export default AllApplicationservice
