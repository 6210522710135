// This slice manages the state for the sign in feature
import {
  PayloadAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { RoleType } from '../Services/Types/roleType'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import RoleService from '../Services/roleService'
import { thunkAPItype } from '../store'
import { handleResponse } from 'src/Utils/Constants'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: RoleType
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {},
}

// Define the reducers that can modify the state
const RolePermissionDetails = createSlice({
  name: 'RolePermissionDetails',
  initialState,
  reducers: {
    setRolePermissionLoading: (state) => {
      state.loading = true
    },
    setRolePermissionSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setRolePermissionFail: (state) => {
      state.success = false
      state.loading = false
      state.data = {}
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(ViewRolePermissionList.pending, (state) => {
    //   state.success = false
    //   state.loading = true
    // })
    // builder.addCase(
    //   ViewRolePermissionList.fulfilled,
    //   (state, action: PayloadAction<stateType>) => {
    //     state.success = action.payload?.success
    //     state.loading = false
    //     state.data = action.payload?.data
    //   },
    // )
    // builder.addCase(ViewRolePermissionList.rejected, (state) => {
    //   state.success = false
    //   state.loading = false
    // })

    builder.addCase(ViewRolePermissionDetail.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewRolePermissionDetail.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(ViewRolePermissionDetail.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

// Define the async thunk action creator for sign in
export const ViewRolePermissionDetail = createAsyncThunk(
  'ViewRolePermissionDetail', // name of the action
  async (data: string, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await RoleService.viewRolePermissionDetails(data, token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

// export const ViewRolePermissionList = createAsyncThunk(
//   'ViewRolePermissionList', // name of the action
//   async (_, thunkAPI: thunkAPItype) => {
//     try {
//       const token = thunkAPI.getState().auth.data?.token
//       const response = await RoleService.viewRolePermission(token) // make the API call
//       return response.data // explicitly return the fetched data
//     } catch (err) {
//       console.error('Error while authentication:', err)
//       throw err // re-throw to allow error handling in components
//     }
//   },
// )

export default RolePermissionDetails.reducer
export const {
  setRolePermissionLoading,
  setRolePermissionSuccess,
  setRolePermissionFail,
} = RolePermissionDetails.actions
