import { baseAPI } from '../Common/httpCommon'
import {
  CitizenshipType,
  CommunicationPreferenceType,
  ContactInformationType,
  MilitaryServiceType,
  PassApplicationType,
} from './Types/applicationType'

const AddContactInformation = async (
  data: ContactInformationType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.post('application/basic/contact-information', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditContactInformation = async (
  data: ContactInformationType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/basic/contact-information', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddCommunicationPreference = async (
  data: CommunicationPreferenceType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/basic/communication', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddCitizenship = async (
  data: CitizenshipType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/basic/citizenship', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddMilitaryService = async (
  data: MilitaryServiceType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/basic/military-service', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddPassApplication = async (
  data: PassApplicationType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/basic/past-application', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const BasicDetailsService = {
  AddContactInformation,
  EditContactInformation,
  AddCommunicationPreference,
  AddCitizenship,
  AddMilitaryService,
  AddPassApplication,
}
export default BasicDetailsService
