import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  Input,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import dot from 'src/Assets/Icons/Action.svg'
import Search from 'src/Assets/Icons/Search.svg'
import AddMember from 'src/Components/Employees/AddMember'
import { ROUTES } from 'src/Utils/Routes'
import Style from './index.module.scss'
// import EditRole from 'src/Components/Employees/EditRole'
import { ViewRolePermissionDetail } from 'src/Redux/Slices/rolePermissionDetails'
// import { DeletRoleRequest } from 'src/Redux/Slices/roleSlice'
import Breadcrumb from 'src/Components/BreadCrumb'
import { ViewRolePermissionList } from 'src/Redux/Slices/rolePermission'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { DynamicObj } from 'src/Utils/Constants'

interface RoleData {
  Role_uuid: string
  Role_name: string
  Role_description: string
  associatedusers: []
}
const RolePermission: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false)
  // const [Editopen, setEditOpen] = useState(false)

  // const [id, setId] = useState('')

  const dispatch = useAppDispatch()

  const handleOpen = () => {
    setOpen(true)
  }
  // const handleEditOpen = () => {
  //   setEditOpen(true)
  // }
  const RoleList = useAppSelector(
    (state) => state.roles?.RolePermissionList?.data,
  )

  useEffect(() => {
    dispatch(ViewRolePermissionList())
  }, [])
  const navigate = useNavigate()
  const handleDetails = (id: string) => {
    navigate('/role/details', { state: { id } })
  }

  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  // const ActionOpen = Boolean(anchorEl)
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget)
  // }
  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  const [q, setQ] = React.useState('')

  function find(items: DynamicObj) {
    return items?.filter((item: DynamicObj) => {
      return ['Role_name', 'Role_description'].some((newItem) => {
        return item[newItem]?.toString().toLowerCase().includes(q.toLowerCase())
      })
    })
  }
  return (
    <>
      <Box className={Style.Main}>
        <Box className={Style.Head}>
          <Breadcrumb
            title='Roles & Permissions'
            Menus={[{ name: 'Roles & Permissions', route: ROUTES.ROLE }]}
          />
          <Box className={Style.Action}>
            <Box className={Style.Left}>
              <Box className={Style.SearchBox}>
                <img src={Search} alt='Search' />
                <Input
                  disableUnderline
                  className={Style.SearchEm}
                  placeholder='Search'
                  type='text'
                  value={q}
                  onChange={(e: { target: { value: string } }) => {
                    setQ(e.target.value)
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={Style.div}>
          {RoleList?.length &&
            find(RoleList)?.map((data: RoleData, index: number) => (
              <Grid item xs={12} key={index} className={Style.whiteContainer}>
                <Typography variant='h5' className={Style.div1}>
                  {data?.Role_name}
                </Typography>
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(ROUTES.ROLE_DETAILS)
                    handleDetails(data?.Role_uuid)
                    dispatch(ViewRolePermissionDetail(data?.Role_uuid))
                  }}
                >
                  <Typography variant='body1' className={Style.div6}>
                    {data?.Role_description}
                  </Typography>
                  <div className={Style.div7} />
                  <Typography variant='h5' className={Style.div5}>
                    Assigned Team Members
                    <AvatarGroup total={data?.associatedusers.length}>
                      {data?.associatedusers.map((data: any, index: number) => (
                        <>
                          <Avatar
                            key={index}
                            alt='associatedusers'
                            src={data?.profileURL}
                          />
                        </>
                      ))}
                    </AvatarGroup>
                  </Typography>
                </Box>
              </Grid>
            ))}
        </Box>
      </Box>
      <AddMember open={open} setOpen={setOpen} handleOpen={handleOpen} />
    </>
  )
}

export default RolePermission
