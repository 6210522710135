import { Box, Button, Input, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import Plus from 'src/Assets/Icons/Plus.svg'
import Search from 'src/Assets/Icons/Search.svg'
import Breadcrumb from 'src/Components/BreadCrumb'
import AddPermission from 'src/Components/Employees/AddPermission'
import AddRole from 'src/Components/Employees/AddRole'
import PermissionList from 'src/Pages/RolePermission/Permission'
import RoleList from 'src/Pages/RolePermission/Role'
import { ROUTES } from 'src/Utils/Routes'
import Style from './index.module.scss'
import { useAppDispatch } from 'src/Redux/hooks'
import { ViewRoleRequest } from 'src/Redux/Slices/roleSlice'
import { ViewPermissionRequest } from 'src/Redux/Slices/permissionManageSlice'
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
const RolePermissionList: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const [value, setValue] = React.useState(0)
  const [changeTab, setChangeTab] = useState('Add New Role')
  const [title, setTitle] = useState('Roles & Permissions')
  const [searchParam, setSearchParam] = React.useState([
    'name',
    'description',
    'scope',
    'permission',
  ])
  const [q, setQ] = React.useState('')

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    // Update the button text based on the tab change event
    if (newValue === 0) {
      setChangeTab('Add New Role')
      setTitle('Roles & Permissions')
    } else if (newValue === 1) {
      setChangeTab('Add Permission')
      setTitle('Permissions List')
    }
  }
  const [openDialog, setOpenDialog] = useState(false)
  const handleOpen = () => {
    setOpenDialog(true)
  }

  function find(items: []) {
    return items?.filter((item) => {
      return searchParam.some((newItem: string) => {
        return item[newItem]?.toString().toLowerCase().includes(q.toLowerCase())
      })
    })
  }
  return (
    <>
      <Box className={Style.Main}>
        <Box className={Style.Head}>
          <Breadcrumb
            title={title}
            Menus={[
              { name: 'Roles & Permissions', route: ROUTES.ROLE_PERMISSION },
            ]}
          />
          <Box className={Style.Action}>
            <Box className={Style.Left}>
              <Box className={Style.SearchBox}>
                <img src={Search} alt='Search' />
                <Input
                  disableUnderline
                  className={Style.SearchEm}
                  placeholder='Search Roles & Permissions'
                  type='text'
                  value={q}
                  onChange={(e: { target: { value: string } }) => {
                    setQ(e.target.value)
                  }}
                />
              </Box>
            </Box>
            <Box className={Style.Right}>
              <Button
                className={Style.Add}
                variant='contained'
                onClick={handleOpen}
              >
                {changeTab}
                <img src={Plus} alt='Plus' style={{ marginLeft: '8px' }} />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#7752FE',
                },
              }}
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
            >
              <Tab
                className={Style.TabText}
                style={{ color: value === 0 ? '#7752FE' : '#99a1b7' }}
                label='Roles'
                {...a11yProps(0)}
                onClick={() => {
                  dispatch(ViewRoleRequest())
                }}
              />
              <Tab
                className={Style.TabText}
                style={{ color: value === 1 ? '#7752FE' : '#99a1b7' }}
                label='Permissions'
                {...a11yProps(1)}
                onClick={() => {
                  dispatch(ViewPermissionRequest())
                }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <RoleList find={find} setSearchParam={setSearchParam} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <PermissionList find={find} setSearchParam={setSearchParam} />
          </CustomTabPanel>
        </Box>
      </Box>

      {openDialog && changeTab === 'Add New Role' && value === 0 && (
        <AddRole
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          handleOpen={handleOpen}
        />
      )}
      {openDialog && changeTab === 'Add Permission' && value === 1 && (
        <AddPermission
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          handleOpen={handleOpen}
        />
      )}
    </>
  )
}

export default RolePermissionList
