// This slice manages the state for the sign in feature
import {
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import {
  EducationAndEmployementType,
  EducationType,
  SpecialEducationType,
  TradeAndTrainingType,
  EmployerDetailsType,
  JobDetailsType,
  ChildCareType,
  SelfEmployementType,
  ForeignWorkType,
  WorkTaxDetailsType,
} from 'src/Redux/Services/Types/applicationType'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { RootState } from 'src/Redux/store'
import { handleResponse } from 'src/Utils/Constants'
import EducationAndEmployementService from 'src/Redux/Services/EducationAndEmploymentService'
import secureLocalStorage from 'react-secure-storage'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: EducationAndEmployementType
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {
    Education: {
      highestGrade: '',
      schoolName: '',
      city: '',
      state: '',
      country: '',
      dateEducationCompleted: '',
    },
    SpecialEducation: {
      startDate: '',
      endDate: '',
      lastGrade: '',
      reasonForSpecialEducation: '',
      schoolName: '',
      city: '',
      state: '',
      country: '',
    },
    TradeAndTraining: {
      educationId: '',
      typeOfSpecializedTraining: '',
      startDate: '',
      endDate: '',
      schoolName: '',
      city: '',
      state: '',
      country: '',
    },
    EmployerDetails: {
      educationId: '',
      employerDetail: [
        {
          companyName: '',
          typeOfBusiness: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          startDate: '',
          endDate: '',
          isphysicallyWorkedLastDate: undefined,
          lastPhysicallyWorkedDate: '',
          jobTitle: '',
          averageHourPerDay: 0,
          averageDaysPerWeek: 0,
          payAmountPerPayPeriod: 0,
          payFrequency: '',
        },
      ],
    },
    JobDetails: {
      oneOrMoreJobsIn15Years: '',
      jobDescriptionOf15Years: '',
      applicableJobs: [],
      hoursToPerform: {
        walk: 0,
        stand: 0,
        sit: 0,
        climb: 0,
        bend: 0,
        kneel: 0,
        crounch: 0,
        crawl: 0,
        writeOrType: 0,
        handleLargeObject: 0,
        reachToThings: 0,
        handleSmallObjects: 0,
      },
      isLifitingInJob: undefined,
      howFarCarryItems: '',
      heaviestWeightLifted: '',
      howmuchWeightLiftedPerDay: '',
      didSuperviseOther: undefined,
      percentageOfTimeSpentSupervising: '',
      howManyPeopleSupervise: 0,
      didHireOrFirePeople: undefined,
      didLeadOrMentorOthers: undefined,
      whatLiftedAndHowOften: '',
    },
    ChildCare: {
      fulltimeChildcareUnder3Years: undefined,
      fulltimeChildcareStartYear: '',
      fulltimeChildcareEndYear: '',
    },
    SelfEmployement: {
      isSelfEmployed: undefined,
      isSelfEmployedDuringLastYear: undefined,
      typeOfLastYearBusiness: '',
      profitMoreAmountLastYear: undefined,
      totalEarnedAmountLastYear: '',
      isSelfEmployedDuringCurrentYear: undefined,
      typeOfCurrentYearBusiness: '',
      profitMoreAmountCurrentYear: undefined,
      totalEarnedAmountCurrentYear: '',
      isSelfEmployedNextYear: undefined,
      typeOfNextYearBusiness: '',
      profitMoreAmountNextYear: undefined,
    },
    ForeignWork: {
      haveWorkedOutSideUSA: undefined,
      isSalaryWageOrCurrentYearSelfEmployement: undefined,
      totalEarnedAmountCurrentYear: '',
      earningShowInSocialSecutiryStatementCurrentYear: undefined,
      isSalaryWageOrLastYearSelfEmployement: undefined,
      totalEarnedAmountLastYear: '',
      earningShowInSocialSecutiryStatementLastYear: undefined,
      eligibleForBenefitsUnderForeignSocialSecuritySystem: undefined,
      usaTaxesTakenOutCurrentYear: undefined,
      usaTaxesNotTakenOutCurrentYearMonths: [],
      usaTaxesTakenOutPastYear: undefined,
      usaTaxesNotTakenOutPastYearMonths: [],
    },
    WorkTaxDetails: {
      didPaymentPaidAnotherYear: undefined,
      haveWorkedWhereTaxesNotDeducted: undefined,
      isReceivingPensionOrAnnuity: undefined,
      isPensionOrAnnuityBasedOnGovernmentEmployment: undefined,
      isExpectPensionOrAnnuityInFuture: undefined,
      pensionAnnuityRailroadWork: undefined,
      pensionAnnuityFutureGovtWork: undefined,
      pensionAnnuityFutureStartDate: undefined,
      lumpSumNoTaxes: undefined,
    },
  },
}

// Define the reducers that can modify the state
const EducationAndEmployement = createSlice({
  name: 'EducationAndEmployement',
  initialState,
  reducers: {
    setEducationAndEmployementLoading: (state) => {
      state.loading = true
    },
    setEducationAndEmployementSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setEducationAndEmployementFail: (state) => {
      state.success = false
      state.loading = false
      state.data = initialState.data
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AddEducation.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddEducation.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddEducation.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

export const {
  setEducationAndEmployementLoading,
  setEducationAndEmployementSuccess,
  setEducationAndEmployementFail,
} = EducationAndEmployement.actions

export const AddEducation = createAsyncThunk(
  'AddEducation', // name of the action
  async (
    data: EducationType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await EducationAndEmployementService.AddEducation(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditEducation = createAsyncThunk(
  'Education', // name of the action
  async (
    data: EducationType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await EducationAndEmployementService.EditEducation(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditSpecialEducation = createAsyncThunk(
  'SpecialEducation', // name of the action
  async (
    data: SpecialEducationType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response =
        await EducationAndEmployementService.EditSpecialEducation(
          data,
          token,
          isPublic,
        ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditTradeAndTraining = createAsyncThunk(
  'TradeAndTraining', // name of the action
  async (
    data: TradeAndTrainingType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response =
        await EducationAndEmployementService.EditTradeAndTraining(
          data,
          token,
          isPublic,
        ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditEmployerDetails = createAsyncThunk(
  'EmployerDetails', // name of the action
  async (
    data: EmployerDetailsType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await EducationAndEmployementService.EditEmployerDetails(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditJobDetails = createAsyncThunk(
  'JobDetails', // name of the action
  async (
    data: JobDetailsType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await EducationAndEmployementService.EditJobDetails(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditChildCare = createAsyncThunk(
  'ChildCare', // name of the action
  async (
    data: ChildCareType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await EducationAndEmployementService.EditChildCare(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditSelfEmployement = createAsyncThunk(
  'SelfEmployement', // name of the action
  async (
    data: SelfEmployementType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await EducationAndEmployementService.EditSelfEmployement(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditForeignWork = createAsyncThunk(
  'ForeignWork', // name of the action
  async (
    data: ForeignWorkType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await EducationAndEmployementService.EditForeignWork(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditWorkTaxDetails = createAsyncThunk(
  'WorkTaxDetails', // name of the action
  async (
    data: WorkTaxDetailsType,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await EducationAndEmployementService.EditWorkTaxDetails(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default EducationAndEmployement.reducer
