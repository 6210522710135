import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import {
  StepLabel,
  StepConnector,
  type StepIconProps,
  StepConnectorProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import CorrectIcon from 'src/Assets/Icons/greenCorrect.svg'
import AlertIcon from 'src/Assets/Icons/organgeAlert.svg'
import { useState } from 'react'
import { COLORS } from 'src/Utils/Constants'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import { StepsState } from 'src/Redux/Services/Types/stepsType'
import { setStepCompleted } from 'src/Redux/Slices/stepSlice'

interface CustomConnecterProps extends StepConnectorProps {
  index: number
  completed: boolean
}
interface CustomIconProps extends StepIconProps {
  active: boolean
}

const QontoStepIconRoot = styled('div')(() => ({
  '& .QontoStepIcon-circle': {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: 'gray',
  },
  '& .QontoStepIcon-circle-active': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: COLORS.MAINCOLOR,
  },
}))

function QontoStepIcon(props: CustomIconProps) {
  const { className, active } = props

  return (
    <QontoStepIconRoot className={className}>
      {active ? (
        <div className='QontoStepIcon-circle-active' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  )
}

const VerticalLinearStepper = ({
  section,
  scrollToSection,
}: {
  section: keyof StepsState
  scrollToSection: (id: any) => void
}) => {
  const [activeStep, setActiveStep] = useState(0)
  const steps = useAppSelector((state: RootState) => state.steps[section])
  const dispatch = useAppDispatch()

  const flagdata = useAppSelector(
    (state: RootState) => state?.applicationStore?.BasicDetails?.flagdata || {},
  )

  const handleStepClick = (index: number) => {
    setActiveStep(index)
    dispatch(setStepCompleted({ section, label: steps[index].label }))
  }

  const hasMatchingRemark = (flagSectionName: string | undefined): boolean => {
    if (flagSectionName?.length) {
      if (!flagdata.remark || !Array.isArray(flagdata.remark)) {
        return true
      }

      const result = flagdata.remark.some((remark) => {
        // Type guard to check if remark is an object with a key property
        if (typeof remark === 'object' && remark !== null && 'key' in remark) {
          return (remark.key as string).includes(flagSectionName)
        }
        return false
      })

      return !result
    } else {
      return true
    }
  }

  const CustomStepConnector = styled(StepConnector)<CustomConnecterProps>(
    ({ index, completed }) => ({
      marginLeft: '3px',
      '& .MuiStepConnector-line': {
        minHeight: '50px',
        borderLeft: '2.5px dotted',
        borderRight: 'hidden',
        borderTop: 'hidden',
        borderBottom: 'hidden',
        borderColor: 'grey',
      },
      '& .MuiStepConnector-icon': {
        marginLeft: '-12px',
      },
    }),
  )

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper
        activeStep={activeStep}
        orientation='vertical'
        connector={
          <CustomStepConnector
            index={activeStep}
            completed={steps[activeStep]?.completed}
          />
        }
        sx={{
          '& .MuiStep-root': {
            '& .MuiStepLabel-root': {
              padding: 0,
              height: 20,
            },
          },
          '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
            borderColor: COLORS.MAINCOLOR,
          },
          '& .MuiStepLabel-label': {
            display: 'flex',
          },
        }}
      >
        {steps?.map(
          (
            step: {
              label: string | undefined
              completed: any
              sectionId: string
              flagSectionName: string | undefined
            },
            index: number,
          ) => {
            const iconToDisplay = hasMatchingRemark(step?.flagSectionName)
              ? AlertIcon
              : CorrectIcon

            return (
              <Step
                key={step.label}
                active={step.completed}
                completed={step.completed}
                sx={{ cursor: 'pointer' }}
              >
                <StepLabel
                  StepIconComponent={(props) => (
                    <QontoStepIcon {...props} active={step.completed} />
                  )}
                  sx={{
                    '& .MuiStepLabel-label.Mui-completed': {
                      color: COLORS.MAINCOLOR,
                    },
                    '&.MuiStepIcon-completed': {
                      backgroundColor: COLORS.MAINCOLOR,
                    },
                    display: 'flex',
                  }}
                  onClick={() => {
                    handleStepClick(index)
                    scrollToSection(step.sectionId)
                  }}
                >
                  <img
                    src={iconToDisplay}
                    style={{ display: 'flex', paddingRight: 8 }}
                  />
                  {step.label}
                </StepLabel>
              </Step>
            )
          },
        )}
      </Stepper>
    </Box>
  )
}

export default VerticalLinearStepper
