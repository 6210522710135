import {
  Box,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  InputLabel,
  FormControl,
} from '@mui/material'
import ActiveRadio from 'src/Assets/Icons/activeRadio.svg'
import InactiveRadio from 'src/Assets/Icons/inactiveRadio.svg'
import { useEffect, useState } from 'react'
import Style from './index.module.scss'

interface CommonRadioBoxProps {
  title: string
  commonState: boolean | undefined
  setcommonState: (value: boolean) => void
  YesTitle: string
  NoTitle: string
  error?: string
}

const CommonRadioBox = ({
  title,
  commonState,
  setcommonState,
  YesTitle,
  NoTitle,
  error,
}: CommonRadioBoxProps) => {
  const [commonStateFake, setcommonStateFake] = useState<boolean | undefined>(
    undefined,
  )
  useEffect(() => {
    setcommonStateFake(commonState)
  }, [commonState])
  return (
    <>
      <Box className={Style.frame}>
        <Grid xs={12} className='div-2'>
          <Typography variant='body1' className={Style.textWrapper}>
            {title} <span style={{ color: 'red' }}>*</span>
          </Typography>
        </Grid>
        <Grid xs={12}>
          <RadioGroup
            aria-label='commonState'
            className={Style.element}
            id='commonState'
            name='commonState'
            value={commonStateFake}
          >
            <FormControlLabel
              className={
                commonState
                  ? Style.radioSelectionBoxSelect
                  : Style.radioSelectionBoxes
              }
              label=''
              value={true}
              control={
                <Box display={'flex'} alignItems={'flex-start'}>
                  <Radio
                    checked={commonState === true}
                    checkedIcon={<img src={ActiveRadio} alt='On' />}
                    icon={<img src={InactiveRadio} alt='Off' />}
                    value={true}
                    onClick={() => {
                      setcommonState(true)
                    }}
                  />
                  <Box className={Style.radioText}>
                    <Typography variant='body1' className={Style.TxtQ}>
                      Yes
                    </Typography>
                    <Typography variant='body1' className={Style.TxtA}>
                      {YesTitle}
                    </Typography>
                  </Box>
                </Box>
              }
            />
            <FormControlLabel
              className={
                commonState === false
                  ? Style.radioSelectionBoxSelect
                  : Style.radioSelectionBoxes
              }
              label=''
              value={false}
              control={
                <Box display={'flex'} alignItems={'flex-start'}>
                  <Radio
                    checked={commonState === false}
                    checkedIcon={<img src={ActiveRadio} alt='On' />}
                    icon={<img src={InactiveRadio} alt='Off' />}
                    value={false}
                    onClick={() => {
                      setcommonState(false)
                    }}
                  />
                  <Box className={Style.radioText}>
                    <Typography variant='body1' className={Style.TxtQ}>
                      No
                    </Typography>
                    <Typography variant='body1' className={Style.TxtA}>
                      {NoTitle}
                    </Typography>
                  </Box>
                </Box>
              }
            />
          </RadioGroup>
        </Grid>
        <FormHelperText>{error}</FormHelperText>
      </Box>
    </>
  )
}

export default CommonRadioBox

export const SmallRadioBox = ({
  value,
  title,
  name,
  onClick,
  error,
}: {
  value: boolean | undefined
  title: string
  name: string
  onClick: (value: boolean) => void
  error: string
}) => {
  const [fakeValue, setfakeValue] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    setfakeValue(value)
  }, [value])
  return (
    <Grid xs={12} py={1} width={'100%'}>
      <InputLabel className={Style.label}>
        {title} <span style={{ color: 'red' }}>*</span>
      </InputLabel>
      <FormControl>
        <RadioGroup
          aria-label={name}
          className={Style.element}
          id={name}
          name={name}
          value={fakeValue}
        >
          <FormControlLabel
            className={
              value === true
                ? Style.smallRadioSelectionBoxSelect
                : Style.smallRadioSelectionBoxes
            }
            label=''
            value={true}
            control={
              <Box display={'flex'} alignItems={'center'}>
                <Radio
                  checked={fakeValue === true}
                  checkedIcon={<img src={ActiveRadio} alt='On' />}
                  icon={<img src={InactiveRadio} alt='Off' />}
                  value={true}
                  onClick={() => {
                    onClick(true)
                  }}
                />
                <Box className={Style.radioText}>
                  <Typography variant='body1' className={Style.TxtQ}>
                    Yes
                  </Typography>
                </Box>
              </Box>
            }
          />
          <FormControlLabel
            className={
              value === false
                ? Style.smallRadioSelectionBoxSelect
                : Style.smallRadioSelectionBoxes
            }
            label=''
            value={false}
            control={
              <Box display={'flex'} alignItems={'center'}>
                <Radio
                  checked={fakeValue === false}
                  checkedIcon={<img src={ActiveRadio} alt='On' />}
                  icon={<img src={InactiveRadio} alt='Off' />}
                  value={false}
                  onClick={() => {
                    onClick(false)
                  }}
                />
                <Box className={Style.radioText}>
                  <Typography variant='body1' className={Style.TxtQ}>
                    No
                  </Typography>
                </Box>
              </Box>
            }
          />
        </RadioGroup>
      </FormControl>
      <FormHelperText>{error}</FormHelperText>
    </Grid>
  )
}
