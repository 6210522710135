import React, { useEffect, type FC } from 'react'
import { Box, Typography } from '@mui/material'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/Utils/Routes'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ViewProfileRequest } from 'src/Redux/Slices/profileSlice'

const PersonalDetail: FC = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(ViewProfileRequest())
  }, [])
  const viewProfile = useAppSelector((state) => state?.profile?.data)
  const navigate = useNavigate()
  function formatPhoneNumber(phoneNumber: string): string {
    // Remove any non-digit characters from the phone number
    const cleanedPhoneNumber = phoneNumber?.replace(/\D/g, '')

    // Check if the phone number is at least 10 digits long
    if (cleanedPhoneNumber?.length < 10) {
      return phoneNumber
    }

    // Format the phone number
    const areaCode = cleanedPhoneNumber?.slice(0, 3)
    const prefix = cleanedPhoneNumber?.slice(3, 6)
    const lineNumber = cleanedPhoneNumber?.slice(6)
    return `(${areaCode}) ${prefix}-${lineNumber}`
  }

  return (
    <Box className={Style.PersonalDetailBox}>
      <Typography className={Style.PersonalDetailTitle}>
        Personal Detail
        <img
          src={Icons.threeDot}
          alt='threeDot'
          style={{ marginRight: '4px', cursor: 'pointer' }}
          onClick={() => {
            navigate(ROUTES.PROFILE)
          }}
        />
      </Typography>

      <Box className={Style.PersonalDetailSubBox}>
        <Typography variant='h2' className={Style.PersonalDetailName}>
          {viewProfile?.firstName + ' ' + viewProfile?.lastName}
        </Typography>
        <Typography className={Style.PersonalDetailRole}>
          {viewProfile?.roleName}
        </Typography>
      </Box>

      <Box className={Style.PersonalDetailDiv}>
        <Typography className={Style.PersonalDetailText}>
          <img
            src={Icons.location}
            alt='location'
            style={{ marginRight: '4px' }}
          />
          {viewProfile?.country}
        </Typography>

        <Typography className={Style.PersonalDetailText}>
          <img src={Icons.phone} alt='phone' style={{ marginRight: '4px' }} />
          {formatPhoneNumber(viewProfile?.phoneNumber)}
        </Typography>

        <Typography className={Style.PersonalDetailText}>
          <img src={Icons.email} alt='email' style={{ marginRight: '4px' }} />
          {viewProfile?.emailId}
        </Typography>
      </Box>
    </Box>
  )
}

export default PersonalDetail
