import * as React from 'react'
import {
  Grid,
  Typography,
  Box,
  Divider,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Chip,
  TableCell,
  Alert,
  LinearProgress,
} from '@mui/material'
import DownArrow from 'src/Assets/Icons/IconChevronDown.svg'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import {
  getOrganizationPayment,
  getOrganizationPaymentSummary,
} from 'src/Redux/Slices/orgSlice'
import { convertISOToDate } from 'src/Utils/Constants'
import { DynamicObj } from 'src/Pages/SignIn'

function MyComponent() {
  const dispatch = useAppDispatch()
  const { selectedOrganization } = useAppSelector((state) => state.organization)
  const [paymentDetail, setPaymentDetail] = React.useState<DynamicObj>([])
  const [paymentSummary, setPaymentSummary] = React.useState<DynamicObj>({})

  const { data } = useAppSelector(
    (state) => state?.applicationStore?.AllApplications,
  )
  React.useEffect(() => {
    if (selectedOrganization?.uuid) {
      dispatch(getOrganizationPayment(selectedOrganization?.uuid)).then(
        (res) => {
          const arrayOfObjects: DynamicObj[] = res.payload?.data
          const newArray: DynamicObj[] = arrayOfObjects.map(
            (obj: DynamicObj) => ({
              ...obj,
              combinedDate: formatDateRange(
                obj.subscriptionStartDate,
                obj.subscriptionEndDate,
              ),
            }),
          )

          setPaymentDetail(newArray)
        },
      )
      dispatch(getOrganizationPaymentSummary(selectedOrganization?.uuid)).then(
        (res) => {
          setPaymentSummary(res?.payload?.data)
        },
      )
    }
  }, [])

  function formatDateRange(startDate: string, endDate: string): string {
    const startDateObj = new Date(startDate)
    const endDateObj = new Date(endDate)

    const startFormatted = `${startDateObj.getDate()} ${startDateObj.toLocaleString('default', { month: 'short' })} ${startDateObj.getFullYear()}`
    const endFormatted = `${endDateObj.getDate()} ${endDateObj.toLocaleString('default', { month: 'short' })} ${endDateObj.getFullYear()}`

    return `${startFormatted} - ${endFormatted}`
  }

  return (
    <>
      {paymentDetail.length && paymentSummary ? (
        <Grid spacing={2} sx={{ alignSelf: 'stretch' }} p={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <Grid container spacing={2} sx={{ mt: { xs: 3, md: 0 } }}>
                <Grid xs={12}>
                  <Box
                    sx={{
                      borderRadius: 2,
                      borderColor: 'rgba(241, 241, 244, 1)',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      backgroundColor: '#fff',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      p: { xs: '0 20px', md: 4 },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        xs={12}
                        md={6}
                        sx={{
                          justifyContent: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                          margin: 'auto 0',
                        }}
                      >
                        <Typography
                          variant='h6'
                          sx={{
                            color: 'var(--Neutral-Gray-900, #252f4a)',
                            fontWeight: 600,
                            lineHeight: '150%',
                          }}
                        >
                          Active until{' '}
                          {convertISOToDate(
                            paymentSummary?.subscriptionEndDate,
                          )}
                        </Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            color: 'var(--Neutral-Gray-700, #78829d)',
                            mt: 1,
                            fontWeight: 500,
                            lineHeight: '143%',
                          }}
                        >
                          We will send you a notification upon Subscription
                          expiration
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        md={6}
                        sx={{
                          justifyContent: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                        }}
                      >
                        <Box
                          sx={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            gap: 1,
                            fontSize: 16,
                            fontWeight: 500,
                            lineHeight: '150%',
                            flexWrap: { xs: 'wrap', md: 'nowrap' },
                          }}
                        >
                          <Typography
                            variant='body1'
                            sx={{
                              color: 'var(--Neutral-Gray-800, #4b5675)',
                            }}
                          >
                            Applications
                          </Typography>
                          <Typography
                            variant='body1'
                            sx={{
                              color: '#1a202c',
                              fontFamily:
                                'SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif',
                            }}
                          >
                            {paymentSummary?.applicationCount} of{' '}
                            {paymentSummary?.numberOfApplication} Used
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            justifyContent: 'center',
                            borderRadius: 8,
                            backgroundColor: '#dfffea',
                            display: 'flex',
                            mt: 1,
                            flexDirection: 'column',
                          }}
                        >
                          <LinearProgress
                            variant='determinate'
                            value={
                              100 -
                              (((parseInt(paymentDetail?.numberOfApplication) ??
                                0) - (data?.length ?? 0) ?? 0) /
                                (parseInt(paymentDetail?.numberOfApplication) ??
                                  0)) *
                                100
                            }
                            sx={{
                              borderRadius: '30px',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: '#17c653', // Change the background color as needed
                              },
                              height: '5px',
                              '@media (max-width: 991px)': {
                                maxWidth: '100%',
                              },
                            }}
                          />
                        </Box>
                        <Typography
                          variant='body2'
                          sx={{
                            color: 'var(--Neutral-Gray-700, #78829d)',
                            mt: 1,
                            fontWeight: 500,
                            lineHeight: '143%',
                          }}
                        >
                          {paymentSummary?.numberOfApplication -
                            paymentSummary?.applicationCount}{' '}
                          Applications remaining until your plan requires update
                          {/* {data?.length} */}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid
                        xs={12}
                        md={8}
                        sx={{
                          justifyContent: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                          margin: '2rem auto 0 0',
                        }}
                      >
                        <Typography
                          variant='body2'
                          sx={{
                            color: 'var(--Neutral-Gray-700, #78829d)',
                            mt: 1,
                            fontWeight: 500,
                            lineHeight: '103%',
                          }}
                        >
                          <span style={{ fontWeight: '800' }}>
                            $ {paymentSummary?.amount?.toFixed(2)}
                          </span>{' '}
                          Per Year
                        </Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            color: 'var(--Neutral-Gray-700, #78829d)',
                            mt: 1,
                            fontWeight: 500,
                            lineHeight: '103%',
                          }}
                        >
                          Small Bussiness - up to{' '}
                          {paymentSummary?.numberOfApplication} Applications
                        </Typography>
                      </Grid>
                      <Grid
                        xs={12}
                        md={4}
                        sx={{
                          justifyContent: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                        }}
                      >
                        <Box
                          sx={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            gap: 1,
                            fontSize: 16,
                            fontWeight: 500,
                            lineHeight: '0%',
                            flexWrap: { xs: 'wrap', md: 'nowrap' },
                          }}
                        ></Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={{
                      borderRadius: '8px',
                      borderColor: 'rgba(241, 241, 244, 1)',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      // backgroundColor: 'red',
                      display: 'flex',
                      mt: 3,
                      flexDirection: 'column',
                      lineHeight: '150%',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        justifyContent: 'space-between',
                        borderBottom: '1px solid rgba(241, 241, 244, 1)',
                        // borderColor: 'rgba(241, 241, 244, 1)',
                        // borderStyle: 'solid',
                        // borderBottomWidth: 1,

                        display: 'flex',
                        width: '100%',
                        gap: 1,
                        p: { xs: '0 20px', md: '15px 30px' },
                        flexWrap: { xs: 'wrap', md: 'nowrap' },
                      }}
                    >
                      <Typography
                        variant='h6'
                        sx={{
                          color: 'var(--Neutral-Gray-900, #252f4a)',
                          margin: 'auto 0',
                        }}
                      >
                        Payment History
                      </Typography>
                      {/* <Box
                        sx={{
                          borderRadius: 2,
                          backgroundColor: '#f2f4f8',
                          display: 'flex',
                          gap: 1,
                          fontSize: 16,
                          color: '#6f737a',
                          fontWeight: 400,
                          p: '8px 15px',
                        }}
                      >
                        x`<Typography
                          variant='body1'
                          sx={{
                            fontFamily:
                              'SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif',
                          }}
                        >
                          This Year
                        </Typography> 
                         <Box
                          component='img'
                          loading='lazy'
                          src={DownArrow}
                          sx={{
                            aspectRatio: 1,
                            objectFit: 'auto',
                            objectPosition: 'center',
                            width: 16,
                            margin: 'auto 0',
                          }}
                        />
                    </Box> */}
                    </Box>
                    <Grid p={2}>
                      <TableContainer>
                        <Table
                          sx={{ minWidth: 650, backgroundColor: '#fff' }}
                          aria-label='simple table'
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>ORDER ID</TableCell>
                              <TableCell align='right'>PLAN NAME</TableCell>
                              <TableCell align='right'>PLAN STATUS</TableCell>
                              <TableCell align='right'>
                                START DATE/END DATE
                              </TableCell>
                              <TableCell align='right'>AMOUNT</TableCell>
                              <TableCell align='right'>PAYMENT DATE</TableCell>
                              <TableCell align='right'>
                                PAYMENT STATUS
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paymentDetail?.map((item: DynamicObj) => (
                              <>
                                <TableRow
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component='th' scope='row'>
                                    {item.stripePaymentId}
                                  </TableCell>
                                  <TableCell align='right'>
                                    {item.planName}
                                  </TableCell>
                                  <TableCell align='right'>
                                    {item.subscriptionId ===
                                    paymentSummary?.id ? (
                                      <Typography
                                        variant='body2'
                                        sx={{
                                          borderRadius: 1,
                                          backgroundColor: '#dfffea',
                                          color: '#17c653',
                                          textAlign: 'center',
                                          justifyContent: 'center',
                                          p: '2px 4px',
                                          whiteSpace: {
                                            xs: 'initial',
                                            md: 'nowrap',
                                          },
                                        }}
                                      >
                                        Active
                                      </Typography>
                                    ) : (
                                      <Typography
                                        variant='body2'
                                        sx={{
                                          borderRadius: 1,
                                          backgroundColor: '#FFEEF3',
                                          color: '#F8285A',
                                          textAlign: 'center',
                                          justifyContent: 'center',
                                          p: '2px 4px',
                                          whiteSpace: {
                                            xs: 'initial',
                                            md: 'nowrap',
                                          },
                                        }}
                                      >
                                        {item.status === 'success'
                                          ? 'Expired'
                                          : item.status}
                                      </Typography>
                                    )}
                                  </TableCell>
                                  <TableCell align='right'>
                                    <Chip label={item.combinedDate} />
                                  </TableCell>
                                  <TableCell align='right'>
                                    $ {item?.amount}
                                  </TableCell>
                                  <TableCell align='right'>
                                    {convertISOToDate(item?.paymentDate)}
                                  </TableCell>
                                  <TableCell align='right'>
                                    {item.status === 'success' ? (
                                      <Typography
                                        variant='body2'
                                        sx={{
                                          borderRadius: 1,
                                          backgroundColor: '#dfffea',
                                          color: '#17c653',
                                          textAlign: 'center',
                                          justifyContent: 'center',
                                          p: '2px 4px',
                                          whiteSpace: {
                                            xs: 'initial',
                                            md: 'nowrap',
                                          },
                                        }}
                                      >
                                        {item.status}
                                      </Typography>
                                    ) : (
                                      <Typography
                                        variant='body2'
                                        sx={{
                                          borderRadius: 1,
                                          backgroundColor: '#FFEEF3',
                                          color: '#F8285A',
                                          textAlign: 'center',
                                          justifyContent: 'center',
                                          p: '2px 4px',
                                          whiteSpace: {
                                            xs: 'initial',
                                            md: 'nowrap',
                                          },
                                        }}
                                      >
                                        {item.status}
                                      </Typography>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    {/* <Table /> */}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  borderRadius: 2,
                  borderColor: 'rgba(241, 241, 244, 1)',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  backgroundColor: '#fff',
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: 'column',
                  width: '100%',
                  mt: { xs: 3, md: 0 },
                }}
              >
                <Typography
                  variant='h6'
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'start',
                    color: 'var(--Neutral-Gray-900, #252f4a)',
                    whiteSpace: { xs: 'initial', md: 'nowrap' },
                    p: { xs: '0 20px', md: '15px 30px' },
                    fontWeight: 600,
                    lineHeight: '150%',
                  }}
                >
                  Summary
                </Typography>
                <Divider
                  style={{
                    borderStyle: 'dashed',
                  }}
                />
                <Box
                  sx={{
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    fontWeight: 500,
                    p: { xs: '0 20px', md: 4 },
                  }}
                >
                  <Typography
                    variant='h6'
                    sx={{
                      color: 'var(--Neutral-Gray-900, #252f4a)',
                      fontWeight: 600,
                      lineHeight: '133%',
                    }}
                  >
                    Plan details
                  </Typography>
                  <Box sx={{ display: 'flex', mt: 1, gap: 1 }}>
                    <Typography
                      variant='body2'
                      sx={{
                        borderRadius: 1,
                        backgroundColor:
                          'var(--primary-shades-tints-10, #f1eeff)',
                        color: 'var(--primary-shades-tints-100, #7752fe)',
                        textAlign: 'center',
                        justifyContent: 'center',
                        p: '2px 6px',
                        lineHeight: '143%',
                      }}
                    >
                      {paymentSummary?.name}
                    </Typography>
                    <Typography
                      variant='body1'
                      sx={{
                        color: 'var(--Neutral-Gray-700, #78829d)',
                        lineHeight: '150%',
                      }}
                    >
                      $ {paymentSummary?.amount} / {paymentSummary?.plan}
                    </Typography>
                  </Box>
                  {/* <Box
                    sx={{
                      display: 'flex',
                      mt: 1,
                      gap: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        color: 'var(--Neutral-Gray-700, #78829d)',
                        mt: 1,
                        fontWeight: 500,
                        lineHeight: '143%',
                      }}
                    >
                      {paymentSummary?.name} - up to{' '}
                      {paymentSummary?.numberOfApplication} Applications
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: 'var(--Neutral-Gray-700, #78829d)',
                        mt: 1,
                        fontWeight: 500,
                        lineHeight: '143%',
                      }}
                    >
                      We will send you a notification upon Subscription
                      expiration
                    </Typography>
                  </Box> */}
                  <Divider
                    style={{
                      marginTop: '16px',
                      marginBottom: '16px',
                      borderStyle: 'dashed',
                    }}
                  />
                  <Box
                    sx={{
                      backgroundColor: '#dbdfe9',
                      mt: 4,
                      height: 1,
                    }}
                  />
                  <Typography
                    variant='h6'
                    sx={{
                      color: 'var(--Neutral-Gray-900, #252f4a)',
                      mt: 4,
                      fontWeight: 600,
                      lineHeight: '133%',
                    }}
                  >
                    Subscription Details
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      mt: 1,
                      gap: 2,
                      fontSize: 14,
                      lineHeight: '143%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        color: 'var(--Neutral-Gray-700, #78829d)',
                        fontFamily:
                          'SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif',
                      }}
                    >
                      Subscription ID
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: 'var(--Neutral-Gray-900, #252f4a)',
                        fontFamily:
                          'SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif',
                      }}
                    >
                      {paymentSummary?.id}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      mt: 1,
                      gap: 2,
                      fontSize: 14,
                      lineHeight: '143%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        color: 'var(--Neutral-Gray-700, #78829d)',
                        fontFamily:
                          'SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif',
                      }}
                    >
                      Started
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: 'var(--Neutral-Gray-900, #252f4a)',
                        fontFamily:
                          'SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif',
                      }}
                    >
                      {convertISOToDate(paymentSummary.subscriptionStartDate)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      mt: 1,
                      gap: 2,
                      fontSize: 14,
                      whiteSpace: { xs: 'initial', md: 'nowrap' },
                      lineHeight: '143%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        color: 'var(--Neutral-Gray-700, #78829d)',
                        fontFamily:
                          'SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif',
                        margin: 'auto 0',
                      }}
                    >
                      Status
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        borderRadius: 1,
                        backgroundColor: '#dfffea',
                        color: '#17c653',
                        textAlign: 'center',
                        justifyContent: 'center',
                        p: '2px 6px',
                        whiteSpace: { xs: 'initial', md: 'nowrap' },
                      }}
                    >
                      Active
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      mt: 1,
                      gap: 2,
                      fontSize: 14,
                      lineHeight: '143%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant='body2'
                      sx={{
                        color: 'var(--Neutral-Gray-700, #78829d)',
                        fontFamily:
                          'SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif',
                      }}
                    >
                      Next Invoice
                    </Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: 'var(--Neutral-Gray-900, #252f4a)',
                        fontFamily:
                          'SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif',
                      }}
                    >
                      {convertISOToDate(paymentSummary.subscriptionEndDate)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Alert
          severity='info'
          sx={{
            textAlign: 'center',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          No any payment data avilable.
        </Alert>
      )}
    </>
  )
}

export default MyComponent
