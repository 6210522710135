import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  AsyncThunk,
  AsyncThunkConfig,
} from '@reduxjs/toolkit/dist/createAsyncThunk'
import { handleResponse } from 'src/Utils/Constants'
import { employeeType } from '../Services/Types/employeeType'
import EmployeeService from '../Services/employeeService'
import { thunkAPItype } from '../store'

// Define the initial state of the slice
interface detailsType {
  success: boolean
  loading: boolean
  data?: employeeType
}
interface stateType {
  success: boolean
  loading: boolean
  data: employeeType[]
  details: detailsType
  organizationList: {
    success: boolean
    loading: boolean
    data?: Array<{ name?: string; uuid?: string }>
  }
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: [], // the data returned from the sign in request
  details: {
    success: false,
    loading: false,
    data: { uuid: '' },
  },
  organizationList: {
    success: false,
    loading: false,
    data: [],
  },
}

// Define the reducers that can modify the state
const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployeeLoading: (state) => {
      state.loading = true
    },
    setEmployeeSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setEmployeeFail: (state) => {
      state.success = false
      state.loading = false
      state.data = []
    },
    setEmployeeDetailsLoading: (state) => {
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
        },
      }
    },
    setEmployeeDetailsSuccess: (state, action) => {
      return {
        ...state,
        details: {
          success: true,
          loading: false,
          data: action.payload,
        },
      }
    },
    setEmployeeDetailsFail: (state) => {
      return {
        ...state,
        details: {
          success: false,
          loading: false,
          data: { uuid: '' },
        },
      }
    },
    setOrganizationListLoading: (state) => {
      return {
        ...state,
        organizationList: {
          ...state.organizationList,
          loading: true,
        },
      }
    },
    setOrganizationListSuccess: (state, action) => {
      return {
        ...state,
        organizationList: {
          success: true,
          loading: false,
          data: action.payload,
        },
      }
    },
    setOrganizationListFail: (state) => {
      return {
        ...state,
        organizationList: {
          success: false,
          loading: false,
          data: [],
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(EmployeeListRequest.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      EmployeeListRequest.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(EmployeeListRequest.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(EmployeeDetailsRequest.pending, (state) => {
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
        },
      }
    })
    builder.addCase(
      EmployeeDetailsRequest.fulfilled,
      (state, action: PayloadAction<detailsType>) => {
        return {
          ...state,
          details: {
            success: action.payload?.success,
            loading: false,
            data: action.payload?.data,
          },
        }
      },
    )
    builder.addCase(EmployeeDetailsRequest.rejected, (state: any) => {
      return {
        ...state,
        details: {
          success: false,
          loading: false,
          data: { uuid: '' },
        },
      }
    })

    builder.addCase(OrganizationListRequest.pending, (state) => {
      return {
        ...state,
        organizationList: {
          ...state.organizationList,
          loading: true,
        },
      }
    })
    builder.addCase(
      OrganizationListRequest.fulfilled,
      (
        state,
        action: PayloadAction<{
          success: false
          loading: false
          data: []
        }>,
      ) => {
        return {
          ...state,
          organizationList: {
            success: action.payload?.success,
            loading: false,
            data: action.payload?.data,
          },
        }
      },
    )
    builder.addCase(OrganizationListRequest.rejected, (state: any) => {
      return {
        ...state,
        organizationList: {
          success: false,
          loading: false,
          data: [],
        },
      }
    })
  },
})

export const { setEmployeeLoading, setEmployeeSuccess, setEmployeeFail } =
  employeeSlice.actions

// Define the async thunk action creator
export const EmployeeListRequest = createAsyncThunk(
  'employeeList', // name of the action
  async (
    filter: {
      scope?: string
      roleId?: string
      organizationId?: string
    },
    thunkAPI: thunkAPItype,
  ) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await EmployeeService.viewEmployeeList(filter, token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while employeeentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const OrganizationListRequest = createAsyncThunk(
  'organizationList', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await EmployeeService.viewOrganizationList(token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while employeeentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

// Define the async thunk action creator
export const EmployeeDetailsRequest: AsyncThunk<
  detailsType,
  any,
  AsyncThunkConfig
> = createAsyncThunk(
  'employeeDetails', // name of the action
  async (uuid: string, thunkAPI: thunkAPItype) => {
    try {
      const token = thunkAPI.getState().auth.data?.token
      const response = await EmployeeService.employeeDetails(token, uuid) // make the API call
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while employeeentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

// Define the async thunk action creator
export const AddEmployeeRequest = createAsyncThunk(
  'addEmployee', // name of the action
  async (data: employeeType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const token = thunkAPI.getState().auth.data?.token
      const response = await EmployeeService.addEmployee(token, data) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while employeeentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

// Define the async thunk action creator
export const DeleteEmployeeRequest = createAsyncThunk(
  'deleteEmployee', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const token = thunkAPI.getState().auth.data?.token
      const response = await EmployeeService.deleteEmployee(
        token,
        data.uuid,
        data.data,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while employeeentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

// Define the async thunk action creator
export const EditEmployeeRequest = createAsyncThunk(
  'editEmployee', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const token = thunkAPI.getState().auth.data?.token
      const response = await EmployeeService.editEmployee(
        token,
        data.data,
        data.uuid,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while employeeentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default employeeSlice.reducer
