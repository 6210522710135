import { baseAPI } from '../Common/httpCommon'

const GetUploadDocumennt = async (
  documentId: string,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.get('/application/get-consent/' + documentId, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddUploadDocumennt = async (
  data: FormData,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.post('/application/signed-consent', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditUploadDocumennt = async (
  data: FormData,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('/application/signed-consent', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e: any) {
    return e
  }
}

const SignConsentFormsService = {
  GetUploadDocumennt,
  AddUploadDocumennt,
  EditUploadDocumennt,
}
export default SignConsentFormsService
