// This slice manages the state for the sign in feature
import {
  PayloadAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { AssistantFormType } from '../../Services/Types/assistantFormType'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { thunkAPItype } from 'src/Redux/store'
import { handleResponse } from 'src/Utils/Constants'
import AssistantService from '../../Services/assistantFormService'
import secureLocalStorage from 'react-secure-storage'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: AssistantFormType
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {
    firstName: '',
    lastName: '',
    relation: '',
    phone: '',
    email: '',
    organizationName: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  },
}

// Define the reducers that can modify the state
const AssistantSlice = createSlice({
  name: 'AssistantForm',
  initialState,
  reducers: {
    setAssistantLoading: (state) => {
      state.loading = true
    },
    setAssistantSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setAssistantFail: (state) => {
      state.success = false
      state.loading = false
      state.data = initialState.data
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AddAssistant.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddAssistant.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddAssistant.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

export const { setAssistantLoading, setAssistantSuccess, setAssistantFail } =
  AssistantSlice.actions

export const AddAssistant = createAsyncThunk(
  'addAssitance', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await AssistantService.AddAssistant(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditAssistant = createAsyncThunk(
  'updateAssitance', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await AssistantService.UpdateAssistant(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default AssistantSlice.reducer
