import { baseAPI } from '../Common/httpCommon'
import { type LegalDetailsType } from './Types/applicationType'

const AddLegalNames = async (
  data: LegalDetailsType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.post('application/legalDocument/legalName', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditLegalNames = async (
  data: LegalDetailsType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/legalDocument/legalName', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddIdentificationNumbers = async (
  data: LegalDetailsType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/legalDocument/identification', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddReceivingDisability = async (
  data: LegalDetailsType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put(
      'application/legalDocument/disabilityPayment',
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Is-public': isPublic,
          'Content-Type': 'application/json',
        },
      },
    )
  } catch (e: any) {
    return e
  }
}

const LegalDetailsService = {
  AddLegalNames,
  EditLegalNames,
  AddIdentificationNumbers,
  AddReceivingDisability,
}
export default LegalDetailsService
