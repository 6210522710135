import { useAppSelector } from 'src/Redux/hooks'
import { DashboardMain, Roles } from 'src/Utils/Constants'
export default function MainSidebar() {
  const data = useAppSelector((state) => state.auth?.data)

  return (
    <>
      {data?.roleScope === Roles.ADMIN && <DashboardMain.AdminSidebar />}
      {data?.roleScope === Roles.LEGAL && <DashboardMain.LegalSidebar />}
    </>
  )
}
