// This slice manages the state for the sign in feature
import { createSlice } from '@reduxjs/toolkit'
import { MedicalDetailsType } from '../../Services/Types/applicationType'

// Define the initial state of the slice
const initialState: MedicalDetailsType = {
  medicalFlagData: {},
  GeneralHealth: {
    applicationId: '',
    dateOfBirth: '',
    gender: '',
    feet: 0,
    inch: 0,
    weight: 0,
  },
  VisionAndHearing: {
    medicalDetailId: '',
    zeroOrLessVision2020: undefined,
    visionCorrectorDetail: '',
    isImpairedVision: undefined,
    visionRelatedSymptoms: [],
    additionalVisionRelatedSymptoms: '',
    isPartialOrTotalDeaf: undefined,
    isUsingHearingAids: undefined,
    isImpairedHearing: undefined,
    hearingSymptoms: [],
    additionalHearingRelatedSymptoms: '',
  },
  IllnessesInjuriesAndConditions: {
    medicalDetailId: '',
    muscleOrMemoryRelatedSymptoms: [],
    energyOrSleepRelatedSymptoms: [],
    feverOrSkinRelatedSymptoms: [],
    breathingOrHeartRelatedSymptoms: [],
    speechRelatedSymptoms: [],
    memoryRelatedSymptoms: [],
    mentalHealthRelatedSymptoms: [],
    urinarySymptoms: [],
    dizzinessOrHeadacheRelatedSymptoms: [],
    isSeizureRelatedSymptoms: undefined,
    seizureRelatedSymptoms: [],
    anotherSymptoms: '',
    howLongSymptomLast: '',
    isAfterEffectSeizureExperience: undefined,
    seizureAfterEffectExperience: [],
    emotionalOrLearningProblems: [],
    speechSymptomsDuration: '',
    headachemigrainesymptoms: [],
    headachemigrainesymptomduration: '',
    seizureaftereffectsymptomsduration: '',
  },
  MedicalProfessionalDetail: {
    medicalDetailId: '',
    isMentalIllness: undefined,
    mentalIllnessMedicalProfessionals: [
      {
        suffix: '',
        doctorFirstName: '',
        doctorLastName: '',
        doctorMedicalSpecialty: '',
        officeOrClinicName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        firstAppointmentDate: '',
        lastAppointmentDate: '',
        nextAppointmentDate: '',
        treatmentType: [],
        typeOfTherapyDoctorProvided: [],
        otherTreatment: '',
        otherTypeOfTherapyDetails: '',
        specialProcedureDetail: '',
      },
    ],
    isPhysicalIllness: undefined,
    physicalIllnessMedicalProfessionals: [
      {
        suffix: '',
        doctorFirstName: '',
        doctorLastName: '',
        doctorMedicalSpecialty: '',
        officeOrClinicName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        firstAppointmentDate: '',
        lastAppointmentDate: '',
        nextAppointmentDate: '',
        treatmentType: [],
        typeOfTherapyDoctorProvided: [],
        otherTreatment: '',
        otherTypeOfTherapyDetails: '',
        specialProcedureDetail: '',
      },
    ],
  },
  HospitalAndMedicalFacilityCare: {
    medicalDetailId: '',
    haveEmergencyRoomVisit: undefined,
    haveEmergencyRoomVisitMedicalFacility: [
      {
        hospitalName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        arrivedDate: '',
        leftDate: '',
        treatmentType: [],
        typeOfTherapyDoctorProvided: [],
        otherTreatment: '',
        otherTypeOfTherapyDetails: '',
        specialProcedureDetail: '',
      },
    ],
    haveMedicalFacility: undefined,
    haveMedicalFacilityMedicalFacility: [
      {
        hospitalName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        arrivedDate: '',
        leftDate: '',
        treatmentType: [],
        typeOfTherapyDoctorProvided: [],
        otherTreatment: '',
        otherTypeOfTherapyDetails: '',
        specialProcedureDetail: '',
      },
    ],
    haveMentalHeathTreatment: undefined,
    haveMentalHeathTreatmentMedicalFacility: [
      {
        hospitalName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        arrivedDate: '',
        leftDate: '',
        treatmentType: [],
        typeOfTherapyDoctorProvided: [],
        otherTreatment: '',
        otherTypeOfTherapyDetails: '',
        specialProcedureDetail: '',
      },
    ],
    haveInpatientOrOverNightStay: undefined,
    haveInpatientOrOverNightStayMedicalFacility: [
      {
        hospitalName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        arrivedDate: '',
        leftDate: '',
        treatmentType: [],
        typeOfTherapyDoctorProvided: [],
        otherTreatment: '',
        otherTypeOfTherapyDetails: '',
        specialProcedureDetail: '',
      },
    ],
    haveOutpatientOrSamedayVisit: undefined,
    haveOutpatientOrSamedayVisitMedicalFacility: [
      {
        hospitalName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        arrivedDate: '',
        leftDate: '',
        treatmentType: [],
        typeOfTherapyDoctorProvided: [],
        otherTreatment: '',
        otherTypeOfTherapyDetails: '',
        specialProcedureDetail: '',
      },
    ],
    haveSurgeries: undefined,
    haveSurgeriesMedicalFacility: [
      {
        hospitalName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        arrivedDate: '',
        leftDate: '',
        treatmentType: [],
        typeOfTherapyDoctorProvided: [],
        otherTreatment: '',
        otherTypeOfTherapyDetails: '',
        specialProcedureDetail: '',
      },
    ],
    havePhysicalTherapy: undefined,
    havePhysicalTherapyMedicalFacility: [
      {
        hospitalName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        arrivedDate: '',
        leftDate: '',
        treatmentType: [],
        typeOfTherapyDoctorProvided: [],
        otherTreatment: '',
        otherTypeOfTherapyDetails: '',
        specialProcedureDetail: '',
      },
    ],
    isDoctorInJail: undefined,
    jailDoctorDetail: [
      {
        organizationName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        contactPersonFirstName: '',
        contactPersonLastName: '',
        arrivedDate: '',
        leftDate: '',
      },
    ],
    isPublicWelfare: undefined,
    welfareDetail: [
      {
        organizationName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        contactPersonFirstName: '',
        contactPersonLastName: '',
        arrivedDate: '',
        leftDate: '',
      },
    ],
    isRecordHeldByAttorney: undefined,
    attorneyDetail: [
      {
        organizationName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        contactPersonFirstName: '',
        contactPersonLastName: '',
        arrivedDate: '',
        leftDate: '',
      },
    ],
    haveDisabilityInsurance: undefined,
    insuranceDetail: [
      {
        organizationName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        contactPersonFirstName: '',
        contactPersonLastName: '',
        arrivedDate: '',
        leftDate: '',
      },
    ],
    isVocationalRehabilitationService: undefined,
    rehabilitationOrganizationDetail: [
      {
        organizationName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        contactPersonFirstName: '',
        contactPersonLastName: '',
        arrivedDate: '',
        leftDate: '',
      },
    ],
    isWorkersCompensation: undefined,
    workersCompensationDetail: [
      {
        organizationName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        contactPersonFirstName: '',
        contactPersonLastName: '',
        arrivedDate: '',
        leftDate: '',
      },
    ],
    isOtherHaveMedicalRecords: undefined,
    otherOrganizationDetail: [
      {
        organizationName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        phoneNumber: '',
        contactPersonFirstName: '',
        contactPersonLastName: '',
        arrivedDate: '',
        leftDate: '',
      },
    ],
  },
  MedicalTest: {
    medicalDetailId: '',
    isMedicalTestCompleted: undefined,
    medicalTest: [
      {
        medicalTestName: '',
        suffix: '',
        doctorFirstName: '',
        doctorLastName: '',
        testedBodyPart: '',
        mostRecentDateTestPerformed: '',
        futureDateTestPerform: '',
      },
    ],
  },
  Medicine: {
    medicalDetailId: '',
    isMedication: undefined,
    medication: [
      {
        medicationName: '',
        suffix: '',
        doctorFirstName: '',
        doctorLastName: '',
        medicationReason: '',
        sideEffects: '',
      },
    ],
  },
  AdditionalInformation: {
    medicalDetailId: '',
    additionalInformation: '',
  },
}

// Define the reducers that can modify the state
const MedicalDetailsStore = createSlice({
  name: 'MedicalDetails',
  initialState,
  reducers: {
    resetMedicalDetails: (state) => {
      state.GeneralHealth = {
        applicationId: '',
        dateOfBirth: '',
        gender: '',
        feet: 0,
        inch: 0,
        weight: 0,
      }
      state.VisionAndHearing = {
        medicalDetailId: '',
        zeroOrLessVision2020: undefined,
        visionCorrectorDetail: '',
        isImpairedVision: undefined,
        visionRelatedSymptoms: [],
        additionalVisionRelatedSymptoms: '',
        isPartialOrTotalDeaf: undefined,
        isUsingHearingAids: undefined,
        isImpairedHearing: undefined,
        hearingSymptoms: [],
        additionalHearingRelatedSymptoms: '',
      }
      state.IllnessesInjuriesAndConditions = {
        medicalDetailId: '',
        muscleOrMemoryRelatedSymptoms: [],
        energyOrSleepRelatedSymptoms: [],
        feverOrSkinRelatedSymptoms: [],
        breathingOrHeartRelatedSymptoms: [],
        speechRelatedSymptoms: [],
        memoryRelatedSymptoms: [],
        mentalHealthRelatedSymptoms: [],
        urinarySymptoms: [],
        dizzinessOrHeadacheRelatedSymptoms: [],
        isSeizureRelatedSymptoms: undefined,
        seizureRelatedSymptoms: [],
        anotherSymptoms: '',
        howLongSymptomLast: '',
        isAfterEffectSeizureExperience: undefined,
        seizureAfterEffectExperience: [],
        emotionalOrLearningProblems: [],
        speechSymptomsDuration: '',
        headachemigrainesymptoms: [],
        headachemigrainesymptomduration: '',
        seizureaftereffectsymptomsduration: '',
      }
      state.MedicalProfessionalDetail = {
        medicalDetailId: '',
        isMentalIllness: undefined,
        mentalIllnessMedicalProfessionals: [
          {
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            doctorMedicalSpecialty: '',
            officeOrClinicName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            firstAppointmentDate: '',
            lastAppointmentDate: '',
            nextAppointmentDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
        isPhysicalIllness: undefined,
        physicalIllnessMedicalProfessionals: [
          {
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            doctorMedicalSpecialty: '',
            officeOrClinicName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            firstAppointmentDate: '',
            lastAppointmentDate: '',
            nextAppointmentDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
      }
      state.HospitalAndMedicalFacilityCare = {
        medicalDetailId: '',
        haveEmergencyRoomVisit: undefined,
        haveEmergencyRoomVisitMedicalFacility: [
          {
            hospitalName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            arrivedDate: '',
            leftDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
        haveMedicalFacility: undefined,
        haveMedicalFacilityMedicalFacility: [
          {
            hospitalName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            arrivedDate: '',
            leftDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
        haveMentalHeathTreatment: undefined,
        haveMentalHeathTreatmentMedicalFacility: [
          {
            hospitalName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            arrivedDate: '',
            leftDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
        haveInpatientOrOverNightStay: undefined,
        haveInpatientOrOverNightStayMedicalFacility: [
          {
            hospitalName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            arrivedDate: '',
            leftDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
        haveOutpatientOrSamedayVisit: undefined,
        haveOutpatientOrSamedayVisitMedicalFacility: [
          {
            hospitalName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            arrivedDate: '',
            leftDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
        haveSurgeries: undefined,
        haveSurgeriesMedicalFacility: [
          {
            hospitalName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            arrivedDate: '',
            leftDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
        havePhysicalTherapy: undefined,
        havePhysicalTherapyMedicalFacility: [
          {
            hospitalName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            arrivedDate: '',
            leftDate: '',
            treatmentType: [],
            typeOfTherapyDoctorProvided: [],
            otherTreatment: '',
            otherTypeOfTherapyDetails: '',
            specialProcedureDetail: '',
          },
        ],
        isDoctorInJail: undefined,
        jailDoctorDetail: [
          {
            organizationName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            contactPersonFirstName: '',
            contactPersonLastName: '',
            arrivedDate: '',
            leftDate: '',
          },
        ],
        isPublicWelfare: undefined,
        welfareDetail: [
          {
            organizationName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            contactPersonFirstName: '',
            contactPersonLastName: '',
            arrivedDate: '',
            leftDate: '',
          },
        ],
        isRecordHeldByAttorney: undefined,
        attorneyDetail: [
          {
            organizationName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            contactPersonFirstName: '',
            contactPersonLastName: '',
            arrivedDate: '',
            leftDate: '',
          },
        ],
        haveDisabilityInsurance: undefined,
        insuranceDetail: [
          {
            organizationName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            contactPersonFirstName: '',
            contactPersonLastName: '',
            arrivedDate: '',
            leftDate: '',
          },
        ],
        isVocationalRehabilitationService: undefined,
        rehabilitationOrganizationDetail: [
          {
            organizationName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            contactPersonFirstName: '',
            contactPersonLastName: '',
            arrivedDate: '',
            leftDate: '',
          },
        ],
        isWorkersCompensation: undefined,
        workersCompensationDetail: [
          {
            organizationName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            contactPersonFirstName: '',
            contactPersonLastName: '',
            arrivedDate: '',
            leftDate: '',
          },
        ],
        isOtherHaveMedicalRecords: undefined,
        otherOrganizationDetail: [
          {
            organizationName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            phoneNumber: '',
            contactPersonFirstName: '',
            contactPersonLastName: '',
            arrivedDate: '',
            leftDate: '',
          },
        ],
      }
      state.MedicalTest = {
        medicalDetailId: '',
        isMedicalTestCompleted: undefined,
        medicalTest: [
          {
            medicalTestName: '',
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            testedBodyPart: '',
            mostRecentDateTestPerformed: '',
            futureDateTestPerform: '',
          },
        ],
      }
      state.Medicine = {
        medicalDetailId: '',
        isMedication: undefined,
        medication: [
          {
            medicationName: '',
            suffix: '',
            doctorFirstName: '',
            doctorLastName: '',
            medicationReason: '',
            sideEffects: '',
          },
        ],
      }
      state.AdditionalInformation = {
        medicalDetailId: '',
        additionalInformation: '',
      }
    },
    setGeneralHealth: (
      state,
      action: { payload: typeof initialState.GeneralHealth },
    ) => {
      state.GeneralHealth = action.payload
    },
    setVisionAndHearing: (
      state,
      action: { payload: typeof initialState.VisionAndHearing },
    ) => {
      state.VisionAndHearing = action.payload
    },
    setIllnessesInjuriesAndConditions: (
      state,
      action: { payload: typeof initialState.IllnessesInjuriesAndConditions },
    ) => {
      state.IllnessesInjuriesAndConditions = action.payload
    },
    setMedicalProfessionalDetail: (
      state,
      action: { payload: typeof initialState.MedicalProfessionalDetail },
    ) => {
      state.MedicalProfessionalDetail = action.payload
    },
    setHospitalAndMedicalFacilityCare: (
      state,
      action: { payload: typeof initialState.HospitalAndMedicalFacilityCare },
    ) => {
      state.HospitalAndMedicalFacilityCare = action.payload
    },
    setMedicalTest: (
      state,
      action: { payload: typeof initialState.MedicalTest },
    ) => {
      state.MedicalTest = action.payload
    },
    setMedicalFlagData: (
      state,
      action: { payload: typeof initialState.medicalFlagData },
    ) => {
      state.medicalFlagData = action.payload
    },
    setMedicine: (state, action: { payload: typeof initialState.Medicine }) => {
      state.Medicine = action.payload
    },
    setAdditionalInformation: (
      state,
      action: { payload: typeof initialState.AdditionalInformation },
    ) => {
      state.AdditionalInformation = action.payload
    },
    setMedicalDetails: (state, action: { payload: typeof initialState }) => {
      state.GeneralHealth = action.payload.GeneralHealth
      state.VisionAndHearing = action.payload.VisionAndHearing
      state.IllnessesInjuriesAndConditions =
        action.payload.IllnessesInjuriesAndConditions
      state.MedicalProfessionalDetail = action.payload.MedicalProfessionalDetail
      state.HospitalAndMedicalFacilityCare =
        action.payload.HospitalAndMedicalFacilityCare
      state.MedicalTest = action.payload.MedicalTest
      state.Medicine = action.payload.Medicine
      state.AdditionalInformation = action.payload.AdditionalInformation
    },
  },
})

export default MedicalDetailsStore.reducer

export const {
  resetMedicalDetails,
  setGeneralHealth,
  setVisionAndHearing,
  setIllnessesInjuriesAndConditions,
  setMedicalProfessionalDetail,
  setHospitalAndMedicalFacilityCare,
  setMedicalTest,
  setMedicine,
  setMedicalDetails,
  setAdditionalInformation,
  setMedicalFlagData,
} = MedicalDetailsStore.actions
