import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Divider, Grid } from '@mui/material'
import Styles from '../index.module.scss'
import DisabilityEmployment from '../EditForms/DisabilityEmployment'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import { convertISOToDate, currentYear, hasEmpty } from 'src/Utils/Constants'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
const DisabilityDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)
  const disabilityEmploymentRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    disabilityEmploymentRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.DisabilityDetails?.DisabilityEmployment,
  )
  const disabilityEmploymentSectionId = useAppSelector(
    (state) =>
      state?.steps?.DisablilityApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Disability & Employment',
      )?.sectionId,
  )
  return (
    <div id={disabilityEmploymentSectionId} ref={disabilityEmploymentRef}>
      <>
        {!editMode ? (
          <>
            <Box className={Styles.MainBox}>
              <Box className={Styles.title}>
                <Box className={Styles.main}>
                  <Typography variant='h6' className={Styles.textWrapper}>
                    Disability & Employment
                  </Typography>
                  <Button
                    className={Styles.button}
                    disabled={hasEmpty(data)}
                    onClick={handleEditToggle}
                  >
                    <Typography className={Styles.div}>Edit</Typography>
                  </Button>
                </Box>
                <div className={Styles.dottedLine} />
              </Box>
              {!hasEmpty(data) ? (
                <>
                  <Box className={Styles.div2}>
                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.p}>
                          How your disability is affecting your employment.
                        </Typography>
                      </Box>

                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data?.affectingEmploymentDetail}
                        </Typography>
                      </Box>
                    </Box>
                    {data?.affectingEmploymentDetail !==
                    'I have never worked' ? (
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            When you were employed during the last two years.
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          {data?.employedDuringLast2YearDetail.map(
                            (item: string) => (
                              <>
                                <Typography className={Styles.textWrapper4}>
                                  {item}
                                </Typography>
                              </>
                            ),
                          )}
                        </Box>
                      </Box>
                    ) : null}

                    {data?.affectingEmploymentDetail ===
                      'I have stopped working' && (
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            I stopped working
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.reasonToStoppedWorking}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    {data?.affectingEmploymentDetail ===
                      'I have stopped working' && (
                      <Box className={Styles.element}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Reason behind stopped working
                          </Typography>
                        </Box>

                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.explainStoppedWorking}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {data?.affectingEmploymentDetail ===
                      'I have never worked' && (
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Condition(s) become severe enough to prevent you
                            from working
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {convertISOToDate(
                              data?.disabilityKeepFromWorkingDate,
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    {data?.affectingEmploymentDetail ===
                      'I have stopped working' && (
                      <Box className={Styles.element2}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Condition(s) become severe enough to keep you from
                            working
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {convertISOToDate(data?.dateOfStoppedWorking)}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    {data?.affectingEmploymentDetail !==
                      'I have never worked' && (
                      <Box className={Styles.element}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Your condition, injury, or illness require changes
                            to your work activity while you were working
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.isRequireWorkChanges ? 'yes' : 'No'}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {data?.affectingEmploymentDetail !==
                      'I have never worked' && data?.isRequireWorkChanges ? (
                      <>
                        {data?.workChangeStartDate && (
                          <Box className={Styles.element2}>
                            <Box className={Styles.question}>
                              <Typography className={Styles.textWrapper3}>
                                Changes begin on
                              </Typography>
                            </Box>
                            <Box className={Styles.ans}>
                              <Typography className={Styles.textWrapper2}>
                                {convertISOToDate(data?.workChangeStartDate)}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </>
                    ) : null}

                    {data?.affectingEmploymentDetail !==
                      'I have never worked' && (
                      <Box className={Styles.element}>
                        <Box className={Styles.question}>
                          <Typography className={Styles.textWrapper3}>
                            Made more than $1,470 during any month in{' '}
                            {currentYear} since the date(s) you provided above
                          </Typography>
                        </Box>
                        <Box className={Styles.ans}>
                          <Typography className={Styles.textWrapper2}>
                            {data?.haveMadeMoreMoneyInAnyMonth ? 'Yes' : 'No'}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    <Box className={Styles.element}>
                      <Box className={Styles.question}>
                        <Typography className={Styles.p}>
                          Do you intent to do work in {currentYear}?
                        </Typography>
                      </Box>

                      <Box className={Styles.ans}>
                        <Typography className={Styles.textWrapper2}>
                          {data?.intendToWorkNextYear ? 'Yes' : 'No'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <CustomAlert message='no data available.' />
              )}
            </Box>
          </>
        ) : (
          <>
            <Grid m={2}>
              <Grid container m={2}>
                <Grid lg={10} className='alignItemCenter' display='flex'>
                  <Typography variant='h1' className='FontBold'>
                    Disability & Employment
                  </Typography>
                </Grid>
                <Divider
                  sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
                />
                <Grid container m={2} ml={0}>
                  <>
                    <DisabilityEmployment handleEditToggle={handleEditToggle} />
                  </>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </div>
  )
}

export default DisabilityDetails
