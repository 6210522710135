import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import FooterContent from 'src/Components/FooterContent'
import IconPage from 'src/Components/IconPage/index'
import styles from './index.module.scss'
import { ROUTES } from 'src/Utils/Routes'
import { useAppDispatch } from 'src/Redux/hooks'
import signBg from 'src/Assets/Icons/signBg.svg'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { COLORS, FONT_ONE, FW_BOLD, FW_REGULAR } from 'src/Utils/Constants'
import { styled } from '@mui/material/styles'

import {
  ResetPasswordRequest,
  VerifyForgetLinkRequest,
} from 'src/Redux/Slices/authSlice'

const ResetPassword: React.FunctionComponent = () => {
  const SubTypography = styled(Typography)<TypographyProps>({
    fontWeight: FW_REGULAR,
    fontSize: FONT_ONE,
    color: COLORS.COLOR_BLUE,
    marginBottom: '50px',
  })
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [verified, setVerified] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [loading, setLoading] = useState(true)
  const {
    br20,
    containerHeight,
    bgCmn1,
    wBox,
    p10,
    secondContainerHeight,
    center,
  } = styles

  const validatePassword = (value: string) => {
    const lowerCase = /[a-z]/g
    const upperCase = /[A-Z]/g
    const number = /[0-9]/g
    const isValid =
      value.length >= 8 &&
      value.match(lowerCase) &&
      value.match(upperCase) &&
      value.match(number)

    if (!isValid) {
      setPasswordError(
        'Password must include at least 8 characters, one uppercase, one lowercase, and one number.',
      )
    } else {
      setPasswordError('')
    }
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setPassword(value)
    validatePassword(value)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword)
  }

  const validateConfirmPassword = (value: string) => {
    if (value !== password) {
      setConfirmPasswordError('Passwords do not match')
    } else {
      setConfirmPasswordError('')
    }
  }

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    setConfirmPassword(value)
    validateConfirmPassword(value)
  }
  const link = useLocation().pathname.split('/')[2]

  useEffect(() => {
    dispatch(VerifyForgetLinkRequest({ link })).then((res) => {
      setLoading(false)
      setVerified(res.payload?.success)
    })
  }, [])

  const handleSubmit = () => {
    const data = { password, link }
    if (password !== '' || confirmPassword !== '') {
      dispatch(ResetPasswordRequest(data))
        .then((res) => {
          if (res.payload?.success) {
            setTimeout(() => {
              navigate(ROUTES.SIGN_IN)
            }, 3000)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else setPasswordError('Please enter a password')
  }

  const text = loading ? (
    <Typography>Loading...</Typography>
  ) : (
    <Typography>Link Expired or Something went wrong</Typography>
  )

  return (
    <>
      <Grid
        container
        style={{ backgroundImage: `url(${signBg})` }}
        className={` ${p10} ${containerHeight}`}
      >
        <IconPage />
        {verified ? (
          <Grid
            container
            lg={6}
            md={6}
            sm={12}
            xs={12}
            direction={'row'}
            className={`${bgCmn1} ${br20} ${secondContainerHeight}`}
          >
            <Grid item lg={12} md={12} sm={12} xs={12} height='10vh'></Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              height='80vh'
              className={`${center}`}
            >
              <Grid className={`${wBox}`}>
                <Typography variant='h5' sx={{ fontWeight: FW_BOLD }}>
                  Reset Password
                </Typography>

                <SubTypography variant='body1' className={styles.subTitle}>
                  Sign in by filling out the below details.
                </SubTypography>
                {/* <Snackbar
                  open={!!passwordError || !!confirmPasswordError}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Alert
                    severity={
                      passwordError || confirmPasswordError
                        ? 'error'
                        : 'success'
                    }
                  >
                    {passwordError || confirmPasswordError}
                  </Alert>
                </Snackbar> */}
                <Box className={styles.flexStartBox}>
                  <Typography className={styles.textFieldWrapper}>
                    Password
                  </Typography>
                  <TextField
                    tabIndex={-1}
                    fullWidth
                    variant='outlined'
                    type={showPassword ? 'text' : 'password'}
                    className={styles.field}
                    onChange={handlePasswordChange}
                    value={password}
                    placeholder='Enter your password'
                    helperText={passwordError}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge='end'
                          >
                            {!showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(passwordError)}
                  />
                </Box>
                <Box className={styles.flexStartBox} pt={2}>
                  <Typography className={styles.textFieldWrapper}>
                    Repeat Password
                  </Typography>
                  <TextField
                    tabIndex={-1}
                    fullWidth
                    variant='outlined'
                    type={showRepeatPassword ? 'text' : 'password'}
                    className={styles.field}
                    placeholder='Repeat your password'
                    onChange={handleConfirmPasswordChange}
                    value={confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={toggleRepeatPasswordVisibility}
                            edge='end'
                          >
                            {!showRepeatPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={confirmPasswordError}
                    error={Boolean(confirmPasswordError)}
                  />
                </Box>
                <Button
                  onClick={() => {
                    handleSubmit()
                  }}
                  className={styles.btnSubmit}
                  variant='contained'
                  fullWidth
                >
                  Reset Password
                </Button>
                <Button
                  variant='text'
                  color='primary'
                  fullWidth
                  onClick={() => {
                    navigate(ROUTES.SIGN_IN)
                  }}
                >
                  Back to Login
                </Button>
              </Grid>
            </Grid>

            <FooterContent />
          </Grid>
        ) : (
          text
        )}
      </Grid>
    </>
  )
}

export default ResetPassword
