import React, { useState } from 'react'
import Head from './ApplicationDetailCards/Header'
import List from './ApplicationDetailCards/List'
const Applications: React.FunctionComponent = () => {
  const [q, setQ] = useState('')
  return (
    <>
      <Head setQ={setQ} />
      <List searchstring={q} />
    </>
  )
}

export default Applications
