import { baseAPI } from '../Common/httpCommon'

const viewSubscriptionList = async (token: string) => {
  try {
    return await baseAPI.get('subscription/list', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const subscriptionDetails = async (token: string, id: string) => {
  try {
    return await baseAPI.get('subscription/detail?uuid=' + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const addSubscription = async (
  token: string,
  data: {
    uuid: string
    name: string
    plan: string
    amount: number
    subscriptionPeriod: number
    numberOfApplication?: number
    numberOfseats?: number
    description: string
    isActive: boolean
  },
) => {
  try {
    return await baseAPI.post('subscription/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const editSubscription = async (
  token: string,
  data: {
    uuid: string
    name: string
    plan: string
    amount: number
    subscriptionPeriod: number
    numberOfApplication?: number
    numberOfseats?: number
    description: string
    isActive: boolean
  },
) => {
  try {
    return await baseAPI.put('subscription/update?uuid=' + data.uuid, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const deleteSubscription = async (
  token: string,
  data: { uuid: string; isActive: boolean },
) => {
  try {
    const { isActive } = data
    return await baseAPI.put(
      'subscription/delete?uuid=' + data.uuid,
      { isActive },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  } catch (e: any) {
    return e
  }
}

const SubscriptionService = {
  viewSubscriptionList,
  addSubscription,
  subscriptionDetails,
  deleteSubscription,
  editSubscription,
}
export default SubscriptionService
