import * as React from 'react'
import { Grid, Typography, Button, Link, Dialog } from '@mui/material'
import Style from '../index.module.scss'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/Utils/Routes'
import Failed from 'src/Assets/Icons/close-circle.svg'

interface PaymentStatusProps {
  status: string
  message: string
  paymentId: string
  date: string
  supportLink: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
}

function PaymentStatus({
  status,
  message,
  paymentId,
  date,
  supportLink,
  open,
  setOpen,
  handleOpen,
}: PaymentStatusProps) {
  const handleClose = () => {
    setOpen(false)
  }
  const navigate = useNavigate()

  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={{
            borderRadius: '20px',
            backgroundColor: '#fff',
            padding: { xs: '20px', md: '40px' },
            minWidth: 500,
            maxWidth: 600,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '150%',
          }}
        >
          <Grid item>
            <img
              loading='lazy'
              src={Failed}
              style={{
                width: '64px',
                aspectRatio: 1,
                objectFit: 'fill',
                objectPosition: 'center',
              }}
              alt=''
            />
          </Grid>
          <Grid item>
            <Typography
              variant='h5'
              align='center'
              sx={{
                color: '#071437',
                marginTop: '30px',
                fontWeight: 500,
                fontSize: '24px',
                lineHeight: '133%',
              }}
            >
              {status}
            </Typography>
          </Grid>
          <Grid sx={{ textwrap: 'balance' }}>
            <Typography
              variant='body1'
              align='center'
              sx={{
                color: '#252f4a',
                marginTop: '15px',
                textWrap: 'balance',
              }}
            >
              Seems like there was some trouble. We are here with you. Just hold
              back.
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant='body2'
              align='center'
              sx={{
                color: '#78829d',
                marginTop: '15px',
              }}
            >
              Payment ID: {paymentId}, {date}
            </Typography>
          </Grid>
          {/* <Grid item>
            <Typography
              variant='body2'
              align='center'
              sx={{
                color: '#78829d',
                marginTop: '15px',
              }}
            >
              {date}
            </Typography>
          </Grid> */}
          <Grid item>
            <Button
              variant='contained'
              sx={{
                borderRadius: '12px',
                backgroundColor: '#7752fe',
                color: '#fff',
                fontWeight: 500,
                marginTop: '30px',
                padding: '13px 25px',
                '&:focus': { outline: '2px solid #7752fe' },
              }}
              onClick={() => {
                navigate(ROUTES.SIGN_UP)
              }}
            >
              Try again
            </Button>
          </Grid>
          <Grid item>
            <Typography
              variant='body2'
              align='center'
              sx={{
                color: '#7752fe',
                marginTop: '30px',
                lineHeight: '24px',
              }}
            >
              Have a question?{' '}
              <Link
                href={supportLink}
                target='_blank'
                sx={{ color: '#7752fe' }}
              >
                Contact Support
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default PaymentStatus
