/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Style from './index.module.scss'
// import { useState } from 'react'
import { useEffect, useState } from 'react'
import ActiveRadio from 'src/Assets/Icons/activeRadio.svg'
import InactiveRadio from 'src/Assets/Icons/inactiveRadio.svg'
import remove from 'src/Assets/Icons/remove.svg'
import { Name } from 'src/Components/Fields'
import { SmallRadioBox } from 'src/Components/Application/CommonRadioBox'

interface dependents {
  firstName: string
  lastName: string
  isYoungerThan17: boolean | undefined
  isChildMarried: boolean | undefined
  isChildDisabled: boolean | undefined
  isChild1819SchoolUnmarried: boolean | undefined
  isChildDisabledBeforeAge22: boolean | undefined
}

const ProfileBox = ({
  errors,
  childStatus,
  // setChildStatus,
  setChildhandle,
  formValues,
  changeHandler,
  handleRemove,
  index,
}: {
  errors: Record<string, string>
  childStatus: {
    isYoungerThan17: boolean
    isChild1819SchoolUnmarried: boolean
    isChildDisabledBeforeAge22: boolean
  }
  setChildhandle: (index: number, fieldName: string, value: boolean) => void
  formValues: dependents
  changeHandler: (data: { name: string; value: string | number }) => void
  handleRemove: () => void
  index: number
}) => {
  // const [isYoungerThan17, setisYoungerThan17] = useState(false)
  // const [isChildDisabledBeforeAge22, setisChildDisabledBeforeAge22] =
  //   useState(false)
  // const [isChild1819SchoolUnmarried, setisChild1819SchoolUnmarried] =
  //   useState(false)
  // useEffect(() => {
  //   setisYoungerThan17(childStatus?.isYoungerThan17)
  //   setisChildDisabledBeforeAge22(childStatus?.isChildDisabledBeforeAge22)
  //   setisChild1819SchoolUnmarried(childStatus?.isChild1819SchoolUnmarried)
  // }, [childStatus])
  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>Child {index + 1}</Typography>
          {index > 0 && (
            <img
              src={remove}
              alt=''
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleRemove()
              }}
            />
          )}
        </Grid>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Name
            name='firstName'
            value={formValues?.firstName}
            title="Child's First Name"
            handleChange={changeHandler}
            error={errors[`firstName[${index}]`] || ''}
          />
          <Name
            name='lastName'
            value={formValues?.lastName}
            title="Child's Last Name"
            handleChange={changeHandler}
            error={errors[`lastName[${index}]`] || ''}
          />
        </Grid>
        <SmallRadioBox
          name='isYoungerThan17'
          value={formValues.isYoungerThan17}
          onClick={(value) => {
            changeHandler({ name: 'isYoungerThan17', value })
          }}
          title='Is this child younger than 17?'
          error={errors.isYoungerThan17}
        />
        {/* <Grid xs={12} py={1} width={'100%'}>
          <InputLabel className={Style.label}>
            Is this child younger than 17?
          </InputLabel>
          <FormControl>
            <RadioGroup
              aria-label='isYoungerThan17'
              className={Style.element}
              id='isYoungerThan17'
              name='isYoungerThan17'
              value={isYoungerThan17}
            >
              <FormControlLabel
                className={
                  childStatus?.isYoungerThan17 === true
                    ? Style.radioSelectionBoxSelect
                    : Style.radioSelectionBoxes
                }
                label=''
                value={true}
                control={
                  <Box display={'flex'} alignItems={'center'}>
                    <Radio
                      checked={childStatus?.isYoungerThan17 === true}
                      checkedIcon={<img src={ActiveRadio} alt='On' />}
                      icon={<img src={InactiveRadio} alt='Off' />}
                      value={true}
                      onClick={() => {
                        setChildhandle(index, 'isYoungerThan17', true)
                      }}
                    />
                    <Box className={Style.radioText}>
                      <Typography variant='body1' className={Style.TxtQ}>
                        Yes
                      </Typography>
                    </Box>
                  </Box>
                }
              />
              <FormControlLabel
                className={
                  !childStatus?.isYoungerThan17
                    ? Style.radioSelectionBoxSelect
                    : Style.radioSelectionBoxes
                }
                label=''
                value={false}
                control={
                  <Box display={'flex'} alignItems={'center'}>
                    <Radio
                      checked={childStatus?.isYoungerThan17 === false}
                      checkedIcon={<img src={ActiveRadio} alt='On' />}
                      icon={<img src={InactiveRadio} alt='Off' />}
                      value={false}
                      onClick={() => {
                        setChildhandle(index, 'isYoungerThan17', false)
                      }}
                    />
                    <Box className={Style.radioText}>
                      <Typography variant='body1' className={Style.TxtQ}>
                        No
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid> */}

        <SmallRadioBox
          name='isChild1819SchoolUnmarried'
          value={formValues.isChild1819SchoolUnmarried}
          onClick={(value) => {
            changeHandler({ name: 'isChild1819SchoolUnmarried', value })
          }}
          title='Is this child 18 to 19 years of age, unmarried, and attending school
            (i.e., below college level) full-time?'
          error={errors.isChild1819SchoolUnmarried}
        />
        {/* <Grid xs={12} py={1} width={'100%'}>
          <InputLabel className={Style.label}>
            Is this child 18 to 19 years of age, unmarried, and attending school
            (i.e., below college level) full-time?
          </InputLabel>
          <FormControl>
            <RadioGroup
              aria-label='isChild1819SchoolUnmarried'
              className={Style.element}
              id='isChild1819SchoolUnmarried'
              name='isChild1819SchoolUnmarried'
              value={isChild1819SchoolUnmarried}
            >
              <FormControlLabel
                className={
                  childStatus?.isChild1819SchoolUnmarried === true
                    ? Style.radioSelectionBoxSelect
                    : Style.radioSelectionBoxes
                }
                label=''
                value={true}
                control={
                  <Box display={'flex'} alignItems={'center'}>
                    <Radio
                      checked={childStatus?.isChild1819SchoolUnmarried === true}
                      checkedIcon={<img src={ActiveRadio} alt='On' />}
                      icon={<img src={InactiveRadio} alt='Off' />}
                      value={true}
                      onClick={() => {
                        setChildhandle(
                          index,
                          'isChild1819SchoolUnmarried',
                          true,
                        )
                      }}
                    />
                    <Box className={Style.radioText}>
                      <Typography variant='body1' className={Style.TxtQ}>
                        Yes
                      </Typography>
                    </Box>
                  </Box>
                }
              />
              <FormControlLabel
                className={
                  !childStatus?.isChild1819SchoolUnmarried
                    ? Style.radioSelectionBoxSelect
                    : Style.radioSelectionBoxes
                }
                label=''
                value={false}
                control={
                  <Box display={'flex'} alignItems={'center'}>
                    <Radio
                      checked={!childStatus?.isChild1819SchoolUnmarried}
                      checkedIcon={<img src={ActiveRadio} alt='On' />}
                      icon={<img src={InactiveRadio} alt='Off' />}
                      value={false}
                      onClick={() => {
                        setChildhandle(
                          index,
                          'isChild1819SchoolUnmarried',
                          false,
                        )
                      }}
                    />
                    <Box className={Style.radioText}>
                      <Typography variant='body1' className={Style.TxtQ}>
                        No
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid> */}

        <SmallRadioBox
          name='isChildDisabledBeforeAge22'
          value={formValues.isChildDisabledBeforeAge22}
          onClick={(value) => {
            changeHandler({ name: 'isChildDisabledBeforeAge22', value })
          }}
          title='Is this child disabled before age 22?'
          error={errors.isChildDisabledBeforeAge22}
        />
        {/* <Grid xs={12} py={1} width={'100%'}>
          <InputLabel className={Style.label}>
            Is this child disabled before age 22?
          </InputLabel>
          <FormControl>
            <RadioGroup
              aria-label='isChildDisabledBeforeAge22'
              className={Style.element}
              id='isChildDisabledBeforeAge22'
              name='isChildDisabledBeforeAge22'
              value={isChildDisabledBeforeAge22}
            >
              <FormControlLabel
                className={
                  childStatus?.isChildDisabledBeforeAge22 === true
                    ? Style.radioSelectionBoxSelect
                    : Style.radioSelectionBoxes
                }
                label=''
                value={true}
                control={
                  <Box display={'flex'} alignItems={'center'}>
                    <Radio
                      checked={childStatus?.isChildDisabledBeforeAge22 === true}
                      checkedIcon={<img src={ActiveRadio} alt='On' />}
                      icon={<img src={InactiveRadio} alt='Off' />}
                      value={true}
                      onClick={() => {
                        setChildhandle(
                          index,
                          'isChildDisabledBeforeAge22',
                          true,
                        )
                      }}
                    />
                    <Box className={Style.radioText}>
                      <Typography variant='body1' className={Style.TxtQ}>
                        Yes
                      </Typography>
                    </Box>
                  </Box>
                }
              />
              <FormControlLabel
                className={
                  !childStatus?.isChildDisabledBeforeAge22
                    ? Style.radioSelectionBoxSelect
                    : Style.radioSelectionBoxes
                }
                label=''
                value={false}
                control={
                  <Box display={'flex'} alignItems={'center'}>
                    <Radio
                      checked={!childStatus?.isChildDisabledBeforeAge22}
                      checkedIcon={<img src={ActiveRadio} alt='On' />}
                      icon={<img src={InactiveRadio} alt='Off' />}
                      value={false}
                      onClick={() => {
                        setChildhandle(
                          index,
                          'isChildDisabledBeforeAge22',
                          false,
                        )
                      }}
                    />
                    <Box className={Style.radioText}>
                      <Typography variant='body1' className={Style.TxtQ}>
                        No
                      </Typography>
                    </Box>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid> */}
      </Box>
    </>
  )
}
export default ProfileBox
