import React, { useEffect, type FC } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ViewApplicationReview } from 'src/Redux/Slices/DashboardSlice'
const ApplicationOverview: FC = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(ViewApplicationReview())
  }, [])

  const applicationReview = useAppSelector(
    (state) => state?.dashboard?.data?.applicationReview,
  )

  return (
    <Box className={Style.overviewBox}>
      {/* <Grid item xs={12} lg={12}>
        <Typography className={Style.overviewBoxTitle}>
          Application Overview
        </Typography>
      </Grid> */}
      <Grid item xs={12} lg={12} width={'100%'} display='flex' gap={'2%'}>
        <Box className={Style.overviewGridBox}>
          <Box className={Style.overviewCard}>
            <Box className={Style.overviewCardContent}>
              <Typography variant='h4' className={Style.overviewCardCount}>
                {applicationReview?.Submitted ?? 0}
              </Typography>
              <img src={Icons?.CompletedApplication} alt='organization' />
            </Box>
            <Typography variant='h2' className={Style.overviewCardTxt}>
              Submitted Applications
            </Typography>
          </Box>
        </Box>

        <Box className={Style.overviewGridBox}>
          <Box className={Style.overviewCard}>
            <Box className={Style.overviewCardContent}>
              <Typography variant='h4' className={Style.overviewCardCount}>
                {applicationReview?.Assigned ?? 0}
              </Typography>
              <img src={Icons?.ApproveApplication} alt='organization' />
            </Box>
            <Typography variant='h2' className={Style.overviewCardTxt}>
              Assigned Applications
            </Typography>
          </Box>
        </Box>

        <Box className={Style.overviewGridBox}>
          <Box className={Style.overviewCard}>
            <Box className={Style.overviewCardContent}>
              <Typography variant='h4' className={Style.overviewCardCount}>
                {applicationReview?.InProgress ?? 0}
              </Typography>
              <img src={Icons?.InProgressApplication} alt='organization' />
            </Box>
            <Typography variant='h2' className={Style.overviewCardTxt}>
              In Progress Applications
            </Typography>
          </Box>
        </Box>

        <Box className={Style.overviewGridBox}>
          <Box className={Style.overviewCard}>
            <Box className={Style.overviewCardContent}>
              <Typography variant='h4' className={Style.overviewCardCount}>
                {applicationReview?.Flagged ?? 0}
              </Typography>
              <img src={Icons?.FlaggedApplication} alt='organization' />
            </Box>
            <Typography variant='h2' className={Style.overviewCardTxt}>
              Flagged Applications
            </Typography>
          </Box>
        </Box>

        <Box className={Style.overviewGridBox}>
          <Box className={Style.overviewCard}>
            <Box className={Style.overviewCardContent}>
              <Typography variant='h4' className={Style.overviewCardCount}>
                {applicationReview?.InReview ?? 0}
              </Typography>
              <img src={Icons?.InReviewApplication} alt='organization' />
            </Box>
            <Typography variant='h2' className={Style.overviewCardTxt}>
              In Review Applications
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}

export default ApplicationOverview
