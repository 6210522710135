// This slice manages the state for the sign in feature
import { createSlice } from '@reduxjs/toolkit'
import { LegalDetailsType } from '../../Services/Types/applicationType'

// Define the initial state of the slice
const initialState: LegalDetailsType = {
  LegalName: {
    applicationId: '',
    haveUsedOtherName: undefined,
    legalNames: [
      {
        firstName: '',
        middleName: '',
        lastName: '',
      },
    ],
  },
  IdentificationNumbers: {
    legalId: '',
    socialSecurityNumber: '',
    otherSocialSecurityNumbers: [''],
    isUsingOtherSSN: undefined,
  },
  ReceivingDisability: {
    legalId: '',
    isHavingBankAccount: undefined,
    accountType: '',
    routingNumber: '',
    accountNumber: '',
  },
}

// Define the reducers that can modify the state
const LegalDetailsStore = createSlice({
  name: 'LegalDetails',
  initialState,
  reducers: {
    resetLegalDetails: (state) => {
      state.LegalName = {
        applicationId: '',
        haveUsedOtherName: undefined,
        legalNames: [
          {
            firstName: '',
            middleName: '',
            lastName: '',
          },
        ],
      }
      state.IdentificationNumbers = {
        legalId: '',
        socialSecurityNumber: '',
        otherSocialSecurityNumbers: [''],
        isUsingOtherSSN: undefined,
      }
      state.ReceivingDisability = {
        legalId: '',
        isHavingBankAccount: undefined,
        accountType: '',
        routingNumber: '',
        accountNumber: '',
      }
    },
    setLegalDetails: (state, action) => {
      state.LegalName = action.payload?.LegalName
      state.IdentificationNumbers = action.payload?.IdentificationNumbers
      state.ReceivingDisability = action.payload?.ReceivingDisability
    },
    setLegalName: (
      state,
      action: { payload: typeof initialState.LegalName },
    ) => {
      state.LegalName = action.payload
    },

    setIdentificationNumbers: (
      state,
      action: { payload: typeof initialState.IdentificationNumbers },
    ) => {
      state.IdentificationNumbers = action.payload
    },
    setReceivingDisability: (
      state,
      action: { payload: typeof initialState.ReceivingDisability },
    ) => {
      state.ReceivingDisability = action.payload
    },
  },
})

export default LegalDetailsStore.reducer

export const {
  resetLegalDetails,
  setLegalName,
  setIdentificationNumbers,
  setReceivingDisability,
  setLegalDetails,
} = LegalDetailsStore.actions
