import { Grid } from '@mui/material'
import React from 'react'
import Logo from 'src/Assets/Images/logo_white.svg'
import User from 'src/Assets/Images/user-img.svg'
import styles from './index.module.scss'

const IconPage: React.FunctionComponent = () => {
  const { center, Dflex } = styles

  return (
    <>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid container direction={'row'}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            height='25vh'
            className={center}
            alignItems={'end'}
          >
            <img src={Logo} />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            height='60vh'
            className={`${center} ${Dflex}`}
          >
            <img src={User} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} height='15vh'></Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default IconPage
