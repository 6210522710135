import {
  Box,
  Button,
  Grid,
  Typography,
  Divider,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
  styled,
} from '@mui/material'
import '../index.scss'
import AddressCard from '../../Card'
import { useEffect, useRef, useState } from 'react'
import ContactInformation from '../../EditForms/BasicDetails/ContactInformation/index'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import { DynamicObj, hasEmpty } from 'src/Utils/Constants'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import { setStepCompleted } from 'src/Redux/Slices/stepSlice'
import Tooltip from '@mui/material/Tooltip'
import error from 'src/Assets/Icons/error.svg'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '16px',
  },
}))

const ErrorImage = styled('img')({
  marginRight: '8px', // Adjust margin as needed
  marginBottom: '-8px', // Adjust margin as needed
  paddingRight: '10px',
  paddingLeft: '10px',
  // position: 'absolute',
})

const Contact = () => {
  const [editMode, setEditMode] = useState(false)
  const dispatch = useAppDispatch()
  const contactRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    contactRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.BasicDetails?.ContactInformation,
  )

  const flagData = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.BasicDetails?.basicFlagData?.ContactInformation,
  )

  const assistancedata = useAppSelector(
    (state: RootState) => state?.applicationStore?.BasicDetails?.assistancedata,
  )

  useEffect(() => {
    if (data) {
      dispatch(
        setStepCompleted({
          section: 'BasicApplicationSteps',
          label: 'Contact',
        }),
      )
    }
  }, [data])

  function renderContactInfoPart(
    part: string | null | undefined,
    separator: string = ' ',
  ): string | null {
    return part ? `${part}${separator}` : ' ' // Concatenate the part with the separator
  }

  function renderContactInfo(data: DynamicObj) {
    const { firstName, middleName, lastName } = data

    const fullName = `${renderContactInfoPart(firstName)}${renderContactInfoPart(middleName)}${renderContactInfoPart(lastName)}`

    return fullName
  }

  function renderAddress(address: DynamicObj) {
    const { streetAddress, city, state, country, postalCode } = address

    const formattedAddress = `${renderContactInfoPart(streetAddress, ',')}${renderContactInfoPart(city, ',')}${renderContactInfoPart(state, ',')}${renderContactInfoPart(country, ',')}${renderContactInfoPart(postalCode)}`

    return formattedAddress
  }

  const contactSectionId = useAppSelector(
    (state) =>
      state?.steps?.BasicApplicationSteps?.find(
        (step: { label: string }) => step.label === 'Contact',
      )?.sectionId,
  )

  return (
    <div id={contactSectionId} ref={contactRef}>
      {!editMode ? (
        <>
          <Grid container m={2}>
            <Grid lg={10} className='alignItemCenter' display='flex'>
              <Typography variant='h1' className='FontBold'>
                Contact Information
              </Typography>
            </Grid>
            <Grid lg={2} sx={{ float: 'right' }}>
              <Box
                sx={{
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#6f737a',
                  padding: '6px 15px',
                  fontSize: '16px',
                  lineHeight: 1.5,
                }}
              >
                <Button
                  className='EditBtnStyle'
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  Edit
                </Button>
              </Box>
            </Grid>
            <Divider sx={{ width: '97%' }} />
          </Grid>

          {!hasEmpty(data) ? (
            <>
              <Grid container m={2}>
                <Typography variant='h2' className='FontBold' m={2}>
                  Applicant Details
                </Typography>
                <TableContainer>
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                      },
                      width: '50%',
                    }}
                  >
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Applicant Name
                      </StyledTableCell>
                      {data && (
                        <StyledTableCell
                          className={
                            flagData?.firstName?.length > 0 ||
                            flagData?.lastName?.length > 0
                              ? 'redFlagFonts'
                              : 'MediumBlackFonts'
                          }
                        >
                          {renderContactInfo(data)}

                          {flagData?.firstName?.length > 0 ||
                          flagData?.lastName?.length > 0 ? (
                            <>
                              <Tooltip
                                title={
                                  flagData?.firstName || flagData?.lastName
                                }
                              >
                                <ErrorImage src={error} alt='Error' />
                              </Tooltip>
                            </>
                          ) : null}
                        </StyledTableCell>
                      )}
                    </TableRow>
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        U.S. Phone Number
                      </StyledTableCell>
                      <StyledTableCell
                        className={
                          flagData?.isUSAPhoneNumber?.length > 0
                            ? 'redFlagFonts'
                            : 'MediumBlackFonts'
                        }
                      >
                        {data?.isUSAPhoneNumber ? 'Yes' : 'No'}
                        {flagData?.isUSAPhoneNumber?.length > 0 ? (
                          <>
                            <Tooltip title={flagData?.isUSAPhoneNumber}>
                              <ErrorImage src={error} alt='Error' />
                            </Tooltip>
                          </>
                        ) : null}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Applicant Phone Number
                      </StyledTableCell>
                      <StyledTableCell
                        className={
                          flagData?.phone?.length > 0
                            ? 'redFlagFonts'
                            : 'MediumBlackFonts'
                        }
                      >
                        {data?.phone}
                        {flagData?.phone?.length > 0 ? (
                          <>
                            <Tooltip title={flagData?.phone}>
                              <ErrorImage src={error} alt='Error' />
                            </Tooltip>
                          </>
                        ) : null}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell variant='head' className='grayFonts'>
                        Applicant Email
                      </StyledTableCell>
                      <StyledTableCell
                        className={
                          flagData?.email?.length > 0
                            ? 'redFlagFonts'
                            : 'MediumBlackFonts'
                        }
                      >
                        {data?.email}
                        {flagData?.email?.length > 0 ? (
                          <>
                            <Tooltip title={flagData?.email}>
                              <ErrorImage src={error} alt='Error' />
                            </Tooltip>
                          </>
                        ) : null}
                      </StyledTableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid m={2}>
                <Grid container m={2}>
                  {data?.physicalAddress?.streetAddress && (
                    <Grid lg={6} pr={2}>
                      <Typography variant='h3' className='FontBold' mb={2}>
                        Physical Address
                      </Typography>

                      <AddressCard
                        streetAddress={
                          data?.physicalAddress?.streetAddress ?? ''
                        }
                        city={data?.physicalAddress?.city ?? ''}
                        state={data?.physicalAddress?.state ?? ''}
                        postalCode={data?.physicalAddress?.postalCode ?? ''}
                        country={data?.physicalAddress?.country ?? ''}
                        flagData={flagData?.physicalAddress ?? {}}
                      />
                    </Grid>
                  )}

                  {!data?.isLiveAtPhysicalAddress ? (
                    <Grid lg={6} pr={2}>
                      <Typography variant='h3' className='FontBold' mb={2}>
                        Address Where You Currently Reside
                      </Typography>

                      {data?.currentAddress?.streetAddress && (
                        <AddressCard
                          streetAddress={
                            data?.currentAddress?.streetAddress ?? ''
                          }
                          city={data?.currentAddress?.city ?? ''}
                          state={data?.currentAddress?.state ?? ''}
                          postalCode={data?.currentAddress?.postalCode ?? ''}
                          country={data?.currentAddress?.country ?? ''}
                          flagData={flagData.currentAddress}
                        />
                      )}
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              {!data?.wantToReceiveMailAtPhysicalAddress ? (
                <Grid m={2}>
                  <Grid container m={2}>
                    {data?.mailingAddress?.streetAddress && (
                      <Grid lg={6} pr={2}>
                        <Typography variant='h3' className='FontBold' mb={2}>
                          Preferred Mailing Address
                        </Typography>

                        <AddressCard
                          streetAddress={
                            data?.mailingAddress?.streetAddress ?? ''
                          }
                          city={data?.mailingAddress?.city ?? ''}
                          state={data?.mailingAddress?.state ?? ''}
                          postalCode={data?.mailingAddress?.postalCode ?? ''}
                          country={data?.mailingAddress?.country ?? ''}
                          flagData={flagData.mailingAddress}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ) : null}

              <Grid container m={2}>
                <Grid lg={12}>
                  <Typography variant='h2' className='FontBold' m={2}>
                    Assistant Details
                  </Typography>
                </Grid>

                {!hasEmpty(assistancedata) ? (
                  <TableContainer>
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: 'none',
                        },
                        width: '50%',
                      }}
                    >
                      <TableRow>
                        <StyledTableCell variant='head' className='grayFonts'>
                          Assistant Name
                        </StyledTableCell>
                        <StyledTableCell className='MediumBlackFonts'>
                          {renderContactInfo(assistancedata)}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell variant='head' className='grayFonts'>
                          Assistant Phone Number
                        </StyledTableCell>
                        <StyledTableCell className='MediumBlackFonts'>
                          {assistancedata?.phoneNumber}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell variant='head' className='grayFonts'>
                          Assistant Email
                        </StyledTableCell>
                        <StyledTableCell className='MediumBlackFonts'>
                          {assistancedata?.emailId}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell variant='head' className='grayFonts'>
                          Street Address
                        </StyledTableCell>
                        <StyledTableCell className='MediumBlackFonts'>
                          {assistancedata?.address &&
                            renderAddress(assistancedata?.address)}
                        </StyledTableCell>
                      </TableRow>
                      <TableRow>
                        {assistancedata?.address?.city && (
                          <>
                            <StyledTableCell
                              variant='head'
                              className='grayFonts'
                            >
                              City/Town
                            </StyledTableCell>
                            <StyledTableCell className='MediumBlackFonts'>
                              {assistancedata?.address?.city}
                            </StyledTableCell>
                          </>
                        )}
                      </TableRow>
                      <TableRow>
                        {assistancedata?.address?.state && (
                          <>
                            <StyledTableCell
                              variant='head'
                              className='grayFonts'
                            >
                              State
                            </StyledTableCell>
                            <StyledTableCell className='MediumBlackFonts'>
                              {assistancedata?.address?.state}
                            </StyledTableCell>
                          </>
                        )}
                      </TableRow>
                      <TableRow>
                        {assistancedata?.address?.postalCode && (
                          <>
                            <StyledTableCell
                              variant='head'
                              className='grayFonts'
                            >
                              Zip Code
                            </StyledTableCell>
                            <StyledTableCell className='MediumBlackFonts'>
                              {assistancedata?.address?.postalCode}
                            </StyledTableCell>
                          </>
                        )}
                      </TableRow>
                      <TableRow>
                        {assistancedata?.address?.country && (
                          <>
                            <StyledTableCell
                              variant='head'
                              className='grayFonts'
                            >
                              Country
                            </StyledTableCell>
                            <StyledTableCell className='MediumBlackFonts'>
                              {assistancedata?.address?.country}
                            </StyledTableCell>
                          </>
                        )}
                      </TableRow>
                    </Table>
                  </TableContainer>
                ) : (
                  <CustomAlert message='no data available.' />
                )}
              </Grid>
            </>
          ) : (
            <CustomAlert message='no data available.' />
          )}
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Contact Information
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <ContactInformation handleEditToggle={handleEditToggle} />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}
export default Contact
