import { Grid } from '@mui/material'
import { useEffect, type FC } from 'react'
import {
  ViewMonthlyRevenue,
  ViewNewOrganization,
  ViewTotalOrganization,
  ViewTotalRevenue,
} from 'src/Redux/Slices/DashboardSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { DashboardCard, Icons, Roles } from 'src/Utils/Constants'
import Style from './index.module.scss'
const AdminDashboard: FC = () => {
  const data = useAppSelector((state) => state.auth?.data)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(ViewTotalOrganization())
    dispatch(ViewNewOrganization())
    dispatch(ViewTotalRevenue())
    dispatch(ViewMonthlyRevenue())
  }, [])
  const organization = useAppSelector((state) => state?.dashboard?.data)

  const JsonData: Array<{
    Icon: string
    count: string
    Description: string
  }> = [
    {
      Icon: Icons?.organization,
      count: organization?.totalOrganization?.total
        ? organization?.totalOrganization?.total
        : 0,
      Description: 'Total Organizations',
    },
    {
      Icon: Icons?.NewRegisteredOrg,
      count: organization?.newOrganization?.total
        ? organization?.newOrganization?.total
        : 0,
      Description: 'New Registered Organizations',
    },
    {
      Icon: Icons?.dollarBox,
      count: organization?.totalRevenue?.totalRevenue
        ? organization?.totalRevenue?.totalRevenue
        : 0,
      Description: 'Total Revenue From Organizations',
    },
    {
      Icon: Icons?.TotalRevenueFromOrg,
      count: organization?.monthlyRevenue?.monthlyRevenue
        ? organization?.monthlyRevenue?.monthlyRevenue
        : 0,
      Description: 'Monthly Revenue From Organizations',
    },
  ]

  return (
    <>
      {data?.roleScope === Roles.LEGAL && (
        <Grid container display='flex' flexWrap={'wrap'} flexDirection={'row'}>
          <DashboardCard.TotalNumberEmployee />
        </Grid>
      )}
      <Grid
        container
        display='flex'
        flexWrap={'wrap'}
        flexDirection={'row'}
        gap={'2%'}
        p={'30px'}
      >
        {JsonData.map(
          (
            data: {
              Icon: string
              count: string
              Description: string
            },
            index: number,
          ) => (
            <DashboardCard.Card key={index} {...data} index={index} />
          ),
        )}
      </Grid>
      <Grid
        item
        container
        display='flex'
        flexWrap={'wrap'}
        flexDirection={'row'}
        gap={'2%'}
        px={'30px'}
      >
        <DashboardCard.PersonalDetail />
        <DashboardCard.LatestOrganization />
      </Grid>
      <Grid
        item
        xs={12}
        p={'30px'}
        gap={2}
        display={'flex'}
        rowGap={2}
        className={Style.GridContent}
      >
        <Grid py={1} className={Style.chartGrid}>
          <DashboardCard.FutureForecastChart />
        </Grid>
        <Grid py={1} className={Style.chartGrid}>
          <DashboardCard.RecurringRevenueChart />
        </Grid>
      </Grid>
      <Grid
        container
        display='flex'
        flexWrap={'wrap'}
        flexDirection={'row'}
        gap={'2%'}
      >
        <DashboardCard.AdminApplicationOverview />
      </Grid>
      <Grid
        item
        xs={12}
        p={'30px'}
        gap={2}
        display={'flex'}
        rowGap={2}
        className={Style.GridContent}
      >
        <Grid py={1} className={Style.chartGrid}>
          <DashboardCard.AdminApplicationAnalysis />
        </Grid>
        <Grid py={1} className={Style.chartGrid}>
          <DashboardCard.AdminAvarageTime />
        </Grid>
      </Grid>
    </>
  )
}

export default AdminDashboard
