import { Avatar, Box, Grid, Input, InputLabel, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Search from 'src/Assets/Icons/Search.svg'
import Breadcrumb from 'src/Components/BreadCrumb'
import { ViewRolePermissionDetail } from 'src/Redux/Slices/rolePermissionDetails'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ROUTES } from 'src/Utils/Routes'
import Style from './index.module.scss'
import { DynamicObj } from 'src/Utils/Constants'

interface associatedusers {
  uuid: string
  Role_name: string
  Role_description: string
  firstname: string
  lastName: string
  profileURL: string
}

const RoleDetails: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const roleUuid = location.state?.id
  const viewRoleDetail = useAppSelector(
    (state) => state?.roles?.RolePermissionDetails?.data,
  )

  useEffect(() => {
    dispatch(ViewRolePermissionDetail(roleUuid))
  }, [])
  const [q, setQ] = React.useState('')

  function find(items: DynamicObj) {
    return items?.filter((item: DynamicObj) => {
      return ['firstname', 'lastName'].some((newItem) => {
        return item[newItem]?.toString().toLowerCase().includes(q.toLowerCase())
      })
    })
  }

  return (
    <>
      <Box className={Style.Main}>
        <Box className={Style.Head}>
          <Box className={Style.Action}>
            <Box className={Style.Left}>
              <Breadcrumb
                title='Roles & Permissions'
                Menus={[
                  {
                    name: 'Roles & Permissions',
                    route: ROUTES.ROLE,
                  },
                  { name: 'Role Details', route: ROUTES.ROLE_DETAILS },
                ]}
              />
            </Box>
            <Box className={Style.Right}>
              <Box className={Style.SearchBox}>
                <img src={Search} alt='Search' />
                <Input
                  disableUnderline
                  className={Style.SearchEm}
                  placeholder='Search'
                  type='text'
                  value={q}
                  onChange={(e: { target: { value: string } }) => {
                    setQ(e.target.value)
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Grid className={Style.ListWrapper}>
          <Grid xs={4} sm={6} md={4} lg={12} className={Style.ListGrid}>
            <Box className={Style.MainBox}>
              <Grid className={Style.MainGrid}>
                <Grid className={Style.SubGrid}>
                  <Box>
                    <Typography className={Style.div1}>
                      {viewRoleDetail?.Role_name}
                    </Typography>
                    <Typography className={Style.div6}>
                      {viewRoleDetail?.Role_description}
                    </Typography>
                    <Box className={Style.permissionBox}>
                      <Grid>
                        <Typography className={Style.div2}>
                          Permissions:
                        </Typography>
                      </Grid>
                      <Grid display={'flex'} gap={1} flexWrap={'wrap'} px={1}>
                        {viewRoleDetail?.permission?.length > 0 && (
                          <>
                            {[...new Set(viewRoleDetail?.permission)].map(
                              (item, index) => (
                                <Typography
                                  key={index}
                                  className={Style.ContentHead}
                                >
                                  {item}
                                </Typography>
                              ),
                            )}
                          </>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box className={Style.ContentBox}>
                <InputLabel className={Style.ContentLabel}>
                  Assigned Team Members
                </InputLabel>
                <Box display={'flex'} gap={2} flexWrap={'wrap'} width={'100%'}>
                  {viewRoleDetail?.associatedusers?.length &&
                    find(viewRoleDetail?.associatedusers)?.map(
                      (item: associatedusers, index: number) => {
                        return (
                          <>
                            <Grid
                              key={index}
                              xs={6}
                              className={Style.ContentGrid}
                            >
                              <Box>
                                <Avatar
                                  sx={{ height: '50px', width: '50px' }}
                                  src={item?.profileURL}
                                  alt='photoURL'
                                />
                              </Box>
                              <Box className={Style.ContentHeadBox}>
                                <Typography
                                  className={Style.ContentHeadNameText}
                                >
                                  {`${item?.firstname} ${item?.lastName}`}
                                </Typography>
                              </Box>
                            </Grid>
                          </>
                        )
                      },
                    )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default RoleDetails
