import './index.scss'
import Contact from './Contact'
import Communication from './Communication'
import MillataryService from './MilataryService'
import PastApplication from './PastApplications'
import CitizenShip from './Citizenship'
import React from 'react'

const ApplicationContent = () => {
  return (
    <>
      <div>
        <Contact />
        <Communication />
        <CitizenShip />
        <MillataryService />
        <PastApplication />
      </div>
    </>
  )
}
export default ApplicationContent
