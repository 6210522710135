// This slice manages the state for the sign in feature
import { createSlice } from '@reduxjs/toolkit'
import { DisabilityDetailsType } from '../../Services/Types/applicationType'

// Define the initial state of the slice
const initialState: DisabilityDetailsType = {
  DisabilityBackground: {
    applicationId: '',
    isAbleToWork: undefined,
    isDisabilityExpected1YearOrLonger: undefined,
    disabilityStartDate: '',
    disabilityEndInDeath: undefined,
    isAbleToPerformUsualJob: undefined,
    is14MonthsUnableToDoJob: undefined,
    disabilityRelatedToWork: undefined,
  },
  DisabilityEmployment: {
    disabilityId: '',
    intendToWorkNextYear: undefined,
    affectingEmploymentDetail: '',
    employedDuringLast2YearDetail: [],
    reasonToStoppedWorking: '',
    explainStoppedWorking: '',
    dateOfStoppedWorking: '',
    isRequireWorkChanges: undefined,
    haveMadeMoreMoneyInAnyMonth: undefined,
    workChangeStartDate: '',
    disabilityKeepFromWorkingDate: '',
  },
  PayoutsSettlements: {
    disabilityId: '',
    haveReceiveMoneyAfterDisability: undefined,
    typeOfPays: [],
    otherPayExplain: '',
    isExpectMoneyInFuture: undefined,
    approxAmountExpected: '',
    typeOfFuturePay: [],
    futureOtherPayExplain: '',
    approxAmountFuturePayout: '',
  },
}

// Define the reducers that can modify the state
const DisabilityDetailsStore = createSlice({
  name: 'DisabilityDetails',
  initialState,
  reducers: {
    resetDisabilityDetails: (state) => {
      state.DisabilityBackground = {
        applicationId: '',
        isAbleToWork: undefined,
        isAbleToPerformUsualJob: undefined,
        is14MonthsUnableToDoJob: undefined,
        disabilityStartDate: '',
        isDisabilityExpected1YearOrLonger: undefined,
        disabilityEndInDeath: undefined,
        disabilityRelatedToWork: undefined,
      }
      state.DisabilityEmployment = {
        disabilityId: '',
        intendToWorkNextYear: undefined,
        affectingEmploymentDetail: '',
        employedDuringLast2YearDetail: [],
        reasonToStoppedWorking: '',
        explainStoppedWorking: '',
        dateOfStoppedWorking: '',
        isRequireWorkChanges: undefined,
        haveMadeMoreMoneyInAnyMonth: undefined,
        workChangeStartDate: '',
        disabilityKeepFromWorkingDate: '',
      }
      state.PayoutsSettlements = {
        disabilityId: '',
        haveReceiveMoneyAfterDisability: true,
        typeOfPays: [],
        otherPayExplain: '',
        isExpectMoneyInFuture: undefined,
        approxAmountExpected: '',
        typeOfFuturePay: [],
        futureOtherPayExplain: '',
        approxAmountFuturePayout: '',
      }
    },
    setDisabilityBackground: (
      state,
      action: { payload: typeof initialState.DisabilityBackground },
    ) => {
      state.DisabilityBackground = action.payload
    },
    setDisabilityEmployment: (
      state,
      action: { payload: typeof initialState.DisabilityEmployment },
    ) => {
      state.DisabilityEmployment = action.payload
    },
    setPayoutsSettlements: (
      state,
      action: { payload: typeof initialState.PayoutsSettlements },
    ) => {
      state.PayoutsSettlements = action.payload
    },
    setDisabilityDetails: (state, action: { payload: typeof initialState }) => {
      state.PayoutsSettlements = action.payload.PayoutsSettlements
      state.DisabilityEmployment = action.payload.DisabilityEmployment
      state.DisabilityBackground = action.payload.DisabilityBackground
    },
  },
})

export default DisabilityDetailsStore.reducer

export const {
  resetDisabilityDetails,
  setDisabilityBackground,
  setDisabilityEmployment,
  setPayoutsSettlements,
  setDisabilityDetails,
} = DisabilityDetailsStore.actions
