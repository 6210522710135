import * as React from 'react'
import Box from '@mui/material/Box'
import Step1 from './step1/index'
import Step2 from './step2/index'

import Style from '../index.module.scss'
interface PopupFormProps {
  handleClose: () => void
}

const HorizontalNonLinearStepper: React.FC<PopupFormProps> = ({
  handleClose,
}) => {
  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleComplete = () => {
    handleClose()
  }

  return (
    <Box sx={{ width: '100%' }}>
      <div>
        <React.Fragment>
          <Box className={Style.DialogContentBox}>
            {activeStep === 0 && (
              <>
                <Step1 handleNext={handleNext} />
              </>
            )}
            {activeStep === 1 && (
              <>
                <Step2 handleNext={handleComplete} handleBack={handleBack} />
              </>
            )}
          </Box>
        </React.Fragment>
      </div>
    </Box>
  )
}
export default HorizontalNonLinearStepper
