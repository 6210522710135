import * as React from 'react'
import {
  Box,
  Avatar,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  CircularProgress,
  Grid,
} from '@mui/material'
import { Add } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/Utils/Routes'
import { Icons, AdminPermissions, MileStoneFour } from 'src/Utils/Constants'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from 'src/Components/Sidebar/index.module.scss'
import { ViewRoleRequest } from 'src/Redux/Slices/roleSlice'
import AvatarIcon from 'src/Assets/Icons/Avatar.svg'
import ProfilePhoto from 'src/Assets/Icons/ProfilePhoto.svg'
import { ViewPermissionRequest } from 'src/Redux/Slices/permissionManageSlice'
import GenAppDialogue from 'src/Pages/Applications/GenerateApplication'

export default function AdminSidebar() {
  // const [openEmployee, setOpenEmployee] = React.useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  // const handleEmployeeClick = () => {
  //   setOpenEmployee(!openEmployee)
  // }
  // const data = useAppSelector((state) => state.auth.data)
  const viewProfile = useAppSelector((state) => state.profile.data)

  const UserPermission = useAppSelector((state) => state.permissions?.data)
  const [openDialog, setOpenDialog] = React.useState(false)

  return (
    <Box className={Style.mainSidebarBox} flexShrink={{ lg: 0 }}>
      <Grid className={Style.SubSidebarBox} flexShrink={{ lg: 0 }}>
        <Box className={Style.AvtarBox}>
          <Avatar
            className={Style.avatarImage}
            src={viewProfile?.profileUrl || ProfilePhoto}
            alt='photoURL'
          />
          <CircularProgress
            variant='determinate'
            value={55}
            size={60}
            thickness={2.5}
            className={Style.avatarProgress}
          />
        </Box>

        <Box sx={{ ml: 2 }}>
          <Typography className={Style.userName}>
            {viewProfile?.firstName}
          </Typography>
          <Typography className={Style.userRole}>
            {viewProfile?.roleName}
          </Typography>
        </Box>
      </Grid>

      <Grid className={Style.sidebarBox} flexShrink={{ lg: 0 }}>
        <Grid gap={30} className={Style.listBox} flexShrink={{ lg: 0 }}>
          <List
            sx={{
              gap: 35,
            }}
            component='nav'
            aria-labelledby='nested-list-subheader'
          >
            {/* dashboard */}
            {UserPermission.includes(AdminPermissions.ADMIN_DASHBOARD) && (
              <>
                <ListItemButton
                  className={
                    location.pathname === ROUTES.DASHBOARD
                      ? Style.Active
                      : Style.listItemBtn
                  }
                  onClick={() => {
                    navigate(ROUTES.DASHBOARD)
                  }}
                >
                  <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                    <img
                      height='20px'
                      width='20px'
                      src={Icons.dashboard}
                      alt='dashboard'
                    />
                  </Box>
                  <ListItemText
                    sx={{ textAlign: 'start' }}
                    primary='dashboard'
                  />
                </ListItemButton>
              </>
            )}

            {/* Organizations */}
            {UserPermission.includes(AdminPermissions.ORGANIZATIONS) && (
              <>
                <ListItemButton
                  className={
                    location.pathname === ROUTES.ORGANIZATIONS
                      ? Style.Active
                      : Style.listItemBtn
                  }
                  onClick={() => {
                    navigate(ROUTES.ORGANIZATIONS)
                  }}
                >
                  <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                    <img
                      height='20px'
                      width='20px'
                      src={Icons.organizations}
                      alt='p'
                    />
                  </Box>
                  <ListItemText
                    sx={{ textAlign: 'start' }}
                    primary='Organizations'
                  />
                </ListItemButton>
              </>
            )}

            {/*  Manage Employees */}
            {UserPermission.includes(AdminPermissions.ADMIN_USERS) && (
              <ListItemButton
                className={
                  location.pathname === ROUTES.ADMIN_MANAGE_EMPLOYEES
                    ? Style.Active
                    : Style.listItemBtn
                }
                onClick={() => {
                  navigate(ROUTES.ADMIN_MANAGE_EMPLOYEES)
                }}
              >
                <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                  <img
                    height='20px'
                    width='20px'
                    src={Icons.employee}
                    alt='p'
                  />
                </Box>
                <ListItemText sx={{ textAlign: 'start' }} primary='Employees' />
              </ListItemButton>
            )}

            {/* Applications */}

            {UserPermission.includes(AdminPermissions.APPLICATIONS) && (
              <>
                <>
                  <ListItemButton
                    className={
                      location.pathname === ROUTES.APPLICATIONS
                        ? Style.Active
                        : Style.listItemBtn
                    }
                    onClick={() => {
                      navigate(ROUTES.APPLICATIONS)
                    }}
                  >
                    <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                      <img
                        height='20px'
                        width='20px'
                        src={Icons.application}
                        alt='p'
                      />
                    </Box>
                    <ListItemText
                      sx={{ textAlign: 'start' }}
                      primary='Applications'
                    />
                  </ListItemButton>
                </>
              </>
            )}

            {/* Subscription Plan */}

            {UserPermission.includes(AdminPermissions.SUBSCRIPTIONS) && (
              <>
                <ListItemButton
                  className={
                    location.pathname === ROUTES.SUBSCRIPTION_PLAN
                      ? Style.Active
                      : Style.listItemBtn
                  }
                  onClick={() => {
                    navigate(ROUTES.SUBSCRIPTION_PLAN)
                  }}
                >
                  <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                    <img
                      height='22px'
                      width='22px'
                      src={Icons.circleDollar}
                      alt='employee'
                    />
                  </Box>
                  <ListItemText
                    sx={{ textAlign: 'start' }}
                    primary='Subscription Plan'
                  />
                </ListItemButton>
              </>
            )}

            {/* Role & Permissions */}
            {UserPermission.includes(AdminPermissions.ROLE_AND_PERMISSION) && (
              <>
                <ListItemButton
                  className={
                    location.pathname === ROUTES.ROLE_PERMISSION
                      ? Style.Active
                      : Style.listItemBtn
                  }
                  onClick={() => {
                    navigate(ROUTES.ROLE_PERMISSION)
                    dispatch(ViewRoleRequest())
                    dispatch(ViewPermissionRequest())
                  }}
                >
                  <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                    <img
                      height='20px'
                      width='20px'
                      src={Icons.role}
                      alt='setting'
                    />
                  </Box>
                  <ListItemText
                    sx={{ textAlign: 'start' }}
                    primary='Role & Permissions'
                  />
                </ListItemButton>
              </>
            )}

            {/* Notification */}
            {UserPermission.includes(AdminPermissions.NOTIFICATION) &&
              (process.env.REACT_APP_MILESTONE_4 ? (
                <>
                  <ListItemButton
                    className={
                      location.pathname === ROUTES.NOTIFICATION
                        ? Style.Active
                        : Style.listItemBtn
                    }
                    onClick={() => {
                      navigate(ROUTES.NOTIFICATION)
                      // dispatch(ViewRoleRequest())
                      // dispatch(ViewPermissionRequest())
                    }}
                  >
                    <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                      <img
                        height='20px'
                        width='20px'
                        src={Icons.notification}
                        alt='setting'
                      />
                    </Box>
                    <ListItemText
                      sx={{ textAlign: 'start' }}
                      primary='Notification'
                    />
                  </ListItemButton>
                </>
              ) : (
                <></>
              ))}
          </List>
        </Grid>

        <Grid className={Style.subContainer}>
          <Box>
            <img src={AvatarIcon} alt='' />
          </Box>
          <Grid sx={{ textAlign: 'left' }}>
            <Typography variant='body2' className={Style.subContainerText}>
              Start a new application now!
            </Typography>
          </Grid>
          <Grid py={1} className={Style.subContainerGrid}>
            {process.env.REACT_APP_MILESTONE_4 ? (
              <>
                <Button
                  className={Style.subContainerButton}
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setOpenDialog(true)
                  }}
                >
                  New Application
                  <Add sx={{ fontSize: 'medium', ml: 2 }} />
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={Style.subContainerButton}
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    navigate(ROUTES.WELCOME_PAGE)
                  }}
                >
                  New Application
                  <Add sx={{ fontSize: 'medium', ml: 2 }} />
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {openDialog && (
        <GenAppDialogue openDialog={openDialog} setOpenDialog={setOpenDialog} />
      )}
    </Box>
  )
}
