import { Box, Grid, Typography } from '@mui/material'
import { useEffect, type FC } from 'react'
import { ViewApplicationReview } from 'src/Redux/Slices/DashboardSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/Utils/Routes'

const ApplicationOverview: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(ViewApplicationReview())
  }, [])

  const applicationReview = useAppSelector(
    (state) => state?.dashboard?.data?.applicationReview,
  )
  return (
    <Box className={Style.overviewBox}>
      <Grid item xs={12} lg={12}>
        <Typography className={Style.overviewBoxTitle}>
          Application Overview
        </Typography>
      </Grid>

      <Grid item xs={12} lg={12} width={'100%'} display='flex' gap={'2%'}>
        <Box
          className={Style.overviewGridBox}
          onClick={() => {
            navigate(ROUTES.APPLICATIONS, {
              state: {
                filterParam: {
                  status: 'Submitted',
                  sort: 'DESC',
                },
              },
            })
          }}
        >
          <Box className={Style.overviewCard}>
            <Box className={Style.overviewCardContent}>
              <Typography variant='h4' className={Style.overviewCardCount}>
                {applicationReview?.Submitted ?? 0}
              </Typography>
              <Typography variant='h2' className={Style.overviewCardTxt}>
                Submitted Applications
              </Typography>
            </Box>
            <img src={Icons?.CompletedApplication} alt='organization' />
          </Box>
        </Box>

        <Box
          className={Style.overviewGridBox}
          onClick={() => {
            navigate(ROUTES.APPLICATIONS, {
              state: {
                filterParam: {
                  status: '',
                  sort: 'DESC',
                  assignee: true,
                },
              },
            })
          }}
        >
          <Box className={Style.overviewCard}>
            <Box className={Style.overviewCardContent}>
              <Typography variant='h4' className={Style.overviewCardCount}>
                {applicationReview?.Assigned ?? 0}
              </Typography>
              <Typography variant='h2' className={Style.overviewCardTxt}>
                Assigned Applications
              </Typography>
            </Box>
            <img src={Icons?.ApproveApplication} alt='organization' />
          </Box>
        </Box>

        <Box
          className={Style.overviewGridBox}
          onClick={() => {
            navigate(ROUTES.APPLICATIONS, {
              state: {
                filterParam: {
                  status: 'In Progress',
                  sort: 'DESC',
                },
              },
            })
          }}
        >
          <Box className={Style.overviewCard}>
            <Box className={Style.overviewCardContent}>
              <Typography variant='h4' className={Style.overviewCardCount}>
                {applicationReview?.InProgress ?? 0}
              </Typography>
              <Typography variant='h2' className={Style.overviewCardTxt}>
                In Progress Applications
              </Typography>
            </Box>
            <img src={Icons?.InProgressApplication} alt='organization' />
          </Box>
        </Box>

        <Box
          className={Style.overviewGridBox}
          onClick={() => {
            navigate(ROUTES.APPLICATIONS, {
              state: {
                filterParam: {
                  status: 'Flagged',
                  sort: 'DESC',
                },
              },
            })
          }}
        >
          <Box className={Style.overviewCard}>
            <Box className={Style.overviewCardContent}>
              <Typography variant='h4' className={Style.overviewCardCount}>
                {applicationReview?.Flagged ?? 0}
              </Typography>
              <Typography variant='h2' className={Style.overviewCardTxt}>
                Flagged Applications
              </Typography>
            </Box>
            <img src={Icons?.FlaggedApplication} alt='organization' />
          </Box>
        </Box>

        <Box
          className={Style.overviewGridBox}
          onClick={() => {
            navigate(ROUTES.APPLICATIONS, {
              state: {
                filterParam: {
                  status: '',
                  sort: 'DESC',
                },
              },
            })
          }}
        >
          <Box className={Style.overviewCard}>
            <Box className={Style.overviewCardContent}>
              <Typography variant='h4' className={Style.overviewCardCount}>
                {applicationReview?.InReview ?? 0}
              </Typography>
              <Typography variant='h2' className={Style.overviewCardTxt}>
                In Review Applications
              </Typography>
            </Box>
            <img src={Icons?.InReviewApplication} alt='organization' />
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}

export default ApplicationOverview
