import {
  AsyncThunk,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { SigninData } from '../Services/Types/SignInType'
import { SignUpFormType } from 'src/Redux/Services/Types/signupDetailType'
import AuthService from '../Services/authService'
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import type { RootState } from 'src/Redux/store'
import { loadStripe } from '@stripe/stripe-js/pure'
import {
  TIMER,
  extractSignUpFormData,
  handleResponse,
} from 'src/Utils/Constants'
import secureLocalStorage from 'react-secure-storage'

const initialSignUpForm = {
  isRegistered: false,
  role: 'Organization',
  password: '',
  confirmPassword: '',
  code: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  country: '',
  emailId: '',
  registration: '',
  orgName: '',
  orgUuid: '',
  orgCity: '',
  orgState: '',
  orgPostalCode: '',
  orgStreetAddress: '',
  orgDescription: '',
  subName: '',
  subPlan: 'month',
  subUuid: '',
  subAmount: 0,
  subDescription: '',
  subNumberOfSeats: 0,
  subSubscriptionPeriod: 0,
  subNumberOfApplications: 0,
}
type DynamicObj = Record<string, boolean | any>
// Define the initial state of the slice
interface stateType {
  success: boolean
  data: SigninData
  sessionId: string
  paymentStatus: string
  selectedPlanId: string
  SignUpData?: SignUpFormType
  fieldErrors?: DynamicObj
  ErrorMsg?: string
  SuccessMSg?: string
  errorMessage?: string | null
  successMessage?: string | null
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  data: {},
  selectedPlanId: '',
  paymentStatus: '',
  sessionId: '',
  SignUpData: initialSignUpForm,
  ErrorMsg: '',
  SuccessMSg: '', // the data returned from the sign in request
  errorMessage: '',
  successMessage: '',
  fieldErrors: {},
}

// Define the reducers that can modify the state
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSessionId: (state, action) => {
      state.sessionId = action.payload
    },
    setSignUpData: (state, action) => {
      const { key, value } = action.payload

      state.SignUpData = {
        ...state.SignUpData,
        [key]: value,
      }
    },

    setDetailedSignUpData: (state, action) => {
      const obj = action.payload

      state.SignUpData = {
        ...state.SignUpData,
        ...obj,
      }
    },
    resetSignUpData: (state) => {
      state.SignUpData = initialSignUpForm
    },

    setAuthSuccess: (state, action) => {
      state.success = true
      state.data = action.payload
    },
    setselectedPlanId: (state, action) => {
      state.selectedPlanId = action.payload
    },
    setAuthFail: (state) => {
      state.success = false
      state.data = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(SignInRequest.pending, (state) => {
      state.success = false
    })
    builder.addCase(
      SignInRequest.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.data = action.payload?.data
      },
    )
    builder.addCase(SignInRequest.rejected, (state) => {
      state.success = false
    })
    builder.addCase(SignUpRequest.pending, (state) => {
      state.success = false
    })
    builder.addCase(
      SignUpRequest.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.data = action.payload?.data
      },
    )
    builder.addCase(GetPaymentDetail.rejected, (state) => {
      state.success = false
    })
    builder.addCase(GetPaymentDetail.pending, (state) => {
      state.success = false
    })
    builder.addCase(
      GetPaymentDetail.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.data = action.payload?.data
      },
    )
    builder.addCase(EmailVerifyRequest.rejected, (state) => {})
    builder.addCase(EmailVerifyRequest.pending, (state) => {})
    builder.addCase(EmailVerifyRequest.fulfilled, (state) => {})
    builder.addCase(SignUpRequest.rejected, (state) => {
      state.success = false
    })
    builder.addCase(CodeVerifyRequest.pending, (state) => {
      state.success = false
    })
    builder.addCase(
      CodeVerifyRequest.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.data = action.payload?.data
      },
    )
    builder.addCase(CodeVerifyRequest.rejected, (state) => {
      state.success = false
    })
    builder.addCase(LogoutRequest.fulfilled, (state) => {
      state = initialState
    })
  },
})

export const {
  setAuthSuccess,
  setAuthFail,
  setselectedPlanId,
  setSessionId,
  setSignUpData,
  setDetailedSignUpData,
  resetSignUpData,
} = authSlice.actions

// Define the async thunk action creator for sign in
export const ForgetPasswordRequest: AsyncThunk<
  stateType,
  any,
  AsyncThunkConfig
> = createAsyncThunk(
  'forgetPassword', // name of the action
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI

    try {
      const response = await AuthService.forgetPassword(data) // make the API call
      handleResponse({ response, dispatch })

      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

// Define the async thunk action creator for sign in
export const VerifyForgetLinkRequest: AsyncThunk<any, any, AsyncThunkConfig> =
  createAsyncThunk(
    'signIn', // name of the action
    async (data, thunkAPI) => {
      const { dispatch } = thunkAPI

      try {
        const response = await AuthService.verifyForgetLink(data) // make the API call
        handleResponse({ response, dispatch })

        return response.data // explicitly return the fetched data
      } catch (err) {
        console.error('Error while authentication:', err)
        throw err // re-throw to allow error handling in components
      }
    },
  )

// Define the async thunk action creator for sign in
export const ResetPasswordRequest: AsyncThunk<any, any, AsyncThunkConfig> =
  createAsyncThunk(
    'resetPassword', // name of the action
    async (data, thunkAPI) => {
      const { dispatch } = thunkAPI

      try {
        const response = await AuthService.resetPassword(data) // make the API call
        handleResponse({ response, dispatch })

        return response.data // explicitly return the fetched data
      } catch (err) {
        console.error('Error while authentication:', err)
        throw err // re-throw to allow error handling in components
      }
    },
  )

// Define the async thunk action creator for sign in
export const SignInRequest: AsyncThunk<
  stateType,
  SigninData,
  AsyncThunkConfig
> = createAsyncThunk(
  'signIn', // name of the action
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI

    try {
      const response = await AuthService.signInRequest({
        ...data,
        deviceToken: secureLocalStorage.getItem('deviceToken'),
      })
      handleResponse({ response, dispatch })

      if (response.data.data) {
        const expirationTime = new Date()
        expirationTime.setMinutes(expirationTime.getMinutes() + TIMER)
        // Convert expiration time to a string format suitable for storing in localStorage
        const tokenExpiration = expirationTime.toISOString()
        secureLocalStorage.setItem('token', response.data.data.token)
        secureLocalStorage.setItem('tokenExpiration', tokenExpiration)
      }

      // make the API call
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

// Define the async thunk action creator for sign in
export const LogoutRequest = createAsyncThunk(
  'logout', // name of the action
  async (_, thunkAPI) => {
    try {
      secureLocalStorage.removeItem('token')
      secureLocalStorage.removeItem('tokenExpiration')
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const SignUpRequest: AsyncThunk<
  stateType,
  SigninData,
  AsyncThunkConfig
> = createAsyncThunk(
  'signUp', // name of the action
  async (data, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await AuthService.signUpRequest(data) // make the API call
      handleResponse({ response, dispatch })

      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const GetPaymentDetail: AsyncThunk<stateType, any, AsyncThunkConfig> =
  createAsyncThunk(
    'GetPaymentDetail', // name of the action
    async (data, thunkAPI) => {
      const { dispatch } = thunkAPI

      try {
        const response = await AuthService.PaymentDetail(data) // make the API call
        if (response.data.data.token) {
          const expirationTime = new Date()
          expirationTime.setMinutes(expirationTime.getMinutes() + TIMER)
          // Convert expiration time to a string format suitable for storing in localStorage
          const tokenExpiration = expirationTime.toISOString()
          secureLocalStorage.setItem('token', response.data.data.token)
          secureLocalStorage.setItem('tokenExpiration', tokenExpiration)
          dispatch(resetSignUpData())
        }

        return response.data // explicitly return the fetched data
      } catch (err) {
        console.error('Error while authentication:', err)
        throw err // re-throw to allow error handling in components
      }
    },
  )

// Define the async thunk action creator for email verification
export const EmailVerifyRequest = createAsyncThunk(
  'mailVerify', // name of the action
  async (data: any, { dispatch }) => {
    try {
      const onlyFail = true
      const response = await AuthService.emailVerifyRequest(data)
      handleResponse({ response, dispatch, onlyFail })

      return response.data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err
    }
  },
)

export const CodeVerifyRequest: AsyncThunk<stateType, any, AsyncThunkConfig> =
  createAsyncThunk(
    'codeVerify', // name of the action
    async (data, thunkAPI) => {
      const { dispatch } = thunkAPI

      try {
        const response = await AuthService.codeVerifyRequest(data)
        // make the API call
        handleResponse({ response, dispatch })

        if (response.data?.data) {
          const jsonData = response
          const values = extractSignUpFormData(jsonData)
          if (values) {
            dispatch(setDetailedSignUpData(values))
          }
        }

        // dispatch(setSignUpData(response.data))
        return response.data // explicitly return the fetched data
      } catch (err) {
        console.error('Error while authentication:', err)
        throw err // re-throw to allow error handling in components
      }
    },
  )

export const OrganizationRequest: AsyncThunk<stateType, any, AsyncThunkConfig> =
  createAsyncThunk(
    'organization', // name of the action
    async (data, thunkAPI) => {
      try {
        const payload = {
          ...data,
          emailId: (thunkAPI.getState() as RootState).auth.SignUpData.emailId,
        }

        const response = await AuthService.organizationRequest(payload) // make the API call

        return response.data // explicitly return the fetched data
      } catch (err) {
        console.error('Error while authentication:', err)
        throw err // re-throw to allow error handling in components
      }
    },
  )

export const PersonalRequest: AsyncThunk<stateType, any, AsyncThunkConfig> =
  createAsyncThunk(
    'personal', // name of the action
    async (data, thunkAPI) => {
      try {
        const payload = {
          ...data,
          emailId: (thunkAPI.getState() as RootState).auth.SignUpData.emailId,
        }

        const response = await AuthService.personalRequest(payload) // make the API call

        return response.data // explicitly return the fetched data
      } catch (err) {
        console.error('Error while authentication:', err)
        throw err // re-throw to allow error handling in components
      }
    },
  )

export const FetchSubscriptions = createAsyncThunk(
  'subscriptions/get', // name of the action
  async (_, thunkAPI) => {
    try {
      const response = await AuthService.fetchSubscriptions() // make the API call

      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const SubscriptionRequest: AsyncThunk<stateType, any, AsyncThunkConfig> =
  createAsyncThunk(
    'subscriptions/post', // name of the action
    async (id, thunkAPI) => {
      try {
        const payload = {
          subscriptionId: id,
          emailId: (thunkAPI.getState() as RootState).auth.SignUpData.emailId,
        }

        const response = await AuthService.subscriptionRequest(payload) // make the API call

        return response.data // explicitly return the fetched data
      } catch (err) {
        console.error('Error while authentication:', err)
        throw err // re-throw to allow error handling in components
      }
    },
  )

export const createPayment: AsyncThunk<any, any, AsyncThunkConfig> =
  createAsyncThunk(
    'payment', // name of the action
    async (data, thunkAPI) => {
      const { dispatch } = thunkAPI
      loadStripe.setLoadParameters({ advancedFraudSignals: false })

      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!)
      if (!stripe) {
        throw new Error('Failed to load Stripe')
      }
      try {
        const response = await AuthService.createPayment(data)
        handleResponse({ response, dispatch })

        // make the API call

        return response // explicitly return the fetched data
      } catch (err) {
        console.error('Error while redirect to checkout:', err)
        throw err // re-throw to allow error handling in components
      }
    },
  )

export default authSlice.reducer
