import React from 'react'
import {
  DialogTitle,
  Dialog,
  Box,
  Typography,
  DialogContent,
} from '@mui/material'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'

import Stepper from './AddOrganizationSteps/stepper'
import { useAppDispatch } from 'src/Redux/hooks'
import { SetAddOrganization } from 'src/Redux/Slices/orgSlice'

const PopupForm = ({
  openDialog,
  setOpenDialog,
  handleOpen,
}: {
  openDialog: boolean
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
}) => {
  const initialOrgObject = {
    organization_address: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
    },
    uuid: '',
    name: '',
    description: '',
    subscriptionId: null,
    createdAt: '',
    emailId: '',
    phoneNumber: null,
    profileUrl: null,
    address: '',
    bussinessName: '',
    bussinessDescription: '',
    bussinessStreetAddress: '',
    bussinessCity: '',
    bussinessCountry: '',
    bussinessState: '',
    bussinessPostalCode: '',
    bussinessEmailId: '',
    bussinessType: '',
    bussinessSpecialization: '',
    bussinessTrackingName: '',
    bussinessProfileUrl: '',
    bussinessPhoneNumber: '',
    bussinessSecondaryPhoneNumber: '',
    bussinessSecondaryEmailId: '',
    bussinessAdminEmailId: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmailId: '',
    contactPassword: '',
    contactRepeatPassword: '',
  }

  const dispatch = useAppDispatch()
  const handleClose = () => {
    setOpenDialog(false)
    dispatch(SetAddOrganization(initialOrgObject))
  }

  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={openDialog}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Add Organization
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='threeDot'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Stepper handleClose={handleClose} />
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default PopupForm
