// This slice manages the state for the sign in feature
import {
  PayloadAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import secureLocalStorage from 'react-secure-storage'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import MedicalDetailsService from 'src/Redux/Services/MedicalDetailsService'
import {
  AdditionalInformationType,
  GeneralHealthType,
  HospitalAndMedicalFacilityCareType,
  IllnessesInjuriesAndConditionsType,
  MedicalDetailsType,
  MedicalProfessionalDetailType,
  MedicalTestType,
  MedicineType,
  VisionAndHearingType,
} from 'src/Redux/Services/Types/applicationType'
import { thunkAPItype } from 'src/Redux/store'
import { handleResponse } from 'src/Utils/Constants'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: MedicalDetailsType
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {
    GeneralHealth: {
      applicationId: '',
      dateOfBirth: '',
      gender: '',
      feet: 0,
      inch: 0,
      weight: 0,
    },
    VisionAndHearing: {
      medicalDetailId: '',
      zeroOrLessVision2020: undefined,
      visionCorrectorDetail: '',
      isImpairedVision: undefined,
      visionRelatedSymptoms: [],
      additionalVisionRelatedSymptoms: '',
      isPartialOrTotalDeaf: undefined,
      isUsingHearingAids: undefined,
      isImpairedHearing: undefined,
      hearingSymptoms: [],
      additionalHearingRelatedSymptoms: '',
    },
    IllnessesInjuriesAndConditions: {
      medicalDetailId: '',
      muscleOrMemoryRelatedSymptoms: [],
      energyOrSleepRelatedSymptoms: [],
      feverOrSkinRelatedSymptoms: [],
      breathingOrHeartRelatedSymptoms: [],
      speechRelatedSymptoms: [],
      memoryRelatedSymptoms: [],
      mentalHealthRelatedSymptoms: [],
      urinarySymptoms: [],
      dizzinessOrHeadacheRelatedSymptoms: [],
      isSeizureRelatedSymptoms: undefined,
      seizureRelatedSymptoms: [],
      anotherSymptoms: '',
      howLongSymptomLast: '',
      isAfterEffectSeizureExperience: undefined,
      seizureAfterEffectExperience: [],
      emotionalOrLearningProblems: [],
      speechSymptomsDuration: '',
      headachemigrainesymptoms: [],
      headachemigrainesymptomduration: '',
      seizureaftereffectsymptomsduration: '',
    },
    MedicalProfessionalDetail: {
      medicalDetailId: '',
      isMentalIllness: undefined,
      isPhysicalIllness: undefined,
      medicalProfessionals: [
        {
          suffix: '',
          doctorFirstName: '',
          doctorLastName: '',
          doctorMedicalSpecialty: '',
          officeOrClinicName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          firstAppointmentDate: '',
          lastAppointmentDate: '',
          nextAppointmentDate: '',
          treatmentType: [],
          typeOfTherapyDoctorProvided: [],
          otherTreatment: '',
          otherTypeOfTherapyDetails: '',
          specialProcedureDetail: '',
        },
      ],
    },
    HospitalAndMedicalFacilityCare: {
      medicalDetailId: '',
      haveEmergencyRoomVisit: undefined,
      haveEmergencyRoomVisitMedicalFacility: [
        {
          hospitalName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          arrivedDate: '',
          leftDate: '',
          treatmentType: [],
          typeOfTherapyDoctorProvided: [],
          otherTreatment: '',
          otherTypeOfTherapyDetails: '',
          specialProcedureDetail: '',
        },
      ],
      haveMedicalFacility: undefined,
      haveMedicalFacilityMedicalFacility: [
        {
          hospitalName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          arrivedDate: '',
          leftDate: '',
          treatmentType: [],
          typeOfTherapyDoctorProvided: [],
          otherTreatment: '',
          otherTypeOfTherapyDetails: '',
          specialProcedureDetail: '',
        },
      ],
      haveMentalHeathTreatment: undefined,
      haveMentalHeathTreatmentMedicalFacility: [
        {
          hospitalName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          arrivedDate: '',
          leftDate: '',
          treatmentType: [],
          typeOfTherapyDoctorProvided: [],
          otherTreatment: '',
          otherTypeOfTherapyDetails: '',
          specialProcedureDetail: '',
        },
      ],
      haveInpatientOrOverNightStay: undefined,
      haveInpatientOrOverNightStayMedicalFacility: [
        {
          hospitalName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          arrivedDate: '',
          leftDate: '',
          treatmentType: [],
          typeOfTherapyDoctorProvided: [],
          otherTreatment: '',
          otherTypeOfTherapyDetails: '',
          specialProcedureDetail: '',
        },
      ],
      haveOutpatientOrSamedayVisit: undefined,
      haveOutpatientOrSamedayVisitMedicalFacility: [
        {
          hospitalName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          arrivedDate: '',
          leftDate: '',
          treatmentType: [],
          typeOfTherapyDoctorProvided: [],
          otherTreatment: '',
          otherTypeOfTherapyDetails: '',
          specialProcedureDetail: '',
        },
      ],
      haveSurgeries: undefined,
      haveSurgeriesMedicalFacility: [
        {
          hospitalName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          arrivedDate: '',
          leftDate: '',
          treatmentType: [],
          typeOfTherapyDoctorProvided: [],
          otherTreatment: '',
          otherTypeOfTherapyDetails: '',
          specialProcedureDetail: '',
        },
      ],
      havePhysicalTherapy: undefined,
      havePhysicalTherapyMedicalFacility: [
        {
          hospitalName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          arrivedDate: '',
          leftDate: '',
          treatmentType: [],
          typeOfTherapyDoctorProvided: [],
          otherTreatment: '',
          otherTypeOfTherapyDetails: '',
          specialProcedureDetail: '',
        },
      ],
      isDoctorInJail: undefined,
      jailDoctorDetail: [
        {
          organizationName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          contactPersonFirstName: '',
          contactPersonLastName: '',
          arrivedDate: '',
          leftDate: '',
        },
      ],
      isPublicWelfare: undefined,
      welfareDetail: [
        {
          organizationName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          contactPersonFirstName: '',
          contactPersonLastName: '',
          arrivedDate: '',
          leftDate: '',
        },
      ],
      isRecordHeldByAttorney: undefined,
      attorneyDetail: [
        {
          organizationName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          contactPersonFirstName: '',
          contactPersonLastName: '',
          arrivedDate: '',
          leftDate: '',
        },
      ],
      haveDisabilityInsurance: undefined,
      insuranceDetail: [
        {
          organizationName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          contactPersonFirstName: '',
          contactPersonLastName: '',
          arrivedDate: '',
          leftDate: '',
        },
      ],
      isVocationalRehabilitationService: undefined,
      rehabilitationOrganizationDetail: [
        {
          organizationName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          contactPersonFirstName: '',
          contactPersonLastName: '',
          arrivedDate: '',
          leftDate: '',
        },
      ],
      isWorkersCompensation: undefined,
      workersCompensationDetail: [
        {
          organizationName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          contactPersonFirstName: '',
          contactPersonLastName: '',
          arrivedDate: '',
          leftDate: '',
        },
      ],
      isOtherHaveMedicalRecords: undefined,
      otherOrganizationDetail: [
        {
          organizationName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
          phoneNumber: '',
          contactPersonFirstName: '',
          contactPersonLastName: '',
          arrivedDate: '',
          leftDate: '',
        },
      ],
    },
    MedicalTest: {
      medicalDetailId: '',
      isMedicalTestCompleted: undefined,
      medicalTest: [
        {
          medicalTestName: '',
          suffix: '',
          doctorFirstName: '',
          doctorLastName: '',
          testedBodyPart: '',
          mostRecentDateTestPerformed: '',
          futureDateTestPerform: '',
        },
      ],
    },
    Medicine: {
      medicalDetailId: '',
      isMedication: undefined,
      medication: [
        {
          medicationName: '',
          suffix: '',
          doctorFirstName: '',
          doctorLastName: '',
          medicationReason: '',
          sideEffects: '',
        },
      ],
    },
    AdditionalInformation: {
      medicalDetailId: '',
      additionalInformation: '',
    },
  },
}

// Define the reducers that can modify the state
const MedicalDetails = createSlice({
  name: 'MedicalDetails',
  initialState,
  reducers: {
    setMedicalDetailsLoading: (state) => {
      state.loading = true
    },
    setMedicalDetailsSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setMedicalDetailsFail: (state) => {
      state.success = false
      state.loading = false
      state.data = initialState.data
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AddGeneralHealth.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddGeneralHealth.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddGeneralHealth.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(EditGeneralHealth.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      EditGeneralHealth.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(EditGeneralHealth.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddVisionAndHearing.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddVisionAndHearing.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddVisionAndHearing.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddIllnessesInjuriesAndConditions.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddIllnessesInjuriesAndConditions.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddIllnessesInjuriesAndConditions.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddMedicalProfessionalDetail.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddMedicalProfessionalDetail.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddMedicalProfessionalDetail.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddHospitalAndMedicalFacilityCare.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddHospitalAndMedicalFacilityCare.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddHospitalAndMedicalFacilityCare.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(addMedicalTest.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      addMedicalTest.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(addMedicalTest.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(addMedicine.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      addMedicine.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(addMedicine.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(addAdditionalInformation.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      addAdditionalInformation.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(addAdditionalInformation.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

export const {
  setMedicalDetailsLoading,
  setMedicalDetailsSuccess,
  setMedicalDetailsFail,
} = MedicalDetails.actions

export const AddGeneralHealth = createAsyncThunk(
  'GeneralHealth', // name of the action
  async (data: GeneralHealthType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await MedicalDetailsService.AddGeneralHealth(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditGeneralHealth = createAsyncThunk(
  'GeneralHealth', // name of the action
  async (data: GeneralHealthType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await MedicalDetailsService.EditGeneralHealth(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddVisionAndHearing = createAsyncThunk(
  'VisionAndHearing', // name of the action
  async (data: VisionAndHearingType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await MedicalDetailsService.AddVisionAndHearing(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddIllnessesInjuriesAndConditions = createAsyncThunk(
  'IllnessesInjuriesAndConditions', // name of the action
  async (data: IllnessesInjuriesAndConditionsType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response =
        await MedicalDetailsService.AddIllnessesInjuriesAndConditions(
          data,
          token,
          isPublic,
        ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddMedicalProfessionalDetail = createAsyncThunk(
  'MedicalProfessionalDetail', // name of the action
  async (data: MedicalProfessionalDetailType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await MedicalDetailsService.AddMedicalProfessionalDetail(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddHospitalAndMedicalFacilityCare = createAsyncThunk(
  'HospitalAndMedicalFacilityCare', // name of the action
  async (data: HospitalAndMedicalFacilityCareType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response =
        await MedicalDetailsService.AddHospitalAndMedicalFacilityCare(
          data,
          token,
          isPublic,
        ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const addMedicalTest = createAsyncThunk(
  'MedicalTest', // name of the action
  async (data: MedicalTestType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await MedicalDetailsService.addMedicalTest(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const addMedicine = createAsyncThunk(
  'Medicine', // name of the action
  async (data: MedicineType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await MedicalDetailsService.AddMedicine(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const addAdditionalInformation = createAsyncThunk(
  'AdditionalInformation', // name of the action
  async (data: AdditionalInformationType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await MedicalDetailsService.AdditionalInformation(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default MedicalDetails.reducer
