import React from 'react'
import { Box, Grid, Typography, Button, Dialog } from '@mui/material'
import Style from '../index.module.scss'
import { COLORS, convertISOToDate } from 'src/Utils/Constants'
import { useNavigate } from 'react-router-dom'
import { setPublic } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ROUTES } from 'src/Utils/Routes'
import Completed from 'src/Assets/Icons/Completed.svg'
import { ViewProfileRequest } from 'src/Redux/Slices/profileSlice'

const SucessPopUp = ({
  planName,
  open,
  setOpen,
  handleOpen,
}: {
  planName: string
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
}) => {
  const handleClose = () => {
    setOpen(false)
  }
  const userdata = useAppSelector((state) => state.auth?.data)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  return (
    <Dialog
      PaperProps={{
        className: Style.DialogProps,
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '20px',
          padding: '40px',
          maxWidth: 600,
          margin: 'auto',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} display='flex' justifyContent='center'>
            <img
              loading='lazy'
              src={Completed}
              alt='Payment success illustration'
              style={{ width: 64 }}
            />
          </Grid>
          <Grid item xs={12} textAlign='center'>
            <Typography variant='h5' component='div' gutterBottom>
              Payment Success!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' color='primary' textAlign='center'>
              Your {planName} subscription has been activated. A confirmation
              email has been sent to you. If you have any questions, please
              contact us at{' '}
              <span style={{ color: COLORS.MAINCOLOR }}>
                support@benefitsboost.com
              </span>
            </Typography>
          </Grid>
          <Grid item md={12} display='flex' justifyContent='center' flex={1}>
            <Button
              variant='contained'
              color='primary'
              sx={{
                borderRadius: '12px',
                padding: '13px 25px',
                background: COLORS.MAINCOLOR,
                width: '50%',
              }}
              onClick={() => {
                navigate(ROUTES.DASHBOARD)
                dispatch(setPublic(false))
                dispatch(ViewProfileRequest())
              }}
            >
              Go to your dashboard
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default SucessPopUp
