import {
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import remove from 'src/Assets/Icons/remove.svg'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import { MedicalProfessionalDetailType } from 'src/Redux/Services/Types/applicationType'
// import Address from '../AddressDetails'
import Style from './index.module.scss'
import styled from 'styled-components'
import { Calander, MultiSelectDropDown } from 'src/Components/Fields'
import {
  DoctorSpeciality,
  Therapies,
  TreatmentOptions,
} from 'src/Utils/Constants'
import { FullAddress } from 'src/Components/Fields/Address'

interface medicalProfessionalsType {
  suffix: string
  doctorFirstName: string
  doctorLastName: string
  doctorMedicalSpecialty: string
  officeOrClinicName: string
  address: {
    streetAddress: string
    city: string
    state: string
    postalCode: string
    country: string
  }
  phoneNumber: string
  firstAppointmentDate: string
  lastAppointmentDate: string
  nextAppointmentDate: string
  treatmentType: string[]
  typeOfTherapyDoctorProvided: string[]
  otherTreatment: string
  otherTypeOfTherapyDetails: string
  specialProcedureDetail: string
}

const StyledSelect = styled(Select)({
  borderRadius: '10px',
})

const DetailBox = ({
  formValues,
  realValue,
  setFormValues,
  changeHandler,
  handleRemove,
  index,
  errors,
}: {
  formValues: medicalProfessionalsType
  realValue: MedicalProfessionalDetailType
  setFormValues: React.Dispatch<
    React.SetStateAction<MedicalProfessionalDetailType>
  >
  changeHandler: (data: { name: string; value: string | string[] }) => void
  handleRemove: () => void
  index: number
  errors: Record<string, string>
}) => {
  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>Profile {index + 1}</Typography>
          {index > 0 && (
            <img
              src={remove}
              alt=''
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleRemove()
              }}
            />
          )}
        </Grid>

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Prefix<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <StyledSelect
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              className={Style.AnsTxt}
              IconComponent={(props) => (
                <IconButton {...props}>
                  <img src={IconChevronDown} alt='IconChevronDown' />
                </IconButton>
              )}
              displayEmpty
              renderValue={
                formValues?.suffix !== ''
                  ? () => formValues?.suffix
                  : () => 'Select'
              }
              value={formValues?.suffix}
              fullWidth
              id='suffix'
              name='suffix'
              error={!!errors?.suffix}
            >
              <MenuItem value=''>
                <em>Select an Answer</em>
              </MenuItem>
              <MenuItem value='Dr.'>Dr.</MenuItem>
              <MenuItem value='Ms.'>Ms.</MenuItem>
              <MenuItem value='Mrs.'>Mrs.</MenuItem>
              <MenuItem value='Mr.'>Mr.</MenuItem>
            </StyledSelect>
            <FormHelperText sx={{ mx: 1.5 }}>{errors?.suffix}</FormHelperText>
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              First Name<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='doctorFirstName'
              name='doctorFirstName'
              placeholder='Enter First Name'
              className={Style.AnsTxt}
              value={formValues?.doctorFirstName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.doctorFirstName}
              helperText={errors?.doctorFirstName}
            />
          </Grid>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Last Name<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='doctorLastName'
              name='doctorLastName'
              placeholder='Enter Last Name'
              className={Style.AnsTxt}
              value={formValues?.doctorLastName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.doctorLastName}
              helperText={errors?.doctorLastName}
            />
          </Grid>
        </Grid>

        <Grid width={'100%'}>
          <InputLabel className={Style.label}>
            Doctor's Medical Specialty<span style={{ color: 'red' }}>*</span>
          </InputLabel>
          <StyledSelect
            onChange={(e) => {
              changeHandler({ name: e.target.name, value: e.target.value })
            }}
            className={Style.AnsTxt}
            IconComponent={(props) => (
              <IconButton {...props}>
                <img src={IconChevronDown} alt='IconChevronDown' />
              </IconButton>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Adjust the value as needed
                },
              },
            }}
            renderValue={
              formValues?.doctorMedicalSpecialty !== ''
                ? () => formValues?.doctorMedicalSpecialty
                : () => 'Select'
            }
            value={formValues?.doctorMedicalSpecialty}
            fullWidth
            id='doctorMedicalSpecialty'
            name='doctorMedicalSpecialty'
            error={!!errors?.doctorMedicalSpecialty}
          >
            <MenuItem value=''>
              <em>Select an Answer</em>
            </MenuItem>
            {DoctorSpeciality?.map((item) => (
              <MenuItem key={item.value} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </StyledSelect>
          <FormHelperText sx={{ mx: 1.5 }}>
            {errors?.doctorMedicalSpecialty}
          </FormHelperText>
        </Grid>

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Office or Clinic Name
            </InputLabel>
            <TextField
              fullWidth
              type='text'
              id='officeOrClinicName'
              name='officeOrClinicName'
              placeholder='Contact Person Name'
              className={Style.AnsTxt}
              value={formValues?.officeOrClinicName}
              onChange={(e) => {
                changeHandler({ name: e.target.name, value: e.target.value })
              }}
              error={!!errors?.officeOrClinicName}
              helperText={errors?.officeOrClinicName}
            />
          </Grid>
        </Grid>

        <FullAddress
          arrayName='mentalIllnessMedicalProfessionals'
          realValues={realValue}
          formValues={formValues}
          handleFieldChange={changeHandler}
          index={index}
          setFormValues={setFormValues}
          errors={errors}
        />

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>Phone Number</InputLabel>
            <TextField
              fullWidth
              id='phoneNumber'
              name='phoneNumber'
              placeholder='phoneNumber Number'
              inputProps={{ maxLength: 10 }}
              className={Style.AnsTxt}
              value={formValues?.phoneNumber}
              onChange={(e) => {
                if (/^\d*$/.test(e.target.value)) {
                  changeHandler({ name: e.target.name, value: e.target.value })
                }
              }}
              error={!!errors?.phoneNumber}
              helperText={errors?.phoneNumber}
            />
          </Grid>
        </Grid>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          {/* <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Estimated First Appointment Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: '100%' }}
                value={
                  formValues?.firstAppointmentDate
                    ? dayjs(formValues?.firstAppointmentDate)
                    : null
                } // Convert to Dayjs object
                onChange={(e) => {
                  changeHandler({
                    name: 'firstAppointmentDate',
                    value: dayjs(e).format('YYYY-MM-DD'),
                  })
                }}
                maxDate={dayjs()} // Use Dayjs for maxDate as well
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    id='firstAppointmentDate'
                    name='firstAppointmentDate'
                    placeholder='mm-DD-YYYY'
                    className={Style.AnsTxt}
                    InputProps={{
                      inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid> */}
          <Calander
            title={'Estimated First Appointment Date'}
            value={formValues.firstAppointmentDate}
            name={'firstAppointmentDate'}
            error={errors?.firstAppointmentDate}
            handleChange={changeHandler}
          />

          {/* <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Estimated Last Appointment Date
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: '100%' }}
                value={
                  formValues?.lastAppointmentDate
                    ? dayjs(formValues?.lastAppointmentDate)
                    : null
                } // Convert to Dayjs object
                onChange={(e) => {
                  changeHandler({
                    name: 'lastAppointmentDate',
                    value: dayjs(e).format('YYYY-MM-DD'),
                  })
                }}
                maxDate={dayjs()} // Use Dayjs for maxDate as well
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    id='lastAppointmentDate'
                    name='lastAppointmentDate'
                    placeholder='mm-DD-YYYY'
                    className={Style.AnsTxt}
                    InputProps={{
                      inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid> */}
          <Calander
            minDate={formValues?.firstAppointmentDate}
            title={'Estimated Last Appointment Date'}
            value={formValues.lastAppointmentDate}
            name={'lastAppointmentDate'}
            error={errors?.lastAppointmentDate}
            handleChange={changeHandler}
          />
        </Grid>

        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <InputLabel className={Style.label}>
              Date (or estimate) of Next Appointment
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: '100%' }}
                value={
                  formValues?.nextAppointmentDate
                    ? dayjs(formValues?.nextAppointmentDate)
                    : null
                } // Convert to Dayjs object
                onChange={(e) => {
                  changeHandler({
                    name: 'nextAppointmentDate',
                    value: dayjs(e).format('YYYY-MM-DD'),
                  })
                }}
                maxDate={dayjs()} // Use Dayjs for maxDate as well
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    style={{ width: '100%' }}
                    id='nextAppointmentDate'
                    name='nextAppointmentDate'
                    placeholder='mm-DD-YYYY'
                    className={Style.AnsTxt}
                    InputProps={{
                      inputProps: { max: dayjs().format('YYYY-MM-DD') }, // Use Dayjs to format the max date
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <FormHelperText sx={{ mt: '-6px', ml: '10px' }}>
          {errors?.nextAppointmentDate}
        </FormHelperText>

        <Box>
          <MultiSelectDropDown
            name={'treatmentType'}
            value={formValues?.treatmentType ?? []}
            error={errors?.treatmentType}
            label={
              'Please tell us the type of treatment that you received from this doctor'
            }
            menulist={TreatmentOptions}
            handleSelectChange={(name: string[]) => {
              // if (formValues.treatmentType?.includes(name)) {
              //   changeHandler({
              //     name: 'treatmentType',
              //     value: formValues?.treatmentType.filter((i) => i !== name),
              //   })
              // } else {
              changeHandler({
                name: 'treatmentType',
                value: name,
              })
              // }
            }}
          />
        </Box>

        {formValues?.treatmentType?.find((i) => i === 'Therapy') ? (
          <Box>
            <MultiSelectDropDown
              name={'typeOfTherapyDoctorProvided'}
              value={formValues.typeOfTherapyDoctorProvided ?? []}
              error={errors.typeOfTherapyDoctorProvided}
              label={
                'Please tell us the type of treatment that you received from this doctor'
              }
              menulist={Therapies}
              handleSelectChange={(name: string[]) => {
                // if (formValues.typeOfTherapyDoctorProvided?.includes(name)) {
                //   changeHandler({
                //     name: 'typeOfTherapyDoctorProvided',
                //     value: formValues.typeOfTherapyDoctorProvided.filter(
                //       (i) => i !== name,
                //     ),
                //   })
                // } else {
                changeHandler({
                  name: 'typeOfTherapyDoctorProvided',
                  value: name,
                })
                // }
              }}
            />
          </Box>
        ) : (
          <></>
        )}

        {formValues.typeOfTherapyDoctorProvided?.find((i) => i === 'Other') ? (
          <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
            <Grid width={'100%'}>
              <InputLabel className={Style.label}>
                If you received any other type of therapy, please explain
              </InputLabel>
              <TextField
                fullWidth
                type='text'
                id='otherTypeOfTherapyDetails'
                name='otherTypeOfTherapyDetails'
                multiline
                rows={4}
                className={Style.AnsTxt}
                placeholder='Other'
                value={formValues?.otherTypeOfTherapyDetails}
                onChange={(e) => {
                  changeHandler({ name: e.target.name, value: e.target.value })
                }}
                error={!!errors?.otherTypeOfTherapyDetails}
                helperText={errors?.otherTypeOfTherapyDetails}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        {formValues.treatmentType?.find((i) => i === 'Other') ? (
          <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
            <Grid width={'100%'}>
              <InputLabel className={Style.label}>
                If you received any other type of treatment, please explain
              </InputLabel>
              <TextField
                fullWidth
                type='text'
                id='otherTreatment'
                name='otherTreatment'
                multiline
                rows={4}
                className={Style.AnsTxt}
                placeholder='Other'
                value={formValues?.otherTreatment}
                onChange={(e) => {
                  changeHandler({ name: e.target.name, value: e.target.value })
                }}
                error={!!errors?.otherTreatment}
                helperText={errors?.otherTreatment}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        {formValues.treatmentType?.find(
          (i) => i === 'Special procedures (e.g., steroid injections)',
        ) ? (
          <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
            <Grid width={'100%'}>
              <InputLabel className={Style.label}>
                If you received any other type of Special procedures (e.g.,
                steroid injections), please explain
              </InputLabel>
              <TextField
                fullWidth
                type='text'
                id='specialProcedureDetail'
                name='specialProcedureDetail'
                multiline
                rows={4}
                className={Style.AnsTxt}
                placeholder='Other'
                value={formValues?.specialProcedureDetail}
                onChange={(e) => {
                  changeHandler({ name: e.target.name, value: e.target.value })
                }}
                error={!!errors?.specialProcedureDetail}
                helperText={errors?.specialProcedureDetail}
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </>
  )
}
export default DetailBox
