import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import remove from 'src/Assets/Icons/remove.svg'
import { Name } from 'src/Components/Fields'
import Style from './index.module.scss'

interface legalNames {
  firstName: string
  middleName: string
  lastName: string
}

const ProfileBox = ({
  errors,
  formValues,
  changeHandler,
  handleRemove,
  index,
}: {
  errors: Record<string, string>
  formValues: legalNames
  changeHandler: (data: { name: string; value: string | number }) => void
  handleRemove: () => void
  index: number
}) => {
  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>Profile {index + 1}</Typography>
          {index > 0 && (
            <img
              src={remove}
              alt=''
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleRemove()
              }}
            />
          )}
        </Grid>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Name
            name='firstName'
            value={formValues.firstName}
            title='First Name'
            handleChange={changeHandler}
            error={errors[`firstName[${index}]`]}
          />
          <Name
            name='middleName'
            value={formValues.middleName}
            title='Middle Name'
            handleChange={changeHandler}
            error={errors[`middleName[${index}]`]}
          />
          <Name
            name='lastName'
            value={formValues.lastName}
            title='Last Name'
            handleChange={changeHandler}
            error={errors[`lastName[${index}]`]}
          />
        </Grid>
      </Box>
    </>
  )
}
export default ProfileBox
