import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import {
  AddPermissionRequest,
  ViewPermissionRequest,
} from 'src/Redux/Slices/permissionManageSlice'
import { useAppDispatch } from 'src/Redux/hooks'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import styled from 'styled-components'

const StyledSelect = styled(Select)({
  borderRadius: '10px', // Set the border radius to your desired value
  // Add any other custom styles here
})
const PopupForm = ({
  openDialog,
  setOpenDialog,
  handleOpen,
}: {
  openDialog: boolean
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
}) => {
  const dispatch = useAppDispatch()

  const handleClose = () => {
    setOpenDialog(false)
  }

  const [formValues, setFormValues] = useState({
    name: '',
    scope: '',
    description: '',
  })
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    // You may perform validation here and update errors state accordingly
  }
  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (!formValues.name.trim()) {
      newErrors.name = 'Permission Name is required'
      valid = false
    }
    if (!formValues.scope.trim()) {
      newErrors.scope = 'Scope is required'
      valid = false
    }
    if (!formValues.description.trim()) {
      newErrors.description = 'Description is required'
      valid = false
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      dispatch(AddPermissionRequest(formValues)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          dispatch(ViewPermissionRequest())
        },
      )
      setOpenDialog(false)
    } else setOpenDialog(true)
  }

  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={openDialog}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Add New Permission
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='threeDot'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={Style.DialogContentBox}>
              <form onSubmit={handleSubmit}>
                <Box width={'100%'}>
                  <InputLabel className={Style.label}>
                    Permission Name<span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='text'
                    id='name'
                    name='name'
                    className={Style.AnsTxt}
                    placeholder='Role Name'
                    value={formValues?.name}
                    onChange={handleFieldChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Box>

                <Box width={'100%'}>
                  <InputLabel className={Style.label} htmlFor='scope'>
                    Scope<span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <StyledSelect
                    onChange={(e) => {
                      handleFieldChange(e)
                    }}
                    className={Style.AnsTxt}
                    IconComponent={(props) => (
                      <IconButton {...props}>
                        <img src={IconChevronDown} alt='IconChevronDown' />
                      </IconButton>
                    )}
                    displayEmpty
                    renderValue={
                      formValues.scope !== ''
                        ? () => formValues.scope
                        : () => 'Select'
                    }
                    value={formValues.scope}
                    fullWidth
                    id='scope'
                    name='scope'
                    placeholder='Scope'
                    error={!!errors.scope}
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value='Admin'>Admin</MenuItem>
                    <MenuItem value='Legal'>Legal</MenuItem>
                    <MenuItem value='Both'>Both</MenuItem>
                  </StyledSelect>
                  <FormHelperText sx={{ mx: 1.5 }}>
                    {errors.scope}
                  </FormHelperText>
                </Box>

                <Box width={'100%'} pt={1}>
                  <InputLabel className={Style.label} htmlFor='Description'>
                    Description<span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='text'
                    id='description'
                    name='description'
                    placeholder='Description'
                    multiline
                    rows={4}
                    className={Style.AnsTxt}
                    value={formValues.description}
                    onChange={handleFieldChange}
                    error={!!errors.description}
                    helperText={errors.description}
                  />
                </Box>
                <DialogActions>
                  <Box className={Style.AddButton}>
                    <Button
                      variant='contained'
                      type='submit'
                      className={Style.Button}
                    >
                      Add
                    </Button>
                  </Box>
                </DialogActions>
              </form>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default PopupForm
