import * as React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

interface MenuItem {
  label: string
  href?: string
}

export default function CustomSeparator({
  menuItems,
}: {
  menuItems: MenuItem[]
}) {
  const breadcrumbs = menuItems.map((item, index) => {
    return (
      <Typography key={index.toString()} color='text.primary'>
        {item.label}
      </Typography>
    )
  })

  return (
    <Stack spacing={2}>
      <Breadcrumbs separator='›' aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  )
}
