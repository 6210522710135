import { FormControlLabel, TableCell, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import MoreIcon from 'src/Assets/Icons/More.svg'
import {
  DeleteSubscriptionRequest,
  SubscriptionListRequest,
} from 'src/Redux/Slices/subscriptionSlice'
import { useAppDispatch } from 'src/Redux/hooks'
import AddEdit from '../AddEditBox'
import Style from '../index.module.scss'
import { IOSSwitch } from 'src/Theme'

export const SubscriptionTable = (subscriptionData: {
  id: string
  name: string
  plan: string
  amount: number
  subscriptionPeriod: number
  status: string
  createdAt: string
  description: string
  isDefault: boolean
}) => {
  const [checked, setChecked] = React.useState(
    subscriptionData.status === 'active',
  )
  const [openEdit, setOpenEdit] = React.useState(false)
  const anchor = React.useRef()
  const dispatch = useAppDispatch()

  useEffect(() => {
    setChecked(subscriptionData.status === 'active')
  }, [subscriptionData])

  const handleCloseEdit = () => {
    setOpenEdit(false)
  }
  const handleOpenEdit = () => {
    if (!subscriptionData?.isDefault) setOpenEdit(true)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    dispatch(
      DeleteSubscriptionRequest({
        uuid: subscriptionData.id,
        isActive: event.target.checked,
      }),
    ).then((res) => {
      if (res.payload?.success) {
        dispatch(SubscriptionListRequest())
      }
    })
  }

  return (
    <>
      <TableCell width={'15%'} className={Style.TableCell}>
        <Typography className={Style.ContentHead}>
          {subscriptionData.name}
        </Typography>
      </TableCell>
      <TableCell width={'30%'} className={Style.TableCell}>
        <Typography className={Style.ContentHead}>
          {subscriptionData.description}
        </Typography>
      </TableCell>
      <TableCell className={Style.TableCell}>
        <Typography className={Style.ContentHead}>
          ${subscriptionData.amount}
        </Typography>
      </TableCell>
      <TableCell className={Style.TableCell}>
        <Typography className={Style.ContentHead}>
          {subscriptionData.subscriptionPeriod} {subscriptionData.plan}
        </Typography>
      </TableCell>
      <TableCell className={Style.TableCell}>
        <Typography className={Style.ContentHead}>
          {new Date(subscriptionData.createdAt).toLocaleDateString()}
        </Typography>
      </TableCell>
      <TableCell className={Style.TableCell}>
        <FormControlLabel
          disabled={subscriptionData?.isDefault}
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              checked={checked}
              onChange={handleChange}
            />
          }
          label=''
        />
      </TableCell>
      <TableCell
        ref={anchor}
        onClick={handleOpenEdit}
        align='right'
        className={Style.TableCell}
      >
        <Tooltip
          title={subscriptionData?.isDefault ? 'Default' : 'Edit'}
          style={{ cursor: 'pointer' }}
        >
          <img alt='' src={MoreIcon} />
        </Tooltip>
      </TableCell>
      {!subscriptionData?.isDefault && openEdit && (
        <AddEdit
          type='edit'
          openEdit={openEdit}
          handleCloseEdit={handleCloseEdit}
          subscriptionId={subscriptionData?.id}
        />
      )}
    </>
  )
}
