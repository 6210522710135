// This slice manages the state for the sign in feature
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import secureLocalStorage from 'react-secure-storage'
import ApplicationService from 'src/Redux/Services/applicationService'
import { thunkAPItype } from 'src/Redux/store'
import { TIMER, handleResponse } from 'src/Utils/Constants'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: Record<string, string>
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailId: '',
  },
}

// Define the reducers that can modify the state
const AssistantSlice = createSlice({
  name: 'AssistantForm',
  initialState,
  reducers: {
    setAssistantLoading: (state) => {
      state.loading = true
    },
    setAssistantSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setAssistantFail: (state) => {
      state.success = false
      state.loading = false
      state.data = initialState.data
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GenApplication.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      GenApplication.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(GenApplication.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

export const { setAssistantLoading, setAssistantSuccess, setAssistantFail } =
  AssistantSlice.actions

export const GenApplication = createAsyncThunk(
  'GenApplication', // name of the action
  async (data: Record<string, string>, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const token = secureLocalStorage.getItem('token') as string
      const response = await ApplicationService.GenApplication(data, token) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const VerifyApplink = createAsyncThunk(
  'VerifyApplink', // name of the action
  async (link: string, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const response = await ApplicationService.VerifyApplink(link) // make the API call
      handleResponse({ response, dispatch })

      if (response.data.data) {
        const expirationTime = new Date()
        expirationTime.setMinutes(expirationTime.getMinutes() + TIMER)
        // Convert expiration time to a string format suitable for storing in localStorage
        const tokenExpiration = expirationTime.toISOString()
        secureLocalStorage.setItem('token', response.data.data.token)
        secureLocalStorage.setItem('tokenExpiration', tokenExpiration)
      }
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default AssistantSlice.reducer
