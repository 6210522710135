import React, { useRef, useState } from 'react'
import { Box, Typography, Button, Grid, Divider, styled } from '@mui/material'
import Styles from '../index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import GeneralHealth from '../EditForms/GeneralHealth'
import { convertISOToDate, hasEmpty } from 'src/Utils/Constants'
import CustomAlert from 'src/Components/NoDataAvilableBox/index'
import Tooltip from '@mui/material/Tooltip'
import error from 'src/Assets/Icons/error.svg'

const MedicalDetails: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)
  const generalHelthRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    generalHelthRef?.current?.scrollIntoView()
  }

  const data = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.MedicalDetails?.GeneralHealth,
  )
  const flagData = useAppSelector(
    (state: RootState) =>
      state?.applicationStore?.MedicalDetails?.medicalFlagData?.GeneralHealth,
  )
  const generalHealthSectionId = useAppSelector(
    (state) =>
      state?.steps?.MedicalApplicationSteps?.find(
        (step: { label: string }) => step.label === 'General Health',
      )?.sectionId,
  )
  const ErrorImage = styled('img')({
    marginRight: '8px', // Adjust margin as needed
    marginBottom: '-8px', // Adjust margin as needed
    paddingRight: '10px',
    paddingLeft: '10px',
    // position: 'absolute',
  })
  return (
    <div id={generalHealthSectionId} ref={generalHelthRef}>
      {!editMode ? (
        <>
          <Box className={Styles.MainBox}>
            <Box className={Styles.title}>
              <Box className={Styles.main}>
                <Typography variant='h6' className={Styles.textWrapper}>
                  General Health
                </Typography>
                <Button
                  className={Styles.button}
                  disabled={hasEmpty(data)}
                  onClick={handleEditToggle}
                >
                  <Typography className={Styles.div}>Edit</Typography>
                </Button>
              </Box>
              <div className={Styles.dottedLine} />
            </Box>

            {!hasEmpty(data) ? (
              <>
                <Box className={Styles.div2}>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.p}>
                        Date of Birth
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography
                        className={
                          flagData?.dateOfBirth?.length > 0
                            ? Styles.flagTextWrapper2
                            : Styles.textWrapper2
                        }
                      >
                        {convertISOToDate(data?.dateOfBirth)}
                        {flagData?.dateOfBirth?.length > 0 ? (
                          <>
                            <Tooltip title={flagData?.dateOfBirth}>
                              <ErrorImage src={error} alt='Error' />
                            </Tooltip>
                          </>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={Styles.element2}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.textWrapper3}>
                        Gender
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography
                        className={
                          flagData?.gender?.length > 0
                            ? Styles.flagTextWrapper2
                            : Styles.textWrapper2
                        }
                      >
                        {data?.gender}
                        {flagData?.gender?.length > 0 ? (
                          <>
                            <Tooltip title={flagData?.gender}>
                              <ErrorImage src={error} alt='Error' />
                            </Tooltip>
                          </>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={Styles.element2}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.textWrapper3}>
                        Height
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography
                        className={
                          flagData?.inch?.length > 0
                            ? Styles.flagTextWrapper2
                            : Styles.textWrapper2
                        }
                      >
                        {data?.feet} Feet {data?.inch} Inch
                        {flagData?.inch?.length > 0 ? (
                          <>
                            <Tooltip title={flagData?.inch}>
                              <ErrorImage src={error} alt='Error' />
                            </Tooltip>
                          </>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={Styles.element}>
                    <Box className={Styles.question}>
                      <Typography className={Styles.textWrapper3}>
                        Weight
                      </Typography>
                    </Box>
                    <Box className={Styles.ans}>
                      <Typography
                        className={
                          flagData?.weight?.length > 0
                            ? Styles.flagTextWrapper2
                            : Styles.textWrapper2
                        }
                      >
                        {data?.weight}lbs
                        {flagData?.weight?.length > 0 ? (
                          <>
                            <Tooltip title={flagData?.weight}>
                              <ErrorImage src={error} alt='Error' />
                            </Tooltip>
                          </>
                        ) : null}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <CustomAlert message='no data available.' />
            )}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  General Health
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <>
                  <GeneralHealth handleEditToggle={handleEditToggle} />
                </>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

export default MedicalDetails
