import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import { ROUTES } from 'src/Utils/Routes'
import { TIMER } from 'src/Utils/Constants'

const SessionTimeoutChecker: React.FC = () => {
  const navigate = useNavigate()
  const [lastActive, setLastActive] = useState(new Date().getTime())

  useEffect(() => {
    const sessionTimeOut = () => {
      const currentTime = new Date().getTime()
      const inactiveTime = currentTime - lastActive

      if (inactiveTime > TIMER * 60 * 1000) {
        // Token has expired, delete it from localStorage
        secureLocalStorage.removeItem('token')
        secureLocalStorage.removeItem('tokenExpiration')
        navigate(ROUTES.SIGN_IN)
      }
    }

    const intervalId = setInterval(sessionTimeOut, 60 * 1000)

    const onMouseMove = () => {
      setLastActive(new Date().getTime())
    }

    const onKeyDown = () => {
      setLastActive(new Date().getTime())
    }

    const onKeyUp = () => {
      setLastActive(new Date().getTime())
    }

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)

    return () => {
      clearInterval(intervalId)
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [lastActive]) // Only run this effect when lastActive changes

  return null // This component does not render anything
}

export default SessionTimeoutChecker
