// This slice manages the state for the sign in feature
import { createSlice } from '@reduxjs/toolkit'

// Define the initial state of the slice
const initialState = {
  isBotOpen: false,
  BasicDetailsStep: '',
  FamilyDetailsStep: '',
  LegalDetailsStep: '',
  DisabilityDetailsStep: '',
  EducationDetailsStep: '',
  MedicalDetailsStep: '',
  UploadDocumentStep: '',
  SignConsentStep: '',
  SubmitStep: '',
}

// Define the reducers that can modify the state
const applicationStepStore = createSlice({
  name: 'applicationStep',
  initialState,
  reducers: {
    resetApplicationStep: (state) => {
      state.isBotOpen = false
      state.BasicDetailsStep = null ?? ''
      state.FamilyDetailsStep = null ?? ''
      state.LegalDetailsStep = null ?? ''
      state.DisabilityDetailsStep = null ?? ''
      state.EducationDetailsStep = null ?? ''
      state.MedicalDetailsStep = null ?? ''
      state.UploadDocumentStep = null ?? ''
      state.SignConsentStep = null ?? ''
      state.SubmitStep = null ?? ''
    },

    toggleBotState: (state, action: { payload: boolean }) => {
      state.isBotOpen = action.payload
    },

    setBasicDetailsStep: (state, action: { payload: string }) => {
      state.BasicDetailsStep = action.payload
    },
    setFamilyDetailsStep: (state, action: { payload: string }) => {
      state.FamilyDetailsStep = action.payload
    },
    setLegalDetailsStep: (state, action: { payload: string }) => {
      state.LegalDetailsStep = action.payload
    },
    setDisabilityDetailsStep: (state, action: { payload: string }) => {
      state.DisabilityDetailsStep = action.payload
    },
    setEducationDetailsStep: (state, action: { payload: string }) => {
      state.EducationDetailsStep = action.payload
    },
    setMedicalDetailsStep: (state, action: { payload: string }) => {
      state.MedicalDetailsStep = action.payload
    },
    setUploadDocumentStep: (state, action: { payload: string }) => {
      state.UploadDocumentStep = action.payload
    },
    setSignConsentStep: (state, action: { payload: string }) => {
      state.SignConsentStep = action.payload
    },
    setSubmitStep: (state, action: { payload: string }) => {
      state.SubmitStep = action.payload
    },
  },
})

export default applicationStepStore.reducer

export const {
  toggleBotState,
  resetApplicationStep,
  setBasicDetailsStep,
  setFamilyDetailsStep,
  setLegalDetailsStep,
  setDisabilityDetailsStep,
  setEducationDetailsStep,
  setMedicalDetailsStep,
  setUploadDocumentStep,
  setSignConsentStep,
  setSubmitStep,
} = applicationStepStore.actions
