import React, { useEffect, useState } from 'react'
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  Box,
  Typography,
  DialogContent,
  TextField,
  Checkbox,
  Grid,
  InputLabel,
  IconButton,
  MenuItem,
  Select,
  FormHelperText,
  // IconButton,
} from '@mui/material'
import { Icons } from 'src/Utils/Constants'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import Style from './index.module.scss'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { AddRoleRequest, ViewRoleRequest } from 'src/Redux/Slices/roleSlice'
import { ViewPermissionRequest } from 'src/Redux/Slices/permissionManageSlice'
import styled from 'styled-components'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'

interface DataType {
  name: string
  scope: string
  description: string
  permissions: string[]
}
interface permissionType {
  id: string
  name: string
  scope: string
  description: string
  permissions: string[]
}
const StyledSelect = styled(Select)({
  borderRadius: '10px', // Set the border radius to your desired value
  // Add any other custom styles here
})

const PopupForm = ({
  openDialog,
  setOpenDialog,
  handleOpen,
}: {
  openDialog: boolean
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
}) => {
  const dispatch = useAppDispatch()
  const PermissionList = useAppSelector(
    (state) => state.permissionManage?.permissionList?.data,
  )

  const handleClose = () => {
    setOpenDialog(false)
  }
  const [checked1, setChecked1] = useState<boolean[]>([])
  const [filteredPermissionList, setFilteredPermissionList] = useState<
    permissionType[]
  >([])

  const [formValues, setFormValues] = useState<DataType>({
    name: '',
    scope: '',
    description: '',
    permissions: [],
  })
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    // You may perform validation here and update errors state accordingly
  }

  useEffect(() => {
    PermissionList?.length > 0 &&
      setFilteredPermissionList(
        PermissionList?.filter((item: { scope: string }) => {
          return item.scope === formValues.scope || item.scope === 'Both'
        }),
      )
  }, [formValues.scope])

  const handleCheckboxChange1 = (index: number, item: { id: string }) => {
    setChecked1((prev) => {
      const newChecked1 = [...prev]
      newChecked1[index] = !newChecked1[index]
      return newChecked1
    })
    if (!checked1[index]) {
      const newPermissions = formValues.permissions

      newPermissions.push(item.id)
      setFormValues({ ...formValues, permissions: newPermissions })
    } else {
      const newPermissions = formValues.permissions
      setFormValues({
        ...formValues,
        permissions: newPermissions.filter((i) => i !== item.id),
      })
    }
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (!formValues.name.trim()) {
      newErrors.name = 'Role Name is required'
      valid = false
    }
    if (!formValues.scope.trim()) {
      newErrors.scope = 'Scope is required'
      valid = false
    }
    if (!formValues.description.trim()) {
      newErrors.description = 'Description is required'
      valid = false
    }
    if (!formValues.permissions.length) {
      newErrors.permissions = 'Permissions is required'
      valid = false
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      dispatch(AddRoleRequest(formValues)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          dispatch(ViewRoleRequest())
          dispatch(ViewPermissionRequest())
        },
      )
      setOpenDialog(false)
    } else setOpenDialog(true)
  }
  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={openDialog}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Add Role
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='threeDot'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={Style.DialogContentBox}>
              <form onSubmit={handleSubmit}>
                <Box width={'100%'}>
                  <InputLabel className={Style.label} htmlFor='RoleName'>
                    Role Name <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='text'
                    id='name'
                    name='name'
                    placeholder='Role Name'
                    className={Style.AnsTxt}
                    sx={{ backgroundColor: '#EDEFF4' }}
                    value={formValues.name}
                    onChange={handleFieldChange}
                    error={!!errors.name}
                    // helperText={errors.name}
                  />
                  <FormHelperText sx={{ mx: 1.5 }}>
                    {errors.name}
                  </FormHelperText>
                </Box>

                <Box width={'100%'}>
                  <InputLabel className={Style.label} htmlFor='scope'>
                    Scope <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <StyledSelect
                    onChange={(e) => {
                      handleFieldChange(e)
                      setChecked1([])
                      setFilteredPermissionList([])
                    }}
                    className={Style.AnsTxt}
                    IconComponent={(props) => (
                      <IconButton {...props}>
                        <img src={IconChevronDown} alt='IconChevronDown' />
                      </IconButton>
                    )}
                    displayEmpty
                    renderValue={
                      formValues.scope !== ''
                        ? () => formValues.scope
                        : () => 'Select'
                    }
                    value={formValues.scope}
                    fullWidth
                    id='scope'
                    name='scope'
                    placeholder='Scope'
                    error={!!errors.scope}
                  >
                    <MenuItem value='Admin'>Admin</MenuItem>
                    <MenuItem value='Legal'>Legal</MenuItem>
                  </StyledSelect>
                  <FormHelperText sx={{ mx: 1.5 }}>
                    {errors.scope}
                  </FormHelperText>
                </Box>

                <Box width={'100%'} pt={1}>
                  <InputLabel className={Style.label}>
                    Description <span style={{ color: 'red' }}>*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    type='text'
                    id='description'
                    name='description'
                    placeholder='Description'
                    multiline
                    rows={4}
                    className={Style.AnsTxt}
                    value={formValues.description}
                    onChange={handleFieldChange}
                    error={!!errors.description}
                    helperText={errors.description}
                  />
                </Box>
                <Box pt={1} className={Style.CBox}>
                  <InputLabel className={Style.label}>
                    Select Modules to Assign the Permissions
                    <span style={{ color: 'red' }}> *</span>
                  </InputLabel>
                  <Grid xs={12} className={Style.Checkbox}>
                    {filteredPermissionList?.length &&
                      filteredPermissionList?.map(
                        (item: permissionType, index: number) => {
                          return (
                            <Box key={index} className={Style.CheckGrid}>
                              <label
                                htmlFor={`checkbox-${index}`}
                                className={Style.CheckLabel}
                              >
                                <Checkbox
                                  className={Style.Check}
                                  checked={checked1[index]}
                                  color='primary'
                                  id={`checkbox-${index}`}
                                  onClick={() => {
                                    handleCheckboxChange1(index, item)
                                  }}
                                />
                                <Typography className={Style.CheckText}>
                                  {item?.name}
                                </Typography>
                              </label>
                            </Box>
                          )
                        },
                      )}
                  </Grid>
                  <FormHelperText sx={{ mx: 1.5 }}>
                    {errors.permissions}
                  </FormHelperText>
                </Box>
                <DialogActions>
                  <Box className={Style.AddButton}>
                    <Button
                      variant='contained'
                      type='submit'
                      className={Style.Button}
                    >
                      Add
                    </Button>
                  </Box>
                </DialogActions>
              </form>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  )
}

export default PopupForm
