import { Box, Menu, MenuItem, TableCell, Typography } from '@mui/material'
import dot from 'src/Assets/Icons/Action.svg'
import Style from './index.module.scss'
import { useState } from 'react'
import EditPermission from 'src/Components/Employees/EditPermission'
import { useAppDispatch } from 'src/Redux/hooks'
import {
  DeletPermissionRequest,
  ViewPermissionRequest,
} from 'src/Redux/Slices/permissionManageSlice'
import DeletePopUp from 'src/Components/DeletePopUp'
export const PermissionsTable = (item: {
  id: string
  name: string
  description: string
  scope: string
  roleName: []
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [Editopen, setEditOpen] = useState(false)
  const [permissionId, setPermissionId] = useState<string>('')
  const [open, setOpen] = useState(false)
  const ActionOpen = Boolean(anchorEl)
  const dispatch = useAppDispatch()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleEditOpen = () => {
    setEditOpen(true)
  }

  const handleDelete = (id: string) => {
    try {
      dispatch(DeletPermissionRequest(id)).then((res) => {
        if (res.payload?.success === true) {
          setOpen(false)
          dispatch(ViewPermissionRequest())
        } else if (res.payload?.success === false) {
          setOpen(true)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <TableCell width={'15%'} className={Style.TableCell}>
        <Typography className={Style.ContentHeadNameText}>
          {item.name}
        </Typography>
      </TableCell>
      <TableCell width={'40%'} className={Style.TableCell}>
        <Box className={Style.txtBox}>
          {item?.roleName.map((data: string, index: number) => {
            return (
              <>
                {data?.length && data ? (
                  <>
                    <Typography key={index} className={Style.ContentHead}>
                      {data}
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
              </>
            )
          })}
        </Box>
      </TableCell>
      <TableCell width={'20%'} className={Style.TableCell}>
        <Typography className={Style.ContentHeadSubTxt}>
          {item?.scope}
        </Typography>
      </TableCell>
      <TableCell width={'1%'} align='right' className={Style.TableCell}>
        <img
          src={dot}
          alt='IconChevronDown'
          aria-controls={ActionOpen ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={ActionOpen ? 'true' : undefined}
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            handleClick(
              e as unknown as React.MouseEvent<HTMLButtonElement, MouseEvent>,
            )
          }}
        />
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={ActionOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose()
              handleEditOpen()
              setPermissionId(item?.id)
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpen(true)
              setPermissionId(item?.id)
              handleClose()
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </TableCell>
      {Editopen && (
        <EditPermission
          permissionId={permissionId}
          Editopen={Editopen}
          setEditOpen={setEditOpen}
          handleEditOpen={handleEditOpen}
        />
      )}
      {open && (
        <DeletePopUp
          id={permissionId}
          handleSubmit={handleDelete}
          openDialog={open}
          setOpenDialog={setOpen}
        />
      )}
    </>
  )
}
