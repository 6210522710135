import { AnyARecord } from 'dns'
import { baseAPI } from '../Common/httpCommon'

const notificationList = async (token: string) => {
  try {
    return await baseAPI.get('notification/list', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const employeeList = async (token: string) => {
  try {
    return await baseAPI.get('notification/employee-list', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } catch (e: any) {
    return e
  }
}

const SendNotification = async (data: AnyARecord, token: string) => {
  try {
    return await baseAPI.post('notification/add', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const NotificationService = {
  notificationList,
  employeeList,
  SendNotification,
}
export default NotificationService
