import { baseAPI } from '../Common/httpCommon'

const AddCurrentSignificant = async (
  data: any,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.post('application/family/current', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const EditCurrentSignificant = async (
  data: any,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/family/current', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddPastSignificant = async (
  data: any,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.put('application/family/past', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddParents = async (data: any, token: string, isPublic: string) => {
  try {
    return await baseAPI.put('application/family/parents', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const AddDependents = async (data: any, token: string, isPublic: string) => {
  try {
    return await baseAPI.put('application/family/dependents', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const FamilyDetailsService = {
  AddCurrentSignificant,
  EditCurrentSignificant,
  AddPastSignificant,
  AddParents,
  AddDependents,
}
export default FamilyDetailsService
