import { baseAPI } from '../Common/httpCommon'

interface FinalSubmitType {
  applicationId: string
}

const FinalSubmit = async (
  data: FinalSubmitType,
  token: string,
  isPublic: string,
) => {
  try {
    return await baseAPI.post('/application/application/finale-step', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Is-public': isPublic,
        'Content-Type': 'application/json',
      },
    })
  } catch (e: any) {
    return e
  }
}

const FinalSubmitService = {
  FinalSubmit,
}
export default FinalSubmitService
