import React from 'react'
import { Paper, Grid, Typography, styled } from '@mui/material'
import styles from './index.module.scss' // Make sure to import your styles correctly
import { DynamicObj } from 'src/Utils/Constants'
import Tooltip from '@mui/material/Tooltip'
import error from 'src/Assets/Icons/error.svg'
interface AddressCardProps {
  streetAddress: string
  city: string
  state: string
  postalCode: string
  country: string
  flagData?: DynamicObj
}

const ErrorImage = styled('img')({
  marginRight: '8px', // Adjust margin as needed
  marginBottom: '-8px', // Adjust margin as needed
  paddingRight: '10px',
  paddingLeft: '10px',
  // position: 'absolute',
})

const AddressCard: React.FC<AddressCardProps> = ({
  streetAddress,
  city,
  state,
  postalCode,
  country,
  flagData,
}) => {
  return (
    <Paper
      sx={{
        p: 2,
        bgcolor: 'transparent',
        border: '1px solid #E1E4EB',
        boxShadow: 0,
      }}
    >
      <Grid container>
        <Grid item lg={4}>
          <Typography variant='h3' className={styles.grayFonts}>
            Street Address
          </Typography>
          <Typography
            variant='h3'
            className={
              flagData?.streetAddress?.length > 0
                ? styles.redFlagFonts
                : styles.MediumBlackFonts
            }
          >
            {streetAddress}
            {flagData?.streetAddress?.length > 0 ? (
              <>
                <Tooltip title={flagData?.streetAddress}>
                  <ErrorImage src={error} alt='Error' />
                </Tooltip>
              </>
            ) : null}
          </Typography>
        </Grid>
        <Grid item lg={4}></Grid>
        <Grid item lg={4}>
          <Typography variant='h3' className={styles.grayFonts}>
            City/Town
          </Typography>
          <Typography
            variant='h3'
            className={
              flagData?.city?.length > 0
                ? styles.redFlagFonts
                : styles.MediumBlackFonts
            }
          >
            {city}
            {flagData?.city?.length > 0 ? (
              <>
                <Tooltip title={flagData?.city}>
                  <ErrorImage src={error} alt='Error' />
                </Tooltip>
              </>
            ) : null}
          </Typography>
        </Grid>
        <Grid pt={2} container>
          <Grid item lg={4}>
            <Typography variant='h3' className={styles.grayFonts}>
              State
            </Typography>
            <Typography
              variant='h3'
              className={
                flagData?.state?.length > 0
                  ? styles.redFlagFonts
                  : styles.MediumBlackFonts
              }
            >
              {state}
              {flagData?.state?.length > 0 ? (
                <>
                  <Tooltip title={flagData?.state}>
                    <ErrorImage src={error} alt='Error' />
                  </Tooltip>
                </>
              ) : null}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography variant='h3' className={styles.grayFonts}>
              Zip Code
            </Typography>
            <Typography
              variant='h3'
              className={
                flagData?.postalCode?.length > 0
                  ? styles.redFlagFonts
                  : styles.MediumBlackFonts
              }
            >
              {postalCode}
              {flagData?.postalCode?.length > 0 ? (
                <>
                  <Tooltip title={flagData?.postalCode}>
                    <ErrorImage src={error} alt='Error' />
                  </Tooltip>
                </>
              ) : null}
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Typography variant='h3' className={styles.grayFonts}>
              Country
            </Typography>
            <Typography
              variant='h3'
              className={
                flagData?.country?.length > 0
                  ? styles.redFlagFonts
                  : styles.MediumBlackFonts
              }
            >
              {country}
              {flagData?.country?.length > 0 ? (
                <>
                  <Tooltip title={flagData?.country}>
                    <ErrorImage src={error} alt='Error' />
                  </Tooltip>
                </>
              ) : null}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AddressCard
