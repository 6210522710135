import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Breadcrumb from 'src/Components/BreadCrumb'
import BasicHeader from 'src/Components/Application/Header'
import CustomizedSteppers from 'src/Components/Application/Stepper'
import BasicForm from '../Applications/ApplicationBasic/Content'
import LegalDetails from './LegalDetails'
import DisabilityDetails from './DisabilityDetails'
import EducationDetails from './EducationDetails'
import FamilyDetails from './FamilyDetails'
import { TAB_DATA } from './allTabsData'
import styles from './ApplicationPage.module.scss'
import MedicalDetails from './MedicalDetails'
import DocumentDetails from './DocumentDetails'
import ConcentForm from './ConsentForm'
import FinalSubmit from './FinalSubmiit'
import { ROUTES } from 'src/Utils/Routes'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { getApplicationDetail } from 'src/Redux/Slices/ApplicationSlices/allApplicationSlice'
import {
  setBasicDetails,
  setBasicFlagData,
} from 'src/Redux/Slices/ApplicationStore/BasicDetailsStore'
import {
  DynamicObj,
  extractDetailedApplicationBasicData,
  extractDetailedApplicationDisabledData,
  extractDetailedApplicationEducationData,
  extractDetailedApplicationFamilyData,
  extractDetailedApplicationLegalData,
  extractDetailedApplicationMedicalData,
  extractFlagBasicData,
  extractFlagEducationData,
  extractFlagFamilyData,
  extractFlagMedicalData,
} from 'src/Utils/Constants'
import {
  setApplicationId,
  setBasicId,
  setFamilyId,
  setLegalId,
  setDisabilityId,
  setEducationAndEmployementId,
  setMedicalDetailId,
  setDisqualification,
  setSignConsentId,
  setUploadDocumentId,
} from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { setLegalDetails } from 'src/Redux/Slices/ApplicationStore/LegalDetailsStore'
import {
  BasicDetailsType,
  DisabilityDetailsType,
  EducationAndEmployementType,
  FamilyDetailsType,
  MedicalDetailsType,
} from 'src/Redux/Services/Types/applicationType'
import {
  setFamilyDetails,
  setFamilyFlagData,
} from 'src/Redux/Slices/ApplicationStore/FamilyDetailsStore'
import { setDisabilityDetails } from 'src/Redux/Slices/ApplicationStore/DisabilityDetailsStore'
import {
  setEducationDetails,
  setEducationFlagData,
} from 'src/Redux/Slices/ApplicationStore/EducationAndEmployement'
import {
  setMedicalDetails,
  setMedicalFlagData,
} from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import Disqualification from 'src/Components/Disqualification'
import { useVisibleSection } from './getSection'
import { setStepCompleted } from 'src/Redux/Slices/stepSlice'
// import { StepsState } from 'src/Redux/Services/Types/stepsType'
import ChatPopup from 'src/Components/ChatBot'
import { RootState } from 'src/Redux/store'

const scrollTop = 305
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
type TabContentProps = Record<
  string,
  {
    title: string
    stepper: React.ReactElement<
      unknown,
      string | React.JSXElementConstructor<unknown>
    >
    content: React.ReactElement<
      unknown,
      string | React.JSXElementConstructor<unknown>
    >
  }
>

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ marginLeft: '18px' }}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

const ApplicationPage = () => {
  const disqualification = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.disqualification,
  )
  // const hasTrueValue =
  //   disqualification &&
  //   Object.values(disqualification).some((value) => value === true)
  const isDisqualified = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.isDisqualified,
  )

  const flagdata = useAppSelector(
    (state: RootState) => state?.applicationStore?.BasicDetails?.flagdata,
  )
  const [openDialog, setOpenDialog] = useState(false)

  const EducationAndEmployement = useAppSelector(
    (state: RootState) => state?.applicationStore?.EducationAndEmployement,
  )
  const familyDetails = useAppSelector(
    (state: RootState) => state?.applicationStore?.FamilyDetails,
  )
  const handleOpen = () => {
    setOpenDialog(true)
  }
  useEffect(() => {
    const basicFlagData = extractFlagBasicData(flagdata)
    const educationFlagData: any = extractFlagEducationData(
      EducationAndEmployement,
      flagdata,
    )
    const medicalFlagData: any = extractFlagMedicalData(flagdata)
    const familyFlagData: any = extractFlagFamilyData(flagdata, familyDetails)
    if (basicFlagData) {
      dispatch(setBasicFlagData(basicFlagData))
    }
    if (educationFlagData) {
      dispatch(setEducationFlagData(educationFlagData))
    }
    if (medicalFlagData) {
      dispatch(setMedicalFlagData(medicalFlagData))
    }
    if (familyFlagData) {
      dispatch(setFamilyFlagData(familyFlagData))
    }
  }, [flagdata])

  useEffect(() => {
    setOpenDialog(isDisqualified)
  }, [disqualification])

  const gridRef = React.useRef<HTMLDivElement>(null)
  const applicationId = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.applicationId,
  )
  const allSteps = useAppSelector((state) => state.steps)
  const keyToRemove = 'activeTab'

  const [selectedTabName, setSelectedTabName] = React.useState(
    allSteps.activeTab,
  )

  const tabData = Object.values(TAB_DATA).map((label) => ({ label }))
  const index = tabData.findIndex((tab) => tab.label === allSteps.activeTab)

  const [tabId, setTabId] = React.useState(index ?? 0)
  const { [keyToRemove]: _, ...stepsWithoutActiveTab } = allSteps

  const sectionIds = Object.values(stepsWithoutActiveTab).flatMap(
    (stepArray: any) => stepArray.map((step: DynamicObj) => step.sectionId),
  )

  const visibleSection = useVisibleSection(sectionIds)

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId)
    if (element) {
      element.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
    }
  }

  useEffect(() => {
    setSelectedTabName(allSteps.activeTab)
    const tabData = Object.values(TAB_DATA).map((label) => ({ label }))
    const index = tabData.findIndex((tab) => tab.label === allSteps.activeTab)
    setTabId(index)
  }, [allSteps.activeTab])

  useEffect(() => {
    // Iterate through allSteps to find the object with matching sectionId
    for (const section in stepsWithoutActiveTab) {
      const steps = stepsWithoutActiveTab[section]
      const matchingStep = steps.find(
        (step: any) => step.sectionId === visibleSection,
      )
      if (matchingStep) {
        dispatch(
          setStepCompleted({
            section,
            label: matchingStep.label,
          }),
        )

        break
      }
    }
  }, [visibleSection])

  const TAB_CONTENT: TabContentProps = {
    [TAB_DATA.BASIC]: {
      title: 'Basic Details',
      stepper: (
        <CustomizedSteppers
          section={'BasicApplicationSteps'}
          scrollToSection={scrollToSection}
        />
      ),
      content: <BasicForm />,
    },
    [TAB_DATA.FAMILY]: {
      title: `${TAB_DATA.FAMILY} Details`,
      stepper: (
        <CustomizedSteppers
          section={'FamilyApplicationSteps'}
          scrollToSection={scrollToSection}
        />
      ),
      content: <FamilyDetails />,
    },
    [TAB_DATA.LEGAL_NAMES_DOCUMENTATION]: {
      title: `${TAB_DATA.LEGAL_NAMES_DOCUMENTATION} Details`,
      stepper: (
        <CustomizedSteppers
          section={'LegalDocApplicationSteps'}
          scrollToSection={scrollToSection}
        />
      ),
      content: <LegalDetails />,
    },
    [TAB_DATA.DISABLILLIY]: {
      title: `${TAB_DATA.DISABLILLIY} Details`,
      stepper: (
        <CustomizedSteppers
          section={'DisablilityApplicationSteps'}
          scrollToSection={scrollToSection}
        />
      ),
      content: <DisabilityDetails />,
    },
    [TAB_DATA.EDUCATION_EMPLOYMENT]: {
      title: `${TAB_DATA.EDUCATION_EMPLOYMENT} Details`,
      stepper: (
        <CustomizedSteppers
          section={'EducationEmploymentApplicationSteps'}
          scrollToSection={scrollToSection}
        />
      ),
      content: <EducationDetails />,
    },
    [TAB_DATA.MEDICAL]: {
      title: `${TAB_DATA.MEDICAL} Details`,
      stepper: (
        <CustomizedSteppers
          section={'MedicalApplicationSteps'}
          scrollToSection={scrollToSection}
        />
      ),
      content: <MedicalDetails />,
    },
    [TAB_DATA.UPLOADED_DOCUMENTATION]: {
      title: `${TAB_DATA.UPLOADED_DOCUMENTATION} Details`,
      stepper: (
        <CustomizedSteppers
          section={'UploadedApplicationSteps'}
          scrollToSection={scrollToSection}
        />
      ),
      content: <DocumentDetails />,
    },
    [TAB_DATA.SIGNED_CONSENT_FORMS]: {
      title: `${TAB_DATA.SIGNED_CONSENT_FORMS} Details`,
      stepper: (
        <CustomizedSteppers
          section={'SignConsentApplicationSteps'}
          scrollToSection={scrollToSection}
        />
      ),
      content: <ConcentForm />,
    },
    [TAB_DATA.SUBMIT]: {
      title: `${TAB_DATA.SUBMIT} Details`,
      stepper: (
        <CustomizedSteppers
          section={'SubmitApplicationSteps'}
          scrollToSection={scrollToSection}
        />
      ),
      content: <FinalSubmit />,
    },
  }

  const headerData = useAppSelector(
    (state) =>
      state.applicationStore?.AllApplications?.data?.find(
        (record: DynamicObj) => record.uuid === applicationId,
      ) || {},
  )

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getApplicationDetail(applicationId)).then((res) => {
      if (!allSteps.activeTab) {
        setSelectedTabName(TAB_DATA.BASIC)
      }
      setApplicationId(applicationId)

      // Check if response contains data
      if (res?.payload?.data) {
        const jsonData = res?.payload?.data
        const basicdata = jsonData?.basicdata[0]
        const familydata = jsonData?.familydata[0]
        const legalData = jsonData?.legalData[0]
        const disablitydata = jsonData?.disabilitydetails[0]
        const educationdata = jsonData?.educationdetails[0]
        const assistancedata = jsonData?.assistancedata[0]
        const assigneeData = jsonData?.assigneeData[0]
        const flagdata = jsonData?.flagdata[0]
        const medicaldetails = jsonData?.medicaldetails[0]
        const signconsentformsdata = jsonData?.signconsentformsdata[0].uuid
        const uploaddocumentdata = jsonData?.uploaddocumentdata[0].uuid

        dispatch(setDisqualification({}))

        if (signconsentformsdata) {
          dispatch(setSignConsentId(signconsentformsdata))
        } else {
          dispatch(setSignConsentId(''))
        }
        if (uploaddocumentdata) {
          dispatch(setUploadDocumentId(uploaddocumentdata))
        } else {
          dispatch(setUploadDocumentId(''))
        }
        const MedicalDetails = extractDetailedApplicationMedicalData(
          medicaldetails,
          applicationId,
        )
        const LegalDetails = extractDetailedApplicationLegalData(
          legalData,
          applicationId,
        )

        const basicDetails = extractDetailedApplicationBasicData(
          basicdata,
          assistancedata,
          assigneeData,
          flagdata,
        )

        const familyDetails = extractDetailedApplicationFamilyData(
          familydata,
          applicationId,
        )
        const disabilityDetails = extractDetailedApplicationDisabledData(
          disablitydata,
          applicationId,
        )

        const educationDetail =
          extractDetailedApplicationEducationData(educationdata)

        if (basicDetails) {
          dispatch(setBasicId(jsonData?.basicdata[0]?.basicId as string))
          dispatch(setBasicDetails(basicDetails as BasicDetailsType))
        }
        if (familyDetails) {
          dispatch(setFamilyId(jsonData?.familydata[0]?.familyId as string))

          dispatch(setFamilyDetails(familyDetails as FamilyDetailsType))
        }

        if (LegalDetails) {
          dispatch(setLegalId(jsonData?.legalData[0]?.legalId as string))

          dispatch(setLegalDetails(LegalDetails))
        }
        if (disabilityDetails) {
          dispatch(
            setDisabilityId(jsonData?.disabilitydetails[0]?.disabilityId),
          )
          dispatch(
            setDisabilityDetails(disabilityDetails as DisabilityDetailsType),
          )
        }
        if (educationDetail) {
          dispatch(
            setEducationAndEmployementId(
              jsonData?.educationdetails[0]?.educationId,
            ),
          )

          dispatch(
            setEducationDetails(educationDetail as EducationAndEmployementType),
          )
        }
        if (MedicalDetails) {
          dispatch(setMedicalDetailId(jsonData?.medicaldetails[0]?.medicalId))
          dispatch(setMedicalDetails(MedicalDetails as MedicalDetailsType))
        }
      }
    })
  }, [applicationId])
  const handleScroll = () => {
    if (gridRef.current) {
      if (gridRef?.current?.scrollTop > scrollTop) {
        gridRef?.current?.scrollTo(0, scrollTop)
        gridRef?.current?.scrollIntoView()
      }
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Find the step with active property set to true
      const element: HTMLElement | null = null
      Object.values(stepsWithoutActiveTab).forEach((steps: any) => {
        const active = steps?.find((step: any) => step.active)
        if (active) {
          // element = document.getElementById('MilitaryService')
          // const releventDiv = document.getElementById('MilitaryService')
          if (element) {
            // element?.scrollIntoView({ block: 'center', behavior: 'smooth' })
          }
          // const releventDiv = document.getElementById(`${foundStep.sectionId}`)
          // releventDiv?.scrollIntoView({ behavior: 'smooth' })
        }
      })
      clearTimeout(timeout)
    }, 200)
  }, [allSteps])

  return (
    <>
      {
        <div
          ref={gridRef}
          onScroll={handleScroll}
          className={styles.GridScroll}
        >
          {openDialog && (
            <Disqualification
              open={openDialog}
              setOpen={setOpenDialog}
              handleOpen={handleOpen}
            />
          )}
          <ChatPopup />

          <Grid m={3}>
            <Breadcrumb
              title='Application Details'
              home={{ route: ROUTES.APPLICATIONS, name: 'Applications' }}
              Menus={[
                {
                  route: ROUTES.APPLICATION_DETAIL,
                  name: 'Application Details',
                },
              ]}
            />
            <Grid container direction='column' justifyContent='space-between'>
              <Grid>
                <Grid item lg={12} className={styles.GridStyle} my={2}>
                  {headerData && (
                    <BasicHeader
                      setTabId={setTabId}
                      setSelectedTabName={setSelectedTabName}
                      headerData={headerData}
                      tabId={tabId}
                    />
                  )}
                </Grid>
                <Grid item lg={12} className={styles.GridStyle} my={2}>
                  <Box>
                    <Box className={styles.AppPageBox}>
                      <Paper elevation={0} className={styles.AppHeaderPaper}>
                        {Object.keys(TAB_CONTENT).map((tabKey) => {
                          const tab =
                            TAB_CONTENT[tabKey as keyof typeof TAB_DATA]
                          if (tabKey === selectedTabName) {
                            return (
                              <Typography
                                className={styles.TitleFont}
                                key={tabId}
                              >
                                {tab.title}
                              </Typography>
                            )
                          } else {
                            return null
                          }
                        })}
                      </Paper>
                      <Grid container>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          sm={3}
                          className={styles.verticalDevider}
                          p={5}
                        >
                          {Object.keys(TAB_CONTENT).map((tabKey) => {
                            const tab =
                              TAB_CONTENT[tabKey as keyof typeof TAB_DATA]
                            if (tabKey === selectedTabName) {
                              return <>{tab.stepper}</>
                            } else {
                              return null
                            }
                          })}
                        </Grid>

                        <Grid
                          item
                          lg={9}
                          md={9}
                          sm={9}
                          mb={3}
                          className={styles.ContentGridStyle}
                          onScroll={(e) => {
                            // const { scrollTop, scrollHeight, clientHeight } =
                            //   e.target as HTMLInputElement
                            // const position = Math.ceil(
                            //   (scrollTop / (scrollHeight - clientHeight)) * 100,
                            // )
                          }}
                        >
                          <CustomTabPanel
                            key={tabId}
                            value={tabId}
                            index={tabId}
                          >
                            {Object.keys(TAB_CONTENT).map((tabKey) => {
                              const tab =
                                TAB_CONTENT[tabKey as keyof typeof TAB_DATA]
                              if (tabKey === selectedTabName) {
                                return <>{tab.content}</>
                              } else {
                                return null
                              }
                            })}
                          </CustomTabPanel>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      }
    </>
  )
}

export default ApplicationPage
