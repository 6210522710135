import {
  Button,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import star from 'src/Assets/Icons/star.svg'
import { ContactInformationType } from 'src/Redux/Services/Types/applicationType'
import { EditContactInformation } from 'src/Redux/Slices/ApplicationSlices/BasicDetailsSlice'
import { setContactInformation } from 'src/Redux/Slices/ApplicationStore/BasicDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import CurrentAddress from './Current'
import MailingAddress from './Mailing'
import PhysicalAddress from './Physical'
import Style from './index.module.scss'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'

const ContactInformation = ({
  handleEditToggle,
}: {
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const assistanceId = location?.state?.data ?? ''

  const ContactInformationState = useAppSelector(
    (state) => state?.applicationStore?.BasicDetails?.ContactInformation,
  )
  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.basicId,
  )
  const [formValues, setFormValues] = useState<ContactInformationType>({
    assistanceId: assistanceId ?? '',
    applicant: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    isUSAPhoneNumber: undefined,
    phone: '',
    physicalAddress: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    isLiveAtPhysicalAddress: undefined,
    currentAddress: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    wantToReceiveMailAtPhysicalAddress: undefined,
    mailingAddress: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
  })

  useEffect(() => {
    if (assistanceId) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        assistanceId,
        applicant: 'Assistance',
      }))
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        applicant: 'Self',
      }))
    }
  }, [assistanceId])

  useEffect(() => {
    setFormValues({
      ...ContactInformationState,
      assistanceId: assistanceId ?? '',
      applicant: assistanceId ? 'Assistance' : 'Self',
    })
  }, [assistanceId])

  useEffect(() => {
    if (formValues?.wantToReceiveMailAtPhysicalAddress === true) {
      setFormValues((prevValues) => ({
        ...prevValues,
        mailingAddress: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
      }))
    }
  }, [!formValues?.wantToReceiveMailAtPhysicalAddress])

  useEffect(() => {
    if (formValues?.isLiveAtPhysicalAddress === true) {
      setFormValues((prevValues) => ({
        ...prevValues,
        currentAddress: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
      }))
    }
  }, [!formValues?.isLiveAtPhysicalAddress])

  // useEffect(() => {
  //   if (
  //     formValues?.isUSAPhoneNumber === false ||
  //     formValues?.isUSAPhoneNumber === undefined
  //   ) {
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       phone: '',
  //     }))
  //   }
  // }, [!formValues?.isUSAPhoneNumber])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleAddressChange = (
    address: 'physicalAddress' | 'currentAddress' | 'mailingAddress',
    event: {
      name: string
      value: string
    },
  ) => {
    const { name, value } = event
    let updatedFormValues: ContactInformationType = { ...formValues }
    updatedFormValues = {
      ...updatedFormValues,
      [address]: {
        ...updatedFormValues[address],
        [name]: value,
      },
    }
    setFormValues(updatedFormValues)
  }

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    let updatedFormValues: ContactInformationType = { ...formValues }

    // If it's a nested field like physicalAddress or currentAddress
    if (name.includes('.')) {
      const [parent, child] = name.split('.')
      updatedFormValues = {
        ...updatedFormValues,
        [parent]: {
          ...updatedFormValues[parent],
          [child]: value,
        },
      }
    } else {
      updatedFormValues = {
        ...updatedFormValues,
        [name]: value,
      }
    }
    setFormValues(updatedFormValues)
    const inputName = e.target.name
    const inputValue = e.target.value

    if (inputName === 'phone' && !/^\d*$/.test(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: '',
      }))
    }
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    // Validation logic for Full Name
    if (!formValues.firstName?.trim()) {
      newErrors.firstName = 'First Name is required'
      valid = false
    } else if (!/^[A-Za-z ]+$/.test(formValues.firstName?.trim())) {
      newErrors.firstName = 'Only characters are allowed'
      valid = false
    }

    if (!formValues.middleName?.trim()) {
      newErrors.middleName = 'Middle Name is required'
      valid = false
    } else if (!/^[A-Za-z ]+$/.test(formValues.middleName?.trim())) {
      newErrors.middleName = 'Only characters are allowed'
      valid = false
    }

    if (!formValues.lastName?.trim()) {
      newErrors.lastName = 'Last Name is required'
      valid = false
    } else if (!/^[A-Za-z ]+$/.test(formValues.lastName?.trim())) {
      newErrors.lastName = 'Only characters are allowed'
      valid = false
    }

    if (typeof !formValues.isUSAPhoneNumber === 'undefined') {
      newErrors.isUSAPhoneNumber = 'Please Select Option'
      valid = false
    }
    if (typeof !formValues.isLiveAtPhysicalAddress === 'undefined') {
      newErrors.isLiveAtPhysicalAddress = 'Please Select Option'
      valid = false
    }
    if (typeof !formValues.wantToReceiveMailAtPhysicalAddress === 'undefined') {
      newErrors.wantToReceiveMailAtPhysicalAddress = 'Please Select Option'
      valid = false
    }
    // Validation logic for Phone Number
    if (!formValues.phone?.trim()) {
      newErrors.phone = 'Phone Number is required'
      valid = false
    } else if (!/^\d{10}$/.test(formValues.phone?.trim())) {
      newErrors.phone = 'Phone Number must be 10 digits'
      valid = false
    }

    if (!formValues.email?.trim()) {
      newErrors.email = 'Email Name is required'
      valid = false
    } else if (
      !/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(formValues.email?.trim())
    ) {
      newErrors.email = 'Please enter a valid email address'
      valid = false
    }

    if (!formValues.physicalAddress?.streetAddress?.trim()) {
      newErrors.address = 'Street Address is required'
      valid = false
    }
    if (!formValues.physicalAddress?.city?.trim()) {
      newErrors.city = 'City/Town is required'
      valid = false
    }

    if (!formValues.physicalAddress?.state?.trim()) {
      newErrors.state = 'State is required'
      valid = false
    }
    if (!formValues.physicalAddress?.postalCode?.trim()) {
      newErrors.postalCode = 'zip Code is required'
      valid = false
    }
    if (!formValues.physicalAddress?.country?.trim()) {
      newErrors.country = 'Country is required'
      valid = false
    }

    if (!formValues.isLiveAtPhysicalAddress) {
      if (!formValues.currentAddress?.streetAddress?.trim()) {
        newErrors.address = 'Street Address is required'
        valid = false
      }
      if (!formValues.currentAddress?.city?.trim()) {
        newErrors.city = 'City/Town is required'
        valid = false
      }
      if (!formValues.currentAddress?.state?.trim()) {
        newErrors.state = 'State is required'
        valid = false
      }
      if (!formValues.currentAddress?.postalCode?.trim()) {
        newErrors.postalCode = 'zip Code is required'
        valid = false
      }
      if (!formValues.currentAddress?.country?.trim()) {
        newErrors.country = 'Country is required'
        valid = false
      }
    }

    if (!formValues.wantToReceiveMailAtPhysicalAddress) {
      if (!formValues.mailingAddress?.streetAddress?.trim()) {
        newErrors.address = 'Street Address is required'
        valid = false
      }
      if (!formValues.mailingAddress?.city?.trim()) {
        newErrors.city = 'City/Town is required'
        valid = false
      }
      if (!formValues.mailingAddress?.state?.trim()) {
        newErrors.state = 'State is required'
        valid = false
      }
      if (!formValues.mailingAddress?.postalCode?.trim()) {
        newErrors.postalCode = 'zip Code is required'
        valid = false
      }
      if (!formValues.mailingAddress?.country?.trim()) {
        newErrors.country = 'Country is required'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      // debugger

      dispatch(
        EditContactInformation({ ...formValues, basicDetailId: detailId }),
      ).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setContactInformation(formValues))

            handleEditToggle()
          }
        },
      )
    }
  }
  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Typography className={Style.label}>
            Let's start by providing your basic contact details.
          </Typography>
          <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
            <Grid width={'100%'}>
              <InputLabel className={Style.label}>
                First Name <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                fullWidth
                type='text'
                id='firstName'
                name='firstName'
                className={Style.AnsTxt}
                placeholder='First Name'
                value={formValues?.firstName}
                onChange={handleFieldChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
                inputProps={{ 'data-field-type': 'alphabets' }}
              />
            </Grid>
            <Grid width={'100%'}>
              <InputLabel className={Style.label}>
                Middel Name <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                fullWidth
                type='text'
                id='middleName'
                name='middleName'
                className={Style.AnsTxt}
                placeholder='Middle Name'
                value={formValues?.middleName}
                onChange={handleFieldChange}
                error={!!errors.middleName}
                helperText={errors.middleName}
                inputProps={{ 'data-field-type': 'alphabets' }}
              />
            </Grid>
            <Grid width={'100%'}>
              <InputLabel className={Style.label}>
                Last Name <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <TextField
                fullWidth
                id='lastName'
                type='text'
                name='lastName'
                className={Style.AnsTxt}
                placeholder='Last Name'
                value={formValues?.lastName}
                onChange={handleFieldChange}
                error={!!errors.lastName}
                helperText={errors.lastName}
                inputProps={{ 'data-field-type': 'alphabets' }}
              />
            </Grid>
          </Grid>

          <Box width={'100%'} mt={3}>
            <InputLabel className={Style.label}>
              Email<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              type='email'
              id='email'
              name='email'
              className={Style.AnsTxt}
              placeholder='Email'
              value={formValues?.email}
              onChange={handleFieldChange}
              error={!!errors.email}
              helperText={errors.email}
              inputProps={{ 'data-field-type': 'email' }}
            />
          </Box>
          <Box width={'100%'} height={'20px'} />

          {/* Common phone number */}
          <CommonRadioBox
            Style={Style}
            // fieldName={'isUSAPhoneNumber'}
            title='Do you have the U.S. Phone Number?'
            // commonState={usPhone}
            // setcommonState={setUsPhone}
            commonState={formValues.isUSAPhoneNumber}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isUSAPhoneNumber: value,
              })
            }}
            // isSubmitted={isSubmitted}
            // errors={errors}
            // setErrors={setErrors}
            YesTitle='I have the U.S. Phone Number'
            NoTitle='I don’t have the U.S. Phone Number'
          />
          <FormHelperText>{errors.isUSAPhoneNumber}</FormHelperText>

          <Box width={'100%'} mt={3}>
            <InputLabel className={Style.label}>
              Phone Number<span style={{ color: 'red' }}>*</span>
            </InputLabel>
            <TextField
              fullWidth
              id='phone'
              // type='number'
              name='phone'
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position='start'>(+1)</InputAdornment>
              //   ),
              // }}
              inputProps={{ maxLength: 10, 'data-field-type': 'number' }}
              className={Style.AnsTxt}
              placeholder='Phone Number'
              value={formValues?.phone}
              onChange={(e) => {
                const regex = /^\d*$/
                const str = e.target.value
                if (regex.test(str)) {
                  handleFieldChange(e)
                }
              }}
              error={!!errors.phone}
              helperText={errors.phone}
            />
          </Box>

          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              p: 2,
              my: 4,
              backgroundColor: '#ECF6FF',
              borderRadius: '10px',
            }}
          >
            <img src={star} className={Style.star} alt='' />
            <Typography>
              <i>
                Make sure to provide the best phone number to reach you between
                9:00 AM and 5 PM in the event that BenefitsBoost or the SSA
                representative reviewing your case has questions
              </i>
            </Typography>
          </Box>

          <PhysicalAddress
            errors={errors}
            handleFieldChange={(data) => {
              handleAddressChange('physicalAddress', data)
            }}
            formValues={formValues}
            setFormValues={setFormValues}
          />
          {/* Common radio */}
          <CommonRadioBox
            Style={Style}
            // fieldName={'isLiveAtPhysicalAddress'}
            title='Just to confirm: do you live at above given address?'
            // commonState={physicalAdd}
            // setcommonState={setphysicalAdd}
            commonState={formValues.isLiveAtPhysicalAddress}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isLiveAtPhysicalAddress: value,
              })
            }}
            // errors={errors}
            // setErrors={setErrors}
            // isSubmitted={isSubmitted}
            YesTitle='I live at above address.'
            NoTitle='I do not live at above address.'
          />

          <FormHelperText>{errors.isLiveAtPhysicalAddress}</FormHelperText>

          {/* currently reside address */}
          {!formValues?.isLiveAtPhysicalAddress ? (
            <>
              <CurrentAddress
                errors={errors}
                handleFieldChange={(data: { name: string; value: string }) => {
                  handleAddressChange('currentAddress', data)
                }}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </>
          ) : (
            <></>
          )}

          {/* Common mail */}
          <CommonRadioBox
            Style={Style}
            // fieldName={'wantToReceiveMailAtPhysicalAddress'}
            title='Would you like to receive mail related to this application at this address?'
            // commonState={mailAdd}
            // setcommonState={setMailAdd}
            commonState={formValues.wantToReceiveMailAtPhysicalAddress}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                wantToReceiveMailAtPhysicalAddress: value,
              })
            }}
            // isSubmitted={isSubmitted}
            // errors={errors}
            // setErrors={setErrors}
            YesTitle=' I would like to receive mail related to this application at this address'
            NoTitle=' I don’t like to receive mail related to this application at this address'
          />

          <FormHelperText>
            {errors.wantToReceiveMailAtPhysicalAddress}
          </FormHelperText>

          {/* mailing address */}
          {!formValues?.wantToReceiveMailAtPhysicalAddress ? (
            <>
              <MailingAddress
                errors={errors}
                handleFieldChange={(data: { name: string; value: string }) => {
                  handleAddressChange('mailingAddress', data)
                }}
                formValues={formValues}
                setFormValues={setFormValues}
              />
            </>
          ) : (
            <></>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                handleEditToggle()
              }}
              // sx={{ visibility: 'hidden' }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
              // onClick={handleNext}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default ContactInformation
