import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, type FC } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { ViewApplicationReviewFilter } from 'src/Redux/Slices/DashboardSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from './index.module.scss'
interface dataType {
  name: string
  value: number
  color: string
}
const ApplicationAnalysis: FC = () => {
  const dispatch = useAppDispatch()
  const [activeButton, setActiveButton] = React.useState('month')
  useEffect(() => {
    dispatch(ViewApplicationReviewFilter(activeButton))
  }, [activeButton])

  const applicationReviewFilter = useAppSelector(
    (state) => state?.dashboard?.data?.applicationReviewFilter,
  )

  const data: dataType[] = [
    {
      name: 'Submitted Applications:',
      value: applicationReviewFilter?.Submitted ?? 0,
      color: '#17C653',
    },
    {
      name: 'In Review Applications:',
      value: applicationReviewFilter?.InReview ?? 0,
      color: '#DBDFE9',
    },
    {
      name: 'Alerts per applications:',
      value: applicationReviewFilter?.Flagged ?? 0,
      color: '#F8285A',
    },
    {
      name: 'In progress Applications:',
      value: applicationReviewFilter?.InProgress ?? 0,
      color: '#1B84FF',
    },
    {
      name: 'Assigned Applications:',
      value: applicationReviewFilter?.Assigned ?? 0,
      color: '#F6C000',
    },
    {
      name: 'Total Applications:',
      value: applicationReviewFilter?.Total ?? 0,
      color: '#7239EA',
    },
  ]

  const handleButtonClick = (value: string) => {
    setActiveButton(value)
  }
  return (
    <Box className={Style.ApplicationBox}>
      <Typography component={'span'} className={Style.ApplicationBoxTitle}>
        Application Analysis
        <Grid item container xs={3} className={Style.ApplicationButton}>
          <Button
            onClick={() => {
              handleButtonClick('month')
            }}
            sx={{
              m: 0,
              height: '32px',
              borderRadius: 1.5,
              backgroundColor:
                activeButton === 'month' ? 'grey.300' : 'transparent',
            }}
          >
            Month
          </Button>
          <Button
            onClick={() => {
              handleButtonClick('week')
            }}
            sx={{
              m: 0,
              height: '32px',
              borderRadius: 1.5,
              backgroundColor:
                activeButton === 'week' ? 'grey.300' : 'transparent',
            }}
          >
            Week
          </Button>
        </Grid>
      </Typography>
      <ResponsiveContainer width={'100%'}>
        <Box className={Style.ApplicationChartBox}>
          <Grid item container xs={6}>
            {applicationReviewFilter ? (
              <>
                <PieChart width={310} height={310}>
                  <Tooltip />
                  <Pie
                    rotate={-90}
                    startAngle={90}
                    endAngle={537}
                    blendStroke
                    data={data}
                    cx={150}
                    cy={150}
                    innerRadius={110}
                    outerRadius={150}
                    fill='#8884d8'
                    // paddingAngle={-6}
                    dataKey='value'
                  >
                    {data.map((entry: dataType, index: number) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Pie
                    cornerRadius={'50%'}
                    rotate={-90}
                    startAngle={90}
                    endAngle={540}
                    blendStroke
                    data={data}
                    cx={150}
                    cy={150}
                    innerRadius={110}
                    outerRadius={150}
                    fill='#8884d8'
                    paddingAngle={-6}
                    dataKey='value'
                  >
                    {data.map((entry: dataType, index: number) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  No data available
                </Box>
              </>
            )}
          </Grid>
          <Grid item container sm={6} gap={'20px'}>
            {data
              .slice()
              .reverse()
              .map((items: dataType, index: number) => (
                <Grid
                  item
                  container
                  key={index}
                  className={Style.ApplicationChartLegend}
                >
                  <Typography variant='h4' className={Style.LegendTitle}>
                    <span
                      className={Style.LegendSpan}
                      style={{ backgroundColor: items.color }}
                    />
                    {items.name}
                  </Typography>
                  <Typography variant='h4' className={Style.LegendTitle}>
                    {items.value}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </Box>
      </ResponsiveContainer>
    </Box>
  )
}

export default ApplicationAnalysis
