import {
  Button,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Style from './index.module.scss'
import { useEffect, useState } from 'react'
import quote from 'src/Assets/Icons/quots.svg'
import star from 'src/Assets/Icons/star.svg'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { AddParents } from 'src/Redux/Slices/ApplicationSlices/FamilyDetailsSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ParentType } from 'src/Redux/Services/Types/applicationType'
import { setParent } from 'src/Redux/Slices/ApplicationStore/FamilyDetailsStore'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import Address from './Address'

const Perants = ({ handleEditToggle }: { handleEditToggle: () => void }) => {
  const dispatch = useAppDispatch()

  const storeValues = useAppSelector(
    (state) => state?.applicationStore?.FamilyDetails?.Parent,
  )

  const familyId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.familyId,
  )
  const [formValues, setFormValues] = useState<ParentType>({
    familyId,
    isParentReceiveOneHalfSupport: undefined,
    firstParent: {
      suffix: '',
      firstName: '',
      lastName: '',
      middleName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
    isSecondParentReceiveOneHalfSupport: undefined,
    secondParent: {
      suffix: '',
      firstName: '',
      lastName: '',
      middleName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
  })

  useEffect(() => {
    setFormValues({ ...storeValues, familyId })
  }, [])

  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFirstParentChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setErrors({})

    const { name, value } = event.target
    if (value.length < 50 && value.match(/^[a-zA-Z. ]*$/)) {
      setFormValues({
        ...formValues,
        firstParent: { ...formValues.firstParent, [name]: value },
      })
    }
  }
  const handleFirstAddressChange = (event: { name: string; value: string }) => {
    setErrors({})
    const { name, value } = event
    setFormValues({
      ...formValues,
      firstParent: {
        ...formValues.firstParent,
        address: { ...formValues.firstParent?.address, [name]: value },
      },
    })
  }
  const handleSecondParentChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setErrors({})
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      secondParent: { ...formValues.secondParent, [name]: value },
    })
  }
  const handleSecondAddressChange = (event: {
    name: string
    value: string
  }) => {
    setErrors({})
    const { name, value } = event
    setFormValues({
      ...formValues,
      secondParent: {
        ...formValues.secondParent,
        address: { ...formValues.secondParent?.address, [name]: value },
      },
    })
  }

  const StyledSelect = styled(Select)({
    borderRadius: '10px', // Set the border radius to your desired value
    // Add any other custom styles here
  })

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    if (formValues?.isParentReceiveOneHalfSupport === undefined) {
      newErrors.isParentReceiveOneHalfSupport = 'Please select an option'
      valid = false
    }
    if (formValues?.isSecondParentReceiveOneHalfSupport === undefined) {
      newErrors.isSecondParentReceiveOneHalfSupport = 'Please select an option'
      valid = false
    }
    if (formValues?.isParentReceiveOneHalfSupport) {
      if (!formValues?.firstParent?.suffix) {
        newErrors.suffix = 'Suffix is required'
        valid = false
      }
      if (!formValues?.firstParent?.firstName) {
        newErrors.firstName = 'First Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.firstParent?.firstName)) {
        newErrors.firstName = 'Only characters are allowed'
        valid = false
      }

      if (!formValues?.firstParent?.lastName) {
        newErrors.lastName = 'Last Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.firstParent?.lastName)) {
        newErrors.lastName = 'Only characters are allowed'
        valid = false
      }

      if (!formValues?.firstParent?.middleName) {
        newErrors.middleName = 'Middle Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.firstParent?.middleName)) {
        newErrors.middleName = 'Only characters are allowed'
        valid = false
      }

      if (!formValues?.firstParent?.address?.streetAddress) {
        newErrors.streetAddress = 'Street Address is required'
        valid = false
      }
      if (!formValues?.firstParent?.address?.city) {
        newErrors.city = 'City is required'
        valid = false
      }
      if (!formValues?.firstParent?.address?.state) {
        newErrors.state = 'State is required'
        valid = false
      }
      if (!formValues?.firstParent?.address?.postalCode) {
        newErrors.postalCode = 'Zip Code is required'
        valid = false
      }
      if (!formValues?.firstParent?.address?.country) {
        newErrors.country = 'Country is required'
        valid = false
      }
    }

    if (formValues?.isSecondParentReceiveOneHalfSupport) {
      if (!formValues?.secondParent?.suffix) {
        newErrors.suffix2 = 'Suffix is required'
        valid = false
      }
      if (!formValues?.secondParent?.firstName) {
        newErrors.firstName2 = 'First Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.secondParent?.firstName)) {
        newErrors.firstName2 = 'Only characters are allowed'
        valid = false
      }

      if (!formValues?.secondParent?.lastName) {
        newErrors.lastName2 = 'Last Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.secondParent?.lastName)) {
        newErrors.lastName2 = 'Only characters are allowed'
        valid = false
      }

      if (!formValues?.secondParent?.middleName) {
        newErrors.middleName2 = 'Middle Name is required'
        valid = false
      } else if (!/^[A-Za-z ]+$/.test(formValues?.secondParent?.middleName)) {
        newErrors.middleName2 = 'Only characters are allowed'
        valid = false
      }

      if (!formValues?.secondParent?.address?.streetAddress) {
        newErrors.streetAddress2 = 'Street Address is required'
        valid = false
      }
      if (!formValues?.secondParent?.address?.city) {
        newErrors.city2 = 'City is required'
        valid = false
      }
      if (!formValues?.secondParent?.address?.state) {
        newErrors.state2 = 'State is required'
        valid = false
      }
      if (!formValues?.secondParent?.address?.postalCode) {
        newErrors.postalCode2 = 'Zip Code is required'
        valid = false
      }
      if (!formValues?.secondParent?.address?.country) {
        newErrors.country2 = 'Country is required'
        valid = false
      }
    }
    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      // alert('Form submitted successfully')
      dispatch(AddParents(formValues)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setParent(formValues))
            handleEditToggle()
          }
        },
      )
    }
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              py: 2,
              my: 3,
              borderRadius: '10px',
            }}
          >
            <img src={quote} className={Style.star} alt='' />
            <Typography className={Style.Heading}>
              Page title goes here
            </Typography>
          </Box>

          {/* radio button box */}
          <CommonRadioBox
            title='Do you have a parent who receives one-half support from you?'
            commonState={formValues?.isParentReceiveOneHalfSupport}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isParentReceiveOneHalfSupport: value,
              })
            }}
            YesTitle='I have a parent who receives one-half support from me'
            NoTitle='I do not have a parent who receives one-half support from me'
            Style={Style}
          />
          <FormHelperText>
            {errors?.isParentReceiveOneHalfSupport}
          </FormHelperText>
          {formValues?.isParentReceiveOneHalfSupport && (
            <>
              <Box
                width={'100%'}
                display={'flex'}
                alignItems={'flex-start'}
                sx={{
                  p: 2,
                  my: 4,
                  backgroundColor: '#ECF6FF',
                  borderRadius: '10px',
                }}
              >
                <img src={star} className={Style.star} alt='' />
                <Typography>
                  <i>
                    Thanks for sharing. Let's gather some details about this
                    parent. If two parents are supported, please provide
                    information about one of them. You'll have the opportunity
                    to add the other in a moment.
                  </i>
                </Typography>
              </Box>

              <Box>
                <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Suffix<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <StyledSelect
                      className={Style.AnsTxt}
                      IconComponent={(props) => (
                        <IconButton {...props}>
                          <img src={IconChevronDown} alt='IconChevronDown' />
                        </IconButton>
                      )}
                      displayEmpty
                      renderValue={
                        formValues?.firstParent?.suffix !== ''
                          ? () => formValues?.firstParent?.suffix
                          : () => 'Select'
                      }
                      fullWidth
                      id='suffix'
                      name='suffix'
                      value={formValues?.firstParent?.suffix}
                      onChange={handleFirstParentChange}
                      error={!!errors.suffix}
                    >
                      <MenuItem value=''>
                        <em>Select an Answer</em>
                      </MenuItem>
                      <MenuItem value='Dr.'>Dr.</MenuItem>
                      <MenuItem value='Ms.'>Ms.</MenuItem>
                      <MenuItem value='Mrs.'>Mrs.</MenuItem>
                      <MenuItem value='Mr.'>Mr.</MenuItem>
                    </StyledSelect>
                    <FormHelperText>{errors?.suffix}</FormHelperText>
                  </Grid>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      First Name<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='firstName'
                      name='firstName'
                      className={Style.AnsTxt}
                      placeholder='First Name'
                      value={formValues?.firstParent?.firstName}
                      onChange={handleFirstParentChange}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                    />
                  </Grid>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label} htmlFor='BillingName'>
                      Middle Name<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='middleName'
                      name='middleName'
                      className={Style.AnsTxt}
                      placeholder='Middle Name'
                      value={formValues?.firstParent?.middleName}
                      onChange={handleFirstParentChange}
                      error={!!errors.middleName}
                      helperText={errors.middleName}
                    />
                  </Grid>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label} htmlFor='BillingName'>
                      Last Name<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='lastName'
                      name='lastName'
                      className={Style.AnsTxt}
                      placeholder='Last Name'
                      value={formValues?.firstParent?.lastName}
                      onChange={handleFirstParentChange}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                    />
                  </Grid>
                </Grid>

                <Address
                  errors={errors}
                  realValues={formValues}
                  formValues={formValues?.firstParent}
                  handleFieldChange={(data) => {
                    handleFirstAddressChange(data)
                  }}
                  whichParent='firstParent'
                  setFormValues={setFormValues}
                />
              </Box>
            </>
          )}
          {/* radio button box */}
          <CommonRadioBox
            title='Do you have second parent who receives one-half support from you?'
            commonState={formValues?.isSecondParentReceiveOneHalfSupport}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isSecondParentReceiveOneHalfSupport: value,
              })
            }}
            YesTitle='I have second parent who receives one-half support from me'
            NoTitle='I do not have second parent who receives one-half support from me'
            Style={Style}
          />
          <FormHelperText>
            {errors?.isSecondParentReceiveOneHalfSupport}
          </FormHelperText>

          {formValues?.isSecondParentReceiveOneHalfSupport && (
            <>
              <Box my={'60px'}>
                <Typography className={Style.label}>
                  We understand. Please share some details about this parent.
                </Typography>
                <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Suffix<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <StyledSelect
                      className={Style.AnsTxt}
                      IconComponent={(props) => (
                        <IconButton {...props}>
                          <img src={IconChevronDown} alt='IconChevronDown' />
                        </IconButton>
                      )}
                      displayEmpty
                      renderValue={
                        formValues?.secondParent?.suffix !== ''
                          ? () => formValues?.secondParent?.suffix
                          : () => 'Select'
                      }
                      fullWidth
                      id='suffix'
                      name='suffix'
                      value={formValues?.secondParent?.suffix}
                      onChange={handleSecondParentChange}
                      error={!!errors?.suffix2}
                    >
                      <MenuItem value=''>
                        <em>Select an Answer</em>
                      </MenuItem>
                      <MenuItem value='Dr.'>Dr.</MenuItem>
                      <MenuItem value='Ms.'>Ms.</MenuItem>
                      <MenuItem value='Mrs.'>Mrs.</MenuItem>
                      <MenuItem value='Mr.'>Mr.</MenuItem>
                    </StyledSelect>
                    <FormHelperText>{errors?.suffix2}</FormHelperText>
                  </Grid>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      First Name<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='firstName'
                      name='firstName'
                      className={Style.AnsTxt}
                      placeholder='FirstName'
                      value={formValues?.secondParent?.firstName}
                      onChange={handleSecondParentChange}
                      error={!!errors.firstName2}
                      helperText={errors.firstName2}
                    />
                  </Grid>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label} htmlFor='BillingName'>
                      Middle Name<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='middleName'
                      name='middleName'
                      className={Style.AnsTxt}
                      placeholder='middleName'
                      value={formValues?.secondParent?.middleName}
                      onChange={handleSecondParentChange}
                      error={!!errors.middleName2}
                      helperText={errors.middleName2}
                    />
                  </Grid>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label} htmlFor='BillingName'>
                      Last Name<span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='lastName'
                      name='lastName'
                      className={Style.AnsTxt}
                      placeholder='lastName'
                      value={formValues?.secondParent?.lastName}
                      onChange={handleSecondParentChange}
                      error={!!errors.lastName2}
                      helperText={errors.lastName2}
                    />
                  </Grid>
                </Grid>

                <Address
                  errors={errors}
                  realValues={formValues}
                  formValues={formValues?.secondParent}
                  handleFieldChange={(data) => {
                    handleSecondAddressChange(data)
                  }}
                  whichParent='secondParent'
                  setFormValues={setFormValues}
                />
              </Box>
            </>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                // setIsSubmitted(false)
                handleEditToggle()
              }}
              // sx={{ visibility: 'hidden' }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
              // onClick={handleNext}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default Perants
