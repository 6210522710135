/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage'
import { getAccessToken } from 'src/Utils/Constants'

const baseURL = process.env.REACT_APP_API_URL
const chatURL = process.env.REACT_APP_CHATBOT_URL
const botURL = process.env.REACT_APP_API_URL
const token = (secureLocalStorage.getItem('token') as string) ?? ''
// all api will be called here

const mapAPI = axios.create({
  baseURL: 'https://restcountries.com/v3.1/',
  headers: {
    'Content-type': 'application/json',
  },
})

const baseAPI = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
  },
})

const chatAPI = axios.create({
  baseURL: chatURL,
  headers: {
    'Content-type': 'application/json',
  },
})

const botAPI = axios.create({
  baseURL: botURL,
  headers: {
    'Content-type': 'application/json',
  },
})

const baseSecureWithFormAPI = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`,
  },
})
const paymentAPI = axios.create({
  baseURL: 'http://localhost:7000/api/',
  headers: {
    'Content-type': 'application/json',
  },
})

const baseSecureAPI = axios.create({
  baseURL,
  timeout: 60000,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

baseSecureAPI.interceptors.request.use(
  (config: any) => {
    if (config.url !== '[url]' && !config.headers.Authorization) {
      const token = getAccessToken()
      config.headers.Authorization = `Bearer ${token}`
    }
    // Do something before request is sent
    return config
  },
  async function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)
export {
  mapAPI,
  baseAPI,
  chatAPI,
  botAPI,
  paymentAPI,
  baseSecureAPI,
  baseSecureWithFormAPI,
}
