// CustomStepper.js
import { Box, Button, Grid, Typography } from '@mui/material'
import React, { RefObject } from 'react'
import LeftStep from 'src/Assets/Icons/LeftStep.svg'
import RightClick from 'src/Assets/Icons/RightClick.svg'
import RightStep from 'src/Assets/Icons/RightStep.svg'
import Style from './index.module.scss'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import {
  NotificationType,
  showNotification,
} from 'src/Redux/Slices/notificationSlice'
import { useLocation } from 'react-router-dom'
import { ROUTES } from 'src/Utils/Routes'

interface FormComponent {
  title: string
  content: React.ComponentType<{
    handleNext: () => void
    handleBack: () => void
  }>
}

interface CustomStepperProps {
  myRef: RefObject<HTMLDivElement>
  formComponents: FormComponent[]
  activeStep: number
  handleNext: () => void
  handleBack: () => void
}
const CustomStepper: React.FC<CustomStepperProps> = ({
  myRef,
  formComponents,
  activeStep,
  handleNext,
  handleBack,
}) => {
  const totalSteps = formComponents?.length
  const dispatch = useAppDispatch()

  const basicId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.basicId,
  )
  const familyId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.familyId,
  )
  const legalId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.legalId,
  )
  const disabilityId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.disabilityId,
  )
  const educationId = useAppSelector(
    (state) =>
      state?.applicationStore?.ApplicationId?.EducationAndEmployementId,
  )
  const medicalId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.MedicalDetailId,
  )

  const IDs = {
    basicId,
    familyId,
    legalId,
    disabilityId,
    educationId,
    medicalId,
  }

  const location = useLocation()

  const handleNextClick = () => {
    switch (location.pathname) {
      case ROUTES.BASIC_DETAILS:
        return !!IDs.basicId
      case ROUTES.FAMILY_DETAILS:
        return !!IDs.familyId
      case ROUTES.LEGAL_DETAILS:
        return !!IDs.legalId
      case ROUTES.DISABILITY_DETAILS:
        return !!IDs.disabilityId
      case ROUTES.EDUCATION_DETAILS:
        return !!IDs.educationId
      case ROUTES.MEDICAL_DETAILS:
        return !!IDs.medicalId
      default:
        return false
    }
  }

  return (
    <Box className={Style.ContactBox} ref={myRef}>
      <Grid className={Style.ContactnGrid}>
        <Typography className={Style.ContactTitle}>
          {formComponents[activeStep]?.title}
        </Typography>
      </Grid>
      <Box className={Style.ContactMainBox}>
        <Button
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ visibility: activeStep === 0 ? 'hidden' : 'visible' }}
        >
          <img src={LeftStep} alt='' key='leftstep' />
        </Button>
        <Box className={Style.ContactSubBox}>
          {formComponents.map((_: any, index: number) => {
            return (
              <>
                {index > 0 && <Box className={Style.ContactMainLine} />}
                <div key={index}>{renderStepIndicator(index)}</div>
              </>
            )
          })}
        </Box>
        <Button
          onClick={() => {
            if (handleNextClick()) {
              handleNext()
            } else
              dispatch(
                showNotification({
                  type: NotificationType.Info,
                  message:
                    'Please fill in all the required fields of first step',
                }),
              )
          }}
          disabled={activeStep === totalSteps - 1}
          sx={{
            visibility: activeStep === totalSteps - 1 ? 'hidden' : 'visible',
          }}
        >
          <img src={RightStep} alt='' />
        </Button>
      </Box>
    </Box>
  )

  function renderStepIndicator(index: number) {
    if (index < activeStep) {
      return (
        <Grid className={Style.ContactFillCircle}>
          <img src={RightClick} alt='' />
        </Grid>
      )
    } else if (index === activeStep) {
      return (
        <Grid className={Style.ContactDotCircleRound}>
          <Grid className={Style.ContactDotCircle} />
        </Grid>
      )
    } else {
      return <Grid className={Style.Round} />
    }
  }
}

export default CustomStepper
