/* eslint-disable no-debugger */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import secureLocalStorage from 'react-secure-storage'
import {
  DynamicObj,
  extractOrganizationContactInfoData,
  extractOrganizationOverviewData,
  handleResponse,
  mapBusinessToJson,
  mapContactToJson,
} from 'src/Utils/Constants'
import OrganizationService from '../Services/orgService'
import { orgEmpType } from '../Services/Types/orgEmpType'
import { OrganizationListObj } from '../Services/Types/orgType'
const token = secureLocalStorage.getItem('token') as string

interface subscriptionDetailsType {
  id: string
  name: string
  amount: string
  numberOfApplication: string
  subscriptionStartDate: string
  subscriptionEndDate: string
  subscriptionPeriod: string
  plan: string
  description: string
  applicationCount: string
}
interface orgState {
  organizationList: OrganizationListObj[]
  selectedOrganization: OrganizationListObj
  addOrganization: OrganizationListObj
  orgEmployees: orgEmpType[]
  subscriptionDetails: subscriptionDetailsType[]
}

const initialOrgObject = {
  organization_address: {
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
  },
  uuid: '',
  isActive: true,
  name: '',
  description: '',
  subscriptionId: null,
  createdAt: '',
  emailId: '',
  phoneNumber: null,
  profileUrl: null,
  address: '',
  bussinessName: '',
  bussinessDescription: '',
  bussinessStreetAddress: '',
  bussinessCity: '',
  bussinessCountry: '',
  bussinessState: '',
  bussinessPostalCode: '',
  bussinessEmailId: '',
  bussinessType: '',
  bussinessSpecialization: '',
  bussinessTrackingName: '',
  bussinessProfileUrl: '',
  bussinessPhoneNumber: '',
  bussinessSecondaryPhoneNumber: '',
  bussinessSecondaryEmailId: '',
  bussinessAdminEmailId: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmailId: '',
  contactPassword: '',
  contactRepeatPassword: '',
  contactUuid: '',
}

// const initialSubscriptionObject = {
//   id: '',
//   name: '',
//   amount: '',
//   numberOfApplication: '',
//   subscriptionStartDate: '',
//   subscriptionEndDate: '',
//   subscriptionPeriod: '',
//   plan: '',
//   description: '',
//   applicationCount:''
// }

const initialState: orgState = {
  organizationList: [],
  selectedOrganization: initialOrgObject,
  addOrganization: initialOrgObject,
  orgEmployees: [],
  subscriptionDetails: [],
}

const organizationSlice = createSlice({
  name: 'Organization',
  initialState,
  reducers: {
    SetOrgList: (state, action: PayloadAction<any>) => {
      state.organizationList = action.payload
    },
    SetSelectedOrganization: (state, action: PayloadAction<any>) => {
      const obj = action.payload
      state.selectedOrganization = {
        ...state.selectedOrganization,
        ...obj,
      }
    },
    SetSelectedOrganizationValue: (state, action) => {
      const { key, value } = action.payload

      state.selectedOrganization = {
        ...state.selectedOrganization,
        [key]: value,
      }
    },

    SetSubscriptionDetailsValue: (state, action) => {
      const { key, value } = action.payload

      state.subscriptionDetails = {
        ...state.subscriptionDetails,
        [key]: value,
      }
    },

    SetAddOrganization: (state, action: PayloadAction<any>) => {
      const obj = action.payload
      state.addOrganization = {
        ...state.addOrganization,
        ...obj,
      }
    },
    setOrgEmployees: (state, action: PayloadAction<any>) => {
      const newArray = action.payload
      state.orgEmployees = newArray
    },

    SetAddOrganizationValue: (state, action) => {
      const { key, value } = action.payload

      state.addOrganization = {
        ...state.addOrganization,
        [key]: value,
      }
    },
  },
})

export const getOrganizationList = createAsyncThunk(
  'getOrganizationList', // name of the action
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI

    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const response = await OrganizationService.getOrganizationList(token) // make the API call
      if (response.data.success) {
        dispatch(SetOrgList(response.data?.data))
      }
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const getOrganizationOverview = createAsyncThunk(
  'getOrganizationList', // name of the action
  async (orgId: string, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const response = await OrganizationService.getOrganizationOverview(
        orgId,
        token,
      ) // make the API call
      const data = extractOrganizationOverviewData(response)
      dispatch(SetSelectedOrganization(data))
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const ActiveOrDeactivateOrg = createAsyncThunk(
  'ActiveOrDeactivateOrg', // name of the action
  async (data: DynamicObj, thunkAPI) => {
    const { dispatch } = thunkAPI
    try {
      const orgId = data.uuid
      const status = { isActive: data.isActive }
      const response = await OrganizationService.ActiveOrDeactivateOrg(
        orgId,
        token,
        status,
      ) // make the API call
      if (response.data.success) {
        const key = 'isActive'
        const value = data.isActive
        dispatch(SetSelectedOrganizationValue({ key, value }))
      }
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditOrganizationOverview = createAsyncThunk(
  'EditOrganizationOverview', // name of the action
  async (data: OrganizationListObj, thunkAPI) => {
    const { dispatch } = thunkAPI

    // try {
    const orgId = data?.uuid ?? ''
    const jsonObj = mapBusinessToJson(data)

    const formData = new FormData()

    for (const key in jsonObj) {
      if (Object.prototype.hasOwnProperty.call(jsonObj, key)) {
        formData.append(key, jsonObj[key])
      }
    }

    const response = await OrganizationService.EditOrganizationOverview(
      orgId,
      formData,
      token,
    )
    handleResponse({ response, dispatch })
    return response.data
  },
)

export const EditOrganizationContactInfo = createAsyncThunk(
  'EditOrganizationOverview', // name of the action
  async (data: OrganizationListObj, thunkAPI) => {
    const { dispatch } = thunkAPI

    // try {
    const orgId = data?.uuid ?? ''

    const jsonObj = mapContactToJson(data)

    const apiObj = JSON.stringify(jsonObj)
    const response = await OrganizationService.EditOrganizationContactInfo(
      orgId,
      apiObj,
      token,
    )
    handleResponse({ response, dispatch })
  },
)

export const AddOrganizationOverview = createAsyncThunk(
  'EditOrganizationOverview', // name of the action
  async (data: OrganizationListObj, thunkAPI) => {
    const { dispatch } = thunkAPI

    // try {
    const jsonObj = mapBusinessToJson(data)

    const formData = new FormData()

    for (const key in jsonObj) {
      if (Object.prototype.hasOwnProperty.call(jsonObj, key)) {
        formData.append(key, jsonObj[key])
      }
    }

    const response = await OrganizationService.AddOrganizationOverview(
      formData,
      token,
    )
    handleResponse({ response, dispatch })

    if (response.data?.data) {
      const key = 'uuid'
      const value = response.data.data.uuid
      dispatch(SetAddOrganizationValue({ key, value }))
    }
    return response.data
  },
)

export const AddOrganizationContactInfo = createAsyncThunk(
  'AddOrganizationContactInfo', // name of the action
  async (data: OrganizationListObj, thunkAPI) => {
    const { dispatch } = thunkAPI

    // try {
    const orgId = data?.uuid ?? ''

    const updatedObj = { ...data }

    delete updatedObj.contactRepeatPassword
    const jsonObj = mapContactToJson(updatedObj)

    const apiObj = JSON.stringify(jsonObj)
    const response = await OrganizationService.AddOrganizationContactInfo(
      orgId,
      apiObj,
      token,
    )
    if (response.data.success) {
      dispatch(SetAddOrganization(initialOrgObject))
      dispatch(getOrganizationList())
    }
    handleResponse({ response, dispatch })
  },
)
export const getOrganizationContactInfo = createAsyncThunk(
  'getOrganizationContactInfo', // name of the action
  async (orgId: string, thunkAPI) => {
    const { dispatch } = thunkAPI

    try {
      const response = await OrganizationService.getOrganizationContactInfo(
        orgId,
        token,
      ) // make the API call

      const data = extractOrganizationContactInfoData(response)
      dispatch(SetSelectedOrganization(data))
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const getOrganizationPayment = createAsyncThunk(
  'getOrganizationPayment', // name of the action
  async (orgId: string, thunkAPI) => {
    const { dispatch } = thunkAPI
    const onlyFail = true

    try {
      const response = await OrganizationService.getOrganizationPayment(
        orgId,
        token,
      )
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)
export const getOrganizationPaymentSummary = createAsyncThunk(
  'getOrganizationPaymentSummary', // name of the action
  async (orgId: string, thunkAPI) => {
    const { dispatch } = thunkAPI
    const onlyFail = true

    try {
      const response = await OrganizationService.getOrganizationPaymentSummary(
        orgId,
        token,
      ) // make the API call

      dispatch(
        SetSubscriptionDetailsValue({
          key: 'subscriptionDetails',
          value: response.data,
        }),
      )
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)
export const getOrganizationEmployees = createAsyncThunk(
  'getOrganizationEmployees', // name of the action
  async (filter: DynamicObj, thunkAPI) => {
    const { dispatch } = thunkAPI

    try {
      const response =
        await OrganizationService.getOrganizationEmployees(filter) // make the API call
      if (response.data?.data) dispatch(setOrgEmployees(response.data?.data))
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const CancelSubscription = createAsyncThunk(
  'DeleteOrganizationOverview', // name of the action
  async (data: string, thunkAPI) => {
    const { dispatch } = thunkAPI

    const response = await OrganizationService.CancelSubscription(data, token)
    handleResponse({ response, dispatch })
    return response.data
  },
)

export const {
  SetOrgList,
  SetSelectedOrganization,
  SetSelectedOrganizationValue,
  SetSubscriptionDetailsValue,
  SetAddOrganizationValue,
  SetAddOrganization,
  setOrgEmployees,
} = organizationSlice.actions
export default organizationSlice.reducer
