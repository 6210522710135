// This slice manages the state for the sign in feature
import {
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import {
  DisabilityBackgroundType,
  DisabilityDetailsType,
  DisabilityEmploymentType,
  DisabilitySettlementsType,
} from 'src/Redux/Services/Types/applicationType'
import { RootState, thunkAPItype } from 'src/Redux/store'
import { handleResponse } from 'src/Utils/Constants'
import DisabilityDetailsService from '../../Services/DisabilityDetailsService'
import secureLocalStorage from 'react-secure-storage'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: DisabilityDetailsType
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {
    DisabilityBackground: {
      applicationId: '',
      isAbleToWork: undefined,
      isDisabilityExpected1YearOrLonger: undefined,
      disabilityStartDate: '',
      disabilityEndInDeath: undefined,
      isAbleToPerformUsualJob: undefined,
      is14MonthsUnableToDoJob: undefined,
    },
    DisabilityEmployment: {
      disabilityId: '',
      intendToWorkNextYear: undefined,
      affectingEmploymentDetail: '',
      employedDuringLast2YearDetail: [],
      reasonToStoppedWorking: '',
      explainStoppedWorking: '',
      dateOfStoppedWorking: '',
      isRequireWorkChanges: undefined,
      haveMadeMoreMoneyInAnyMonth: undefined,
      workChangeStartDate: '',
      disabilityKeepFromWorkingDate: '',
    },
    PayoutsSettlements: {
      disabilityId: '',
      haveReceiveMoneyAfterDisability: undefined,
      typeOfPays: [],
      otherPayExplain: '',
      approxAmountExpected: 0,
      isExpectMoneyInFuture: undefined,
      typeOfFuturePay: [],
      futureOtherPayExplain: '',
      approxAmountFuturePayout: 0,
    },
  },
}

// Define the reducers that can modify the state
const DisabilityDetails = createSlice({
  name: 'DisabilityDetails',
  initialState,
  reducers: {
    setDisabilityDetailsLoading: (state) => {
      state.loading = true
    },
    setDisabilityDetailsSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setDisabilityDetailsFail: (state) => {
      state.success = false
      state.loading = false
      state.data = initialState.data
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AddDisabilityBackground.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddDisabilityBackground.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddDisabilityBackground.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(EditDisabilityBackground.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      EditDisabilityBackground.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(EditDisabilityBackground.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddDisabilityEmployment.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddDisabilityEmployment.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddDisabilityEmployment.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(AddPayoutsSettlements.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddPayoutsSettlements.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddPayoutsSettlements.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

export const {
  setDisabilityDetailsLoading,
  setDisabilityDetailsSuccess,
  setDisabilityDetailsFail,
} = DisabilityDetails.actions

export const AddDisabilityBackground = createAsyncThunk(
  'DisabilityBackground', // name of the action
  async (data: DisabilityBackgroundType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await DisabilityDetailsService.AddDisabilityBackground(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditDisabilityBackground = createAsyncThunk(
  'EditDisabilityBackground', // name of the action
  async (
    data: DisabilityDetailsType | any,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await DisabilityDetailsService.EditDisabilityBackground(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddDisabilityEmployment = createAsyncThunk(
  'IdentificationNumbers', // name of the action
  async (data: DisabilityEmploymentType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await DisabilityDetailsService.AddDisabilityEmployment(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddPayoutsSettlements = createAsyncThunk(
  'PayoutsSettlements', // name of the action
  async (data: DisabilitySettlementsType, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await DisabilityDetailsService.AddPayoutsSettlements(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default DisabilityDetails.reducer
