import React from 'react'

import Education from './Education'
import EmployerDetails from './EmployerDetails'
import ForeignWork from './ForeignWork'
import JobDetails from './JobDetails'
import ChildCare from './ChildCare'
import SelfEmployment from './SelfEmployment'
import SpecialEducation from './SpecialEducation'
import SpecializedTradeEducationTraining from './SpecializedTradeEducationTraining'
import WorkTaxDetails from './WorkTaxDetails'

const EducationDetails: React.FunctionComponent = () => {
  return (
    <>
      <Education />
      <SpecialEducation />
      <SpecializedTradeEducationTraining />
      <EmployerDetails />
      <JobDetails />
      <ChildCare />
      <SelfEmployment />
      <ForeignWork />
      <WorkTaxDetails />
    </>
  )
}

export default EducationDetails
