import { useEffect, useState } from 'react'
import { GetPaymentDetail } from 'src/Redux/Slices/authSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import SucessPopUp from 'src/Components/PymentPopups/SuccessPopup'
import { useLocation } from 'react-router-dom'

const Success: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const [openDialog, setOpenDialog] = useState(true)

  const handleOpen = () => {
    setOpenDialog(true)
  }
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('emailId')
  const emailId = useAppSelector((state) => state?.auth?.SignUpData?.emailId)
  const sessionId = useAppSelector((state: RootState) => state.auth?.sessionId)
  const [planName, setPlanName] = useState('')

  useEffect(() => {
    dispatch(
      GetPaymentDetail({
        emailId: email ?? emailId,
        sessionId,
        status: 'success',
      }),
    ).then((res) => {
      if (res?.payload?.success) {
        setPlanName(res?.payload?.data?.SubscrioptionData?.name)
      }
    })
  }, [])
  return (
    <>
      <SucessPopUp
        planName={planName}
        open={openDialog}
        setOpen={setOpenDialog}
        handleOpen={handleOpen}
      />
    </>
  )
}

export default Success
