// This slice manages the state for the sign in feature
import {
  PayloadAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { RoleType } from '../Services/Types/roleType'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import PermissionService from '../Services/permissionService'
import { thunkAPItype } from '../store'
import { LogoutRequest } from './authSlice'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: RoleType
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {},
}

// Define the reducers that can modify the state
const permissionDetailsSlice = createSlice({
  name: 'permissionDetails',
  initialState,
  reducers: {
    setRoleLoading: (state) => {
      state.loading = true
    },
    setRoleSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setRoleFail: (state) => {
      state.success = false
      state.loading = false
      state.data = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewPermissionDetailRequest.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewPermissionDetailRequest.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(ViewPermissionDetailRequest.rejected, (state) => {
      state.success = false
      state.loading = false
    })
    builder.addCase(LogoutRequest.fulfilled, (state) => {
      state = initialState
    })
  },
})

// Define the async thunk action creator for sign in
export const ViewPermissionDetailRequest = createAsyncThunk(
  'viewPermissionDetail', // name of the action
  async (data: string, thunkAPI: thunkAPItype) => {
    try {
      const token = thunkAPI.getState().auth.data?.token
      const response = await PermissionService.viewPermissionDetail(data, token) // make the API call
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default permissionDetailsSlice.reducer
export const { setRoleLoading, setRoleSuccess, setRoleFail } =
  permissionDetailsSlice.actions
