import {
  Box,
  Button,
  CardContent,
  Typography,
  Grid,
  Divider,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import SignConcentForm from './EditForms/SignConsentForms/index'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { GetUploadDocument } from 'src/Redux/Slices/ApplicationSlices/SignConsentFormsSlice'
import DescriptionIcon from '@mui/icons-material/Description'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
const ConcentForm: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false)

  const signConsentId: string = useAppSelector(
    (state) => state.applicationStore.ApplicationId?.signConsentId,
  )
  const [formValues, setFormValues] = useState<any>({
    ssa: [],
    medical: [],
    other: [],
  })

  const [getData, setGetData] = useState<{
    consentUrls?: Array<{
      url: string
      name: string
      isUpdated: boolean
      consentUrls: string
    }>
  }>({})
  const titles = [
    'SSA-1696  Or Other Representation Signed Consent Forms',
    'Signed Medical Consent Forms',
    'Any Other Relevant Signed Consent Forms',
  ]
  const consentFormRef = useRef<HTMLDivElement>(null)

  const handleEditToggle = () => {
    setEditMode(!editMode)
    consentFormRef?.current?.scrollIntoView()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const dispatch = useAppDispatch()
  useEffect(() => {
    const keys = Object.keys(formValues)
    const newData = { ...formValues }
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index] as keyof typeof formValues
      newData[key] =
        getData.consentUrls
          ?.filter((item) => item.name === key)
          .map((item) => {
            return item.url
          }) ?? []
    }
    setFormValues(newData)
  }, [getData])

  useEffect(() => {
    if (signConsentId)
      dispatch(GetUploadDocument(signConsentId)).then((res: any) => {
        if (res.payload?.success) {
          setGetData(res.payload?.data)
        }
      })
  }, [editMode])

  const getIconForFile = (url: string, orgUrl: string) => {
    if (url.endsWith('.pdf')) {
      return <PictureAsPdfIcon style={{ fontSize: 100 }} />
    } else if (url.endsWith('.doc') || url.endsWith('.docx')) {
      return <DescriptionIcon style={{ fontSize: 100 }} />
    }
    return (
      <img
        src={orgUrl}
        alt='Document1'
        style={{
          height: '300px',
          width: '300px',
        }}
      />
    )
  }

  return (
    <>
      {!editMode ? (
        <>
          <div ref={consentFormRef} />
          <Box className={styles.container}>
            {Object.keys(formValues).map((key, index) => {
              const matchedUrls = getData?.consentUrls?.filter((consent) =>
                formValues[key as keyof typeof formValues].includes(
                  consent.url,
                ),
              )
              return (
                <div key={index}>
                  <Box className={styles.title}>
                    <Typography variant='h6' className={styles.titleHeading}>
                      {titles[index]}
                    </Typography>

                    {index === 0 ? (
                      <>
                        <Button
                          variant='outlined'
                          className={styles.editButton}
                          onClick={handleEditToggle}
                        >
                          Edit
                        </Button>
                      </>
                    ) : null}
                  </Box>
                  <div className={styles.dottedLine} />

                  <CardContent className={styles.cardContent}>
                    {matchedUrls?.map((consent) => (
                      <Box
                        key={consent.url}
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        style={{
                          height: '300px',
                          width: '300px',
                          border: '1px solid #ccc',
                          marginBottom: '16px',
                        }}
                      >
                        {getIconForFile(consent.consentUrls, consent.url)}
                      </Box>
                    ))}
                  </CardContent>
                </div>
              )
            })}
          </Box>
        </>
      ) : (
        <>
          <Grid m={2}>
            <Grid container m={2}>
              <Grid lg={10} className='alignItemCenter' display='flex'>
                <Typography variant='h1' className='FontBold'>
                  Signed Concent Form
                </Typography>
              </Grid>
              <Divider
                sx={{ width: '99%', marginTop: '8px', marginBottom: '8px' }}
              />
              <Grid container m={2} ml={0}>
                <SignConcentForm handleEditToggle={handleEditToggle} />
                <>{/* <Medicine handleEditToggle={handleEditToggle} /> */}</>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default ConcentForm
