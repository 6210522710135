import {
  Button,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  styled,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useEffect, useState } from 'react'
import IconChevronDown from 'src/Assets/Icons/IconChevronDown.svg'
import quote from 'src/Assets/Icons/quots.svg'
import star from 'src/Assets/Icons/star.svg'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { MilitaryServiceType } from 'src/Redux/Services/Types/applicationType'
import { AddMilitaryService } from 'src/Redux/Slices/ApplicationSlices/BasicDetailsSlice'
import { setMilitaryService } from 'src/Redux/Slices/ApplicationStore/BasicDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import ProfileBox from './ProfileBox'
import Style from './index.module.scss'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'

const StyledSelect = styled(Select)({
  borderRadius: '10px',
})

const MilitaryService = ({
  handleEditToggle,
}: {
  setDetailId?: React.Dispatch<React.SetStateAction<string>>
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const MilitaryServiceState = useAppSelector(
    (state) => state?.applicationStore?.BasicDetails?.MilitaryService,
  )

  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.basicId,
  )
  const [selectedDate, setSelectedDate] = useState<
    Date | null | undefined | any
  >(null)
  const [endDate, setEndDate] = useState<Date | null | undefined>(null)
  const [isChecked, setIsChecked] = useState<boolean[]>([false])
  const [formValues, setFormValues] = useState<MilitaryServiceType>({
    basicDetailId: detailId,
    isServedInMilitary: undefined,
    isReceivingFederalAgencyBenefit: undefined,
    typeOfBenefit: '',
    serviceInformation: [
      {
        typeOfDuty: '',
        branchOfService: '',
        startDate: '',
        endDate: '',
      },
    ],
  })

  useEffect(() => {
    if (formValues?.isServedInMilitary === false) {
      setFormValues((prevValues) => ({
        ...prevValues,
        isReceivingFederalAgencyBenefit: undefined,
        typeOfBenefit: '',
        serviceInformation: [
          {
            typeOfDuty: '',
            branchOfService: '',
            startDate: '',
            endDate: '',
          },
        ],
      }))
    }
  }, [formValues?.isServedInMilitary])

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date)
    setEndDate(date)
  }

  useEffect(() => {
    setFormValues({
      ...MilitaryServiceState,
      basicDetailId: detailId,
    })
    setIsChecked(
      MilitaryServiceState?.serviceInformation?.map(
        (item: {
          typeOfDuty: string
          branchOfService: string
          startDate: string
          endDate: string
        }) => !item.endDate,
      ),
    )
  }, [])

  useEffect(() => {
    if (formValues.isServedInMilitary !== undefined) {
      setFormValues((prevState) => ({
        ...prevState,
        ...MilitaryServiceState,
        isServedInMilitary: formValues.isServedInMilitary,
        isReceivingFederalAgencyBenefit:
          formValues.isReceivingFederalAgencyBenefit,
      }))
    }
  }, [formValues.isServedInMilitary])

  const [errors, setErrors] = useState<
    Record<string, string | Array<Record<string, string>>>
  >({ serviceInformation: [{}] })

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    // You may perform validation here and update errors state accordingly
  }

  const validateForm = () => {
    let valid = true
    const newErrors: typeof errors = {
      serviceInformation: [{}],
    }
    const currentDate = new Date().toISOString().split('T')[0]
    if (formValues?.isServedInMilitary?.valueOf() === undefined) {
      newErrors.isServedInMilitary = 'Please select an option'
      valid = false
    }
    if (formValues.isServedInMilitary === true) {
      for (let i = 0; i < formValues?.serviceInformation?.length; i++) {
        if (!formValues?.serviceInformation[i].typeOfDuty.trim()) {
          newErrors.serviceInformation = (
            newErrors.serviceInformation as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return { ...error, typeOfDuty: 'Type of Duty is required' }
            return error
          })
          valid = false
        }
        if (!formValues?.serviceInformation[i].branchOfService.trim()) {
          newErrors.serviceInformation = (
            newErrors.serviceInformation as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return {
                ...error,
                branchOfService: 'Branch of Service is required',
              }
            return error
          })
          valid = false
        }

        if (!formValues?.serviceInformation[i].startDate) {
          newErrors.serviceInformation = (
            newErrors.serviceInformation as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return { ...error, startDate: 'Start Date is required' }
            return error
          })
          valid = false
        }
        if (formValues?.serviceInformation[i].startDate > currentDate) {
          newErrors.serviceInformation = (
            newErrors.serviceInformation as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return {
                ...error,
                startDate: 'Start Date cannot be in the future',
              }
            return error
          })
          valid = false
        }
        if (formValues?.serviceInformation[i].endDate > currentDate) {
          newErrors.serviceInformation = (
            newErrors.serviceInformation as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return {
                ...error,
                endDate: 'End Date cannot be in the future',
              }
            return error
          })
          valid = false
        }

        if (!isChecked[i]) {
          if (!formValues?.serviceInformation[i].endDate) {
            newErrors.serviceInformation = (
              newErrors.serviceInformation as Array<Record<string, string>>
            )?.map((error, index) => {
              if (i === index)
                return { ...error, endDate: 'End Date is required' }
              return error
            })
            valid = false
          }
        }

        ;(newErrors.serviceInformation as Array<Record<string, string>>).push(
          {},
        )
      }
    }
    if (formValues?.isReceivingFederalAgencyBenefit === true) {
      if (!formValues.typeOfBenefit?.trim()) {
        newErrors.typeOfBenefit = 'Type of Benefit is required'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (validateForm()) {
      dispatch(setMilitaryService(formValues))
      const formValuesToSubmit: typeof formValues = {}
      formValuesToSubmit.basicDetailId = detailId
      formValuesToSubmit.isServedInMilitary = formValues.isServedInMilitary
      if (formValues.isServedInMilitary) {
        formValuesToSubmit.serviceInformation = formValues.serviceInformation
        formValuesToSubmit.isReceivingFederalAgencyBenefit =
          formValues.isReceivingFederalAgencyBenefit
        if (formValues.isReceivingFederalAgencyBenefit) {
          formValuesToSubmit.typeOfBenefit = formValues.typeOfBenefit
        }
      }
      if (formValues.isServedInMilitary === false) {
        formValuesToSubmit.basicDetailId = detailId
        formValuesToSubmit.isServedInMilitary = formValues.isServedInMilitary
      }
      dispatch(AddMilitaryService(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            handleEditToggle()
          }
        },
      )
    }
  }

  const handleValuesChange = (
    index: number,
    data: {
      name: string
      value: string
    },
  ) => {
    const { name, value } = data
    let newProfileForms = [...formValues.serviceInformation]
    newProfileForms = newProfileForms.map(
      (
        item: {
          typeOfDuty: string
          branchOfService: string
          startDate: string
          endDate: string
        },
        i: number,
      ) => {
        if (i === index) {
          return {
            ...item,
            [name]: value,
          }
        }
        return item
      },
    )
    // newProfileForms[`${index}`][`${data.name}`] = data.value
    setFormValues({ ...formValues, serviceInformation: newProfileForms })
  }

  const handleRemove = (index: number) => {
    const newProfileForms = [...formValues.serviceInformation]
    newProfileForms.splice(index, 1)
    setFormValues({ ...formValues, serviceInformation: newProfileForms })
    setIsChecked([...isChecked.slice(0, index), ...isChecked.slice(index + 1)])
  }

  const addProfileBox = () => {
    setFormValues({
      ...formValues,
      serviceInformation: [
        ...formValues.serviceInformation,
        {
          typeOfDuty: '',
          branchOfService: '',
          startDate: '',
          endDate: '',
        },
      ],
    })
    setIsChecked([...isChecked, false])
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              py: 2,
              my: 3,
              borderRadius: '10px',
            }}
          >
            <img src={quote} className={Style.star} alt='' />
            <Typography className={Style.Heading}>
              Good work! Next topic: military service.
            </Typography>
          </Box>

          {/* Common radio */}
          <CommonRadioBox
            Style={Style}
            title='Did you serve or are you serving in the U.S. military?'
            commonState={formValues.isServedInMilitary}
            setcommonState={(value: boolean) => {
              setFormValues({ ...formValues, isServedInMilitary: value })
            }}
            YesTitle='I served or am serving in the U.S. military.'
            NoTitle='I did not serve, nor am I serving in the U.S. military.'
          />
          <FormHelperText>{errors?.isServedInMilitary}</FormHelperText>

          {formValues.isServedInMilitary === true ? (
            <>
              <Box
                width={'100%'}
                display={'flex'}
                alignItems={'flex-start'}
                sx={{
                  p: 2,
                  my: 4,
                  backgroundColor: '#ECF6FF',
                  borderRadius: '10px',
                }}
              >
                <img src={star} className={Style.star} alt='' />
                <Typography>
                  <i>
                    Thank you for your service! Please tell us a little more.
                  </i>
                </Typography>
              </Box>

              <Box
                my={'20px'}
                gap={'30px'}
                display={'flex'}
                flexDirection={'column'}
              >
                <Typography className={Style.label}>
                  Military Service Information
                </Typography>

                {formValues.serviceInformation.map((form, index) => (
                  <ProfileBox
                    selectedDate={selectedDate}
                    endDate={endDate}
                    handleDateChange={handleDateChange}
                    errors={
                      errors.serviceInformation[index] as Record<string, string>
                    }
                    key={index}
                    handleRemove={() => {
                      handleRemove(index)
                    }}
                    index={index}
                    formValues={form}
                    changeHandler={(data) => {
                      handleValuesChange(index, data)
                    }}
                    isChecked={isChecked[index]}
                    setIsChecked={(value) => {
                      setIsChecked(
                        isChecked.map((val, i) => (i === index ? value : val)),
                      )
                    }}
                  />
                ))}

                <Grid xs={12} className={Style.cardAddInstance}>
                  <Box className='addNames'>
                    <Typography
                      className={Style.addNamesHeading}
                      variant='body1'
                    >
                      Service, Past and Present
                    </Typography>
                    <Typography className={Style.addNamesText} variant='body1'>
                      For an effective records search, It is important that all
                      service be shown below
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      onClick={addProfileBox}
                      className={Style.addNamesButton}
                      variant='contained'
                    >
                      Add Military Service
                    </Button>
                  </Box>
                </Grid>
              </Box>

              {/* Common radio */}
              <CommonRadioBox
                Style={Style}
                title='Are you receiving or eligible to receive a military or civilian federal agency benefit?'
                commonState={formValues.isReceivingFederalAgencyBenefit}
                setcommonState={(value: boolean) => {
                  setFormValues({
                    ...formValues,
                    isReceivingFederalAgencyBenefit: value,
                  })
                }}
                YesTitle='I am receiving or eligible to receive a military or civilian federal agency benefit'
                NoTitle='I am not receiving or eligible to receive a military or civilian federal agency benefit'
              />
              <FormHelperText>
                {errors?.isReceivingFederalAgencyBenefit}
              </FormHelperText>

              {formValues.isReceivingFederalAgencyBenefit === true ? (
                <>
                  <Box my={'60px'}>
                    <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                      <Grid width={'100%'}>
                        <InputLabel className={Style.label}>
                          Got it. What type of benefit is it?
                        </InputLabel>
                        <StyledSelect
                          onChange={(e) => {
                            handleFieldChange(e)
                          }}
                          className={Style.AnsTxt}
                          IconComponent={(props) => (
                            <IconButton {...props}>
                              <img
                                src={IconChevronDown}
                                alt='IconChevronDown'
                              />
                            </IconButton>
                          )}
                          displayEmpty
                          renderValue={
                            formValues.typeOfBenefit !== ''
                              ? () => formValues.typeOfBenefit
                              : () => 'Select'
                          }
                          value={formValues.typeOfBenefit}
                          fullWidth
                          id='typeOfBenefit'
                          name='typeOfBenefit'
                          placeholder='Relation with applicant'
                          error={
                            formValues?.isReceivingFederalAgencyBenefit &&
                            !!errors.relation
                          }
                        >
                          <MenuItem value=''>
                            <em>Select an Answer</em>
                          </MenuItem>
                          <MenuItem value='Military'>Military</MenuItem>
                          <MenuItem value='Civilian'>Civilian</MenuItem>
                          <MenuItem value='Both'>Both</MenuItem>
                        </StyledSelect>
                        <FormHelperText sx={{ mx: 1.5 }}>
                          {errors.typeOfBenefit}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleEditToggle}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
              // onClick={handleNext}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default MilitaryService
