import { Box, Grid, Typography } from '@mui/material'
import React, { useState, type FC } from 'react'
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'
import CalenderPopup from './Calender'
interface dataType {
  name: string
  value: number
}
interface ChartData {
  name: string
  value: number
}

interface TooltipProps {
  active?: boolean
  payload?: Array<{
    name: string
    value: number
    payload: ChartData
  }>
}
const AvarageTime: FC = () => {
  // it's just a temp data. It will be replaced in future once API integration is done.
  const data: dataType[] = [
    { name: 'Jan', value: 10 },
    { name: 'Feb', value: 12 },
    { name: 'Mar', value: 3 },
    { name: 'Apr', value: 5 },
    { name: 'May', value: 4 },
    { name: 'Jun', value: 3 },
    { name: 'Jul', value: 12 },
    { name: 'Aug', value: 3 },
    { name: 'Sep', value: 10 },
    { name: 'Oct', value: 12 },
    { name: 'Nov', value: 6 },
    { name: 'Dec', value: 8 },
  ]
  // Randomize the values while keeping the names intact
  // const randomizedData = data.map((item: dataType) => ({
  //   name: item.name,
  //   value: Math.floor(Math.random() * 1000), // Randomize values between 0 and 1000
  // }))
  const CustomTooltip: React.FC<TooltipProps> = ({ active, payload }) => {
    if (active && payload?.length) {
      const data = payload[0].payload
      return (
        <>
          <Box className={Style.customTooltip}>
            <Box className={Style.myHeader} display='flex' alignItems='center'>
              <Typography className={Style.maintxt}>8 Applications</Typography>
              {/* <span>{data.name}</span> */}
            </Box>
            {/* <Box className={Style.myDivider} /> */}
            <Box className={Style.myDetails} display='flex'>
              {/* <Box className={Style.myIcon} /> */}
              <Typography>{`Avg. Time Spent: ${data.value}`}</Typography>
            </Box>
            <Box className={Style.myDetails} display='flex'>
              <Typography>{`Avg. Savings: $${data.value * 50}`}</Typography>
            </Box>
          </Box>
        </>
      )
    }

    return null
  }

  const anchor = React.useRef()
  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => {
    setOpen(!open)
  }

  const [dateRange, setDateRange] = React.useState<string | undefined>(
    new Date().toISOString(),
  )

  const formatDate = (dateString: any) => {
    const date = new Date(dateString)
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  // const formatAPIDate = (dateString: any) => {
  //   const date = new Date(dateString);
  //   return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  // };

  return (
    <>
      <Box className={Style.myContainer}>
        {/* <Typography component={'span'} className={Style.myHeading}>
        Time Spent Per Application (hours)
        <Typography component={'span'} className={Style.myDate}>
          Jan 22, 2024
          <img src={Icons.calander} alt='calander' className={Style.myIcon} />
        </Typography>
      </Typography> */}
        <Box className={Style.RecurringRevenueBoxTitle}>
          <Grid>
            <Typography className={Style.RecurringRevenueBoxText}>
              Time Spent Per Application (hours)
            </Typography>
          </Grid>
          <Box
            ref={anchor}
            onClick={toggle}
            className={Style.RecurringRevenueBoxDate}
          >
            <Typography
              component={'span'}
              className={Style.RecurringRevenueDate}
            >
              {formatDate(dateRange)}
            </Typography>
            <img
              src={Icons.calander}
              alt='calander'
              height={22}
              width={22}
              style={{ cursor: 'pointer', marginLeft: '6px' }}
            />
          </Box>
        </Box>

        <Grid item container xs={12} className={Style.chartGrid}>
          <Box className={Style.ApplicationChartBox}>
            <ResponsiveContainer width='100%' height='100%'>
              <ComposedChart data={data}>
                <CartesianGrid strokeDasharray='3 3' />
                <defs>
                  <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='20%' stopColor='#8E8FFA' stopOpacity={0.4} />
                    <stop offset='90%' stopColor='#fff' stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey='name'
                  axisLine={{ stroke: '#99A1B7' }}
                  tick={{ fill: '#99A1B7' }}
                />
                <YAxis
                  tickFormatter={(value) => `${value}h`}
                  axisLine={{ stroke: '#99A1B7' }}
                  tick={{ fill: '#99A1B7' }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type='bumpX'
                  dataKey='value'
                  stroke='#8E8FFA'
                  fillOpacity={5}
                  fill='url(#colorUv)'
                />
                <Line
                  tooltipType='none'
                  dot={false}
                  activeDot={{ r: 5 }}
                  type='bumpX'
                  dataKey='value'
                  stroke='#8E8FFA'
                  strokeWidth={3}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
      </Box>
      <CalenderPopup
        toggle={toggle}
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        anchor={anchor}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
    </>
  )
}

export default AvarageTime
