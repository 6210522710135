import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/Redux/store'

export default function CircularIndeterminate(): React.ReactElement {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading)

  return (
    <>
      {isLoading && (
        <Backdrop
          open
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
    </>
  )
}
