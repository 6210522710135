// This slice manages the state for the family details
import { createSlice } from '@reduxjs/toolkit'
import { FamilyDetailsType } from '../../Services/Types/applicationType'

// Define the initial state of the slice
const initialState: FamilyDetailsType = {
  familyFlagData: {},
  CurrentSignificant: {
    havePriorMarriages: undefined,
    pastMarriage10Years: undefined,
    spouseFirstName: '',
    isLegallyMarried: undefined,
    spouseLastName: '',
    spouseDateOfBirth: '',
    spouseSSN: '',
    typeOfMarriage: '',
    dateOfMarriage: '',
    placeOfMarriage: {
      city: '',
      state: '',
      other: '',
      country: '',
    },
    applicationId: '',
  },
  Dependents: {
    familyId: '',
    haveAnyChildern: undefined,
    numberOfChildern: '',
    dependents: [
      {
        firstName: '',
        lastName: '',
        isYoungerThan17: undefined,
        isChildMarried: undefined,
        isChildDisabled: undefined,
        isChild1819SchoolUnmarried: undefined,
        isChildDisabledBeforeAge22: undefined,
      },
    ],
  },
  Parent: {
    familyId: '',
    isParentReceiveOneHalfSupport: undefined,
    firstParent: {
      suffix: '',
      firstName: '',
      lastName: '',
      middleName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
    isSecondParentReceiveOneHalfSupport: undefined,
    secondParent: {
      suffix: '',
      firstName: '',
      lastName: '',
      middleName: '',
      address: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
  },
  PassSignificant: {
    familyId: '',
    havePriorMarriages: undefined,
    pastMarriage10Years: undefined,
    isPriorMarriageEndDuetoSpouseDeath: undefined,
    pastSignificantOthers: [
      {
        spouseFirstName: '',
        spouseLastName: '',
        spouseDateOfBirth: '',
        spouseSSN: '',
        typeOfMarriage: '',
        dateOfMarriage: '',
        marriageEndDate: '',
        howMarriageEnded: '',
        placeOfMarriage: {
          city: '',
          state: '',
          other: '',
          country: '',
        },
        placeOfMarriageEnded: {
          city: '',
          state: '',
          other: '',
          country: '',
        },
        pastSOEndInDeath: '',
        pastSODeathDate: '',
      },
    ],
  },
}

// Define the reducers that can modify the state
const FamilyDetailsStore = createSlice({
  name: 'FamilyDetails',
  initialState,
  reducers: {
    resetFamilyDetails: (state) => {
      state.CurrentSignificant = {
        spouseFirstName: '',
        spouseLastName: '',
        spouseDateOfBirth: '',
        spouseSSN: '',
        typeOfMarriage: '',
        dateOfMarriage: '',
        placeOfMarriage: {
          city: '',
          state: '',
          other: '',
          country: '',
        },
        applicationId: '',
      }
      state.Dependents = {
        familyId: '',
        haveAnyChildern: undefined,
        numberOfChildern: '',
        dependents: [
          {
            firstName: '',
            lastName: '',
            isYoungerThan17: undefined,
            isChildMarried: undefined,
            isChildDisabled: undefined,
            isChild1819SchoolUnmarried: undefined,
            isChildDisabledBeforeAge22: undefined,
          },
        ],
      }
      state.Parent = {
        familyId: '',
        isParentReceiveOneHalfSupport: undefined,
        firstParent: {
          suffix: '',
          firstName: '',
          lastName: '',
          middleName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
        },
        isSecondParentReceiveOneHalfSupport: undefined,
        secondParent: {
          suffix: '',
          firstName: '',
          lastName: '',
          middleName: '',
          address: {
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
          },
        },
      }
      state.PassSignificant = {
        familyId: '',
        havePriorMarriages: undefined,
        pastMarriage10Years: undefined,
        isPriorMarriageEndDuetoSpouseDeath: undefined,
        pastSignificantOthers: [
          {
            spouseFirstName: '',
            spouseLastName: '',
            spouseDateOfBirth: '',
            spouseSSN: '',
            typeOfMarriage: '',
            dateOfMarriage: '',
            marriageEndDate: '',
            howMarriageEnded: '',
            placeOfMarriage: {
              city: '',
              state: '',
              other: '',
              country: '',
            },
            placeOfMarriageEnded: {
              city: '',
              state: '',
              other: '',
              country: '',
            },
            pastSOEndInDeath: '',
            pastSODeathDate: '',
          },
        ],
      }
    },
    setFamilyDetails: (state, action: { payload: typeof initialState }) => {
      state.CurrentSignificant = action.payload.CurrentSignificant
      state.Dependents = action.payload.Dependents
      state.Parent = action.payload.Parent
      state.PassSignificant = action.payload.PassSignificant
    },
    setCurrentSignificant: (
      state,
      action: { payload: typeof initialState.CurrentSignificant },
    ) => {
      state.CurrentSignificant = action.payload
    },
    setDependents: (
      state,
      action: { payload: typeof initialState.Dependents },
    ) => {
      state.Dependents = action.payload
    },
    setParent: (state, action: { payload: typeof initialState.Parent }) => {
      state.Parent = action.payload
    },
    setFamilyFlagData: (
      state,
      action: { payload: typeof initialState.familyFlagData },
    ) => {
      state.familyFlagData = action.payload
    },
    setPassSignificant: (
      state,
      action: { payload: typeof initialState.PassSignificant },
    ) => {
      state.PassSignificant = action.payload
    },
  },
})

export default FamilyDetailsStore.reducer

export const {
  resetFamilyDetails,
  setCurrentSignificant,
  setDependents,
  setFamilyDetails,
  setParent,
  setFamilyFlagData,
  setPassSignificant,
} = FamilyDetailsStore.actions
