import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import quote from 'src/Assets/Icons/quots.svg'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import { DisabilitySettlementsType } from 'src/Redux/Services/Types/applicationType'
import { AddPayoutsSettlements } from 'src/Redux/Slices/ApplicationSlices/DisabilityDetailsSlice'
import { setPayoutsSettlements } from 'src/Redux/Slices/ApplicationStore/DisabilityDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import Style from './index.module.scss'

interface OptionsType {
  value: string
  label: string
}

const StyledSelect = styled(Select)({
  borderRadius: '10px', // Set the border radius to your desired value
})

const PayoutsSettlements = ({
  handleEditToggle,
}: {
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()
  const disabilityId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.disabilityId,
  )

  const PayoutsSettlementsState = useAppSelector(
    (state) => state?.applicationStore?.DisabilityDetails?.PayoutsSettlements,
  )

  const [formValues, setFormValues] = useState<DisabilitySettlementsType>({
    disabilityId,
    haveReceiveMoneyAfterDisability: undefined,
    typeOfPays: [],
    otherPayExplain: '',
    approxAmountExpected: '',
    isExpectMoneyInFuture: undefined,
    typeOfFuturePay: [],
    futureOtherPayExplain: '',
    approxAmountFuturePayout: '',
  })

  useEffect(() => {
    if (!formValues?.isExpectMoneyInFuture) {
      setFormValues((prevValues) => ({
        ...prevValues,
        typeOfFuturePay: [],
        futureOtherPayExplain: '',
        approxAmountFuturePayout: 0,
      }))
    }
  }, [formValues?.isExpectMoneyInFuture])
  useEffect(() => {
    if (!formValues?.haveReceiveMoneyAfterDisability) {
      setFormValues((prevValues) => ({
        ...prevValues,
        typeOfPays: [],
        otherPayExplain: '',
        approxAmountExpected: 0,
      }))
    }
  }, [formValues?.haveReceiveMoneyAfterDisability])

  useEffect(() => {
    setFormValues({ ...PayoutsSettlementsState, disabilityId })
  }, [])
  const [errors, setErrors]: [
    Record<string, string>,
    React.Dispatch<React.SetStateAction<Record<string, string>>>,
  ] = useState({})

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string> = {}

    if (formValues?.haveReceiveMoneyAfterDisability === undefined) {
      newErrors.haveReceiveMoneyAfterDisability = 'please select option'
      valid = false
    }
    if (formValues?.isExpectMoneyInFuture === undefined) {
      newErrors.isExpectMoneyInFuture = 'please select option'
      valid = false
    }

    if (formValues?.haveReceiveMoneyAfterDisability) {
      if (formValues.typeOfPays.length === 0) {
        newErrors.typeOfPays = 'please select type of pays'
        valid = false
      }
      if (!formValues.otherPayExplain.trim()) {
        newErrors.otherPayExplain = 'please enter description'
        valid = false
      }
      if (formValues.approxAmountExpected <= 0) {
        newErrors.approxAmountExpected = 'please enter amount'
        valid = false
      }
    }

    if (formValues?.isExpectMoneyInFuture) {
      if (formValues.typeOfFuturePay.length === 0) {
        newErrors.typeOfFuturePay = 'please select type of pays'
        valid = false
      }
      if (!formValues.futureOtherPayExplain.trim()) {
        newErrors.futureOtherPayExplain = 'please enter description'
        valid = false
      }
      if (formValues.approxAmountFuturePayout <= 0) {
        newErrors.approxAmountFuturePayout = 'please enter amount'
        valid = false
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValuesToSubmit: typeof formValues = {}
    formValuesToSubmit.disabilityId = disabilityId
    formValuesToSubmit.haveReceiveMoneyAfterDisability =
      formValues.haveReceiveMoneyAfterDisability
    formValuesToSubmit.isExpectMoneyInFuture = formValues.isExpectMoneyInFuture
    if (formValues?.haveReceiveMoneyAfterDisability) {
      formValuesToSubmit.typeOfPays = formValues.typeOfPays
      formValuesToSubmit.otherPayExplain = formValues.otherPayExplain
      formValuesToSubmit.approxAmountExpected = formValues.approxAmountExpected
    }
    if (formValues?.isExpectMoneyInFuture) {
      formValuesToSubmit.typeOfFuturePay = formValues.typeOfFuturePay
      formValuesToSubmit.futureOtherPayExplain =
        formValues.futureOtherPayExplain
      formValuesToSubmit.approxAmountFuturePayout =
        formValues.approxAmountFuturePayout
    }
    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      dispatch(AddPayoutsSettlements(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setPayoutsSettlements(formValues))

            handleEditToggle()
          }
        },
      )
    }
  }

  const handleSelectChange = (name: string, type: string) => {
    // Check if the item is already selected
    const typeOfPays = formValues[type].includes(name)

    if (typeOfPays) {
      // Remove the item from the array
      setFormValues((prevValues) => ({
        ...prevValues,
        [type]: prevValues[type].filter((item: string) => item !== name),
      }))
    } else {
      // Add the item to the array
      setFormValues((prevValues) => ({
        ...prevValues,
        [type]: [...prevValues[type], name],
      }))
    }
  }

  const selectOptions = [
    { value: 'Unemployment', label: 'Unemployment' },
    { value: 'Pension', label: 'Pension' },
    { value: 'Sick/Vacation Pay', label: 'Sick/Vacation Pay' },
    { value: 'Short-Term Disability', label: 'Short-Term Disability' },
    { value: 'Long-Term Disability', label: 'Long-Term Disability' },
    {
      value: 'Income from Rental Properties',
      label: 'Income from Rental Properties',
    },
    { value: 'Income from Farmland', label: 'Income from Farmland' },
    { value: 'Child Support', label: 'Child Support' },
    { value: 'Earnings from Employer', label: 'Earnings from Employer' },
    {
      value: 'Earnings from Self-Employment',
      label: 'Earnings from Self-Employment',
    },
    { value: 'Workers Compensation', label: 'Workers Compensation' },
  ]

  const selectTypeOfFuturePays = [
    { value: 'Unemployment', label: 'Unemployment' },
    { value: 'Pension', label: 'Pension' },
    { value: 'Sick/Vacation Pay', label: 'Sick/Vacation Pay' },
    { value: 'Short-Term Disability', label: 'Short-Term Disability' },
    { value: 'Long-Term Disability', label: 'Long-Term Disability' },
    {
      value: 'Income from Rental Properties',
      label: 'Income from Rental Properties',
    },
    { value: 'Income from Farmland', label: 'Income from Farmland' },
    { value: 'Child Support', label: 'Child Support' },
    { value: 'Earnings from Employer', label: 'Earnings from Employer' },
    {
      value: 'Earnings from Self-Employment',
      label: 'Earnings from Self-Employment',
    },
  ]

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'flex-start'}
            sx={{
              py: 2,
              my: 3,
              borderRadius: '10px',
            }}
          >
            <img src={quote} className={Style.star} alt='' />
            <Typography className={Style.Heading}>
              Good work! The next topic is employee-based payouts. This helps us
              understand if you've had any settlements with past employers.
            </Typography>
          </Box>

          {/* Common radio */}
          <Box>
            <CommonRadioBox
              Style={Style}
              title='Have you received money from your employer on or after the date that you became unable to work?'
              commonState={formValues?.haveReceiveMoneyAfterDisability}
              setcommonState={(value: boolean) => {
                setFormValues({
                  ...formValues,
                  haveReceiveMoneyAfterDisability: value,
                })
              }}
              YesTitle='I have received money from my employer on or after the date that I became unable to work'
              NoTitle='I have not received money from my employer on or after the date that I became unable to work'
            />
            <FormHelperText sx={{ mx: 1.5 }}>
              {errors?.haveReceiveMoneyAfterDisability}
            </FormHelperText>
          </Box>

          {formValues.haveReceiveMoneyAfterDisability ? (
            <>
              <Box my={'30px'}>
                <InputLabel className={Style.label} htmlFor='BillingName'>
                  What type of income are you receiving?
                </InputLabel>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Types of Pay Expected (check all that apply)
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <FormControl fullWidth>
                      <StyledSelect
                        id='typeOfPays'
                        name='typeOfPays'
                        // multiple
                        className={Style.AnsTxt}
                        value={formValues?.typeOfPays}
                        renderValue={() => (
                          <Box display='flex' flexWrap='wrap' gap={1}>
                            {formValues?.typeOfPays?.map((value, index) => (
                              <Chip
                                key={index}
                                className={Style.ChipItem}
                                label={value}
                                onMouseDown={(event) => {
                                  event.stopPropagation()
                                }}
                                onDelete={(e) => {
                                  e.stopPropagation()
                                  handleSelectChange(value, 'typeOfPays')
                                  setFormValues((prevState) => ({
                                    ...prevState,
                                    typeOfPays: prevState.typeOfPays.filter(
                                      (item) => item !== value,
                                    ),
                                  }))
                                }}
                                deleteIcon={
                                  <CloseIcon
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      handleSelectChange(value, 'typeOfPays')
                                      setFormValues((prevState) => ({
                                        ...prevState,
                                        typeOfPays: prevState.typeOfPays.filter(
                                          (item) => item !== value,
                                        ),
                                      }))
                                    }}
                                  />
                                }
                              />
                            ))}
                          </Box>
                        )}
                      >
                        <Box sx={{ height: '250px', overflow: 'auto' }}>
                          {selectOptions?.map(
                            (item: OptionsType, index: number) => (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  handleSelectChange(item.value, 'typeOfPays')
                                }}
                                value={item.value}
                              >
                                {item.value}
                              </MenuItem>
                            ),
                          )}
                        </Box>
                      </StyledSelect>
                      <FormHelperText sx={{ mx: 1.5 }}>
                        {errors.typeOfPays}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      If you have other types of pay expected, please explain.
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='otherPayExplain'
                      name='otherPayExplain'
                      multiline
                      rows={4}
                      className={Style.AnsTxt}
                      placeholder='Other'
                      value={formValues?.otherPayExplain}
                      onChange={handleFieldChange}
                      error={!!errors.otherPayExplain}
                      helperText={errors.otherPayExplain}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Approximate Amount Expected From Your Employer (in US
                      dollars).
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='approxAmountExpected'
                      name='approxAmountExpected'
                      className={Style.AnsTxt}
                      placeholder='Enter amount'
                      inputProps={{ maxLength: 12 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                      }}
                      value={formValues?.approxAmountExpected}
                      onChange={(e) => {
                        if (
                          e.target.value.length <= 10 &&
                          /^\d*$/.test(e.target.value)
                        )
                          handleFieldChange(
                            e as React.ChangeEvent<HTMLInputElement>,
                          )
                      }}
                      error={!!errors.approxAmountExpected}
                      helperText={errors.approxAmountExpected}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <></>
          )}

          {/* Common radio */}
          <Box>
            <CommonRadioBox
              Style={Style}
              title='Do you expect to receive any money from your employer in the future?'
              commonState={formValues?.isExpectMoneyInFuture}
              setcommonState={(value: boolean) => {
                setFormValues({ ...formValues, isExpectMoneyInFuture: value })
              }}
              YesTitle='I expect to receive money from my employer in the future'
              NoTitle='I do not expect to receive any money from my employer in the future'
            />
            <FormHelperText sx={{ mx: 1.5 }}>
              {errors?.isExpectMoneyInFuture}
            </FormHelperText>
          </Box>

          {formValues.isExpectMoneyInFuture ? (
            <>
              <Box my={'30px'}>
                <InputLabel className={Style.label} htmlFor='BillingName'>
                  Got it. Please provide some details
                </InputLabel>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Types of Pay Expected (check all that apply).
                      <span style={{ color: 'red' }}>*</span>
                    </InputLabel>
                    <FormControl fullWidth>
                      <StyledSelect
                        id='typeOfFuturePay'
                        name='typeOfFuturePay'
                        multiple
                        className={Style.AnsTxt}
                        value={formValues?.typeOfFuturePay}
                        renderValue={() => (
                          <Box display='flex' flexWrap='wrap' gap={1}>
                            {formValues?.typeOfFuturePay?.map(
                              (value, index) => (
                                <Chip
                                  key={index}
                                  className={Style.ChipItem}
                                  label={value}
                                  onMouseDown={(event) => {
                                    event.stopPropagation()
                                  }}
                                  onDelete={(e) => {
                                    e.stopPropagation()
                                    handleSelectChange(value, 'typeOfFuturePay')
                                    setFormValues((prevState) => ({
                                      ...prevState,
                                      typeOfFuturePay:
                                        prevState.typeOfFuturePay.filter(
                                          (item) => item !== value,
                                        ),
                                    }))
                                  }}
                                  deleteIcon={
                                    <CloseIcon
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleSelectChange(
                                          value,
                                          'typeOfFuturePay',
                                        )
                                        setFormValues((prevState) => ({
                                          ...prevState,
                                          typeOfFuturePay:
                                            prevState.typeOfFuturePay.filter(
                                              (item) => item !== value,
                                            ),
                                        }))
                                      }}
                                    />
                                  }
                                />
                              ),
                            )}
                          </Box>
                        )}
                      >
                        <Box sx={{ height: '250px', overflow: 'auto' }}>
                          {selectTypeOfFuturePays?.map(
                            (item: OptionsType, index: number) => (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  handleSelectChange(
                                    item.value,
                                    'typeOfFuturePay',
                                  )
                                }}
                                value={item.value}
                              >
                                {item.value}
                              </MenuItem>
                            ),
                          )}
                        </Box>
                      </StyledSelect>
                      <FormHelperText sx={{ mx: 1.5 }}>
                        {errors.typeOfFuturePay}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      If you have other types of pay expected, please explain.
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='futureOtherPayExplain'
                      name='futureOtherPayExplain'
                      multiline
                      rows={4}
                      className={Style.AnsTxt}
                      placeholder='Other'
                      value={formValues?.futureOtherPayExplain}
                      onChange={handleFieldChange}
                      error={!!errors.futureOtherPayExplain}
                      helperText={errors.futureOtherPayExplain}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} gap={2} width={'100%'} display={'flex'}>
                  <Grid width={'100%'}>
                    <InputLabel className={Style.label}>
                      Approximate Amount Expected From Your Employer (in US
                      dollars).
                    </InputLabel>
                    <TextField
                      fullWidth
                      type='text'
                      id='approxAmountFuturePayout'
                      name='approxAmountFuturePayout'
                      className={Style.AnsTxt}
                      placeholder='Other'
                      inputProps={{ maxLength: 12 }}
                      value={formValues?.approxAmountFuturePayout}
                      onChange={(e) => {
                        if (
                          e.target.value.length <= 10 &&
                          /^\d*$/.test(e.target.value)
                        )
                          handleFieldChange(
                            e as React.ChangeEvent<HTMLInputElement>,
                          )
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>$</InputAdornment>
                        ),
                      }}
                      error={!!errors.approxAmountFuturePayout}
                      helperText={errors.approxAmountFuturePayout}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <></>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                handleEditToggle()
              }}
              // sx={{ visibility: 'hidden' }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
              // onClick={handleNext}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default PayoutsSettlements
