import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
// import { useState } from 'react'
import remove from 'src/Assets/Icons/remove.svg'
import { Calander, CommonDropdown } from 'src/Components/Fields'
import Style from './index.module.scss'
import { useEffect } from 'react'

const ProfileBox = ({
  formValues,
  errors,
  changeHandler,
  handleRemove,
  index,
  isChecked,
  setIsChecked,
}: {
  formValues: {
    typeOfDuty: string
    branchOfService: string
    startDate: string
    endDate: string
  }
  endDate: Date | null | undefined | any
  selectedDate: Date | null | undefined | any
  handleDateChange: (date: Date | null) => void
  errors: Record<string, string>
  changeHandler: (data: { name: string; value: string | number }) => void
  handleRemove: () => void
  index: number
  isChecked: boolean
  setIsChecked: (value: boolean) => void
}) => {
  // const [isChecked, setIsChecked] = useState(false) // Initialize the state variable

  const handleCheckboxChange = (event: { target: { checked: boolean } }) => {
    setIsChecked(event.target.checked)

    if (event.target.checked) {
      changeHandler({
        name: 'endDate',
        value: '',
      })
    }
  }

  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>
            Military Duty {index + 1}
          </Typography>
          {index > 0 && (
            <img
              src={remove}
              alt=''
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleRemove()
              }}
            />
          )}
        </Grid>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                color='primary'
                className={Style.Check}
                onChange={handleCheckboxChange}
              />
            }
            className={Style.label}
            classes={{ label: Style.label }}
            label='Presently working'
          />
        </FormGroup>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <CommonDropdown
            name='typeOfDuty'
            value={formValues?.typeOfDuty}
            label='Type of Duty'
            menulist={[
              { name: 'Active', value: 'Active' },
              { name: 'Reserve', value: 'Reserve' },
            ]}
            handleChange={changeHandler}
            error={errors?.typeOfDuty}
          />
          <CommonDropdown
            name='branchOfService'
            value={formValues?.branchOfService}
            label='Branch of Service'
            menulist={[
              { name: 'Air Force', value: 'Air Force' },
              { name: 'Army', value: 'Army' },
              { name: 'CGS', value: 'CGS' },
              { name: 'Coast Guard', value: 'Coast Guard' },
              { name: 'Marine Corps', value: 'Marine Corps' },
              { name: 'National Guard', value: 'National Guard' },
              { name: 'Navy', value: 'Navy' },
              { name: 'PHS', value: 'PHS' },
            ]}
            handleChange={changeHandler}
            error={errors?.branchOfService}
          />
        </Grid>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Calander
            name='startDate'
            value={formValues?.startDate}
            title='Start Date'
            handleChange={changeHandler}
            error={errors?.startDate}
          />
          <Calander
            name='endDate'
            value={formValues?.endDate}
            title='End Date'
            handleChange={changeHandler}
            error={errors?.endDate}
            disabled={isChecked}
            minDate={formValues?.startDate}
          />
        </Grid>
      </Box>
    </>
  )
}
export default ProfileBox
