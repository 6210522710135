// This slice manages the state for the sign in feature
import {
  PayloadAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { RoleType } from '../Services/Types/roleType'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { handleResponse } from 'src/Utils/Constants'
import RoleService from '../Services/roleService'
import { thunkAPItype } from '../store'
import { LogoutRequest } from './authSlice'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: RoleType[]
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: [
    {
      roleName: '',
      description: '',
      scope: '',
      permissions: [],
    },
  ],
}

// Define the reducers that can modify the state
const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setRoleLoading: (state) => {
      state.loading = true
    },
    setRoleSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setRoleFail: (state) => {
      state.success = false
      state.loading = false
      state.data = [{}]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewRoleRequest.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewRoleRequest.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(ViewRoleRequest.rejected, (state) => {
      state.success = false
      state.loading = false
    })
    builder.addCase(AddRoleRequest.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddRoleRequest.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddRoleRequest.rejected, (state) => {
      state.success = false
      state.loading = false
    })
    builder.addCase(EditRoleRequest.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      EditRoleRequest.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(EditRoleRequest.rejected, (state) => {
      state.success = false
      state.loading = false
    })
    builder.addCase(LogoutRequest.fulfilled, (state) => {
      state = initialState
    })
  },
})

export const { setRoleLoading, setRoleSuccess, setRoleFail } = roleSlice.actions

// Define the async thunk action creator for sign in
export const ViewRoleRequest = createAsyncThunk(
  'viewRole', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const token = thunkAPI.getState().auth.data?.token
      const response = await RoleService.viewRole(token) // make the API call
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddRoleRequest = createAsyncThunk(
  'addRole', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const token = thunkAPI.getState().auth.data?.token
      const response = await RoleService.addRole(data, token) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditRoleRequest = createAsyncThunk(
  'editRole', // name of the action
  async (
    { uuid, data }: { uuid: string; data: any },
    thunkAPI: thunkAPItype,
  ) => {
    try {
      const { dispatch } = thunkAPI
      const token = thunkAPI.getState().auth.data?.token
      const response = await RoleService.editRole(uuid, data, token) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const DeletRoleRequest = createAsyncThunk(
  'deletRole', // name of the action
  async (data: string, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const token = thunkAPI.getState().auth.data?.token
      const response = await RoleService.deletRole(data, token) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default roleSlice.reducer
