import { LinearProgressProps, Box, LinearProgress } from '@mui/material'
import React from 'react'
import Location from 'src/Assets/Icons/location.svg'
import Users from 'src/Assets/Icons/Users.svg'
import mail from 'src/Assets/Icons/email.svg'
import phone from 'src/Assets/Icons/phone.svg'
import Pending from 'src/Assets/Icons/Pending.svg'
import styles from './index.module.scss'
const Cards: React.FunctionComponent<{
  row: {
    name: string
    basicInfo: {
      emailId: string
      phoneNumber: string
      firstName: string
      address: {
        streetAddress: string
      }
    }
    status: string
  }
}> = ({ row }) => {
  const LinearProgressWithLabel = (
    props: LinearProgressProps & { value: number },
  ) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant='determinate' {...props} />
        </Box>
      </Box>
    )
  }

  return (
    <>
      <div className={styles.manageEmployeeCard}>
        <div className={styles.frame}>
          <div className={styles.div2}>
            <div className={styles.title}>
              <div className={styles.textWrapper}>{row?.applicant}</div>
              <div className={styles.rectangle} />
              <div className={styles.textWrapper2}>SSN : {row?.SSN}</div>
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.div3}>
              <img src={Users} className={styles.iconInstanceNode} />
              <div className={styles.textWrapper3}>
                {row?.basicInfo?.firstName}
              </div>
            </div>
            <div className={styles.div3}>
              <img src={Location} className={styles.iconInstanceNode} />
              <p className={styles.textWrapper3}>
                {row?.basicInfo?.address?.streetAddress}
              </p>
            </div>
            <div className={styles.frame2}>
              <div className={styles.email}>
                <img src={phone} className={styles.iconInstanceNode} />
                <div className={styles.textWrapper3}>
                  {row?.basicInfo?.phoneNumber
                    ? `(${row?.basicInfo?.phoneNumber.slice(0, 3)}) ${row?.basicInfo?.phoneNumber.slice(3, 6)}-${row?.basicInfo?.phoneNumber.slice(6)}`
                    : '-'}
                </div>
              </div>
              <div className={styles.email}>
                <img src={mail} className={styles.iconInstanceNode} />
                <div className={styles.textWrapper3}>
                  {row?.basicInfo?.emailId}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rectangle2} />
          <Box className={styles.div2} gap={1}>
            {/* <img className={styles.user} alt='User' src={employee} /> */}
            <div className={styles.info2}>
              <div className={styles.divWrapper}>
                <div className={styles.textWrapper4}>
                  Application Completion
                </div>
              </div>
              <div className={styles.bar}>
                <div className={styles.holder} />
              </div>
            </div>
            <Box
              className={styles.textWrapper5}
              sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}
            >
              <div className={styles.frame4}>72%</div>
              <Box className={styles.frame3}>
                <img src={Pending} alt='' />
                {row?.status === 'InProgress' ? 'In Progress' : row?.status}
              </Box>
            </Box>
            <LinearProgressWithLabel value={72} />
          </Box>
        </div>
      </div>
    </>
  )
}
export default Cards
