import {
  Box,
  Button,
  FormGroup,
  TextField,
  Typography,
  Grid,
  Divider,
} from '@mui/material'
import { useState, useEffect, ChangeEvent, useRef } from 'react'
import styles from '../index.module.scss'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import {
  OrganizationRequest,
  setDetailedSignUpData,
  setSignUpData,
} from 'src/Redux/Slices/authSlice'
import { RootState } from 'src/Redux/store'
const Organization = ({ handleNext }: { handleNext: () => void }) => {
  const dispatch = useAppDispatch()

  const { SignUpData } = useAppSelector((state: RootState) => state?.auth)

  const [formData, setFormData] = useState<Record<string, string>>({
    orgState: '',
    orgCity: '',
    orgPostalCode: '',
    streetAddress: '',
    description: '',
    name: '',
  })
  const [errors, setErrors] = useState<typeof formData>({})
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    setFormData({
      orgState: SignUpData?.orgState ?? '',
      orgCity: SignUpData?.orgCity ?? '',
      orgPostalCode: SignUpData?.orgPostalCode ?? '',
      streetAddress: SignUpData?.orgStreetAddress ?? '',
      description: SignUpData?.orgDescription ?? '',
      name: SignUpData?.orgName ?? '',
    })
    setFormValues({
      ...formValues,
      streetAddress: SignUpData?.orgStreetAddress ?? '',
    })
  }, [])

  function validateUSZipCode(postalCode: string): boolean {
    // Regular expression for US ZIP codes (5 digits or 5+4 digits with optional hyphen)
    const zipCodePattern = /^\d{4,6}(-\d{4})?$/
    return zipCodePattern.test(postalCode)
  }
  const handleValidation = () => {
    const errorsCopy = { ...errors }
    const { orgPostalCode, streetAddress, description, name } = formData
    errorsCopy.orgState = ''
    errorsCopy.orgCity = ''
    errorsCopy.orgPostalCode = orgPostalCode
      ? validateUSZipCode(orgPostalCode)
        ? ''
        : 'Invalid Zip Code'
      : 'Zip Code is required'
    errorsCopy.streetAddress = formValues.streetAddress
      ? ''
      : 'Street Address is required'
    errorsCopy.description = description ? '' : 'Description is required'
    errorsCopy.name = name
      ? /^[A-Z a-z]+$/.test(name)
        ? ''
        : 'Organization Name should only contain alphabetic characters'
      : 'Name is required'
    setErrors(errorsCopy)
    return errorsCopy
  }
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { id, value } = e.target
    const key = id
    setFormData({ ...formData, [e.target.name]: e.target.value })
    dispatch(setSignUpData({ key, value }))
  }
  const handleContinue = () => {
    const formErrors = handleValidation()
    console.log(errors)
    if (
      formErrors.orgPostalCode ||
      formErrors.streetAddress ||
      formErrors.description ||
      formErrors.name
    ) {
      setIsSubmitted(true)
    } else {
      const object = {
        state: formData.orgState,
        city: formData.orgCity,
        postalCode: formData.orgPostalCode,
        streetAddress: formData.streetAddress,
        description: formData.description,
        name: formData.name,
      }
      dispatch(OrganizationRequest(object)).then((res) => {
        if (res.payload?.success) {
          handleNext()
        } else {
          setIsSubmitted(true)
        }
      })
    }
  }

  const initialObj = {
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  }
  interface Place {
    id: string
    description: string
  }
  const inputRef = useRef()
  const [formValues, setFormValues] = useState(initialObj)
  const [predictions, setPredictions] = useState<Place[]>([])
  const autocomplete = (query: string) => {
    if (query.length === 0) {
      setPredictions([])
      return
    }

    const autocompleteService = new google.maps.places.AutocompleteService()

    autocompleteService.getPlacePredictions(
      { input: query },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const placePredictions: Place[] = predictions.map((prediction) => {
            return {
              description: prediction.description,
              id: prediction.place_id,
            }
          })
          setPredictions(placePredictions)
        }
      },
    )
  }

  function fetchPlaceDetails(placeId: string) {
    const placesService = new google.maps.places.PlacesService(
      document.createElement('div'),
    )
    placesService.getDetails({ placeId }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        // Access address components
        const addressComponents = place.address_components

        if (addressComponents && addressComponents.length > 0) {
          if (place?.address_components) {
            const addressComponents = place.address_components
            const updatedFormValues = { ...formData }
            updatedFormValues.streetAddress = ''
            addressComponents.forEach(
              (component: { types; long_name: string }) => {
                const types = component.types
                if (types.includes('street_number')) {
                  updatedFormValues.streetAddress = component.long_name
                } else if (
                  types.includes('route') ||
                  types.includes('sublocality')
                ) {
                  updatedFormValues.streetAddress += ` ${component.long_name}`
                } else if (types.includes('locality')) {
                  updatedFormValues.orgCity = component.long_name
                } else if (types.includes('administrative_area_level_1')) {
                  updatedFormValues.orgState = component.long_name
                } else if (types.includes('postal_code')) {
                  updatedFormValues.orgPostalCode = component.long_name
                }
              },
            )
            const value = updatedFormValues.streetAddress
            const key = 'orgStreetAddress'
            dispatch(setDetailedSignUpData(updatedFormValues))
            dispatch(setSignUpData({ key, value }))

            setFormValues({
              ...formValues,
              streetAddress: updatedFormValues.streetAddress,
            })

            setFormData(updatedFormValues)
            if (updatedFormValues.streetAddress.length) {
              setPredictions([])
            }
          } else {
            console.error('Invalid place or missing address components')
          }
        }
      }
    })
  }

  useEffect(() => {
    if (!formData?.streetAddress.length) {
      setPredictions([])
    }
  }, [formData])
  // useEffect(() => {
  //   if (!formData?.streetAddress.length) {
  //     const obj = { ...formData }
  //     obj.orgStreetAddress = formValues.streetAddress
  //     setFormData(obj)
  //   }
  // }, [formValues.streetAddress])

  const handleAddressChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target
    if (value.length) {
      setFormValues({
        ...formValues,
        [name]: value,
      })
      setFormData({ ...formData, [name]: value })

      autocomplete(value)
    } else {
      setFormValues(initialObj)
      setPredictions([])
    }
  }

  const handlePredictionClick = (prediction: Place) => {
    fetchPlaceDetails(prediction.id)
    // You can do additional things here, like setting the selected prediction as the input value
  }

  //* ****************************************************************** */

  return (
    <>
      <Box className={styles.title}>
        <Typography className={styles.phrase}>Organization Details</Typography>
        <Typography className={styles.subPhrase}>
          Please fill in the following organization details.
        </Typography>
      </Box>
      <Box className={styles.form}>
        <FormGroup className={styles.fieldsWrapper}>
          <Box className={styles.fields}>
            <Box className={styles.flexStartBox}>
              <Typography
                component={'span'}
                className={styles.textFieldWrapper}
              >
                Organization Name
                <Typography className={styles.FF002E}>*</Typography>
              </Typography>
              <TextField
                fullWidth
                variant='outlined'
                className={styles.field}
                name='name'
                id='orgName'
                placeholder='Enter your organization Name'
                value={formData.name}
                onChange={(e) => {
                  handleChange(e)
                }}
                error={!!errors.name && isSubmitted}
                helperText={isSubmitted ? errors.name : ''}
              />
            </Box>
            <Box className={styles.flexStartBox}>
              <Typography
                component={'span'}
                className={styles.textFieldWrapper}
              >
                Street Address
                <Typography className={styles.FF002E}>*</Typography>
              </Typography>
              <TextField
                fullWidth
                variant='outlined'
                className={styles.field}
                name='streetAddress'
                id='orgStreetAddress'
                inputRef={inputRef}
                placeholder='Enter your street address'
                value={formValues?.streetAddress}
                onChange={handleAddressChange}
                error={!!errors.streetAddress && isSubmitted}
                helperText={isSubmitted ? errors.streetAddress : ''}
              />
              {predictions.length ? (
                <>
                  <Grid item className={styles.listBox}>
                    {predictions.map((prediction, index) => (
                      <>
                        <div
                          key={index}
                          className={styles.listItem}
                          onClick={() => {
                            handlePredictionClick(prediction)
                          }}
                        >
                          {prediction.description}
                        </div>
                        <Divider />
                      </>
                    ))}
                  </Grid>
                </>
              ) : null}
            </Box>
            <Box className={styles.flexStartBox}>
              <Typography
                component={'span'}
                className={styles.textFieldWrapper}
              >
                Zip Code
                <Typography className={styles.FF002E}>*</Typography>
              </Typography>
              <TextField
                fullWidth
                variant='outlined'
                className={styles.field}
                inputProps={{ maxLength: 6 }}
                name='orgPostalCode'
                id='orgPostalCode'
                placeholder='Enter your zip code'
                value={formData.orgPostalCode}
                onChange={(e) => {
                  handleChange(e)
                }}
                error={!!errors.orgPostalCode && isSubmitted}
                helperText={isSubmitted ? errors.orgPostalCode : ''}
              />
            </Box>
            <Box className={styles.flexSpaceBox}>
              <Box className={styles.fullWidth}>
                <Typography
                  component={'span'}
                  className={styles.textFieldWrapper}
                >
                  State
                </Typography>

                <TextField
                  fullWidth
                  variant='outlined'
                  className={styles.field}
                  name='orgState'
                  id='orgState'
                  placeholder='Enter your state'
                  value={formData.orgState}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                />
              </Box>
              <Box className={styles.fullWidth}>
                <Typography
                  component={'span'}
                  className={styles.textFieldWrapper}
                >
                  City
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  className={styles.field}
                  name='orgCity'
                  id='orgCity'
                  placeholder='Enter your city'
                  value={formData.orgCity}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                />
                {/* <Select
                  className={styles.field}
                  IconComponent={(props) => (
                    <IconButton {...props}>
                      <img src={IconChevronDown} alt='IconChevronDown' />
                    </IconButton>
                  )}
                  displayEmpty
                  defaultValue={SignUpData?.orgCity ?? ''}
                  name='city'
                  id='orgCity'
                  value={formData.orgCity}
                  onChange={(e) => {
                    handleSelectChange(e, 'orgCity')
                  }}
                  size='small'
                  style={{ minWidth: '190px' }}
                >
                  <MenuItem value=''>
                    <em style={{ color: COLORS.COLOR_DARK_GRAY }}>Select</em>
                  </MenuItem>
                  {City.getCitiesOfState('US', formData.orgState).map(
                    (city) => (
                      <MenuItem key={city.name} value={city.name}>
                        {city.name}
                      </MenuItem>
                    ),
                  )}
                </Select> */}
              </Box>
            </Box>
            <Box className={styles.flexStartBox}>
              <Typography
                component={'span'}
                className={styles.textFieldWrapper}
              >
                Organization Description
                <Typography className={styles.FF002E}>*</Typography>
              </Typography>
              <TextField
                multiline
                id='orgDescription'
                rows={4}
                fullWidth
                variant='outlined'
                className={styles.field}
                name='description'
                placeholder='Enter your organization description'
                value={formData.description}
                onChange={(e) => {
                  handleChange(e)
                }}
                error={!!errors.description && isSubmitted}
                helperText={isSubmitted ? errors.description : ''}
              />
            </Box>
          </Box>
        </FormGroup>
      </Box>
      <Button onClick={handleContinue} className={styles.button}>
        <Typography className={styles.mediumText}>Continue</Typography>
      </Button>
    </>
  )
}
export default Organization
