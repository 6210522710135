import { Box, Grid, Typography } from '@mui/material'
import { type FC } from 'react'
import dollar from 'src/Assets/Icons/dollar.svg'
import Style from './index.module.scss'
interface CardData {
  index: number
  Icon: string
  count: number | bigint | any
  Description: string
}
const Card: FC<CardData> = ({ Icon, count, Description, index }) => {
  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
    .format(count)
    .replace('$', '')
    .split('.')[0]
  return (
    <Box className={Style.CardBox}>
      <Box className={Style.ImgBox}>
        {index === 2 || index === 3 ? (
          <>
            <Box className={Style.ImgBoxTwo}>
              <img src={dollar} alt='dollar' />
              <Typography variant='h4' className={Style.ImgText}>
                {formattedAmount}
              </Typography>
            </Box>
          </>
        ) : (
          <Typography variant='h4' className={Style.ImgText}>
            {count}
          </Typography>
        )}
        <img src={Icon} alt='organization' />
      </Box>
      <Grid>
        <Typography variant='h2' className={Style.ImgTextTwo}>
          {Description}
        </Typography>
      </Grid>
    </Box>
  )
}

export default Card
