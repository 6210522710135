import * as React from 'react'
import {
  Box,
  Avatar,
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  CircularProgress,
  Grid,
} from '@mui/material'
import { ExpandLess, ExpandMore, Add } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/Utils/Routes'
import { Icons, LegalPermissions, MileStoneFour } from 'src/Utils/Constants'
import { useAppSelector } from 'src/Redux/hooks'
import Style from 'src/Components/Sidebar/index.module.scss'
import AvatarIcon from 'src/Assets/Icons/Avatar.svg'
import ProfilePhoto from 'src/Assets/Icons/ProfilePhoto.svg'
import ActiveStep from 'src/Assets/Icons/MangeEmployeeActive.svg'
import InactiveStep from 'src/Assets/Icons/MangeEmployeeInactive.svg'
import GenAppDialogue from 'src/Pages/Applications/GenerateApplication'

export default function LegalSidebar() {
  const [openEmployee, setOpenEmployee] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const handleEmployeeClick = () => {
    setOpenEmployee(!openEmployee)
  }
  // const data = useAppSelector((state) => state.auth.data)
  const viewProfile = useAppSelector((state) => state.profile.data)
  const viewUserData = useAppSelector((state) => state.auth.data)

  const UserPermission = useAppSelector((state) => state.permissions?.data)
  const [openDialog, setOpenDialog] = React.useState(false)

  return (
    <Box className={Style.mainSidebarBox} flexShrink={{ lg: 0 }}>
      <Grid className={Style.SubSidebarBox} flexShrink={{ lg: 0 }}>
        <Box className={Style.AvtarBox}>
          <Avatar
            className={Style.avatarImage}
            src={
              viewProfile?.profileUrl ||
              viewUserData?.profileUrl ||
              ProfilePhoto
            }
            alt='photoURL'
          />
          <CircularProgress
            variant='determinate'
            value={55}
            size={60}
            thickness={2.5}
            className={Style.avatarProgress}
          />
        </Box>

        <Box sx={{ ml: 2 }}>
          <Typography className={Style.userName}>
            {viewProfile?.firstName || viewUserData?.firstName}
          </Typography>
          <Typography className={Style.userRole}>
            {viewProfile?.organizationName ||
              viewUserData?.OrganizationData?.name}
          </Typography>
        </Box>
      </Grid>

      <Grid className={Style.sidebarBox} flexShrink={{ lg: 0 }}>
        <Grid gap={30} className={Style.listBox} flexShrink={{ lg: 0 }}>
          <List
            sx={{
              gap: 35,
            }}
            component='nav'
            aria-labelledby='nested-list-subheader'
          >
            {/* dashboard */}
            {UserPermission?.includes(LegalPermissions.LEGAL_DASHBOARD) && (
              <>
                <ListItemButton
                  className={
                    location.pathname === ROUTES.DASHBOARD
                      ? Style.Active
                      : Style.listItemBtn
                  }
                  onClick={() => {
                    navigate(ROUTES.DASHBOARD)
                  }}
                >
                  <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                    <img
                      height='20px'
                      width='20px'
                      src={Icons.dashboard}
                      alt='dashboard'
                    />
                  </Box>
                  <ListItemText
                    sx={{ textAlign: 'start' }}
                    primary='dashboard'
                  />
                </ListItemButton>
              </>
            )}

            {/* Employees */}
            {UserPermission?.includes(LegalPermissions.EMPLOYEES) &&
            UserPermission?.includes(
              LegalPermissions.ROLE_AND_PERMISSION_MAP,
            ) ? (
              <>
                <ListItemButton
                  className={
                    location.pathname === ROUTES.LEGAL_MANAGE_EMPLOYEES ||
                    location.pathname === ROUTES.ROLE
                      ? Style.Active
                      : Style.listItemBtn
                  }
                  onClick={() => {
                    navigate(ROUTES.LEGAL_MANAGE_EMPLOYEES)
                    handleEmployeeClick()
                  }}
                >
                  <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                    <img
                      height='20px'
                      width='20px'
                      src={Icons.employee}
                      alt='employee'
                    />
                  </Box>
                  <ListItemText
                    sx={{ textAlign: 'start' }}
                    primary='Employees'
                  />
                  {openEmployee ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openEmployee} timeout='auto' unmountOnExit>
                  <List
                    className={
                      location.pathname === ROUTES.LEGAL_MANAGE_EMPLOYEES ||
                      location.pathname === ROUTES.LEGAL_EMPLOYEE_DETAIL
                        ? Style.Active
                        : Style.listItemBtn
                    }
                    sx={{ ml: 4 }}
                    component='div'
                    disablePadding
                  >
                    <Box
                      className={Style.stepBox}
                      zIndex={
                        location.pathname === ROUTES.LEGAL_MANAGE_EMPLOYEES ||
                        location.pathname === ROUTES.LEGAL_EMPLOYEE_DETAIL
                          ? 1
                          : 0
                      }
                      top={'-10px'}
                    >
                      <img src={ActiveStep} alt='|' />
                    </Box>
                    <ListItemButton
                      onClick={() => {
                        navigate(ROUTES.LEGAL_MANAGE_EMPLOYEES)
                      }}
                      className={
                        location.pathname === ROUTES.LEGAL_MANAGE_EMPLOYEES ||
                        location.pathname === ROUTES.LEGAL_EMPLOYEE_DETAIL
                          ? Style.subActive
                          : Style.sublistItemBtn
                      }
                    >
                      <ListItemText
                        sx={{ textAlign: 'start' }}
                        primary='Manage Employees'
                      />
                    </ListItemButton>
                  </List>
                </Collapse>

                <Collapse in={openEmployee} timeout='auto' unmountOnExit>
                  <List
                    className={
                      location.pathname === ROUTES.ROLE ||
                      location.pathname === ROUTES.ROLE_DETAILS
                        ? Style.Active
                        : Style.listItemBtn
                    }
                    sx={{ ml: 4 }}
                    component='div'
                    disablePadding
                  >
                    <Box
                      className={Style.stepBox}
                      zIndex={
                        location.pathname === ROUTES.ROLE ||
                        location.pathname === ROUTES.ROLE_DETAILS
                          ? 1
                          : 0
                      }
                      top={'-55px'}
                    >
                      <img src={InactiveStep} alt='|' />
                    </Box>
                    <ListItemButton
                      onClick={() => {
                        navigate(ROUTES.ROLE)
                      }}
                      className={
                        location.pathname === ROUTES.ROLE ||
                        location.pathname === ROUTES.ROLE_DETAILS
                          ? Style.subActive
                          : Style.sublistItemBtn
                      }
                    >
                      <ListItemText
                        sx={{ textAlign: 'start' }}
                        primary='Role & Permissions'
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            ) : UserPermission?.includes(LegalPermissions.EMPLOYEES) ? (
              <>
                <ListItemButton
                  className={
                    location.pathname === ROUTES.LEGAL_MANAGE_EMPLOYEES ||
                    location.pathname === ROUTES.LEGAL_EMPLOYEE_DETAIL
                      ? Style.Active
                      : Style.listItemBtn
                  }
                  onClick={() => {
                    navigate(ROUTES.LEGAL_MANAGE_EMPLOYEES)
                  }}
                >
                  <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                    <img
                      height='20px'
                      width='20px'
                      src={Icons.employee}
                      alt='employee'
                    />
                  </Box>
                  <ListItemText
                    sx={{ textAlign: 'start' }}
                    primary='Manage Employees'
                  />
                </ListItemButton>
              </>
            ) : (
              <></>
            )}

            {!UserPermission?.includes(LegalPermissions.EMPLOYEES) &&
              UserPermission?.includes(
                LegalPermissions.ROLE_AND_PERMISSION_MAP,
              ) && (
                <>
                  <ListItemButton
                    className={
                      location.pathname === ROUTES.ROLE ||
                      location.pathname === ROUTES.ROLE_DETAILS
                        ? Style.Active
                        : Style.listItemBtn
                    }
                    onClick={() => {
                      navigate(ROUTES.ROLE)
                    }}
                  >
                    <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                      <img
                        height='20px'
                        width='20px'
                        src={Icons.employee}
                        alt='employee'
                      />
                    </Box>
                    <ListItemText
                      sx={{ textAlign: 'start' }}
                      primary='Role & Permissions'
                    />
                  </ListItemButton>
                </>
              )}

            {/* Applications */}
            {UserPermission?.includes(LegalPermissions.APPLICATIONS) && (
              <>
                <ListItemButton
                  className={
                    location.pathname === ROUTES.APPLICATIONS
                      ? Style.Active
                      : Style.listItemBtn
                  }
                  onClick={() => {
                    navigate(ROUTES.APPLICATIONS)
                  }}
                >
                  <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                    <img
                      height='20px'
                      width='20px'
                      src={Icons.application}
                      alt='p'
                    />
                  </Box>
                  <ListItemText
                    sx={{ textAlign: 'start' }}
                    primary='Applications'
                  />
                </ListItemButton>
              </>
            )}

            {/* Subscription Plan */}
            {UserPermission?.includes(
              LegalPermissions.ENHANCED_SUBSCRIPTIONS,
            ) && (
              <>
                <ListItemButton
                  className={
                    location.pathname === ROUTES.ENHANCED_PLAN
                      ? Style.Active
                      : Style.listItemBtn
                  }
                  onClick={() => {
                    navigate(ROUTES.ENHANCED_PLAN)
                  }}
                >
                  <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                    <img
                      height='20px'
                      width='20px'
                      src={Icons.subscription}
                      alt='employee'
                    />
                  </Box>
                  <ListItemText
                    sx={{ textAlign: 'start' }}
                    primary='Subscription Plan'
                  />
                </ListItemButton>
              </>
            )}

            {/* Notification */}
            {UserPermission.includes(LegalPermissions.NOTIFICATION) &&
              process.env.REACT_APP_MILESTONE_4 && (
                <>
                  <ListItemButton
                    className={
                      location.pathname === ROUTES.NOTIFICATION
                        ? Style.Active
                        : Style.listItemBtn
                    }
                    onClick={() => {
                      navigate(ROUTES.NOTIFICATION)
                      // dispatch(ViewRoleRequest())
                      // dispatch(ViewPermissionRequest())
                    }}
                  >
                    <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
                      <img
                        height='20px'
                        width='20px'
                        src={Icons.notification}
                        alt='setting'
                      />
                    </Box>
                    <ListItemText
                      sx={{ textAlign: 'start' }}
                      primary='Notification'
                    />
                  </ListItemButton>
                </>
              )}
          </List>
        </Grid>

        {UserPermission?.includes(LegalPermissions.APPLICATIONS) && (
          <>
            <Grid className={Style.subContainer}>
              <Box>
                <img src={AvatarIcon} alt='' />
              </Box>
              <Grid sx={{ textAlign: 'left' }}>
                <Typography variant='body2' className={Style.subContainerText}>
                  Start a new application now!
                </Typography>
              </Grid>
              <Grid py={1} className={Style.subContainerGrid}>
                {process.env.REACT_APP_MILESTONE_4 ? (
                  <>
                    <Button
                      className={Style.subContainerButton}
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setOpenDialog(true)
                      }}
                    >
                      New Application
                      <Add sx={{ fontSize: 'medium', ml: 2 }} />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className={Style.subContainerButton}
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        navigate(ROUTES.WELCOME_PAGE)
                      }}
                    >
                      New Application
                      <Add sx={{ fontSize: 'medium', ml: 2 }} />
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {openDialog && (
        <GenAppDialogue openDialog={openDialog} setOpenDialog={setOpenDialog} />
      )}
    </Box>
  )
}
