import React from 'react'

import DisabilityBackground from './DisabilityBackground/index'
import DisabilityEmployment from './DisabilityEmployment/index'
import PayoutSettlement from './PayoutSettlements/index'

const DisabilityDetails: React.FunctionComponent = () => {
  return (
    <>
      <DisabilityBackground />
      <DisabilityEmployment />
      <PayoutSettlement />
    </>
  )
}

export default DisabilityDetails
