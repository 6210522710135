// This slice manages the state for the sign in feature
import {
  PayloadAction,
  ThunkDispatch,
  UnknownAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import secureLocalStorage from 'react-secure-storage'
import {} from 'src/Redux/Services/Types/applicationType'
import { UploadDocumentType } from 'src/Redux/Services/Types/uploadDocumentType'
import UploadDocumentService from 'src/Redux/Services/uploadDocumentsService'
import { RootState } from 'src/Redux/store'
import { handleResponse } from 'src/Utils/Constants'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: UploadDocumentType
}
const initialState: stateType = {
  success: false, // has the sign in been successful?
  loading: false, // is the sign in request currently being processed?
  data: {
    employerW2: [],
    marriage: [],
    address: [],
    birth: [],
    employment: [],
    educationOrTraining: [],
    citizenship: [],
  },
}

// Define the reducers that can modify the state
const UploadDocument = createSlice({
  name: 'UploadDocument',
  initialState,
  reducers: {
    setUploadDocumentLoading: (state) => {
      state.loading = true
    },
    setUploadDocumentSuccess: (state, action) => {
      state.success = true
      state.loading = false
      state.data = action.payload
    },
    setUploadDocumentFail: (state) => {
      state.success = false
      state.loading = false
      state.data = initialState.data
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AddUploadDocument.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      AddUploadDocument.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data = action.payload?.data
      },
    )
    builder.addCase(AddUploadDocument.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

export const {
  setUploadDocumentLoading,
  setUploadDocumentSuccess,
  setUploadDocumentFail,
} = UploadDocument.actions

export const GetUploadDocument = createAsyncThunk(
  'GetUploadDocument', // name of the action
  async (
    documentId: string,
    thunkAPI: {
      getState: () => RootState
    },
  ) => {
    try {
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await UploadDocumentService.GetUploadDocumennt(
        documentId,
        token,
        isPublic,
      ) // make the API call
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const GetSignedConentUploadDocument = createAsyncThunk(
  'GetSignedConentUploadDocument', // name of the action
  async (
    documentId: string,
    thunkAPI: {
      getState: () => RootState
    },
  ) => {
    try {
      // const docId = '2f40dd96-493b-491b-a303-ce94d74ee6ef'

      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await UploadDocumentService.GetUploadDocumennt(
        documentId,
        // docId,
        token,
        isPublic,
      ) // make the API call
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const AddUploadDocument = createAsyncThunk(
  'AddUploadDocument', // name of the action
  async (
    data: FormData,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await UploadDocumentService.AddUploadDocumennt(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const EditUploadDocument = createAsyncThunk(
  'UploadDocument', // name of the action
  async (
    data: FormData,
    thunkAPI: {
      getState: () => RootState
      dispatch: ThunkDispatch<unknown, unknown, UnknownAction>
    },
  ) => {
    try {
      const { dispatch } = thunkAPI
      const { isPublic } = thunkAPI.getState().applicationStore.ApplicationId
      const token = secureLocalStorage.getItem('token') as string
      const response = await UploadDocumentService.EditUploadDocumennt(
        data,
        token,
        isPublic,
      ) // make the API call
      handleResponse({ response, dispatch })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default UploadDocument.reducer
