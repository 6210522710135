import {
  Box,
  Button,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import {
  CodeVerifyRequest,
  EmailVerifyRequest,
  SignUpRequest,
  setSignUpData,
} from 'src/Redux/Slices/authSlice'
import { PayloadAction } from '@reduxjs/toolkit'

import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import styles from '../index.module.scss'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { RootState } from 'src/Redux/store'
import { useLocation } from 'react-router-dom'

const AccountInfo = ({ handleNext }: { handleNext: () => void }) => {
  const { SignUpData } = useAppSelector((state: RootState) => state?.auth)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [isRegistered, setIsRegistered] = useState<boolean>(false)
  const [isDisable, setIsDisable] = useState<boolean>(false)
  const [isDisableButton, setIsDisableButton] = useState<boolean>(true)

  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(true)
  const location = useLocation()

  interface stateType {}

  interface SerializedError {}
  interface PayloadType {
    warn?: boolean | object
    success?: boolean | object
  }

  useEffect(() => {
    setEmail(SignUpData?.emailId ?? '')
    // dispatch(setSignUpData({ key:'subUuid', value:location.search }))
    console.log(location)

    // setPassword(SignUpData?.password ?? '')
    // setConfirmPassword(SignUpData?.codeValidation ?? '')
    // setCode(SignUpData?.code ?? '')
  }, [])
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword)
  }
  const dispatch = useAppDispatch()
  const calculateStrength = (password: string) => {
    let strength = 0
    if (password.length > 5) strength++
    if (password.match(/[a-z]+/) && password.match(/[A-Z]+/)) strength++
    if (password.match(/[0-9]+/)) strength++
    if (password.match(/[$@#&!]+/)) strength++
    return strength
  }
  const strength = calculateStrength(password)
  const emailValidation = !/^\S+@\S+\.\S+$/.test(email)
  const codeValidation = code.length !== 6

  const isValidEmail = (email: string): boolean => {
    setIsSubmitted(false)
    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  useEffect(() => {
    const key = 'isRegistered'
    const value = isRegistered
    dispatch(setSignUpData({ key, value }))
  }, [isRegistered])

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { id, value } = event.target
    const key = id

    setPasswordMatch(true)
    dispatch(setSignUpData({ key, value }))
  }
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isValidEmail(email)) {
        dispatch(EmailVerifyRequest(email)).then(
          (
            res:
              | PayloadAction<
                  stateType,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'fulfilled'
                  },
                  never
                >
              | PayloadAction<
                  unknown,
                  string,
                  {
                    arg: any
                    requestId: string
                    requestStatus: 'rejected'
                    aborted: boolean
                    condition: boolean
                  } & ({} | ({} & {})),
                  SerializedError
                >,
          ) => {
            const payload = res.payload as PayloadType

            if (payload?.warn) {
              setIsRegistered(true)
            } else if (payload?.success) {
              setIsRegistered(false)
            }
            setIsDisable(false)
          },
        )
      }
    }, 3000)

    return () => {
      setIsDisable(true)
      clearTimeout(delayDebounceFn)
    }
  }, [email])

  useEffect(() => {
    if (isRegistered) {
      if (isValidEmail(email) && code.length > 0) {
        setIsDisableButton(false)
      } else {
        setIsDisableButton(true)
      }
    } else {
      if (isValidEmail(email) && strength >= 4 && passwordMatch) {
        setIsDisableButton(false)
      } else {
        setIsDisableButton(true)
      }
    }
  }, [email, code, password, confirmPassword])
  const handleContinue = () => {
    if (password !== confirmPassword) setPasswordMatch(false)
    if (isRegistered && passwordMatch) {
      if (emailValidation || codeValidation) {
        setIsSubmitted(true)
        return
      }
      const loginData = {
        emailId: email,
        otp: code,
      }

      dispatch(CodeVerifyRequest(loginData)).then(
        (
          res:
            | PayloadAction<
                stateType,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'fulfilled'
                },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & ({} | ({} & {})),
                SerializedError
              >,
        ) => {
          const payload = res.payload as PayloadType

          if (payload?.success) {
            handleNext()
          } else {
            setIsSubmitted(true)
          }
        },
      )
    } else {
      if (emailValidation || strength !== 4 || password !== confirmPassword) {
        setIsSubmitted(true)
        return
      }
      const loginData = {
        emailId: email,
        password,
      }
      dispatch(SignUpRequest(loginData)).then(
        (
          res:
            | PayloadAction<
                stateType,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'fulfilled'
                },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & ({} | ({} & {})),
                SerializedError
              >,
        ) => {
          const payload = res.payload as PayloadType

          if (payload?.success) {
            handleNext()
          } else {
            setIsSubmitted(true)
          }
        },
      )
    }
  }
  return (
    <>
      {/* {loading && <Loader />} */}
      <Box className={styles.title}>
        <Typography className={styles.phrase}>Account Information</Typography>
        <Typography className={styles.subPhrase}>
          Please fill in the following account information.
        </Typography>
      </Box>
      <Box className={styles.form}>
        <FormGroup className={styles.fieldsWrapper}>
          <Box className={styles.fields}>
            <Box className={styles.flexStartBox}>
              <Typography className={styles.textFieldWrapper}>
                Email Address
              </Typography>
              <TextField
                fullWidth
                id='emailId'
                variant='outlined'
                className={styles.field}
                placeholder='Enter your email'
                onChange={(e) => {
                  setIsSubmitted(false)
                  setEmail(e.target.value)
                  handleTextChange(e)
                }}
                value={email}
                error={emailValidation && email.length > 0}
                helperText={
                  emailValidation && email.length
                    ? 'Please enter a valid email address'
                    : undefined
                }
              />
            </Box>
            {isRegistered ? (
              <>
                <Box className={styles.flexStartBox}>
                  <Typography className={styles.textFieldWrapper}>
                    Verification Code
                  </Typography>
                  <TextField
                    fullWidth
                    id='code'
                    variant='outlined'
                    className={styles.field}
                    onChange={(e) => {
                      setIsSubmitted(false)
                      setCode(e.target.value)
                      handleTextChange(e)
                    }}
                    value={code}
                    error={isSubmitted && codeValidation}
                    helperText={
                      isSubmitted && codeValidation
                        ? 'Please enter a valid code'
                        : undefined
                    }
                    type='number'
                    disabled={isDisable}
                    required
                  />
                </Box>
              </>
            ) : (
              <>
                <Box className={styles.flexStartBox}>
                  <Typography className={styles.textFieldWrapper}>
                    Password
                  </Typography>
                  <TextField
                    fullWidth
                    id='password'
                    variant='outlined'
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge='end'
                          >
                            {!showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={styles.field}
                    onChange={(e) => {
                      setIsSubmitted(false)
                      setPassword(e.target.value)
                      handleTextChange(e)
                    }}
                    disabled={isDisable}
                    placeholder='Enter your password'
                    value={password}
                    error={isSubmitted && strength !== 4}
                  />
                  <Box className={styles.validationBar}>
                    <Box
                      className={
                        strength >= 1 ? styles.validatorred : styles.validator
                      }
                    />
                    <Box
                      className={
                        strength >= 2
                          ? styles.validatororange
                          : styles.validator
                      }
                    />
                    <Box
                      className={
                        strength >= 3
                          ? styles.validatoryellow
                          : styles.validator
                      }
                    />
                    <Box
                      className={
                        strength >= 4 ? styles.validatorgreen : styles.validator
                      }
                    />
                  </Box>
                  <Box className={styles.flexSpaceBox}>
                    {isSubmitted && strength !== 4 && (
                      <Typography className={styles.useOrMore}>
                        Use 8 or more characters with a mix of letters, numbers
                        &amp; symbols.
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box className={styles.flexStartBox}>
                  <Typography className={styles.textFieldWrapper}>
                    Repeat Password
                  </Typography>
                  <TextField
                    fullWidth
                    variant='outlined'
                    id='confirmPassword'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={toggleRepeatPasswordVisibility}
                            edge='end'
                          >
                            {!showRepeatPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    type={showRepeatPassword ? 'text' : 'password'}
                    error={!passwordMatch}
                    onChange={(e) => {
                      setIsSubmitted(false)
                      setConfirmPassword(e.target.value)
                      handleTextChange(e)
                    }}
                    className={styles.field}
                    disabled={isDisable}
                    placeholder='Repeat your password'
                  />
                  {!passwordMatch && (
                    <Typography className={styles.useOrMore}>
                      Passwords do not match
                    </Typography>
                  )}
                </Box>
              </>
            )}
            <Box className={styles.terms}>
              <Typography className={styles.byClickingContinue}>
                <span className={styles.span}>
                  By clicking continue, you agree to the BenefitsBoost{' '}
                </span>
                <a
                  href='https://benefitsboost.vercel.app/terms-and-conditions'
                  target='_blank'
                  rel='noopener noreferrer'
                  className={styles.underline}
                >
                  User Agreement
                </a>
                <span className={styles.span}>, </span>
                <a
                  href='https://benefitsboost.vercel.app/privacy-policy'
                  target='_blank'
                  rel='noopener noreferrer'
                  className={styles.underline}
                >
                  Privacy Policy
                </a>
                <span className={styles.span}>, and </span>
                <a
                  href='https://benefitsboost.vercel.app/terms-and-conditions'
                  target='_blank'
                  rel='noopener noreferrer'
                  className={styles.underline}
                >
                  Cookie Policy
                </a>
                <span className={styles.span}>.</span>
              </Typography>
            </Box>
          </Box>
        </FormGroup>
      </Box>
      <Button
        onClick={() => {
          handleContinue()
        }}
        disabled={isDisableButton}
        className={styles.button}
      >
        <Typography className={styles.mediumText}>Continue</Typography>
      </Button>
    </>
  )
}

export default AccountInfo
