import React, { useEffect, useState } from 'react'
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  Box,
  Typography,
  DialogContent,
  TextField,
  Grid,
  Avatar,
} from '@mui/material'
import SearchIcon from 'src/Assets/Icons/searchLg.svg'
import { DynamicObj, Icons } from 'src/Utils/Constants'
import Style from './index.module.scss'
import { useAppDispatch } from 'src/Redux/hooks'
import { setIsDisqualified } from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import {
  AssignEmployeeToApplication,
  getAllApplicationEmployeeList,
  getAllApplicationList,
} from 'src/Redux/Slices/ApplicationSlices/allApplicationSlice'

interface AssignEmpProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleOpen: () => void
  data: DynamicObj
}

interface Employee {
  firstName: string
  lastName: string
  // Add other properties if necessary
}

const AssignEmp: React.FC<AssignEmpProps> = ({
  open,
  setOpen,
  handleOpen,
  data,
}) => {
  const dispatch = useAppDispatch()
  const handleClose = () => {
    setOpen(false)
    dispatch(setIsDisqualified(false))
  }

  const searchParam = ['firstName', 'lastName']
  const [q, setQ] = React.useState('')

  const [empList, setEmpList] = useState<Employee[]>([])
  const [selectedEmp, setSelectedEmp] = useState({
    applicationId: '',
    employeeId: '',
  })

  useEffect(() => {
    setSelectedEmp((prevState) => ({
      ...prevState,
      applicationId: data?.uuid,
    }))
  }, [data])

  useEffect(() => {
    dispatch(getAllApplicationEmployeeList()).then((res: any) => {
      if (res?.payload?.success) {
        setEmpList(res.payload.data)
      }
    })
  }, [dispatch])

  const handleClick = (id: string) => {
    setSelectedEmp((prevState) => ({
      ...prevState,
      employeeId: id,
    }))
  }
  function find(items: object[]) {
    return items?.filter((item: DynamicObj) => {
      return searchParam?.some((newItem: string) => {
        return item[newItem]?.toString().toLowerCase().includes(q.toLowerCase())
      })
    })
  }
  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={open}
        onClose={handleClose}
      >
        <Box className={Style.MainBox}>
          <DialogTitle className={Style.DialogTitle}>
            <Box className={Style.TitileBox}>
              <Typography variant='h5' className={Style.titleText}>
                Assign Employee
                <img
                  onClick={handleClose}
                  src={Icons.Cancel}
                  alt='Cancel'
                  height={'26px'}
                  width={'26px'}
                />
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              maxHeight: '60vh', // Set the maximum height for the content
              overflowY: 'auto', // Enable vertical scrolling
            }}
          >
            <Grid sx={{ textAlign: 'center', justifyContent: 'center' }}>
              <TextField
                className={Style.search}
                placeholder='Search application'
                variant='outlined'
                value={q}
                onChange={(e: { target: { value: string } }) => {
                  setQ(e.target.value)
                }}
                InputProps={{
                  startAdornment: (
                    <img
                      width='16px'
                      height='16px'
                      src={SearchIcon}
                      alt='Search'
                    />
                  ),
                }}
                sx={{
                  marginTop: '16px',
                  marginBottom: '16px',
                  '& fieldset': { borderColor: '#c8c9cc' },
                  '& .MuiInputBase-root': {
                    height: '40px',
                    backgroundColor: '#f2f4f8',
                    paddingLeft: '15px',
                    borderRadius: '10px',
                  },
                  '& .MuiInputBase-input': {
                    paddingLeft: '6px',
                    color: '#9d9fa4',
                  },
                }}
              />
            </Grid>
            <Grid container direction='column' spacing={1}>
              {empList
                ? find(empList).map((item: any, index) => (
                    <Grid
                      item
                      key={index}
                      onClick={() => {
                        handleClick(item?.userUuid)
                      }}
                    >
                      <Box
                        className={
                          item?.userUuid === selectedEmp?.employeeId
                            ? Style.AvatarBoxSelected
                            : Style.AvatarBox
                        }
                        my={2}
                      >
                        <Avatar
                          src={item?.profileURL}
                          alt='Profile Image'
                          sx={{ width: 50, height: 50 }}
                        />
                        <Box className={Style.Box1}>
                          <Box className={Style.Box2}>
                            <Typography
                              variant='body1'
                              className={Style.semiBoldText}
                            >
                              {`${item?.firstName} ${item?.lastName}`}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <div className={Style.dottedLine} />
                    </Grid>
                  ))
                : null}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid lg={12}></Grid>
            <Box className={Style.AddButton}>
              <Button
                className={Style.Button}
                onClick={() => {
                  dispatch(AssignEmployeeToApplication(selectedEmp))
                  dispatch(getAllApplicationList({}))
                  handleClose()
                }}
              >
                <Typography variant='button'>Assign</Typography>
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

export default AssignEmp
