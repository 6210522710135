// This slice manages the state for the sign in feature
import {
  PayloadAction,
  // AsyncThunk,
  // PayloadAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
// import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import DashboardService from '../Services/DashboardService'
import { thunkAPItype } from '../store'
import { handleResponse } from 'src/Utils/Constants'

// Define the initial state of the slice
interface stateType {
  success: boolean
  loading: boolean
  data: any
}
const initialState: stateType = {
  success: false,
  loading: false,
  data: {
    totalOrganization: {},
    latestOrganization: {},
    newOrganization: {},
    totalRevenue: {},
    monthlyRevenue: {},
    applicationReview: {},
    applicationReviewFilter: {},
    totalEmployee: {},
    recurringRevenue: {},
    futureForecast: {},
  },
}

// Define the reducers that can modify the state
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ViewTotalOrganization.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewTotalOrganization.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data.totalOrganization = action.payload?.data
      },
    )
    builder.addCase(ViewTotalOrganization.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(ViewLatestOrganization.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewLatestOrganization.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data.latestOrganization = action.payload?.data
      },
    )
    builder.addCase(ViewLatestOrganization.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(ViewNewOrganization.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewNewOrganization.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data.newOrganization = action.payload?.data
      },
    )
    builder.addCase(ViewNewOrganization.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(ViewTotalRevenue.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewTotalRevenue.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data.totalRevenue = action.payload?.data
      },
    )
    builder.addCase(ViewTotalRevenue.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(ViewMonthlyRevenue.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewMonthlyRevenue.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data.monthlyRevenue = action.payload?.data
      },
    )
    builder.addCase(ViewMonthlyRevenue.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(ViewApplicationReview.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewApplicationReview.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data.applicationReview = action.payload?.data
      },
    )
    builder.addCase(ViewApplicationReview.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(ViewApplicationReviewFilter.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewApplicationReviewFilter.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data.applicationReviewFilter = action.payload?.data
      },
    )
    builder.addCase(ViewApplicationReviewFilter.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(ViewTotalEmployee.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewTotalEmployee.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data.totalEmployee = action.payload?.data
      },
    )
    builder.addCase(ViewTotalEmployee.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(ViewRecurringRevenue.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewRecurringRevenue.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data.recurringRevenue = action.payload?.data
      },
    )
    builder.addCase(ViewRecurringRevenue.rejected, (state) => {
      state.success = false
      state.loading = false
    })

    builder.addCase(ViewFutureForecast.pending, (state) => {
      state.success = false
      state.loading = true
    })
    builder.addCase(
      ViewFutureForecast.fulfilled,
      (state, action: PayloadAction<stateType>) => {
        state.success = action.payload?.success
        state.loading = false
        state.data.futureForecast = action.payload?.data
      },
    )
    builder.addCase(ViewFutureForecast.rejected, (state) => {
      state.success = false
      state.loading = false
    })
  },
})

// Define the async thunk action creator for sign in
export const ViewTotalOrganization = createAsyncThunk(
  'TotalOrganization', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await DashboardService.totalOrganization(token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const ViewLatestOrganization = createAsyncThunk(
  'LatestOrganization', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await DashboardService.latestOrganization(token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const ViewNewOrganization = createAsyncThunk(
  'NewOrganization', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await DashboardService.newOrganization(token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const ViewTotalRevenue = createAsyncThunk(
  'TotalRevenue', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await DashboardService.totalRevenue(token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const ViewMonthlyRevenue = createAsyncThunk(
  'MonthlyRevenue', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await DashboardService.monthlyRevenue(token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const ViewApplicationReviewFilter = createAsyncThunk(
  'ApplicationReviewFilter', // name of the action
  async (data: string, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await DashboardService.applicationReviewFilter(
        data,
        token,
      ) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const ViewApplicationReview = createAsyncThunk(
  'ApplicationReview', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await DashboardService.applicationReview(token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const ViewTotalEmployee = createAsyncThunk(
  'TotalEmployee', // name of the action
  async (_, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await DashboardService.totalEmployee(token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const ViewRecurringRevenue = createAsyncThunk(
  'RecurringRevenue', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await DashboardService.recurringRevenue(data, token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const ViewFutureForecast = createAsyncThunk(
  'FutureForecast', // name of the action
  async (data: any, thunkAPI: thunkAPItype) => {
    try {
      const { dispatch } = thunkAPI
      const onlyFail = true
      const token = thunkAPI.getState().auth.data?.token
      const response = await DashboardService.futureForecast(data, token) // make the API call
      handleResponse({ response, dispatch, onlyFail })
      return response.data // explicitly return the fetched data
    } catch (err) {
      console.error('Error while authentication:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export default dashboardSlice.reducer
