import {
  Box,
  Button,
  IconButton,
  Popper,
  TextField,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { MouseEventHandler, useEffect, useState, useRef } from 'react'
import { AudioVisualizer } from 'react-audio-visualize'
import DownIcon from 'src/Assets/Icons/IconChevronDown.svg'
import RocketLogo from 'src/Assets/Icons/RocketLogo.svg'
import bot from 'src/Assets/Icons/bot.svg'
import ChatBot from 'src/Assets/Icons/ChatBot.svg'
import UserBot from 'src/Assets/Icons/UserBot.svg'
import GeneralChat from 'src/Assets/Icons/GeneralChat.svg'
import Send from 'src/Assets/Icons/Enter.svg'
import Play from 'src/Assets/Icons/play.svg'
import Style from './index.module.scss'
import AudioRecorder from './recorder'
import {
  session,
  chatbot,
} from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { toggleBotState } from 'src/Redux/Slices/ApplicationStore/ApplicationStepStore'

const ChatPopup = ({
  showOptions,
  setChatForm,
}: {
  showOptions?: boolean
  setChatForm: React.Dispatch<React.SetStateAction<any>>
}) => {
  const dispatch = useAppDispatch()
  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )
  const [audioFile, setAudioFile] = useState<Array<HTMLAudioElement | null>>([
    null,
  ])
  const [message, setMessage] = useState('')
  const [headerButton, setHeaderButton] = useState<string>('Support Chat')
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null)
  const [recordedUrl, setRecordedUrl] = useState<string | Blob>('')
  const [chatStack, setChatStack] = useState<
    Array<{
      message: string | Blob
      type: string
      buttons?: Array<{ title: string; payload: string }>
    }>
  >([])
  const [audioPlaying, setAudioPlaying] = useState<
    Array<{ state: boolean; time: number } | null>
  >([null])
  const el = useRef<any>(null)

  useEffect(() => {
    el.current?.scrollIntoView({ behavior: 'smooth' })
  }, [chatStack.length, message])

  useEffect(() => {
    dispatch(session(detailId))
    handleBotMessage('Support Chat')
  }, [])

  // useEffect(() => {
  //   if (recordedUrl) {
  //     dispatch(
  //       chatbot({ text: message, file: recordedUrl, uuid: detailId }),
  //     ).then((response) => {
  //       setChatForm(response.payload.form)
  //     })
  //   }
  // }, [recordedUrl])

  const handleUserMessage = (message: string) => {
    // if (recordedUrl) {
    //   dispatch(
    //     chatbot({ text: message, file: recordedUrl, uuid: detailId }),
    //   ).then((response) => {
    //     if (response.payload) {
    //       setChatForm(response.payload.form)
    //       const chatResponce = response.payload.response
    //         .map((item: { text: string }) => item.text)
    //         .join('\n')
    //       setChatStack([
    //         ...chatStack,
    //         { message, type: 'user' },
    //         {
    //           message: chatResponce,
    //           type: 'bot',
    //         },
    //       ])
    //     }
    //   })
    // }
    if (message !== '') {
      setChatStack([...chatStack, { message, type: 'user' }])
      if (recordedUrl) {
        setMessage('')
        return
      }
      dispatch(
        chatbot({ text: message, file: recordedUrl, uuid: detailId }),
      ).then((response) => {
        if (response.payload) {
          const chatResponce = response.payload.response
            .map((item: { text: string }) => item.text)
            .join('\n')
          const buttons = response.payload.response
            .map(
              (item: { buttons?: Array<{ title: string; payload: string }> }) =>
                item.buttons,
            )
            .flat(Infinity)
          setChatStack([
            ...chatStack,
            { message, type: 'user' },
            {
              message: chatResponce,
              buttons: buttons[0] ? buttons : undefined,
              type: 'bot',
            },
          ])
        }
        if (response.payload?.form) {
          setChatForm(response.payload.form)
        }
      })
      setMessage('')
    }
  }
  const handleBotMessage = (message: string) => {
    if (message !== '') {
      dispatch(
        chatbot({ text: message, file: recordedUrl, uuid: detailId }),
      ).then((response) => {
        if (response.payload) {
          const chatResponce = response.payload.response
            .map((item: { text: string }) => item.text)
            .join('\n')
          const buttons = response.payload.response
            .map(
              (item: { buttons?: Array<{ title: string; payload: string }> }) =>
                item.buttons,
            )
            .flat(Infinity)
          setChatStack([
            ...chatStack,
            {
              message: chatResponce,
              buttons: buttons[0] ? buttons : undefined,
              type: 'bot',
            },
          ])
        }
      })
      setMessage('')
    }
  }
  const handleIconClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget)
      dispatch(toggleBotState(true))
    } else {
      setAnchorEl(null)
      dispatch(toggleBotState(false))
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    dispatch(toggleBotState(false))
  }
  useEffect(() => {
    if (chatStack.map((item) => item.type).includes('audio')) {
      if (audioFile.length !== chatStack.length) {
        setAudioFile(
          chatStack.map((item) =>
            item.type === 'audio'
              ? new Audio(URL.createObjectURL(item.message as Blob))
              : null,
          ),
        )
      }
      if (audioPlaying.length !== chatStack.length) {
        setAudioPlaying(
          chatStack.map((item) =>
            item.type === 'audio' ? { state: false, time: 0 } : null,
          ),
        )
      }
    }
  }, [chatStack])

  const handleStop = (index: number) => {
    audioFile[index]?.pause()
    setAudioPlaying(
      audioPlaying.map((item, i) => {
        if (i === index) {
          return {
            state: false,
            time: 0,
          }
        }
        return item
      }),
    )
  }

  const handlePlay = (index: number) => {
    audioFile[index]?.play()
    setAudioPlaying(
      audioPlaying.map((item, i) => {
        if (i === index) {
          return {
            state: true,
            time: 0,
          }
        }
        return item
      }),
    )
  }
  useEffect(() => {
    setTimeout(() => {
      setAudioPlaying(
        audioPlaying.map((item) => {
          if (item?.state) {
            return {
              state: true,
              time: item.time + 1,
            }
          }
          return item
        }),
      )
    }, 1000)
  }, [audioPlaying])
  const handleTime = (index: number) => {
    if (audioFile[index]?.currentTime) {
      return dayjs()
        .set('second', audioFile[index]?.currentTime)
        .set('minute', audioFile[index]?.currentTime / 60)
        .format('mm:ss')
    }
    return '00:00'
  }
  return (
    <>
      {process.env.REACT_APP_MILESTONE_4 ? (
        <>
          <IconButton className={Style.icon} href='' onClick={handleIconClick}>
            {/* Your chat icon */}
            <img src={RocketLogo} alt='Bot' />
          </IconButton>
          <Box
            style={{
              display: anchorEl ? 'flex' : 'none',
              position: 'fixed',
              top: '0',
              left: '0',
              height: '100vh',
              width: '100vw',
              zIndex: '99',
            }}
            onClick={handleClose}
          >
            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [-275, 0],
                  },
                },
              ]}
              className={Style.popper}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <Box>
                <Box className={Style.header}>
                  <Box className={Style.headerButton}>
                    <Button
                      className={Style.button}
                      style={{
                        backgroundColor:
                          headerButton === 'Support Chat' ? 'blue' : '',
                      }}
                      onClick={() => {
                        handleBotMessage('Support Chat')
                        setHeaderButton('Support Chat')
                      }}
                    >
                      <img src={GeneralChat} alt='GeneralChat' />
                      <Typography className={Style.headerText}>
                        General Chat
                      </Typography>
                    </Button>
                    {showOptions && (
                      <Button
                        className={Style.button}
                        style={{
                          backgroundColor:
                            headerButton === 'Free Flow' ? 'blue' : '',
                        }}
                        onClick={() => {
                          handleBotMessage('Free Flow')
                          setHeaderButton('Free Flow')
                        }}
                      >
                        <img src={bot} alt='Bot' />
                        <Typography className={Style.headerText}>
                          Form Fill Up
                        </Typography>
                      </Button>
                    )}
                  </Box>
                  <Box className={Style.closeIcon} onClick={handleClose}>
                    <img src={DownIcon} alt='close' />
                  </Box>
                </Box>
                <Box className={Style.body}>
                  <Box className={Style.chatBox}>
                    <Box className={Style.chatContainer}>
                      {chatStack.map((item, index) => {
                        if (item.type === 'audio') {
                          return (
                            <Box key={index} className={Style.audio}>
                              <Box
                                onClick={() => {
                                  if (!audioPlaying[index]?.state) {
                                    handlePlay(index)
                                  } else handleStop(index)
                                }}
                                display={'flex'}
                                alignItems={'center'}
                              >
                                <img src={Play} alt='>' />
                                {handleTime(index)}
                              </Box>
                              <Box className={Style.audioContainer}>
                                <AudioVisualizer
                                  barPlayedColor='#071437'
                                  currentTime={
                                    audioFile[index]?.currentTime ?? 0
                                  }
                                  blob={item.message as Blob}
                                  width={130}
                                  height={30}
                                  barWidth={2.6}
                                  gap={2}
                                  barColor={'#BBA8FF'}
                                />
                              </Box>
                            </Box>
                          )
                        } else
                          return (
                            <Box
                              key={index}
                              className={
                                item.type === 'bot' ? Style.bot : Style.user
                              }
                            >
                              <div
                                className={
                                  item.type === 'bot' ? 'chatbot' : 'userbot'
                                }
                              >
                                <img
                                  src={item.type === 'bot' ? ChatBot : UserBot}
                                  alt={item.type === 'bot' ? 'bot' : 'user'}
                                />
                              </div>
                              <Typography style={{ direction: 'ltr' }}>
                                {item.message as string}
                              </Typography>
                              {item.buttons && (
                                <Box className={Style.buttonContainer}>
                                  {item.buttons.map((button, index) => (
                                    <Button
                                      key={index}
                                      className={Style.button}
                                      onClick={() => {
                                        handleUserMessage(button.title)
                                      }}
                                    >
                                      {button.title}
                                    </Button>
                                  ))}
                                </Box>
                              )}
                            </Box>
                          )
                      })}
                      <div id={'el'} ref={el}></div>
                    </Box>
                  </Box>
                  <Box className={Style.footer}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        handleUserMessage(message)
                      }}
                    >
                      <AudioRecorder
                        setChatForm={setChatForm}
                        recordedUrl={recordedUrl}
                        setChatStack={setChatStack}
                        setRecordedUrl={setRecordedUrl}
                      />
                      <TextField
                        className={Style.input}
                        value={message}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            handleUserMessage(message)
                          }
                        }}
                        onChange={(e) => {
                          setMessage(e.target.value)
                        }}
                        placeholder='Enter your message...'
                      />
                      <Button type='submit' className={Style.sendButton}>
                        <img src={Send} alt='send' />
                      </Button>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Popper>
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default ChatPopup
