import React, { useEffect, useState, type FC } from 'react'
import { Avatar, AvatarGroup, Box, Button, Typography } from '@mui/material'
import { Icons } from 'src/Utils/Constants'
// import './index.scss'
import Style from './index.module.scss'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ViewTotalEmployee } from 'src/Redux/Slices/DashboardSlice'
import AddEmployee from 'src/Components/Employees/AddEmployee'

const TotalNumberEmployee: FC = () => {
  const [openAdd, setOpenAdd] = useState(false)
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(ViewTotalEmployee())
  }, [])

  const handleAddEmployee = () => {
    setOpenAdd(true)
  }
  const totalEmployee = useAppSelector(
    (state) => state?.dashboard?.data?.totalEmployee,
  )

  // const totalEmployeeData = [
  //   {
  //     profileURL: 'https://picsum.photos/id/200/200/300',
  //   },
  //   {
  //     profileURL: 'https://picsum.photos/id/235/200/300',
  //   },
  //   {
  //     profileURL: 'https://picsum.photos/id/238/200/300',
  //   },
  //   {
  //     profileURL: 'https://picsum.photos/id/139/200/300',
  //   },
  //   {
  //     profileURL: 'https://picsum.photos/id/240/200/300',
  //   },
  //   {
  //     profileURL: 'https://picsum.photos/id/241/200/300',
  //   },
  // ]

  return (
    <>
      <Box className={Style.LegalCard}>
        <Box className={Style.divTwo}>
          <Typography className={Style.HeadText}>Overview</Typography>
          <Box className={Style.divThree}>
            <Typography className={Style.SubText}>Home</Typography>
          </Box>
        </Box>
        <Box className={Style.divFour}>
          <Box className={Style.left}>
            <Typography className={Style.textWrapperThree}>
              {totalEmployee ? totalEmployee?.length : 0}
            </Typography>
            <Typography className={Style.textWrapperFour}>
              Total Employees Managing Cases
            </Typography>
          </Box>
          <Box className={Style.right}>
            <Box className={Style.filterTwo}>
              <AvatarGroup
                sx={{ flexDirection: 'row-reverse' }}
                total={totalEmployee ? totalEmployee?.length : 0}
              >
                {totalEmployee?.length &&
                  totalEmployee?.map(
                    (
                      item: { profileUrl: string | undefined },
                      index: React.Key | null | undefined,
                    ) => (
                      <Avatar
                        key={index}
                        alt='Trevor Henderson'
                        src={item.profileUrl}
                      />
                    ),
                  )}
              </AvatarGroup>
            </Box>
            <Button
              className={Style.Add}
              variant='contained'
              onClick={handleAddEmployee}
            >
              Add Employee
              <img src={Icons.Plus} alt='Plus' style={{ marginLeft: '8px' }} />
            </Button>
          </Box>
        </Box>
      </Box>

      {openAdd && <AddEmployee open={openAdd} setOpen={setOpenAdd} />}
    </>
  )
}

export default TotalNumberEmployee
