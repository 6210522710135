import { type FC } from 'react'
import { Box, Grid } from '@mui/material'
import { DashboardCard, LegalPermissions } from 'src/Utils/Constants'
import Style from './index.module.scss'
import { useAppSelector } from 'src/Redux/hooks'

const AdminDashboard: FC = () => {
  const UserPermission = useAppSelector((state) => state.permissions?.data)
  return (
    <>
      <Box className={Style.MainBox}>
        <Grid container className={Style.GridBox}>
          {UserPermission?.includes(LegalPermissions.EMPLOYEES) && (
            <DashboardCard.TotalNumberEmployee />
          )}
        </Grid>
        <Grid container className={Style.GridContent}>
          <DashboardCard.LegalApplicationOverview />
        </Grid>
        <Grid
          xs={12}
          // sm={12}
          // md={12}
          gap={2}
          display={'flex'}
          rowGap={2}
          className={Style.GridContent}
        >
          <Grid py={1} className={Style.chartGrid}>
            <DashboardCard.LegalApplicationAnalysis />
          </Grid>
          <Grid py={1} className={Style.chartGrid}>
            <DashboardCard.LegalAvarageTime />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AdminDashboard
