// This slice manages the state for the sign in feature
import { createSlice } from '@reduxjs/toolkit'
import {
  ChildCareType,
  EducationAndEmployementType,
  EducationType,
  EmployerDetailsType,
  ForeignWorkType,
  JobDetailsType,
  SelfEmployementType,
  SpecialEducationType,
  TradeAndTrainingType,
  WorkTaxDetailsType,
} from '../../Services/Types/applicationType'

// Define the initial state of the slice
const initialState: EducationAndEmployementType = {
  educationFlagData: {},
  Education: {
    highestGrade: '',
    schoolName: '',
    city: '',
    state: '',
    country: '',
    dateEducationCompleted: '',
  },
  SpecialEducation: {
    startDate: '',
    endDate: '',
    lastGrade: '',
    reasonForSpecialEducation: '',
    schoolName: '',
    city: '',
    state: '',
    country: '',
  },
  TradeAndTraining: {
    typeOfSpecializedTraining: '',
    startDate: '',
    endDate: '',
    schoolName: '',
    city: '',
    state: '',
    country: '',
  },
  EmployerDetails: {
    employerDetail: [
      {
        companyName: '',
        typeOfBusiness: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        startDate: '',
        endDate: '',
        isphysicallyWorkedLastDate: undefined,
        lastPhysicallyWorkedDate: '',
        jobTitle: '',
        averageHourPerDay: 0,
        averageDaysPerWeek: 0,
        payAmountPerPayPeriod: 0,
        payFrequency: '',
      },
    ],
  },
  JobDetails: {
    oneOrMoreJobsIn15Years: '',
    jobDescriptionOf15Years: '',
    applicableJobs: [],
    hoursToPerform: {
      walk: 0,
      stand: 0,
      sit: 0,
      climb: 0,
      bend: 0,
      kneel: 0,
      crounch: 0,
      crawl: 0,
      writeOrType: 0,
      handleLargeObject: 0,
      reachToThings: 0,
      handleSmallObjects: 0,
    },
    isLifitingInJob: undefined,
    howFarCarryItems: '',
    heaviestWeightLifted: '',
    howmuchWeightLiftedPerDay: '',
    didSuperviseOther: undefined,
    percentageOfTimeSpentSupervising: '',
    howManyPeopleSupervise: 0,
    didHireOrFirePeople: undefined,
    didLeadOrMentorOthers: undefined,
    whatLiftedAndHowOften: '',
  },
  ChildCare: {
    fulltimeChildcareUnder3Years: undefined,
    fulltimeChildcareStartYear: '',
    fulltimeChildcareEndYear: '',
  },
  SelfEmployement: {
    isSelfEmployed: undefined,
    isSelfEmployedDuringLastYear: undefined,
    typeOfLastYearBusiness: '',
    profitMoreAmountLastYear: undefined,
    totalEarnedAmountLastYear: '',
    isSelfEmployedDuringCurrentYear: undefined,
    typeOfCurrentYearBusiness: '',
    profitMoreAmountCurrentYear: undefined,
    totalEarnedAmountCurrentYear: '',
    isSelfEmployedNextYear: undefined,
    typeOfNextYearBusiness: '',
    profitMoreAmountNextYear: undefined,
  },
  ForeignWork: {
    haveWorkedOutSideUSA: undefined,
    isSalaryWageOrCurrentYearSelfEmployement: undefined,
    totalEarnedAmountCurrentYear: '',
    earningShowInSocialSecutiryStatementCurrentYear: undefined,
    isSalaryWageOrLastYearSelfEmployement: undefined,
    totalEarnedAmountLastYear: '',
    earningShowInSocialSecutiryStatementLastYear: undefined,
    eligibleForBenefitsUnderForeignSocialSecuritySystem: undefined,
    usaTaxesTakenOutCurrentYear: undefined,
    usaTaxesNotTakenOutCurrentYearMonths: [],
    usaTaxesTakenOutPastYear: undefined,
    usaTaxesNotTakenOutPastYearMonths: [],
  },
  WorkTaxDetails: {
    didPaymentPaidAnotherYear: undefined,
    haveWorkedWhereTaxesNotDeducted: undefined,
    isReceivingPensionOrAnnuity: undefined,
    isPensionOrAnnuityBasedOnGovernmentEmployment: undefined,
    isExpectPensionOrAnnuityInFuture: undefined,
    pensionAnnuityRailroadWork: undefined,
    pensionAnnuityFutureGovtWork: undefined,
    pensionAnnuityFutureStartDate: '',
    lumpSumNoTaxes: undefined,
  },
  haveReceivedSpecialEducation: undefined,
  haveSpecializedTraining: undefined,
}
// Define the reducers that can modify the state
const EducationAndEmployementStore = createSlice({
  name: 'EducationAndEmployement',
  initialState,
  reducers: {
    resetEducationAndEmployement: (state) => {
      state.haveReceivedSpecialEducation = undefined
      state.haveSpecializedTraining = undefined
      state.Education = {
        highestGrade: '',
        schoolName: '',
        city: '',
        state: '',
        country: '',
        dateEducationCompleted: '',
      }
      state.SpecialEducation = {
        startDate: '',
        endDate: '',
        lastGrade: '',
        reasonForSpecialEducation: '',
        schoolName: '',
        city: '',
        state: '',
        country: '',
      }
      state.TradeAndTraining = {
        typeOfSpecializedTraining: '',
        startDate: '',
        endDate: '',
        schoolName: '',
        city: '',
        state: '',
        country: '',
      }
      state.EmployerDetails = {
        employerDetail: [
          {
            companyName: '',
            typeOfBusiness: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            startDate: '',
            endDate: '',
            isphysicallyWorkedLastDate: undefined,
            lastPhysicallyWorkedDate: '',
            jobTitle: '',
            averageHourPerDay: 0,
            averageDaysPerWeek: 0,
            payAmountPerPayPeriod: 0,
            payFrequency: '',
          },
        ],
      }
      state.JobDetails = {
        oneOrMoreJobsIn15Years: '',
        jobDescriptionOf15Years: '',
        applicableJobs: [],
        hoursToPerform: {
          walk: 0,
          stand: 0,
          sit: 0,
          climb: 0,
          bend: 0,
          kneel: 0,
          crounch: 0,
          crawl: 0,
          writeOrType: 0,
          handleLargeObject: 0,
          reachToThings: 0,
          handleSmallObjects: 0,
        },
        isLifitingInJob: undefined,
        howFarCarryItems: '',
        heaviestWeightLifted: '',
        howmuchWeightLiftedPerDay: '',
        didSuperviseOther: undefined,
        percentageOfTimeSpentSupervising: '',
        howManyPeopleSupervise: 0,
        didHireOrFirePeople: undefined,
        didLeadOrMentorOthers: undefined,
        whatLiftedAndHowOften: '',
      }
      state.ChildCare = {
        fulltimeChildcareUnder3Years: undefined,
        fulltimeChildcareStartYear: '',
        fulltimeChildcareEndYear: '',
      }
      state.SelfEmployement = {
        isSelfEmployed: undefined,
        isSelfEmployedDuringLastYear: undefined,
        typeOfLastYearBusiness: '',
        profitMoreAmountLastYear: undefined,
        totalEarnedAmountLastYear: '',
        isSelfEmployedDuringCurrentYear: undefined,
        typeOfCurrentYearBusiness: '',
        profitMoreAmountCurrentYear: undefined,
        totalEarnedAmountCurrentYear: '',
        isSelfEmployedNextYear: undefined,
        typeOfNextYearBusiness: '',
        profitMoreAmountNextYear: undefined,
      }
      state.ForeignWork = {
        haveWorkedOutSideUSA: undefined,
        isSalaryWageOrCurrentYearSelfEmployement: undefined,
        totalEarnedAmountCurrentYear: '',
        earningShowInSocialSecutiryStatementCurrentYear: undefined,
        isSalaryWageOrLastYearSelfEmployement: undefined,
        totalEarnedAmountLastYear: '',
        earningShowInSocialSecutiryStatementLastYear: undefined,
        eligibleForBenefitsUnderForeignSocialSecuritySystem: undefined,
        usaTaxesTakenOutCurrentYear: undefined,
        usaTaxesNotTakenOutCurrentYearMonths: [],
        usaTaxesTakenOutPastYear: undefined,
        usaTaxesNotTakenOutPastYearMonths: [],
      }
      state.WorkTaxDetails = {
        didPaymentPaidAnotherYear: undefined,
        haveWorkedWhereTaxesNotDeducted: undefined,
        isReceivingPensionOrAnnuity: undefined,
        isPensionOrAnnuityBasedOnGovernmentEmployment: undefined,
        isExpectPensionOrAnnuityInFuture: undefined,
        pensionAnnuityRailroadWork: undefined,
        pensionAnnuityFutureGovtWork: undefined,
        pensionAnnuityFutureStartDate: undefined,
        lumpSumNoTaxes: undefined,
      }
    },
    setEducation: (state, action: { payload: EducationType }) => {
      state.Education = action.payload
    },
    setSpecialEducation: (state, action: { payload: SpecialEducationType }) => {
      state.SpecialEducation = action.payload
    },
    setTradeAndTraining: (state, action: { payload: TradeAndTrainingType }) => {
      state.TradeAndTraining = action.payload
    },
    setEmployerDetails: (
      state,
      action: { payload: EmployerDetailsType & { isPresent: boolean[] } },
    ) => {
      state.EmployerDetails = action.payload
    },
    setJobDetails: (state, action: { payload: JobDetailsType }) => {
      state.JobDetails = action.payload
    },
    setEducationFlagData: (
      state,
      action: { payload: typeof initialState.educationFlagData },
    ) => {
      state.educationFlagData = action.payload
    },
    setChildCare: (state, action: { payload: ChildCareType }) => {
      state.ChildCare = action.payload
    },
    setSelfEmployement: (state, action: { payload: SelfEmployementType }) => {
      state.SelfEmployement = action.payload
    },
    setForeignWork: (state, action: { payload: ForeignWorkType }) => {
      state.ForeignWork = action.payload
    },
    setWorkTaxDetails: (state, action: { payload: WorkTaxDetailsType }) => {
      state.WorkTaxDetails = action.payload
    },
    haveReceivedSpecialEducation: (state, action: { payload: Boolean }) => {
      state.haveReceivedSpecialEducation = action.payload as unknown as boolean
    },
    haveSpecializedTraining: (state, action: { payload: Boolean }) => {
      state.haveSpecializedTraining = action.payload as unknown as boolean
    },
    setEducationDetails: (
      state,
      action: { payload: EducationAndEmployementType },
    ) => {
      state.Education = action.payload.Education

      state.SpecialEducation = action.payload.SpecialEducation

      state.TradeAndTraining = action.payload.TradeAndTraining

      state.EmployerDetails = action.payload.EmployerDetails

      state.JobDetails = action.payload.JobDetails

      state.ChildCare = action.payload.ChildCare

      state.WorkTaxDetails = action.payload.WorkTaxDetails
      state.ForeignWork = action.payload.ForeignWork

      state.SelfEmployement = action.payload.SelfEmployement
      state.haveReceivedSpecialEducation =
        action.payload.haveReceivedSpecialEducation
      state.haveSpecializedTraining = action.payload.haveSpecializedTraining
    },
  },
})

export default EducationAndEmployementStore.reducer

export const {
  resetEducationAndEmployement,
  setEducation,
  setSpecialEducation,
  setTradeAndTraining,
  setEmployerDetails,
  setJobDetails,
  setChildCare,
  setSelfEmployement,
  setForeignWork,
  setWorkTaxDetails,
  setEducationFlagData,
  setEducationDetails,
  haveReceivedSpecialEducation,
  haveSpecializedTraining,
} = EducationAndEmployementStore.actions
