import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Radio,
  Typography,
} from '@mui/material'
import styles from './index.module.scss'
import style from '../index.module.scss'
import { useEffect, useState } from 'react'
import {
  FetchSubscriptions,
  setSignUpData,
  setselectedPlanId,
} from 'src/Redux/Slices/authSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'src/Utils/Routes'
import { RootState } from 'src/Redux/store'
import { IOSSwitch } from 'src/Theme'

const Subscription = ({
  handleNext,
  autoPay,
  setAutoPay,
}: {
  handleNext: () => void
  autoPay: boolean
  setAutoPay: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const dispatch = useAppDispatch()

  const { SignUpData } = useAppSelector((state: RootState) => state?.auth)

  const [plans, setPlans] = useState<
    Array<{
      uuid?: string
      name?: string
      description?: string
      numberOfApplication?: number
      numberOfseats?: number
      subscriptionPeriod?: number
      amount?: number
      plan?: string
    }>
  >([])
  const navigate = useNavigate()

  const [selected, setSelected] = useState('month')
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>('')
  const [planError, setPlanError] = useState<string>('')
  useEffect(() => {
    setSelected(SignUpData.subPlan || 'month')
    setSelectedPlan(SignUpData?.subUuid ?? '')
  }, [selected, selectedPlan])
  const handleChange = (newSelection: string, id: string) => {
    const key = id
    const value = newSelection
    setSelected(newSelection)

    dispatch(setSignUpData({ key, value }))
  }
  const handlePlanChange = (newSelection: string | undefined, id: string) => {
    const key = id
    const value = newSelection
    setSelectedPlan(newSelection)

    dispatch(setselectedPlanId(newSelection))
    dispatch(setSignUpData({ key, value }))

    setPlanError('')
  }
  useEffect(() => {
    dispatch(FetchSubscriptions()).then((res) => {
      setSelected('month')
      setPlans(res.payload?.data)
    })
  }, [])

  const isPaymentFlowPending = false

  const handleContinue = () => {
    if (isPaymentFlowPending) {
      navigate(ROUTES.SIGN_IN)
    } else {
      if (selectedPlan) {
        handleNext()
      } else {
        setPlanError('Please select a plan')
      }
    }
  }
  return (
    <>
      <Box className={style.title}>
        <Typography className={style.phrase}>Subscription Details</Typography>
        <Typography className={style.subPhrase}>
          Please fill in the following Subscription details.
        </Typography>
      </Box>
      {/* <Grid >

      </Grid> */}
      <Box className={styles.planSwitch} width={'100%'}>
        <Button
          onClick={() => {
            handleChange('month', 'subPlan')
          }}
          className={
            selected === 'month' ? styles.planSelectedOption : styles.planOption
          }
        >
          Monthly
        </Button>
        <Button
          onClick={() => {
            handleChange('year', 'subPlan')
          }}
          className={
            selected === 'year' ? styles.planSelectedOption : styles.planOption
          }
        >
          Annual
        </Button>
      </Box>
      {plans
        ?.filter((plan) => plan.plan === selected)
        .map((plan) => (
          <Box
            key={plan.uuid}
            className={
              selectedPlan === plan.uuid ? styles.selectedCard : styles.planCard
            }
            role='radio'
            aria-checked={selectedPlan === plan.uuid}
            onClick={() => {
              handlePlanChange(plan.uuid, 'subUuid')
            }}
          >
            <Box className={styles.planHeader}>
              {/* <Box
                className={
                  selectedPlan === plan.uuid
                    ? styles.planCheckedbox
                    : styles.planCheckbox
                }
              > */}
              <Box
                className={
                  selectedPlan === plan.uuid
                    ? styles.planCheckedbox
                    : styles.planCheckbox
                }
              >
                <Radio checked={selectedPlan === plan.uuid} />
              </Box>
              <Box className={styles.planInfo}>
                <Typography className={styles.planName}>{plan.name}</Typography>
                <Typography
                  className={styles.planPrice}
                  style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
                >
                  {plan.description}
                </Typography>
              </Box>
            </Box>
            <Box className={styles.planFooter}>
              <Typography className={styles.planCurrency}>$</Typography>
              <Typography className={styles.planAmount}>
                {plan.amount}
              </Typography>
              <Typography className={styles.planPeriod}>
                /{plan.plan}
              </Typography>
            </Box>
          </Box>
        ))}
      <Box className={styles.toggleWrapper}>
        <Box className={styles.toggleText}>
          <Typography className={styles.textFieldWrapper}>
            Auto Payment
          </Typography>
        </Box>
        <Box>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={autoPay}
                onChange={() => {
                  setAutoPay(!autoPay)
                }}
              />
            }
            label=''
          />
        </Box>
      </Box>
      <FormHelperText className={styles.error}>{planError}</FormHelperText>

      <Button
        variant='contained'
        onClick={handleContinue}
        className={styles.button}
      >
        <Typography className={styles.mediumText}>Continue</Typography>
      </Button>
    </>
  )
}

export default Subscription
