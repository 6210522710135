import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState, type FC } from 'react'
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { ViewFutureForecast } from 'src/Redux/Slices/DashboardSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { Icons } from 'src/Utils/Constants'
// import CalenderPopup from './Calender'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { SubscriptionListRequest } from 'src/Redux/Slices/subscriptionSlice'
import Style from './index.module.scss'
// import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import 'react-calendar/dist/Calendar.css'
import './calender.scss'
// import Calender from 'src/Assets/Icons/calander.svg'
// import Calendar from 'react-calendar'

interface ChartData {
  name: string
  value: number
}

// interface DateRange {
//   startDate?: Date | undefined | any,
//   endDate?: Date | undefined | any
// }

interface subscriptionType {
  id: string
  name: string
  plan: string
  amount: number
  subscriptionPeriod: number
  status: string
  createdAt: string
  description: string
}

interface TooltipProps {
  active?: boolean
  payload?: Array<{
    name: string
    value: number
    payload: ChartData
  }>
}
const FutureForecastChart: FC = () => {
  const dispatch = useAppDispatch()
  // it's just a temp data. It will be replaced in future once API integration is done.
  // const data = [
  //   { name: 'Jan', value: 10050000 },
  //   { name: 'Feb', value: 25500000 },
  //   { name: 'Mar', value: 35900000 },
  //   { name: 'Apr', value: 50800000 },
  //   { name: 'May', value: 45000000 },
  //   { name: 'Jun', value: 55000000 },
  //   { name: 'Jul', value: 20500000 },
  //   { name: 'Aug', value: 30500000 },
  //   { name: 'Sep', value: 5000000 },
  //   { name: 'Oct', value: 3000000 },
  //   { name: 'Nov', value: 600000 },
  //   { name: 'Dec', value: 6500000 },
  // ]
  useEffect(() => {
    dispatch(SubscriptionListRequest())
  }, [])

  const subscriptionList: subscriptionType[] = useAppSelector(
    (state) => state.subscription.data,
  )

  const futureForecast = useAppSelector(
    (state) => state?.dashboard?.data?.futureForecast,
  )

  const formatYAxisValue = (value: number) => {
    if (value >= 1000000) {
      return `${value / 1000000} M`
    } else if (value >= 1000) {
      return `${value / 1000} K`
    }
    return value
  }
  const [activeButton, setActiveButton] = React.useState<subscriptionType[]>([])

  const CustomTooltip: React.FC<TooltipProps> = ({ active, payload }) => {
    if (active && payload?.length) {
      const data = payload[0].payload
      // // const formattedAmount = new Intl.NumberFormat('en-US', {
      // //   style: 'currency',
      // //   currency: 'USD',
      // // })
      //   .format(data.value)
      //   .replace('$', '')
      //   .split('.')[0]
      return (
        <>
          <Box className={Style.customTooltip}>
            <Box className={Style.myHeader} display='flex' alignItems='center'>
              <Typography>{data.name}</Typography>
              {/* <span>{data.name}</span> */}
            </Box>
            <Box className={Style.myDivider} />
            <Box className={Style.myDetails} display='flex'>
              <Box className={Style.myIcon} />
              <Typography>{`Revenue: $${formatYAxisValue(data.value)}`}</Typography>
            </Box>
            {/* <Box className={Style.myDetails} display='flex'>
              <Typography>{`Savings: ${data.value}`}</Typography>
            </Box> */}
          </Box>
        </>
      )
    }

    return null
  }

  // const anchor = React.useRef()
  const [open, setOpen] = useState<boolean>(false)

  // const toggle = () => { setOpen(!open) };

  // const [dateRange, setDateRange] = React.useState<DateRange>({
  //   startDate: new Date(),
  //   endDate: new Date(),
  // });

  const formatDate = (dateString: any) => {
    const date = new Date(dateString)
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
  }

  const formatAPIDate = (dateString: any) => {
    const date = new Date(dateString)
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
  }

  const [value, onChange] = useState<any>([
    new Date(),
    new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
  ])

  useEffect(() => {
    const futureForecastData = {
      // startDate: formatAPIDate(dateRange?.startDate),
      startDate: formatAPIDate(value[0]),
      endDate: formatAPIDate(value[1]),
      // endDate: formatAPIDate(dateRange?.endDate),
      subscriptionId: activeButton.map((item) => `'${item.id}'`).join(','),
    }

    dispatch(ViewFutureForecast(futureForecastData))
  }, [activeButton, value])

  return (
    <>
      <Box className={Style.RecurringRevenueBox}>
        <Box className={Style.RecurringRevenueBoxTitle}>
          <Grid>
            <Typography className={Style.RecurringRevenueBoxText}>
              Future Forecast
            </Typography>
          </Grid>
          <Box
            onClick={() => {
              setOpen(!open)
            }}
            className={Style.RecurringRevenueBoxDate}
          >
            <Typography
              component={'span'}
              className={Style.RecurringRevenueDate}
            >
              {formatDate(value[0])} - {formatDate(value[1])}
            </Typography>
            <Box
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <DateRangePicker
                calendarIcon={
                  <img
                    src={Icons.calander}
                    alt='calander'
                    height={20}
                    width={20}
                  />
                }
                clearIcon={null}
                // maxDate={new Date()}
                minDate={new Date()}
                onChange={onChange}
                value={value}
                onCalendarClose={() => {
                  setOpen(false)
                }}
                isOpen={open ?? false}
              />
            </Box>
          </Box>
          {/* <Box ref={anchor} onClick={toggle} className={Style.RecurringRevenueBoxDate}>
            <Typography component={'span'} className={Style.RecurringRevenueDate}>
              {formatDate(dateRange?.startDate)} - {formatDate(dateRange?.endDate)}
            </Typography>
            <img
              src={Icons.calander}
              alt='calander'
              height={22}
              width={22}
              style={{ cursor: 'pointer', marginLeft: '5px' }}
            />
          </Box> */}
        </Box>
        <Grid>
          <Grid item xs={12}>
            <Typography
              component={'span'}
              className={Style.RecurringRevenueTxtAMain}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  color: 'grey.400',
                  fontSize: '14px',
                  fontFamily: 'SFUiDisplaymedium',
                  margin: '4px',
                }}
              >
                $
              </Typography>
              {futureForecast?.sum}
              {/* <Typography
                component={'span'}
                className={Style.RecurringRevenueTxtSub}
              >
                <img
                  src={Icons.upArrow}
                  alt='upArrow'
                  style={{ marginRight: '2px' }}
                />
                2.5%
              </Typography> */}
            </Typography>
          </Grid>

          <Grid
            item
            container
            spacing={2}
            xs={12}
            sx={{ display: 'flex' }}
            p={2}
          >
            {subscriptionList?.length &&
              subscriptionList?.map((item: subscriptionType, index: number) => (
                <Grid item key={index}>
                  <Button
                    onClick={() => {
                      if (activeButton.includes(item)) {
                        setActiveButton(
                          activeButton.filter(
                            (i: subscriptionType) => i !== item,
                          ),
                        )
                      } else {
                        setActiveButton([...activeButton, item])
                      }
                    }}
                    className={
                      activeButton.includes(item)
                        ? Style.theActiveBtn
                        : Style.theBtn
                    }
                  >
                    {item?.name}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </Grid>

        <Grid item container xs={12} className={Style.chartGrid}>
          <Box className={Style.ApplicationChartBox}>
            <ResponsiveContainer width='100%' height='100%'>
              <ComposedChart data={futureForecast?.result}>
                <CartesianGrid strokeDasharray='3 3' />
                <defs>
                  <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='20%' stopColor='#8E8FFA' stopOpacity={0.4} />
                    <stop offset='90%' stopColor='#fff' stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey='name'
                  axisLine={{ stroke: '#99A1B7' }}
                  tick={{ fill: '#99A1B7' }}
                />
                <YAxis
                  tickFormatter={(value) => `$${formatYAxisValue(value)}`}
                  axisLine={{ stroke: '#99A1B7' }}
                  tick={{ fill: '#99A1B7' }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type='bumpX'
                  dataKey='value'
                  stroke='#8E8FFA'
                  fillOpacity={5}
                  fill='url(#colorUv)'
                />
                <Line
                  tooltipType='none'
                  dot={false}
                  activeDot={{ r: 5 }}
                  type='bumpX'
                  dataKey='value'
                  stroke='#8E8FFA'
                  strokeWidth={3}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
      </Box>
      {/* <CalenderPopup
        toggle={toggle}
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        anchor={anchor}
        dateRange={dateRange}
        setDateRange={setDateRange}
      /> */}
    </>
  )
}

export default FutureForecastChart
