import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import { useAppSelector } from 'src/Redux/hooks'
import { ROUTES } from 'src/Utils/Routes'

import { isSupported } from 'firebase/messaging'
import Notification from 'src/Components/firebaseNotifications/Notification'
import ApplicationCommon from 'src/Pages/Applications/AddApplication'
import {
  AdminPermissions,
  ApplicationComponent,
  LegalPermissions,
  MainComponent,
  RouteComponent,
} from 'src/Utils/Constants'
import NotificationBar from '../Alert_Notify/NotificationBar'
import AssistantForm from '../Application/AssitantForm'
import Loader from '../Loader'
import ProtectedRoute from './secureRoutes'
import SessionTimeoutChecker from './sessionTimeout'

interface ProtectedRouteProps {
  isAuthenticated: boolean
  authenticationPath: string
  outlet: JSX.Element
}

const Router: React.FC = () => {
  const UserPermission = useAppSelector((state) => state.permissions?.data)
  const isPublic = useAppSelector(
    (state) => state.applicationStore.ApplicationId.isPublic,
  )
  const token = secureLocalStorage.getItem('token')
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: !!token && !isPublic,
    authenticationPath: '/',
  }
  const publicProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: !!token,
    authenticationPath: '/',
  }
  const [willSupport, setWillSupport] = React.useState(false)
  useEffect(() => {
    isSupported()
      .then((isAvailable: boolean) => {
        if (isAvailable) {
          setWillSupport(true)
        }
      })
      .catch(console.error)
  }, [])
  return (
    <>
      <NotificationBar />
      <Loader />
      <SessionTimeoutChecker />
      {willSupport && <Notification />}
      <Routes>
        <Route path={ROUTES.DEFAULT_PATH} element={<RouteComponent.SignIn />} />
        <Route
          path={ROUTES.STYLE_GUIDE}
          element={<RouteComponent.LocationInput />}
        />
        <Route path={ROUTES.SIGN_IN} element={<RouteComponent.SignIn />} />
        <Route path={ROUTES.SIGN_UP} element={<RouteComponent.SignUp />} />
        <Route
          path={ROUTES.FORGOT_PASSWORD}
          element={<RouteComponent.ForgotPassword />}
        />
        <Route path='temp' element={<MainComponent.AdminManageEmployees />} />
        <Route
          path={ROUTES.RESET_PASSWORD}
          element={<RouteComponent.ResetPassword />}
        />
        <Route
          path={ROUTES.PAYMENT_SUCCESS}
          element={<RouteComponent.PaymentSuccess />}
        />
        <Route
          path={ROUTES.PAYMENT_FAIL}
          element={<RouteComponent.PaymentFail />}
        />
        <Route
          path={ROUTES.WELCOME_PAGE}
          element={
            // <ProtectedRoute
            //   {...defaultProtectedRouteProps}
            //   outlet={
            <ApplicationComponent.Welcome />
            // }
            // />
          }
        />
        <Route
          path={ROUTES.APPLICATION_ASSISTANT}
          element={
            // <ProtectedRoute
            // {...defaultProtectedRouteProps}
            // outlet={
            <AssistantForm />
            // }
            // />
          }
        />
        <Route
          path={ROUTES.APPLICATION}
          element={
            // <ProtectedRoute
            //   {...defaultProtectedRouteProps}
            //   outlet={
            <ApplicationCommon />
            // }
            // />
          }
        />
        <Route
          path={ROUTES.APPLICATION}
          element={
            <ProtectedRoute
              {...publicProtectedRouteProps}
              outlet={<ApplicationCommon />}
            />
          }
        />

        <Route
          path={ROUTES.DASHBOARD}
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<RouteComponent.MainDashboard />}
            />
          }
        />
        {UserPermission?.includes(AdminPermissions.ORGANIZATIONS) && (
          <>
            <Route
              path={ROUTES.ORGANIZATIONS}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
            <Route
              path={ROUTES.ORGANIZATION_DETAIL}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
          </>
        )}
        {UserPermission?.includes(AdminPermissions.ROLE_AND_PERMISSION) && (
          <Route
            path={ROUTES.ROLE_PERMISSION}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<RouteComponent.MainDashboard />}
              />
            }
          />
        )}
        {UserPermission?.includes(LegalPermissions.EMPLOYEES) ? (
          <>
            <Route
              path={ROUTES.LEGAL_MANAGE_EMPLOYEES}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
            <Route
              path={ROUTES.LEGAL_EMPLOYEE_DETAIL}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
            {UserPermission?.includes(
              LegalPermissions.ROLE_AND_PERMISSION_MAP,
            ) && (
              <>
                {' '}
                <Route
                  path={ROUTES.ROLE}
                  element={
                    <ProtectedRoute
                      {...defaultProtectedRouteProps}
                      outlet={<RouteComponent.MainDashboard />}
                    />
                  }
                />
                <Route
                  path={ROUTES.ROLE_DETAILS}
                  element={
                    <ProtectedRoute
                      {...defaultProtectedRouteProps}
                      outlet={<RouteComponent.MainDashboard />}
                    />
                  }
                />
              </>
            )}
          </>
        ) : UserPermission?.includes(
            LegalPermissions.ROLE_AND_PERMISSION_MAP,
          ) ? (
          <>
            {' '}
            <Route
              path={ROUTES.ROLE}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
            <Route
              path={ROUTES.ROLE_DETAILS}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
          </>
        ) : (
          <></>
        )}
        {UserPermission?.includes(AdminPermissions.ADMIN_USERS) && (
          <>
            <Route
              path={ROUTES.ADMIN_MANAGE_EMPLOYEES}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
            <Route
              path={ROUTES.ADMIN_EMPLOYEE_DETAIL}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
            {(UserPermission?.includes(
              AdminPermissions.ROLE_AND_PERMISSION_MAP,
            ) ||
              UserPermission?.includes(
                AdminPermissions.ROLE_AND_PERMISSION,
              )) && (
              <>
                {' '}
                <Route
                  path={ROUTES.ROLE}
                  element={
                    <ProtectedRoute
                      {...defaultProtectedRouteProps}
                      outlet={<RouteComponent.MainDashboard />}
                    />
                  }
                />
                <Route
                  path={ROUTES.ROLE_DETAILS}
                  element={
                    <ProtectedRoute
                      {...defaultProtectedRouteProps}
                      outlet={<RouteComponent.MainDashboard />}
                    />
                  }
                />
              </>
            )}
          </>
        )}
        {UserPermission?.includes(AdminPermissions.APPLICATIONS) && (
          <>
            <Route
              path={ROUTES.APPLICATIONS}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
            <Route
              path={ROUTES.APPLICATION_DETAIL}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
          </>
        )}
        {UserPermission?.includes(AdminPermissions.SUBSCRIPTIONS) && (
          <>
            <Route
              path={ROUTES.SUBSCRIPTION_PLAN}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
            <Route
              path={ROUTES.ENHANCED_PLAN}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
            <Route
              path={ROUTES.BILLING_INFO}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
          </>
        )}
        {UserPermission?.includes(LegalPermissions.ENHANCED_SUBSCRIPTIONS) && (
          <>
            <Route
              path={ROUTES.SUBSCRIPTION_PLAN}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
            <Route
              path={ROUTES.ENHANCED_PLAN}
              element={
                <ProtectedRoute
                  {...defaultProtectedRouteProps}
                  outlet={<RouteComponent.MainDashboard />}
                />
              }
            />
          </>
        )}
        <Route
          path={ROUTES.SETTING}
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<RouteComponent.MainDashboard />}
            />
          }
        />
        <Route
          path={ROUTES.PROFILE}
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<RouteComponent.MainDashboard />}
            />
          }
        />
        {
          <Route
            path={ROUTES.NOTIFICATION}
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<RouteComponent.MainDashboard />}
              />
            }
          />
        }
        <Route
          path='*'
          element={<Navigate to={ROUTES.DEFAULT_PATH} replace />}
        />
      </Routes>
    </>
  )
}

export default Router
