import { useEffect, useState, useRef } from 'react'

export const useVisibleSection = (sectionIds: string[]) => {
  const [visibleSection, setVisibleSection] = useState<string | null>(null)
  const observer = useRef<IntersectionObserver | null>(null)

  useEffect(() => {
    const handleIntersections = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisibleSection(entry.target.id)
        }
      })
    }

    observer.current = new IntersectionObserver(handleIntersections, {
      root: null, // use the viewport as the root
      rootMargin: '0px',
      threshold: 0.5, // Adjust this value based on when you want the section to be considered visible
    })

    const currentObserver = observer.current

    sectionIds.forEach((id) => {
      const element = document.getElementById(id)
      if (element) {
        currentObserver.observe(element)
      }
    })

    return () => {
      sectionIds.forEach((id) => {
        const element = document.getElementById(id)
        if (element) {
          currentObserver.unobserve(element)
        }
      })
      currentObserver.disconnect()
    }
  }, [sectionIds])

  return visibleSection
}
