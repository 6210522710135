import { loadStripe } from '@stripe/stripe-js/pure'
export const makePayment = async (id: any) => {
  loadStripe.setLoadParameters({ advancedFraudSignals: false })

  const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!)

  if (!stripe) {
    throw new Error('Failed to load Stripe')
  }
  const result = await stripe.redirectToCheckout({
    sessionId: id,
  })

  if (result.error) {
    console.log(result.error)
  }
}
