import { FormHelperText, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Style from './index.module.scss'
// import { useState } from 'react'
import remove from 'src/Assets/Icons/remove.svg'
import { useEffect, useState } from 'react'

const ProfileBox = ({
  errors,
  formValues,
  changeHandler,
  handleRemove,
  index,
}: {
  errors: Record<string, string>
  formValues: string
  changeHandler: (value: string) => void
  handleRemove: (index: number) => void
  index: number
}) => {
  const [part1, setPart1] = useState(formValues.slice(0, 3))
  const [part2, setPart2] = useState(formValues.slice(3, 5))
  const [part3, setPart3] = useState(formValues.slice(5))
  // const [fullValue, setFullValue] = useState('')
  // useEffect(() => {
  //   setFullValue(`${part1}${part2}${part3}`)
  // }, [part1, part2, part3])
  // useEffect(() => {
  //   changeHandler(fullValue)
  // }, [fullValue])
  useEffect(() => {
    setPart1(formValues.slice(0, 3))
    setPart2(formValues.slice(3, 5))
    setPart3(formValues.slice(5))
  }, [formValues])
  return (
    <>
      <Box className={Style.profileBox}>
        <Grid
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography className={Style.label}>Profile {index + 1}</Typography>
          {index > 0 && (
            <img
              src={remove}
              alt=''
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleRemove(index)
              }}
            />
          )}
        </Grid>
        <Grid xs={12} gap={2} py={1} width={'100%'} display={'flex'}>
          <Grid width={'100%'}>
            <TextField
              fullWidth
              id={`part1-${index}`}
              name='part1'
              placeholder='###'
              className={Style.AnsTxt}
              value={part1}
              onChange={(e) => {
                if (e.target.value.match(/^\d*$/)) {
                  setPart1(e.target.value)
                  changeHandler(`${e.target.value}${part2}${part3}`)
                }
              }}
              inputProps={{ maxLength: 3 }}
              // error={!!errors.socialSecurityNumber}
              // helperText={errors.socialSecurityNumber}
            />
          </Grid>
          <Grid width={'100%'}>
            <TextField
              fullWidth
              id={`part2-${index}`}
              name='part2'
              placeholder='##'
              className={Style.AnsTxt}
              value={part2}
              onChange={(e) => {
                if (e.target.value.match(/^\d*$/)) {
                  setPart2(e.target.value)
                  changeHandler(`${part1}${e.target.value}${part3}`)
                }
              }}
              inputProps={{ maxLength: 2 }}
              // error={!!errors.socialSecurityNumber}
              // helperText={errors.socialSecurityNumber}
            />
          </Grid>
          <Grid width={'100%'}>
            <TextField
              fullWidth
              id={`part3-${index}`}
              name='part3'
              placeholder='####'
              className={Style.AnsTxt}
              value={part3}
              onChange={(e) => {
                if (e.target.value.match(/^\d*$/)) {
                  setPart3(e.target.value)
                  changeHandler(`${part1}${part2}${e.target.value}`)
                }
              }}
              inputProps={{ maxLength: 4 }}
              // error={!!errors.socialSecurityNumber}
              // helperText={errors.socialSecurityNumber}
            />
          </Grid>
        </Grid>
        <FormHelperText sx={{ mx: 1.5 }}>
          {errors[`otherSocialSecurityNumbers.${index}`]}
        </FormHelperText>
      </Box>
    </>
  )
}
export default ProfileBox
