import { useEffect, useRef, useState } from 'react'
import LegalNames from './LegalNames'
import IdentificationNumbers from './IdentificationNumbers'
import ReceivingDisability from './ReceivingDisability'
import CustomStepper from 'src/Components/Application/CustomStepper'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { setLegalDetailsStep } from 'src/Redux/Slices/ApplicationStore/ApplicationStepStore'

const LegalDetails = ({
  chatForm,
  setChatForm,
}: {
  chatForm?: any
  setChatForm?: any
}) => {
  const formComponents = [
    {
      title: 'Legal Names',
      content: LegalNames,
    },
    {
      title: 'Identification Numbers',
      content: IdentificationNumbers,
    },
    {
      title: 'Receiving Disability Benefits Payment',
      content: ReceivingDisability,
    },
  ]

  const dispatch = useAppDispatch()
  const location = useLocation()
  const currentPath = location.pathname
  const myRef = useRef<HTMLDivElement>(null)
  const totalSteps = formComponents.length

  const LegalDetailsStep = useAppSelector(
    (state) => state?.applicationStore?.ApplicationStep?.LegalDetailsStep,
  )

  // const [activeStep, setActiveStep] = useState<number>(0)
  const [activeStep, setActiveStep] = useState(
    LegalDetailsStep ? parseInt(LegalDetailsStep) : 0,
  )

  useEffect(() => {
    // localStorage.setItem('LegalDetails', activeStep.toString())
    dispatch(setLegalDetailsStep(activeStep.toString()))
  }, [activeStep, currentPath])

  useEffect(() => {
    setChatForm(false)
  }, [])

  const handleNext = () => {
    setChatForm(false)
    setActiveStep((prevActiveStep) =>
      prevActiveStep < totalSteps - 1 ? prevActiveStep + 1 : prevActiveStep,
    )
    myRef?.current?.scrollIntoView()
  }

  const handleBack = () => {
    setChatForm(false)
    setActiveStep((prevActiveStep) =>
      prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep,
    )
    myRef?.current?.scrollIntoView()
  }

  const FormToShow = formComponents[activeStep].content // Get the form component based on the active step
  return (
    <>
      <CustomStepper
        myRef={myRef}
        formComponents={formComponents}
        activeStep={activeStep}
        handleNext={handleNext}
        handleBack={handleBack}
      />
      <Box>
        {FormToShow && (
          <FormToShow
            chatForm={chatForm}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        )}
        {/* Render the form component based on the active step */}
      </Box>
    </>
  )
}

export default LegalDetails
