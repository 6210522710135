/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import FooterContent from 'src/Components/FooterContent'
import * as Comps from 'src/Components/SignUp'
import { makePayment } from 'src/Components/SignUp/Billing/paymentFun'
import {
  EmailVerifyRequest,
  createPayment,
  setSessionId,
} from 'src/Redux/Slices/authSlice'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { RootState } from 'src/Redux/store'
import { STEPS } from 'src/Utils/Constants'
import styles from './index.module.scss'

const SignUp: React.FunctionComponent = () => {
  const {
    SideSteps,
    AccountInfo,
    Organization,
    Personal,
    Subscription,
    // Billing,
  } = Comps
  // const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const selectedPlanId = useAppSelector(
    (state: RootState) => state?.auth?.SignUpData?.subUuid,
  )
  const isRegistered = useAppSelector(
    (state: RootState) => state?.auth?.SignUpData?.isRegistered,
  )

  const emailId = useAppSelector((state) => state?.auth?.SignUpData?.emailId)

  const [autoPay, setAutoPay] = useState(false)
  const [planId, setPlanId] = useState('')
  const stepKeys = Object.keys(STEPS)
  const [active, setActive] = useState<string>(stepKeys[0])
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    if (secureLocalStorage.getItem('token')) {
      navigate('/dashboard')
    }
    if (location.state?.step === 'subscription') {
      setActive('subscription')
    }
  }, [])
  useEffect(() => {
    setPlanId(selectedPlanId)
  }, [selectedPlanId])

  const handleNext = () => {
    setActive((now) => {
      const index = stepKeys.indexOf(now)
      if (index === stepKeys.length - 1) return stepKeys[0]
      return stepKeys[index + 1]
    })
  }
  const handlePrevious = () => {
    setActive((now) => {
      const index = stepKeys.indexOf(now)
      return stepKeys[index - 1]
    })
  }

  useEffect(() => {
    // const data = {
    //   planDetail: [
    //     {
    //       price_data: {
    //         currency: 'usd',
    //         unit_amount: 100,
    //         product_data: {
    //           name: 'Subscription plan 1',
    //           description: 'Subscription plan 1 description',
    //           metadata: {
    //             category: 'Clothing', // Product category
    //             size: 'Medium', // Product size
    //             color: 'Blue', // Product color
    //           },
    //         },
    //       },
    //       quantity: 1,
    //     },
    //   ],
    // }
    if (
      active === STEPS.billing.title &&
      planId &&
      (location.state?.emailId || emailId)
    ) {
      dispatch(
        createPayment({
          subscriptionId: planId,
          emailId: location.state?.emailId || emailId,
          autoPayment: autoPay,
        }),
      ).then(async (res) => {
        dispatch(setSessionId(res?.payload?.data?.data?.sessionId))
        makePayment(res?.payload?.data?.data?.sessionId)
      })
    }
    // Call your function before load
  }, [active, planId])
  const handleSignIn = () => {
    navigate('/')
  }
  const isValidEmail = (email: string): boolean => {
    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const sendOtpAgain = () => {
    if (isValidEmail(emailId)) {
      dispatch(EmailVerifyRequest(emailId))
    }
  }

  return (
    <Grid container className={styles.signUp}>
      <SideSteps active={active} />
      <Box className={styles.container2}>
        <Box className={styles.background}>
          <Box className={styles.mainBox}>
            {active === STEPS.account.title && (
              <AccountInfo handleNext={handleNext} />
            )}
            {active === STEPS.organization.title && (
              <Organization handleNext={handleNext} />
            )}
            {active === STEPS.personal.title && (
              <Personal handleNext={handleNext} />
            )}
            {active === STEPS.subscription.title && (
              <Subscription
                autoPay={autoPay}
                setAutoPay={setAutoPay}
                handleNext={handleNext}
              />
            )}
            {/* {active === STEPS.billing.title && <Billing />} */}

            <>
              {/* {active !== STEPS.account.title && (
                <Button onClick={handlePrevious} className={styles.prvButton}>
                  <Typography className={styles.mediumText}>
                    Previous
                  </Typography>
                </Button>
              )} */}
              {/* {active !== STEPS.account.title && (
                <Button onClick={handlePrevious} className={styles.prvButton}>
                  <Typography className={styles.mediumText}>
                    Previous
                  </Typography>
                </Button>
              )} */}
              {active !== STEPS.account.title &&
                (active !== STEPS.organization.title || !isRegistered) && (
                  <Button
                    disabled={location.state?.step === 'subscription'}
                    onClick={handlePrevious}
                    className={styles.prvButton}
                  >
                    <Typography className={styles.mediumText}>
                      Previous
                    </Typography>
                  </Button>
                )}
              {active === STEPS.account.title && isRegistered ? (
                <>
                  <Box onClick={sendOtpAgain} className={styles.signup}>
                    <Typography className={styles.textWrapper8}>
                      Didn't get the code?
                    </Typography>
                    <Typography className={styles.textFieldWrapper}>
                      Resend
                    </Typography>
                    <Typography className={styles.textWrapper8}>Or</Typography>
                    <Typography
                      onClick={handleSignIn}
                      className={styles.textFieldWrapper}
                    >
                      Sign In
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box onClick={handleSignIn} className={styles.signup}>
                  <Typography className={styles.textWrapper8}>
                    Already have an account?
                  </Typography>
                  <Typography className={styles.textFieldWrapper}>
                    Sign In
                  </Typography>
                </Box>
              )}
            </>
            {/* )}  */}
          </Box>
          <FooterContent />
        </Box>
      </Box>
    </Grid>
  )
}

export default SignUp
