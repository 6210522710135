import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { Key, useEffect, useState } from 'react'
import CommonRadioBox, {
  SmallRadioBox,
} from 'src/Components/Application/CommonRadioBox'
import { AddButton, ApplicationIntroBox, StarText } from 'src/Components/Fields'
import { HospitalAndMedicalFacilityCareType } from 'src/Redux/Services/Types/applicationType'
import { AddHospitalAndMedicalFacilityCare } from 'src/Redux/Slices/ApplicationSlices/MedicalDetailsSlice'
import { setHospitalAndMedicalFacilityCare } from 'src/Redux/Slices/ApplicationStore/MedicalDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import HospitalDetailsBox from './HospitalDetailsBox'
import OrganizationDetailBox from './OrgDetailsBox'
import Style from './index.module.scss'

const HospitalMedicalFacilityCare = ({
  handleEditToggle,
}: {
  setDetailId?: React.Dispatch<React.SetStateAction<string>>
  handleEditToggle: () => void
}) => {
  const dispatch = useAppDispatch()

  const MedicalDetailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.MedicalDetailId,
  )

  const HospitalAndMedicalFacilityCareState = useAppSelector(
    (state) =>
      state?.applicationStore?.MedicalDetails?.HospitalAndMedicalFacilityCare,
  )

  const hospitalValues = {
    hospitalName: '',
    address: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    phoneNumber: '',
    arrivedDate: '',
    leftDate: '',
    treatmentType: [],
    typeOfTherapyDoctorProvided: [],
    otherTreatment: '',
    otherTypeOfTherapyDetails: '',
    specialProcedureDetail: '',
  }
  const organizationValues = {
    organizationName: '',
    address: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    phoneNumber: '',
    contactPersonFirstName: '',
    contactPersonLastName: '',
    arrivedDate: '',
    leftDate: '',
  }
  const [formValues, setFormValues] =
    useState<HospitalAndMedicalFacilityCareType>({
      medicalDetailId: MedicalDetailId,
      haveEmergencyRoomVisit: undefined,
      haveEmergencyRoomVisitMedicalFacility: [hospitalValues],
      haveMedicalFacility: undefined,
      haveMedicalFacilityMedicalFacility: [hospitalValues],
      haveMentalHeathTreatment: undefined,
      haveMentalHeathTreatmentMedicalFacility: [hospitalValues],
      haveInpatientOrOverNightStay: undefined,
      haveInpatientOrOverNightStayMedicalFacility: [hospitalValues],
      haveOutpatientOrSamedayVisit: undefined,
      haveOutpatientOrSamedayVisitMedicalFacility: [hospitalValues],
      haveSurgeries: undefined,
      haveSurgeriesMedicalFacility: [hospitalValues],
      havePhysicalTherapy: undefined,
      havePhysicalTherapyMedicalFacility: [hospitalValues],

      isDoctorInJail: undefined,
      jailDoctorDetail: [organizationValues],
      isPublicWelfare: undefined,
      welfareDetail: [organizationValues],
      isRecordHeldByAttorney: undefined,
      attorneyDetail: [organizationValues],
      haveDisabilityInsurance: undefined,
      insuranceDetail: [organizationValues],
      isVocationalRehabilitationService: undefined,
      rehabilitationOrganizationDetail: [organizationValues],
      isWorkersCompensation: undefined,
      workersCompensationDetail: [organizationValues],
      isOtherHaveMedicalRecords: undefined,
      otherOrganizationDetail: [organizationValues],
    })

  useEffect(() => {
    setFormValues({
      ...HospitalAndMedicalFacilityCareState,
      medicalDetailId: MedicalDetailId,
    })
  }, [])

  const [errors, setErrors] = useState<
    Record<string, string | Array<Record<string, string>>>
  >({
    haveEmergencyRoomVisitMedicalFacility: [{}],
    haveMedicalFacilityMedicalFacility: [{}],
    haveMentalHeathTreatmentMedicalFacility: [{}],
    haveInpatientOrOverNightStayMedicalFacility: [{}],
    haveOutpatientOrSamedayVisitMedicalFacility: [{}],
    haveSurgeriesMedicalFacility: [{}],
    havePhysicalTherapyMedicalFacility: [{}],
    jailDoctorDetail: [{}],
    welfareDetail: [{}],
    attorneyDetail: [{}],
    insuranceDetail: [{}],
    rehabilitationOrganizationDetail: [{}],
    workersCompensationDetail: [{}],
    otherOrganizationDetail: [{}],
  })

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string | Array<Record<string, string>>> = {
      haveEmergencyRoomVisitMedicalFacility: [{}],
      haveMedicalFacilityMedicalFacility: [{}],
      haveMentalHeathTreatmentMedicalFacility: [{}],
      haveInpatientOrOverNightStayMedicalFacility: [{}],
      haveOutpatientOrSamedayVisitMedicalFacility: [{}],
      haveSurgeriesMedicalFacility: [{}],
      havePhysicalTherapyMedicalFacility: [{}],
      jailDoctorDetail: [{}],
      welfareDetail: [{}],
      attorneyDetail: [{}],
      insuranceDetail: [{}],
      rehabilitationOrganizationDetail: [{}],
      workersCompensationDetail: [{}],
      otherOrganizationDetail: [{}],
    }

    const names: [
      'haveEmergencyRoomVisitMedicalFacility',
      'haveMedicalFacilityMedicalFacility',
      'haveMentalHeathTreatmentMedicalFacility',
      'haveInpatientOrOverNightStayMedicalFacility',
      'haveOutpatientOrSamedayVisitMedicalFacility',
      'haveSurgeriesMedicalFacility',
      'havePhysicalTherapyMedicalFacility',
    ] = [
      'haveEmergencyRoomVisitMedicalFacility',
      'haveMedicalFacilityMedicalFacility',
      'haveMentalHeathTreatmentMedicalFacility',
      'haveInpatientOrOverNightStayMedicalFacility',
      'haveOutpatientOrSamedayVisitMedicalFacility',
      'haveSurgeriesMedicalFacility',
      'havePhysicalTherapyMedicalFacility',
    ]

    const valids: [
      'haveEmergencyRoomVisit',
      'haveMedicalFacility',
      'haveMentalHeathTreatment',
      'haveInpatientOrOverNightStay',
      'haveOutpatientOrSamedayVisit',
      'haveSurgeries',
      'havePhysicalTherapy',
    ] = [
      'haveEmergencyRoomVisit',
      'haveMedicalFacility',
      'haveMentalHeathTreatment',
      'haveInpatientOrOverNightStay',
      'haveOutpatientOrSamedayVisit',
      'haveSurgeries',
      'havePhysicalTherapy',
    ]

    const OrgNames: [
      'jailDoctorDetail',
      'welfareDetail',
      'attorneyDetail',
      'insuranceDetail',
      'rehabilitationOrganizationDetail',
      'workersCompensationDetail',
      'otherOrganizationDetail',
    ] = [
      'jailDoctorDetail',
      'welfareDetail',
      'attorneyDetail',
      'insuranceDetail',
      'rehabilitationOrganizationDetail',
      'workersCompensationDetail',
      'otherOrganizationDetail',
    ]

    const Orgvalids: [
      'isDoctorInJail',
      'isPublicWelfare',
      'isRecordHeldByAttorney',
      'haveDisabilityInsurance',
      'isVocationalRehabilitationService',
      'isWorkersCompensation',
      'isOtherHaveMedicalRecords',
    ] = [
      'isDoctorInJail',
      'isPublicWelfare',
      'isRecordHeldByAttorney',
      'haveDisabilityInsurance',
      'isVocationalRehabilitationService',
      'isWorkersCompensation',
      'isOtherHaveMedicalRecords',
    ]
    const currentDate = new Date().toISOString().split('T')[0]
    // if (formValues?.haveEmergencyRoomVisit === undefined) {
    //   newErrors.haveEmergencyRoomVisit = 'Please select an option'
    //   valid = false
    // }
    // if (formValues?.haveMedicalFacility === undefined) {
    //   newErrors.haveMedicalFacility = 'Please select an option'
    //   valid = false
    // }
    // if (formValues?.haveMentalHeathTreatment === undefined) {
    //   newErrors.haveMentalHeathTreatment = 'Please select an option'
    //   valid = false
    // }
    // if (formValues?.haveInpatientOrOverNightStay === undefined) {
    //   newErrors.haveInpatientOrOverNightStay = 'Please select an option'
    //   valid = false
    // }
    // if (formValues?.haveOutpatientOrSamedayVisit === undefined) {
    //   newErrors.haveOutpatientOrSamedayVisit = 'Please select an option'
    //   valid = false
    // }
    // if (formValues?.haveSurgeries === undefined) {
    //   newErrors.haveSurgeries = 'Please select an option'
    //   valid = false
    // }

    for (let index = 0; index < valids.length; index++) {
      const element = valids[index]
      if (formValues?.[element] === undefined) {
        newErrors[element] = 'Please select an option'
        valid = false
      }
    }
    for (let index = 0; index < Orgvalids.length; index++) {
      const element = Orgvalids[index]
      if (formValues?.[element] === undefined) {
        newErrors[element] = 'Please select an option'
        valid = false
      }
    }

    // if (formValues?.isDoctorInJail === undefined) {
    //   newErrors.isDoctorInJail = 'Please select an option'
    //   valid = false
    // }
    // if (formValues?.isPublicWelfare === undefined) {
    //   newErrors.isPublicWelfare = 'Please select an option'
    //   valid = false
    // }
    // if (formValues?.isRecordHeldByAttorney === undefined) {
    //   newErrors.isRecordHeldByAttorney = 'Please select an option'
    //   valid = false
    // }
    // if (formValues?.haveDisabilityInsurance === undefined) {
    //   newErrors.haveDisabilityInsurance = 'Please select an option'
    //   valid = false
    // }
    // if (formValues?.isVocationalRehabilitationService === undefined) {
    //   newErrors.isVocationalRehabilitationService = 'Please select an option'
    //   valid = false
    // }
    // if (formValues?.isWorkersCompensation === undefined) {
    //   newErrors.isWorkersCompensation = 'Please select an option'
    //   valid = false
    // }
    // if (formValues?.isOtherHaveMedicalRecords === undefined) {
    //   newErrors.isOtherHaveMedicalRecords = 'Please select an option'
    //   valid = false
    // }

    for (let index = 0; index < OrgNames.length; index++) {
      const element = OrgNames[index]

      if (formValues?.[Orgvalids[index]] === true) {
        for (let i = 0; i < formValues[element]?.length; i++) {
          if (!formValues[element]?.[i]?.organizationName.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    organizationName: 'Organization Name is required',
                  }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].address.streetAddress.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    streetAddress: 'Street Address is required',
                  }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].address.city.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index) return { ...error, city: 'City is required' }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].address.state.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index) return { ...error, state: 'State is required' }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].address.postalCode.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, postalCode: 'Postal Code is required' }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].address.country.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, country: 'Country is required' }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].phoneNumber.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    phoneNumber: 'Phone Number is required',
                  }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].contactPersonFirstName.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    contactPersonFirstName: 'First Name is required',
                  }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].contactPersonLastName.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    contactPersonLastName: 'Last Name is required',
                  }
                return error
              },
            )
            valid = false
          }
          if (formValues[element][i]?.arrivedDate === '') {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    arrivedDate: 'Arrived Date is required',
                  }
                return error
              },
            )
            valid = false
          }
          if (formValues[element][i]?.arrivedDate > currentDate) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    arrivedDate: 'Arrived Date cannot be in the future',
                  }
                return error
              },
            )
            valid = false
          }
          if (formValues[element][i]?.leftDate === '') {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    leftDate: 'Left Date is required',
                  }
                return error
              },
            )
            valid = false
          }
          if (formValues[element][i]?.leftDate > currentDate) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    leftDate: 'Left Date cannot be in the future',
                  }
                return error
              },
            )
            valid = false
          }
          newErrors[element] = [...newErrors[element], {}]
        }
      }
    }

    for (let index = 0; index < names.length; index++) {
      const element = names[index]

      if (formValues?.[valids[index]] === true) {
        for (let i = 0; i < formValues[element]?.length; i++) {
          if (!formValues[element]?.[i]?.hospitalName.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    hospitalName: 'Hospital Name is required',
                  }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].address.streetAddress.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    streetAddress: 'Street Address is required',
                  }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].address.city.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index) return { ...error, city: 'City is required' }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].address.state.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index) return { ...error, state: 'State is required' }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].address.postalCode.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, postalCode: 'Postal Code is required' }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].address.country.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return { ...error, country: 'Country is required' }
                return error
              },
            )
            valid = false
          }
          if (!formValues[element][i].phoneNumber.trim()) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    phoneNumber: 'Phone Number is required',
                  }
                return error
              },
            )
            valid = false
          }
          if (formValues[element][i]?.arrivedDate === '') {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    arrivedDate: 'Arrived Date is required',
                  }
                return error
              },
            )
            valid = false
          }
          if (formValues[element][i]?.arrivedDate > currentDate) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    arrivedDate: 'Arrived Date cannot be in the future',
                  }
                return error
              },
            )
            valid = false
          }
          if (formValues[element][i]?.leftDate === '') {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    leftDate: 'Left Date is required',
                  }
                return error
              },
            )
            valid = false
          }
          if (formValues[element][i]?.leftDate > currentDate) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    leftDate: 'Left Date cannot be in the future',
                  }
                return error
              },
            )
            valid = false
          }
          if (formValues[element][i]?.treatmentType.length === 0) {
            newErrors[element] = newErrors[element]?.map(
              (error: any, index: number) => {
                if (i === index)
                  return {
                    ...error,
                    treatmentType: 'Treatment Type is required',
                  }
                return error
              },
            )
            valid = false
          }
          newErrors[element] = [...newErrors[element], {}]
        }
      }
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValuesToSubmit: typeof formValues = {}
    formValuesToSubmit.medicalDetailId = formValues.medicalDetailId

    if (formValues.haveEmergencyRoomVisit === false) {
      formValuesToSubmit.haveEmergencyRoomVisit =
        formValues.haveEmergencyRoomVisit
    } else {
      formValuesToSubmit.haveEmergencyRoomVisit =
        formValues.haveEmergencyRoomVisit
      formValuesToSubmit.haveEmergencyRoomVisitMedicalFacility =
        formValues.haveEmergencyRoomVisitMedicalFacility
    }

    if (formValues.haveMedicalFacility === false) {
      formValuesToSubmit.haveMedicalFacility = formValues.haveMedicalFacility
    } else {
      formValuesToSubmit.haveMedicalFacility = formValues.haveMedicalFacility
      formValuesToSubmit.haveMedicalFacilityMedicalFacility =
        formValues.haveMedicalFacilityMedicalFacility
    }

    if (formValues.haveMentalHeathTreatment === false) {
      formValuesToSubmit.haveMentalHeathTreatment =
        formValues.haveMentalHeathTreatment
    } else {
      formValuesToSubmit.haveMentalHeathTreatment =
        formValues.haveMentalHeathTreatment
      formValuesToSubmit.haveMentalHeathTreatmentMedicalFacility =
        formValues.haveMentalHeathTreatmentMedicalFacility
    }

    if (formValues.haveInpatientOrOverNightStay === false) {
      formValuesToSubmit.haveInpatientOrOverNightStay =
        formValues.haveInpatientOrOverNightStay
    } else {
      formValuesToSubmit.haveInpatientOrOverNightStay =
        formValues.haveInpatientOrOverNightStay
      formValuesToSubmit.haveInpatientOrOverNightStayMedicalFacility =
        formValues.haveInpatientOrOverNightStayMedicalFacility
    }

    if (formValues.haveOutpatientOrSamedayVisit === false) {
      formValuesToSubmit.haveOutpatientOrSamedayVisit =
        formValues.haveOutpatientOrSamedayVisit
    } else {
      formValuesToSubmit.haveOutpatientOrSamedayVisit =
        formValues.haveOutpatientOrSamedayVisit
      formValuesToSubmit.haveOutpatientOrSamedayVisitMedicalFacility =
        formValues.haveOutpatientOrSamedayVisitMedicalFacility
    }

    if (formValues.haveSurgeries === false) {
      formValuesToSubmit.haveSurgeries = formValues.haveSurgeries
    } else {
      formValuesToSubmit.haveSurgeries = formValues.haveSurgeries
      formValuesToSubmit.haveSurgeriesMedicalFacility =
        formValues.haveSurgeriesMedicalFacility
    }

    if (formValues.havePhysicalTherapy === false) {
      formValuesToSubmit.havePhysicalTherapy = formValues.havePhysicalTherapy
    } else {
      formValuesToSubmit.havePhysicalTherapy = formValues.havePhysicalTherapy
      formValuesToSubmit.havePhysicalTherapyMedicalFacility =
        formValues.havePhysicalTherapyMedicalFacility
    }

    if (formValues.isDoctorInJail === false) {
      formValuesToSubmit.isDoctorInJail = formValues.isDoctorInJail
    } else {
      formValuesToSubmit.isDoctorInJail = formValues.isDoctorInJail
      formValuesToSubmit.jailDoctorDetail = formValues.jailDoctorDetail
    }

    if (formValues.isPublicWelfare === false) {
      formValuesToSubmit.isPublicWelfare = formValues.isPublicWelfare
    } else {
      formValuesToSubmit.isPublicWelfare = formValues.isPublicWelfare
      formValuesToSubmit.welfareDetail = formValues.welfareDetail
    }

    if (formValues.isRecordHeldByAttorney === false) {
      formValuesToSubmit.isRecordHeldByAttorney =
        formValues.isRecordHeldByAttorney
    } else {
      formValuesToSubmit.isRecordHeldByAttorney =
        formValues.isRecordHeldByAttorney
      formValuesToSubmit.attorneyDetail = formValues.attorneyDetail
    }

    if (formValues.haveDisabilityInsurance === false) {
      formValuesToSubmit.haveDisabilityInsurance =
        formValues.haveDisabilityInsurance
    } else {
      formValuesToSubmit.haveDisabilityInsurance =
        formValues.haveDisabilityInsurance
      formValuesToSubmit.insuranceDetail = formValues.insuranceDetail
    }

    if (formValues.isVocationalRehabilitationService === false) {
      formValuesToSubmit.isVocationalRehabilitationService =
        formValues.isVocationalRehabilitationService
    } else {
      formValuesToSubmit.isVocationalRehabilitationService =
        formValues.isVocationalRehabilitationService
      formValuesToSubmit.rehabilitationOrganizationDetail =
        formValues.rehabilitationOrganizationDetail
    }

    if (formValues.isWorkersCompensation === false) {
      formValuesToSubmit.isWorkersCompensation =
        formValues.isWorkersCompensation
    } else {
      formValuesToSubmit.isWorkersCompensation =
        formValues.isWorkersCompensation
      formValuesToSubmit.workersCompensationDetail =
        formValues.workersCompensationDetail
    }

    if (formValues.isOtherHaveMedicalRecords === false) {
      formValuesToSubmit.isOtherHaveMedicalRecords =
        formValues.isOtherHaveMedicalRecords
    } else {
      formValuesToSubmit.isOtherHaveMedicalRecords =
        formValues.isOtherHaveMedicalRecords
      formValuesToSubmit.otherOrganizationDetail =
        formValues.otherOrganizationDetail
    }

    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      dispatch(AddHospitalAndMedicalFacilityCare(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            dispatch(setHospitalAndMedicalFacilityCare(formValues))
            handleEditToggle()
          }
        },
      )
    }
  }

  const handleDetailBoxValuesChange = (
    index: number,
    data: { name: string; value: string },
    name:
      | 'haveEmergencyRoomVisitMedicalFacility'
      | 'haveMedicalFacilityMedicalFacility'
      | 'haveMentalHeathTreatmentMedicalFacility'
      | 'haveInpatientOrOverNightStayMedicalFacility'
      | 'haveOutpatientOrSamedayVisitMedicalFacility'
      | 'haveSurgeriesMedicalFacility'
      | 'havePhysicalTherapyMedicalFacility'
      | 'jailDoctorDetail'
      | 'welfareDetail'
      | 'attorneyDetail'
      | 'insuranceDetail'
      | 'rehabilitationOrganizationDetail'
      | 'workersCompensationDetail'
      | 'otherOrganizationDetail',
  ) => {
    let newProfileForms = [...formValues[name]]
    newProfileForms = newProfileForms?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [data.name]: data.value,
        }
      }
      return item
    })
    setFormValues({ ...formValues, [name]: newProfileForms })
  }

  const handleDetailBoxRemove = (
    index: number,
    name:
      | 'haveEmergencyRoomVisitMedicalFacility'
      | 'haveMedicalFacilityMedicalFacility'
      | 'haveMentalHeathTreatmentMedicalFacility'
      | 'haveInpatientOrOverNightStayMedicalFacility'
      | 'haveOutpatientOrSamedayVisitMedicalFacility'
      | 'haveSurgeriesMedicalFacility'
      | 'havePhysicalTherapyMedicalFacility'
      | 'jailDoctorDetail'
      | 'welfareDetail'
      | 'attorneyDetail'
      | 'insuranceDetail'
      | 'rehabilitationOrganizationDetail'
      | 'workersCompensationDetail'
      | 'otherOrganizationDetail',
  ) => {
    const newProfileForms = [...formValues[name]]
    newProfileForms.splice(index, 1)
    setFormValues({ ...formValues, [name]: newProfileForms })
  }

  const addDetailBox = (
    name:
      | 'haveEmergencyRoomVisitMedicalFacility'
      | 'haveMedicalFacilityMedicalFacility'
      | 'haveMentalHeathTreatmentMedicalFacility'
      | 'haveInpatientOrOverNightStayMedicalFacility'
      | 'haveOutpatientOrSamedayVisitMedicalFacility'
      | 'haveSurgeriesMedicalFacility'
      | 'havePhysicalTherapyMedicalFacility'
      | 'jailDoctorDetail'
      | 'welfareDetail'
      | 'attorneyDetail'
      | 'insuranceDetail'
      | 'rehabilitationOrganizationDetail'
      | 'workersCompensationDetail'
      | 'otherOrganizationDetail',
    type: 'hospitalValues' | 'organizationValues',
  ) => {
    if (
      typeof formValues[name] === typeof formValues.attorneyDetail ||
      typeof formValues[name] ===
        typeof formValues.haveMentalHeathTreatmentMedicalFacility
    ) {
      type === 'hospitalValues' &&
        setFormValues({
          ...formValues,
          [name]: [...formValues[name], hospitalValues],
        })
      type === 'organizationValues' &&
        setFormValues({
          ...formValues,
          [name]: [...formValues[name], organizationValues],
        })
    }
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox
            heading="We appreciate you sharing these details with us. 
          Now, let's switch gears to better understand if you've seen or been treated 
          by at least one doctor or medical professional for any conditions since
          you've been unable to work."
          />

          <StarText
            text={`Be sure to have your medical records handy for this section
                because specific names, dates, and types of treatment or tests
                will be gathered.`}
          />

          <Box>
            <Box height={'30px'} width={'100%'} />

            <CommonRadioBox
              error={errors?.haveEmergencyRoomVisit}
              title='Have you seen a medical professional or been to a medical facility for an emergency room visit?'
              commonState={formValues.haveEmergencyRoomVisit}
              setcommonState={(value: boolean) => {
                setFormValues({ ...formValues, haveEmergencyRoomVisit: value })
              }}
              YesTitle='I have seen a medical professional and been to a medical facility for an emergency room visit'
              NoTitle='I have not seen a medical professional or been to a medical facility for an emergency room visit'
            />

            {formValues?.haveEmergencyRoomVisit ? (
              <>
                <Box
                  my={'20px'}
                  gap={'30px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography className={Style.label}>
                    Please share the details of the hospital, urgent care,
                    clinic, or medical facility that treated you for your
                    emergency room visit.
                  </Typography>

                  {formValues?.haveEmergencyRoomVisitMedicalFacility?.map(
                    (form, index: number) => (
                      <HospitalDetailsBox
                        key={index}
                        realValue={formValues}
                        handleRemove={() => {
                          handleDetailBoxRemove(
                            index,
                            'haveEmergencyRoomVisitMedicalFacility',
                          )
                        }}
                        errors={
                          errors.haveEmergencyRoomVisitMedicalFacility?.[index]
                        }
                        index={index}
                        fieldName={'haveEmergencyRoomVisitMedicalFacility'}
                        formValues={form}
                        changeHandler={(data: {
                          name: string
                          value: string
                        }) => {
                          handleDetailBoxValuesChange(
                            index,
                            data,
                            'haveEmergencyRoomVisitMedicalFacility',
                          )
                        }}
                        setFormValues={setFormValues}
                      />
                    ),
                  )}

                  <AddButton
                    title='Details of the hospital, urgent care, clinic, or medical facility'
                    subTitle='For an effective records search, It is important that all service be shown below'
                    button='Add Medical Facility'
                    onClick={() => {
                      addDetailBox(
                        'haveEmergencyRoomVisitMedicalFacility',
                        'hospitalValues',
                      )
                    }}
                  />
                </Box>
              </>
            ) : (
              <></>
            )}

            <Box height={'30px'} width={'100%'} />

            <CommonRadioBox
              error={errors?.haveMedicalFacility}
              title='Have you seen a medical professional or been to a medical facility for any medical tests or medications ordered by this facility?'
              commonState={formValues.haveMedicalFacility}
              setcommonState={(value: boolean) => {
                setFormValues({ ...formValues, haveMedicalFacility: value })
              }}
              YesTitle='I have seen a medical professional and been to a medical facility for medical tests or medications ordered by this facility'
              NoTitle='I have not seen a medical professional or been to a medical facility for any medical tests or medications ordered by this facility'
            />

            {formValues?.haveMedicalFacility ? (
              <>
                <Box
                  my={'20px'}
                  gap={'30px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography className={Style.label}>
                    Please share the details of the hospital, urgent care,
                    clinic, or medical facility that treated you for any medical
                    tests or medications ordered by this facility.
                  </Typography>

                  {formValues?.haveMedicalFacilityMedicalFacility?.map(
                    (form, index: Key | null | undefined) => (
                      <HospitalDetailsBox
                        key={index}
                        realValue={formValues}
                        handleRemove={() => {
                          handleDetailBoxRemove(
                            index,
                            'haveMedicalFacilityMedicalFacility',
                          )
                        }}
                        errors={
                          errors.haveMedicalFacilityMedicalFacility?.[index]
                        }
                        index={index}
                        fieldName={'haveMedicalFacilityMedicalFacility'}
                        formValues={form}
                        changeHandler={(data: {
                          name: string
                          value: string
                        }) => {
                          handleDetailBoxValuesChange(
                            index,
                            data,
                            'haveMedicalFacilityMedicalFacility',
                          )
                        }}
                        setFormValues={setFormValues}
                      />
                    ),
                  )}

                  <AddButton
                    title='Details of the hospital, urgent care, clinic, or medical facility'
                    subTitle='For an effective records search, It is important that all service be shown below'
                    button='Add Medical Facility'
                    onClick={() => {
                      addDetailBox(
                        'haveMedicalFacilityMedicalFacility',
                        'hospitalValues',
                      )
                    }}
                  />
                </Box>
              </>
            ) : (
              <></>
            )}

            <Box height={'30px'} width={'100%'} />

            <CommonRadioBox
              error={errors?.haveMentalHeathTreatment}
              title='Have you seen a medical professional or been to a medical facility for any mental health treatment or evaluations?'
              commonState={formValues.haveMentalHeathTreatment}
              setcommonState={(value: boolean) => {
                setFormValues({
                  ...formValues,
                  haveMentalHeathTreatment: value,
                })
              }}
              YesTitle='I have seen a medical professional and been to a medical facility for mental health treatment or evaluations'
              NoTitle='I have not seen a medical professional or been to a medical facility for any mental health treatment or evaluations'
            />

            {formValues.haveMentalHeathTreatment ? (
              <>
                <Box
                  my={'20px'}
                  gap={'30px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography className={Style.label}>
                    Please share the details of the hospital, urgent care,
                    clinic, or medical facility that treated you for any mental
                    health treatment or evaluations'.
                  </Typography>

                  {formValues?.haveMentalHeathTreatmentMedicalFacility?.map(
                    (form, index: Key | null | undefined) => (
                      <HospitalDetailsBox
                        key={index}
                        realValue={formValues}
                        handleRemove={() => {
                          handleDetailBoxRemove(
                            index,
                            'haveMentalHeathTreatmentMedicalFacility',
                          )
                        }}
                        fieldName={'haveMentalHeathTreatmentMedicalFacility'}
                        errors={
                          errors.haveMentalHeathTreatmentMedicalFacility?.[
                            index
                          ]
                        }
                        index={index}
                        formValues={form}
                        changeHandler={(data: {
                          name: string
                          value: string
                        }) => {
                          handleDetailBoxValuesChange(
                            index,
                            data,
                            'haveMentalHeathTreatmentMedicalFacility',
                          )
                        }}
                        setFormValues={setFormValues}
                      />
                    ),
                  )}

                  <AddButton
                    title='Details of the hospital, urgent care, clinic, or medical facility'
                    subTitle='For an effective records search, It is important that all service be shown below'
                    button='Add Medical Facility'
                    onClick={() => {
                      addDetailBox(
                        'haveMentalHeathTreatmentMedicalFacility',
                        'hospitalValues',
                      )
                    }}
                  />
                </Box>
              </>
            ) : (
              <></>
            )}

            <Box height={'30px'} width={'100%'} />

            <CommonRadioBox
              error={errors?.haveInpatientOrOverNightStay}
              title='Have you seen a medical professional or been to a medical facility for any inpatient or overnight stays?'
              commonState={formValues.haveInpatientOrOverNightStay}
              setcommonState={(value: boolean) => {
                setFormValues({
                  ...formValues,
                  haveInpatientOrOverNightStay: value,
                })
              }}
              YesTitle='I have seen a medical professional and been to a medical facility for inpatient or overnight stays'
              NoTitle='I have not seen a medical professional or been to a medical facility for inpatient or overnight stays'
            />

            {formValues.haveInpatientOrOverNightStay ? (
              <>
                <Box
                  my={'20px'}
                  gap={'30px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography className={Style.label}>
                    Please share the details of the hospital, urgent care,
                    clinic, or medical facility that treated you for any
                    inpatient or overnight stays.
                  </Typography>

                  {formValues?.haveInpatientOrOverNightStayMedicalFacility?.map(
                    (form, index: Key | null | undefined) => (
                      <HospitalDetailsBox
                        key={index}
                        realValue={formValues}
                        handleRemove={() => {
                          handleDetailBoxRemove(
                            index,
                            'haveInpatientOrOverNightStayMedicalFacility',
                          )
                        }}
                        fieldName={
                          'haveInpatientOrOverNightStayMedicalFacility'
                        }
                        errors={
                          errors.haveInpatientOrOverNightStayMedicalFacility?.[
                            index
                          ]
                        }
                        index={index}
                        formValues={form}
                        changeHandler={(data: {
                          name: string
                          value: string
                        }) => {
                          handleDetailBoxValuesChange(
                            index,
                            data,
                            'haveInpatientOrOverNightStayMedicalFacility',
                          )
                        }}
                        setFormValues={setFormValues}
                      />
                    ),
                  )}

                  <AddButton
                    title='Details of the hospital, urgent care, clinic, or medical facility'
                    subTitle='For an effective records search, It is important that all service be shown below'
                    button='Add Medical Facility'
                    onClick={() => {
                      addDetailBox(
                        'haveInpatientOrOverNightStayMedicalFacility',
                        'hospitalValues',
                      )
                    }}
                  />
                </Box>
              </>
            ) : (
              <></>
            )}

            <Box height={'30px'} width={'100%'} />

            <CommonRadioBox
              error={errors?.haveOutpatientOrSamedayVisit}
              title='Have you seen a medical professional or been to a medical facility for any outpatient or same-day visits?'
              commonState={formValues.haveOutpatientOrSamedayVisit}
              setcommonState={(value: boolean) => {
                setFormValues({
                  ...formValues,
                  haveOutpatientOrSamedayVisit: value,
                })
              }}
              YesTitle='I have seen a medical professional and been to a medical facility for outpatient or same-day visits'
              NoTitle='I have not seen a medical professional or been to a medical facility for outpatient or same-day visits'
            />

            {formValues.haveOutpatientOrSamedayVisit ? (
              <>
                <Box
                  my={'20px'}
                  gap={'30px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography className={Style.label}>
                    Please share the details of the hospital, urgent care,
                    clinic, or medical facility that treated you for any
                    outpatient or same-day visits.
                  </Typography>

                  {formValues?.haveOutpatientOrSamedayVisitMedicalFacility?.map(
                    (form, index: Key | null | undefined) => (
                      <HospitalDetailsBox
                        key={index}
                        realValue={formValues}
                        handleRemove={() => {
                          handleDetailBoxRemove(
                            index,
                            'haveOutpatientOrSamedayVisitMedicalFacility',
                          )
                        }}
                        fieldName={
                          'haveOutpatientOrSamedayVisitMedicalFacility'
                        }
                        errors={
                          errors.haveOutpatientOrSamedayVisitMedicalFacility?.[
                            index
                          ]
                        }
                        index={index}
                        formValues={form}
                        changeHandler={(data: {
                          name: string
                          value: string
                        }) => {
                          handleDetailBoxValuesChange(
                            index,
                            data,
                            'haveOutpatientOrSamedayVisitMedicalFacility',
                          )
                        }}
                        setFormValues={setFormValues}
                      />
                    ),
                  )}

                  <AddButton
                    title='Details of the hospital, urgent care, clinic, or medical facility'
                    subTitle='For an effective records search, It is important that all service be shown below'
                    button='Add Medical Facility'
                    onClick={() => {
                      addDetailBox(
                        'haveOutpatientOrSamedayVisitMedicalFacility',
                        'hospitalValues',
                      )
                    }}
                  />
                </Box>
              </>
            ) : (
              <></>
            )}

            <Box height={'30px'} width={'100%'} />

            <CommonRadioBox
              error={errors?.haveSurgeries}
              title='Have you seen a medical professional or been to a medical facility for any surgeries?'
              commonState={formValues.haveSurgeries}
              setcommonState={(value: boolean) => {
                setFormValues({ ...formValues, haveSurgeries: value })
              }}
              YesTitle='I have seen a medical professional and been to a medical facility for surgeries'
              NoTitle='I have not seen a medical professional or been to a medical facility for any surgeries'
            />

            {formValues.haveSurgeries ? (
              <>
                <Box
                  my={'20px'}
                  gap={'30px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography className={Style.label}>
                    Please share the details of the hospital, urgent care,
                    clinic, or medical facility that treated you for any
                    surgeries.
                  </Typography>

                  {formValues?.haveSurgeriesMedicalFacility?.map(
                    (form, index: Key | null | undefined) => (
                      <HospitalDetailsBox
                        key={index}
                        realValue={formValues}
                        handleRemove={() => {
                          handleDetailBoxRemove(
                            index,
                            'haveSurgeriesMedicalFacility',
                          )
                        }}
                        fieldName={'haveSurgeriesMedicalFacility'}
                        errors={errors.haveSurgeriesMedicalFacility?.[index]}
                        index={index}
                        formValues={form}
                        changeHandler={(data: {
                          name: string
                          value: string
                        }) => {
                          handleDetailBoxValuesChange(
                            index,
                            data,
                            'haveSurgeriesMedicalFacility',
                          )
                        }}
                        setFormValues={setFormValues}
                      />
                    ),
                  )}

                  <AddButton
                    title='Details of the hospital, urgent care, clinic, or medical facility'
                    subTitle='For an effective records search, It is important that all service be shown below'
                    button='Add Medical Facility'
                    onClick={() => {
                      addDetailBox(
                        'haveSurgeriesMedicalFacility',
                        'hospitalValues',
                      )
                    }}
                  />
                </Box>
              </>
            ) : (
              <></>
            )}

            <Box height={'30px'} width={'100%'} />

            <CommonRadioBox
              error={errors?.havePhysicalTherapy}
              title='Have you seen a medical professional or been to a medical facility for any physical therapy?'
              commonState={formValues.havePhysicalTherapy}
              setcommonState={(value: boolean) => {
                setFormValues({ ...formValues, havePhysicalTherapy: value })
              }}
              YesTitle='I have seen a medical professional and been to a medical facility for physical therapy'
              NoTitle='I have not seen a medical professional or been to a medical facility for physical therapy'
            />

            {formValues.havePhysicalTherapy ? (
              <>
                <Box
                  my={'20px'}
                  gap={'30px'}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <Typography className={Style.label}>
                    Please share the details of the hospital, urgent care,
                    clinic, or medical facility that treated you for any
                    physical therapy.
                  </Typography>

                  {formValues?.havePhysicalTherapyMedicalFacility?.map(
                    (form, index: Key | null | undefined) => (
                      <HospitalDetailsBox
                        key={index}
                        realValue={formValues}
                        handleRemove={() => {
                          handleDetailBoxRemove(
                            index,
                            'havePhysicalTherapyMedicalFacility',
                          )
                        }}
                        fieldName={'havePhysicalTherapyMedicalFacility'}
                        errors={
                          errors.havePhysicalTherapyMedicalFacility?.[index]
                        }
                        index={index}
                        formValues={form}
                        changeHandler={(data: {
                          name: string
                          value: string
                        }) => {
                          handleDetailBoxValuesChange(
                            index,
                            data,
                            'havePhysicalTherapyMedicalFacility',
                          )
                        }}
                        setFormValues={setFormValues}
                      />
                    ),
                  )}

                  <AddButton
                    title='Details of the hospital, urgent care, clinic, or medical facility'
                    subTitle='For an effective records search, It is important that all service be shown below'
                    button='Add Medical Facility'
                    onClick={() => {
                      addDetailBox(
                        'havePhysicalTherapyMedicalFacility',
                        'hospitalValues',
                      )
                    }}
                  />
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>

          <Box height={'60px'} width={'100%'} />
          <StarText
            text={`Before we move on, we want to be certain that we've gathered all of the organizations that may have your medical records.`}
          />
          <Box height={'30px'} width={'100%'} />

          <SmallRadioBox
            name='isDoctorInJail'
            value={formValues.isDoctorInJail}
            onClick={(value) => {
              setFormValues({ ...formValues, isDoctorInJail: value })
            }}
            title='Doctors in a prison or jail'
            error={errors.isDoctorInJail}
          />

          {formValues.isDoctorInJail ? (
            <>
              <Box
                my={'20px'}
                gap={'30px'}
                display={'flex'}
                flexDirection={'column'}
              >
                <Typography className={Style.label}>
                  Please share the details of the Doctors in a prison or jail
                  that has your medical records.
                </Typography>

                {formValues?.jailDoctorDetail?.map(
                  (form, index: Key | null | undefined) => (
                    <OrganizationDetailBox
                      key={index}
                      realValue={formValues}
                      handleRemove={() => {
                        handleDetailBoxRemove(index, 'jailDoctorDetail')
                      }}
                      fieldName={'jailDoctorDetail'}
                      errors={errors.jailDoctorDetail?.[index]}
                      index={index}
                      formValues={form}
                      changeHandler={(data: {
                        name: string
                        value: string
                      }) => {
                        handleDetailBoxValuesChange(
                          index,
                          data,
                          'jailDoctorDetail',
                        )
                      }}
                      setFormValues={setFormValues}
                    />
                  ),
                )}

                <AddButton
                  title='Details of the hospital, urgent care, clinic, or medical facility'
                  subTitle='For an effective records search, It is important that all service be shown below'
                  button='Add Medical Facility'
                  onClick={() => {
                    addDetailBox('jailDoctorDetail', 'organizationValues')
                  }}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <SmallRadioBox
            name='isPublicWelfare'
            value={formValues.isPublicWelfare}
            onClick={(value) => {
              setFormValues({ ...formValues, isPublicWelfare: value })
            }}
            title='Public welfare'
            error={errors.isPublicWelfare}
          />

          {formValues.isPublicWelfare ? (
            <>
              <Box
                my={'20px'}
                gap={'30px'}
                display={'flex'}
                flexDirection={'column'}
              >
                <Typography className={Style.label}>
                  Please share the details of the Public welfare that has your
                  medical records.
                </Typography>

                {formValues?.welfareDetail?.map(
                  (form, index: Key | null | undefined) => (
                    <OrganizationDetailBox
                      key={index}
                      realValue={formValues}
                      handleRemove={() => {
                        handleDetailBoxRemove(index, 'welfareDetail')
                      }}
                      fieldName={'welfareDetail'}
                      errors={errors.welfareDetail?.[index]}
                      index={index}
                      formValues={form}
                      changeHandler={(data: {
                        name: string
                        value: string
                      }) => {
                        handleDetailBoxValuesChange(
                          index,
                          data,
                          'welfareDetail',
                        )
                      }}
                      setFormValues={setFormValues}
                    />
                  ),
                )}

                <AddButton
                  title='Details of the hospital, urgent care, clinic, or medical facility'
                  subTitle='For an effective records search, It is important that all service be shown below'
                  button='Add Medical Facility'
                  onClick={() => {
                    addDetailBox('welfareDetail', 'organizationValues')
                  }}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <SmallRadioBox
            name='isRecordHeldByAttorney'
            value={formValues.isRecordHeldByAttorney}
            onClick={(value) => {
              setFormValues({ ...formValues, isRecordHeldByAttorney: value })
            }}
            title='Records held by an attorney/lawyer'
            error={errors.isRecordHeldByAttorney}
          />

          {formValues.isRecordHeldByAttorney ? (
            <>
              <Box
                my={'20px'}
                gap={'30px'}
                display={'flex'}
                flexDirection={'column'}
              >
                <Typography className={Style.label}>
                  Please share the details of the attorney/lawyer that has your
                  medical records.
                </Typography>

                {formValues?.attorneyDetail?.map(
                  (form, index: Key | null | undefined) => (
                    <OrganizationDetailBox
                      key={index}
                      realValue={formValues}
                      handleRemove={() => {
                        handleDetailBoxRemove(index, 'attorneyDetail')
                      }}
                      fieldName={'attorneyDetail'}
                      errors={errors.attorneyDetail?.[index]}
                      index={index}
                      formValues={form}
                      changeHandler={(data: {
                        name: string
                        value: string
                      }) => {
                        handleDetailBoxValuesChange(
                          index,
                          data,
                          'attorneyDetail',
                        )
                      }}
                      setFormValues={setFormValues}
                    />
                  ),
                )}

                <AddButton
                  title='Details of the hospital, urgent care, clinic, or medical facility'
                  subTitle='For an effective records search, It is important that all service be shown below'
                  button='Add Medical Facility'
                  onClick={() => {
                    addDetailBox('attorneyDetail', 'organizationValues')
                  }}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <SmallRadioBox
            name='haveDisabilityInsurance'
            value={formValues.haveDisabilityInsurance}
            onClick={(value) => {
              setFormValues({ ...formValues, haveDisabilityInsurance: value })
            }}
            title='Short-term or long-term disability insurance (Excluding private health insurance)'
            error={errors.haveDisabilityInsurance}
          />

          {formValues.haveDisabilityInsurance ? (
            <>
              <Box
                my={'20px'}
                gap={'30px'}
                display={'flex'}
                flexDirection={'column'}
              >
                <Typography className={Style.label}>
                  Please share the details of the Short-term or long-term
                  disability insurance that has your medical records.
                </Typography>

                {formValues?.insuranceDetail?.map(
                  (form, index: Key | null | undefined) => (
                    <OrganizationDetailBox
                      key={index}
                      realValue={formValues}
                      handleRemove={() => {
                        handleDetailBoxRemove(index, 'insuranceDetail')
                      }}
                      fieldName={'insuranceDetail'}
                      errors={errors.insuranceDetail?.[index]}
                      index={index}
                      formValues={form}
                      changeHandler={(data: {
                        name: string
                        value: string
                      }) => {
                        handleDetailBoxValuesChange(
                          index,
                          data,
                          'insuranceDetail',
                        )
                      }}
                      setFormValues={setFormValues}
                    />
                  ),
                )}

                <AddButton
                  title='Details of the hospital, urgent care, clinic, or medical facility'
                  subTitle='For an effective records search, It is important that all service be shown below'
                  button='Add Medical Facility'
                  onClick={() => {
                    addDetailBox('insuranceDetail', 'organizationValues')
                  }}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <SmallRadioBox
            name='isVocationalRehabilitationService'
            value={formValues.isVocationalRehabilitationService}
            onClick={(value) => {
              setFormValues({
                ...formValues,
                isVocationalRehabilitationService: value,
              })
            }}
            title='Vocational rehabilitation services'
            error={errors.isVocationalRehabilitationService}
          />

          {formValues.isVocationalRehabilitationService ? (
            <>
              <Box
                my={'20px'}
                gap={'30px'}
                display={'flex'}
                flexDirection={'column'}
              >
                <Typography className={Style.label}>
                  Please share the details of the Vocational rehabilitation
                  services that has your medical records.
                </Typography>

                {formValues?.rehabilitationOrganizationDetail?.map(
                  (form, index: Key | null | undefined) => (
                    <OrganizationDetailBox
                      key={index}
                      realValue={formValues}
                      handleRemove={() => {
                        handleDetailBoxRemove(
                          index,
                          'rehabilitationOrganizationDetail',
                        )
                      }}
                      fieldName={'rehabilitationOrganizationDetail'}
                      errors={errors.rehabilitationOrganizationDetail?.[index]}
                      index={index}
                      formValues={form}
                      changeHandler={(data: {
                        name: string
                        value: string
                      }) => {
                        handleDetailBoxValuesChange(
                          index,
                          data,
                          'rehabilitationOrganizationDetail',
                        )
                      }}
                      setFormValues={setFormValues}
                    />
                  ),
                )}

                <AddButton
                  title='Details of the hospital, urgent care, clinic, or medical facility'
                  subTitle='For an effective records search, It is important that all service be shown below'
                  button='Add Medical Facility'
                  onClick={() => {
                    addDetailBox(
                      'rehabilitationOrganizationDetail',
                      'organizationValues',
                    )
                  }}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <SmallRadioBox
            name='isWorkersCompensation'
            value={formValues.isWorkersCompensation}
            onClick={(value) => {
              setFormValues({ ...formValues, isWorkersCompensation: value })
            }}
            title='Workers compensation'
            error={errors.isWorkersCompensation}
          />

          {formValues.isWorkersCompensation ? (
            <>
              <Box
                my={'20px'}
                gap={'30px'}
                display={'flex'}
                flexDirection={'column'}
              >
                <Typography className={Style.label}>
                  Please share the details of the workers' compensation
                  organization that has your medical records.
                </Typography>

                {formValues?.workersCompensationDetail?.map(
                  (form, index: Key | null | undefined) => (
                    <OrganizationDetailBox
                      key={index}
                      realValue={formValues}
                      handleRemove={() => {
                        handleDetailBoxRemove(
                          index,
                          'workersCompensationDetail',
                        )
                      }}
                      fieldName={'workersCompensationDetail'}
                      errors={errors.workersCompensationDetail?.[index]}
                      index={index}
                      formValues={form}
                      changeHandler={(data: {
                        name: string
                        value: string
                      }) => {
                        handleDetailBoxValuesChange(
                          index,
                          data,
                          'workersCompensationDetail',
                        )
                      }}
                      setFormValues={setFormValues}
                    />
                  ),
                )}

                <AddButton
                  title='Details of the hospital, urgent care, clinic, or medical facility'
                  subTitle='For an effective records search, It is important that all service be shown below'
                  button='Add Medical Facility'
                  onClick={() => {
                    addDetailBox(
                      'workersCompensationDetail',
                      'organizationValues',
                    )
                  }}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <SmallRadioBox
            name='isOtherHaveMedicalRecords'
            value={formValues.isOtherHaveMedicalRecords}
            onClick={(value) => {
              setFormValues({ ...formValues, isOtherHaveMedicalRecords: value })
            }}
            title='Other, please explain (Excluding private health insurance)'
            error={errors.isOtherHaveMedicalRecords}
          />

          {formValues.isOtherHaveMedicalRecords ? (
            <>
              <Box
                my={'20px'}
                gap={'30px'}
                display={'flex'}
                flexDirection={'column'}
              >
                <Typography className={Style.label}>
                  Please share the details of the others and Excluding private
                  health insurance that has your medical records.
                </Typography>

                {formValues?.otherOrganizationDetail?.map(
                  (form, index: Key | null | undefined) => (
                    <OrganizationDetailBox
                      key={index}
                      realValue={formValues}
                      handleRemove={() => {
                        handleDetailBoxRemove(index, 'otherOrganizationDetail')
                      }}
                      fieldName={'otherOrganizationDetail'}
                      errors={errors.otherOrganizationDetail?.[index]}
                      index={index}
                      formValues={form}
                      changeHandler={(data: {
                        name: string
                        value: string
                      }) => {
                        handleDetailBoxValuesChange(
                          index,
                          data,
                          'otherOrganizationDetail',
                        )
                      }}
                      setFormValues={setFormValues}
                    />
                  ),
                )}

                <AddButton
                  title='Details of the hospital, urgent care, clinic, or medical facility'
                  subTitle='For an effective records search, It is important that all service be shown below'
                  button='Add Medical Facility'
                  onClick={() => {
                    addDetailBox(
                      'otherOrganizationDetail',
                      'organizationValues',
                    )
                  }}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={() => {
                handleEditToggle()
              }}
            >
              Discard
            </Button>
            <Button
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Update
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default HospitalMedicalFacilityCare
