import React, { type FC } from 'react'
import { Box } from '@mui/material'
import { DashboardMain } from 'src/Utils/Constants'

const MainDashboard: FC = () => {
  const showProfile = () => {
    return true
  }

  return (
    <>
      <Box data-testid='dashboard'>
        <DashboardMain.Header showProfile={showProfile} />
        <Box
          sx={{
            height: 'calc(100vh - 92px)',
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'row', lg: 'row' },
          }}
        >
          <DashboardMain.SideBar />
          <DashboardMain.Main />
        </Box>
      </Box>
    </>
  )
}

export default MainDashboard
