import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material'
import React from 'react'
import warning from 'src/Assets/Icons/warning.svg'
import Style from './index.module.scss'

const DeletePopUp = ({
  id,
  openDialog,
  setOpenDialog,
  handleSubmit,
}: {
  id: string
  openDialog: boolean
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmit: (id: string) => void
}) => {
  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <Dialog
        PaperProps={{
          className: Style.DialogProps,
        }}
        open={openDialog}
        onClose={() => {
          handleClose()
        }}
      >
        <DialogContent className={Style.DialogContentBox}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            alignItems={'center'}
            textAlign={'center'}
          >
            <Box padding={'10px'}>
              <img src={warning} alt='warning' />
            </Box>
            <Typography
              component='h6'
              sx={{
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: '32px',
                my: 3,
              }}
            >
              Are you sure you want to Delete?
            </Typography>
            {/* <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                color: '#78829D',
              }}
            >
              No data will be saved on your device. All progress will be lost.
              Rest assure you won't lose any data.
            </Typography> */}
          </Box>
          <Box display={'flex'} gap={2}>
            <Button
              variant='contained'
              sx={{
                textTransform: 'none',
                textWrap: 'nowrap',
                color: '#252F4A',
                fontSize: '16',
                background: '#F1F1F4',
                borderRadius: '10px',
                '&:hover': { background: '#F1F1F4' },
                height: 40,
                margin: 0,
                alignItem: 'center',
                px: '10px',
              }}
              onClick={() => {
                handleClose()
              }}
            >
              No
            </Button>

            <Button
              variant='contained'
              sx={{
                textTransform: 'none',
                textWrap: 'nowrap',
                color: '#fff',
                fontSize: '16',
                background: '#7752FE',
                borderRadius: '10px',
                '&:hover': { background: '#7752FE' },
                height: 40,
                margin: 0,
                alignItem: 'center',
                px: '10px',
              }}
              onClick={() => {
                handleSubmit(id)
              }}
            >
              Yes
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DeletePopUp
