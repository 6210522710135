// This slice manages the state for the sign in feature
import { createAsyncThunk } from '@reduxjs/toolkit'
import ChatbotService from 'src/Redux/Services/chatbotService'

export const chatbot = createAsyncThunk(
  'chatbot',
  async (data: { text?: string; file?: any; uuid: string }) => {
    try {
      const response = await ChatbotService.Chatbot(data)
      return response.data
    } catch (err) {
      console.error('Error while sending request:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const JsonData = createAsyncThunk(
  'jsonData',
  async (data: { uuid: string; data: any }) => {
    try {
      const response = await ChatbotService.jsonData(data)
      return response.data
    } catch (err) {
      console.error('Error while sending request:', err)
      throw err // re-throw to allow error handling in components
    }
  },
)

export const session = createAsyncThunk('jsonData', async (userid: string) => {
  try {
    const response = await ChatbotService.session(userid)
    return response.data
  } catch (err) {
    console.error('Error while sending request:', err)
    throw err // re-throw to allow error handling in components
  }
})
