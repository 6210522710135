// stepsSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TAB_DATA } from 'src/Pages/Applications/allTabsData'
import {
  StepsState,
  SetStepCompletedPayload,
} from 'src/Redux/Services/Types/stepsType'

const initialState: StepsState = {
  activeTab: TAB_DATA.BASIC,
  SubmitApplicationSteps: [
    {
      tabName: TAB_DATA.SUBMIT,
      label: 'Additional Services & Submit',
      completed: false,
      active: false,
      sectionId: 'AdditionalServicesSubmit',
      flagSectionName: '',
    },
  ],
  SignConsentApplicationSteps: [
    {
      tabName: TAB_DATA.SIGNED_CONSENT_FORMS,
      label: 'Disability Benefits Representative Form',
      completed: false,
      active: false,
      sectionId: 'DisabilityBenefitsRepresentativeForm',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.SIGNED_CONSENT_FORMS,
      label: 'Medical Consent Form',
      completed: false,
      active: false,
      sectionId: 'MedicalConsentForm',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.SIGNED_CONSENT_FORMS,
      label: 'Any Other Relevant Signed Consent Forms',
      completed: false,
      active: false,
      sectionId: 'AnyOtherRelevantSignedConsentForms',
      flagSectionName: '',
    },
  ],
  UploadedApplicationSteps: [
    {
      tabName: TAB_DATA.UPLOADED_DOCUMENTATION,
      label: 'Required Documents',
      completed: false,
      active: false,
      sectionId: 'RequiredDocuments',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.UPLOADED_DOCUMENTATION,
      label: 'Additional Documents',
      completed: false,
      active: false,
      sectionId: 'AdditionalDocuments',
      flagSectionName: '',
    },
  ],
  MedicalApplicationSteps: [
    {
      tabName: TAB_DATA.MEDICAL,
      label: 'General Health',
      completed: false,
      active: false,
      sectionId: 'GeneralHealth',
      flagSectionName: 'generalHealth',
    },
    {
      tabName: TAB_DATA.MEDICAL,
      label: 'Vision & Hearing',
      completed: false,
      active: false,
      sectionId: 'VisionHearing',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.MEDICAL,
      label: 'Illnesses, Injuries, & Conditions',
      completed: false,
      active: false,
      sectionId: 'IllnessesInjuriesConditions',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.MEDICAL,
      label: 'Medical Professionals',
      completed: false,
      active: false,
      sectionId: 'MedicalProfessionals',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.MEDICAL,
      label: 'Hospital or Medical Facility Care',
      completed: false,
      active: false,
      sectionId: 'HospitalOrMedicalFacilityCare',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.MEDICAL,
      label: 'Medical Tests',
      completed: false,
      active: false,
      sectionId: 'MedicalTests',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.MEDICAL,
      label: 'Medicine',
      completed: false,
      active: false,
      sectionId: 'Medicine',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.MEDICAL,
      label: 'Additional Information',
      completed: false,
      active: false,
      sectionId: 'AdditionalInformation',
      flagSectionName: '',
    },
  ],
  EducationEmploymentApplicationSteps: [
    {
      tabName: TAB_DATA.EDUCATION_EMPLOYMENT,
      label: 'Education',
      completed: false,
      active: false,
      sectionId: 'Education',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.EDUCATION_EMPLOYMENT,
      label: 'Special Education',
      completed: false,
      active: false,
      sectionId: 'SpecialEducation',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.EDUCATION_EMPLOYMENT,
      label: 'Specialized Trade Education & Training',
      completed: false,
      active: false,
      sectionId: 'SpecializedTradeEducationTraining',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.EDUCATION_EMPLOYMENT,
      label: 'Employer Details',
      completed: false,
      active: false,
      sectionId: 'EmployerDetails',
      flagSectionName: 'employerDetail',
    },
    {
      tabName: TAB_DATA.EDUCATION_EMPLOYMENT,
      label: 'Job Details',
      completed: false,
      active: false,
      sectionId: 'JobDetails',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.EDUCATION_EMPLOYMENT,
      label: 'Child Care',
      completed: false,
      active: false,
      sectionId: 'childCare',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.EDUCATION_EMPLOYMENT,
      label: 'Self-Employment',
      completed: false,
      active: false,
      sectionId: 'SelfEmployment',
      flagSectionName: 'selfEmployment',
    },
    {
      tabName: TAB_DATA.EDUCATION_EMPLOYMENT,
      label: 'Foreign Work',
      completed: false,
      active: false,
      sectionId: 'ForeignWork',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.EDUCATION_EMPLOYMENT,
      label: 'Work Tax Details',
      completed: false,
      active: false,
      sectionId: 'WorkTaxDetails',
      flagSectionName: '',
    },
  ],
  DisablilityApplicationSteps: [
    {
      tabName: TAB_DATA.DISABLILLIY,
      label: 'Disability Background',
      completed: false,
      active: false,
      sectionId: 'DisabilityBackground',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.DISABLILLIY,
      label: 'Disability & Employment',
      completed: false,
      active: false,
      sectionId: 'DisabilityEmployment',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.DISABLILLIY,
      label: 'Payouts & Settlements',
      completed: false,
      active: false,
      sectionId: 'PayoutsSettlements',
      flagSectionName: '',
    },
  ],
  LegalDocApplicationSteps: [
    {
      tabName: TAB_DATA.LEGAL_NAMES_DOCUMENTATION,
      label: 'Legal Names',
      completed: false,
      active: false,
      sectionId: 'LegalNames',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.LEGAL_NAMES_DOCUMENTATION,
      label: 'Identification Numbers',
      completed: false,
      active: false,
      sectionId: 'IdentificationNumbers',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.LEGAL_NAMES_DOCUMENTATION,
      label: 'Receiving Disability Benefit Payments',
      completed: false,
      active: false,
      sectionId: 'ReceivingDisabilityBenefitPayments',
      flagSectionName: '',
    },
  ],
  FamilyApplicationSteps: [
    {
      tabName: TAB_DATA.FAMILY,
      label: 'Current Significant Other',
      completed: false,
      active: false,
      sectionId: 'CurrentSignificantOther',
      flagSectionName: 'currentSignificantOther',
    },
    {
      tabName: TAB_DATA.FAMILY,
      label: 'Past Significant Other(s)',
      completed: false,
      active: false,
      sectionId: 'PastSignificantOthers',
      flagSectionName: 'pastSignificantOthers',
    },
    {
      tabName: TAB_DATA.FAMILY,
      label: 'Dependents',
      completed: false,
      active: false,
      sectionId: 'Dependents',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.FAMILY,
      label: 'Parents',
      completed: false,
      active: false,
      sectionId: 'Parents',
      flagSectionName: '',
    },
  ],
  BasicApplicationSteps: [
    {
      tabName: TAB_DATA.BASIC,
      label: 'Contact',
      completed: false,
      active: false,
      sectionId: 'Contact',
      flagSectionName: 'contactInformation',
    },
    {
      tabName: TAB_DATA.BASIC,
      label: 'Communication',
      completed: false,
      active: false,
      sectionId: 'Communication',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.BASIC,
      label: 'Citizenship',
      completed: false,
      active: false,
      sectionId: 'Citizenship',
      flagSectionName: 'citizenship',
    },
    {
      tabName: TAB_DATA.BASIC,
      label: 'Military Service',
      completed: false,
      active: false,
      sectionId: 'MilitaryService',
      flagSectionName: '',
    },
    {
      tabName: TAB_DATA.BASIC,
      label: 'Past Applications',
      completed: false,
      active: false,
      sectionId: 'PastApplications',
      flagSectionName: '',
    },
  ],
}

const stepsSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    setStepCompleted: (
      state,
      action: PayloadAction<SetStepCompletedPayload>,
    ) => {
      const { section, label } = action.payload
      const stepSection = state[section]
      if (stepSection) {
        const stepIndex = stepSection.findIndex((step) => step.label === label)

        if (stepIndex !== -1) {
          stepSection.forEach(
            (step: { active: boolean; completed: boolean }, index: number) => {
              step.active = index === stepIndex // Set active to true for the found step index
              step.completed = index <= stepIndex // Set completed to true for steps up to and including the found step index
            },
          )
        }
      }
    },
    setActiveStepTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload
    },
  },
})

export const { setStepCompleted, setActiveStepTab } = stepsSlice.actions

export default stepsSlice.reducer
