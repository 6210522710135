import { chatAPI } from '../Common/httpCommon'

const Chatbot = async (data: { text?: string; file?: File; uuid: string }) => {
  try {
    return await chatAPI.post('chatbot', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e: any) {
    return e
  }
}

const jsonData = async (data: any) => {
  try {
    return await chatAPI.post('json_data', data)
  } catch (e: any) {
    return e
  }
}

const session = async (userid: string) => {
  try {
    return await chatAPI.post(`session/${userid}`)
  } catch (e: any) {
    return e
  }
}

const ChatbotService = {
  Chatbot,
  jsonData,
  session,
}
export default ChatbotService
