import { Box, Grid, Table, TableBody, TableCell, TableRow } from '@mui/material'
import React from 'react'
import Style from './index.module.scss'
import { RoleTable } from 'src/Pages/RolePermission/Role/RoleTableList'
// import { employees } from 'src/Utils/Constants'
import { useAppSelector } from 'src/Redux/hooks'
interface Role {
  uuid: string
  name: string
  description: string
  scope: string
  permission: []
}

const RoleList = (props: { find: Function; setSearchParam: Function }) => {
  const { find, setSearchParam } = props
  const RoleList = useAppSelector((state) => state.roles?.roleList?.data)
  React.useEffect(() => {
    setSearchParam(['name', 'description', 'scope', 'permission'])
  }, [])
  return (
    <>
      <Box className={Style.Main}>
        <Grid className={Style.ListWrapper}>
          <Grid className={Style.ListGrid}>
            <Box className={Style.Table}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={Style.TblHeading} align='left'>
                      ROLE
                    </TableCell>
                    <TableCell
                      width={'80%'}
                      className={Style.TblHeading}
                      align='left'
                    >
                      PERMISSION(S)
                    </TableCell>
                    <TableCell
                      width={'10%'}
                      className={Style.TblHeading}
                      align='left'
                    >
                      SCOPE
                    </TableCell>
                    <TableCell className={Style.TblHeading} align='right'>
                      ACTION
                    </TableCell>
                  </TableRow>
                  {RoleList?.length &&
                    find(RoleList)?.map((item: Role, index: number) => {
                      return (
                        <TableRow key={index}>
                          <RoleTable {...item} />
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default RoleList
