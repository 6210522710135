import { Grid } from '@mui/material'
import SeparatorFrame from '../Frame1'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { useEffect } from 'react'
import { getAllApplicationList } from 'src/Redux/Slices/ApplicationSlices/allApplicationSlice'
import { RootState } from 'src/Redux/store'
import CustomAlert from 'src/Components/NoDataAvilableBox'
import { DynamicObj } from 'src/Utils/Constants'
import { useLocation } from 'react-router-dom'

const GridStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  lg: 6,
  xl: 4,
  md: 6,
  sm: 12,
}

const List = (props: { searchstring: string }) => {
  const location = useLocation()
  const { filterParam } = location.state || {}
  const dispatch = useAppDispatch()
  const { data } = useAppSelector(
    (state: RootState) => state?.applicationStore?.AllApplications,
  )
  const searchParam = [
    'address',
    'createdAt',
    'emailId',
    'firstName',
    'lastName',
    'middleName',
    'phoneNumber',
    'socialSecurityNumber',
    'status',
  ]

  useEffect(() => {
    if (filterParam) {
      dispatch(getAllApplicationList(filterParam))
    } else {
      dispatch(getAllApplicationList({}))
    }
  }, [])

  function find(items: object[]) {
    return items?.filter((item: DynamicObj) => {
      return searchParam?.some((newItem: string) => {
        if (newItem === 'organization_address') {
          return (
            item?.organization_address.streetAddress
              ?.toString()
              .toLowerCase()
              .includes(props.searchstring.toLowerCase()) ||
            item?.organization_address.city
              ?.toString()
              .toLowerCase()
              .includes(props.searchstring.toLowerCase()) ||
            item?.organization_address.state
              ?.toString()
              .toLowerCase()
              .includes(props.searchstring.toLowerCase()) ||
            item?.organization_address.postalCode
              ?.toString()
              .toLowerCase()
              .includes(props.searchstring.toLowerCase())
          )
        }

        return item[newItem]
          ?.toString()
          .toLowerCase()
          .includes(props.searchstring.toLowerCase())
      })
    })
  }
  return (
    <>
      {Array.isArray(data) && data.length > 0 ? (
        <Grid container m={1}>
          {find(data).map((item: any) => (
            <Grid key={item.id} mr={{ lg: -2, md: 0 }} {...GridStyles} item>
              <SeparatorFrame
                completionVal={item?.applicationComplete}
                status={item?.status}
                data={item}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <Grid m={1} lg={10}>
            <CustomAlert message='No applications. Get started now!' />
          </Grid>
        </>
      )}
    </>
  )
}

export default List
