import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CommonRadioBox from 'src/Components/Application/CommonRadioBox'
import {
  AddButton,
  ApplicationIntroBox,
  Calander,
  CommonDropdown,
} from 'src/Components/Fields'
import { PassApplicationType } from 'src/Redux/Services/Types/applicationType'
import { AddPassApplication } from 'src/Redux/Slices/ApplicationSlices/BasicDetailsSlice'
import { JsonData } from 'src/Redux/Slices/ApplicationSlices/chatbotSlice'
import {
  setDisqualification,
  setIsDisqualified,
} from 'src/Redux/Slices/ApplicationStore/ApplicationIdStore'
import { setPassApplication } from 'src/Redux/Slices/ApplicationStore/BasicDetailsStore'
import { useAppDispatch, useAppSelector } from 'src/Redux/hooks'
import { ROUTES } from 'src/Utils/Routes'
import Style from '../index.module.scss'
import DetailBox from './DetailsBox'
import ProfileBox from './ProfileBox'

const PassApplication = ({
  handleNext,
  handleBack,
  chatForm,
}: {
  chatForm?: any
  handleBack: () => void
  handleNext: () => void
}) => {
  const PassApplicationState = useAppSelector(
    (state) => state?.applicationStore?.BasicDetails?.PassApplication,
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const detailId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.basicId,
  )
  const applicationId = useAppSelector(
    (state) => state?.applicationStore?.ApplicationId?.applicationId,
  )

  const [formValues, setFormValues] = useState<PassApplicationType>({
    basicDetailId: detailId,
    isReceivingMedicareBenefits: undefined,
    haveAppliedForMedicareBenefits: undefined,
    statusOfPreviousMedicareBenefit: '',
    // SSDI
    isReceivingSSDI: undefined,
    haveAppliedForSSDI: undefined,
    statusOfPreviousSSDI: '',
    SSDIApprovedEndDate: '',
    isSSDIDenial60Days: undefined,
    denialDateSSDI: '',
    // SSI
    isReceivingSSI: undefined,
    haveAppliedForSSI: undefined,
    statusOfSSI: '',
    SSIApprovedEndDate: '',
    isSSIDenial60Days: undefined,
    denialDateSSI: '',
    // other
    haveAppliedWithSSN: undefined,
    appliedForAdditionalBenefits: '',
    currentlyparticipatingPrograms: '',
    benefitAppliedSSNDetail: [
      {
        suffix: '',
        firstName: '',
        middleName: '',
        lastName: '',
        monthlyBenefitAmount: '',
        socialSecurityNumber: '',
      },
    ],
    participateProgramDetails: [
      {
        organizationName: '',
        address: {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        contactName: '',
        phone: '',
        estimatedDate: '',
        description: '',
        stillParticipatedInProgram: undefined,
      },
    ],
  })

  const isBotOpen = useAppSelector(
    (state) => state.applicationStore.ApplicationStep.isBotOpen,
  )
  useEffect(() => {
    setFormValues({
      ...PassApplicationState,
      basicDetailId: detailId,
    })
  }, [])

  useEffect(() => {
    if (isBotOpen)
      dispatch(
        JsonData({
          uuid: applicationId,
          data: {
            ...formValues,
            haveAppliedForSSI:
              formValues?.haveAppliedForSSI === undefined
                ? 'Boolean'
                : formValues?.haveAppliedForSSI,
            isSSIDenial60Days:
              formValues?.isSSIDenial60Days === undefined
                ? 'Boolean'
                : formValues?.isSSIDenial60Days,
            isReceivingMedicareBenefits:
              formValues?.isReceivingMedicareBenefits === undefined
                ? 'Boolean'
                : formValues?.isReceivingMedicareBenefits,
            haveAppliedForMedicareBenefits:
              formValues?.haveAppliedForMedicareBenefits === undefined
                ? 'Boolean'
                : formValues?.haveAppliedForMedicareBenefits,
            isReceivingSSDI:
              formValues?.isReceivingSSDI === undefined
                ? 'Boolean'
                : formValues?.isReceivingSSDI,
            haveAppliedForSSDI:
              formValues?.haveAppliedForSSDI === undefined
                ? 'Boolean'
                : formValues?.haveAppliedForSSDI,
            isReceivingSSI:
              formValues?.isReceivingSSI === undefined
                ? 'Boolean'
                : formValues?.isReceivingSSI,
            haveAppliedWithSSN:
              formValues?.haveAppliedWithSSN === undefined
                ? 'Boolean'
                : formValues?.haveAppliedWithSSN,
            stillParticipatedInProgram:
              formValues?.participateProgramDetails.map((item) => {
                return {
                  stillParticipatedInProgram:
                    item?.stillParticipatedInProgram === undefined
                      ? 'Boolean'
                      : item?.stillParticipatedInProgram,
                }
              }),
          },
        }),
      )
  }, [isBotOpen])

  useEffect(() => {
    if (chatForm)
      setFormValues((prevState) => ({
        ...prevState,
        ...chatForm,
      }))
  }, [chatForm])
  // disqualification
  useEffect(() => {
    if (formValues?.isReceivingSSDI !== undefined) {
      dispatch(
        setDisqualification({ isReceivingSSDI: formValues?.isReceivingSSDI }),
      )

      dispatch(setIsDisqualified(formValues.isReceivingSSDI))
    }
  }, [formValues?.isReceivingSSDI])

  useEffect(() => {
    if (formValues?.isSSDIDenial60Days !== undefined) {
      dispatch(
        setDisqualification({
          isSSDIDenial60Days: formValues?.isSSDIDenial60Days,
        }),
      )

      dispatch(setIsDisqualified(formValues.isSSDIDenial60Days))
    }
  }, [formValues?.isSSDIDenial60Days])

  useEffect(() => {
    if (formValues?.isReceivingSSI !== undefined) {
      dispatch(
        setDisqualification({ isReceivingSSI: formValues?.isReceivingSSI }),
      )

      dispatch(setIsDisqualified(formValues?.isReceivingSSI))
    }
  }, [formValues?.isReceivingSSI])

  useEffect(() => {
    if (formValues?.isSSIDenial60Days !== undefined) {
      dispatch(
        setDisqualification({
          isSSIDenial60Days: formValues?.isSSIDenial60Days,
        }),
      )

      dispatch(setIsDisqualified(formValues?.isSSIDenial60Days))
    }
  }, [formValues?.isSSIDenial60Days])

  useEffect(() => {
    if (formValues?.statusOfPreviousSSDI === 'In progress') {
      dispatch(
        setDisqualification({
          statusOfPreviousSSDI: true,
        }),

        dispatch(setIsDisqualified(true)),
      )
    } else {
      dispatch(
        setDisqualification({
          statusOfPreviousSSDI: false,
        }),

        dispatch(setIsDisqualified(false)),
      )
    }
  }, [formValues?.statusOfPreviousSSDI])

  useEffect(() => {
    if (formValues?.statusOfSSI === 'In progress') {
      dispatch(
        setDisqualification({
          statusOfSSI: true,
        }),

        dispatch(setIsDisqualified(true)),
      )
    } else {
      dispatch(
        setDisqualification({
          statusOfSSI: false,
        }),

        dispatch(setIsDisqualified(false)),
      )
    }
  }, [formValues?.statusOfSSI])

  const isDisabled = () => {
    if (formValues?.isReceivingSSDI === true) {
      return true
    }
    if (formValues?.isReceivingSSI === true) {
      return true
    }
    if (formValues?.isSSDIDenial60Days === true) {
      return true
    }
    if (formValues?.isSSIDenial60Days === true) {
      return true
    }
    if (formValues?.statusOfPreviousSSDI === 'In progress') {
      return true
    }
    if (formValues?.statusOfSSI === 'In progress') {
      return true
    }
  }

  const [errors, setErrors] = useState<
    Record<string, string | Array<Record<string, string>>>
  >({
    benefitAppliedSSNDetail: [{}],
    participateProgramDetails: [{}],
  })

  const handleFieldChange = (data: {
    name: string
    value: string | number | boolean
  }) => {
    const { name, value } = data
    setFormValues({ ...formValues, [name]: value })
  }

  const validateForm = () => {
    let valid = true
    const newErrors: Record<string, string | Array<Record<string, string>>> = {
      benefitAppliedSSNDetail: [{}],
      participateProgramDetails: [{}],
    }
    const currentDate = new Date().toISOString().split('T')[0]
    if (formValues?.isReceivingMedicareBenefits === undefined) {
      newErrors.isReceivingMedicareBenefits = 'Please select an option'
      valid = false
    }
    if (formValues?.isReceivingMedicareBenefits === false) {
      if (formValues?.haveAppliedForMedicareBenefits === undefined) {
        newErrors.haveAppliedForMedicareBenefits = 'Please select an option'
        valid = false
      }
      if (formValues?.haveAppliedForMedicareBenefits === true) {
        if (!formValues?.statusOfPreviousMedicareBenefit.trim()) {
          newErrors.statusOfPreviousMedicareBenefit = 'Status is required'
          valid = false
        }
      }
    }

    if (formValues?.isReceivingSSDI === undefined) {
      newErrors.isReceivingSSDI = 'Please select an option'
      valid = false
    }
    if (formValues?.isReceivingSSDI === false) {
      if (formValues?.haveAppliedForSSDI === undefined) {
        newErrors.haveAppliedForSSDI = 'Please select an option'
        valid = false
      }
      if (formValues?.haveAppliedForSSDI === true) {
        if (!formValues.statusOfPreviousSSDI.trim()) {
          newErrors.statusOfPreviousSSDI = 'Status is required'
          valid = false
        }
        if (formValues?.statusOfPreviousSSDI === 'Approved') {
          if (!formValues.SSDIApprovedEndDate.trim()) {
            newErrors.SSDIApprovedEndDate = 'Date is required'
            valid = false
          }
          if (formValues?.SSDIApprovedEndDate > currentDate) {
            newErrors.SSDIApprovedEndDate = 'Date cannot be in the future'
            valid = false
          }
        }

        if (formValues?.statusOfPreviousSSDI === 'Denied') {
          if (formValues?.isSSDIDenial60Days === undefined) {
            newErrors.isSSDIDenial60Days = 'Please select an option'
            valid = false
          }
          if (formValues?.isSSDIDenial60Days === false) {
            if (!formValues.denialDateSSDI?.trim()) {
              newErrors.denialDateSSDI = 'Date is required'
              valid = false
            }
            if (formValues?.denialDateSSDI > currentDate) {
              newErrors.denialDateSSDI = 'Date cannot be in the future'
              valid = false
            }
          }
        }
      }
    }

    if (formValues?.isReceivingSSI === undefined) {
      newErrors.isReceivingSSI = 'Please select an option'
      valid = false
    }

    if (formValues?.isReceivingSSI === false) {
      if (formValues?.haveAppliedForSSI === undefined) {
        newErrors.haveAppliedForSSI = 'Please select an option'
        valid = false
      }
      if (formValues?.haveAppliedForSSI === true) {
        if (!formValues.statusOfSSI?.trim()) {
          newErrors.statusOfSSI = 'Status is required'
          valid = false
        }
        if (formValues?.statusOfSSI === 'Approved') {
          if (!formValues.SSIApprovedEndDate?.trim()) {
            newErrors.SSIApprovedEndDate = 'Date is required'
            valid = false
          }
          if (formValues?.SSIApprovedEndDate > currentDate) {
            newErrors.SSIApprovedEndDate = 'Date cannot be in the future'
            valid = false
          }
        }
        if (formValues?.statusOfSSI === 'Denied') {
          if (formValues?.isSSIDenial60Days === undefined) {
            newErrors.isSSIDenial60Days = 'Please select an option'
            valid = false
          }
          if (formValues?.isSSIDenial60Days === false) {
            if (!formValues.denialDateSSI?.trim()) {
              newErrors.denialDateSSI = 'Date is required'
              valid = false
            }
            if (formValues?.denialDateSSI > currentDate) {
              newErrors.denialDateSSI = 'Date cannot be in the future'
              valid = false
            }
          }
        }
      }
    }

    if (formValues?.haveAppliedWithSSN === undefined) {
      newErrors.haveAppliedWithSSN = 'Please select an option'
      valid = false
    }
    if (formValues?.haveAppliedWithSSN === false) {
      for (let i = 0; i < formValues.benefitAppliedSSNDetail.length; i++) {
        if (!formValues.benefitAppliedSSNDetail[i].suffix.trim()) {
          newErrors.benefitAppliedSSNDetail = (
            newErrors.benefitAppliedSSNDetail as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index) return { ...error, suffix: 'Suffix is required' }
            return error
          })
          valid = false
        }
        if (!formValues.benefitAppliedSSNDetail[i].firstName.trim()) {
          newErrors.benefitAppliedSSNDetail = (
            newErrors.benefitAppliedSSNDetail as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return { ...error, firstName: 'First Name is required' }
            return error
          })
          valid = false
        }
        if (!formValues.benefitAppliedSSNDetail[i].middleName.trim()) {
          newErrors.benefitAppliedSSNDetail = (
            newErrors.benefitAppliedSSNDetail as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return { ...error, middleName: 'Middle Name is required' }
            return error
          })
          valid = false
        }
        if (!formValues.benefitAppliedSSNDetail[i].lastName.trim()) {
          newErrors.benefitAppliedSSNDetail = (
            newErrors.benefitAppliedSSNDetail as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return { ...error, lastName: 'Last Name is required' }
            return error
          })
          valid = false
        }
        if (
          !formValues.benefitAppliedSSNDetail[i].monthlyBenefitAmount.trim()
        ) {
          newErrors.benefitAppliedSSNDetail = (
            newErrors.benefitAppliedSSNDetail as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return {
                ...error,
                monthlyBenefitAmount: 'Monthly Benefit Amount is required',
              }
            return error
          })
          valid = false
        }
        if (
          !formValues.benefitAppliedSSNDetail[i].socialSecurityNumber.trim()
        ) {
          newErrors.benefitAppliedSSNDetail = (
            newErrors.benefitAppliedSSNDetail as Array<Record<string, string>>
          )?.map((error, index) => {
            if (i === index)
              return {
                ...error,
                socialSecurityNumber: 'Social Security Number is required',
              }
            return error
          })
          valid = false
        }
      }
    }

    if (!formValues.appliedForAdditionalBenefits.trim()) {
      newErrors.appliedForAdditionalBenefits = 'please select'
      valid = false
    }

    if (!formValues.currentlyparticipatingPrograms.trim()) {
      newErrors.currentlyparticipatingPrograms = 'please select'
      valid = false
    }

    for (let i = 0; i < formValues.participateProgramDetails.length; i++) {
      if (!formValues.participateProgramDetails[i].organizationName.trim()) {
        newErrors.participateProgramDetails = (
          newErrors.participateProgramDetails as Array<Record<string, string>>
        )?.map((error, index) => {
          if (i === index)
            return {
              ...error,
              organizationName: 'Organization Name is required',
            }
          return error
        })
        valid = false
      }
      if (!formValues.participateProgramDetails[i].description.trim()) {
        newErrors.participateProgramDetails = (
          newErrors.participateProgramDetails as Array<Record<string, string>>
        )?.map((error, index) => {
          if (i === index)
            return { ...error, description: 'Description is required' }
          return error
        })
        valid = false
      }
      if (!formValues.participateProgramDetails[i].contactName.trim()) {
        newErrors.participateProgramDetails = (
          newErrors.participateProgramDetails as Array<Record<string, string>>
        )?.map((error, index) => {
          if (i === index)
            return { ...error, contactName: 'Contact Name is required' }
          return error
        })
        valid = false
      }
      if (!formValues.participateProgramDetails[i].phone.trim()) {
        newErrors.participateProgramDetails = (
          newErrors.participateProgramDetails as Array<Record<string, string>>
        )?.map((error, index) => {
          if (i === index) return { ...error, phone: 'Phone is required' }
          return error
        })
        valid = false
      }
      if (!formValues.participateProgramDetails[i].estimatedDate.trim()) {
        newErrors.participateProgramDetails = (
          newErrors.participateProgramDetails as Array<Record<string, string>>
        )?.map((error, index) => {
          if (i === index)
            return { ...error, estimatedDate: 'Estimated Date is required' }
          return error
        })
        valid = false
      }
      if (formValues.participateProgramDetails[i].estimatedDate > currentDate) {
        newErrors.participateProgramDetails = (
          newErrors.participateProgramDetails as Array<Record<string, string>>
        )?.map((error, index) => {
          if (i === index)
            return {
              ...error,
              estimatedDate: 'Estimated Date cannot be in the future',
            }
          return error
        })
        valid = false
      }
      if (
        formValues.participateProgramDetails[i].stillParticipatedInProgram ===
        undefined
      ) {
        newErrors.participateProgramDetails = (
          newErrors.participateProgramDetails as Array<Record<string, string>>
        )?.map((error, index) => {
          if (i === index)
            return {
              ...error,
              stillParticipatedInProgram: 'Please select an option',
            }
          return error
        })
        valid = false
      }
      if (
        !formValues.participateProgramDetails[i].address.streetAddress?.trim()
      ) {
        newErrors.participateProgramDetails = (
          newErrors.participateProgramDetails as Array<Record<string, string>>
        )?.map((error, index) => {
          if (i === index)
            return {
              ...error,
              streetAddress: 'Street Address is required',
            }
          return error
        })
        valid = false
      }
      if (!formValues.participateProgramDetails[i].address.city?.trim()) {
        newErrors.participateProgramDetails = (
          newErrors.participateProgramDetails as Array<Record<string, string>>
        )?.map((error, index) => {
          if (i === index) return { ...error, city: 'City is required' }
          return error
        })
        valid = false
      }
      if (!formValues.participateProgramDetails[i].address.state?.trim()) {
        newErrors.participateProgramDetails = (
          newErrors.participateProgramDetails as Array<Record<string, string>>
        )?.map((error, index) => {
          if (i === index) return { ...error, state: 'State is required' }
          return error
        })
        valid = false
      }
      if (!formValues.participateProgramDetails[i].address.postalCode?.trim()) {
        newErrors.participateProgramDetails = (
          newErrors.participateProgramDetails as Array<Record<string, string>>
        )?.map((error, index) => {
          if (i === index)
            return { ...error, postalCode: 'Postal Code is required' }
          return error
        })
        valid = false
      }
      if (!formValues.participateProgramDetails[i].address.country?.trim()) {
        newErrors.participateProgramDetails = (
          newErrors.participateProgramDetails as Array<Record<string, string>>
        )?.map((error, index) => {
          if (i === index) return { ...error, country: 'Country is required' }
          return error
        })
        valid = false
      }
      ;(
        newErrors.participateProgramDetails as Array<Record<string, string>>
      ).push({})
    }

    setErrors(newErrors)
    return valid
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const formValuesToSubmit: typeof formValues = {}
    formValuesToSubmit.basicDetailId = formValues.basicDetailId
    formValuesToSubmit.isReceivingMedicareBenefits =
      formValues.isReceivingMedicareBenefits
    formValuesToSubmit.isReceivingSSDI = formValues.isReceivingSSDI
    formValuesToSubmit.isReceivingSSI = formValues.isReceivingSSI
    formValuesToSubmit.haveAppliedWithSSN = formValues.haveAppliedWithSSN

    if (formValues?.haveAppliedWithSSN === false) {
      formValuesToSubmit.benefitAppliedSSNDetail =
        formValues.benefitAppliedSSNDetail
    }

    formValuesToSubmit.appliedForAdditionalBenefits =
      formValues.appliedForAdditionalBenefits
    formValuesToSubmit.currentlyparticipatingPrograms =
      formValues.currentlyparticipatingPrograms
    formValuesToSubmit.participateProgramDetails =
      formValues.participateProgramDetails

    if (formValues.isReceivingMedicareBenefits === false) {
      formValuesToSubmit.haveAppliedForMedicareBenefits =
        formValues.haveAppliedForMedicareBenefits
      if (formValues.haveAppliedForMedicareBenefits) {
        formValuesToSubmit.statusOfPreviousMedicareBenefit =
          formValues.statusOfPreviousMedicareBenefit
      }
    }
    // SSDI
    if (formValues.isReceivingSSDI === false) {
      formValuesToSubmit.haveAppliedForSSDI = formValues.haveAppliedForSSDI
      if (formValues.haveAppliedForSSDI) {
        formValuesToSubmit.statusOfPreviousSSDI =
          formValues.statusOfPreviousSSDI
        if (formValues.statusOfPreviousSSDI === 'Approved') {
          formValuesToSubmit.SSDIApprovedEndDate =
            formValues.SSDIApprovedEndDate
        }
        if (formValues.statusOfPreviousSSDI === 'Denied') {
          formValuesToSubmit.isSSDIDenial60Days = formValues.isSSDIDenial60Days
          if (formValues.isSSDIDenial60Days === false) {
            formValuesToSubmit.denialDateSSDI = formValues.denialDateSSDI
          }
        }
      }
    }
    // SSI
    if (formValues.isReceivingSSI === false) {
      formValuesToSubmit.haveAppliedForSSI = formValues.haveAppliedForSSI
      if (formValues.haveAppliedForSSI) {
        formValuesToSubmit.statusOfSSI = formValues.statusOfSSI
        if (formValues.statusOfSSI === 'Approved') {
          formValuesToSubmit.SSIApprovedEndDate = formValues.SSIApprovedEndDate
        }
        if (formValues.statusOfSSI === 'Denied') {
          formValuesToSubmit.isSSIDenial60Days = formValues.isSSIDenial60Days
          if (formValues.isSSIDenial60Days === false) {
            formValuesToSubmit.denialDateSSI = formValues.denialDateSSI
          }
        }
      }
    }

    // Handle form submission here, you can access formValues to submit data
    if (validateForm()) {
      dispatch(setPassApplication(formValues))
      dispatch(AddPassApplication(formValuesToSubmit)).then(
        (
          res:
            | PayloadAction<
                any,
                string,
                { arg: any; requestId: string; requestStatus: 'fulfilled' },
                never
              >
            | PayloadAction<
                unknown,
                string,
                {
                  arg: any
                  requestId: string
                  requestStatus: 'rejected'
                  aborted: boolean
                  condition: boolean
                } & (
                  | { rejectedWithValue: true }
                  | ({ rejectedWithValue: false } & {})
                ),
                SerializedError
              >,
        ) => {
          if (res?.payload?.success) {
            handleNext()
            navigate(ROUTES.FAMILY_DETAILS)
          }
        },
      )
    }
  }

  const handleValuesChange = (
    index: number,
    data: { name: string; value: string },
  ) => {
    let newProfileForms = [...formValues.benefitAppliedSSNDetail]
    newProfileForms = newProfileForms?.map(
      (
        item: {
          suffix: string
          firstName: string
          middleName: string
          lastName: string
          monthlyBenefitAmount: string
          socialSecurityNumber: string
        },
        i: number,
      ) => {
        if (i === index) {
          return {
            ...item,
            [data.name]: data.value,
          }
        }
        return item
      },
    )
    setFormValues({ ...formValues, benefitAppliedSSNDetail: newProfileForms })
  }

  const handleRemove = (index: number) => {
    const newProfileForms = [...formValues.benefitAppliedSSNDetail]
    newProfileForms.splice(index, 1)
    setFormValues({ ...formValues, benefitAppliedSSNDetail: newProfileForms })
  }

  const addProfileBox = () => {
    setFormValues({
      ...formValues,
      benefitAppliedSSNDetail: [
        ...formValues.benefitAppliedSSNDetail,
        {
          suffix: '',
          firstName: '',
          middleName: '',
          lastName: '',
          monthlyBenefitAmount: '',
          socialSecurityNumber: '',
        },
      ],
    })
  }

  const handleDetailBoxValuesChange = (
    index: number,
    data: { name: string; value: string | number | boolean },
  ) => {
    let newProfileForms = [...formValues?.participateProgramDetails]
    newProfileForms = newProfileForms?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [data.name]: data.value,
        }
      }
      return item
    })
    setFormValues({ ...formValues, participateProgramDetails: newProfileForms })
  }

  const handleDetailBoxRemove = (index: number) => {
    const newProfileForms = [...formValues.participateProgramDetails]
    newProfileForms.splice(index, 1)
    setFormValues({ ...formValues, participateProgramDetails: newProfileForms })
  }

  const addDetailBox = () => {
    if (formValues.participateProgramDetails?.length)
      setFormValues({
        ...formValues,
        participateProgramDetails: [
          ...formValues.participateProgramDetails,
          {
            organizationName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            contactName: '',
            phone: '',
            estimatedDate: '',
            description: '',
            stillParticipatedInProgram: undefined,
          },
        ],
      })
    else
      setFormValues({
        ...formValues,
        participateProgramDetails: [
          {
            organizationName: '',
            address: {
              streetAddress: '',
              city: '',
              state: '',
              postalCode: '',
              country: '',
            },
            contactName: '',
            phone: '',
            estimatedDate: '',
            description: '',
            stillParticipatedInProgram: undefined,
          },
        ],
      })
  }

  return (
    <Box className={Style.DialogContentBox}>
      <Box className={Style.GridBox}>
        <form className={Style.Form} onSubmit={handleSubmit}>
          <ApplicationIntroBox
            heading="Let's review any benefits for which you've applied in the past to
              ensure that you're eligible for SSI and/or SSDI."
          />
          <Typography variant='body1' className={Style.label}>
            Medical Benefits
          </Typography>
          <CommonRadioBox
            error={errors?.isReceivingMedicareBenefits as string}
            title='Are you currently receiving Medicare benefits?'
            commonState={formValues?.isReceivingMedicareBenefits}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isReceivingMedicareBenefits: value,
              })
            }}
            YesTitle='I am currently receiving Medicare benefits.'
            NoTitle='I am not currently receiving Medicare benefits.'
          />
          {formValues?.isReceivingMedicareBenefits === false && (
            <>
              <CommonRadioBox
                error={errors?.haveAppliedForMedicareBenefits as string}
                title='Have you previously applied for Medicare benefits?'
                commonState={formValues?.haveAppliedForMedicareBenefits}
                setcommonState={(value: boolean) => {
                  setFormValues({
                    ...formValues,
                    haveAppliedForMedicareBenefits: value,
                  })
                }}
                YesTitle='I have previously applied for Medicare benefits.'
                NoTitle='I have not previously applied for Medicare benefits.'
              />
              {formValues?.haveAppliedForMedicareBenefits === true && (
                <>
                  <CommonDropdown
                    name='statusOfPreviousMedicareBenefit'
                    label='What’s the status of the Medicare benefits program for which you previously applied?'
                    value={formValues?.statusOfPreviousMedicareBenefit}
                    menulist={[
                      { name: 'Approved', value: 'Approved' },
                      { name: 'Denied', value: 'Denied' },
                      { name: 'In progress', value: 'In progress' },
                    ]}
                    handleChange={handleFieldChange}
                    error={errors?.statusOfPreviousMedicareBenefit as string}
                  />
                </>
              )}
            </>
          )}
          <Typography variant='body1' className={Style.label}>
            Social Security Benefits (SSDI)
          </Typography>

          <CommonRadioBox
            error={errors?.isReceivingSSDI as string}
            title='Are you currently receiving Social Security benefits (SSDI)?'
            commonState={formValues?.isReceivingSSDI}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isReceivingSSDI: value,
              })
            }}
            YesTitle='I am currently receiving Social Security benefits (SSDI).'
            NoTitle='I am not currently receiving Social Security benefits (SSDI).'
          />
          {formValues?.isReceivingSSDI === false && (
            <>
              <CommonRadioBox
                error={errors?.haveAppliedForSSDI as string}
                title='Have you previously applied for Social Security benefits (SSDI)?'
                commonState={formValues?.haveAppliedForSSDI}
                setcommonState={(value: boolean) => {
                  setFormValues({
                    ...formValues,
                    haveAppliedForSSDI: value,
                  })
                }}
                YesTitle='I have previously applied for Social Security benefits (SSDI).'
                NoTitle='I have not previously applied for Social Security benefits (SSDI).'
              />
              {formValues?.haveAppliedForSSDI === true && (
                <>
                  <CommonDropdown
                    name='statusOfPreviousSSDI'
                    value={formValues?.statusOfPreviousSSDI}
                    label='What’s the status of the Social Security benefits (SSDI) program for which you previously applied?'
                    menulist={[
                      { name: 'Approved', value: 'Approved' },
                      { name: 'Denied', value: 'Denied' },
                      { name: 'In progress', value: 'In progress' },
                    ]}
                    handleChange={handleFieldChange}
                    error={errors?.statusOfPreviousSSDI as string}
                  />
                  {formValues?.statusOfPreviousSSDI === 'Approved' && (
                    <>
                      <Calander
                        name='SSDIApprovedEndDate'
                        value={formValues?.SSDIApprovedEndDate}
                        title='End Date of Benefits (estimate if unknown)'
                        handleChange={handleFieldChange}
                        error={errors?.SSDIApprovedEndDate as string}
                      />
                    </>
                  )}
                  {formValues?.statusOfPreviousSSDI === 'Denied' && (
                    <>
                      <CommonRadioBox
                        error={errors?.isSSDIDenial60Days as string}
                        title={`Thanks for sharing that you've applied for benefits in the past. Was your benefits application denied in the last 60 days?`}
                        commonState={formValues?.isSSDIDenial60Days}
                        setcommonState={(value: boolean) => {
                          setFormValues({
                            ...formValues,
                            isSSDIDenial60Days: value,
                          })
                        }}
                        YesTitle='My past benefits application denied in the last 60 days.'
                        NoTitle='My past benefits application was not denied in the last 60 days.'
                      />
                      {formValues?.isSSDIDenial60Days === false && (
                        <Calander
                          name='denialDateSSDI'
                          value={formValues?.denialDateSSDI}
                          title='Denial Date of Benefits (estimate if unknown):'
                          handleChange={handleFieldChange}
                          error={errors?.denialDateSSDI as string}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}

          <Typography variant='body1' className={Style.label}>
            Supplemental Security Income (SSI)
          </Typography>

          <CommonRadioBox
            error={errors?.isReceivingSSI as string}
            title='Are you currently receiving Supplemental Security Income (SSI)?'
            commonState={formValues?.isReceivingSSI}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                isReceivingSSI: value,
              })
            }}
            YesTitle='I am currently receiving Supplemental Security Income (SSI).'
            NoTitle='I am not currently receiving Supplemental Security Income (SSI).'
          />
          {formValues?.isReceivingSSI === false && (
            <>
              <CommonRadioBox
                error={errors?.haveAppliedForSSI as string}
                title='Have you previously applied for Supplemental Security Income (SSI)?'
                commonState={formValues?.haveAppliedForSSI}
                setcommonState={(value: boolean) => {
                  setFormValues({
                    ...formValues,
                    haveAppliedForSSI: value,
                  })
                }}
                YesTitle='I have previously applied for Supplemental Security Income (SSI).'
                NoTitle='I have not previously applied for Supplemental Security Income (SSI).'
              />
              {formValues?.haveAppliedForSSI === true && (
                <>
                  <CommonDropdown
                    name='statusOfSSI'
                    value={formValues?.statusOfSSI ?? ''}
                    label='What’s the status of the Supplemental Security Income (SSI) program for which you previously applied?'
                    menulist={[
                      { name: 'Approved', value: 'Approved' },
                      { name: 'Denied', value: 'Denied' },
                      { name: 'In progress', value: 'In progress' },
                    ]}
                    handleChange={handleFieldChange}
                    error={errors?.statusOfSSI as string}
                  />
                  {formValues?.statusOfSSI === 'Approved' && (
                    <>
                      <Calander
                        name='SSIApprovedEndDate'
                        value={formValues?.SSIApprovedEndDate}
                        title='End Date of Benefits (estimate if unknown)'
                        handleChange={handleFieldChange}
                        error={errors?.SSIApprovedEndDate as string}
                      />
                    </>
                  )}
                  {formValues?.statusOfSSI === 'Denied' && (
                    <>
                      <CommonRadioBox
                        error={errors?.isSSIDenial60Days as string}
                        title={`Thanks for sharing that you've applied for benefits in the past. Was your benefits application denied in the last 60 days?`}
                        commonState={formValues?.isSSIDenial60Days}
                        setcommonState={(value: boolean) => {
                          setFormValues({
                            ...formValues,
                            isSSIDenial60Days: value,
                          })
                        }}
                        YesTitle='My past benefits application denied in the last 60 days.'
                        NoTitle='My past benefits application was not denied in the last 60 days.'
                      />
                      {formValues?.isSSIDenial60Days === false && (
                        <Calander
                          name='denialDateSSI'
                          value={formValues?.denialDateSSI}
                          title='Denial Date of Benefits (estimate if unknown):'
                          handleChange={handleFieldChange}
                          error={errors?.denialDateSSI as string}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          <CommonRadioBox
            error={errors?.haveAppliedWithSSN as string}
            title='Did you previously apply using your own Social Security Number (SSN)?'
            commonState={formValues?.haveAppliedWithSSN}
            setcommonState={(value: boolean) => {
              setFormValues({
                ...formValues,
                haveAppliedWithSSN: value,
              })
            }}
            YesTitle='I previously applied using my own Social Security Number (SSN).'
            NoTitle='I did not previously apply using my own Social Security Number (SSN).'
          />
          {formValues?.haveAppliedWithSSN === false && (
            <>
              <Typography className={Style.label}>
                Please tell us who’s social security number (SSN) for Benefits
                were applied to
              </Typography>
              {/* address */}
              {formValues.benefitAppliedSSNDetail?.map((form, index) => (
                <ProfileBox
                  key={index}
                  errors={
                    errors.benefitAppliedSSNDetail[index] as Record<
                      string,
                      string
                    >
                  }
                  handleRemove={() => {
                    handleRemove(index)
                  }}
                  index={index}
                  formValues={form}
                  setFormValues={setFormValues}
                  changeHandler={(data: { name: string; value: string }) => {
                    handleValuesChange(index, data)
                  }}
                />
              ))}
              <Box height={'30px'} width={'100%'} />

              <AddButton
                title='Social Security Numbers for Benefits'
                subTitle='For an effective records search, It is important that all
                  service be shown below'
                button=' Add Additional SSNs'
                onClick={addProfileBox}
              />
            </>
          )}

          <CommonDropdown
            name='appliedForAdditionalBenefits'
            value={formValues?.appliedForAdditionalBenefits}
            label="Let us know if you've ever applied or intend to apply for any of
              these additional benefits."
            menulist={[
              { name: 'Workers compensation', value: 'Workers compensation' },
              {
                name: 'State disability in California, New York, New Jersey, Rhode Island',
                value:
                  'State disability in California, New York, New Jersey, Rhode Island',
              },
              {
                name: 'Veterans Administration benefits',
                value: 'Veterans Administration benefits',
              },
              { name: 'Welfare', value: 'Welfare' },
              { name: 'None of the Above', value: 'None of the Above' },
            ]}
            handleChange={handleFieldChange}
            error={errors?.appliedForAdditionalBenefits as string}
          />
          <CommonDropdown
            name='currentlyparticipatingPrograms'
            value={formValues?.currentlyparticipatingPrograms}
            label='Have you participated in, or are you currently participating in,
            any of the following programs?'
            menulist={[
              {
                name: 'A Plan to Achive Self-Program (PASS)',
                value: 'A Plan to Achive Self-Program (PASS)',
              },
              {
                name: 'Any Individualized Education Program (IEP) throgh a school (up to age 21)',
                value:
                  'Any Individualized Education Program (IEP) throgh a school (up to age 21)',
              },
              {
                name: 'Any program providing vocational rehabilitation, employment services, other support services that in getting you to work.',
                value:
                  'Any program providing vocational rehabilitation, employment services, other support services that in getting you to work.',
              },
              {
                name: 'Employment plan with a vocational rehabilitation agency or any other organization',
                value:
                  'Employment plan with a vocational rehabilitation agency or any other organization',
              },
              {
                name: 'Ticket to Work Program',
                value: 'Ticket to Work Program',
              },
            ]}
            handleChange={handleFieldChange}
            error={errors?.currentlyparticipatingPrograms as string}
          />
          <Typography className={Style.label}>
            For each selected answer above, please share details of the answer
            in which you've participated or are planning to participate
          </Typography>

          {formValues?.participateProgramDetails?.map((form, index) => (
            <DetailBox
              key={index}
              realValue={formValues}
              handleRemove={() => {
                handleDetailBoxRemove(index)
              }}
              errors={errors.participateProgramDetails[index]}
              index={index}
              formValues={form}
              changeHandler={(data: { name: string; value: string }) => {
                handleDetailBoxValuesChange(index, data)
              }}
              setFormValues={setFormValues}
              participatedProgram={
                formValues.participateProgramDetails[index]
                  .stillParticipatedInProgram
              }
              setparticipatedProgram={(value: boolean) => {
                handleDetailBoxValuesChange(index, {
                  name: 'stillParticipatedInProgram',
                  value,
                })
              }}
            />
          ))}

          <Box height={'30px'} width={'100%'} />

          <AddButton
            title='Participated or are planning to participate'
            subTitle='For an effective records search, It is important that all
                  service be shown below'
            button='Add Program Details'
            onClick={addDetailBox}
          />

          <Box className={Style.AddButton}>
            <Button
              variant='contained'
              className={Style.ButtonOne}
              onClick={handleBack}
            >
              Previous
            </Button>
            <Button
              disabled={isDisabled()}
              variant='contained'
              type='submit'
              className={Style.ButtonTwo}
            >
              Save & Continue
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default PassApplication
